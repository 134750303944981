import React, { useState, useEffect } from "react";
import { useParams, useHistory, Link } from "react-router-dom";
import Header from "../HeaderTrilhaDeConhecimento";
import api from "../../services/api";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import { useStyles, Container, CustomTooltip } from "./styles";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import InfoIcon from "@mui/icons-material/Info";
import LinearProgress from "@mui/material/LinearProgress";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import {
  Button,
  List,
  ListItem,
  ListItemText,
  Collapse,
  ListItemButton,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import Atividade from "./ModalAtividade";
import AssignmentIcon from "@mui/icons-material/Assignment";

const theme = createTheme({
  palette: {
    primary: {
      main: "#BD6F57",
      darker: "rgba(212, 109, 13, 1)",
    },
    // success: {
  },
});

function PageTrilhaDeConhecimento() {
  const { id } = useParams();
  const history = useHistory();
  const classes = useStyles();
  const [playlistItems, setPlaylistItems] = useState([]);
  const [trilha, setTrilha] = useState({});
  const [cursos, setCursos] = useState([]);
  const [openAtividade, setOpenAtividade] = useState(false);
  const [atividadeIndex, setAtividadeIndex] = useState(0);
  const [cursoId, setCursoId] = useState(false);
  const [atualiza, setAtualiza] = useState(false);
  const [loading, setLoading] = useState(true)
  const [showCursos, setShowCursos] = useState(
    new Array(cursos.length).fill(false)
  );
  const isSmallScreen = useMediaQuery("(max-width:600px)");

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      try {
        const trilhaResponse = await api.get(`/trilhas-de-conhecimento/${id}`);
        setTrilha(trilhaResponse.data);
        setLoading(false);

        api
          .get(`/trilhas-de-conhecimento/cursos/${id}`)
          .then((response) => {
            // console.log("cursoTrilha", response.data);
            setCursos(response.data);
            setLoading(false);
          })
          .catch((err) => {
            console.log(err);
          });
      } catch (error) {
        console.error("Erro ao buscar dados da API:", error);
      }
    };

    fetchData();
    setAtualiza(false)
  }, [id, atualiza]);

  const handleBack = () => {
    history.push("/oxetrilhas");
  };

  return (
    <ThemeProvider theme={theme}>
      <Header onBack={handleBack} trilha={trilha} />
      <Container>
        <List>
          {cursos.map((tipo, index) => (
            <div key={index}>
              <ListItemButton
                sx={{ background: "rgb(0,0,0,0.05)" }}
                disabled={tipo.bloqueado}
                onClick={() => {
                  const updatedShowCursos = [...showCursos];
                  updatedShowCursos[index] = !showCursos[index];
                  setShowCursos(updatedShowCursos);
                }} 
              >
                <div
                  style={{
                    display: "flex",
                    width: "80%",
                    alignItems: "center",
                  }}
                >
                  {showCursos[index] ? <ExpandLess /> : <ExpandMore />}
                  <h2
                    style={{
                      margin: 0,
                      fontSize:
                        isSmallScreen && tipo.modulo.curso.titulo.length > 20
                          && "0.7"
                    }}
                  >{`Módulo ${index + 1}:`}</h2>
                  <h2
                    style={{
                      margin: "0% 1%",
                      fontWeight: "lighter",
                      fontSize:
                        isSmallScreen && tipo.modulo.curso.titulo.length > 20
                          && "0.7"
                    }}
                  >
                    {tipo.modulo.curso.titulo}
                  </h2>
                </div>
                <div
                  style={{
                    display: "flex",
                    width: "20%",
                    alignItems: "center",
                    justifyContent: "end",
                  }}
                >
                  <h3
                    style={{
                      margin: "0 1%",
                      fontSize: isSmallScreen ? "0.8em" : "1em",
                    }}
                  >
                    {tipo.modulo.curso.playlist_items.length} vídeos
                  </h3>
                  <CustomTooltip title={tipo.modulo.curso.descricao}>
                    <InfoIcon
                      sx={{
                        color: "#BD6F57",
                      }}
                    />
                  </CustomTooltip>
                </div>
                <Typography
                  variant="body1"
                  sx={{
                    display: tipo.atividade_concluida ? "flex" : "none",
                    color: "green",
                    marginLeft: "2%",
                  }}
                  color="success"
                >
                  Concluído
                </Typography>
              </ListItemButton>
              <Collapse in={showCursos[index]} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  {tipo.videos_vistos.map((list, listIndex) =>
                    tipo.bloqueado ? (
                      <ListItemButton
                        key={listIndex}
                        disabled={tipo.bloqueado}
                        sx={{
                          background: "rgb(0,0,0,0.05)",
                          "&:hover": {
                            background: "rgb(0,0,0,0.1)",
                          },
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            width: "90%",
                            alignItems: "center",
                          }}
                        >
                          {list.visto ? (
                            <CustomTooltip title="Concluído">
                              <CheckCircleIcon
                                sx={{
                                  color: "green",
                                  marginRight: "2%",
                                  marginLeft: "2%",
                                }}
                              />
                            </CustomTooltip>
                          ) : (
                            <PlayCircleIcon
                              sx={{
                                color: "#BD6F57",
                                marginRight: "2%",
                                marginLeft: "2%",
                              }}
                            />
                          )}
                          <ListItemText
                            primary={
                              tipo.modulo.curso.playlist_items[listIndex].titulo
                            }
                            style={{
                              marginRight: isSmallScreen && "15px",
                              width: isSmallScreen && "80%",
                            }}
                          />
                          {list.pausado !== false && list.duracao !== false && (
                            <LinearProgress
                              variant="determinate"
                              value={(list.pausado / list.duracao) * 100}
                              color="primary"
                              sx={{
                                width: "10%",
                                marginRight: "2%",
                              }}
                            />
                          )}
                        </div>
                        {!isSmallScreen && (
                          <Button
                            startIcon={<PlayArrowIcon />}
                            variant="contained"
                            sx={{
                              padding: "0.6% 2%",
                              background:
                                "linear-gradient(108deg, #C4764E -14.59%, #BD6F57 36.45%, #914C90 107.21%)",
                            }}
                          >
                            {list.pausado && !list.visto
                              ? "Continuar"
                              : "Assistir"}
                          </Button>
                        )}
                      </ListItemButton>
                    ) : (
                      <Link
                        to={`/trilha/${trilha.id}/curso/${tipo.modulo.curso.id}/${list.video}`}
                        style={{ color: "black", display: ListItemButton }}
                      >
                        <ListItemButton
                          key={listIndex}
                          // disabled={tipo.bloqueado}
                          sx={{
                            background: "rgb(0,0,0,0.05)",
                            "&:hover": {
                              background: "rgb(0,0,0,0.1)",
                            },
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              width: isSmallScreen ? "100%" : "90%",
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                          >
                            {list.visto ? (
                              <CustomTooltip title="Concluído">
                                <CheckCircleIcon
                                  sx={{
                                    color: "green",
                                    marginRight: "2%",
                                    marginLeft: "2%",
                                  }}
                                />
                              </CustomTooltip>
                            ) : (
                              <PlayCircleIcon
                                sx={{
                                  color: "#BD6F57",
                                  marginRight: "2%",
                                  marginLeft: "2%",
                                }}
                              />
                            )}
                            <ListItemText
                              primary={
                                tipo.modulo.curso.playlist_items[listIndex]
                                  .titulo
                              }
                              style={{
                                marginRight: isSmallScreen && "15px",
                                width: isSmallScreen && "80%",
                              }}
                            />
                            {list.pausado !== false &&
                              list.duracao !== false && (
                                <LinearProgress
                                  variant="determinate"
                                  value={
                                    (list.visto
                                      ? 1
                                      : list.pausado / list.duracao) * 100
                                  }
                                  color="primary"
                                  sx={{
                                    width: isSmallScreen ? "20%" : "10%",
                                    marginRight: "2%",
                                  }}
                                />
                              )}
                          </div>
                          {!isSmallScreen && (
                            <Button
                              startIcon={<PlayArrowIcon />}
                              variant="contained"
                              sx={{
                                padding: "0.6% 2%",
                                background:
                                  "linear-gradient(108deg, #C4764E -14.59%, #BD6F57 36.45%, #914C90 107.21%)",
                              }}
                            >
                              {list.pausado && !list.visto
                                ? "Continuar"
                                : "Assistir"}
                            </Button>
                          )}
                        </ListItemButton>
                      </Link>
                    )
                  )}
                </List>
                {/* {console.log(tipo.modulo.curso.atividade.id)} */}
                <CustomTooltip
                  title={
                    !tipo.curso_concluido
                      ? "Conclua todos os vídeos para responder"
                      : ""
                  }
                >
                  <List component="div" disablePadding>
                    <ListItem
                      key={1}
                      sx={{
                        background: "rgb(0,0,0,0.05)",
                        "&:hover": {
                          background: "rgb(0,0,0,0.1)",
                        },
                      }}
                    >
                      <CustomTooltip
                        title={tipo.atividade_concluida ? "Concluído" : ""}
                      >
                        <AssignmentIcon
                          sx={{
                            color: tipo.atividade_concluida
                              ? "green"
                              : tipo.curso_concluido
                              ? "#BD6F57"
                              : "gray",
                            marginRight: "2%",
                            marginLeft: "2%",
                          }}
                        />
                      </CustomTooltip>

                      <ListItemText primary="Questionário" />
                      <Button
                        variant="contained"
                        sx={{
                          padding: "0.4% 1%",
                          // background:tipo.atividade_concluida? "" "C4764E",
                          // "linear-gradient(108deg, #C4764E -14.59%, #BD6F57 36.45%, #914C90 107.21%)",
                        }}
                        color={tipo.atividade_concluida ? "success" : "primary"}
                        onClick={() => {
                          if (!tipo.atividade_concluida) {
                            setOpenAtividade(true);
                            setCursoId(tipo.modulo);
                            setAtividadeIndex(index);
                          }
                        }}
                        disabled={!tipo.curso_concluido}
                      >
                        {tipo.atividade_concluida
                          ? `${tipo.nota_atividade}/10`
                          : "Responder"}
                      </Button>
                    </ListItem>
                  </List>
                </CustomTooltip>
              </Collapse>
            </div>
          ))}
        </List>
      </Container>

      <Atividade
        open={openAtividade}
        close={() =>{ setOpenAtividade(false);
          setAtualiza(true);
        }}
        curso={cursoId}
        trilhaId={trilha.id}
      />
    </ThemeProvider>
  );
}

export default PageTrilhaDeConhecimento;

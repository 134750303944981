import React from 'react'
import { Box } from '@mui/system'
import Typography from "@mui/material/Typography";
import { useDispatch, useSelector } from 'react-redux'
import Divider from "@mui/material/Divider";

import FormularioEmpresa from './FormularioEmpresa'
import FormularioAluno from './FormularioAluno'

function Formulario(){
    function getProfileType(){
        return profile.razao_social ? 'EMPRESA' : 'ALUNO'
    }

    let profile = useSelector((state) => {
        if (state && state.user && state.user.profile && state.user.profile.dataValues){
          return state.user.profile.dataValues
        }else if (state && state.user && state.user.profile){
          return state.user.profile
        }
      });

    return (
        <Box sx={{ p: 1 }}>
            {
                getProfileType() === "EMPRESA" ? <FormularioEmpresa /> : <FormularioAluno />
            }
        </Box>
    )
}

export default Formulario;
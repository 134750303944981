import React, { useEffect } from 'react'
import L from 'leaflet'
import checked from '../../assets/mapIcons/Checked.png'
import notChecked from '../../assets/mapIcons/NotChecked.png'

//Importando ícones do mapa
//Aceleradoras ícone Faltante
import aceleradoras from '../../assets/mapIcons/Aceleradoras.png'
import agenciasFomento from '../../assets/mapIcons/Agencias de fomento.png'
import corporateVenture from '../../assets/mapIcons/Corporate Venture.png'
import coworking from '../../assets/mapIcons/Coworking.png'
import economiaCriativa from '../../assets/mapIcons/Empresa de Economia Criativa.png'
import empresaJunior from '../../assets/mapIcons/Empresa júnior.png'
import empresaBaseTecnologica from '../../assets/mapIcons/Empresas de Base Tecnológica.png'
import empresaInvestimento from '../../assets/mapIcons/Empresas de investimento.png'
import governo from '../../assets/mapIcons/Governo.png'
import iCTs from '../../assets/mapIcons/ICTs (Institutos de Ciência e Tecnologia).png'
import incubadoras from '../../assets/mapIcons/Incubadoras.png'
import markerSpace from '../../assets/mapIcons/Maker Space.png'
import outros from '../../assets/mapIcons/Outros.png'
import polosTecnologia from '../../assets/mapIcons/Polos de Tecnologia.png'
import propriedadeIndustrial from '../../assets/mapIcons/Propriedade Industrial.png'
import startups from '../../assets/mapIcons/Startups.png'

import api from '../../services/api'
import { mapaRequest, mapaOut } from '../../store/modules/auth/actions'
import { useDispatch } from 'react-redux'

function MapChart(props) {
  const dispatch = useDispatch()

  const whenLoading = async () => {
    dispatch(mapaRequest())
  }
  const whenLoaded = async () => {
    dispatch(mapaOut())
  }
  useEffect(() => {
    whenLoading()
    api.get('mapa/data').then(response => {
      whenLoaded()
      const data = response.data

      let mbAttr =
        'Dados do Mapa &copy; <a href="/">Oxetech Alagoas</a>, Direitos © <a href="http://secti.al.gov.br" target="_blank">SECTI</a> | <a href="https://www.laccan.ufal.br" target="_blank">LaCCAN</a> | <a href="http://ufal.br" target="_blank">UFAL</a>'
      var mbUrl =
        'https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoibWFwYm94IiwiYSI6ImNpejY4NXVycTA2emYycXBndHRqcmZ3N3gifQ.rJcFIG214AriISLbB6B5aw'
      var grayscale = L.tileLayer(mbUrl, {
        id: 'mapbox/light-v9',
        tileSize: 512,
        zoomOffset: -1,
        attribution: mbAttr
      })

      var mainLayer = L.tileLayer(mbUrl, {
        id: 'mapbox/streets-v11',
        tileSize: 512,
        zoomOffset: -1,
        attribution: mbAttr
      })

      let roadsLayer = L.tileLayer('http://{s}.tile.osm.org/{z}/{x}/{y}.png', {
        attribution: mbAttr
      })

      //criando o mapa
      const mapchart = L.map('mapchart', {
        center: center,
        zoom: 9,
        layers: [mainLayer, roadsLayer]
      })

      var baseLayers = {
        Rodovias: roadsLayer,
        Principal: mainLayer,
        'Escala Cinza': grayscale
      }

      //Definindo tamanho unificado
      let unifiedIconSize = [35, 35]

      //criando Icones leaflet
      let iconAceleradoras = L.icon({
        iconUrl: aceleradoras,
        iconSize: unifiedIconSize
      })
      let iconAgenciasFomento = L.icon({
        iconUrl: agenciasFomento,
        iconSize: unifiedIconSize
      })
      let iconCorporateVenture = L.icon({
        iconUrl: corporateVenture,
        iconSize: unifiedIconSize
      })
      let iconCoworking = L.icon({
        iconUrl: coworking,
        iconSize: unifiedIconSize
      })
      let iconEconomiaCriativa = L.icon({
        iconUrl: economiaCriativa,
        iconSize: unifiedIconSize
      })
      let iconEmpresaJunior = L.icon({
        iconUrl: empresaJunior,
        iconSize: unifiedIconSize
      })
      let iconEmpresaBaseTecnologica = L.icon({
        iconUrl: empresaBaseTecnologica,
        iconSize: unifiedIconSize
      })
      let iconEmpresaInvestimento = L.icon({
        iconUrl: empresaInvestimento,
        iconSize: unifiedIconSize
      })
      let iconGoverno = L.icon({
        iconUrl: governo,
        iconSize: unifiedIconSize
      })
      let iconICTs = L.icon({
        iconUrl: iCTs,
        iconSize: unifiedIconSize
      })
      let iconIncubadoras = L.icon({
        iconUrl: incubadoras,
        iconSize: unifiedIconSize
      })
      let iconMarkerSpace = L.icon({
        iconUrl: markerSpace,
        iconSize: unifiedIconSize
      })
      let iconOutros = L.icon({
        iconUrl: outros,
        iconSize: unifiedIconSize
      })
      let iconPolosTecnologia = L.icon({
        iconUrl: polosTecnologia,
        iconSize: unifiedIconSize
      })
      let iconPropriedadeIndustrial = L.icon({
        iconUrl: propriedadeIndustrial,
        iconSize: unifiedIconSize
      })
      let iconStartups = L.icon({
        iconUrl: startups,
        iconSize: unifiedIconSize
      })

      //criando arrays de marcadores
      let markerArrayAceleradoras = []
      let markerArrayAgenciasFomento = []
      let markerArrayCorporateVenture = []
      let markerArrayCoworking = []
      let markerArrayEmpresaEconomiaCriativa = []
      let markerArrayEmpresaJunior = []
      let markerArrayEmpresaBaseTecnologica = []
      let markerArrayEmpresaInvestimento = []
      let markerArrayGoverno = []
      let markerArrayICTs = []
      let markerArrayIncubadoras = []
      let markerArrayMarkerSpace = []
      let markerArrayOutros = []
      let markerArrayPolosTecnologia = []
      let markerArrayPropriedadeIndustrial = []
      let markerArrayStartups = []

      //adicionando marcadores referentes a cada instituição aos marker arrays
      for (let i in data) {
        let element = data[i]
        let tooltipOptions = { direction: 'top', offset: [2, 0] }

        let markerAceleradora
        let markerAgenciasFomento
        let markerCorporateVenture
        let markerCoworking
        let markerEmpresaEconomiaCriativa
        let markerEmpresaJunior
        let markerEmpresaBaseTecnologica
        let markerEmpresaInvestimento
        let markerGoverno
        let markerICTs
        let markerIncubadoras
        let markerMarkerSpace
        let markerOutros
        let markerPolosTecnologia
        let markerPropriedadeIndustrial
        let markerStartups

        let tooltipContent = `${element.razao_social}</br>
        PROPRIEDADE INTELECTUAL
        ${
          element.tem_propriedade_intelectual
            ? `<img src=' ${checked}' height='20px'/>`
            : `<img src=' ${notChecked}' height='20px'/>`
        }`

        //Pegando dados do banco e gerando grupos de marcadores
        if (element.latitude !== null && element.longitude !== null) {
          if (element.categoria === null) {
            markerOutros = L.marker(
              [parseFloat(element.latitude), parseFloat(element.longitude)],
              { icon: iconOutros }
            )

            markerOutros.bindTooltip(element.razao_social, tooltipOptions)
            markerArrayOutros.push(markerOutros)
          } else if (element.tem_propriedade_intelectual) {
            markerPropriedadeIndustrial = L.marker(
              [parseFloat(element.latitude), parseFloat(element.longitude)],
              { icon: iconPropriedadeIndustrial }
            )
            markerPropriedadeIndustrial.bindTooltip(
              tooltipContent,
              tooltipOptions
            )
            markerArrayPropriedadeIndustrial.push(markerPropriedadeIndustrial)
          } else {
            element.categoria.map((category, key) => {
              switch (category) {
                case 'Aceleradora':
                  markerAceleradora = L.marker(
                    [
                      parseFloat(element.latitude),
                      parseFloat(element.longitude)
                    ],
                    { icon: iconAceleradoras }
                  )

                  markerAceleradora.bindTooltip(tooltipContent, tooltipOptions)

                  markerArrayAceleradoras.push(markerAceleradora)
                  break
                case 'Agencia de Fomento':
                  markerAgenciasFomento = L.marker(
                    [
                      parseFloat(element.latitude),
                      parseFloat(element.longitude)
                    ],
                    { icon: iconAgenciasFomento }
                  )

                  markerAgenciasFomento.bindTooltip(
                    tooltipContent,
                    tooltipOptions
                  )
                  markerArrayAgenciasFomento.push(markerAgenciasFomento)
                  break
                case 'Corporate Venture':
                  markerCorporateVenture = L.marker(
                    [
                      parseFloat(element.latitude),
                      parseFloat(element.longitude)
                    ],
                    { icon: iconCorporateVenture }
                  )

                  markerCorporateVenture.bindTooltip(
                    tooltipContent,
                    tooltipOptions
                  )
                  markerArrayCorporateVenture.push(markerCorporateVenture)
                  break
                case 'Coworking':
                  markerCoworking = L.marker(
                    [
                      parseFloat(element.latitude),
                      parseFloat(element.longitude)
                    ],
                    { icon: iconCoworking }
                  )
                  markerCoworking.bindTooltip(tooltipContent, tooltipOptions)
                  markerArrayCoworking.push(markerCoworking)

                  break
                case 'Empresa de Economia Criativa':
                  markerEmpresaEconomiaCriativa = L.marker(
                    [
                      parseFloat(element.latitude),
                      parseFloat(element.longitude)
                    ],
                    { icon: iconEconomiaCriativa }
                  )
                  markerEmpresaEconomiaCriativa.bindTooltip(
                    tooltipContent,
                    tooltipOptions
                  )
                  markerArrayEmpresaEconomiaCriativa.push(
                    markerEmpresaEconomiaCriativa
                  )

                  break
                case 'Empresa júnior':
                  markerEmpresaJunior = L.marker(
                    [
                      parseFloat(element.latitude),
                      parseFloat(element.longitude)
                    ],
                    { icon: iconEmpresaJunior }
                  )
                  markerEmpresaJunior.bindTooltip(
                    tooltipContent,
                    tooltipOptions
                  )
                  markerArrayEmpresaJunior.push(markerEmpresaJunior)

                  break
                case 'Empresa de Base Tecnológica':
                  markerEmpresaBaseTecnologica = L.marker(
                    [
                      parseFloat(element.latitude),
                      parseFloat(element.longitude)
                    ],
                    { icon: iconEmpresaBaseTecnologica }
                  )
                  markerEmpresaBaseTecnologica.bindTooltip(
                    tooltipContent,
                    tooltipOptions
                  )
                  markerArrayEmpresaBaseTecnologica.push(
                    markerEmpresaBaseTecnologica
                  )

                  break
                case 'Empresa de Investimento':
                  markerEmpresaInvestimento = L.marker(
                    [
                      parseFloat(element.latitude),
                      parseFloat(element.longitude)
                    ],
                    { icon: iconEmpresaInvestimento }
                  )
                  markerEmpresaInvestimento.bindTooltip(
                    tooltipContent,
                    tooltipOptions
                  )
                  markerArrayEmpresaInvestimento.push(markerEmpresaInvestimento)
                  break
                case 'Governo':
                  markerGoverno = L.marker(
                    [
                      parseFloat(element.latitude),
                      parseFloat(element.longitude)
                    ],
                    { icon: iconGoverno }
                  )
                  markerGoverno.bindTooltip(tooltipContent, tooltipOptions)
                  markerArrayGoverno.push(markerGoverno)

                  break
                case 'ICTs(Institutos de Ciência e Tecnologia)':
                  markerICTs = L.marker(
                    [
                      parseFloat(element.latitude),
                      parseFloat(element.longitude)
                    ],
                    { icon: iconICTs }
                  )
                  markerICTs.bindTooltip(tooltipContent, tooltipOptions)
                  markerArrayICTs.push(markerICTs)

                  break
                case 'Incubadoras':
                  markerIncubadoras = L.marker(
                    [
                      parseFloat(element.latitude),
                      parseFloat(element.longitude)
                    ],
                    { icon: iconIncubadoras }
                  )
                  markerIncubadoras.bindTooltip(tooltipContent, tooltipOptions)
                  markerArrayIncubadoras.push(markerIncubadoras)

                  break
                case 'Marker Space':
                  markerMarkerSpace = L.marker(
                    [
                      parseFloat(element.latitude),
                      parseFloat(element.longitude)
                    ],
                    { icon: iconMarkerSpace }
                  )
                  markerMarkerSpace.bindTooltip(tooltipContent, tooltipOptions)
                  markerArrayMarkerSpace.push(markerMarkerSpace)

                  break
                case 'Outro':
                  markerOutros = L.marker(
                    [
                      parseFloat(element.latitude),
                      parseFloat(element.longitude)
                    ],
                    { icon: iconOutros }
                  )
                  markerOutros.bindTooltip(tooltipContent, tooltipOptions)
                  markerArrayOutros.push(markerOutros)

                  break
                case 'Polos de Tecnologia':
                  markerPolosTecnologia = L.marker(
                    [
                      parseFloat(element.latitude),
                      parseFloat(element.longitude)
                    ],
                    { icon: iconPolosTecnologia }
                  )
                  markerPolosTecnologia.bindTooltip(
                    tooltipContent,
                    tooltipOptions
                  )
                  markerArrayPolosTecnologia.push(markerPolosTecnologia)
                  break
                case 'Startup':
                  markerStartups = L.marker(
                    [
                      parseFloat(element.latitude),
                      parseFloat(element.longitude)
                    ],
                    { icon: iconStartups }
                  )
                  markerStartups.bindTooltip(tooltipContent, tooltipOptions)
                  markerArrayStartups.push(markerStartups)
                  break
                default:
                  break
              }
            })
          }
        }
      }

      var overlays = {}

      //adicionando layers com os marcadores ao mapa
      if (markerArrayAceleradoras) {
        var mapMarkersLayerAceleradoras = L.layerGroup(
          markerArrayAceleradoras
        ).addTo(mapchart)
        overlays['Aceleradoras'] = mapMarkersLayerAceleradoras
      }

      if (markerArrayAgenciasFomento) {
        var mapMarkersLayerAgenciasFomento = L.layerGroup(
          markerArrayAgenciasFomento
        ).addTo(mapchart)
        overlays['Agências de Fomento'] = mapMarkersLayerAgenciasFomento
      }

      if (markerArrayCorporateVenture) {
        var mapMarkersLayerCorporateVenture = L.layerGroup(
          markerArrayCorporateVenture
        ).addTo(mapchart)
        overlays['Corporate Venture'] = mapMarkersLayerCorporateVenture
      }

      if (markerArrayCoworking) {
        var mapMarkersLayerCoworking =
          L.layerGroup(markerArrayCoworking).addTo(mapchart)
        overlays['Coworking'] = mapMarkersLayerCoworking
      }
      if (markerArrayEmpresaEconomiaCriativa) {
        var mapMarkersLayerEmpresaEconomiaCriativa = L.layerGroup(
          markerArrayEmpresaEconomiaCriativa
        ).addTo(mapchart)
        overlays['Empresa de Economia Criativa'] =
          mapMarkersLayerEmpresaEconomiaCriativa
      }
      if (markerArrayEmpresaJunior) {
        var mapMarkersLayerEmpresaJunior = L.layerGroup(
          markerArrayEmpresaJunior
        ).addTo(mapchart)
        overlays['Empresa Júnior'] = mapMarkersLayerEmpresaJunior
      }
      if (markerArrayEmpresaBaseTecnologica) {
        var mapMarkersLayerEmpresaBaseTecnologica = L.layerGroup(
          markerArrayEmpresaBaseTecnologica
        ).addTo(mapchart)
        overlays['Empresa de Base Tecnológica'] =
          mapMarkersLayerEmpresaBaseTecnologica
      }

      if (markerArrayEmpresaInvestimento) {
        var mapMarkersLayerEmpresaInvestimento = L.layerGroup(
          markerArrayEmpresaInvestimento
        ).addTo(mapchart)
        overlays['Empresa de Investimento'] = mapMarkersLayerEmpresaInvestimento
      }
      if (markerArrayGoverno) {
        var mapMarkersLayerGoverno =
          L.layerGroup(markerArrayGoverno).addTo(mapchart)
        overlays['Governo'] = mapMarkersLayerGoverno
      }
      if (markerArrayICTs) {
        var mapMarkersLayerICTs = L.layerGroup(markerArrayICTs).addTo(mapchart)
        overlays['ICT(Institutos de Ciência e Tecnologia)'] =
          mapMarkersLayerICTs
      }
      if (markerArrayIncubadoras) {
        var mapMarkersLayerIncubadoras = L.layerGroup(
          markerArrayIncubadoras
        ).addTo(mapchart)
        overlays['Incubadora'] = mapMarkersLayerIncubadoras
      }
      if (markerArrayMarkerSpace) {
        var mapMarkersLayerMarkerSpace = L.layerGroup(
          markerArrayMarkerSpace
        ).addTo(mapchart)
        overlays['Marker Space'] = mapMarkersLayerMarkerSpace
      }
      if (markerArrayPolosTecnologia) {
        var mapMarkersLayerPolosTecnologia = L.layerGroup(
          markerArrayPolosTecnologia
        ).addTo(mapchart)
        overlays['Polo de Tecnologia'] = mapMarkersLayerPolosTecnologia
      }
      if (markerArrayPropriedadeIndustrial) {
        var mapMarkersLayerPropriedadeIndustrial = L.layerGroup(
          markerArrayPropriedadeIndustrial
        ).addTo(mapchart)
        overlays['Propriedade Industrial'] =
          mapMarkersLayerPropriedadeIndustrial
      }
      if (markerArrayStartups) {
        var mapMarkersLayerStartups =
          L.layerGroup(markerArrayStartups).addTo(mapchart)
        overlays['Startup'] = mapMarkersLayerStartups
      }
      if (markerArrayOutros) {
        var mapMarkersLayerOutros =
          L.layerGroup(markerArrayOutros).addTo(mapchart)
        overlays['Outros'] = mapMarkersLayerOutros
      }

      L.control.layers(baseLayers, overlays).addTo(mapchart)

      //corrigindo zoom dos marcadores
      mapchart.on('zoomend', () => {
        //recebendo zoom atual
        let currentZoom = mapchart.getZoom() / 2
        //calculando novo tamanho
        let localZoom = [
          unifiedIconSize[0] - 2 + currentZoom,
          unifiedIconSize[1] - 2 + currentZoom
        ]

        let newIconAceleradoras = new L.icon({
          iconUrl: aceleradoras,
          iconSize: localZoom
        })
        mapMarkersLayerAceleradoras.eachLayer(function (layer) {
          layer.setIcon(newIconAceleradoras)
        })

        let newIconAgenciasFomento = new L.icon({
          iconUrl: agenciasFomento,
          iconSize: localZoom
        })
        mapMarkersLayerAgenciasFomento.eachLayer(function (layer) {
          layer.setIcon(newIconAgenciasFomento)
        })

        let newIconCorporateVenture = new L.icon({
          iconUrl: corporateVenture,
          iconSize: localZoom
        })
        mapMarkersLayerCorporateVenture.eachLayer(function (layer) {
          layer.setIcon(newIconCorporateVenture)
        })

        let newIconCoworking = new L.icon({
          iconUrl: coworking,
          iconSize: localZoom
        })
        mapMarkersLayerCoworking.eachLayer(function (layer) {
          layer.setIcon(newIconCoworking)
        })

        let newIconEconomiaCriativa = new L.icon({
          iconUrl: economiaCriativa,
          iconSize: localZoom
        })
        mapMarkersLayerEmpresaEconomiaCriativa.eachLayer(function (layer) {
          layer.setIcon(newIconEconomiaCriativa)
        })

        let newIconEmpresaJunior = new L.icon({
          iconUrl: empresaJunior,
          iconSize: localZoom
        })
        mapMarkersLayerEmpresaJunior.eachLayer(function (layer) {
          layer.setIcon(newIconEmpresaJunior)
        })

        let newIconEmpresaBaseTecnologica = new L.icon({
          iconUrl: empresaBaseTecnologica,
          iconSize: localZoom
        })
        mapMarkersLayerEmpresaBaseTecnologica.eachLayer(function (layer) {
          layer.setIcon(newIconEmpresaBaseTecnologica)
        })

        let newIconEmpresaInvestimento = new L.icon({
          iconUrl: empresaInvestimento,
          iconSize: localZoom
        })
        mapMarkersLayerEmpresaInvestimento.eachLayer(function (layer) {
          layer.setIcon(newIconEmpresaInvestimento)
        })

        let newIconGoverno = new L.icon({
          iconUrl: governo,
          iconSize: localZoom
        })
        mapMarkersLayerGoverno.eachLayer(function (layer) {
          layer.setIcon(newIconGoverno)
        })

        let newIconICTs = new L.icon({
          iconUrl: iCTs,
          iconSize: localZoom
        })
        mapMarkersLayerICTs.eachLayer(function (layer) {
          layer.setIcon(newIconICTs)
        })

        let newIconIncubadoras = new L.icon({
          iconUrl: incubadoras,
          iconSize: localZoom
        })
        mapMarkersLayerIncubadoras.eachLayer(function (layer) {
          layer.setIcon(newIconIncubadoras)
        })

        let newIconMarkerSpace = new L.icon({
          iconUrl: markerSpace,
          iconSize: localZoom
        })
        mapMarkersLayerMarkerSpace.eachLayer(function (layer) {
          layer.setIcon(newIconMarkerSpace)
        })

        let newIconOutros = new L.icon({
          iconUrl: outros,
          iconSize: localZoom
        })
        mapMarkersLayerOutros.eachLayer(function (layer) {
          layer.setIcon(newIconOutros)
        })

        let newIconPolosTecnologia = new L.icon({
          iconUrl: polosTecnologia,
          iconSize: localZoom
        })
        mapMarkersLayerPolosTecnologia.eachLayer(function (layer) {
          layer.setIcon(newIconPolosTecnologia)
        })

        let newIconPropriedadeIndustrial = new L.icon({
          iconUrl: propriedadeIndustrial,
          iconSize: localZoom
        })
        mapMarkersLayerPropriedadeIndustrial.eachLayer(function (layer) {
          layer.setIcon(newIconPropriedadeIndustrial)
        })
        let newIconStartups = new L.icon({
          iconUrl: startups,
          iconSize: localZoom
        })
        mapMarkersLayerStartups.eachLayer(function (layer) {
          layer.setIcon(newIconStartups)
        })

        //markerEmpresaEconomiaCriativa.setIcon(iconEconomiaCriativa);
      })
    })
  }, [])

  const center = [-9.571306, -36.78195]

  return (
    <>
      <div
        id="mapchart"
        style={{ height: '100vh', width: '100vw', display: 'block' }}
      ></div>
    </>
  )
}

export default MapChart

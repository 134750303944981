import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';

function Modal({ open, handleClose, perguntas, respostas}) {
  return (  
    <Dialog open={open} onClose={handleClose}  >
       {perguntas.map((pergunta, index) => (
         <React.Fragment key={index}>
          <DialogTitle>{pergunta}</DialogTitle>
          <DialogContent>
            <p>{respostas[index]}</p>
          </DialogContent>
          </React.Fragment>
      ))}
      <DialogActions>
        <Button onClick={handleClose} 
        sx={{ backgroundColor: '#D46D0D', color: 'white', '&:hover': { backgroundColor: 'rgba(212, 109, 13, 0.45)' } }}
        >Fechar</Button>
      </DialogActions>
    </Dialog>
  );
}

export default Modal;
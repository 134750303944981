import React, { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CircularProgress from "@mui/material/CircularProgress";
import { Grid, Box, Typography, Paper, useMediaQuery } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import * as palette from "../../utils/styledVariables";

const theme = createTheme({
  palette: {
    primary: {
      main: palette.laranjaOXLTransparente,
    },
    secondary: {
      main: "#8E24AA",
    },
  },
});

const styles = {
  paper: {
    margin: "10px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
};

const DualColorCircularProgress = (props) => {
  const { value } = props;
  const numberValue = parseFloat(value) * 100;
  const isSmallScreen = useMediaQuery("(max-width:600px)");

  return (
    <ThemeProvider theme={theme}>
      <Box position="relative" display="inline-flex">
        <CircularProgress
          variant="determinate"
          value={100}
          size={isSmallScreen ? "80px" : "100px"}
          thickness={4}
          color="primary"
        />
        <CircularProgress
          variant="determinate"
          value={numberValue}
          size={isSmallScreen ? "80px" : "100px"}
          thickness={4}
          color="secondary"
          sx={{
            position: "absolute",
            left: 0,
          }}
        />
        <Box
          top={0}
          left={0}
          bottom={0}
          right={0}
          display="flex"
          alignItems="center"
          justifyContent="center"
          position="absolute"
        >
          <Typography variant="h6" component="div" color="textSecondary">
            {`${numberValue}%`}
          </Typography>
        </Box>
      </Box>
    </ThemeProvider>
  );
};

const CardDesempenho = (props) => {
  const { partialAverage, percentage } = props;
  const isSmallScreen = useMediaQuery("(max-width:600px)");

  return (
    <Card sx={{ backgroundColor: palette.laranjaOXLTransparente}}>
      <CardContent
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          paddingLeft: "10px",
          marginLeft: isSmallScreen ? "10px" : "20px",
          height: isSmallScreen ? "50px" : "22.7vh",
        }}
      >
        <Grid container>
          <Grid
            item
            xs={12}
            sm={6}
            md={6}
            lg={6}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              padding: isSmallScreen ? "0px" : "0px",
            }}
          >
            <Paper
              sx={[
                {
                  backgroundColor: palette.laranjaOXLTransparente,
                  height: "50px",
                  width: isSmallScreen ? "100%" : "10vw",
                },
                styles.paper,
              ]}
            >
              <Typography
                sx={{
                  fontSize: "16px",
                  color: palette.preto,
                  fontFamily: "Poppins",
                  fontWeight: 500,
                }}
              >
                Média Geral: {partialAverage}
              </Typography>
            </Paper>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={6}
            lg={6}
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div style={{ marginTop: "12px" }}>
              <DualColorCircularProgress value={percentage} />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "6px",
              }}
            >
              <Typography style={{ fontSize: "12px" }}>
                Taxa de Aprovação
              </Typography>
            </div>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default CardDesempenho;

import styled from 'styled-components'
import { Tabs, Typography, Select, Button } from 'antd';
import * as palette from "../../../../utils/styledVariables"



export const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  background: #fff; 
  height: 100%;
  min-height: 100vh;
  .card{
    display: flex;
    justify-content: center;
    align-items: center;
  }

`
export const SelectButton = styled((props) => (
  <Button
    {...props}
    sx={{  bgcolor: palette.laranjaIntermediario2,
      "&:hover": {
        bgcolor: palette.laranjaSecundario
    },
     
    }}
    variant="contained"
  />
))({
  height: "30px",
  margin: "2px",
  float: "right",
});
export const Titulo = styled(Typography)`
  font-family: 'Poppins', sans-serif;
  padding-top: 3%;
  font-size: 2rem;
  font-weight: 400;
  text-align: center;
`

export const Seletor = styled(Select)`
  height: 100%;
  width: 30%;
`
export const Logo = styled.div`
  width: 100%;
  padding: 1%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  .img {
    width: 30%;
    
    @media (max-width: 700px) {
      width: 50%;
    }
  }
  .subtitulo {
    font-family: 'Karla', sans-serif;
    color: black;
    font-size: 1.2rem;
    font-weight: bold;
    text-align: center;
  }
`

export const TabsCursos = styled(Tabs)`
  .ant-tabs-tab-btn{
    color: rgba(0, 0, 0, 0.77);
    font-family: 'Karla', sans-serif;
    font-weight: 700;
    font-size: 1.1em;
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
   color: rgba(212, 109, 13, 1) !important; 
   font-weight: 700;
  }
  .ant-tabs-ink-bar {
   position: absolute;
   background: rgba(212, 109, 13, 1);
   pointer-events: none;
  }
  .ant-tabs-nav-wrap{
    border: 1px solid white !important;
  }

`
import { Alert, Box, CssBaseline, Snackbar } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Buscador from "../../components/Buscador";
import CancelButton from "../../components/CancelButton";
import TableCard from "../../components/DashUserAdm/TableCard";
import ModalConfirmacao from "../../components/ModalConfirmacao";
import SidebarAdmin from "../../components/SidebarAdmin";
import api from "../../services/api";
import * as palette from "../../utils/styledVariables";
import { Container } from "./styles";
import { useAuth } from "../../hooks/auth/AuthContext";


function GerenciamentoAdmin() {
  const { sudoUser } = useAuth();
  const profile = useSelector((state) => state.user.profile);
  const [dadosAdmins, setDadosAdmins] = useState([]);
  const [sidebarCollapsed, setSidebarCollapsed] = useState(false);
  const [openModalCriacao, setOpenModalCriacao] = useState(false);
  const [openModalRemocao, setOpenModalRemocao] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  const onMobile = width <= 600;
  const [newAdmin, setNewAdmin] = useState(null);
  const [pessoasFisicas, setPessoasFisicas] = useState([]);
  const [alert, setAlert] = useState("");
  const [error, setError] = useState(false);

  const getAdminData = async () => {
    try {
      const res = await api.get("aluno/admins");
      setDadosAdmins(res.data);
    } catch (error) {
      setError(true);
      setAlert(error.message.data.error);
    }
  };

  const getAlunosData = async () => {
    try {
      const resAlunos = await api.get("alunos");
   
      const resAdmins = await api.get("aluno/admins");
  
      const resSudos = await api.get("aluno/sudos");
      
      const dadosAdmins = resAdmins.data;
      const dadosSudos = resSudos.data;
  
      const filteredAlunos = resAlunos.data.filter(aluno => {
        return aluno.id !== profile.id && !dadosAdmins.find(admin => admin.id === aluno.id) && !dadosSudos.find(sudo => sudo.id === aluno.id);
      });
  
      const filteredPF = filteredAlunos.map((pf) => {
        return { name: pf.name, label: pf.cpf, value: pf.id };
      });
      setPessoasFisicas(filteredPF);
    } catch (error) {
      setError(true);
      setAlert(error.message.data.error);
    }
  };
  
  const handleSelect = (e, newValue) => {
    if (newValue !== null) {
      setNewAdmin(newValue);
      setOpenModalCriacao(true);
    }
  };

  useEffect(async () => {
    getAdminData();
    getAlunosData();
  }, []);

  const handleCreateAdmin = async () => {
    try {
      const res = await api.patch(`aluno/admins/${newAdmin.value}`);
      getAdminData();
      setOpenModalCriacao(false);
      setAlert(res.data.message);
    } catch (error) {
      setError(true);
      setAlert(error.message.data.error);
    } finally {
      setNewAdmin(null);
    }
  };

  const handleRemoveAdmin = async () => {
    try {
      const res = await api.patch(`aluno/admins/${newAdmin.id}/remove`);
      getAdminData();
      setOpenModalRemocao(false);
      setAlert(res.data.message);
    } catch (error) {
      setError(true);
      setAlert(error.message.data.error);
    } finally {
      setNewAdmin(null);
    }
  };

  const handleCloseAlert = () => {
    setAlert("");
    setError(false);
  };

  const removeAdminButton = (params) => {
    return (
      <CancelButton
        onClick={() => {
          setNewAdmin(params.row);
          setOpenModalRemocao(true);
        }}
        defaultText={"Habilitado"}
        cancelText={"Remover"}
      />
    );
  };

  const buscador = (
    <Buscador
      label={"Adicionar novo administrador (Busca por CPF)"}
      onSelect={handleSelect}
      multiple={false}
      size={"small"}
      sx={{
        width: "500px",
        float: "right",
        color: palette.profileWhiteShade,
      }}
      data={pessoasFisicas}
    />
  );

  const table = {
    name: "Administradores",
    columns: [
      {
        field: "status",
        headerName: "Status",
        width: 150,
        renderCell: removeAdminButton,
      },
      { field: "name", headerName: "Nome", width: 350 },
      { field: "cpf", headerName: "CPF", width: 150 },
    ],
    rows: dadosAdmins,
    buttonsBar: buscador,
    statusOptions: false,
    changeStatus: "",
  };

  return (
    <Container>
      {sudoUser && (
        <Box sx={{ display: "flex", zIndex: 2 }}>
          <CssBaseline />

          <Box
            sx={{
              display: "flex",
              backgroundColor: palette.roxoMedio,
              flexGrow: 1,
            }}
          >
            <SidebarAdmin
              onMobile={onMobile}
              collapsed={sidebarCollapsed}
              setCollapsed={setSidebarCollapsed}
            />
            <Box
              sx={{ display: "flex", backgroundColor: "#FFFFF", flexGrow: 1 }}
            >
              <ModalConfirmacao
                id="confirmacao-criacao"
                open={openModalCriacao}
                text={`Confirma a adição de ${newAdmin?.name}, CPF ${newAdmin?.label}, como administrador?`}
                handleChange={handleCreateAdmin}
                handleRevert={() => setOpenModalCriacao(false)}
              />
              <ModalConfirmacao
                id="confirmacao-remocao"
                open={openModalRemocao}
                text={`Confirma a remoção de ${newAdmin?.name}, CPF ${newAdmin?.cpf}, dos administradores?`}
                handleChange={handleRemoveAdmin}
                handleRevert={() => setOpenModalRemocao(false)}
              />
              <Box
                component="main"
                sx={{
                  backgroundColor: "#FFFFFF",
                  flexGrow: 2,
                  p: 1,
                  width: { xs: `calc(100% - 20vh)` },
                  marginLeft: onMobile || sidebarCollapsed ? "auto" : "240px",
                }}
              >
                <TableCard props={table} />
              </Box>
            </Box>
          </Box>
        </Box>
      )}
      <Snackbar
        open={alert.length > 0}
        autoHideDuration={6000}
        onClose={handleCloseAlert}
      >
        <Alert
          variant="filled"
          onClose={handleCloseAlert}
          severity={error ? "error" : "success"}
          sx={{ width: "100%" }}
        >
          {alert}
        </Alert>
      </Snackbar>
    </Container>
  );
}

export default GerenciamentoAdmin;

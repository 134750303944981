import React, { useEffect, useState } from "react";
import {
  Modal,
  Paper,
  Grid,
  Box,
  Typography,
  useMediaQuery,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import CardDesempenho from "../CardDesempenho";
import CardRanking from "../CardRanking";
import LineChartCard from "../lineChart";
import * as palette from "../../../utils/styledVariables";

const styles = {
  modal: {
    width: "90%",
    maxWidth: "800px",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "#f2f2f2",
    padding: "10px",
    textAlign: "center",
    borderRadius: "1vh",
    justifyContent: "center",
    display: "flex",
  },
  paper: {
    marginBottom: "5px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
};

export const ModalDesempenhoTrilha = ({ open, close, performanceTrail }) => {
  const isSmallScreen = useMediaQuery("(max-width:600px)");
  const [partialAverage, setPartialAverage] = useState(false);
  const [percentage, setPercentage] = useState(false);
  const [mediaModulos, setMediaModulos] = useState([]);
  const [ranking, setRanking] = useState([]);
  const handleClose = () => close();

  useEffect(() => {
    if (performanceTrail) {
      setPartialAverage(performanceTrail.mediaGeral);
      setPercentage(performanceTrail.porcentagemDeAprovados);
      setMediaModulos(performanceTrail.mediaParcialPorAtividade);
      setRanking(performanceTrail.ranking);
    }
  }, [performanceTrail]);

  const dataPoints = mediaModulos
    ? mediaModulos.map((media, index) => {
        const cores = [
          "blue",
          "purple",
          "red",
          "green",
          "orange",
          "pink",
          "cyan",
          "gray",
        ];

        const cor = cores[index % cores.length];

        return { x: `Módulo ${index + 1}`, y: media, color: cor };
      })
    : [];

  return (
    <Modal open={open} onClose={close}>
      <ClickAwayListener onClickAway={handleClose}>
        <Box>
          <IconButton
            size="large"
            sx={{ display: "flex", float: "right" }}
            component="label"
            onClick={handleClose}
          >
            <CloseIcon
              fontSize="large"
              sx={{ color: palette.profileWhiteShade }}
            />
          </IconButton>

          <Grid sx={styles.modal} container direction="row">
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Paper
                sx={{
                  backgroundColor: palette.laranjaOXLTransparente,
                  ...styles.paper,
                }}
              >
                <Typography
                  sx={{
                    fontSize: isSmallScreen ? "2.0vh" : "3.0vh",
                    color: palette.preto,
                    fontFamily: "Poppins",
                    fontWeight: "500",
                  }}
                >
                  Análise de Trilha
                </Typography>
              </Paper>
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Box style={{ marginBottom: "10px" }}>
                <CardDesempenho
                  partialAverage={partialAverage}
                  percentage={percentage ? percentage : 0}
                />
              </Box>
              <Box style={{ marginBottom: "10px" }}>
                <LineChartCard dataPoints={dataPoints} />
              </Box>
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Box sx={{ width: "98%", marginLeft: "7px" }}>
                <CardRanking ranking={ranking} />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </ClickAwayListener>
    </Modal>
  );
};

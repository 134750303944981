const Levels = {
    skillLevels: {
        '1': 'Noções básicas',
        '2': 'Iniciante',
        '3': 'Intermédiario',
        '4': 'Avançado',
        '5': 'Especialista'
    },
    languageLevels:
    {
        '1': 'Noções básicas',
        '2': 'Iniciante',
        '3': 'Intermédiario',
        '4': 'Avançado',
        '5': 'Fluente'
    },
    experienceLevels: {
        '0': 'Sem experiência',
        '1': '1 ano',
        '2': '2 anos',
        '3': '3 anos',
        '4': '4 anos',
        '5': '5 anos ou mais'
    }

}

export default Levels;
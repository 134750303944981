import AddCircleIcon from "@mui/icons-material/AddCircle";
import ErrorIcon from "@mui/icons-material/Error";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import api from "../../../services/api";
import CardTurmas from "./CardTurmas";
import ModalCadastro from "./Modais/ModalCadastroTurma";
import { TabsCursos } from "./styles";
import { useLab } from "../../../hooks/useLab";

const theme = createTheme({
  palette: {
    primary: {
      main: "rgba(212, 109, 13, 0.5)",
      darker: "rgba(212, 109, 13, 1)",
    },
  },
});

function ProfileAluno() {
  const [labsAndTurmas, setLabsAndTurmas] = useState(null);
  const [turmas, setTurmas] = useState([]);

  const labs = useSelector((state) => state.user.labs_profile);
  const lab = useLab();
  
  useEffect(() => {
    if (!labsAndTurmas) {
      const fetchTurmas = async () => {
        try {
          const responses = await Promise.all(labs.map((lab) => {
            const route = lab.isCoordenador ? 
              `turma/coordenador/${lab.lab_id}` : 
              `turma/instrutor/${lab.lab_id}`;
            return api.get(route);
          }));

          const labsAndTurmasObjs = labs.map((lab, idx) =>({ 
            lab_id: lab.lab_id, 
            turmas: responses[idx].data
          }));

          setLabsAndTurmas(labsAndTurmasObjs);
        } catch(error) {}
      }

      fetchTurmas();
    }
  }, [labsAndTurmas]);

  const selectTurmas = () => {
    if (labsAndTurmas) {
      const labTurmas = labsAndTurmas.find((labObj) => labObj.lab_id === lab.lab_id);
      setTurmas(labTurmas.turmas);
    }
  };

  useEffect(() => {
    if (labsAndTurmas)
      selectTurmas();
  }, [labsAndTurmas]);

  useEffect(() => {
    selectTurmas();
  }, [lab]);
  console.log("turmas", turmas)
  const turmasEmSelecao = turmas.filter((turma) => turma.status === "1");
  const turmasAbertas = turmas.filter((turma) => turma.status === "2");
  const turmasFechadas = turmas.filter((turma) => turma.status === "0");
  const [openCadastroTurma, setOpenCadastroTurma] = useState(false);

  const tabs = [
    {
      label: "Em seleção",
      componente: (
        <ThemeProvider theme={theme}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
              paddingBottom: '2%'
            }}
          >
            <Grid container rowSpacing={2} spacing={2}>
              {turmasEmSelecao.reverse().map((turma) => (
                <Grid key={turma.id} item xs={12} sm={12} md={3}>
                  <CardTurmas key={turma.id} turmas={turma} />
                </Grid>
              ))}
              <Grid
                item
                xs={12}
                sm={12}
                md={3}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {(lab.isCoordenador || lab.super_agente) && (
                  <Tooltip title="Abrir nova turma" placement="right">
                    <Button
                      sx={{
                        color: "rgba(212, 109, 13, 0.5)",
                        border: "1px solid rgba(9, 7, 7, 0.1)",
                        borderRadius: "15px",
                        width: "100%",
                        height: "200px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: "15px",
                      }}
                      variant="outlined"
                      onClick={() => setOpenCadastroTurma(true)}
                    >
                      <AddCircleIcon fontSize="large" />
                    </Button>
                  </Tooltip>
                )}
              </Grid>
            </Grid>
          </div>
        </ThemeProvider>
      ),
    },
    {
      label: "Iniciadas",
      componente: (
        <ThemeProvider theme={theme}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
              paddingBottom: '2%'
            }}
          >
            {turmasAbertas.length > 0 ? (
              <Grid container rowSpacing={2} spacing={2}>
                {turmasAbertas.map((turma) => (
                  <Grid item key={turma.id} xs={12} sm={12} md={3}>
                    <CardTurmas key={turma.id} turmas={turma} />
                  </Grid>
                ))}
              </Grid>
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  marginTop: "20%",
                }}
              >
                <ErrorIcon color="primary" fontSize="large" />
                <p>Não há turmas abertas no momento.</p>
              </div>
            )}
          </div>
        </ThemeProvider>
      ),
    },
    {
      label: "Encerradas",
      componente: (
        <ThemeProvider theme={theme}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
              paddingBottom: '2%'
            }}
          >
            {turmasFechadas.length > 0 ? (
              <Grid container rowSpacing={2} spacing={2}>
                {turmasFechadas.map((turma) => (
                  <Grid item xs={12} sm={12} md={3}>
                    <CardTurmas key={turma.id} turmas={turma} />
                  </Grid>
                ))}
              </Grid>
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  marginTop: "20%",
                }}
              >
                <ErrorIcon color="primary" fontSize="large" />
                <p>Não há turmas fechadas no momento.</p>
              </div>
            )}
          </div>
        </ThemeProvider>
      ),
    },
  ];

  const handleCloseModal = () => {
    setOpenCadastroTurma(false);
  };
  return (
    <Box component="main" sx={{ flexGrow: 1, p: 0 }}>
      <TabsCursos
        className="tabs"
        defaultActiveKey="1"
        centered
        items={tabs.map((item, i) => {
          return {
            label: item.label,
            key: i,
            children: item.componente,
          };
        })}
      />

      <ModalCadastro
        open={openCadastroTurma}
        onHandleClose={() => handleCloseModal()}
      />
    </Box>
  );
}

export default ProfileAluno;

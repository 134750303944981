import * as palette from "../../utils/styledVariables";
import React, { useEffect, useState } from "react";
import { pdf } from "@react-pdf/renderer";
import Currriculum from "../curriculum";
import { Box, CssBaseline, Button } from "@mui/material";
import TableCard from "./TableCard";
import api from "../../services/api";
import formatDate from "../../utils/formartDate";


export default function Talentos() {
   
    
const [alunos, setAlunos] = useState([]);
    
   

const studentsHeader = [
{ field: "id" },

{ field: "nome", headerName: "Nome", width: 200 },
{
    field: "curriculum",
    headerName: "Currículo",
    width: 200,
    renderCell: (params) => {
    return (
        <Button
        sx={{ color: palette.laranjaIntermediario2 }}
        onClick={() => {
            pdf(<Currriculum candidate={params.value} />)
            .toBlob()
            .then((blob) => {
                const url = URL.createObjectURL(blob);
                const a = document.createElement("a");
                a.setAttribute("href", url);
                a.setAttribute("download", `${params.value.nome_social ? params.value.nome_social : params.value.name}.pdf`);
                a.click();
            });
        }}
        >
        Baixar
        </Button>
    );
    },
},

{ field: "telefone", headerName: "Telefone", width: 150 },
{ field: "email", headerName: "E-mail", width: 200 },
{ field: "nivel_escolaridade", headerName: "Escolaridade", width: 200 },
{ field: "area", headerName: "Área de atuação", width: 200 },
{ field: "experiencia_ti", headerName: "Experiência em TI", width: 200 },
{ field: "experiencia_dev", headerName: "Experiência em Dev", width: 200 },
{ field: "edital", headerName: "Edital", width: 200 },

// {
//     field: "docs",
//     headerName: "Documentação",
//     width: 200,
//     renderCell: (params) => {
//         return (
//         <Button
//             sx={{ color: palette.roxoAzulado }}
//             onClick={() => {
//             api
//                 .get(`/oxetechwork/inscricao-aluno/docs/${params.value}`, {
//                 responseType: "arraybuffer",
//                 })
//                 .then((res) => {
//                 var blob = new Blob([res.data], {
//                     type: "application/pdf",
//                 });
//                 var url = window.URL.createObjectURL(blob);
//                 window.open(url);
//                 });
//             }}
//         >
//             Baixar
//         </Button>
//         );
//     },
//     },
];

const formatAlunosData = (alunosData) => {
    const formated = [];
    alunosData.forEach((aln) => {
        const data = {
        id: aln.id,
        status: aln.status,
        nome: aln.aluno_info.nome_social ? aln.aluno_info.nome_social : aln.aluno_info.name,
        cpf: aln.aluno_info.cpf,
        rg: aln.rg,
        motivo_de_rejeicao: aln.motivo_de_rejeicao,
        numero_inscricao: aln.num_inscricao,
        edital: aln.edital_info.title,
        createdAt: formatDate(aln.createdAt),
        encerramento: aln.data_encerramento
            ? formatDate(aln.data_encerramento)
            : "Não consta",
        telefone: aln.aluno_info.telefone,
        email: aln.aluno_info.email,
        nivel_escolaridade: aln.nivel_escolaridade,
        area: aln.area,
        experiencia_ti: aln.experiencia_ti,
        experiencia_dev: aln.experiencia_ti,
        curriculum: aln.aluno_info,
        docs: aln.file_id,
        };

        formated.push(data);
    });

    setAlunos(formated);
    };

useEffect(() => {
    api.get("/oxetechwork/inscricao-aluno/talentos").then((res) => {
            formatAlunosData(res.data);
        });
    }, []);
    return(
        <Box sx={{
        width: "90%", height: '150vh', maxHeight: "1000px", display: "flex", zIndex: 2}}>
        <CssBaseline />
  
        <Box sx={{ display: "flex", backgroundColor: "#8e24aa", flexGrow: 1 }}>
          <Box sx={{ display: "flex", backgroundColor: "#FFFFF", flexGrow: 1,  }}>
            <Box
              component="main"
              sx={{
                
                backgroundColor: "#FFFFFF",
                flexGrow: 2,
                p: 1,
                width: { xs: `calc(100% - 20vh)` },
                paddingTop: "4vh",
              }}
            >
            <TableCard name="Inscritos" columns={studentsHeader} rows={alunos} />
          </Box>
        </Box>
      </Box>
    </Box>)
}  


import { Typography, TextField, Grid, Paper } from "@mui/material";
import React from "react";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import ptLocale from "date-fns/locale/pt-BR";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

const style = {
    paper: {
        padding: "10px",
        width: "100%",
        margin: "5px"
    },
    field: { padding: "5px" }

};
function DadosEdital(props) {

    const today = new Date();

    return (<Grid container item xs={12} sm={12} md={12} lg={12}><Paper sx={style.paper} >

        <Typography>{props.title}</Typography>
        <Grid container item xs={12} sm={12} md={12} lg={12}>
            <Grid item xs={12} sm={12} md={12} lg={12} sx={style.field}>
                {"Título do edital"}
                <TextField
                    hiddenLabel
                    id="nome-edital"
                    variant="filled"
                    fullWidth
                    value={props.editalTitle}
                    onChange={props.handleTitle}
                    onBlur={props.handleTitle}
                />
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={6} sx={style.field}>
                {"Data de abertura"}
                <LocalizationProvider locale={ptLocale} dateAdapter={AdapterDateFns}>
                    <DatePicker
                        value={props.startDate}
                        onChange={props.handleStart}
                        renderInput={(params) => (
                            <TextField
                                variant="filled"
                                fullWidth
                                {...params}
                                sx={style.field}
                            />
                        )}
                        minDate={props.minDate}
                        inputFormat="dd/MM/yyyy"
                    />
                </LocalizationProvider>

            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} sx={style.field}>
                {"Data de encerramento"}
                <LocalizationProvider locale={ptLocale} dateAdapter={AdapterDateFns}>
                    <DatePicker
                        value={props.endDate}
                        onChange={props.handleEnd}
                        renderInput={(params) => (
                            <TextField
                                variant="filled"
                                fullWidth
                                {...params}
                                sx={style.field}
                            />
                        )}
                        minDate={props.startDate}
                        inputFormat="dd/MM/yyyy"
                    />
                </LocalizationProvider>
            </Grid></Grid>

    </Paper >    </Grid >)
}

export default DadosEdital
import {
  AddAPhoto as AddAPhotoIcon,
  Edit as EditIcon,
  Logout as LogoutIcon,
} from "@mui/icons-material";
import { Avatar, Divider, ListItemIcon, Menu, MenuItem } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useAuth } from "../../hooks/auth/AuthContext";
import logoSecti from "../../assets/brasao_governo_50.png";
import escola from "../../OxeTechEdu/assets/book.svg";
import SchoolIcon from '@mui/icons-material/School';
import api from "../../services/api";

import { LabsList } from "./LabsList";

export default function AccountMenu({ anchorEl, onClick, current }) {
  const profile_labs = useSelector((state) => state.user.labs_profile);

  const profile_pf = useSelector((state) => state.user.profile);
  const profile_escola = useSelector((state) => state.user.escola_profile);
  const { logout, adminUser } = useAuth();
  const [avatarUrl, setAvatarUrl] = useState(null);

  useEffect(async () => {
    try {
      const response = await api.get("/aluno/avatar", {
        responseType: "arraybuffer",
      });
      const imgUrl = URL.createObjectURL(
        new Blob([response.data], { type: "image" })
      );
      setAvatarUrl(imgUrl);
    } catch (err) {
      console.log(err);
    }
  }, []);

  const history = useHistory();
  const dispatch = useDispatch();
  const open = Boolean(anchorEl);

  const handleClose = () => {
    onClick();
  };

  function handleSignOut() {
    logout();
  }

  return (
    <Menu
      anchorEl={anchorEl}
      id="account-menu"
      open={open}
      onClose={handleClose}
      onClick={handleClose}
      PaperProps={{
        elevation: 0,
        sx: {
          overflow: "visible",
          // width: '120px',
          filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
          mt: 0.5,
          "& .MuiAvatar-root": {
            width: 20,
            height: 20,
            ml: -0.5,
            mr: 1,
          },
          "&:before": {
            content: '""',
            display: "block",
            position: "absolute",
            top: 0,
            right: 14,
            width: 10,
            height: 10,
            bgcolor: "background.paper",
            transform: "translateY(-50%) rotate(45deg)",
            zIndex: 0,
          },
        },
      }}
      transformOrigin={{ horizontal: "right", vertical: "top" }}
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
    >
      <MenuItem
        selected={current === "aluno"}
        onClick={() => history.push("/dashboard")}
      >
        <Avatar src={avatarUrl} /> Perfil Aluno
      </MenuItem>
      {profile_labs && <LabsList labs={profile_labs} />}
      {adminUser && (
        <MenuItem
          selected={current === "admin"}
          onClick={() => history.push("/dashadmin")}
        >
          <img
            src={logoSecti}
            alt="admin perfil"
            width={20}
            style={{ marginLeft: "-4px", marginRight: "8px" }}
          />{" "}
          Perfil Administrativo
        </MenuItem>
      )}
      {profile_escola && (
        <MenuItem
          selected={current === "escola"}
          onClick={() => history.push(`/escola/${profile_escola.id}`)}
        >
          {/* <img
            src={escola}
            alt="escola perfil"
            width={18}
            style={{ marginLeft: "-4px", marginRight: "10px" }}
          />{" "} */}
          <SchoolIcon sx={{marginLeft: "-5px", marginRight: "8px", color: '#D46D0D'}} />
          Perfil Escola
        </MenuItem>
      )}
      <Divider />
      {current === "aluno" && (
        <label htmlFor="raised-button-file">
          <MenuItem>
            <ListItemIcon>
              <AddAPhotoIcon fontSize="small" />
            </ListItemIcon>
            Alterar foto
          </MenuItem>
        </label>
      )}
      <MenuItem onClick={() => history.push("/account")}>
        <ListItemIcon>
          <EditIcon fontSize="small" />
        </ListItemIcon>
        Editar Dados
      </MenuItem>
      <MenuItem onClick={handleSignOut}>
        <ListItemIcon>
          <LogoutIcon fontSize="small" />
        </ListItemIcon>
        Sair
      </MenuItem>
    </Menu>
  );
}

import React, { useEffect, useState } from "react";
import {
  Modal,
  Paper,
  Grid,
  Box,
  Typography,
  useMediaQuery,
} from "@mui/material";
import * as palette from "../../../utils/styledVariables";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import CardDesempenho from "../CardDesempenho";

const styles = {
  modal: {
    width: "28%",
    height: "35%",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "#f2f2f2",
    padding: "10px",
    textAlign: "center",
    borderRadius: "1vh",
    justifyContent: "center",
  },
  paper: {
    width: "100%",
    marginBottom: "5px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
};

export const ModalDesempenhoAtividade = ({ open, close, perfomance }) => {
  const isSmallScreen = useMediaQuery("(max-width:600px)");
  const [partialAverage, setPartialAverage] = useState(false);
  const [percentage, setPercentage] = useState(false);
  const handleClose = () => close();

  useEffect(() => {
    if (performance) {
      setPartialAverage(perfomance.media_parcial);
      setPercentage(perfomance.porcentagem_concluidas);
    }
  }, [perfomance]);

  return (
    <Modal open={open} close={close}>
      <ClickAwayListener onClickAway={handleClose}>
        <Box>
          <IconButton
            size="large"
            sx={{ display: "flex", float: "right" }}
            component="label"
            onClick={handleClose}
          >
            <CloseIcon
              fontSize="large"
              sx={{ color: palette.profileWhiteShade }}
            />
          </IconButton>

          <Grid sx={styles.modal} container direction="row">
            <Grid container item xs={12} sm={12} md={12} lg={12}>
              <Paper
                sx={[
                  {
                    backgroundColor: palette.laranjaOXLTransparente,
                    height: "50px",
                  },
                  styles.paper,
                ]}
              >
                <Typography
                  sx={{
                    fontSize: isSmallScreen ? "2.0vh" : "3.0vh",
                    color: palette.preto,
                    fontFamily: "Poppins",
                    fontWeight: 500,
                  }}
                >
                  Análise de Atividade
                </Typography>
              </Paper>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12}>
              <CardDesempenho
                partialAverage={partialAverage}
                percentage={
                  percentage !== null && percentage !== undefined
                    ? percentage
                    : 0
                }
              />
            </Grid>
          </Grid>
        </Box>
      </ClickAwayListener>
    </Modal>
  );
};

import MenuIcon from "@mui/icons-material/Menu";
import {
  AppBar,
  Avatar,
  Box,
  Container,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink, useLocation, withRouter } from "react-router-dom";
import logo from "../../assets/logo_oxe-tech.png";
import { store } from "../../store";
import { useAuth } from "../../hooks/auth/AuthContext";
import { ButtonOff } from "./styles";

import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
import Badge from "@mui/material/Badge";
import { useEffect, useState } from "react";
import api from "../../services/api";
import { seeNotifications } from "../../store/modules/user/actions";
import {
  azulOxeWork,
  gradienteLaranja,
  roxoEscuro,
} from "../../utils/styledVariables";
import Painel from "../Notificacoes/painel";

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 800,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
});

function ElevationScroll(props) {
  const { children, window } = props;
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

const pages = [
  { name: "Meu perfil", url: "/dashboard" },
  // { name: "Cursos", url: "/cursos" },
  { name: "OxeDin", url: "/oxedin" },
  { name: "OxeTech Work", url: "/oxe-tech-work" },
  { name: "Editar dados", url: "/account" },
  { name: "Sair", url: "sair" },
];

const isActive = window.location.pathname;

const ResponsiveAppBar = (props) => {
  let profile = useSelector((state) =>
    state.user.profile.dataValues
      ? state.user.profile.dataValues
      : state.user.profile
  );
  const dispatch = useDispatch();
  function getName() {
    let name;

    if (profile.razao_social) {
      name = profile.razao_social.split("")[0];
    }

    if (profile.nome_social) {
      name = profile.nome_social.split("")[0];
    } else if (profile.name) {
      name = profile.name.split("")[0];
    }

    return name;
  }
  const { history } = props;
  const [anchorElNav, setAnchorElNav] = React.useState(null);

  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const location = useLocation();
  const { logout } = useAuth();
  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleMenuClick = (pageURL) => {
    handleCloseNavMenu();
    history.push(pageURL);
  };

  function handleSignOut() {
    logout();
  }
  //Notificações
  const [notificacoes, setNotificacoes] = useState([]);

  const [anchorNotif, setAnchorNotif] = useState(null);

  const [hasNewNotifications, setHasNewNotifications] = useState(false);

  //Identificar o usuário e usar a rota
  const getNotificationEndpoint = () => {
    if (profile.cpf !== undefined && profile.reponsibility === undefined) {
      //É aluno
      return "/aluno/see-notifications";
    } else if (profile.cnpj !== undefined) {
      //É empresa
      return "/empresa/see-notifications";
    } else if (profile.reponsibility !== undefined) {
      //É admin
      return "/admin-see-notifications";
    }
  };

  const markAllNotificationAsSeen = async () => {
    if (hasNewNotifications) {
      const endpoint = getNotificationEndpoint();
      setNotificacoes(
        notificacoes.map((notificacao) => (notificacao.seen = true))
      );
      await api.patch(endpoint, { userId: profile.id });

      // profile.notificacoes = notificacoes;
      dispatch(seeNotifications(profile, endpoint));
    }
  };
  //Checar se tem novas notificações para ativar o badge
  const checkHasNewNotifications = () => {
    if (notificacoes.length === 0) {
      setHasNewNotifications(false);
    }

    for (const notificacao of notificacoes) {
      if (notificacao.seen === false) {
        setHasNewNotifications(true);
        break;
      }
    }
  };

  useEffect(() => {
    setNotificacoes(profile.notificacoes);
    checkHasNewNotifications();
  }, [notificacoes]);

  const hadleOpenNotif = (event) => {
    setAnchorNotif(event.currentTarget);
  };

  const handleCloseNotif = async () => {
    // Marcar todas as notificações como lidas
    // Fechar a aba de notificações
    setAnchorNotif(null);
    setHasNewNotifications(false);
    await markAllNotificationAsSeen();
  };

  return location.pathname === "/oxetechwork" ||
    location.pathname === "/oxe-tech-work" ||
    location.pathname.indexOf("oxetechwork-inscricao") !== -1 ||
    location.pathname.indexOf("oxetechwork-inscritos") !== -1 ? (
    <React.Fragment></React.Fragment>
  ) : (
    <ThemeProvider theme={theme}>
      <ElevationScroll {...props}>
        <AppBar
          style={{
            background: profile.cpf ? gradienteLaranja : gradienteLaranja,
            position: "sticky",
          }}
          elevation={0}
        >
          <Container maxWidth="100vw">
            <Toolbar disableGutters sx={{ padding: "1" }}>
              <Box
                sx={{
                  flexGrow: 1,
                  display: { xs: "none", sm: "flex" },
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Box>
                  <Tooltip title="Logo OxeTech">
                    <IconButton onClick={() => handleMenuClick("/")}>
                      <img src={logo} alt="OxeTech" width={"80%"} />
                    </IconButton>
                  </Tooltip>
                </Box>

                <Box>
                  <Tooltip title="Notificações" arrow>
                    <IconButton onClick={hadleOpenNotif} sx={{ margin: "4px" }}>
                      <Badge
                        // color="blue"
                        overlap="circular"
                        badgeContent=" "
                        variant="dot"
                        sx={{
                          margin: "6px 2px",
                          "& .MuiBadge-badge": {
                            backgroundColor: azulOxeWork,
                          },
                        }}
                        invisible={!hasNewNotifications}
                      >
                        <NotificationsNoneOutlinedIcon
                          sx={{ color: "white" }}
                        />
                      </Badge>
                    </IconButton>
                  </Tooltip>
                  <Painel
                    anchorNotif={anchorNotif}
                    handleCloseNotif={handleCloseNotif}
                    notificacoes={notificacoes}
                  />
                </Box>
              </Box>

              {/* ---------- Visível para Mobile ----------*/}
              <Box sx={{ flexGrow: 1, display: { xs: "flex", sm: "none" } }}>
                <IconButton
                  aria-label="Páginas do OxeTech"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleOpenNavMenu}
                  color="inherit"
                  sx={{
                    display:
                      location.pathname !== "/dashboard" &&
                      location.pathname !== "/empresa-account" &&
                      location.pathname !== "/dashadmin"
                        ? { xs: "flex", sm: "none" }
                        : { xs: "none", sm: "none" },
                  }}
                >
                  <MenuIcon fontSize="40px" />
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorElNav}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  open={Boolean(anchorElNav)}
                  onClose={handleCloseNavMenu}
                  sx={{
                    display: { xs: "block", sm: "none" },
                  }}
                >
                  {profile.razao_social ? (
                    <MenuItem onClick={() => handleMenuClick(pages[0].url)}>
                      <Typography textAlign="center">
                        {pages[0].name}
                      </Typography>
                    </MenuItem>
                  ) : (
                    pages.map((page) => (
                      <MenuItem
                        key={page.name}
                        onClick={() =>
                          page.name !== "Sair"
                            ? handleMenuClick(page.url)
                            : handleSignOut()
                        }
                      >
                        <Typography textAlign="center">{page.name}</Typography>
                      </MenuItem>
                    ))
                  )}
                </Menu>
              </Box>

              <Box
                sx={{
                  flexGrow:
                    location.pathname !== "/dashboard" &&
                    location.pathname !== "/empresa-account" &&
                    location.pathname !== "/dashadmin"
                      ? 2
                      : 1,
                  display: { xs: "flex", sm: "none" },
                }}
              >
                <Tooltip title="Logo OxeTech">
                  <IconButton onClick={() => handleMenuClick("/")}>
                    <img src={logo} alt="OxeTech" width={"80%"} />
                  </IconButton>
                </Tooltip>
              </Box>
              {/* ---------- FIM Visível para Mobile ----------*/}

              <Box
                sx={{
                  flexGrow: 0,
                  display:
                    location.pathname !== "/empresa-account" &&
                    location.pathname !== "/dashadmin"
                      ? { xs: "none", sm: "flex" }
                      : { xs: "none", sm: "none" },
                }}
              >
                <NavLink
                  to={location.pathname === "/dashboard" ? "/" : "/dashboard"}
                  style={{ display: "flex", justifyContent: "flex-start" }}
                >
                  {location.pathname === "/dashboard" ? (
                    <ButtonOff>Início</ButtonOff>
                  ) : (
                    <ButtonOff>Meu perfil</ButtonOff>
                  )}
                </NavLink>

                <NavLink
                  to="/inovation-map"
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                  }}
                >
                  <ButtonOff
                    style={{
                      borderBottom:
                        location.pathname === "/inovation-map"
                          ? "1px solid white"
                          : "none",
                    }}
                  >
                    Mapa de Inovação
                  </ButtonOff>{" "}
                </NavLink>
                {/* <ButtonLink
              onClick={handleCloseNavMenu}
              sx={{
                my: 2,
                mr: 4,
                color: '#FFF',
                display: profile.razao_social ? 'none' : 'inline',
                
              }}
            >
              <Link to="/oxedin">
                <img width={90} src={logoOxedin} alt="OxeTech" />
              </Link>
            </ButtonLink> */}

                <Tooltip title="Editar perfil" arrow>
                  <IconButton sx={{ pr: 2 }}>
                    {profile.razao_social ? (
                      <Link to="/empresa-account">
                        <Avatar
                          className="avatar"
                          size={40}
                          style={{
                            backgroundColor: "rgba(211, 211, 211)",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <h3
                            style={{
                              color: "#000",
                              position: "relative",
                              top: "5px",
                            }}
                          >
                            {getName()}
                          </h3>
                        </Avatar>
                      </Link>
                    ) : (
                      <Link to="/account">
                        <Avatar
                          className="avatar"
                          size={40}
                          style={{
                            backgroundColor: "rgba(211, 211, 211)",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <h3
                            style={{
                              color: "#000",
                              position: "relative",
                              top: "5px",
                            }}
                          >
                            {getName()}
                          </h3>
                        </Avatar>
                      </Link>
                    )}
                  </IconButton>
                </Tooltip>

                {/* <Avatar alt="Remy Sharp" src="/static/images/avatar/2.pg" /> */}
              </Box>
            </Toolbar>
          </Container>
        </AppBar>
      </ElevationScroll>
    </ThemeProvider>
  );
};
export default withRouter(ResponsiveAppBar);

import React, { useEffect } from 'react'
import Box from '@mui/material/Box';
import InstagramIcon from '@mui/icons-material/Instagram';
import IconButton from '@mui/material/IconButton';
import { useSelector } from 'react-redux'
import './SocialNetworks.css'

function Instagram() {
    let profile = useSelector((state) => state.user.profile.dataValues ? state.user.profile.dataValues : state.user.profile);
    const [value, setValue] = React.useState('');
    const [editState, setEditState] = React.useState(false);

    function handleChange(event){
        profile.dataChanged = true;
        profile.link_instagram = event.target.value;
        setValue(event.target.value)
    }

    function handleClick(event){
        setEditState(true);
        setTimeout(() => {
            document.getElementById("InstagramInput").focus();
        }, 50)
    }

    useEffect(() => {
        if (profile.link_instagram){
            setValue(profile.link_instagram);
        }
    }, [])

    return (
        <Box sx={{display: 'flex', mx: 1}}>
            <IconButton
                sx={{ color: '#FFFFFF' }}
                onClick={handleClick}
                onFocus={handleClick}
            >
                <InstagramIcon />
            </IconButton>
            <input
                id={'InstagramInput'}
                type="text"
                value={value}
                placeholder={'Seu link do Instagram..'}
                onChange={handleChange}
                onBlur={() => {
                    setEditState(false);
                }}
                style={{
                    display: editState ? 'flex' : 'none',
                    width: '100%',
                    marginTop: '4px',
                    marginBottom: '4px',
                    color: 'white',
                    backgroundColor: '#a026c1',
                    border: '1px dashed pink',
                    padding: '4px',
                    borderRadius: '5px',
                }}
            />
        </Box>
    )
}

export default Instagram;
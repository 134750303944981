//login, cadastro, forms colors
export const linearGradient =
  'linear-gradient(180deg,rgba(116, 31, 138, 0.9) 35%,rgba(0, 0, 99, 1) 100%)';
export const formBackgroundColor = 'rgba(255,255,255,0.98)';
export const formMainColor = 'rgba(95, 25, 130, 1)';
export const formSecudaryColor = 'rgba(95, 25, 130, 0.5)';
export const formTextColor = 'rgba(142, 36, 170,1)';
export const formError = "rgba(232, 28, 28, 0.2)" 
// Profile colors
export const profileMainColor = 'rgba(116, 31, 138, 1)';
export const profileSecondaryColor = '#8E24AA';
export const profileMainBrightColor = 'rgba(255, 255,255)';
export const profileOnHover = 'rgba(204, 151, 255, 1)';
export const profileWhiteShade = '#F8F8F8'
export const profileWhiteShadeTransp = 'rgb(248, 248, 248, 0.40)'
export const profileCardBackgroundColor = "rgba(173, 137, 191, 0.22)"
//map header color
export const mapHeaderColor = profileMainColor;
// Paleta Geral
export const roxoEscuro = '#741F8A'
export const roxoMedio = '#8E24AA'
export const roxoClaro = '#AD89BF'
export const roxoAzulado = '#8451cc'
export const azulEscuro = '#000063'
export const azulOxedin = '#4F69AA'
export const laranjaOxeLab = '#D46D0D'
export const azulOxeWork = '#1609B2'
export const preto = '#2a132f'
export const cinza = '#555555'
export const gradiente = "radial-gradient(292.41% 335.33% at 41.6% 50%, #741F8A 2.22%, #1609B3 100%)"
export const roxoClaroTransparente = "rgba(173, 137, 191, 0.22)"

export const transparente = "rgba(0, 0, 0, 0.30)"
export const laranjaMain = "#f05e23"
export const laranjaIntermediario = "#f4871e"
export const laranjaIntermediario2 = "#f27420"
export const laranjaSecundario = "#f6931c"
export const laranjaSecundarioTransp = "rgba(246, 147, 28, 0.3)"
export const gradienteLaranja = "linear-gradient(90deg, rgba(246,147,28,1) 0%, rgba(240,94,35,1) 80%)"
export const gradienteLaranja180 = "linear-gradient(180deg, rgba(246,147,28,1) 0%, rgba(240,94,35,1) 80%)"
export const gradienteLaranjaInvertido = "linear-gradient(-90deg, rgba(246,147,28,1) 0%, rgba(240,94,35,1) 50%)"
export const gradienteLaranjaIntermediario = "linear-gradient(90deg, rgba(246,147,28,1) 10%,  #f4871e 80%)"
export const gradienteLaranjaIntermediarioInv = "linear-gradient(-90deg, rgba(246,147,28,1) 10%,  #f4871e 80%)"
export const laranjaOXLTransparente = "rgba(212, 109, 13, 0.1) !important"
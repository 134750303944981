import styled from 'styled-components'

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: auto;
  img {
    width: 15vw;
  }
  .ant-form-item-label > label {
    width: 340px;
    font-size: 16px;
  }

  @media (max-width: 700px) {
    img {
      width: 25vw;
    }
    /* .ant-form-item-control-input-content {
      width: '99%';
    } */
  }
`
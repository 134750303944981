import styled from 'styled-components'
import { Tabs } from 'antd';

export const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  background: #fff; 
  height: 100%;
  min-height: 100vh;
  .card{
    display: flex;
    justify-content: center;
    align-items: center;
  }

`


export const TabsPage = styled(Tabs)`
  .ant-tabs-tab-btn{
    color: rgba(0, 0, 0, 0.77);
    font-family: 'Karla', sans-serif;
    font-weight: 700;
    font-size: 1.5rem;
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
   color: rgba(212, 109, 13, 1) !important; 
   font-weight: 700;
  }
  .ant-tabs-ink-bar {
   position: absolute;
   background: rgba(212, 109, 13, 1);
   pointer-events: none;
  }
  .ant-tabs-nav-wrap{
    border: 1px solid white !important;
  }

`

import React from "react";
import { Typography, Button, useMediaQuery } from "@mui/material";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import { useStyles } from "./styles";

const headerStyle = {
  //background: 'linear-gradient(108deg, #F05E23 -14.59%, #972E96 107.21%)',
  background:
    "linear-gradient(108deg, #C4764E -14.59%, #BD6F57 36.45%, #914C90 107.21%)",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  height: "200px",
  fontFamily: "Poppins",
  color: "white",

  //borderRadius:'10px'
};

const buttonStyle = {
  fontSize: "38px",
  width: "48px",
  height: "48px",
  marginRight: "20px",
  color: "white",
};

function Header({ onBack, modulo }) {
  const classes = useStyles();
  const isSmallScreen = useMediaQuery("(max-width:600px)");

  return (
    <div
      style={{
        ...headerStyle,
        flexDirection: isSmallScreen && "column",
        paddingLeft: "2%",
        paddingBottom: "2%",
      }}
    >
      <div
        // className={classes.informationText}
        style={{
          width: isSmallScreen ? "100%" : "40%",
          marginTop: isSmallScreen && "3%",
          height: "80%",
        }}
      >
        <Typography
          variant="h6"
          className={classes.whiteText}
          style={{
            fontWeight: "normal",
            fontSize: "15px",
          }}
        >
          Trilha de {modulo.trilhaTitulo}
        </Typography>
        <Typography
          variant="h3"
          className={classes.whiteText}
          style={{
            fontWeight: "normal",
            fontSize: isSmallScreen ? "30px" : "40px",
          }}
        >
          Módulo
        </Typography>
        <Typography
          variant="h3"
          style={{
            color: "white",
            fontWeight: "bold",
            fontSize: isSmallScreen ? "30px" : "40px",
          }}
        >
          {modulo.titulo}
        </Typography>
      </div>

      <div
        // className={classes.informationText}
        style={{
          width: isSmallScreen ? "100%" : "60%",
          height: "80%",
          overflow: "auto",
        }}
      >
        <Typography
          variant="h4"
          className={classes.whiteText}
          style={{
            fontWeight: "normal",
            fontSize: isSmallScreen ? "22px" : "32px",
          }}
        >
          Descrição:
        </Typography>
        <Typography
          variant="h6"
          className={classes.whiteText}
          style={{ fontWeight: "normal", fontSize: "14px" }}
        >
          {modulo.descricao}
        </Typography>
      </div>

      <Button
        onClick={onBack}
        variant="outlined"
        style={{
          border: "none",
          position: isSmallScreen && "absolute",
          right: isSmallScreen && 0,
        }}
        aria-label="white"
      >
        <ArrowCircleLeftIcon
          style={{ ...buttonStyle, fontSize: isSmallScreen && "20px" }}
        />
      </Button>
    </div>
  );
}

export default Header;

import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { 
    List, 
    Space, 
    Breadcrumb, 
    Row, 
    Col, 
    Spin, 
    Statistic 
} from 'antd';

import { 
    LoadingOutlined, 
    HomeOutlined, 
    UserOutlined
} from '@ant-design/icons';

import api from "../../services/api";

import CadastroVagaAdm from "../../components/CadastroVagaAdm";

import { Container, Header } from './styles';

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

function PageVagasAdm() {

    const [vagas, setVagas] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        api.get("vagas/vagasforadm").then((response) => {
          setVagas(response.data);
          setLoading(false);
        });
    }, []);

    const IconText = ({ icon, text }) => (
        <Space>
            {React.createElement(icon)}
            {text}
        </Space>
    );

  return (
    <Container>
        <div>
            <Breadcrumb>
            <Breadcrumb.Item>
                <Link to={{ pathname: "/" }}>
                    <HomeOutlined />
                </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item >
                Vagas
            </Breadcrumb.Item>
            </Breadcrumb>
        </div>

        <Header>
            <Row gutter={40}>
                <Col span={30}>
                { vagas.length === 0 ? 
                    <Spin indicator={antIcon} /> 
                    :
                    <Statistic title="Vagas Cadastradas" value={vagas.length} /> 
                }
                </Col>
            </Row>

            <CadastroVagaAdm />
        </Header>

        <List
            itemLayout="vertical"
            size="small"
            pagination={{
                pageSize: 4,
                pageSizeOptions: [4],
                size: "small",
                responsive: true,
            }}
            loading={loading}
            dataSource={vagas}
            renderItem={item => (
                <List.Item
                    key={item.id}
                    actions={[
                        <IconText icon={UserOutlined} text="156" key="list-vertical-star-o" />,
                    ]}
                >
                    <List.Item.Meta
                    //   avatar={<Avatar src={item.avatar} />}
                        title={<Link to={`showvaga/${item.id}`}>{item.titulo}</Link>}
                        description={item.descricao}
                    />
                    {item.content}
                </List.Item>
            )}
        />
    </Container>
  );
}

export default PageVagasAdm;
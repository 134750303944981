import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import Checkbox from "@mui/material/Checkbox";
import api from "../../services/api";
import { blue, green, red } from '@mui/material/colors';
export default function Frequencia({ turmaId, setFrequencia}) {
  const [alunosComFrequencia, setAlunosComFrequencia] = useState([]);
  useEffect(() => {
    api
      .get(`matriculas/turma/${turmaId}`)
      .then((response) => {
        const rows = response.data?.map((matricula) => {
          return {
            id: matricula.id,
            nome: matricula.aluno,
            status: null,
          };
        });
        setAlunosComFrequencia(rows);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    setFrequencia(alunosComFrequencia)
  }, [alunosComFrequencia])
  

  const columns = [
    {
      field: "nome",
      headerName: "Nome",
      width: 500,
    },
    {
      field: "presente",
      headerName: "Presente",
      width: 150,
      renderCell: (params) => (
        <Checkbox
          checked={params.row.status === "1"}
          onChange={(e) => {
            const newStatus = e.target.checked ? "1" : "0";
            const updatedAluno = { ...params.row, status: newStatus };
            setAlunosComFrequencia((prevAlunos) =>
              prevAlunos.map((aluno) =>
                aluno.id === updatedAluno.id ? updatedAluno : aluno
              )
            );
          }}
          sx={{
            "&.Mui-checked": {
              color: params.row.status === "1" ? green[500] : "initial",
            },
          }}
        />
      ),
    },
    {
      field: "falta",
      headerName: "Falta",
      width: 150,
      renderCell: (params) => (
        <Checkbox
          checked={params.row.status === "0"}
          onChange={(e) => {
            const newStatus = e.target.checked ? "0" : "1";
            const updatedAluno = { ...params.row, status: newStatus };
            setAlunosComFrequencia((prevAlunos) =>
              prevAlunos.map((aluno) =>
                aluno.id === updatedAluno.id ? updatedAluno : aluno
              )
            );
          }}
          sx={{
            "&.Mui-checked": {
              color: params.row.status === "0" ? red[500] : "initial",
            },
          }}
        />
      ),
    },
    {
      field: "faltaJustificada",
      headerName: "Falta Justificada",
      width: 150,
      renderCell: (params) => (
        <Checkbox
          checked={params.row.status === "2"}
          onChange={(e) => {
            const newStatus = e.target.checked ? "2" : "1";
            const updatedAluno = { ...params.row, status: newStatus };
            setAlunosComFrequencia((prevAlunos) =>
              prevAlunos.map((aluno) =>
                aluno.id === updatedAluno.id ? updatedAluno : aluno
              )
            );
          }}
          sx={{
            "&.Mui-checked": {
              color: params.row.status === "2" ? blue[500] : "initial",
            },
          }}
        />
      ),
    },
  ];
  return (
    <Box sx={{ height: 400, width: "100%" }}>
      <DataGrid
        rows={alunosComFrequencia}
        columns={columns}
        disableRowSelectionOnClick={true}
        components={{
            Toolbar: ({ components, ...props }) => (
              <div
                {...props}
                style={{
                  backgroundColor: 'white',
                }}
              >
                {components}
              </div>
            ),}}
      />
    </Box>
  );
}

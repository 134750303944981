import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { FiPower } from "react-icons/fi";
import { Button } from "antd";

import logoOxe from "../../assets/logo_oxe-tech.png";

import { signOut } from "../../store/modules/auth/actions";

import { Container, Content, Menu, Footer } from "./styles";
import { useAuth } from "../../hooks/auth/AuthContext";

function Siderbar() {
  const dispatch = useDispatch();
  const { logout } = useAuth;
  const profile = useSelector((state) => state.user.profile);

  function handleSignOut() {
    logout();
  }

  return (
    <Container>
      <Content>
        <nav>
          {/* <Link to="/">
            <MdKeyboardBackspace size={22} />
          </Link> */}
          <img src={logoOxe} alt="User" style={{ width: "25rem" }} />
          <h1 style={{ color: "#fff" }}>{profile.name} </h1>
        </nav>
        <Menu>
          <Link style={{ color: "#fff" }} to="/perfil">
            Perfil
          </Link>
          {/* { profile.escritorio ? <Link to="/escritorio">Meu Escritório</Link> : <Link to="/offices">Escritórios</Link> } */}
          {/* { profile.escritorio ? <Link to="/clientes">Meus clientes</Link> : <Link to="/">Usuários</Link> } */}
          {/* { profile.escritorio && <Link to="/">Central de Ajuda</Link> } */}
          {/* { profile.escritorio_admin && <Link to="/">Meu Plano</Link> } */}
        </Menu>
        <div>
          <Button onClick={handleSignOut}>
            <FiPower color={"#fafafa"} />
            <strong>Sair</strong>
          </Button>
        </div>

        <Footer>
          <p style={{ color: "#fff" }}>© 2020 Todos os direitos reservados</p>
        </Footer>
      </Content>
    </Container>
  );
}

export default Siderbar;

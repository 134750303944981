import styled from 'styled-components'
import { motion } from 'framer-motion'

export const Container = styled.div``

export const SectionOne = styled.div`
  -webkit-user-drag: none;
  background: linear-gradient(
    180deg,
    rgba(116, 31, 138, 0.9) 35%,
    rgba(0, 0, 99, 1) 100%
  );
  background-size: cover;

  overflow: hidden;
  height: 100vh;
  padding: 10vh;
  display: flex;
  text-align: center;
  position: relative;
  p {
    color: rgba(255, 255, 255, 1);
    font-size: 1.5em;
    text-align-last: justify;
    /* word-spacing: 1px; */
  }
`

export const TriangleFrame = styled(motion.img)`
  position: fixed;
  margin-left: -20%;
  margin-top: 10%;
  width: 120vw;
`

export const LogoOxeDin = styled(motion.img)`
  position: absolute;
  width: 28vw;
`

export const LogoOxeTechLab = styled(motion.img)`
  width: 35vw;
`

export const LogoOxeTechWork = styled(motion.img)`
  width: 28vw;
`

export const LogoOxeTechHat = styled(motion.img)`
  width: 28vw;
`

export const InovationMap = styled(motion.img)`
  position: absolute;
  width: 18vw;
`

export const Text = styled(motion.div)`
  display: flex;
  align-items: row;
  align-items: flex-end;
  margin: auto;
  width: 85%;
  a {
    color: rgba(255, 255, 255, 0.7);
    font-size: 120%;
    transition: color 0.2s ease;
    &:hover {
      color: rgba(255, 255, 255, 1);
    }
  }
  @media (min-width: 1500px) {
    width: 80%;
  }
  .typ {
    @media (min-width: 1500px) {
      font-size: 200%;
      margin-bottom: 15%;
      margin-top: 2%;
    }
  }
  .tool {
    position: absolute;
    z-index: 1000;
    /* bottom: 1%; */
    @media (min-width: 1500px) {
      font-size: 160%;
      right: 48%;
    }
  }
`

export const navegator = styled(motion.p)``

import * as React from 'react'
import {
  AppBar,
  Box,
  Container,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Tooltip,
  Typography,
  Button
} from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'
import { withRouter } from 'react-router-dom'
import logo from '../../assets/logo_oxe-tech.png'
import { styled } from '@mui/material/styles'
import { Link } from 'react-router-dom'

const pages = [
  { name: 'Início', url: '/' },
  { name: 'Cursos', url: '/cursos' },
  { name: 'Mapa de Inovação', url: '/inovation-map' },
  { name: 'Login', url: '/login' }
]
const pages_mobile = [
  { name: 'Início', url: '/' },
  { name: 'Cursos', url: '/cursos' },
  { name: 'Mapa de Inovação', url: '/inovation-map' },
  { name: 'Login', url: '/login' },
  { name: 'Cadastre-se', url: '/select-cadastro' }
]

const Buttons = styled(Button)({
  border: 'none',
  color: 'white',
  fontFamily: ['Inter', 'sans-serif'].join(','),
  textTransform: 'none',
  fontSize: 16,
  margin: 10,
  '&:hover': {
    paddingBottom: 2,
    borderBottom: '2px solid',
    borderRadius: 15,
    borderImage: 'linear-gradient(to right, darkblue, darkorchid) 1',
    background: 'transparent'
  }
})

const Cadastrar = styled(Button)({
  color: 'white',
  fontFamily: ['Inter', 'sans-serif'].join(','),
  textTransform: 'none',
  fontSize: 16,
  margin: 10,
  border: '2px solid',
  borderImage: 'linear-gradient(to right, darkblue, darkorchid) 1',
  '&:hover': {
    borderBottom: '2px solid',
    borderImage: 'linear-gradient(to right, darkorchid,darkblue) 1',
    background: 'transparent'
  }
})
const ResponsiveAppBar = props => {
  const { history } = props
  const [anchorElNav, setAnchorElNav] = React.useState(null)

  const handleOpenNavMenu = event => {
    setAnchorElNav(event.currentTarget)
  }

  const handleCloseNavMenu = () => {
    setAnchorElNav(null)
  }

  const handleMenuClick = pageURL => {
    handleCloseNavMenu()
    history.push(pageURL)
  }

  return (
    <AppBar
      style={{
        background: 'rgba(116, 31, 138,0.9)'
      }}
      position="sticky"
      // elevation={2}
    >
      <Container maxWidth="xl">
        <div
          style={{
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          <Toolbar disableGutters>
            {/* <Box sx={{ flexGrow: 1, display: { xs: 'none', sm: 'flex' } }}>
              <Tooltip title="Logo OxeTech">
                <IconButton
                  onClick={() => handleMenuClick('/')}
                  // sx={{ flexGrow: 1, display: { xs: "none", md: "flex", p: 0 } }}
                >
                  <img src={logo} alt="OxeTech" width={'80%'} />
                </IconButton>
              </Tooltip>
            </Box> */}

            <Box
              sx={{
                flexGrow: 1,
                display: { xs: 'flex', sm: 'none' },
                boxShadow: '2px'
              }}
            >
              <IconButton
                size="large"
                aria-label="Páginas do OxeTech"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left'
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left'
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: 'block', sm: 'none' }
                }}
              >
                {pages_mobile.map(page => (
                  <MenuItem
                    key={page.name}
                    onClick={() => handleMenuClick(page.url)}
                  >
                    <Typography textAlign="center">{page.name}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>

            <Box sx={{ flexGrow: 1.1 }}>
              <Tooltip title="Logo OxeTech">
                <IconButton onClick={() => handleMenuClick('/')}>
                  <img src={logo} alt="OxeTech" width={'80%'} />
                </IconButton>
              </Tooltip>
            </Box>
            <Box
              sx={{
                flexGrow: 0,
                display: { xs: 'none', sm: 'flex' }
              }}
            >
              {pages.map(page => (
                <Buttons
                  key={page}
                  onClick={() => handleMenuClick(page.url)}
                  size={'large'}
                >
                  {page.name}
                </Buttons>
              ))}
              <Cadastrar>
                <Link
                  to="/select-cadastro"
                  style={{ color: 'white', fontWeight: 400 }}
                >
                  CADASTRE-SE
                </Link>
              </Cadastrar>
            </Box>
          </Toolbar>
          <Typography
            sx={{
              my: 0,
              color: 'rgba(89,24,129,1)',
              backgroundColor: 'rgba(255,255,255,0.85)',
              boxShadow: '5px'
            }}
            variant="h5"
            textAlign="center"
          >
            Mapa da inovação de Alagoas
          </Typography>
        </div>
      </Container>
    </AppBar>
  )
}
export default withRouter(ResponsiveAppBar)

import { styled } from '@mui/material/styles'
import { Button } from '@mui/material'

export const ButtonOff = styled(Button)`
  border: none;
  color: white;
  font-family: 'Inter', sans-serif;
  text-transform: none;
  font-size: 16px;
  margin: 0 10px;
  /* :hover {
    padding-bottom: 2;
    border-bottom: 2px solid;
    border-radius: 15;
    border-image: linear-gradient(to right, darkblue, darkorchid) 1;
    background: transparent;
  } */
  .activeLink {
    background-color: darkblue;
  }
  .normalLink {
    background-color: darkblue;
  }
`
export const ButtonOn = styled(Button)`
  border: none;
  height: auto;
  color: white;
  font-family: 'Inter', sans-serif;
  padding-bottom: 2;
  border-bottom: 2px solid;
  border-radius: 15;
  border-image: linear-gradient(to right, darkblue, darkorchid) 1;
  background: transparent;
  text-transform: capitalize;
  font-size: 16px;
  margin: 0 10px;
  padding: 0;
  :hover {
    border: none;
  }
`

export const Cadastrar = styled(Button)`
  color: white;
  font-family: 'Inter', sans-serif;
  text-transform: none;
  font-size: 16px;
  margin: 10px;
  border: 2px solid;
  border-image: linear-gradient(to right, darkblue, darkorchid) 1;
  :hover {
    border-bottom: 2px solid;
    border-image: linear-gradient(to right, darkorchid, darkblue) 1;
    background: transparent;
  }
`

export const CadastrarOn = styled(Button)`
  color: white;
  font-family: 'Inter', sans-serif;
  text-transform: none;
  font-size: 16px;
  margin: 10px;
  border: 2px solid;
  border-image: linear-gradient(to right, darkblue, darkorchid) 1;
  :hover {
    border-bottom: 2px solid;
    border-image: linear-gradient(to right, darkorchid, darkblue) 1;
    background: transparent;
  }
`
export const ButtonLink = styled(Button)`
  color: white;
  &:hover,
  &:active {
    color: #741f8a;
    font-size: 14px;
    text-decoration: underline;
  }
`
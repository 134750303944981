import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import {
  Avatar,
  Breadcrumb,
  Tabs,
  Descriptions,
  Skeleton,
  Divider,
  Tag
} from 'antd'
import {
  HomeOutlined,
  UserOutlined,
  LinkedinFilled,
  GithubFilled,
  LinkOutlined
} from '@ant-design/icons'

import api from '../../services/api'

import { Container, Content } from './styles'

function ProfileAlunoPublic({ match }) {
  const { id } = match.params

  const { TabPane } = Tabs

  const [aluno, setAluno] = useState()

  useEffect(() => {
    const loadAluno = async () => {
      await api.get(`aluno/profile?id=${id}`).then(response => {
        setAluno(response.data)
      })
    }
    loadAluno()
  }, [id])

  return (
    <Container>
      <div>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to={{ pathname: '/' }}>
              <HomeOutlined />
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to={{ pathname: '/oxedin' }}>
              <span>OxeDin</span>
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>{aluno ? aluno.name : <Skeleton />}</Breadcrumb.Item>
        </Breadcrumb>
      </div>

      {aluno ? (
        <Content>
          <Avatar size={80} icon={<UserOutlined />} />
          <Divider />
          <Descriptions title={`${aluno.name}`} size="middle">
            <Descriptions.Item label="Email"> {aluno.email} </Descriptions.Item>
            <Descriptions.Item label="Ocupação">
              {aluno.ocupacao}
            </Descriptions.Item>
            <Descriptions.Item label="Município">
              {aluno.municipio}
            </Descriptions.Item>
            <Descriptions.Item>
              <h3>
                {aluno.linkedin ? (
                  <a href={aluno.linkedin} target="_blank" rel="noreferrer">
                    <LinkedinFilled style={{ fontSize: '2rem' }} />
                  </a>
                ) : (
                  ''
                )}
                {aluno.github ? (
                  <a href={aluno.github} target="_blank" rel="noreferrer">
                    <GithubFilled style={{ fontSize: '2rem' }} />
                  </a>
                ) : (
                  ''
                )}
                {aluno.outro ? (
                  <a href={aluno.outro} target="_blank" rel="noreferrer">
                    <LinkOutlined style={{ fontSize: '2rem' }} />
                  </a>
                ) : (
                  ''
                )}
              </h3>
            </Descriptions.Item>
          </Descriptions>

          <Divider />

          <Descriptions title={<strong>Sobre mim</strong>} size="middle">
            <Descriptions.Item label="" style={{ width: '60vw' }}>
              {aluno.sobre_mim}
            </Descriptions.Item>
          </Descriptions>

          <Descriptions size="middle">
            <Descriptions.Item label={<strong>Foco na carreira</strong>}>
              {aluno.foco_carreira}
            </Descriptions.Item>
          </Descriptions>

          <Descriptions size="middle">
            <Descriptions.Item label={<strong>Habilidades</strong>}>
              {aluno.skills
                ? aluno.skills.map(skill => {
                    return <Tag color="orange">{skill}</Tag>
                  })
                : null}
            </Descriptions.Item>
          </Descriptions>
        </Content>
      ) : (
        <Skeleton />
      )}

      <Tabs defaultActiveKey="1" centered>
        <TabPane tab="Conquistas" key="1">
          <p>Conquistas</p>
        </TabPane>
        <TabPane tab="Cursos" key="2">
          <p>Cursos</p>
        </TabPane>
      </Tabs>
    </Container>
  )
}

export default ProfileAlunoPublic

import styled from "styled-components";
import * as palette from "../../../../../utils/styledVariables"

export const Container = styled.div`
  min-height: 100px;
  max-height: 100px;
  color: ${palette.profileWhiteShade};
  padding: 5% 16px 5% 16px;

  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;

  background: linear-gradient(128.73deg, #D56D0D -13.25%, #1609B3 197.9%);
  border-radius: 1vh 1vh 0vh 0vh;
  .entrar{
    display: none;
  }
  &:hover{
    .entrar{
    display: flex;
    color: white;
  }
  }
  
`;

export const Title = styled.span`

font-family: "Roboto";
font-style: normal;
font-weight: 500;
font-size: 22pt;
line-height: 24pt;

overflow: hidden;
text-overflow: ellipsis;
display: -webkit-box;
-webkit-line-clamp: 2;
        line-clamp: 2; 
-webkit-box-orient: vertical;`;
import {
  Box,
  Button,
  Grid,
  Modal,
  Paper,
  TextField,
  Typography,
  FormHelperText
} from "@mui/material";
import Alert from "@mui/material/Alert";
import CircularProgress from "@mui/material/CircularProgress";
import Snackbar from "@mui/material/Snackbar";
import { makeStyles } from "@mui/styles";
import cep from "cep-promise";
import React, { useEffect, useState } from "react";
import InputMask from "react-input-mask";
import api from "../../services/api";
import * as palette from "../../utils/styledVariables";
import Buscador from "../Buscador";
import EditAgentes from "./EditAgentes";
import {
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Tooltip,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AssistantPhotoIcon from "@mui/icons-material/AssistantPhoto";
const useStyles = makeStyles({
  autocomplete: {
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "purple",
    },
    "& label.Mui-focused": {
      color: "purple",
    },
  },
});

const styles = {
  modal: {
    width: "50%",
    position: "absolute",
    top: "50%",
    marginTop: "5%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "#f2f2f2",
    padding: "14px",
    textAlign: "center",
    borderRadius: "1vh",
    justifyContent: "center",
    overflow: "auto",
  },

  uploadButton: {
    height: "40px",
    color: palette.laranjaIntermediario2,
    "&:hover": {
    color: palette.laranjaSecundario
  },
  },

  paper: {
    width: "100%",
    margin: "5px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  box: {
    backgroundColor: palette.laranjaOXLTransparente,
    padding: "10px",
    margin: "5px",
    borderRadius: "1vh",
    display: "flex",
    // flexDirection: "column",
  },
  subtitles: {
    fontFamily: "Roboto",
    fontStyle: "bold",
    fontWeight: "500",
    fontSize: "1.5vh",
    lineHeight: "2.5vh",
    margin: "0px 0px 5px 0px",
    textAlign: "left",
  },

  field: { padding: "5px" },
};

function ModalCadastroLab(props) {
  const [rua, setRua] = useState(sessionStorage.getItem("rua_lab") || "");
  const [bairro, setBairro] = useState(
    sessionStorage.getItem("bairro_lab") || ""
  );
  const [state, setState] = useState(sessionStorage.getItem("state") || "");
  const [municipio, setMunicipio] = useState(
    sessionStorage.getItem("municipio") || ""
  );
  const [numero, setNumero] = useState(sessionStorage.getItem("numero") || "");
  const [complemento, setComplemento] = useState(
    JSON.parse(sessionStorage.getItem("complemento")) || ""
  );
  const [CEP, setCEP] = useState(sessionStorage.getItem("CEP") || "");
  const [unidade, setUnidade] = useState(
    sessionStorage.getItem("unidade") || ""
  );
  const [coordenador, setCoordenador] = useState(null);
  const [agentes, setAgentes] = useState([]);
  const [ruaError, setRuaError] = useState("");
  const [bairroError, setBairroError] = useState("");
  const [municipioError, setMunicipioError] = useState("");
  const [numeroError, setNumeroError] = useState("");
  const [CEPError, setCEPError] = useState("");
  const [unidadeError, setUnidadeError] = useState("");
  const [coordenadorError, setCoordenadorError] = useState("");

  const [alertSuccessful, setAlertSuccessful] = useState("");
  const [alertUnauthorized, setAlertUnauthorized] = useState("");

  const [address, setAddress] = useState(null);
  const [errorCep, setErrorCep] = useState(null);
  const [alunos, setAlunos] = useState([]);
  const alunos_sem_coordenador = alunos?.filter(
    (obj) => obj.id !== coordenador?.id
  );
  const [exibirCampoAdicionar, setExibirCampoAdicionar] = useState(false);

  const [loading, setLoading] = useState(false);

  const classes = useStyles();
  const handleClose = () => {
    props.close();
  };

  const handleCep = (event) => {
    setCEP(event.target.value);
    setErrorCep({ CEP: "" });

    let reg = new RegExp(/^[0-9]{2}\.[0-9]{3}-[0-9]{3}$/).test(
      event.target.value
    );
    if (reg) {
      cep(event.target.value)
        .then((response) => {
          const {
            street: rua,
            neighborhood: bairro,
            city: municipio,
            cep,
            state: estado,
          } = response;

          setRua(rua);
          setBairro(bairro);
          setMunicipio(municipio);
          setState(estado);
        })
        .catch((error) => {
          setErrorCep({ CEP: "Não conseguimos resgatar o seu CEP." });
          setAddress({
            rua: "",
            bairro: "",
            municipio: "",
            cep: "",
            state: "",
            numero: "",
          });
        });
    } else {
      setErrorCep({ CEP: "CEP inválido." });
    }
  };

  const handlerua = (event) => {
    setRua(event.target.value);
  };

  const handlebairro = (event) => {
    setBairro(event.target.value);
  };

  const handlenumero = (event) => {
    setNumero(event.target.value);
    if (event.target.value.length > 0)
      sessionStorage.setItem("numero", event.target.value);
    else {
      sessionStorage.removeItem("numero");
    }
  };

  const handlemunicipio = (event) => {
    setMunicipio(event.target.value);
  };

  const handleFinish = async () => {
    let isValid = true;
    // console.log(agentes);

    if (!coordenador) {
      // alert("Adicione um coordenador")
      setCoordenadorError("Campo obrigatório");
      isValid = false;
    } else {
      setCoordenadorError("");
    }

    if (!rua) {
      setRuaError("Campo obrigatório");
      isValid = false;
    } else {
      setRuaError("");
    }

    if (!bairro) {
      setBairroError("Campo obrigatório");
      isValid = false;
    } else {
      setBairroError("");
    }

    if (!municipio) {
      setMunicipioError("Campo obrigatório");
      isValid = false;
    } else {
      setMunicipioError("");
    }

    if (!numero) {
      setNumeroError("Campo obrigatório");
      isValid = false;
    } else {
      setNumeroError("");
    }

    if (!CEP) {
      setCEPError("Campo obrigatório");
      isValid = false;
    } else {
      setCEPError("");
    }

    if (!unidade) {
      setUnidadeError("Campo obrigatório");
      isValid = false;
    } else {
      setUnidadeError("");
    }

    if (isValid) {
      const data = {
        nome: unidade,
        municipio: municipio,
        rua: rua,
        bairro: bairro,
        numero: numero,
        cep: CEP.replace(/[.-]/g, ""),
        complemento: complemento,
        coordenador_id: coordenador.id,
        agentes: agentes.length !== 0 ? agentes.map((obj) => ({id: obj.id, super_agente:obj.super_agente  })) : [],
      };
      setLoading(true);
      api
        .post("/laboratorio/create", data)
        .then((response) => {
          setLoading(false);
          setAlertSuccessful(response.data.message);

          sessionStorage.removeItem("rua_lab");
          sessionStorage.removeItem("bairro_lab");
          sessionStorage.removeItem("state");
          sessionStorage.removeItem("municipio");
          sessionStorage.removeItem("numero");
          sessionStorage.removeItem("CEP");
          sessionStorage.removeItem("unidade");
          sessionStorage.removeItem("coordenador");
          sessionStorage.removeItem("agentes");
          setTimeout(() => {
            handleClose()
          }, 1000);
        })
        .catch((err) => {
          setLoading(false);
          setAlertUnauthorized(err.response.data);
        });
    }
  };

  useEffect(() => {
    api.get("alunos").then((res) => {
      setAlunos(res.data);
    });
  }, []);

  useEffect(() => {
    // Salvar os valores dos estados no sessionStorage
    sessionStorage.setItem("rua_lab", rua);
    sessionStorage.setItem("bairro_lab", bairro);
    sessionStorage.setItem("state", state);
    sessionStorage.setItem("municipio", municipio);
    sessionStorage.setItem("numero", numero);
    sessionStorage.setItem("CEP", CEP);
    sessionStorage.setItem("unidade", unidade);

  }, [
    rua,
    bairro,
    state,
    municipio,
    numero,
    CEP,
    unidade,
  ]);

  const onSelectCoordenador = (e, newValue) => {
    if (newValue) {
      setCoordenador(newValue.value);
    }
  };
  const onSelectAgentes = (e, newValue) => {
    if (newValue) {
      const agenteExists = agentes.some(
        (objeto) => objeto.id === newValue.value?.id
      );
      if (!agenteExists) {
        setAgentes([...agentes, {...newValue.value, super_agente: false}]);
      } else {
        alert("Esse agente já foi adicionado");
      }
    }
    // setAgentes(newValue.map((obj) => obj.value));
  };
  const dataBuscadorCoordenador = alunos.map((obj) => ({
    name: obj.name,
    label: obj.cpf,
    value: obj,
  }));
  const dataBuscadorAgente = alunos_sem_coordenador.map((obj) => ({
    name: obj.name,
    label: obj.cpf,
    value: obj,
  }));

  const excluirAgente = (index) => {
    const nomesAtualizados = [...agentes];
    nomesAtualizados.splice(index, 1);
    setAgentes(nomesAtualizados);
  };
  
  const habilitarSuperAgente = (index) => {
    const agenteHabilitado = [...agentes];
    agenteHabilitado[index].super_agente = !agenteHabilitado[index].super_agente;
    setAgentes(agenteHabilitado);
  };
  return (
    <Modal
      open={props.open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        overflow: "auto",
      }}
    >
      <Box sx={{}}>
        <Grid sx={styles.modal} container>
          <Grid container item xs={12} sm={12} md={12} lg={12}>
            <Paper
              sx={[
                {
                  backgroundColor: palette.laranjaOXLTransparente,
                  height: "50px",
                },
                styles.paper,
              ]}
            >
              <Typography
                sx={{
                  fontSize: "24px",
                  color: palette.preto,
                  fontFamily: "Poppins",
                  fontWeight: 500,
                }}
              >
                Cadastro de Laboratório
              </Typography>
            </Paper>
          </Grid>
          <Grid container spacing={2} sx={styles.box}>
            <Typography
              sx={{
                fontSize: "20px",
                color: palette.preto,
                fontFamily: "Poppins",
                fontWeight: 500,
                margin: "2px",
                textAlign: "left",
              }}
            >
              Dados dos responsáveis
            </Typography>
            <Grid item xs={12} md={12} sx={styles.field}>
              <Buscador
                onSelect={onSelectCoordenador}
                data={dataBuscadorCoordenador}
                multiple={false}
                label={"Selecione o coordenador por CPF"}
                sx={""}
              />
              <FormHelperText sx={{display: coordenadorError?'flex':'none', color: 'red'}} >{coordenadorError}</FormHelperText>
            </Grid>
            <Grid item xs={12} md={12} sx={styles.field}>
              <Typography sx={styles.subtitles}>
                Agentes do Laboratório
              </Typography>
              <div >
                <List sx={{maxHeight: "100px", overflow: 'auto'}}>
                  {/* {console.log("agentes", agentes)} */}
                  {agentes.map(
                    (nome, index) =>
                      nome.Aluno?.cpf !== coordenador && (
                        <ListItem key={index}>
                          <ListItemText 
                            primary={
                              nome.Aluno
                                ? <span style={{ fontSize: "0.9em" }}>{nome.Aluno.name} - {nome.Aluno.cpf}</span>
                                : <span style={{ fontSize: "0.9em" }}>{nome.name} - {nome.cpf}</span>
                            }
                          />
                          <ListItemSecondaryAction>
                            <Tooltip title="Excluir agente" placement="top">
                              <IconButton
                                // edge="end"
                                aria-label="delete"
                                onClick={() => excluirAgente(index)}
                              >
                                <CloseIcon />
                              </IconButton>
                              </Tooltip>
                              <Tooltip title="Super-agente" placement="top">
                              <IconButton
                                // edge="end"
                                aria-label="super agente"
                                onClick={() => habilitarSuperAgente(index)}
                              >
                                <AssistantPhotoIcon sx={{color: nome.super_agente? 'rgba(212, 109, 13,  1)': 'gray'}} />
                              </IconButton>
                            </Tooltip>
                          </ListItemSecondaryAction>
                        </ListItem>
                      )
                  )}
                </List>
              </div>
            </Grid>
            <Grid item xs={12} md={12} sx={styles.field}>
              {exibirCampoAdicionar ? (
                <div style={{ display: "flex" }}>
                  <Buscador
                    onSelect={onSelectAgentes}
                    data={dataBuscadorAgente}
                    label={"Selecione o agente por CPF"}
                    sx={{ width: "100%" }}
                  />
                  <Tooltip title="Voltar">
                    <IconButton
                      // edge="end"
                      aria-label="delete"
                      onClick={() => setExibirCampoAdicionar(false)}
                    >
                      <ArrowBackIcon />
                    </IconButton>
                  </Tooltip>
                </div>
              ) : (
                <div style={{ display: "flex", justifyContent: "flex-start" }}>
                  <Button
                    variant="text"
                    sx={{ color: palette.laranjaIntermediario2}}
                    
                    onClick={() => setExibirCampoAdicionar(true)}
                  >
                    Adicionar novo agente
                  </Button>
                </div>
              )}
            </Grid>
            {/* <EditAgentes
              alunos={dataBuscadorAgente}
              styles={styles}
              classes={classes}
              isEdit={false}
              coordenador={coordenador?.cpf}
            /> */}
          </Grid>

          <Grid container sx={styles.box}>
            <Typography
              sx={{
                fontSize: "20px",
                color: palette.preto,
                fontFamily: "Poppins",
                fontWeight: 500,
                marginBottom: "6px",
                textAlign: "left",
              }}
            >
              Endereço do Laboratório
            </Typography>

            <Grid container columns={2} sx={{ padding: "10px" }} spacing={1}>
              <Grid item xs={2} sm={2} md={1}>
                <Typography sx={styles.subtitles}>CEP</Typography>

                <InputMask
                  mask="99.999-999"
                  value={CEP}
                  maskChar=" "
                  onBlur={handleCep}
                  onChange={handleCep}
                >
                  {() => (
                    <TextField
                      hiddenLabel
                      id="cep"
                      autoComplete="cep"
                      variant="filled"
                      fullWidth
                      error={Boolean(errorCep?.CEP) || Boolean(CEPError)}
                      helperText={errorCep?.CEP || CEPError}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  )}
                </InputMask>
              </Grid>

              <Grid item xs={2} sm={2} md={1}>
                <Typography sx={styles.subtitles}>Rua</Typography>
                <TextField
                  hiddenLabel
                  id="rua"
                  variant="filled"
                  fullWidth
                  value={rua}
                  onChange={handlerua}
                  error={Boolean(ruaError)}
                  helperText={ruaError}
                  required
                />
              </Grid>

              <Grid item xs={2} sm={2} md={1}>
                <Typography sx={styles.subtitles}>Município</Typography>
                <TextField
                  hiddenLabel
                  id="municipio"
                  variant="filled"
                  fullWidth
                  value={municipio}
                  onChange={handlemunicipio}
                  error={Boolean(municipioError)}
                  helperText={municipioError}
                  required
                />
              </Grid>
              <Grid item xs={2} sm={2} md={1}>
                <Typography sx={styles.subtitles}>Bairro</Typography>
                <TextField
                  hiddenLabel
                  id="bairro"
                  variant="filled"
                  fullWidth
                  value={bairro}
                  onChange={handlebairro}
                  error={Boolean(bairroError)}
                  helperText={bairroError}
                  required
                />
              </Grid>
              <Grid item xs={2} md={2}>
                <Typography sx={styles.subtitles}>Complemento</Typography>
                <TextField
                  hiddenLabel
                  id="municipio"
                  variant="filled"
                  fullWidth
                  value={complemento}
                  onChange={(e) => setComplemento(e.target.value)}
                />
              </Grid>
              <Grid item xs={2} sm={2} md={1}>
                <Typography sx={styles.subtitles}>Número</Typography>
                <TextField
                  hiddenLabel
                  id="numero"
                  variant="filled"
                  fullWidth
                  value={numero}
                  onChange={handlenumero}
                  error={Boolean(numeroError)}
                  helperText={numeroError}
                  required
                />
              </Grid>

              <Grid item xs={2} sm={2} md={1}>
                <Typography sx={styles.subtitles}>
                  Unidade{" "}
                  <span style={{ fontSize: "1em", color: "grey" }}>
                    (Benedito Bentes, Maceió...)
                  </span>{" "}
                </Typography>
                <TextField
                  hiddenLabel
                  id="municipio"
                  variant="filled"
                  fullWidth
                  value={unidade}
                  onChange={(e) => setUnidade(e.target.value)}
                  error={Boolean(unidadeError)}
                  helperText={unidadeError}
                  required
                />
              </Grid>
            </Grid>
          </Grid>

          <Button
            startIcon={
              loading ? <CircularProgress color="inherit" size={20} /> : null
            }
            variant="contained"
            onClick={handleFinish}
            sx={{
              height: "40px",
              bgcolor: palette.laranjaIntermediario2,
    "&:hover": {
      bgcolor: palette.laranjaSecundario
  },
              marginTop: "5px",
            }}
          >
            Cadastrar Laboratório
          </Button>
        </Grid>
        <Snackbar
          open={alertUnauthorized.length > 0}
          autoHideDuration={6000}
          onClose={() => setAlertUnauthorized("")}
        >
          <Alert
            variant="filled"
            onClose={() => setAlertUnauthorized("")}
            severity="error"
            sx={{ width: "100%" }}
          >
            {alertUnauthorized}
          </Alert>
        </Snackbar>
        <Snackbar
          open={alertSuccessful.length > 0}
          autoHideDuration={6000}
          onClose={() => setAlertSuccessful("")}
        >
          <Alert
            variant="filled"
            onClose={() => setAlertSuccessful("")}
            severity="success"
            sx={{ width: "100%" }}
          >
            {alertSuccessful}
          </Alert>
        </Snackbar>
      </Box>
    </Modal>
  );
}

export default ModalCadastroLab;

import React, { useState } from "react";
import { Dialog, DialogActions, Button, Snackbar, Alert } from "@mui/material";
import logo from "../../../assets/logo.svg";
import { useHistory,useParams } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import api from "../../../../services/api";
function ModalConfirmar({ open, handleClose, idTurma, frequencia }) {
  const [loading, setLoading] = useState(false);
  const [frequenciaSucesso, setFrequenciaSucesso] = useState("");
  const [frequenciaError, setFrequenciaError] = useState("");
  const history = useHistory();
  const { escolaId} = useParams();

  const totalPresentes = frequencia.filter((aluno) => aluno.presente);
  const handleConfirmar = () => {
    let data = {
      frequencias: frequencia.map((aluno) => ({
        inscricao_id: aluno.id,
        presente: aluno.presente,
      })),
    };

    setLoading(true);
    api
      .patch(`/oxetechedu/turmas/frequencia/${idTurma}`, data)
      .then((response) => {
        setLoading(false);
        setFrequenciaSucesso("Frequência registrada e turma encerrada!");
        setTimeout(() => history.push(`/escola/${escolaId}`), [2000]);
        
      })
      .catch((error) => {
        setFrequenciaError(error.response.data.error);
        console.log(error.response.data);
      });
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          padding: "5%",
        }}
      >
        <img src={logo} alt="logoOxetechLab" width="30%" />
        <h2 style={{ textAlign: "center", fontSize: "1.3em" }}>
          Deseja registrar a frequência?
        </h2>
        <h4
          style={{
            fontWeight: "light",
            textAlign: "center",
            color: "rgb(0,0,0,0.8)",
          }}
        >
          Sua turma teve um total de{" "}
          <strong style={{ color: "#D46D0D" }}>{totalPresentes.length}</strong>{" "}
          alunos presentes. Ao confirmar, a turma será encerrada. Por favor,
          certifique-se de que está inserindo as informações corretas.
        </h4>

        <DialogActions>
          <Button
            onClick={handleConfirmar}
            startIcon={
              loading ? <CircularProgress color="inherit" size={20} /> : null
            }
            variant="contained"
            sx={{
              backgroundColor: "#D46D0D",

              color: "white",
              "&:hover": { backgroundColor: "rgba(212, 109, 13, 0.45)" },
              marginRight: "10%",
            }}
          >
            Confirmar
          </Button>
          <Button
            onClick={handleClose}
            variant="outlined"
            sx={{
              borderColor: "#D46D0D",
              color: "#D46D0D",
              "&:hover": { borderColor: "rgba(212, 109, 13, 0.45)" },
            }}
          >
            Cancelar
          </Button>
        </DialogActions>
      </div>
      <Snackbar
        open={frequenciaSucesso.length > 0}
        autoHideDuration={6000}
        onClose={() => setFrequenciaSucesso("")}
      >
        <Alert
          variant="filled"
          onClose={() => setFrequenciaSucesso("")}
          severity="success"
          sx={{ width: "100%" }}
        >
          {frequenciaSucesso}
        </Alert>
      </Snackbar>
      <Snackbar
        open={frequenciaError.length > 0}
        autoHideDuration={6000}
        onClose={() => setFrequenciaError("")}
      >
        <Alert
          variant="filled"
          onClose={() => setFrequenciaError("")}
          severity="error"
          sx={{ width: "100%" }}
        >
          {frequenciaError}
        </Alert>
      </Snackbar>
    </Dialog>
  );
}

export default ModalConfirmar;

import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import { makeStyles } from "@mui/styles";
import { styled } from "@mui/material/styles";
import { Link } from "react-router-dom";
import { Modal, message } from "antd";
import { Content } from "./styles";
import api from "../../services/api";
import logo_oxe from '../../assets/logo_oxeTechBlue.png'

import history from "../../services/history";
import {
  Tabs,
  Tab,
  Typography,
  Box,
  Button,
  TextField,
  Stack,
  Grid,
} from "@mui/material";
import * as palette from "../../utils/styledVariables";
import InputMask from "react-input-mask";
import { isValidCPF, isValidCNPJ } from "@brazilian-utils/brazilian-utils";

const WhiteBox = styled(Box)`
  width: 40%;
  /* height: 55vh; */
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: auto;
  border-radius: 0.5rem;
  color: black;
  overflow: hidden;
  font-size: 20px;
  text-align: center;
  background: rgba(255, 255, 255, 89%);
  animation: fadeIn;
  animation-duration: 1s;

  padding: 35px;

  @media (min-width: 1500px) {
    width: 25vw;
  }

  @media (max-width: 800px) {
    width: 60vw;
  }

  @media (max-width: 600px) {
    width: 90vw;
  }

  @media (min-height: 900px) {
    height: 60vh;
  }

  @media (max-height: 700px) {
    height: 75vh;
  }

  @media (max-height: 550px) {
    height: 85vh;
  }

  @media (max-height: 500px) {
    height: 95vh;
  }
`;

const ColorButton = styled(Button)(({ theme }) => ({
  color: "#fff",
  heith: "2vh",
  backgroundColor: palette.laranjaIntermediario2,
  "&:hover": {
    backgroundColor: palette.laranjaSecundario
},
  // backgroundColor: "#741f8a",
  // "&:hover": {
  //   backgroundColor: "#6B85C2",
  // },
}));

const StyledTabs = styled(Tabs)({
  "& .MuiTabs-indicator": {
    backgroundColor: palette.laranjaIntermediario2,
  },
});

const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: "none",
    minWidth: 0,
    [theme.breakpoints.up("sm")]: {
      minWidth: 0,
    },
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(1),
    color: palette.laranjaIntermediario2,
      //palette.profileSecondaryColor,
    "&:hover": {
      color: palette.laranjaIntermediario2,
        //palette.profileSecondaryColor,
      opacity: 1,
    },
    "&.Mui-selected": {
      color: palette.laranjaIntermediario2,
        //palette.profileSecondaryColor,
      fontWeight: theme.typography.fontWeightMedium,
    },
    "&.Mui-focusVisible": {
      backgroundColor: palette.laranjaIntermediario2,
      //palette.profileSecondaryColor,
    },
  })
);

const UseStyles = makeStyles((theme) => ({
  root: {
    overflow: "hidden",
    background: palette.gradienteLaranja180,
     // "linear-gradient(180deg,rgba(116, 31, 138, 0.9) 35%,rgba(0, 0, 99, 1) 100%)",
    height: "100vh",
  },
  paperContainer: {
    backgroundImage: `url(/assets/backgroundSelect.png)`,
    height: "100vh",
    minWidth: "100vw",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const unmask = (raw) => {
  return raw.replace(/[^0-9]/g, "");
};

export default function EmailRecovery() {
  const [cpf, setCPF] = React.useState(null);
  const [errorCPF, setErrorCPF] = React.useState(null);

  const [cnpj, setCNPJ] = React.useState(null);
  const [errorCNPJ, setErrorCNPJ] = React.useState(null);

  const [email, setEmail] = React.useState(null);
  const [errorEmail, setErrorEmail] = React.useState(null);
  const [emailConfirm, setEmailConfirm] = React.useState(null);

  const [password, setPassword] = React.useState(null);

  const [value, setValue] = React.useState(0);

  const onFinishPF = async () => {
    const error = errorEmail?.email || errorCPF?.cpf;
    if (email && password && cpf && !error) {
      const unmasked = unmask(cpf);
      try {
        await api.put("aluno/recover-access", {
          email: email,
          password: password,
          cpf: unmasked,
        });

        success();
      } catch (err) {
        message.error(err.response.data);
      }
      return;
    }
    message.error("Erro. Verifique os dados informados.");
  };

  const onFinishPJ = async () => {
    const error = errorEmail?.email || errorCNPJ?.cnpj;
    if (email && password && cnpj && !error) {
      const unmasked = unmask(cnpj);
      try {
        await api.put("empresa/recover-access", {
          email: email,
          password: password,
          cnpj: unmasked,
        });

        success();
      } catch (err) {
        message.error(err.response.data);
      }
      return;
    }

    message.error("Erro. Verifique os dados informados.");
  };

  const success = () => {
    Modal.success({
      content: `Seu email foi alterado.`,
      onOk() {
        history.push("/login");
      },
    });
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleCNPJ = (event) => {
    const {
      target: { value },
    } = event;
    setCNPJ(value);
    setErrorCNPJ({ cnpj: "" });
    if (value.trim().length > 0) {
      let reg = new RegExp(/^\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2}$/);
      if (!reg.test(value) || !isValidCNPJ(value)) {
        setErrorCNPJ({ cnpj: "CNPJ inválido." });
      }
    } else {
      setErrorCNPJ({ cnpj: "Campo obrigatório." });
    }
  };

  const handlePassword = (event) => {
    const {
      target: { value },
    } = event;
    setPassword(value);
  };

  const handleEmail = (event) => {
    const {
      target: { value },
    } = event;
    setEmail(value);
    setErrorEmail({ email: "" });
    if (value.length < 1) {
      setErrorEmail({ email: "Campo obrigatório." });
    } else if (email != emailConfirm) {
      setErrorEmail({
        email: "Os e-mails não coincidem. Verifique novamente.",
      });
    }
  };

  const handleEmailConfirm = (event) => {
    const {
      target: { value },
    } = event;
    setEmailConfirm(value);
    setErrorEmail({ email: "" });
    if (value.length < 1) {
      setErrorEmail({ email: "Campo obrigatório." });
    } else if (email != emailConfirm) {
      setErrorEmail({
        email: "Os e-mails não coincidem. Verifique novamente.",
      });
    }
  };

  const handleCPF = (event) => {
    const {
      target: { value },
    } = event;
    setCPF(value);
    setErrorCPF({ cpf: "" });
    if (value.trim().length > 0) {
      let reg = new RegExp(/^\d{3}\.\d{3}\.\d{3}-\d{2}$/).test(value);
      if (!reg || !isValidCPF(value)) {
        setErrorCPF({ cpf: "CPF inválido." });
      }
    } else {
      setErrorCPF({ cpf: "Campo obrigatório." });
    }
  };

  const handleDisableCopyPaste = (e) => {
    if (e.ctrlKey === true && e.keyCode === 86 ){
       e.preventDefault()
    }
 }

  const classes = UseStyles();

  return (
    <React.Fragment>
      <CssBaseline />
      <div className={classes.root}>
        <div className={classes.paperContainer} />
        <WhiteBox>
          <Content>
            <img
              className="img-resp"
              src={logo_oxe}
              alt="Logo OxeTechLab"
            ></img>
            <Typography
              variant="h4"
              component="div"
              gutterBottom
              sx={{
                // mt: 4,

                fontSize: "25px",
              }}
            >
              Alterar email cadastrado
            </Typography>
            <StyledTabs
              value={value}
              onChange={handleChange}
              centered
              variant="fullWidth"
            >
              <StyledTab label="PESSOA FÍSICA" />
              <StyledTab label="PESSOA JURÍDICA" />
            </StyledTabs>
            <TabPanel value={value} index={0}>
              <Grid container spacing={2} columns={1} sx={{ mt: 2 }}>
                <Grid item xs={1}>
                  <TextField
                    color="secondary"
                    id="email"
                    name="email"
                    label="Novo e-mail"
                    value={email}
                    onChange={handleEmail}
                    onBlur={handleEmail}
                    onKeyDown = {handleDisableCopyPaste}
                    error={Boolean(errorEmail?.email)}
                    helperText={errorEmail?.email}
                    required
                    fullWidth
                  />{" "}
                </Grid>{" "}
                <Grid item xs={1}>
                  <TextField
                    color="secondary"
                    id="email-confirm"
                    name="email-confirm"
                    label="Confirme o e-mail"
                    value={emailConfirm}
                    onChange={handleEmailConfirm}
                    onBlur={handleEmailConfirm}
                    onKeyDown = {handleDisableCopyPaste}
                    error={Boolean(errorEmail?.email)}
                    helperText={errorEmail?.email}
                    required
                    fullWidth
                  />
                </Grid>{" "}
                <Grid item xs={1}>
                  <InputMask
                    mask="999.999.999-99"
                    value={cpf}
                    disabled={false}
                    maskChar=" "
                    onBlur={handleCPF}
                    onChange={handleCPF}
                  >
                    {() => (
                      <TextField
                        color="secondary"
                        id="cpf"
                        name="cpf"
                        label="CPF"
                        error={Boolean(errorCPF?.cpf)}
                        helperText={errorCPF?.cpf}
                        required
                        fullWidth
                      />
                    )}
                  </InputMask>
                </Grid>{" "}
                <Grid item xs={1}>
                  <TextField
                    color="secondary"
                    id="senha"
                    name="senha"
                    label="Senha cadastrada"
                    value={password}
                    onChange={handlePassword}
                    onBlur={handlePassword}
                    required
                    fullWidth
                    type="password"
                  />
                </Grid>
                <Grid container item columns={1} direction="row">
                  <Grid item xs={1}>
                    <ColorButton block onClick={onFinishPF} sx={{mr: 2}}>
                      Confirmar
                    </ColorButton>
                    <Link to="/login" type="button" style={{ color: "black" }}>
                      <ColorButton block type="submit">
                        Voltar
                      </ColorButton>
                    </Link>
                  </Grid>
                </Grid>
              </Grid>
            </TabPanel>

            <TabPanel value={value} index={1}>
              <Grid container spacing={2} columns={1} sx={{ mt: 2 }}>
                <Grid item xs={1}>
                  <TextField
                    color="secondary"
                    id="email"
                    name="email"
                    label="Novo e-mail"
                    value={email}
                    onChange={handleEmail}
                    onBlur={handleEmail}
                    onKeyDown = {handleDisableCopyPaste}
                    error={Boolean(errorEmail?.email)}
                    helperText={errorEmail?.email}
                    required
                    fullWidth
                  />{" "}
                </Grid>{" "}
                <Grid item xs={1}>
                  <TextField
                    color="secondary"
                    id="email-confirm"
                    name="email-confirm"
                    label="Confirme o e-mail"
                    value={emailConfirm}
                    onChange={handleEmailConfirm}
                    onBlur={handleEmailConfirm}
                    onKeyDown = {handleDisableCopyPaste}
                    error={Boolean(errorEmail?.email)}
                    helperText={errorEmail?.email}
                    required
                    fullWidth
                  />
                </Grid>{" "}
                <Grid item xs={1}>
                  <InputMask
                    mask="99.999.999/9999-99"
                    value={cnpj}
                    disabled={false}
                    maskChar=" "
                    onBlur={handleCNPJ}
                    onChange={handleCNPJ}
                  >
                    {() => (
                      <TextField
                        required
                        id="cnpj"
                        name="cnpj"
                        label="CNPJ"
                        fullWidth
                        color="secondary"
                        error={Boolean(errorCNPJ?.cnpj)}
                        helperText={errorCNPJ?.cnpj}
                      />
                    )}
                  </InputMask>
                </Grid>{" "}
                <Grid item xs={1}>
                  <TextField
                    color="secondary"
                    id="senha"
                    name="senha"
                    label="Senha cadastrada"
                    value={password}
                    onChange={handlePassword}
                    onBlur={handlePassword}
                    required
                    fullWidth
                    type="password"
                  />
                </Grid>
                <Grid item xs={1}>
                  <ColorButton block onClick={onFinishPJ} sx={{ mr: 2 }}>
                    Confirmar
                  </ColorButton>
                  <Link to="/login" type="button" style={{ color: "black" }}>
                    <ColorButton block type="submit">
                      Voltar
                    </ColorButton>
                  </Link>
                </Grid>
              </Grid>
            </TabPanel>
          </Content>
        </WhiteBox>
      </div>
    </React.Fragment>
  );
}

import styled from 'styled-components'

export const Content = styled.div`
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 auto;
  .ant-form-item-label > label {
    width: 340px;
    font-size: 16px;
  }
  img {
    width: 40%;
  }
  @media (max-width: 700px) {
  
    /* .ant-form-item-control-input-content {
      width: '99%';
    } */
  }
`
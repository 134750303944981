import {
    Box,
    Button,
    Grid,
    Modal,
    Paper,
    TextField,
    Typography,
    FormHelperText
  } from "@mui/material";
  import Alert from "@mui/material/Alert";
  import CircularProgress from "@mui/material/CircularProgress";
  import Snackbar from "@mui/material/Snackbar";
  import { makeStyles } from "@mui/styles";
  import cep from "cep-promise";
  import React, { useEffect, useState } from "react";
  import InputMask from "react-input-mask";
  import api from "../../../../services/api";
  import * as palette from "../../../../utils/styledVariables";
  import Buscador from "../../../../components/Buscador/index";
import { useSnackbar } from "notistack";


  import {
    List,
    ListItem,
    ListItemText,
    ListItemSecondaryAction,
    Tooltip,
    IconButton,
  } from "@mui/material";
  import CloseIcon from "@mui/icons-material/Close";
  import ArrowBackIcon from "@mui/icons-material/ArrowBack";
  import AssistantPhotoIcon from "@mui/icons-material/AssistantPhoto";
  const useStyles = makeStyles({
    autocomplete: {
      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "purple",
      },
      "& label.Mui-focused": {
        color: "purple",
      },
    },
  });
  
  const styles = {
    modal: {
      width: "50%",
      position: "absolute",
      top: "50%",
      marginTop: "5%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      bgcolor: "#f2f2f2",
      padding: "14px",
      textAlign: "center",
      borderRadius: "1vh",
      justifyContent: "center",
      overflow: "auto",
    },
  
    uploadButton: {
      height: "40px",
      color: palette.laranjaIntermediario2,
      "&:hover": {
      color: palette.laranjaSecundario
    },
    },
  
    paper: {
      width: "100%",
      margin: "5px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  
    box: {
      backgroundColor: palette.laranjaOXLTransparente,
      padding: "10px",
      margin: "5px",
      borderRadius: "1vh",
      display: "flex",
      // flexDirection: "column",
    },
    subtitles: {
      fontFamily: "Roboto",
      fontStyle: "bold",
      fontWeight: "500",
      fontSize: "1.5vh",
      lineHeight: "2.5vh",
      margin: "0px 0px 5px 0px",
      textAlign: "left",
    },
  
    field: { padding: "5px" },
  };
  
  function ModalCadastroEscola(props) {
  const { enqueueSnackbar } = useSnackbar();
    const [rua, setRua] = useState(sessionStorage.getItem("rua_lab") || "");
    const [bairro, setBairro] = useState(
      sessionStorage.getItem("bairro_lab") || ""
    );
    const [state, setState] = useState(sessionStorage.getItem("state") || "");
    const [municipio, setMunicipio] = useState(
      sessionStorage.getItem("municipio") || ""
    );
    const [numero, setNumero] = useState(sessionStorage.getItem("numero") || "");
    const [complemento, setComplemento] = useState(
      JSON.parse(sessionStorage.getItem("complemento")) || ""
    );
    const [CEP, setCEP] = useState(sessionStorage.getItem("CEP") || "");
    const [unidade, setUnidade] = useState(
      sessionStorage.getItem("unidade") || ""
    );
    const [coordenador, setCoordenador] = useState(null);
    const [agentes, setAgentes] = useState(null);
    const [ruaError, setRuaError] = useState("");
    const [bairroError, setBairroError] = useState("");
    const [municipioError, setMunicipioError] = useState("");
    const [numeroError, setNumeroError] = useState("");
    const [CEPError, setCEPError] = useState("");
    const [unidadeError, setUnidadeError] = useState("");
    const [coordenadorError, setCoordenadorError] = useState("");
  
    const [alertSuccessful, setAlertSuccessful] = useState("");
    const [alertUnauthorized, setAlertUnauthorized] = useState("");
  
    const [address, setAddress] = useState(null);
    const [errorCep, setErrorCep] = useState(null);
    const [alunos, setAlunos] = useState([]);
    const alunos_sem_coordenador = alunos?.filter(
      (obj) => obj.id !== coordenador?.id
    );
    const [exibirCampoAdicionar, setExibirCampoAdicionar] = useState(false);
  
    const [loading, setLoading] = useState(false);
  
    const classes = useStyles();
    const handleClose = () => {
      props.close();
    };
  
    const handleCep = (event) => {
      setCEP(event.target.value);
      setErrorCep({ CEP: "" });
  
      let reg = new RegExp(/^[0-9]{2}\.[0-9]{3}-[0-9]{3}$/).test(
        event.target.value
      );
      if (reg) {
        cep(event.target.value)
          .then((response) => {
            const {
              street: rua,
              neighborhood: bairro,
              city: municipio,
              cep,
              state: estado,
            } = response;
  
            setRua(rua);
            setBairro(bairro);
            setMunicipio(municipio);
            setState(estado);
          })
          .catch((error) => {
            setErrorCep({ CEP: "Não conseguimos resgatar o seu CEP." });
            setAddress({
              rua: "",
              bairro: "",
              municipio: "",
              cep: "",
              state: "",
              numero: "",
            });
          });
      } else {
        setErrorCep({ CEP: "CEP inválido." });
      }
    };
  
    const handlerua = (event) => {
      setRua(event.target.value);
    };
  
    const handlebairro = (event) => {
      setBairro(event.target.value);
    };
  
    const handlenumero = (event) => {
      setNumero(event.target.value);
      if (event.target.value.length > 0)
        sessionStorage.setItem("numero", event.target.value);
      else {
        sessionStorage.removeItem("numero");
      }
    };
  
    const handlemunicipio = (event) => {
      setMunicipio(event.target.value);
    };
  
    const handleFinish = async () => {
      let isValid = true;
    
      if (!coordenador) {
        setCoordenadorError("Campo obrigatório");
        isValid = false;
      } else {
        setCoordenadorError("");
      }
  
      if (!rua) {
        setRuaError("Campo obrigatório");
        isValid = false;
      } else {
        setRuaError("");
      }
  
      if (!bairro) {
        setBairroError("Campo obrigatório");
        isValid = false;
      } else {
        setBairroError("");
      }
  
      if (!municipio) {
        setMunicipioError("Campo obrigatório");
        isValid = false;
      } else {
        setMunicipioError("");
      }
  
      if (!numero) {
        setNumeroError("Campo obrigatório");
        isValid = false;
      } else {
        setNumeroError("");
      }
  

      if (isValid) {
        
        const data = {
          nome: unidade,
          rua: rua,
          municipio: municipio,
          bairro: bairro,
          numero: numero,
          cep: CEP.replace(/[.-]/g, ""),
          complemento: complemento,
          professor_id: coordenador.id,
          bolsista_id: agentes.id,
        };
        setLoading(true);
        api
          .post("/oxetechedu/escola", data)
          .then((response) => {
            setLoading(false);
            setAlertSuccessful(response.data.message);
  
            sessionStorage.removeItem("rua_lab");
            sessionStorage.removeItem("bairro_lab");
            sessionStorage.removeItem("state");
            sessionStorage.removeItem("municipio");
            sessionStorage.removeItem("numero");
            sessionStorage.removeItem("CEP");
            sessionStorage.removeItem("unidade");
            sessionStorage.removeItem("coordenador");
            sessionStorage.removeItem("agentes");
            enqueueSnackbar("Escola cadastrada com sucesso!", {
              variant: "success",
            });
            setTimeout(() => {
              handleClose();
            }, 1000);
          })
          .catch((err) => {
            setLoading(false);
            setAlertUnauthorized(err.response.data.error);
          });
      }
    };
  
    useEffect(() => {
      api.get("alunos").then((res) => {
        setAlunos(res.data);
      });
    }, []);
  
    useEffect(() => {
      // Salvar os valores dos estados no sessionStorage
      sessionStorage.setItem("rua_lab", rua);
      sessionStorage.setItem("bairro_lab", bairro);
      sessionStorage.setItem("state", state);
      sessionStorage.setItem("municipio", municipio);
      sessionStorage.setItem("numero", numero);
      sessionStorage.setItem("CEP", CEP);
  
    }, [
      rua,
      bairro,
      state,
      municipio,
      numero,
      CEP,
    ]);
  
    const onSelectCoordenador = (e, newValue) => {
      if (newValue) {
        setCoordenador(newValue.value);
      }
    };
    const onSelectAgentes = (e, newValue) => {
      if (newValue) {
        setAgentes(newValue.value);
      }
    };
    const dataBuscadorCoordenador = alunos.map((obj) => ({
      name: obj.name,
      label: obj.cpf,
      value: obj,
    }));
    const dataBuscadorAgente = alunos_sem_coordenador.map((obj) => ({
      name: obj.name,
      label: obj.cpf,
      value: obj,
    }));
  
    return (
      <Modal
        open={props.open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          overflow: "auto",
        }}
      >
        <Box sx={{}}>
          <Grid sx={styles.modal} container>
            <Grid container item xs={12} sm={12} md={12} lg={12}>
              <Paper
                sx={[
                  {
                    backgroundColor: palette.laranjaOXLTransparente,
                    height: "50px",
                  },
                  styles.paper,
                ]}
              >
                <Typography
                  sx={{
                    fontSize: "24px",
                    color: palette.preto,
                    fontFamily: "Poppins",
                    fontWeight: 500,
                  }}
                >
                  Cadastro de Escola
                </Typography>
              </Paper>
            </Grid>
            <Grid container spacing={2} sx={styles.box}>
              <Typography
                sx={{
                  fontSize: "20px",
                  color: palette.preto,
                  fontFamily: "Poppins",
                  fontWeight: 500,
                  margin: "2px",
                  textAlign: "left",
                }}
              >
                Dados dos responsáveis
              </Typography>
              <Grid item xs={12} md={12} sx={styles.field}>
                <Buscador
                  onSelect={onSelectCoordenador}
                  data={dataBuscadorCoordenador}
                  multiple={false}
                  label={"Selecione o professor por CPF"}
                  sx={""}
                />
                <FormHelperText sx={{display: coordenadorError?'flex':'none', color: 'red'}} >{coordenadorError}</FormHelperText>
              </Grid>
              
              <Grid item xs={12} md={12} sx={styles.field}>
                <Buscador
                  onSelect={onSelectAgentes}
                  data={dataBuscadorAgente}
                  multiple={false}
                  label={"Selecione o bolsista por CPF"}
                  sx={""}
                />
                <FormHelperText sx={{display: coordenadorError?'flex':'none', color: 'red'}} >{coordenadorError}</FormHelperText>
              </Grid>
              
            </Grid>
  
            <Grid container sx={styles.box}>
              <Typography
                sx={{
                  fontSize: "20px",
                  color: palette.preto,
                  fontFamily: "Poppins",
                  fontWeight: 500,
                  marginBottom: "6px",
                  textAlign: "left",
                }}
              >
                Dados da Escola
              </Typography>
  
              <Grid container columns={2} sx={{ padding: "10px" }} spacing={1}>
              <Grid item xs={2} sm={2} md={1}>
                <Typography sx={styles.subtitles}>
                  Nome da Instituição{" "}
                </Typography>
                <TextField
                  hiddenLabel
                  id="municipio"
                  variant="filled"
                  fullWidth
                  value={unidade}
                  onChange={(e) => setUnidade(e.target.value)}
                  error={Boolean(unidadeError)}
                  helperText={unidadeError}
                  required
                />
              </Grid>
                <Grid item xs={2} sm={2} md={1}>
                  <Typography sx={styles.subtitles}>CEP</Typography>
  
                  <InputMask
                    mask="99.999-999"
                    value={CEP}
                    maskChar=" "
                    onBlur={handleCep}
                    onChange={handleCep}
                  >
                    {() => (
                      <TextField
                        hiddenLabel
                        id="cep"
                        autoComplete="cep"
                        variant="filled"
                        fullWidth
                        error={Boolean(errorCep?.CEP) || Boolean(CEPError)}
                        helperText={errorCep?.CEP || CEPError}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    )}
                  </InputMask>
                </Grid>
  
                <Grid item xs={2} sm={2} md={1}>
                  <Typography sx={styles.subtitles}>Rua</Typography>
                  <TextField
                    hiddenLabel
                    id="rua"
                    variant="filled"
                    fullWidth
                    value={rua}
                    onChange={handlerua}
                    error={Boolean(ruaError)}
                    helperText={ruaError}
                    required
                  />
                </Grid>
  
                <Grid item xs={2} sm={2} md={1}>
                  <Typography sx={styles.subtitles}>Município</Typography>
                  <TextField
                    hiddenLabel
                    id="municipio"
                    variant="filled"
                    fullWidth
                    value={municipio}
                    onChange={handlemunicipio}
                    error={Boolean(municipioError)}
                    helperText={municipioError}
                    required
                  />
                </Grid>
                <Grid item xs={2} sm={2} md={1}>
                  <Typography sx={styles.subtitles}>Bairro</Typography>
                  <TextField
                    hiddenLabel
                    id="bairro"
                    variant="filled"
                    fullWidth
                    value={bairro}
                    onChange={handlebairro}
                    error={Boolean(bairroError)}
                    helperText={bairroError}
                    required
                  />
                </Grid>
                <Grid item xs={2} md={2}>
                  <Typography sx={styles.subtitles}>Complemento</Typography>
                  <TextField
                    hiddenLabel
                    id="municipio"
                    variant="filled"
                    fullWidth
                    value={complemento}
                    onChange={(e) => setComplemento(e.target.value)}
                  />
                </Grid>
                <Grid item xs={2} sm={2} md={1}>
                  <Typography sx={styles.subtitles}>Número</Typography>
                  <TextField
                    hiddenLabel
                    id="numero"
                    variant="filled"
                    fullWidth
                    value={numero}
                    onChange={handlenumero}
                    error={Boolean(numeroError)}
                    helperText={numeroError}
                    required
                  />
                </Grid>
              </Grid>
            </Grid>
  
            <Button
              startIcon={
                loading ? <CircularProgress color="inherit" size={20} /> : null
              }
              variant="contained"
              onClick={handleFinish}
              sx={{
                height: "40px",
                bgcolor: palette.laranjaIntermediario2,
      "&:hover": {
        bgcolor: palette.laranjaSecundario
    },
                marginTop: "5px",
              }}
            >
              Cadastrar Escola
            </Button>
          </Grid>
          <Snackbar
            open={alertUnauthorized.length > 0}
            autoHideDuration={6000}
            onClose={() => setAlertUnauthorized("")}
          >
            <Alert
              variant="filled"
              onClose={() => setAlertUnauthorized("")}
              severity="error"
              sx={{ width: "100%" }}
            >
              {alertUnauthorized}
            </Alert>
          </Snackbar>
          <Snackbar
            open={alertSuccessful.length > 0}
            autoHideDuration={6000}
            onClose={() => setAlertSuccessful("")}
          >
            <Alert
              variant="filled"
              onClose={() => setAlertSuccessful("")}
              severity="success"
              sx={{ width: "100%" }}
            >
              {alertSuccessful}
            </Alert>
          </Snackbar>
        </Box>
      </Modal>
    );
  }
  
  export default ModalCadastroEscola;
  
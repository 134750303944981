import React, { useEffect } from 'react';

import Box from '@mui/material/Box';
import RoomIcon from '@mui/icons-material/Room';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { makeStyles } from '@material-ui/styles';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { alpha, styled } from '@mui/material/styles';
import Options from '../../../utils/municipios';
import { useSelector } from 'react-redux'

const InputLabelCustom = styled(InputLabel)(({ theme }) => ({
    color: 'white',
    '&.MuiInputLabel-root': {
        '&.Mui-focused': {
            color: `pink`,
        },
    },
}));

const useStyles = makeStyles({
    standardSelect: {
        color: '#FFFFFF !important',
    },
})

function Municipio() {
    const profile = useSelector(state => state.user.profile);
    const [municipio, setMunicipio] = React.useState("");
    const [editState, setEditState] = React.useState(false);
    const [openState, setOpenState] = React.useState(false);

    const handleChange = (event) => {
        profile.dataChanged = true;
        let cidade = capitalizeWords(event.target.value)
        cidade = cidade.replace(' Da ', ' da ')
        cidade = cidade.replace(' De ', ' de ')
        cidade = cidade.replace(' Do ', ' do ')
        setMunicipio(cidade);
        profile.municipio = cidade
    };

    function capitalizeWords(words) {
        words= words.split(" ");
        for (let i = 0; i < words.length; i++) {
            words[i] = words[i][0].toUpperCase() + words[i].substr(1);
        }

        return words.join(' ')
    }

    useEffect(() => {
        if (profile.municipio ){
            let cidade = capitalizeWords(profile.municipio.toLowerCase())
            cidade = cidade.replace(' Da ', ' da ')
            cidade = cidade.replace(' De ', ' de ')
            cidade = cidade.replace(' Do ', ' do ')
            setMunicipio(cidade);
        }
    }, [])

    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                flexWrap: 'wrap',
            }}
        >
            <Box
                sx={{
                    display: !editState ? 'flex' : 'none',
                    alignItems: 'center'
                }}
                onClick={() => {setEditState(true);}}
                onFocus={() => {setEditState(true);}}
            >
                <RoomIcon
                    sx={{ color: '#FFFFFF' }}
                />
                <Typography
                    tabIndex="0"
                    noWrap
                    variant="body2"
                    sx={{
                        p: 0.5,
                        color: '#FFFFFF',
                        fontFamily: 'Roboto',
                        textTransform: 'capitalize',
                    }}
                >
                    {profile.municipio.toLowerCase() + ' - AL'}
                </Typography>
            </Box>
            <Box
                sx={{
                    display: editState ? 'flex' : 'none',
                    py: 1,
                    width: 210,
                }}
            >
                <FormControl
                    fullWidth
                    variant="standard"
                    sx={{border: '1px dashed pink'}}
                >
                    <InputLabelCustom
                        id="demo-simple-select-label"
                    >
                        Municipio:
                    </InputLabelCustom>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={municipio}
                        defaultValue={municipio}
                        label="Municipio:"
                        onOpen={() => setOpenState(true)}
                        onClose={() => setOpenState(false)}
                        open={openState}
                        onChange={handleChange}
                        onBlur={() => {
                            setEditState(false);
                        }}
                    >
                        {
                            Options.map((item, index) => {

                                return (
                                    <MenuItem
                                        key={index}
                                        value={item}>{item}
                                    </MenuItem>
                                )
                            })
                        }
                    </Select>
                </FormControl>
            </Box>
        </Box>
    )
}

export default Municipio
import styled from 'styled-components'

export const Container = styled.div`
  background: linear-gradient(
    179.73deg,
    #ffffff 15.07%,
    #d6ddee 85.51%,
    #6b85c2 176.06%,
    #8298cb 176.06%
  ); 
  height: 100%;
  min-height: 100vh;
  /* padding: 1em; */
  /* overflow-y: scroll; */
  /* overflow: hidden ;    */
  .card{
    display: flex;
    justify-content: center;
    align-items: center;
  }

`

export const Logo = styled.div`
  width: 100%;
  padding: 2%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  .img {
    width: 40%;
    
    @media (max-width: 700px) {
      width: 50%;
    }
  }
  .subtitulo {
    font-family: 'Karla', sans-serif;
    color: black;
    font-size: 1.2rem;
    font-weight: bold;
    text-align: center;
  }
`
import styled from "styled-components";
import Modal from "@mui/material/Modal";
import { Select } from "antd";

export const SelectSchool = styled(Select)`
  width: 100%;
`;
export const ModalFirst = styled(Modal)`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100%;
  .caixa {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    border-radius: 15px;
    padding: 2%;
    width: 40%;
    flex-direction: column;
  }
  .header {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    h2 {
      margin-bottom: 0;
      font-size: 1.5em;
    }
    h4 {
      text-align: center;
      color: gray;
    }
  }
  .logo {
    width: 40%;
    margin-bottom: 3%;
  }
  h2 {
    font-family: "Poppins", sans-serif;
    font-size: 1.8em;
    margin-bottom: 5%;
    @media (max-width: 720px) {
      font-size: 1.4em;
      text-align: center;
    }
  }

  .botao {
    background: #f27420;
    margin-top: 8px;
    color: white
  }
  .botao:hover {
    background: #f6931c;
  }
`;

import styled from 'styled-components'
import { motion } from 'framer-motion'

export const Container = styled.div``

export const SectionOne = styled.div`
  -webkit-user-drag: none;
  background: linear-gradient(
    180deg,
    rgba(116, 31, 138, 0.9) 35%,
    rgba(0, 0, 99, 1) 100%
  );
  background-size: cover;

  overflow: hidden;
  height: 100vh;
  padding: 10vh;
  display: flex;
  text-align: center;
  position: relative;
  p {
    color: rgba(255, 255, 255, 0.9);
    font-size: 1.5em;
  }
  .hat {
    position: absolute;
    z-index: 1000;
    left: 45%;
    bottom: 80%;
  }
`

export const TriangleFrame = styled(motion.img)`
  position: relative;
  right: 120%;
  bottom: 200%;
  width: 500vw;
  height: 450vh;
  @media (min-width: 1500px) {
    right: 160%;
  }
`

export const LogoOxeDin = styled(motion.img)`
  position: absolute;
  width: 28vw;
`

export const LogoOxeTechLab = styled(motion.img)`
  width: 28vw;
`

export const LogoOxeTechWork = styled(motion.img)`
  width: 28vw;
`

export const LogoOxeTechHat = styled(motion.img)`
  width: 12rem;
  @media (min-width: 1500px) {
    width: 20rem;
  }
`

export const InovationMap = styled(motion.img)`
  position: absolute;
  width: 18vw;
`

export const Text = styled(motion.div)`
  position: absolute;
  z-index: 1000;
  width: 90%;
  top: 30%;
  display: flex;
  align-items: center;
  margin-left: 2%;
  margin-right: 2%;
  a {
    color: rgba(255, 255, 255, 0.7);
    font-size: 80%;

    transition: color 0.2s ease;
    &:hover {
      color: rgba(255, 255, 255, 1);
    }
  }
  .typ {
    width: 45%;
    color: rgba(255, 255, 255, 1);
    font-size: 120%;
    @media (min-width: 1500px) {
      font-size: 200%;
    }
  }
  .publico {
    width: 45%;
    margin-left: 5%;
    margin-bottom: 3%;

    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .body1 {
    font-size: 100%;
    @media (min-width: 1500px) {
      font-size: 150%;
    }
  }
`

export const navegator = styled(motion.p)``

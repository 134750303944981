import { styled } from '@mui/material/styles'
import { Box } from '@mui/system'
import 'animate.css'

export const Container = styled('div')`
  background: linear-gradient(180deg, #1976D2 48.1%, #340E74 89.08%, #000067 122.33%);
  overflow-y: auto;
  overflow-x: hidden;
  height: 100vh;
  /* width */
  ::-webkit-scrollbar {
    width: 10px;
  }
  
  ::-webkit-scrollbar-track {
    background: #f1f1f16f;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #8f54a0;
    border-radius: 10px;
    /* border: 3px solid #ffffff; */
  }

`

export const Introducao = styled(Box)`
  width: 100%;
  height: auto;

  background-image: url(/assets/inovation.png);
  background-color: #741f8a;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 2.5vh 5vw;

  .grow {
    @media (min-width: 700px) {
      flex-grow: 1;
    }
  }
  @media (max-width: 800px) {
    padding: 0;
    justify-content: flex-end;
    flex-direction: column-reverse;
  }

  @media (max-width: 380px) {
    padding: 0;
    justify-content: flex-end;
    flex-direction: column-reverse;
  }
`

export const Title = styled(Box)`
  width: 50vw;
  padding-right: 5vw;
  margin: 2vh;
  font-family: 'Poppins', sans-serif;
  .titulo {
    color: #fff;
    font-size: 2.7rem;
    /* animation: fadeInUp;
    animation-duration: 3s; */
    animation: myAnim 2s ease 0s 1 normal forwards;
    @media (max-width: 800px) {
      text-align: center;
    }
    @media (min-width: 1500px) {
      font-size: 4rem;
    }
    @keyframes myAnim {
      0% {
        opacity: 0;
        transform: translateY(100px);
      }

      100% {
        opacity: 1;
        transform: translateY(0);
      }
    }
  }

  .subtitulo {
    color: rgba(255, 255, 255, 0.9);
    font-family: 'Poppins', sans-serif;
    font-size: 0.9rem;
    animation: fadeInUp;
    animation-duration: 3s;
    @media (max-width: 800px) {
      text-align: center;
    }
    @media (min-width: 1800px) {
      font-size: 1.5rem;
    }
  }
  @media (max-width: 800px) {
    width: 90vw;
  }
`

export const Programas = styled(Box)`
  width: 100%;
  height: auto;
  background: radial-gradient(
    326.37% 326.37% at 42.99% 227.91%,
    rgba(0, 0, 99, 0.7) 16.71%,
    rgba(142, 36, 170, 0.7) 85.66%
  );
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  .titulo {
    font-size: 400%;
    font-family: 'Poppins', sans-serif;
    color: white;
    @media (min-width: 1500px) {
      font-size: 500%;
    }
    @media (max-width: 1000px) {
      font-size: 300%;
    }
  }
`

import styled from 'styled-components';

export const Container = styled.div`
  padding: 5px 15px;

`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 5vw;
  align-items: center;

  #validacao {
    position: fixed;
    top: 10px;
    right: 10px;

    button {
      background: none;
      color: rgb(235, 91, 40);
      font-weight: 600;
      border-color: rgb(235, 91, 40);

      &:hover {
        background: rgba(235, 91, 40, 0.8);
        color: #fff;
      }
    }
  }

  #btn-comprovante {
    background: rgb(235, 91, 40);
    border: 0;

    a {
      color: #fff;
      margin-left: 5px;

      &:hover {
      color: rgb(235, 91, 40);
    }
    }

    &:hover {
      background: #fff;
      border: 1px solid rgb(235, 91, 40);
      color: rgb(235, 91, 40);
    }
  }
`;

import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import { Box, Typography } from "@mui/material";
import { Progress } from "antd";
import React, { useEffect } from "react";
import styled from "styled-components";
import api from "../../../services/api";
import {
  laranjaMain,
  laranjaSecundarioTransp,
  profileWhiteShade
} from "../../../utils/styledVariables";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const Title = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
`;

const Label = styled.div`
  border-radius: 5px;
  padding: 1% 2%;
  margin-top: 1%;
  margin-right: 2%;

  h6 {
    color: white;
    margin-bottom: 0;
  }
`;

const LabelIniciante = () => {
  return (
    <Label style={{ background: profileWhiteShade, width: "auto" }}>
      {" "}
      <h6 style={{color: laranjaMain}}>INICIANTE</h6>
    </Label>
  );
};
const LabelItermediario = () => {
  return (
    <Label style={{ background: profileWhiteShade, width: "auto" }}>
      {" "}
      <h6 style={{color: laranjaMain}}>INTERMEDIÁRIO</h6>
    </Label>
  );
};
const LabelAvancado = () => {
  return (
    <Label style={{ background: profileWhiteShade, width: "auto" }}>
      {" "}
      <h6 style={{color: laranjaMain}}>AVANÇADO</h6>
    </Label>
  );
};
const LabelCampeao = () => {
  return (
    <Label style={{ background: profileWhiteShade, width: "auto" }}>
      {" "}
      <h6 style={{color: laranjaMain}}>CAMPEÃO</h6>
    </Label>
  );
};

export default function NivelPerfil() {
  const [percentage, setPercentage] = React.useState(30);
  const [infoAluno, setInfoAluno] = React.useState([]);
  const [label, setLabel] = React.useState("");

  useEffect(() => {
    api.get("aluno/profile").then((response) => {
      setInfoAluno(response.data);
    });
  }, []);

  const handleProgressBar = () => {
    const itemPoints = {
      linkedin: 1,
      github: 1,
      experiences: {
        professional: 20,
        certification: 8,
        education: 20,
      },
      languages: 10,
      skills: 10,
    };
    let points = 0;
    let fieldsFilled = []; // array para armazenar os campos já preenchidos

    // Check linkedin and github
    if (infoAluno.linkedin) {
      points += itemPoints.linkedin;
      fieldsFilled.push("linkedin"); // adiciona o campo preenchido ao array
    }
    if (infoAluno.github) {
      points += itemPoints.github;
      fieldsFilled.push("github"); // adiciona o campo preenchido ao array
    }

    // Check experiences
    if (infoAluno.experiences) {
      infoAluno.experiences.forEach((exp) => {
        const expPoints = itemPoints.experiences[exp.experience_type];
        if (expPoints && fieldsFilled.indexOf(exp.experience_type) === -1) {
          // verifica se o campo já foi preenchido antes de adicionar os pontos
          points += expPoints;
          fieldsFilled.push(exp.experience_type);
        }
      });
    }

    // Check languages and skills
    if (infoAluno.languages) {
      points += itemPoints.languages;
      fieldsFilled.push("languages");
    }
    if (infoAluno.skills) {
      points += itemPoints.skills;
      fieldsFilled.push("skills");
    }

    const maxPoints = Object.values(itemPoints).reduce(
      (acc, points) =>
        acc +
        (typeof points === "object"
          ? Object.values(points).reduce((acc, p) => acc + p, 0)
          : points),
      0
    );

    const percentage = Math.floor((points / maxPoints) * 100);

    let label;
    if (percentage <= 50) {
      label = "Iniciante";
    } else if (percentage > 50 && percentage <= 70) {
      label = "Intermediário";
    } else if (percentage > 70 && percentage <= 99) {
      label = "Avançado";
    } else if (percentage === 100) {
      label = "Campeão";
    }

    setLabel(label);
    setPercentage(percentage);
    return label;
  };

  useEffect(() => {
    handleProgressBar();
  }, [infoAluno]);

  return (
    <>
      <Title>
        <Typography
          noWrap
          sx={{
            color: "#FFFFFF",
            fontFamily: "Roboto",
            fontWeight: 500,
            pt: 0.5,
            flexGrow: 1,
          }}
          variant="body1"
        >
          Nível do Perfil
        </Typography>
        {label === "Iniciante" && <LabelIniciante />}
        {label === "Intermediário" && <LabelItermediario />}
        {label === "Avançado" && <LabelAvancado />}
        {label === "Campeão" && <LabelCampeao />}
      </Title>
      <Container>
        <Progress
          showInfo={false}
          strokeColor={{
            "0%": "rgba(238,113,113,1)", //"rgba(173, 135, 255, 1)",
            "100%": "rgba(246,215,148,1)", //"rgba(219, 34, 223, 1)",
          }}
          trailColor={laranjaSecundarioTransp}//"rgba(173, 137, 191, 0.3)"
          percent={percentage}
        />
        <Box sx={{}}>
          <EmojiEventsIcon sx={{ fontSize: "3em", color: "#FFFFFF" }} />
        </Box>
      </Container>
    </>
  );
}

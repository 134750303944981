import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import MainHeader from "../MainHeader";
import {
  Container,
  Card,
  Grid,
  Typography,
  Button,
  List,
  ListItem,
  ListItemText,
  Tooltip,
  useMediaQuery
} from "@mui/material";
import { useStyles, IconeAnimado } from "./styles";
import { useHistory, useParams, Link } from "react-router-dom";
import ReactPlayer from "react-player";
import Header from "../HeaderModulo";
import api from "../../services/api";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import GraphicEqIcon from "@mui/icons-material/GraphicEq";
import ModalAtividade from "../PageTrilhaDeConhecimento/ModalAtividade"
import AssignmentIcon from "@mui/icons-material/Assignment";
import { DownloadCertificadoButton } from "../../../src/pages/PageTrilhaNew/Certificado/index"
const theme = createTheme({
  palette: {
    primary: {
      main: "#BD6F57",
      darker: "rgba(212, 109, 13, 1)",
    },
  },
});
function PageCursoTrilha() {
  const { idTrilha} = useParams();
  const signed = useSelector((state) => state.auth.signed);
  let profile = useSelector((state) => state.user.profile.dataValues ? state.user.profile.dataValues : state.user.profile);
  const classes = useStyles();
  const history = useHistory();
  const { idCurso: cursoId } = useParams();
  const [trilha, setTrilha] = useState({});
  const [stopFetch, setStopFetch] = useState(false);
  const [youtubeVideo, setYoutubeVideo] = useState({
    titulo: "",
    video_id: "",
    descricao: "",
  });
  const [playlistItems, setPlaylistItems] = useState([]);
  const [curso, setCurso] = useState([]);
  const [aulaAtualIndex, setAulaAtualIndex] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [teste, setTeste] = useState("0");
  const [visto, setVisto] = useState(false);
  const [pausado, setPausado] = useState(1);
  const [duracao, setDuracao] = useState(0);
  const [modulo, setModulo] = useState({});
  const noventaeCincoPorCentoAssistido = duracao * 0.95;
  const [openAtividade, setOpenAtividade] = useState(false);
  const [atividadeIndex, setAtividadeIndex] = useState(0);
  const [item, setItem] = useState([]);
  const isSmallScreen = useMediaQuery('(max-width:600px)');
  const [atualiza, setAtualiza] = useState(false);
  const [loading, setLoading] = useState(true)
  const handleProgress = (state) => {
    if (state.playedSeconds >= noventaeCincoPorCentoAssistido) {
      setVisto(true);
    }
    // console.log(state)
    setCurrentTime(state.playedSeconds);
  };

  const handlePause = () => {
    // console.log("Pausou")
    setPausado(Math.floor(currentTime));
    setTeste("Vídeo pausado em" + currentTime + "segundos");
  };
  const handleDuration = (duration) => {
    setDuracao(duration);
  };
  const handleEnded = () => {
    // youtubeVideo.concluido = true;
    setVisto(true);
    setPausado(duracao);
    // youtubeVideo.pausado = 0;
    setTeste("Vídeo concluído em" + currentTime + "segundos");
  };

  const getVideoId = () => {
    const urlParts = window.location.href.split("/");
    return urlParts[urlParts.length - 1];
  };

  useEffect(() => {
    const fetchVideo = async () => {
      setLoading(true);
      if (cursoId && !stopFetch) {
        const videoId = getVideoId();
        try {
          const response = await api.get(`/trilhas-de-conhecimento/cursos/${idTrilha}`);
          const cursoData = response.data.find(item => item.modulo.curso_id == cursoId);
          if (cursoData) {
            setModulo(cursoData.modulo);
            setItem(cursoData);
            setCurso(cursoData.modulo.curso);
            setPlaylistItems(cursoData.videos_vistos);
            setTrilha(cursoData.modulo.trilha);
          }
          setLoading(false);
          const video = cursoData.videos_vistos.find(item => item.video === videoId);
          if (video) {
            setYoutubeVideo(video);
            setStopFetch(true);
          }
        } catch (error) {
          console.log(error);
        }
      }
    };
    fetchVideo();
    setAtualiza(false);
  }, [cursoId, playlistItems, atualiza]);

  useEffect(() => {

    const video = {
      trilhaId: trilha.id,
      moduloId: modulo.id,
      videoId: youtubeVideo.video,
      visto: visto,
      pausado: pausado,
      duracao: duracao,
    };

    api
      .patch("/trilhas-de-conhecimento/inscricao/atualizar-video", video)
      .then((response) => {
        // console.log(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [visto, pausado]);

  const handleBack = () => {
    history.push(`/oxetrilha/${trilha.id}`);
  };

  const handleVideoClick = (videoId) => {
    history.push(`/trilha/${trilha.id}/curso/${cursoId}/${videoId}`);
    window.location.reload();
  };

  const handleProximaAula = () => {
    if (aulaAtualIndex < playlistItems.length - 1) {
      const proximaAulaIndex = aulaAtualIndex + 1;
      setYoutubeVideo(playlistItems[proximaAulaIndex]);
      setAulaAtualIndex(proximaAulaIndex);
      const novaAulaId = playlistItems[proximaAulaIndex].video_id;
      history.push(`/curso-trilha/${cursoId}/${novaAulaId}`);
    } else {
      console.log("Fim dos vídeos");
    }
  };

  const moduloTrilha = {
    trilhaTitulo: trilha.titulo,
    titulo: curso.titulo,
    descricao: curso.descricao,
  };

  return (
    <ThemeProvider theme={theme}>
      {!signed && <MainHeader />}
      {youtubeVideo && <Header onBack={handleBack} modulo={moduloTrilha} />}
      <div className={classes.container}>
        <Card className={classes.videoCard}  style={{height: isSmallScreen && 'auto' }}>
          <Grid container spacing={2} style={{ height: "100%" }}>
            <Grid item xs={12} md={6} style={{ height: isSmallScreen ? "40%": "100%"}}>
              {/* <Typography variant="body1" className={classes.videoTitle}>
                Aula: {youtubeVideo?.titulo || "Título não disponível"};
              </Typography> */}
              <div className={classes.playerWrapper}>
                {!youtubeVideo ? (
                  <div>Loading video...</div>
                ) : (
                  <ReactPlayer
                    url={`http://www.youtube.com/embed/${youtubeVideo.video}`}
                    width="100%"
                    height="100%"
                    controls
                    playing={false}
                    onProgress={handleProgress}
                    onPause={handlePause}
                    onEnded={handleEnded}
                    onDuration={handleDuration}
                    config={{
                      youtube: {
                        playerVars: {
                          modestbranding: 1,
                          rel: 0,
                          enablejsapi: 1,
                          origin: "https://www.youtube.com",
                          iv_load_policy: 3,
                          showinfo: 0,
                          start: youtubeVideo.pausado,
                        },
                      },
                    }}
                  />
                )}
                {/* <div>
                  {teste} De onde paraou {youtubeVideo?.pausado}
                </div> */}
                {/* <div>index{aulaAtualIndex}</div> */}
              </div>
            </Grid>
            <Grid item xs={12} md={6} spacing={2}>
              <div className={classes.informationContainer}>
                <List className={classes.lista}>
                  {/* Mapeando os vídeos e criando itens de lista */}
                  {playlistItems.map((video, index) => (
                    <ListItem
                      key={index}
                      onClick={() => handleVideoClick(video.video)}
                      sx={{
                        cursor: "pointer",
                        height: "60px",
                        // backgroundColor: index % 2 === 0 ? "white" : "rgba(246,147,28,0.07)",
                        backgroundColor: "white",
                        "&:hover": {
                          // backgroundColor: index % 2 === 0 ? "rgb(0,0,0,0.05)" : "rgba(246,147,28,0.3)",
                          backgroundColor: "rgb(0,0,0,0.05)",
                        },
                      }}
                    >
                      {/* Texto do item de lista */}
                      {video.visto ? (
                        <CheckCircleIcon
                          sx={{
                            color: "green",
                            marginRight: "2%",
                            marginLeft: "2%",
                          }}
                        />
                      ) : video.video === youtubeVideo.video ? (
                        <IconeAnimado>
                          <div className="animated-sound-icon">
                            <GraphicEqIcon
                              fontSize="small"
                              sx={{ color: "#BD6F57" }}
                            />
                          </div>
                        </IconeAnimado>
                      ) : (
                        <PlayCircleIcon
                          sx={{
                            color: "#BD6F57",
                            marginRight: "2%",
                            marginLeft: "2%",
                          }}
                        />
                      )}
                      <ListItemText
                        primary={curso.playlist_items[index].titulo}
                        sx={{ width: "60%" }}
                      />
                      <h5 style={{display: video.video === youtubeVideo.video? 'flex': 'none', color: '#BD6F57' }} >Reproduzindo</h5>
                    </ListItem>
                    
                  ))}
                   <ListItem
                    key={1}
                    sx={{
                      background: "rgb(0,0,0,0.05)",
                      "&:hover": {
                        background: "rgb(0,0,0,0.1)",
                      },
                    }}
                  >
                    <Tooltip title={item.atividade_concluida?"Concluído": ""}>
                      <AssignmentIcon
                        sx={{
                          color: item.atividade_concluida
                            ? "green"
                            : item.curso_concluido
                            ? "#BD6F57"
                            : "gray",
                          marginRight: "2%",
                          marginLeft: "2%",
                        }}
                      />
                    </Tooltip>

                    <ListItemText primary="Questionário" />
                    <Button
                      variant="contained"
                      sx={{
                        padding: "0.4% 1%",
                        // background:tipo.atividade_concluida? "" "C4764E",
                        // "linear-gradient(108deg, #C4764E -14.59%, #BD6F57 36.45%, #914C90 107.21%)",
                      }}
                      color= {item.atividade_concluida? "success" : "primary"}
                      onClick={() => {
                        if(!item.atividade_concluida){
                          setOpenAtividade(true);
                          // setCursoId(tipo.modulo);
                          // setAtividadeIndex(index);
                        }
                      }}
                      disabled={!item.curso_concluido}
                    >
                      {item.atividade_concluida
                            ? `${item.nota_atividade}/10`
                            :"Responder"} 
                    </Button>
                  </ListItem>
                  <ListItem key={1}>
                  <ListItemText
                    sx={{
                      marginLeft: "auto", 
                    }}
                  />
                  {item.atividade_concluida && (
                    <DownloadCertificadoButton
                      nome={profile.name}
                      curso={trilha.titulo}
                      cargaHoraria={trilha.carga_horaria}
                    />
                  )}
                </ListItem>

                </List>
              </div>
            </Grid>
          </Grid>
        </Card>
        { modulo &&
          <ModalAtividade
             open={openAtividade}
             close={() => {setOpenAtividade(false);
              setAtualiza(true)}
            }
             curso={modulo}
             trilhaId={idTrilha}
          />
        }
      </div>
    </ThemeProvider>
  );
}

export default PageCursoTrilha;

import React, { useEffect, useState } from "react";
import { Grid, Paper, Table, TableHead, TableBody, TableRow, TableCell, Typography, Modal, Button, Box} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import ModalEdicaoCiclo from './ModalEdicaoCiclo';
import { format } from 'date-fns'
import IconButton from '@mui/material/IconButton';
import * as palette from "../../utils/styledVariables"
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { SnackbarProvider } from 'notistack';

const styles = {
    modal: {

        width: "50%",
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: "#f2f2f2",
        padding: "10px",
        textAlign: "center",
        borderRadius: "1vh",
        justifyContent: 'center'

    },

    paper: {
        width: "100%",
        margin: "5px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",

    },

    field: { padding: "5px" }



}

function ModalVerCiclos(props) {
  const [selectedCiclos, setSelectedCiclos] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedCicloId, setSelectedCicloId] = useState(null);
  const [editarCicloAberto, setEditarCicloAberto] = useState(false);
  
  const handleCloseModal = () => {
    setOpen(false);
  };

  const handleEditarCiclo = (cicloId) => {
    setSelectedCicloId(cicloId);
    setEditarCicloAberto(true);
    handleCloseModal();
  };

  const handleClose = () => {
    props.close();
  };

  useEffect(() => {
    const cicloState = () => {
      setSelectedCiclos(props.selectedCiclos);
    };

    cicloState();
  }, [props.selectedCiclos]);

  return (
    <Modal open={props.open}>
      
        <Box>
          <IconButton size='large' sx={{ display: "flex", float: "right" }} component="label" onClick={handleClose}>
            <CloseIcon fontSize='large' sx={{ color: palette.profileWhiteShade }} />
          </IconButton>

          <Grid sx={styles.modal} container direction="row">
            <Grid container item xs={12} sm={12} md={12} lg={12}>
              <Paper sx={[{ backgroundColor: palette.laranjaOXLTransparente, height: "50px" }, styles.paper]}>
                <Typography sx={{ fontSize: "24px", color: palette.preto, fontFamily: "Poppins", fontWeight: 500 }}>Lista de Ciclos</Typography>
              </Paper>
            </Grid>

            <Paper sx={styles.paper}>
              <Grid container item xs={12} sm={12} md={12} lg={12}>
                <Box sx={{ width: '100%' }}>
                  <Table sx={{ margin: '0 auto' }}>
                    <TableHead>
                      <TableRow>
                        <TableCell>Número do Ciclo</TableCell>
                        <TableCell>Data de Abertura</TableCell>
                        <TableCell>Data de Encerramento</TableCell>
                        <TableCell>Editar ciclo</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {selectedCiclos.sort((a, b) => a.num_ciclo - b.num_ciclo)
                      .map((selectedCiclo) => (
                        <TableRow key={selectedCiclo.id}>
                          <TableCell>{selectedCiclo.num_ciclo}</TableCell>
                          <TableCell>{format(new Date(selectedCiclo.start), 'dd/MM/yyyy')}</TableCell>
                          <TableCell>{format(new Date(selectedCiclo.end), 'dd/MM/yyyy')}</TableCell>
                          <TableCell>
                            <Button onClick={() => handleEditarCiclo(selectedCiclo.id)} sx={{ color: palette.laranjaIntermediario2 }}>
                              <ModeEditIcon />
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Box>
              </Grid>
            </Paper>
          </Grid>

          {editarCicloAberto && (
            <SnackbarProvider maxSnack={3}>
              <ModalEdicaoCiclo
              open={editarCicloAberto} 
              ciclo={selectedCiclos.find(ciclo => ciclo.id === selectedCicloId)}
              onClose={() => {setEditarCicloAberto(false); handleClose();}}
              
            />
            </SnackbarProvider>
          )}
        </Box>
      
    </Modal>
  )
}

export default ModalVerCiclos;
import React, { useState, useEffect } from "react";
import { Box, Container, Paper, Typography } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import helperTexts from "../../utils/helperTexts";
import * as palette from "../../utils/styledVariables";
import CircularProgress from '@mui/material/CircularProgress';

const style = {
  padding: "16px",
  justifyContent: "center",
  alignItems: "center",
  height: "85vh",
  fontSize: "26px",
  color: palette.preto,
  fontStyle: "normal",
  fontWeight: 450,
  background: palette.laranjaOXLTransparente,
  //palette.roxoClaroTransparente,
  textAlign: "left",
  borderRadius: "15px",
  marginBottom: "10px",
};

function TableCard({ props, loading }) {
  const { name, buttonsBar, rows, columns, totalDeVagas } = props;

  return (
    <Container sx={style}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignContent: "center",
          width: "100%",
          paddingTop: 1,
          paddingBottom: 1,
        }}
      >
        <Typography
          sx={{
            fontSize: "24px",
            color: palette.preto,
            fontFamily: "Poppins",
            fontWeight: 500,
          }}
        >
          {name}
        </Typography>
        {buttonsBar}
        {totalDeVagas}
      </Box>
      <Paper sx={{ height: "90%", background: palette.profileWhiteShade }}>
  {loading && (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
      }}
    >
      <CircularProgress size={65} sx={{ color: palette.laranjaIntermediario }} />
    </Box>
  )}
  {!loading && (
    <DataGrid
      rows={rows}
      columns={columns}
      components={{ Toolbar: GridToolbar }}
      columnVisibilityModel={{ id: false }}
      sx={{
        background: palette.profileWhiteShade,
        "& .MuiDataGrid-toolbarContainer": {
          "& .MuiButton-text": {
            color: palette.laranjaSecundario,
          },
        },
        "& .MuiDataGrid-columnHeaderTitle": {
          color: palette.preto,
        },
        "& .MuiDataGrid-cellContent": {
          color: palette.preto,
        },
      }}
      localeText={helperTexts.dataGridToolbarText}
    />
  )}
</Paper>

    </Container>
  );
}

export default TableCard;

import React, { useState, useEffect } from "react";
import Sidebar from "../../SidebarAdmin/index.js";
import {
  Box,
  CssBaseline,
  IconButton,
  Button,
  Tabs,
  Tab,
  ThemeProvider,
  createTheme,
} from "@mui/material";
import * as palette from "../../../utils/styledVariables";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import { BotaoCadastro } from "./style";
import { ModalCadastro } from "../ModalCadastro/index.js";
import { ModalEditar } from "../ModalEditar";
import TableCard from "../TableCard";
import ModalConfirmacao from "../ModalConfirmacao";
import api from "../../../services/api";
import { ModalCadastroTrilha } from "../ModalCadastroTrilha/index.js";
import { ModalEditarTrilha } from "../ModalEditarTrilha/index.js";
import { SnackbarProvider } from "notistack";
import ContentPasteSearchIcon from "@mui/icons-material/ContentPasteSearch";
import { ModalDesempenhoAtividade } from "../ModalDesempenhoAtividade";
import { ModalDesempenhoTrilha } from "../ModalDesempenhoTrilha/index.js";
import { ModalEditarAtividade } from "../ModalEditarAtividade/index.js";

export const AdmCursos = () => {
  const theme = createTheme({
    palette: {
      secondary: {
        main: palette.laranjaIntermediario2,
      },
    },
  });
  const [atividades, setAtividades] = useState([]);
  const [cursos, setCursos] = useState([]);
  const [trilhas, setTrilhas] = useState([]);
  const [currentExcludingCourse, setCurrentExcludingCourse] = useState(null);
  const [currentEditingCourse, setCurrentEditingCourse] = useState(null);
  const [currentEditingCurso, setCurrentEditingCurso] = useState(null);
  const [currentExcludingTrilha, setCurrentExcludingTrilha] = useState(null);
  const [currentEditingAtividade, setCurrentEditingAtividade] = useState(null);

  const [width, setWidth] = useState(window.innerWidth);
  const onMobile = width <= 600;
  const [sidebarCollapsed, setSidebarCollapsed] = useState(false);

  const [openModalRegistrar, setOpenModalRegistrar] = useState(false);
  const handleOpenModalRegistrar = () => setOpenModalRegistrar(true);
  const handleCloseModalRegistrar = () => {
    setOpenModalRegistrar(false);
    setAtualiza(true)
  };

  const [openModalEditarCurso, setOpenModalEditarCurso] = useState(false);
  const [openModalEditarTrilha, setOpenModalEditarTrilha] = useState(false);
  const [openModalEditarAtividade, setOpenModalEditarAtividade] =
    useState(false);
  const [performanceActivity, setPerformanceActivity] = useState(false);
  const [performanceTrail, setPerformanceTrail] = useState(false);

  const [openModalCadastroTrilha, setOpenModalCadastroTrilha] = useState(false);
  const [openModalCadastroAtividade, setOpenModalCadastroAtividade] =
    useState(false);
  const [openModalDesempenhoAtividade, setOpenModalDesempenhoAtividade] =
    useState(false);
  const [openModalDesempenhoTrilha, setOpenModalDesempenhoTrilha] =
    useState(false);
  const [highlightedEdital, setHighlightedEdital] = useState(null);
  const [hideTimeout, setHideTimeout] = useState(800);
  const [loading, setLoading] = useState(true);
  const [atualiza, setAtualiza] = useState(false);
  const [activityCreated, setActivityCreated] = useState(
    JSON.parse(sessionStorage.getItem("atividade")) || false
  );

  const handleMouseEnter = (editalId) => {
    setHighlightedEdital(editalId);
    if (hideTimeout) {
      clearTimeout(hideTimeout);
      setHideTimeout(null);
    }
  };
  const handleMouseLeave = () => {
    const timeout = setTimeout(() => {
      setHighlightedEdital(null);
      setHideTimeout(null);
    }, 800);
    setHideTimeout(timeout);
  };

  const [currentExcludingAtividade, setCurrentExcludingAtividade] =
    useState(false);
  const [
    openModalConfirmarExcluirAtividade,
    setOpenModalConfirmarExcluirAtividade,
  ] = useState(false);

  const handleOpenModalCadastroTrilha = () => {
    setOpenModalCadastroTrilha(true);
  };

  const handleCloseModalDesempenhoAtividade = () => {
    setOpenModalDesempenhoAtividade(false);
  };

  const handleOpenModalEditar = (curso) => {
    setOpenModalEditarCurso(true);
    setCurrentEditingCurso(curso);
  };

  const handleOpenModalEditarAtividade = (curso) => {
    setOpenModalEditarAtividade(true);
    setCurrentEditingAtividade(curso.atividade);
  };

  const handleOpenModalDesempenhoAtividade = (moduloId) => {
    getPerfomance(moduloId);
    setOpenModalDesempenhoAtividade(true);
  };

  const handleOpenModalDesempenhoTrilha = (id) => {
    getPerfomanceTrail(id);
    setOpenModalDesempenhoTrilha(true);
  };

  const handleCloseModalDesempenhoTrilha = () => {
    setOpenModalDesempenhoTrilha(false);
  };

  const handleOpenModalEditarTrilha = (trilha) => {
    setOpenModalEditarTrilha(true);
    setCurrentEditingCourse(trilha);
  };

  const handleCloseModalEditarTrilha = () => {
    setOpenModalEditarTrilha(false);
    setCurrentEditingCourse(null);
  };

  const [openModalConfirmarExcluir, setOpenModalConfirmarExcluir] =
    useState(false);

  const [openModalConfirmarExcluirTrilha, setOpenModalConfirmarExcluirTrilha] =
    useState(false);
  const handleOpenModalConfirmarExcluir = (curso) => {
    setCurrentExcludingCourse(curso);
    setOpenModalConfirmarExcluir(true);
  };

  const handleCloseModalConfirmarExcluir = () => {
    setOpenModalConfirmarExcluir(false);
    setCurrentExcludingTrilha(null);
    setAtualiza(true)
  };
  const handleOpenModalConfirmarExcluirTrilha = (trilha) => {
    setCurrentExcludingTrilha(trilha);
    setOpenModalConfirmarExcluirTrilha(true);
  };

  const handleCloseModalConfirmarExcluirTrilha = () => {
    setOpenModalConfirmarExcluirTrilha(false);
    setCurrentExcludingTrilha(null);
    setAtualiza(true)
  };

  const handleOpenModalConfirmarExcluirAtividade = (atividade) => {
    setCurrentExcludingAtividade(atividade);
    setOpenModalConfirmarExcluirAtividade(true);
  };

  const handleCloseModalConfirmarExcluirAtividade = () => {
    setOpenModalConfirmarExcluirAtividade(false);
    setCurrentExcludingAtividade(null);
  };

  const handleExcluirCurso = async () => {
    try {
      await api.delete(`/cursos/${currentExcludingCourse.id}`);
      setAtualiza(true)
      handleCloseModalConfirmarExcluir(false)
    } catch (error) {}
  };

  const handleExcluirTrilha = async () => {
    try {
      await api.delete(`/trilhas-de-conhecimento/${currentExcludingTrilha.id}`);
      setAtualiza(true)
      setOpenModalConfirmarExcluirTrilha(false)
    } catch (error) {}
  };

  const handleExcluirAtividade = async () => {
    try {
      await api.delete(`/atividade-trilha/${currentExcludingAtividade.id}`);
      setAtualiza(true)
      setOpenModalConfirmarExcluirAtividade(false)
    } catch (error) {}
  };
  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  useEffect(() => {
    setLoading(true);
    api.get("/cursos").then((response) => {
      setCursos(response.data)  
      setLoading(false)});
    api
      .get("/trilhas-de-conhecimento/")
      .then((response) => {
        setTrilhas(response.data)
        setLoading(false);
  })

  }, []);

  const cadastrarCursoButton = (
    <div style={{ float: "right" }}>
      <BotaoCadastro onClick={handleOpenModalRegistrar}>
        Cadastrar Curso
      </BotaoCadastro>
    </div>
  );

  const getPerfomance = (id) => {
    api
      .get(`trilhas-de-conhecimento/desempenho/atividade/${id}`)
      .then((response) => {
        setPerformanceActivity(response.data);
        return response.data;
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const getPerfomanceTrail = async (id) => {
    api
      .get(`trilhas-de-conhecimento/desempenho/${id}`)
      .then((response) => {
        setPerformanceTrail(response.data);
        return response.data;
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const cadastrarTrilhaButton = (
    <div style={{ float: "right" }}>
      <BotaoCadastro onClick={handleOpenModalCadastroTrilha}>
        Cadastrar Trilha
      </BotaoCadastro>
    </div>
  );

  const cursosHeader = [
    {
      field: "titulo",
      headerName: "Título",
      width: 300,
      renderCell: (params) => {
        return (
          <div style={{ display: "flex", flexDirection: "column" }}>
              <span>{params.formattedValue}</span>
          </div>
        );
      },
    },
    {
      field: "editar_curso",
      headerName: "Editar curso",
      width: 120,
      renderCell: (params) => {
        return (
          <div style={{ display: "flex", flexDirection: "column" }}>
           
              <span>{params.formattedValue}</span>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Button
                    onClick={(e) => handleOpenModalEditar(params.row)}
                    sx={{ color: palette.laranjaIntermediario2 }}
                  >
                    <ModeEditIcon />
                  </Button>
                </div>
          </div>
        );
      },
    },
    {
      field: "editar_atividade",
      headerName: "Editar atividade",
      width: 120,
      renderCell: (params) => {
        return (
          <div style={{ display: "flex", flexDirection: "column" }}>
           
              <span>{params.formattedValue}</span>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Button
                    onClick={(e) => handleOpenModalEditarAtividade(params.row)}
                    sx={{ color: palette.laranjaIntermediario2 }}
                  >
                    <ModeEditIcon />
                  </Button>
                </div>
          </div>
        );
      },
    },
    {
      field: "playlist_id",
      headerName: "Identificador da Playlist",
      width: 200,
    },
    {
      headerName: "Desempenho da atividade",
      width: 180,
      renderCell: (params) => (
        <IconButton
          color="secondary"
          onClick={(e) => handleOpenModalDesempenhoAtividade(params.row.id)}
        >
          <ContentPasteSearchIcon />
        </IconButton>
      ),
    },
    {
      field: "Excluir",
      headerName: "Excluir",
      width: 80,
      renderCell: (params) => {
        return (
          <IconButton
            color="secondary"
            onClick={(e) => handleOpenModalConfirmarExcluir(params.row)}
          >
            <DeleteIcon />
          </IconButton>
        );
      },
    },
  ];

  const trilhaHeader = [
    {
      field: "Título",
      headerName: "Título da Trilha",
      width: 300,
      renderCell: (params) => {
        return (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span
              key={params.id}
              onMouseEnter={() => handleMouseEnter(params.id)}
              onMouseLeave={handleMouseLeave}
              style={{ display: "flex", alignItems: "center" }}
            >
              <span>{params.row.titulo}</span>
              {highlightedEdital === params.id && (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Button
                    onClick={(e) => handleOpenModalEditarTrilha(params.row)}
                    sx={{ color: palette.laranjaIntermediario2 }}
                  >
                    <ModeEditIcon />
                  </Button>
                </div>
              )}
            </span>
          </div>
        );
      },
    },
    {
      field: "Desempenho",
      headerName: "Desempenho",
      width: 110,
      renderCell: (params) => {
        return (
          <IconButton
            color="secondary"
            onClick={(e) => handleOpenModalDesempenhoTrilha(params.id)}
          >
            <ContentPasteSearchIcon />
          </IconButton>
        );
      },
    },
    {
      field: "excluir",
      headerName: "Excluir",
      width: 80,
      renderCell: (params) => {
        return (
          <IconButton
            color="secondary"
            onClick={(e) => handleOpenModalConfirmarExcluirTrilha(params.row)}
          >
            <DeleteIcon />
          </IconButton>
        );
      },
    },
  ];

 
  const table = [
    {
      name: "Cursos cadastrados",
      columns: cursosHeader,
      rows: cursos,
      buttonsBar: cadastrarCursoButton,
    },
    {
      name: "Trilhas cadastradas",
      columns: trilhaHeader,
      rows: trilhas,
      buttonsBar: cadastrarTrilhaButton,
    },
   
  ];
  const [chosenTable, setChosenTable] = useState(0);

  return (
    <Box
      sx={{
        display: "flex",
        flexGrow: 1,
      }}
    >
      <CssBaseline />

      <Sidebar
        onMobile={onMobile}
        collapsed={sidebarCollapsed}
        setCollapsed={setSidebarCollapsed}
      />
      <ThemeProvider theme={theme}>
        <Box
          component="main"
          sx={{
            backgroundColor: "#FFFFFF",
            flexGrow: 2,
            p: 1,
            width: { xs: `calc(100% - 20vh)` },
            marginLeft: onMobile || sidebarCollapsed ? "auto" : "240px",
          }}
        >
          <Tabs
            centered
            value={chosenTable}
            onChange={(event, newValue) => setChosenTable(newValue)}
            sx={{ marginBottom: 1 }}
            indicatorColor="secondary"
            textColor="secondary"
          >
            {table.map((table, index) => (
              <Tab value={index} label={table.name} key={index} />
            ))}
          </Tabs>
          <TableCard props={table[chosenTable]} loading={loading} />
        </Box>
      </ThemeProvider>

      <SnackbarProvider maxSnack={3}>
        <ModalCadastro
          open={openModalRegistrar}
          close={handleCloseModalRegistrar}
        />
      </SnackbarProvider>
      <ModalConfirmacao
        open={openModalConfirmarExcluir}
        handleRevert={handleCloseModalConfirmarExcluir}
        handleChange={handleExcluirCurso}
        message={"Confirma a exclusão do curso?"}
      />

      <ModalConfirmacao
        open={openModalConfirmarExcluirTrilha}
        handleRevert={handleCloseModalConfirmarExcluirTrilha}
        handleChange={handleExcluirTrilha}
        message={"Confirma a exclusão da trilha?"}
      />

      <ModalConfirmacao
        open={openModalConfirmarExcluirAtividade}
        handleRevert={handleCloseModalConfirmarExcluirAtividade}
        handleChange={handleExcluirAtividade}
        message={"Confirma a exclusão da atividade?"}
      />
      <SnackbarProvider maxSnack={3}>
        <ModalCadastroTrilha
          open={openModalCadastroTrilha}
          close={() => {
            setOpenModalCadastroTrilha(false);
            setAtualiza(true)
          }}
          cursos={cursos}
        />
      </SnackbarProvider>

      <SnackbarProvider maxSnack={3}>
        <ModalEditarAtividade
          onOpen={openModalEditarAtividade}
          onClose={() => {
            setOpenModalEditarAtividade(false);
          }}
          atividade={currentEditingAtividade}
          edit={activityCreated}
        />
      </SnackbarProvider>

      <SnackbarProvider maxSnack={3}>
        <ModalEditar
          open={openModalEditarCurso}
          close={() => {
            setOpenModalEditarCurso(false);
          }}
          curso={currentEditingCurso}
        />
      </SnackbarProvider>
      <SnackbarProvider maxSnack={3}>
        <ModalEditarTrilha
          open={openModalEditarTrilha}
          close={handleCloseModalEditarTrilha}
          trilha={currentEditingCourse}
          cursos={cursos}
        />
      </SnackbarProvider>

      <ModalDesempenhoAtividade
        open={openModalDesempenhoAtividade}
        close={handleCloseModalDesempenhoAtividade}
        perfomance={performanceActivity}
      />

      <ModalDesempenhoTrilha
        open={openModalDesempenhoTrilha}
        close={handleCloseModalDesempenhoTrilha}
        performanceTrail={performanceTrail}
      />
    </Box>
  );
};

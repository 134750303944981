import React, { useState } from "react";

import {
  Avatar,
  Modal,
  Paper,
  Typography,
  useMediaQuery,
  IconButton,
  Tabs,
  Tab,
  Box,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Grid,
  Card,
  CardHeader,
  CardContent,
  Button,
  TextField
} from '@mui/material';

import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import EditIcon from '@mui/icons-material/Edit';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';


import {
  useStyles,
} from './styles';

import IconeAtividadePendente from '../../../assets/iconesAtividadesDaTurma/pending-activities-icon.svg';

const CardEntrega = ({atividade}) => {  
  const classes = useStyles();
  const [nota, setNota] = useState(atividade.nota);
  const [editNota, setEditNota] = useState(false);
  const [textEditNota, setTextEditNota] = useState("Dar uma nota")
  
  const toggleEditNota = () =>{ 
    if (editNota) {
      setEditNota(false);
      setTextEditNota("Dar uma nota");
    } else {
      setEditNota(true);
      setTextEditNota("Salvar");
    }
  }

  const handleChangeNota = (event) => {
    const { value } = event.target;
    if (value >= 0 && value <= 10) {
      setNota(value);
    }
  }

  return (
    <Card className={classes.cardContainer}>
      <CardHeader
        avatar={<Avatar>{atividade.aluno[0]}</Avatar>}
        title={atividade.aluno}
      />
      <CardContent className={classes.cardBody}>
        <PictureAsPdfIcon sx={{
          color: 'red',
          width: '25%',
          height: '25%',
          margin: '0.5rem'
        }}/>
        <Typography variant='body1' sx={{cursor: 'pointer'}}>
          {atividade.arquivo}
        </Typography>

        <div className={classes.cardFooter}>
          {
            editNota ? 
              <TextField
                id="outlined-number"
                type="number"
                InputLabelProps={{
                  shrink: true,
                }}
                value={nota}
                onChange={handleChangeNota}
              /> :
            <Typography variant='body1'>{nota}/10</Typography>
          }
          <Typography>
            {atividade.entregue && <Button disableRipple onClick={toggleEditNota} >{textEditNota}</Button>}
          </Typography>
        </div>
      </CardContent>
    </Card>
  );
};

export const ModalDetalhesAtividadeProfessor = ({ atividade, open, close }) => {
  const classes = useStyles();
  const isSmallScreen = useMediaQuery('(max-width:600px)');
  const [chosenView, setChosenView] = useState(0);
  const [chosenFilter, setChosenFilter] = useState(0);

  const alunos = [
    { aluno: "Thalia Barbosa Marques de Almeida", arquivo: "Trabalho_de_css.pdf", nota: 0, entregue: true },
    { aluno: "Thalia Barbosa Marques de Almeida", arquivo: "Trabalho_de_css.pdf", nota: 0, entregue: true },
    { aluno: "Thalia Barbosa Marques de Almeida", arquivo: "Trabalho_de_css.pdf", nota: 0, entregue: true },
    { aluno: "Thalia Barbosa Marques de Almeida", arquivo: "Trabalho_de_css.pdf", nota: 0, entregue: true },
    { aluno: "Thalia Barbosa Marques de Almeida", arquivo: "Trabalho_de_css.pdf", nota: 0, entregue: true },
    { aluno: "Thalia Barbosa Marques de Almeida", arquivo: "Trabalho_de_css.pdf", nota: 0, entregue: true },
    { aluno: "Thalia Barbosa Marques de Almeida", arquivo: "Trabalho_de_css.pdf", nota: 0, entregue: true },
    { aluno: "Thalia Barbosa Marques de Almeida", arquivo: "Trabalho_de_css.pdf", nota: 0, entregue: false },
    { aluno: "Thalia Barbosa Marques de Almeida", arquivo: "Trabalho_de_css.pdf", nota: 0, entregue: false },
    { aluno: "Thalia Barbosa Marques de Almeida", arquivo: "Trabalho_de_css.pdf", nota: 0, entregue: false },
    { aluno: "Thalia Barbosa Marques de Almeida", arquivo: "Trabalho_de_css.pdf", nota: 0, entregue: false },
  ];


  return (
    <Modal
      open={open}
      onClose={close}
    >
      <Paper elevation={6} className={classes.modalContainer}>
        <div className={classes.goBackContainer}>
          <IconButton className={classes.goBackButton} color="#D56D0D2E" onClick={close}>
            <ArrowBackIosNewIcon sx={{ color: "#D46D0D" }} />
          </IconButton>
        </div>
        <Box sx={{ width: "100%" }}>
          <Tabs
            centered
            value={chosenView}
            onChange={(event, newValue) => setChosenView(newValue)}
            sx={{ marginBottom: 1 }}
            indicatorColor="primary"
            textColor="primary"
          >
            <Tab label="Atividade" value={0} />
            <Tab label="Trabalhos dos alunos" value={1} />
          </Tabs>
        </Box>

        {
          chosenView === 0 ?
            <>
              <div className={classes.modalHeader}>
                <div className={classes.iconAndTitleContainer}>
                  <Avatar
                    className={classes.icon}
                    src={IconeAtividadePendente}
                    alt={'Ícone de atividade'}
                    classes={{ img: classes.iconImg }}
                  />
                  <Typography
                    variant='h5'
                    align='left'
                    className={classes.title}
                    style={{ fontSize: isSmallScreen ? '3.0vh' : '5.0vh' }}
                  >
                    {atividade.titulo}
                  </Typography>
                </div>
                <div className={classes.modalDateContainer}>
                  <Typography variant='h6'>
                    <span style={{ color: '#D56D0D', fontWeight: 700 }}>Data de entrega:</span> 05/05/2023 às 16h
                  </Typography>
                </div>
              </div>
              <div className={classes.descriptionContainer}>
                <Typography variant="body1" className={classes.description}>{atividade.descricao}</Typography>
              </div>
              <div className={classes.submissionContainer}>
                <IconButton sx={{ color: "#D46D0D" }} >
                  <EditIcon sx={{ height: '8vh', width: '8vh' }} />
                </IconButton>
              </div>
            </> :
            <>
              <div className={classes.filterContainer}>
                <FormControl >
                  <InputLabel id="filtro">Filtrar</InputLabel>
                  <Select
                    labelId="filtro"
                    value={chosenFilter}
                    label="Filtrar"
                    onChange={(event) => setChosenFilter(event.target.value)}
                    className={classes.select}
                  >
                    <MenuItem value={0}>Entregues</MenuItem>
                    <MenuItem value={1}>Pendentes</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <Grid container spacing={2} sx={{marginBottom: '1rem'}} >
                {
                  chosenFilter === 0 ?
                    alunos
                      .filter((atv) => atv.entregue === true)
                      .map((atv, idx) =>
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                          <CardEntrega atividade={atv} key={idx} />
                        </Grid>
                      ) :
                    alunos
                      .filter((atv) => atv.entregue === false)
                      .map((atv, idx) =>
                        <Grid item xxs={12} sm={6} md={4} lg={3}>
                          <CardEntrega atividade={atv} key={idx}></CardEntrega>
                        </Grid>
                      )
                }
              </Grid>
            </>
        }
      </Paper>
    </Modal>
  );
};
import React from "react";
import { Box, Container, Fab, Tooltip } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import * as palette from "../../../utils/styledVariables";
import helperTexts from "../../../utils/helperTexts";
import { Tab, Tabs } from "@mui/material";
import { useState } from "react";
import { Button } from "@mui/base";
import Aula from "../Modais/FormAula";
import EncerrarTurma from "../Modais/EncerrarTurma";
import { addDays, parseISO } from "date-fns";
import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium";
import ModalCertificados from "../Modais/Certificados";

const style = {
  width: "100%",
  padding: "16px",
  justifyContent: "center",
  alignItems: "center",
  height: "80vh",
  fontSize: "26px",
  color: palette.preto,
  fontStyle: "normal",
  fontWeight: 450,
  background: "rgba(212, 109, 13, 0.1)",
  textAlign: "left",
  borderRadius: "15px",
  marginBottom: "10px",
};

function TableCard({ tables, turma, permissao, aulasNumero }) {
  const [chosenTable, setChosenTable] = useState(0);
  const [matriculaOn, setMatriculaOn] = useState(false);
  const [encerrarOn, setEncerrarOn] = useState(false);
  const [openCertificadoTurma, setOpenCertificadoTurma] = useState(false);

  return (
    <Box sx={style}>
      <Box sx={{ width: "100%" }}>
        <Tabs
          centered
          value={chosenTable}
          onChange={(event, newValue) => setChosenTable(newValue)}
          sx={{
            marginBottom: 1,
            "& .MuiButtonBase-root.Mui-selected": {
              color: palette.laranjaOxeLab,
            },
            "& .MuiTabs-indicator": {
              background: palette.laranjaOxeLab,
            },
          }}
        >
          {tables.map((table, index) => (
            <Tab value={index} label={table.name} key={index} />
          ))}
        </Tabs>
      </Box>

      <DataGrid
        rows={tables[chosenTable].rows}
        columns={tables[chosenTable].columns}
        // components={{ Toolbar: GridToolbar }}
        // columnVisibilityModel={{ id: false }}
        sx={{
          height: "90%",
          background: palette.profileWhiteShadeTransp,
          borderRadius: "15px",
          "& .MuiDataGrid-toolbarContainer": {
            "& .MuiButton-text": {
              color: palette.laranjaOxeLab,
            },
          },
          "& .MuiDataGrid-columnHeaderTitle": {
            color: palette.preto,
          },
          "& .MuiDataGrid-cellContent": {
            color: palette.preto,
          },
        }}
        localeText={helperTexts.dataGridToolbarText}
      />
      {chosenTable === 0 && turma.status === "2"? (
        <Box
          sx={{
            display: "flex",
            position: "fixed",
            right: "20px",
            bottom: "55px",
            zIndex: 9999,
          }}
        >
          <Fab
            onClick={() => setMatriculaOn(true)}
            variant="extended"
            sx={{
              background: "rgba(212, 109, 13, 1)",
              color: "white",
              "&:hover": { background: "rgba(212, 109, 13, 0.7)" },
            }}
          >
            + Criar Aula
          </Fab>
        </Box>
      ) : chosenTable === 1 && permissao && turma.status === "2" ? (
        <Box
          sx={{
            display: "flex",
            position: "fixed",
            right: "20px",
            bottom: "55px",
            zIndex: 9999,
          }}
        >
          <Fab
            onClick={() => setEncerrarOn(true)}
            variant="extended"
            sx={{
              background: "rgba(212, 109, 13, 1)",
              color: "white",
              "&:hover": { background: "rgba(212, 109, 13, 0.7)" },
            }}
          >
            Encerrar Turma
          </Fab>
        </Box>
      ) : (
        turma.status === "0" && (
          <Box
            sx={{
              display: "flex",
              position: "fixed",
              right: "20px",
              bottom: "55px",
              zIndex: 9999,
            }}
          >
            <Fab
              onClick={() => setOpenCertificadoTurma(true)}
              variant="extended"
              sx={{
                background: "rgba(212, 109, 13, 1)",
                color: "white",
                "&:hover": { background: "rgba(212, 109, 13, 0.7)" },
              }}
            >
              <WorkspacePremiumIcon />
              Gerar Certificados
            </Fab>
          </Box>
        )
      )}

      <Aula
        open={matriculaOn}
        handleClose={() => setMatriculaOn(false)}
        aulasNumero={aulasNumero}
        idTurma={turma.id}
      />
      <EncerrarTurma
        open={encerrarOn}
        handleClose={() => setEncerrarOn(false)}
        idTurma={turma.id}
      />
      <ModalCertificados
        open={openCertificadoTurma}
        onClose={() => setOpenCertificadoTurma(false)}
        turma={turma}
      />
    </Box>
  );
}

export default TableCard;

import * as React from "react";
import { withRouter } from "react-router-dom";
import {
  AppBar,
  Box,
  Container,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import logo from "../../assets/LOGO_OXETECH_WORK_01-removebg-preview 3.png";
import { signOut } from "../../store/modules/auth/actions";
import { Link, NavLink } from "react-router-dom";
import { ButtonOn, ButtonOff, Cadastrar } from "./styles";
import { useDispatch, useSelector } from "react-redux";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { store } from "../../store";
import Avatar from "@mui/material/Avatar";
import LogoutIcon from "@mui/icons-material/Logout";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
import { useState, useEffect } from "react";
import Painel from "../Notificacoes/painel";
import Badge from "@mui/material/Badge";
import { seeNotifications } from "../../store/modules/user/actions";
import api from "../../services/api";
import { Logout } from "@mui/icons-material";
import { useAuth } from "../../hooks/auth/AuthContext";
const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 800,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
});
const pages = [{ name: "Vagas", url: "/oxetechwork" }];

const ResponsiveAppBar = (props) => {
  const { history } = props;
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const { logout } = useAuth();

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleMenuClick = (pageURL) => {
    handleCloseNavMenu();
    history.push(pageURL);
  };

  const dispatch = useDispatch();

  function handleSignOut() {
    logout();
  }

  const isActive = window.location.pathname;
  const { signed } = store.getState().auth;
  const { adminUser } = useAuth();
  let profile = useSelector((state) => {
    if (
      state &&
      state.user &&
      state.user.profile &&
      state.user.profile.dataValues
    ) {
      return state.user.profile.dataValues;
    } else if (state && state.user && state.user.profile) {
      return state.user.profile;
    }
  });

  function getName() {
    let name;

    if (profile.razao_social) {
      name = profile.razao_social.split("")[0];
    }

    if (profile.nome_social) {
      name = profile.nome_social.split("")[0];
    } else if (profile.name) {
      name = profile.name.split("")[0];
    }

    return name;
  }

  //Notificações
  const [notificacoes, setNotificacoes] = useState([]);

  const [anchorNotif, setAnchorNotif] = useState(null);

  const [hasNewNotifications, setHasNewNotifications] = useState(false);

  //Identificar o usuário e usar a rota
  const getNotificationEndpoint = () => {
    if (profile.cpf !== undefined && profile.reponsibility === undefined) {
      //É aluno
      return "/aluno/see-notifications";
    } else if (profile.cnpj !== undefined) {
      //É empresa
      return "/empresa/see-notifications";
    } else if (profile.reponsibility !== undefined) {
      //É admin
      return "/admin-see-notifications";
    }
  };

  const markAllNotificationAsSeen = async () => {
    if (hasNewNotifications) {
      const endpoint = getNotificationEndpoint();
      setNotificacoes(
        notificacoes.map((notificacao) => (notificacao.seen = true))
      );
      await api.patch(endpoint, { userId: profile.id });

      // profile.notificacoes = notificacoes;
      dispatch(seeNotifications(profile, endpoint));
    }
  };
  //Checar se tem novas notificações para ativar o badge
  const checkHasNewNotifications = () => {
    if (notificacoes.length === 0) {
      setHasNewNotifications(false);
    }

    for (const notificacao of notificacoes) {
      console.log(notificacao);
      if (notificacao.seen === false) {
        setHasNewNotifications(true);
        break;
      }
    }
  };

  useEffect(() => {
    if (profile && profile.notificacoes) {
      setNotificacoes(profile.notificacoes);
      checkHasNewNotifications();
    }
  }, [profile]);

  const hadleOpenNotif = (event) => {
    setAnchorNotif(event.currentTarget);
  };

  const handleCloseNotif = async () => {
    // Marcar todas as notificações como lidas
    // Fechar a aba de notificações
    setAnchorNotif(null);
    setHasNewNotifications(false);
    await markAllNotificationAsSeen();
  };

  return (
    <ThemeProvider theme={theme}>
      <AppBar
        style={{
          background: "#1F308A",
          lineHeight: "8%",
        }}
        position="relative"
        elevation={0}
      >
        <Container maxWidth="100vw">
          <Toolbar disableGutters sx={{ padding: "1" }}>
            <Box sx={{ flexGrow: 1, display: { xs: "none", sm: "flex" } }}>
              <Tooltip title="Logo OxeTechWork">
                <IconButton onClick={() => handleMenuClick("/oxe-tech-work")}>
                  <img width={200} src={logo} alt="OxeTechWork" />
                </IconButton>
              </Tooltip>
            </Box>
            {/* ---------- Visível para Mobile ----------*/}

            <Box sx={{ display: { xs: "flex", sm: "none" } }}>
              <IconButton
                aria-label="Páginas do OxeTech"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon fontSize="40px" />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: "block", sm: "none" },
                }}
              >
                <Box>
                  {pages.map((page) => (
                    <MenuItem
                      key={page.name}
                      onClick={() => handleMenuClick(page.url)}
                    >
                      <Typography textAlign="center">{page.name}</Typography>
                    </MenuItem>
                  ))}
                  {signed !== false || adminUser === true ? (
                    <>
                      <MenuItem onClick={() => handleMenuClick("/dashboard")}>
                        <Typography>Meu Perfil</Typography>
                      </MenuItem>
                      <MenuItem onClick={() => handleMenuClick("/account")}>
                        <Typography>Editar perfil</Typography>
                      </MenuItem>
                      <MenuItem onClick={handleSignOut}>
                        <Typography textAlign="center">Sair</Typography>
                      </MenuItem>
                    </>
                  ) : (
                    <>
                      <MenuItem onClick={() => handleMenuClick("/login")}>
                        <Typography>Login</Typography>
                      </MenuItem>
                      <MenuItem
                        onClick={() => handleMenuClick("/select-cadastro")}
                      >
                        <Typography>Cadastre-se</Typography>
                      </MenuItem>
                    </>
                  )}
                </Box>
              </Menu>
            </Box>
            <Box
              sx={{
                flexGrow: 3,
                justifyContent: "center",
                display: { xs: "flex", sm: "none" },
              }}
            >
              <Tooltip title="Logo OxeTech">
                <IconButton onClick={() => handleMenuClick("/")}>
                  <img width={150} src={logo} alt="OxeTechWork" />
                </IconButton>
              </Tooltip>
            </Box>

            {/* ---------- FIM Visível para Mobile ----------*/}

            <Box
              sx={{
                flexGrow: 0,
                display: { xs: "none", sm: "flex" },
              }}
            >
              {pages.map((page, i) => (
                <NavLink key={i} to={page.url}>
                  {page.url === "/select-cadastro" ? (
                    <Cadastrar>{page.name}</Cadastrar>
                  ) : isActive === page.url ? (
                    <ButtonOn>{page.name}</ButtonOn>
                  ) : (
                    <ButtonOff>{page.name}</ButtonOff>
                  )}
                </NavLink>
              ))}
              {signed === false && adminUser === false ? (
                <>
                  <ButtonOff onClick={() => handleMenuClick("/login")}>
                    Login
                  </ButtonOff>
                  <Cadastrar
                    onClick={() => handleMenuClick("/select-cadastro")}
                  >
                    Cadastre-se
                  </Cadastrar>
                </>
              ) : (
                <>
                  <MenuItem onClick={() => handleMenuClick("/dashboard")}>
                    <Typography textAlign="center">Meu Perfil</Typography>
                  </MenuItem>

                  <Tooltip title="Notificações" arrow>
                    <IconButton onClick={hadleOpenNotif} sx={{ margin: "4px" }}>
                      <Badge
                        color="error"
                        overlap="circular"
                        badgeContent=" "
                        variant="dot"
                        sx={{ margin: "6px 2px" }}
                        invisible={!hasNewNotifications}
                      >
                        <NotificationsNoneOutlinedIcon
                          sx={{ color: "white" }}
                        />
                      </Badge>
                    </IconButton>
                  </Tooltip>
                  <Painel
                    anchorNotif={anchorNotif}
                    handleCloseNotif={handleCloseNotif}
                    notificacoes={notificacoes || []}
                  />

                  <Tooltip title="Início" arrow>
                    <IconButton sx={{ pr: 2 }}>
                      <Link to="/oxe-tech-work">
                        <Avatar
                          className="avatar"
                          size={40}
                          style={{
                            backgroundColor: "rgba(211, 211, 211)",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <h3
                            style={{
                              color: "#000",
                              position: "relative",
                              top: "5px",
                            }}
                          >
                            {getName()}
                          </h3>
                        </Avatar>
                        {/* <Avatar alt="Remy Sharp" src="/static/images/avatar/2.pg" /> */}
                      </Link>
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Sair" arrow>
                    <IconButton
                      onClick={handleSignOut}
                      aria-label="sair"
                      sx={{ color: "red", display: "inline" }}
                    >
                      <LogoutIcon fontSize={"small"} />
                    </IconButton>
                  </Tooltip>
                </>
              )}
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </ThemeProvider>
  );
};
export default withRouter(ResponsiveAppBar);

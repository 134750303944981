import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CircularProgress from "@mui/material/CircularProgress";
import { Grid, Box, Typography, Paper, useMediaQuery } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import * as palette from "../../utils/styledVariables";

const theme = createTheme({
  palette: {
    primary: {
      main: palette.laranjaSecundarioTransp,
    },
    secondary: {
      main: "#FFFFFF",
    },
  },
});

const styles = {
  paper: {
    margin: "10px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
};

const DualColorCircularProgress = (props) => {
  const { value } = props;
  const numberValue = parseFloat(value) * 100;
  const isSmallScreen = useMediaQuery("(max-width:600px)");

  return (
    <ThemeProvider theme={theme}>
      <Box position="relative" display="inline-flex">
        <CircularProgress
          variant="determinate"
          value={100}
          size={isSmallScreen ? "80px" : "100px"}
          thickness={4}
          color="primary"
        />
        <CircularProgress
          variant="determinate"
          value={numberValue}
          size={isSmallScreen ? "80px" : "100px"}
          thickness={4}
          color="secondary"
          sx={{
            position: "absolute",
            left: 0,
          }}
        />
        <Box
          top={0}
          left={0}
          bottom={0}
          right={0}
          display="flex"
          alignItems="center"
          justifyContent="center"
          position="absolute"
        >
          <Typography
            variant="h6"
            component="div"
            color="textSecondary"
            sx={{ color: "#FFFFFF" }}
          >
            {`${numberValue}%`}
          </Typography>
        </Box>
      </Box>
    </ThemeProvider>
  );
};

const CardDesempenho = ({ mediaParcial, notas, progresso }) => {
  const isSmallScreen = useMediaQuery("(max-width:600px)");

  return (
    <Card sx={{ backgroundColor: palette.laranjaIntermediario2, borderRadius: "2vh" }}>
      <CardContent
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          paddingLeft: "10px",
          marginLeft: isSmallScreen ? "10px" : "20px",
        }}
      >
        <Typography
          variant="h5"
          component="div"
          style={{ fontWeight: "bold", color: "white" }}
        >
          Desempenho na Trilha
        </Typography>
        <Grid container>
          <Grid
            item
            xs={12}
            sm={6}
            md={6}
            lg={6}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              padding: isSmallScreen ? "0px" : "0px",
            }}
          >
            <Paper
              sx={[
                {
                  backgroundColor: palette.laranjaSecundarioTransp,
                  height: "50px",
                  width: isSmallScreen ? "100%" : "10vw",
                },
                styles.paper,
              ]}
            >
              <Typography
                sx={{
                  fontSize: "16px",
                  color: "white",
                  fontFamily: "Poppins",
                  fontWeight: 500,
                }}
              >
                Média Parcial: {mediaParcial}
              </Typography>
            </Paper>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={6}
            lg={6}
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div style={{ marginTop: "12px" }}>
              <DualColorCircularProgress value={progresso} />
            </div>
          </Grid>
        </Grid>
        <Grid container>
          <Typography
            variant="h5"
            component="div"
            style={{ fontWeight: "bold", color: "white" }}
          >
            Desempenho por atividades
          </Typography>
          <Grid
            item
            xs={12}
            sm={6}
            md={8}
            lg={8}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              padding: isSmallScreen ? "0px" : "0px",
            }}
          >
            {notas.map((nota, idx) => (
              <Paper
                key={idx}
                sx={[
                  {
                    backgroundColor: palette.laranjaSecundarioTransp,
                    height: "50px",
                    width: isSmallScreen ? "100%" : "10vw",
                  },
                  styles.paper,
                ]}
              >
                <Typography
                  sx={{
                    fontSize: "16px",
                    color: "white",
                    fontFamily: "Poppins",
                    fontWeight: 500,
                  }}
                >
                  {idx + 1}° Módulo: {nota}
                </Typography>
              </Paper>
            ))}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default CardDesempenho;

import { makeStyles, styled } from '@material-ui/styles';
import { Chip } from '@mui/material';
import styledComponent from 'styled-components'
export const useStyles = makeStyles(() => ({
  root: {
    height: '14vh',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0.5rem',
    alignItems: 'center',
    gap: 2,
    borderRadius: '15px',
    boxSizing: 'border-box',
    marginBottom: '2rem',
    cursor: 'pointer',
  },

  iconAndTitleContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flexGrow: 1,
    height: '100%',
    width: '65%',
    marginTop: '3vh',
    marginBottom: '3vh',
    
  },

  icon: {
    margin: '0 1rem 0 1rem',
    backgroundColor: '#D9D9D978',
    width: 'auto',
    height: '7vh',
    flexShrink: 0,
    aspectRatio: '1/1', 
  },

  iconImg: {
    width: '60%',
    height: '60%',
    objectFit: 'contain'
  },

  title: {
    fontFamily: 'Roboto',
    fontWeight: 400,
    height: '100%',
    display: 'flex',
    alignItems: 'center',
  },

  statusAndDateContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignContent: 'flex-end',
    height: '100%',
    maxWidth: '35%'
  },

  dateContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'inherit',
  },

  // professor from here
  createButton: {
    color: 'white',
    backgroundColor: '#D46D0D',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: '15px',
    fontSize: '1.0rem',
    paddingLeft: '1rem',
    paddingRight: '1rem',
    marginBottom: '1rem',
    color: 'white'
  },

  dropDownButtonContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end'
  },

  dropDownRotateUp: {
    transform: 'rotate(180deg)',
    transition: 'transform 0.3s ease-in-out',
  },

  dropDownRotateDown: {
    transform: 'rotate(0deg)',
    transition: 'transform 0.3s ease-in-out',
  },

  activiesNumberContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    width: '100%',
    textAlign: 'center',
    marginBottom: '2rem',
    height: '10vh'
  },

  activiesNumberChildContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  }
}));

const LabelChip = styled((props) =>
  <Chip {...props} variant='filled' />)({    
    fontWeight: 400,
    textAlign: 'center',
    padding: '0.4rem'
  });

export const ChipPendente = styled((props) =>
  <LabelChip {...props} label="Pendente" />)({
    color: '#D46D0D',
    backgroundColor: '#D56D0D2E',
  });

export const ChipConcluido = styled((props) =>
  <LabelChip {...props} label='Concluído' />)({
    backgroundColor: '#D46D0D',
    color: 'white',
  });

export const VerticalBar = styledComponent.div`
  position: relative;
  width: 2px;
  height: 100%;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 1px;
    background-color: #ccc;
  }
`;
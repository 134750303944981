import * as React from 'react'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Carousel from 'react-material-ui-carousel'

import { Typography } from '@mui/material';

import iconeOxetechWork from '../../../../src/assets/iconeOxetechWork.png'

import img1 from '../../../../src/assets/empresasOxeWork/cit_hd_white.svg'
import img2 from '../../../../src/assets/empresasOxeWork/doity.svg'
import img3 from '../../../../src/assets/empresasOxeWork/handtalk_hd.svg'
import img4 from '../../../../src/assets/empresasOxeWork/id5_hd.svg'
import img5 from '../../../../src/assets/empresasOxeWork/senai.svg'

const logos = [
    {nome: img1, width: '50%', alt: 'Logo da cit'},
    {nome: img2, width: '50%', alt: 'Logo da doity'},
    {nome: img3, width: '50%', alt: 'Logo da handtalk'},
    {nome: img4, width: '50%', alt: 'Logo da id5'},
    {nome: img5, width: '50%', alt: 'Logo da senai'}
]


export default function QuemEstaContratando() {

  return (
    <Box sx={{minHeight: 500, display: 'flex', gap: 3, alignItems: 'center', justifyContent: 'center',background:' radial-gradient(331.06% 357.54% at 14.93% -190.86%, rgba(31, 48, 138, 0.7) 66.57%, rgba(107, 133, 194, 0.7) 100%)'
}}>
    <Grid container spacing={1}>
        <Grid item xs={12} md={12} lg={12}>
            <Typography sx={{ textAlign: 'center', p: 4, pl: 7, fontWeight: 500, fontFamily: 'Poppins', color: '#FFFFFF' }} variant="h2">
                Veja quem está contratando
            </Typography>
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
        <Carousel 
            navButtonsAlwaysInvisible={true}
            stopAutoPlayOnHover ={false}
            swipe={true}
            animation="slide"
            sx={{}}>
                {logos.map((logo)=>{
                    return(<div style={{height: '30vh',display:'flex', justifyContent: 'center' }}><img src={logo.nome} alt= {logo.alt} width={logo.width}/></div>) 
                })}
            </Carousel>
        </Grid>
    </Grid>
{/* 
        <Box>
            <Box sx={{display: 'flex', justifyContent: 'center', p: 5, mt: 4}}>
                <img src={iconeOxetechWork} alt={iconeOxetechWork} />
            </Box>
        </Box> */}
    </Box>
  )
}

import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { List, Button } from 'antd';
import * as palette from '../../utils/styledVariables';
import api from '../../services/api';

import logo from '../../assets/logo_oxeDIN.png';

import { Container, Header, Vagas } from './styles';

function PageVagasAlunos() {
  const [vagas, setVagas] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    api.get('vagas/vagasabertas').then(response => {
      setVagas(response.data);
      setLoading(false);
    });
  }, []);

  return (
    <Container>
      <Header>
        <img src={logo} alt="Oxe-tech_Oxe-Din" />
      </Header>

      <Vagas>
        <List
          itemLayout="vertical"
          size="small"
          pagination={{
            pageSize: 4,
            pageSizeOptions: [4],
            size: 'small',
            responsive: true
          }}
          loading={loading}
          dataSource={vagas}
          renderItem={item => (
            <List.Item
              key={item.id}
              actions={[
                <Link to={`vaga/${item.id}`}>
                  <Button
                    style={{
                      background: palette.profileMainColor,
                      color: '#fff',
                      border: 0,
                      fontSize: '1.3rem',
                      fontWeight: '600'
                    }}
                  >
                    Mais informações
                  </Button>
                </Link>
              ]}
            >
              <List.Item.Meta
                title={<Link to={`vaga/${item.id}`}>{item.titulo}</Link>}
                description={item.descricao}
              />
              {item.content}
            </List.Item>
          )}
        />
      </Vagas>
    </Container>
  );
}

export default PageVagasAlunos;

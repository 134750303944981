import { takeLatest, call, put, all } from "redux-saga/effects";
import { message } from "antd";
import Options from "../../../utils/options";
import history from "../../../services/history";
import api from "../../../services/api";

import { signInSuccess, signInFailure, mapaInSucess } from "./actions";

// export function* signIn({ payload }) {
//   const { email, password } = payload;
//   const response = yield call(api.post, "usersessions", {
//     email,
//     password,
//   });
//   try {

//     const { token, labs_data,...rest } = response.data;
//     api.defaults.headers.Authorization = `Bearer ${token}`;

//     yield put(
//       signInSuccess(token, rest, {
//         data: labs_data,
//       },
//       )
//     );

//     rest.responsabilidade === Options.profileResponsabilites.admin ||
//     rest.responsabilidade === Options.profileResponsabilites.sudo
//       ? history.push("/dashadmin")
//       : history.push("/dashboard");
//   } catch (err) {
//     message.error(err.response.data.error);
//     yield put(signInFailure());
//   }
// }

export function setToken({ payload }) {
  if (!payload) return;

  const { token } = payload.auth;

  if (token) {
    api.defaults.headers.Authorization = `Bearer ${token}`;
  }
}

export function signOut() {
  history.push("/");
}

export function* sigInMapa() {
  try {
    const response = yield call(api.post, "/mapa", {});

    const { token } = response.data;

    // yield put(mapaInSucess(token));
  } catch (err) {
    message.error(err.response.data.error);
  }
}

export default all([
  takeLatest("persist/REHYDRATE", setToken),
  // takeLatest("@auth/SIGN_IN_REQUEST", signIn),
  takeLatest("@auth/SIGN_OUT", signOut),

  takeLatest("@auth/MAPA_IN_REQUEST", sigInMapa),
  // takeLatest("@auth/SIGN_UP_REQUEST", signUp)
]);

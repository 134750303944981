import { styled } from "@mui/material/styles";
import { Box } from "@mui/system";
import "animate.css";
import {
  gradienteLaranja,
  gradienteLaranjaInvertido,
} from "../../utils/styledVariables";
export const Container = styled("div")`
  // background: linear-gradient(
  //   180deg,
  //   #741f8a 48.1%,
  //   #340e74 89.08%,
  //   #000063 122.33%

  background: ${gradienteLaranjaInvertido};
  overflow: auto;
  /* width */
  /* ::-webkit-scrollbar {
    width: 10px;
  }
  
  ::-webkit-scrollbar-track {
    background: #f1f1f16f;
    border: 1px solid red;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #8f54a0;
    border-radius: 10px;
  } */
`;

export const Introducao = styled(Box)`
  width: 100%;
  height: auto;

  // background-image: url(/assets/banner.png);
  background-color: #741f8a;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  // padding: 2.5vh 5vw;

  .grow {
    @media (min-width: 700px) {
      flex-grow: 1;
    }
  }
  @media (max-width: 800px) {
    padding: 0;
    justify-content: flex-end;
    flex-direction: column-reverse;
  }

  @media (max-width: 380px) {
    padding: 0;
    justify-content: flex-end;
    flex-direction: column-reverse;
  }
`;

export const Title = styled(Box)`
  width: 50vw;
  padding-right: 5vw;
  margin: 2vh;
  font-family: "Poppins", sans-serif;
  .titulo {
    color: #fff;
    font-size: 2.7rem;
    animation: myAnim 4s ease 0s 1 normal backwards;
    @media (max-width: 800px) {
      text-align: center;
    }
    @media (min-width: 1500px) {
      font-size: 4rem;
    }
    @keyframes myAnim {
      0% {
        opacity: 0;
        transform: translateY(50px);
      }

      100% {
        opacity: 1;
        transform: translateY(0);
      }
    }
  }

  .subtitulo {
    color: rgba(255, 255, 255, 0.9);
    font-family: "Poppins", sans-serif;
    font-size: 0.9rem;
    text-align: justify;
    animation: fadeInUp;
    animation-duration: 3s;
    @media (max-width: 800px) {
      text-align: center;
    }
    @media (min-width: 1800px) {
      font-size: 1.5rem;
    }
  }
  @media (max-width: 800px) {
    width: 90vw;
  }
`;

export const Programas = styled(Box)`
  width: 100%;
  height: auto;
  background: ${gradienteLaranja};
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  .titulo {
    font-size: 400%;
    font-family: "Poppins", sans-serif;
    color: white;
    @media (min-width: 1500px) {
      font-size: 500%;
    }
    @media (max-width: 1000px) {
      font-size: 300%;
    }
  }
`;

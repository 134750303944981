import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CloseIcon from "@mui/icons-material/Close";
import {
  Button,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Tooltip,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
import api from "../../services/api";
import Buscador from "../Buscador";
import AssistantPhotoIcon from "@mui/icons-material/AssistantPhoto";
import * as palette from "../../utils/styledVariables";

const useStyles = makeStyles((theme) => ({
  customList: {
    overflow: "auto",
    maxHeight: "200px",
    "&::-webkit-scrollbar": {
      width: "4px",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#6B7280",
      borderRadius: "4px",
    },
  },
}));
const EditAgentes = (props) => {
  const classesScroll = useStyles();
  const { alunos, styles, classes, isEdit, coordenador } = props;
  const [agente, setAgente] = useState(null);
  const [agentes, setAgentes] = useState([]);
  const [exibirCampoAdicionar, setExibirCampoAdicionar] = useState(false);

  useEffect(() => {
    api
      .get(`agente/agentes-lab?labId=${isEdit}`)
      .then((response) => {
        setAgentes(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [isEdit]);

  const excluirAgente = (index) => {
    api
      .delete(`agente/delete?agenteId=${agentes[index].id}`)
      .then((response) => {
        const nomesAtualizados = [...agentes];
        nomesAtualizados.splice(index, 1);
        setAgentes(nomesAtualizados);
      })
      .catch((error) => {
        console.log("Erro ao deletar agente");
      });
  };
  const habilitarSuperAgente = (index) => {
    const agenteHabilitado = [...agentes];
    agenteHabilitado[index].super_agente =
      !agenteHabilitado[index].super_agente;
      console.log(agenteHabilitado)
    console.log(
      agenteHabilitado[index].id,
      isEdit,
      agenteHabilitado[index].Aluno.id,
      agenteHabilitado[index].super_agente
    );
    const data = {
      laboratorio_id: isEdit,
      aluno_id: agenteHabilitado[index].Aluno.id,
      super_agente: agenteHabilitado[index].super_agente,
    };
    api
    .put(`agente/${agenteHabilitado[index].id}`, data)
    .then((response) => {
        setAgentes(agenteHabilitado);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const onSelectAgentes = (e, newValue) => {
    // setAgente(newValue);
    if (newValue) {
      const agenteExists = agentes.some((objeto) =>
        objeto.Aluno
          ? objeto.aluno_id === newValue.value?.id
          : objeto.id === newValue.value?.id
      );
      if (!agenteExists) {
        const data = {
          aluno_id: newValue.value.id,
          laboratorio_id: isEdit,
          super_agente: false,
        };
        api
          .post(`agente/`, data)
          .then((response) => {
            setAgentes([
              ...agentes,response.data,
            ]);
            setAgente(null);
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        alert("Agente já existe.");
      }
    }
  };

  const dataBuscadorAgentes = alunos.map((obj) => ({
    name: obj.name,
    label: obj.cpf,
    value: obj,
  }));

  return (
    <>
      <Grid item xs={12} md={8} sx={styles.field}>
        <Typography sx={styles.subtitles}>Agentes do Laboratório</Typography>
        <List className={classesScroll.customList}>
          {/* {console.log("agentes", agentes)} */}
          {agentes.map(
            (nome, index) =>
              nome.Aluno?.cpf !== coordenador && (
                <ListItem key={index}>
                  <ListItemText
                    primary={
                      nome.Aluno ? (
                        <span style={{ fontSize: "0.9em" }}>
                          {nome.Aluno.name} - {nome.Aluno.cpf}
                        </span>
                      ) : (
                        <span style={{ fontSize: "0.9em" }}>
                          {nome.name} - {nome.cpf}
                        </span>
                      )
                    }
                  />
                  <ListItemSecondaryAction>
                    <Tooltip title="Excluir agente" placement="top">
                      <IconButton
                        // edge="end"
                        aria-label="delete"
                        onClick={() => excluirAgente(index)}
                      >
                        <CloseIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Super-agente" placement="top">
                      <IconButton
                        // edge="end"
                        aria-label="super agente"
                        onClick={() => habilitarSuperAgente(index)}
                      >
                        <AssistantPhotoIcon
                          sx={{
                            color: nome.super_agente
                              ? "rgba(212, 109, 13,  1)"
                              : "gray",
                          }}
                        />
                      </IconButton>
                    </Tooltip>
                  </ListItemSecondaryAction>
                </ListItem>
              )
          )}
        </List>
      </Grid>
      <Grid item xs={12} md={12} sx={styles.field}>
        {exibirCampoAdicionar ? (
          <div style={{ display: "flex" }}>
            <Buscador
              onSelect={onSelectAgentes}
              data={dataBuscadorAgentes}
              label={"Selecione o agente por CPF"}
              sx={{ width: "100%" }}
            />
            <Tooltip title="Voltar">
              <IconButton
                // edge="end"
                aria-label="delete"
                onClick={() => setExibirCampoAdicionar(false)}
              >
                <ArrowBackIcon />
              </IconButton>
            </Tooltip>
          </div>
        ) : (
          <div style={{ display: "flex", justifyContent: "flex-start" }}>
            <Button
              variant="text"
             sx={{color: palette.laranjaIntermediario2}}
              onClick={() => setExibirCampoAdicionar(true)}
            >
              Adicionar novo agente
            </Button>
          </div>
        )}
      </Grid>
    </>
  );
};

export default EditAgentes;

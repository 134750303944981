import styled from "styled-components";
import { Modal } from 'antd';

export const ModalEmpresa = styled(Modal)`
    display: flex;
    justify-content: center;

    .ant-modal-wrap{
        width: 60vw;
        @media (max-width: 720px) {
            width: 90vw;
        }
    }
    .ant-modal-content{
        width: 60%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        padding: 2%;

        @media (max-width: 720px) {
            width: 90vw;
        }
    }
    .ant-modal-body{
        padding: 0 4%;
    }
    .header{
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
    }
    .logo{
        width: 40%;
        margin-bottom: 5%;
    }
    h2{
        font-family: 'Poppins', sans-serif;
        font-size: 1.8em;
        margin-bottom: 5%;
        @media (max-width: 720px) {
        font-size: 1.4em;
        text-align:center;
        }
    }
    h3{
        font-family: 'Karla', sans-serif;
        font-weight: 700;
        font-size: 1.5em;
        padding:0;
        margin: 0;
        @media (max-width: 720px) {
        font-size: 1.2em;
        }
    }
    h4{
        font-family: 'Karla', sans-serif;
        font-weight: 700;
        font-size: 1.2em;
        @media (max-width: 720px) {
        font-size: 1em;
        }
    }
    h5{
        font-family: 'Karla', sans-serif;
        font-weight: 600;
        font-size: 1em;
       
    }
    ol{
        color: #000;
        margin-left: 3%;
    } 
    ol li::marker{
        color: #8E24AA;
    } 
    li{
        font-family: 'Karla', sans-serif;
        font-weight: 600;
        font-size: 1em;
        
    }
    .info{
        display:flex;
        align-items: flex-start;
    }
    .botoes{
        display:flex;
        flex-direction: column;
    }
    .botao{
        background: #741F8A;
    }
    .botao:hover{
        background: rgba(116, 31, 138, 0.58);
    }

`
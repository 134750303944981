import React from 'react'
import { useSelector } from 'react-redux';
import MainHeader from '../../components/MainHeader'


import logo from '../../assets/logo_oxeDIN.png'

import Card from '../../components/VagasOxedin'


import { Container, Logo } from './styles'

function Oxedin() {
  const signed = useSelector(state => state.auth.signed);
  
  return (
    <Container>
      { !signed ? <MainHeader /> : ''}
          <Logo>
              <img src={logo} className="img" alt="Oxe-tech_Oxe-Din" />
            <p className="subtitulo">
              Encontre oportunidades nas melhores empresas alagoanas!
            </p>
          </Logo>
          <Card className="card" />  
    </Container>
  )
}

export default Oxedin
import React from "react";
import ReactApexChart from "react-apexcharts";

const FrequencyChart = ({ data }) => {
  const frequencies = {};
  data.forEach((item) => {
    if (item)
      frequencies[item] = frequencies[item] ? frequencies[item] + 1 : 1;
  });

  const chartData = {
    categories: Object.keys(frequencies),
    series: [
      {
        name: "Frequência",
        data: Object.values(frequencies),
      },
    ],
  };

  const options = {
    chart: {
      type: "bar",
    },
    xaxis: {
      categories: chartData.categories,
      labels: {
        style: {
          fontSize: "12px",
        },
      },
      title: {
        text: "Tecnologias",
      },
    },
    yaxis: {
      title: {
        text: "Frequência",
      },
    },
    colors: ["#D46D0D"],
  };

  return (
    <div>
      <h6>Habilidades dos inscritos</h6>
      <ReactApexChart
        options={options}
        series={chartData.series}
        type="bar"
        height={250}
      />
    </div>
  );
};

const App = (props) => {
  return (
    <div>
      <FrequencyChart data={props.habilidades} />
    </div>
  );
};

export default App;

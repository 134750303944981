import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import ProfileAluno from './ProfileAluno';

import { useLocation } from 'react-router-dom';
import VagasOxedin from '../VagasOxedin';

function Main({styles}){
    const location = useLocation();
    let content = '';

    switch (location.pathname) {
        case '/profilealuno' : {
            content = <ProfileAluno />
            break;
        }
        default: {
            content = <ProfileAluno/>
        }
    }

    return (
        <Box
            component="main"
            sx={{ ...styles, backgroundColor: '#FFFFFF', flexGrow: 1, p: 3 }}
        >
            {content}
        </Box>
    )
}

export default Main;
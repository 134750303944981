import {
  Box,
  Button,
  Grid,
  Modal,
  Paper,
  TextField,
  Typography,
  FormHelperText,
  Tooltip,
  IconButton,
} from "@mui/material";
import Alert from "@mui/material/Alert";
import CircularProgress from "@mui/material/CircularProgress";
import Snackbar from "@mui/material/Snackbar";
import { makeStyles } from "@mui/styles";
import cep from "cep-promise";
import React, { useEffect, useState } from "react";
import InputMask from "react-input-mask";
import api from "../../../../services/api";
import * as palette from "../../../../utils/styledVariables";
import Buscador from "../../../../components/Buscador/index";
import { useSnackbar } from "notistack";
import alterar from "../../../../assets/iconesOxeLab/alterar.svg";
const useStyles = makeStyles({
  autocomplete: {
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "purple",
    },
    "& label.Mui-focused": {
      color: "purple",
    },
  },
});

const styles = {
  modal: {
    width: "50%",
    position: "absolute",
    top: "50%",
    marginTop: "5%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "#f2f2f2",
    padding: "14px",
    textAlign: "center",
    borderRadius: "1vh",
    justifyContent: "center",
    overflow: "auto",
  },

  uploadButton: {
    height: "40px",
    color: palette.laranjaIntermediario2,
    "&:hover": {
      color: palette.laranjaSecundario,
    },
  },

  paper: {
    width: "100%",
    margin: "5px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  box: {
    backgroundColor: palette.laranjaOXLTransparente,
    padding: "10px",
    margin: "5px",
    borderRadius: "1vh",
    display: "flex",
    // flexDirection: "column",
  },
  subtitles: {
    fontFamily: "Roboto",
    fontStyle: "bold",
    fontWeight: "500",
    fontSize: "1.5vh",
    lineHeight: "2.5vh",
    margin: "0px 0px 5px 0px",
    textAlign: "left",
  },

  field: { padding: "5px" },
};

function ModalEditarEscola(props) {
  const { enqueueSnackbar } = useSnackbar();
  const [rua, setRua] = useState(sessionStorage.getItem("rua_labEdit") || "");
  const [bairro, setBairro] = useState(
    sessionStorage.getItem("bairro_labEdit") || ""
  );
  const [state, setState] = useState(sessionStorage.getItem("stateEdit") || "");
  const [municipio, setMunicipio] = useState(
    sessionStorage.getItem("municipioEdit") || ""
  );
  const [numero, setNumero] = useState(
    sessionStorage.getItem("numeroEdit") || ""
  );
  const [complemento, setComplemento] = useState(
    JSON.parse(sessionStorage.getItem("complementoEdit")) || ""
  );
  const [CEP, setCEP] = useState(sessionStorage.getItem("CEPEdit") || "");
  const [unidade, setUnidade] = useState(
    sessionStorage.getItem("unidadeEdit") || ""
  );

  const [coordenador, setCoordenador] = useState(null);
  const [agentes, setAgentes] = useState(null);
  const [ruaError, setRuaError] = useState("");
  const [bairroError, setBairroError] = useState("");
  const [municipioError, setMunicipioError] = useState("");
  const [numeroError, setNumeroError] = useState("");
  const [CEPError, setCEPError] = useState("");
  const [unidadeError, setUnidadeError] = useState("");
  const [coordenadorError, setCoordenadorError] = useState("");
  const [nomeCoordenador, setNomeCoordenador] = useState("");
  const [cpfCoordenador, setCpfCoordenador] = useState("");
  const [nomeAgente, setNomeAgente] = useState("");
  const [cpfAgente, setCpfAgente] = useState("");

  const [alertSuccessful, setAlertSuccessful] = useState("");
  const [alertUnauthorized, setAlertUnauthorized] = useState("");
  const [mostrarBuscadorCoordenador, setMostrarBuscadorCoordenador] =
    useState(false);
  const [mostrarBuscadorAgente, setMostrarBuscadorAgente] = useState(false);
  const [agenteError, setAgenteError] = useState("");
  const [dados, setDados] = useState();
  const [exibirTrocaCoordenador, setExibirTrocaCoordenador] = useState(false);
  const [exibirTrocaAgente, setExibirTrocaAgente] = useState(false);

  const handleEditarCoordenador = () => {
    setMostrarBuscadorCoordenador(true);
  };

  const handleEditarAgente = () => {
    setMostrarBuscadorAgente(true);
  };
  const [address, setAddress] = useState(null);
  const [errorCep, setErrorCep] = useState(null);
  const [alunos, setAlunos] = useState([]);
  const alunos_sem_coordenador = alunos?.filter(
    (obj) => obj.id !== coordenador?.id
  );
  const [exibirCampoAdicionar, setExibirCampoAdicionar] = useState(false);

  const [loading, setLoading] = useState(false);

  const classes = useStyles();
  const handleClose = () => {
    props.close();
    setExibirTrocaCoordenador(false);
    setExibirTrocaAgente(false);
  };

  useEffect(() => {
    if (props.open && props.escolaData && props.escolaData.id) {
      api
        .get(`/oxetechedu/escola/${props.escolaData.id}/escola`)
        .then((res) => {
          const coordenadorData = {
            id: res.data.professor_id,
            name: res.data.professor.name,
            cpf: res.data.professor.cpf,
          };

          const agentesData = {
            id: res.data.BolsistaOxetechEdu.aluno_id,
            name: res.data.BolsistaOxetechEdu.Aluno.name,
            cpf: res.data.BolsistaOxetechEdu.Aluno.cpf,
          };

          setDados(res.data);
          setCoordenador(coordenadorData);
          setAgentes(agentesData);
        })
        .catch((error) => {
          console.error("Falha ao buscar dados da escola:", error);
        });

      setBairro(props.escolaData.bairro || "");
      setRua(props.escolaData.rua || "");
      setMunicipio(props.escolaData.municipio || "");
      setNumero(props.escolaData.numero || "");
      setComplemento(props.escolaData.complemento || "");
      setCEP(props.escolaData.cep || "");
      setUnidade(props.escolaData.nome || "");
    }
  }, [props.escolaData, props.open]);
  const handleCep = (event) => {
    setCEP(event.target.value);
    setErrorCep({ CEP: "" });

    let reg = new RegExp(/^[0-9]{2}\.[0-9]{3}-[0-9]{3}$/).test(
      event.target.value
    );
    if (reg) {
      cep(event.target.value)
        .then((response) => {
          const {
            street: rua,
            neighborhood: bairro,
            city: municipio,
            cep,
            state: estado,
          } = response;

          setRua(rua);
          setBairro(bairro);
          setMunicipio(municipio);
          setState(estado);
        })
        .catch((error) => {
          setErrorCep({ CEP: "Não conseguimos resgatar o seu CEP." });
          setAddress({
            rua: "",
            bairro: "",
            municipio: "",
            cep: "",
            state: "",
            numero: "",
          });
        });
    } else {
      setErrorCep({ CEP: "CEP inválido." });
    }
  };

  const handlerua = (event) => {
    setRua(event.target.value);
  };

  const handlebairro = (event) => {
    setBairro(event.target.value);
  };

  const handlenumero = (event) => {
    setNumero(event.target.value);
    if (event.target.value.length > 0)
      sessionStorage.setItem("numero", event.target.value);
    else {
      sessionStorage.removeItem("numero");
    }
  };

  const handlemunicipio = (event) => {
    setMunicipio(event.target.value);
  };

  const handleFinish = async () => {
    let isValid = true;

    if (!coordenador) {
      setCoordenadorError("Campo obrigatório");
      isValid = false;
    } else {
      setCoordenadorError("");
    }

    if (!rua) {
      setRuaError("Campo obrigatório");
      isValid = false;
    } else {
      setRuaError("");
    }

    if (!bairro) {
      setBairroError("Campo obrigatório");
      isValid = false;
    } else {
      setBairroError("");
    }

    if (!municipio) {
      setMunicipioError("Campo obrigatório");
      isValid = false;
    } else {
      setMunicipioError("");
    }

    if (!numero) {
      setNumeroError("Campo obrigatório");
      isValid = false;
    } else {
      setNumeroError("");
    }

    if (isValid) {
      const data = {
        nome: unidade,
        rua: rua,
        municipio: municipio,
        bairro: bairro,
        numero: numero,
        cep: CEP.replace(/[.-]/g, ""),
        complemento: complemento,
        professor_id: coordenador.id,
        bolsista_id: agentes.id,
      };
      setLoading(true);
      api
        .patch(`/oxetechedu/escola/${props.escolaData.id}`, data)
        .then((response) => {
          setLoading(false);
          setAlertSuccessful(response.data.message);

          sessionStorage.removeItem("rua_labEdit");
          sessionStorage.removeItem("bairro_labEdit");
          sessionStorage.removeItem("stateEdit");
          sessionStorage.removeItem("municipioEdit");
          sessionStorage.removeItem("numeroEdit");
          sessionStorage.removeItem("CEPEdit");
          sessionStorage.removeItem("unidadeEdit");
          sessionStorage.removeItem("coordenadorEdit");
          sessionStorage.removeItem("agentesEdit");
          

          setTimeout(() => {
            handleClose();
          }, 1000);
        })
        .catch((err) => {
          setLoading(false);
          setAlertUnauthorized(err.response.data.error);
        });
    }
  };

  useEffect(() => {
    api.get("alunos").then((res) => {
      setAlunos(res.data);
    });
  }, []);

  useEffect(() => {
    sessionStorage.setItem("rua_labEdit", rua);
    sessionStorage.setItem("bairro_labEdit", bairro);
    sessionStorage.setItem("stateEdit", state);
    sessionStorage.setItem("municipioEdit", municipio);
    sessionStorage.setItem("numeroEdit", numero);
    sessionStorage.setItem("CEPEdit", CEP);    
  }, [rua, bairro, state, municipio, numero, CEP]);

  const onSelectCoordenador = (e, newValue) => {
    if (newValue) {
      setCoordenador(newValue.value);
    }
  };
  const onSelectAgentes = (e, newValue) => {
    if (newValue) {
      setAgentes(newValue.value);
    }
  };
  const dataBuscadorCoordenador = alunos.map((obj) => ({
    name: obj.name,
    label: obj.cpf,
    value: obj,
  }));
  const dataBuscadorAgente = alunos_sem_coordenador.map((obj) => ({
    name: obj.name,
    label: obj.cpf,
    value: obj,
  }));

  return (
    <Modal
      open={props.open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        overflow: "auto",
      }}
    >
      <Box sx={{}}>
        <Grid sx={styles.modal} container>
          <Grid container item xs={12} sm={12} md={12} lg={12}>
            <Paper
              sx={[
                {
                  backgroundColor: palette.laranjaOXLTransparente,
                  height: "50px",
                },
                styles.paper,
              ]}
            >
              <Typography
                sx={{
                  fontSize: "24px",
                  color: palette.preto,
                  fontFamily: "Poppins",
                  fontWeight: 500,
                }}
              >
                Edição de Escola
              </Typography>
            </Paper>
          </Grid>
          <Grid container spacing={2} sx={styles.box}>
            <Typography
              sx={{
                fontSize: "20px",
                color: palette.preto,
                fontFamily: "Poppins",
                fontWeight: 500,
                margin: "2px",
                textAlign: "left",
              }}
            >
              Dados dos responsáveis
            </Typography>
            <Grid
              item
              xs={12}
              md={12}
              sx={{ ...styles.field, alignItems: "center" }}
            >
              <Typography sx={styles.subtitles}>Professor</Typography>
              <div
                style={{
                  display: exibirTrocaCoordenador ? "none" : "flex",
                  alignItems: "center",
                }}
              >
                <h4
                  style={{
                    fontWeight: "initial",
                    textAlign: "start",
                    margin: 0,
                    marginRight: "8px",
                  }}
                >
                  {coordenador?.name} - {coordenador?.cpf}
                </h4>
                <Tooltip title="Alterar professor">
                  <IconButton
                    aria-label="change"
                    onClick={() => setExibirTrocaCoordenador(true)}
                  >
                    <img src={alterar} alt="alterar" width="15px" />
                  </IconButton>
                </Tooltip>
              </div>
              {exibirTrocaCoordenador && (
                <Buscador
                  onSelect={onSelectCoordenador}
                  data={dataBuscadorCoordenador}
                  multiple={false}
                  label={"Selecione o professor por CPF"}
                  sx={{ width: "100%" }}
                />
              )}
            </Grid>

            <Grid item xs={12} md={12} sx={styles.field}>
              <Typography sx={styles.subtitles}>Bolsista</Typography>
              <div
                style={{
                  display: exibirTrocaAgente ? "none" : "flex",
                  alignItems: "center",
                }}
              >
                <h4
                  style={{
                    fontWeight: "initial",
                    textAlign: "start",
                    margin: 0,
                    marginRight: "8px",
                  }}
                >
                  {agentes?.name} - {agentes?.cpf}
                </h4>
                <Tooltip title="Alterar bolsista">
                  <IconButton
                    aria-label="change"
                    onClick={() => setExibirTrocaAgente(true)}
                  >
                    <img src={alterar} alt="alterar" width="15px" />
                  </IconButton>
                </Tooltip>
              </div>
              {exibirTrocaAgente && (
                <Buscador
                  onSelect={onSelectAgentes}
                  data={dataBuscadorAgente}
                  multiple={false}
                  label={"Selecione o bolsista por CPF"}
                  sx={{ width: "100%" }}
                />
              )}
            </Grid>
          </Grid>

          <Grid container sx={styles.box}>
            <Typography
              sx={{
                fontSize: "20px",
                color: palette.preto,
                fontFamily: "Poppins",
                fontWeight: 500,
                marginBottom: "6px",
                textAlign: "left",
              }}
            >
              Dados da Escola
            </Typography>

            <Grid container columns={2} sx={{ padding: "10px" }} spacing={1}>
              <Grid item xs={2} sm={2} md={1}>
                <Typography sx={styles.subtitles}>
                  Nome da Instituição{" "}
                </Typography>
                <TextField
                  hiddenLabel
                  id="municipio"
                  variant="filled"
                  fullWidth
                  value={unidade}
                  onChange={(e) => setUnidade(e.target.value)}
                  error={Boolean(unidadeError)}
                  helperText={unidadeError}
                  required
                />
              </Grid>
              <Grid item xs={2} sm={2} md={1}>
                <Typography sx={styles.subtitles}>CEP</Typography>

                <InputMask
                  mask="99.999-999"
                  value={CEP}
                  maskChar=" "
                  onBlur={handleCep}
                  onChange={handleCep}
                >
                  {() => (
                    <TextField
                      hiddenLabel
                      id="cep"
                      autoComplete="cep"
                      variant="filled"
                      fullWidth
                      error={Boolean(errorCep?.CEP) || Boolean(CEPError)}
                      helperText={errorCep?.CEP || CEPError}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  )}
                </InputMask>
              </Grid>

              <Grid item xs={2} sm={2} md={1}>
                <Typography sx={styles.subtitles}>Rua</Typography>
                <TextField
                  hiddenLabel
                  id="rua"
                  variant="filled"
                  fullWidth
                  value={rua}
                  onChange={handlerua}
                  error={Boolean(ruaError)}
                  helperText={ruaError}
                  required
                />
              </Grid>

              <Grid item xs={2} sm={2} md={1}>
                <Typography sx={styles.subtitles}>Município</Typography>
                <TextField
                  hiddenLabel
                  id="municipio"
                  variant="filled"
                  fullWidth
                  value={municipio}
                  onChange={handlemunicipio}
                  error={Boolean(municipioError)}
                  helperText={municipioError}
                  required
                />
              </Grid>
              <Grid item xs={2} sm={2} md={1}>
                <Typography sx={styles.subtitles}>Bairro</Typography>
                <TextField
                  hiddenLabel
                  id="bairro"
                  variant="filled"
                  fullWidth
                  value={bairro}
                  onChange={handlebairro}
                  error={Boolean(bairroError)}
                  helperText={bairroError}
                  required
                />
              </Grid>
              <Grid item xs={2} md={2}>
                <Typography sx={styles.subtitles}>Complemento</Typography>
                <TextField
                  hiddenLabel
                  id="municipio"
                  variant="filled"
                  fullWidth
                  value={complemento}
                  onChange={(e) => setComplemento(e.target.value)}
                />
              </Grid>
              <Grid item xs={2} sm={2} md={1}>
                <Typography sx={styles.subtitles}>Número</Typography>
                <TextField
                  hiddenLabel
                  id="numero"
                  variant="filled"
                  fullWidth
                  value={numero}
                  onChange={handlenumero}
                  error={Boolean(numeroError)}
                  helperText={numeroError}
                  required
                />
              </Grid>
            </Grid>
          </Grid>

          <Button
            startIcon={
              loading ? <CircularProgress color="inherit" size={20} /> : null
            }
            variant="contained"
            onClick={handleFinish}
            sx={{
              height: "40px",
              bgcolor: palette.laranjaIntermediario2,
              "&:hover": {
                bgcolor: palette.laranjaSecundario,
              },
              marginTop: "5px",
            }}
          >
            Editar Escola
          </Button>
        </Grid>
        <Snackbar
          open={alertUnauthorized.length > 0}
          autoHideDuration={6000}
          onClose={() => setAlertUnauthorized("")}
        >
          <Alert
            variant="filled"
            onClose={() => setAlertUnauthorized("")}
            severity="error"
            sx={{ width: "100%" }}
          >
            {alertUnauthorized}
          </Alert>
        </Snackbar>
        <Snackbar
          open={alertSuccessful.length > 0}
          autoHideDuration={6000}
          onClose={() => setAlertSuccessful("")}
        >
          <Alert
            variant="filled"
            onClose={() => setAlertSuccessful("")}
            severity="success"
            sx={{ width: "100%" }}
          >
            {alertSuccessful}
          </Alert>
        </Snackbar>
      </Box>
    </Modal>
  );
}

export default ModalEditarEscola;

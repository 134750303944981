import React, { useEffect, useContext} from 'react'
import { Box } from '@mui/system'
import api from '../../../services/api'
import Typography from "@mui/material/Typography";
import { useDispatch, useSelector } from 'react-redux'
import Divider from "@mui/material/Divider";
import TextField from '@mui/material/TextField';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import MailIcon from '@mui/icons-material/Mail';
import BadgeIcon from '@mui/icons-material/Badge';
import FingerprintIcon from '@mui/icons-material/Fingerprint';
import InputMask from "react-input-mask";
import PhoneIcon from '@mui/icons-material/Phone';
import SchoolIcon from '@mui/icons-material/School';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { School } from '@mui/icons-material';
import Button from '@mui/material/Button';
import UploadIcon from '@mui/icons-material/Upload';
import EastIcon from '@mui/icons-material/East';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import DeleteIcon from '@mui/icons-material/Delete';
import Checkbox from '@mui/material/Checkbox';
import { SnackbarProvider, useSnackbar } from 'notistack';
import LabelIcon from '@mui/icons-material/Label';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import prettyBytes from 'pretty-bytes';
import { Route, Redirect, useLocation } from 'react-router-dom'
import history from '../../../services/history'
import DownloadIcon from '@mui/icons-material/Download';
import TableSortLabel from '@mui/material/TableSortLabel';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import SaveIcon from '@mui/icons-material/Save';
import EditIcon from '@mui/icons-material/Edit';
import PersonIcon from '@mui/icons-material/Person';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import iconeOxetechWork from "../../../assets/iconeOxetechWork.png"
import TablePagination from '@mui/material/TablePagination';
import PropTypes from 'prop-types';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { visuallyHidden } from '@mui/utils';
import moment from 'moment';
import 'moment/locale/pt-br'
moment.locale('pt-br')

function Empresa(){
    const [inscritos, setInscritos] = React.useState([]);
    const [rows, setRows] = React.useState([]);
    const { enqueueSnackbar } = useSnackbar();

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('num_inscricao');
    
    const headCells = [
        {
          id: "createdAt",
          numeric: false,
          disablePadding: false,
          label: 'Data de Inscrição',
        },
        {
          id: "razao_social",
          numeric: false,
          disablePadding: false,
          label: 'Nome da Empresa',
        },
        {
            id: 'nome_responsavel',
            numeric: false,
            disablePadding: false,
            label: 'Nome do Responsável',
        },
        {
          id: 'cnpj',
          numeric: false,
          disablePadding: false,
          label: 'CNPJ',
        },
        {
          id: 'num_inscricao',
          numeric: false,
          disablePadding: false,
          label: 'Numero de Inscrição',
        },
        {
          id: 'anexo_comprovante_inscricao_cnpj',
          numeric: false,
          disablePadding: false,
          label: 'Comprovante - CNPJ',
        },
        {
          id: 'status',
          numeric: true,
          align: 'right',
          disablePadding: false,
          label: 'Status',
        },
      ];
      

    function EnhancedTableHead(props) {
        const { order, orderBy, onRequestSort } =
          props;
        const createSortHandler = (property) => (event) => {
          onRequestSort(event, property);
        };
      
        return (
          <TableHead>
            <TableRow>
            <TableCell />
              {headCells.map((headCell) => (
                <TableCell
                  key={headCell.id}
                  align={headCell.numeric ? 'right' : 'left'}
                  padding={headCell.disablePadding ? 'none' : 'normal'}
                  sortDirection={orderBy === headCell.id ? order : false}
                >
                  <TableSortLabel
                    active={orderBy === headCell.id}
                    direction={orderBy === headCell.id ? order : 'asc'}
                    onClick={createSortHandler(headCell.id)}
                  >
                    {headCell.label}
                    {orderBy === headCell.id ? (
                      <Box component="span" sx={visuallyHidden}>
                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                      </Box>
                    ) : null}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
        );
      }

    function descendingComparator(a, b, orderBy) {
        if (orderBy === 'razao_social') {
            if (b['empresa_info']['razao_social'] < a['empresa_info']['razao_social']) {
                return -1;
            }
            if (b['empresa_info']['razao_social'] > a['empresa_info']['razao_social']) {
                return 1;
            }
            return 0;
        } else if(orderBy === 'nome_responsavel'){
            if (b['empresa_info']['nome_responsavel'] < a['empresa_info']['nome_responsavel']) {
                return -1;
            }
            if (b['empresa_info']['nome_responsavel'] > a['empresa_info']['nome_responsavel']) {
                return 1;
            }
            return 0;
        } else if(orderBy === 'cnpj'){
            if (b['empresa_info']['cnpj'] < a['empresa_info']['cnpj']) {
                return -1;
            }
            if (b['empresa_info']['cnpj'] > a['empresa_info']['cnpj']) {
                return 1;
            }
            return 0;
        } else {
            if (b[orderBy] < a[orderBy]) {
                return -1;
            }
            if (b[orderBy] > a[orderBy]) {
                return 1;
            }
            return 0;
        }

      }
      
      function getComparator(order, orderBy) {
        return order === 'desc'
          ? (a, b) => descendingComparator(a, b, orderBy)
          : (a, b) => -descendingComparator(a, b, orderBy);
      }
      
      // This method is created for cross-browser compatibility, if you don't
      // need to support IE11, you can use Array.prototype.sort() directly
      function stableSort(array, comparator) {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
          const order = comparator(a[0], b[0]);
          if (order !== 0) {
            return order;
          }
          return a[1] - b[1];
        });
        return stabilizedThis.map((el) => el[0]);
      }

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
      };
  
    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
  
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
    };

    useEffect(() => {
        api.get(`oxetechwork/inscricao-empresa/all`).then(response => {
            setInscritos(response.data)
            setRows(response.data)
        }).catch((err) => {
            console.log(err)
        })
    }, [])

    function Row(props) {
        const { row } = props;
        const [open, setOpen] = React.useState(false);

        return (
            <React.Fragment>
                <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                    <TableCell>
                        <IconButton
                            aria-label="expand row"
                            size="small"
                            onClick={() => setOpen(!open)}
                        >
                            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>
                    </TableCell>
                    <TableCell component="th" scope="row">
                        {moment(row.createdAt).utc("America/Alagoas").fromNow()}
                    </TableCell>
                    <TableCell component="th" scope="row">
                        {row.empresa_info.razao_social}
                    </TableCell>
                    <TableCell component="th" scope="row">
                        {row.empresa_info.nome_responsavel}
                    </TableCell>
                    <TableCell component="th" scope="row">
                        {row.empresa_info.cnpj.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5")}
                    </TableCell>
                    <TableCell component="th" scope="row">
                        {row.num_inscricao}
                    </TableCell>
                    <TableCell component="th" scope="row">
                        <Button
                            size="small"
                            onClick={() => { getDocs(row) }}
                        >
                            <PictureAsPdfIcon />
                        </Button>
                    </TableCell>
                    <TableCell align="right">
                        <Box sx={{
                            color: row.status === "Validado" ? 'green' : row.status === "Em validação" ? 'orange' : 'red'
                        }}>
                            <Box sx={{ display: 'flex', gap: 1, alignItems: 'center', justifyContent: 'flex-end' }}>
                                <select
                                    onChange={(e) => handleStatus(e.target.value, row)}
                                    value={row.status}
                                >
                                    <option>Em validação</option>
                                    <option>Validado</option>
                                    <option>Rejeitado</option>
                                </select>
                            </Box>
                            <TextareaAutosize
                                onChange={(e) => handleMotivoRejeicao(e, row)}
                                minRows={row?.minRows | 1}
                                placeholder="Motivo de Rejeição"
                                defaultValue={row.motivo_de_rejeicao}
                                disabled={row.status === "Rejeitado" && row.motivo_de_rejeicao !== "" && row.editMode === undefined}
                                style={{
                                    padding: '8px',
                                    width: '100%',
                                    marginTop: '8px',
                                    color: 'black',
                                    display: row.status === 'Rejeitado' ? 'flex' : 'none'
                                }}
                            />
                            <Box
                                sx={{
                                    display: row.status === "Rejeitado" && row.editMode === undefined ? 'flex' : 'none',
                                    justifyContent: 'flex-end'
                                }}
                            >
                                <Button
                                    size="small"
                                    onClick={(e) => handleEditMode(e, row)}
                                >
                                    <EditIcon />
                                </Button>
                            </Box>
                            <Box
                                sx={{
                                    display: row.dataChanged ? 'flex' : 'none',
                                    justifyContent: 'flex-end'
                                }}
                            >
                                <Button
                                    size="small"
                                    onClick={(e) => { handleSave(e, row) }}
                                >
                                    <SaveIcon />
                                </Button>
                            </Box>
                        </Box>
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                        <Collapse in={open} timeout="auto" unmountOnExit>
                            <Box sx={{ margin: 1 }}>
                                <Typography variant="h6" component="div">
                                    Trabalhos realizados pela empresa atualmente
                                </Typography>
                                <Typography sx={{ display: 'flex', alignItems: 'center', gap: 1 }} variant="body2" component="div">
                                    <DoubleArrowIcon /> { row.trabalhos }
                                </Typography>
                            </Box>
                            <Box sx={{ margin: 1 }}>
                                <Typography variant="h6" component="div">
                                    Os objetivos da empresa no programa OxeTech Work
                                </Typography>
                                <Typography sx={{ display: 'flex', alignItems: 'center', gap: 1 }} variant="body2" component="div">
                                    <DoubleArrowIcon /> { row.objetivos }
                                </Typography>
                            </Box>
                            <Box sx={{ margin: 1 }}>
                                <Typography variant="h6" component="div">
                                    Em quais atividades os bolsistas exercerão suas funções? Como ele poderá ajudar a empresa a desenvolver atividades em Ciência, Tecnologia e Inovação?
                                </Typography>
                                <Typography sx={{ display: 'flex', alignItems: 'center', gap: 1 }} variant="body2" component="div">
                                    <DoubleArrowIcon /> { row.atividades_bolsista }
                                </Typography>
                            </Box>
                            <Box sx={{ margin: 1 }}>
                                <Typography variant="h6" component="div">
                                    Em quais áreas os bolsistas irão se aprofundar para o desenvolvimento das atribuições?
                                </Typography>
                                <Typography sx={{ display: 'flex', alignItems: 'center', gap: 1 }} variant="body2" component="div">
                                    <DoubleArrowIcon /> { row.areas_bolsista }
                                </Typography>
                            </Box>
                            <Box sx={{ display: 'flex'}}>
                                <Box sx={{ margin: 1 }}>
                                    <Typography variant="h6" component="div">
                                        Número de funcionarios:
                                    </Typography>
                                    <Typography sx={{ display: 'flex', alignItems: 'center', gap: 1 }} variant="body2" component="div">
                                        <DoubleArrowIcon /> { row.num_funcionarios }
                                    </Typography>
                                </Box>
                                <Box sx={{ margin: 1 }}>
                                    <Typography variant="h6" component="div">
                                        Tempo de atividade:
                                    </Typography>
                                    <Typography sx={{ display: 'flex', alignItems: 'center', gap: 1 }} variant="body2" component="div">
                                        <DoubleArrowIcon /> { row.tempo_de_atividade }
                                    </Typography>
                                </Box>
                            </Box>
                        </Collapse>
                    </TableCell>
                </TableRow>
            </React.Fragment>
        );
    }

    function handleStatus(status, inscrito){
        const newArray = inscritos.map((item) => {
            if (item.empresa_id === inscrito.empresa_id){
                if (item.status !== status){
                    item.dataChanged = true
                    item.status = status
                    item.motivo_de_rejeicao = ''
                    item.minRows = 3
                    item.editMode = true;
                }
            }
            return item
        })
        setInscritos(newArray)
    }

    function handleSave(e, inscrito){
        api.patch(`/oxetechwork/inscricao-empresa/status`, inscrito).then(response => {
            enqueueSnackbar("Inscrição atualizada com sucesso!", {variant: 'success'})
            const newArray = inscritos.map((item) => {
                if (item.empresa_id === inscrito.empresa_id){
                    item.dataChanged = false
                    item.editMode = undefined;
                    item.minRows = 1;
                }
                return item
            })
            setInscritos(newArray)
        }).catch((err) => {
            console.log(err)
        })
    }

    const handleMotivoRejeicao = (e, inscrito) => {
        inscritos.map((item, index) => {
            if (item.empresa_id === inscrito.empresa_id){
                if (item.motivo_de_rejeicao !== e.target.value){
                    item.dataChanged = true
                    item.motivo_de_rejeicao = e.target.value
                }
            }
            return item
        })
    }

    const handleEditMode = (e, inscrito) => {
        const newArray = inscritos.map((item, index) => {
            if (item.empresa_id === inscrito.empresa_id){
                if (item.motivo_de_rejeicao !== e.target.value){
                    item.editMode = true
                    item.dataChanged = true
                    item.minRows = 3
                }
            }
            return item
        })
        setInscritos(newArray)
    }

    function getDocs(inscrito){
        api.get(`/oxetechwork/inscricao-empresa/docs/` + inscrito.anexo_comprovante_inscricao_cnpj, {responseType: 'arraybuffer'}).then(response => {
            if (response.data){
                var blob = new Blob([response.data], {
                    type: 'application/pdf'
                });
                var url = window.URL.createObjectURL(blob)
                window.open(url);
            }else{
                enqueueSnackbar('Falha ao carregar PDF', {variant: 'error'})
            }
        }).catch((err) => {
            console.log(err)
            enqueueSnackbar('Falha ao carregar PDF', {variant: 'error'})
        })
    }

    return (
        <Box sx={{ p: 2 }}>
            {/* <pre>{ JSON.stringify(inscritos, null, 2) }</pre> */}
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <Typography sx={{ fontWeight: 'bold' }} variant="h6">
                    EMPRESAS INSCRITAS
                </Typography>
            </Box>
            <Divider sx={{ my: 1 }} variant="fullWidth" />
            <TableContainer component={Paper}>
                <Table aria-label="collapsible table">
                    <EnhancedTableHead
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={handleRequestSort}
                        rowCount={rows.length}
                    />
                    <TableBody>
                        {
                        stableSort(rows, getComparator(order, orderBy))
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row, i) => (
                            <Row
                                key={i}
                                row={row}
                            />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Paper>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 15]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    sx={{
                        '& .MuiTablePagination-select': { backgroundColor: '#dfdfdf!important', color: 'black !important' },
                        '& .MuiTablePagination-selectIcon': { color: 'black !important' },
                    }}
                />
            </Paper>
        </Box>
    )
}

export default function IntegrationNotistack() {
    return (
        <SnackbarProvider maxSnack={3}>
            <Empresa />
        </SnackbarProvider>
    );
}





import React, { useEffect, useState, useLayoutEffect } from "react";
import { useSelector } from "react-redux";
import {
  Modal,
  Paper,
  TextField,
  Grid,
  Button,
  Box,
  Typography,
} from "@mui/material";
import * as palette from "../../utils/styledVariables";
import api from "../../services/api";
import CardDatas from "./CardDatas";
import DadosEdital from "./DadosEdital";
import DadosVagas from "./DadosVagas";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import ptLocale from "date-fns/locale/pt-BR";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import prettyBytes from "pretty-bytes";
import DeleteIcon from "@mui/icons-material/Delete";
import UploadIcon from "@mui/icons-material/Upload";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { useSnackbar, SnackbarProvider } from "notistack";

const styles = {
  modal: {
    width: "50%",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "#f2f2f2",
    padding: "10px",
    textAlign: "center",
    borderRadius: "1vh",
    justifyContent: "center",
  },

  uploadButton: {
    height: "40px",
    color: palette.roxoMedio,
  },

  paper: {
    width: "100%",
    margin: "5px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  field: { padding: "5px" },
};

function ModalEditarEdital(props) {
  const { enqueueSnackbar } = useSnackbar();

  const [file, setFile] = React.useState(null);
  const [fileId, setFileId] = React.useState(null);

  const defaultButtonText = "Anexar PDF do edital";
  const [buttonText, setButtonText] = React.useState(defaultButtonText);

  const [empresaStart, setEmpresaStart] = useState(null);
  const [empresaEnd, setEmpresaEnd] = useState(null);

  const [alunoStart, setAlunoStart] = useState(null);
  const [alunoEnd, setAlunoEnd] = useState(null);

  const [openModal, setOpenModal] = useState(false);
  const [vagasTotal, setVagasTotal] = useState(null);
  const [vagasOferta, setVagasOferta] = useState(null);
  const [vagasPreenchidas, setVagasPreenchidas] = useState(null);

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [title, setTitle] = useState(null);
  const [editalId, setEditalId] = useState(null);

  const [edital, setEdital] = useState(null);

  const [open, setOpen] = useState(false);

  const handleCloseModal = () => {
    setOpen(false);
  };

  const handleClose = () => {
    props.close();
  };

  const handleTitle = (e) => {
    setTitle(e.target.value);
  };

  const handleStartDate = (value) => {
    setStartDate(value);
  };

  const handleEndDate = (value) => {
    setEndDate(value);
  };

  const handleEmpresaStart = (value) => {
    setEmpresaStart(value);
  };

  const handleEmpresaEnd = (value) => {
    setEmpresaEnd(value);
  };

  const handleAlunoStart = (value) => {
    setAlunoStart(value);
  };

  const handleEdital = (e) => {
    setEdital(e.target.value);
  };

  const handleAlunoEnd = (value) => {
    setAlunoEnd(value);
  };

  const handleTotal = (e) => {
    const {
      target: { value },
    } = e;
    if (value > -1 || value == "") {
      setVagasTotal(value);
    }
  };

  const handleOferta = (e) => {
    const {
      target: { value },
    } = e;

    if (value > -1 || value == "") {
      setVagasOferta(value);
    }
  };

  const handlePreenchidas = (e) => {
    const {
      target: { value },
    } = e;

    if (value > -1 || value == "") {
      setVagasPreenchidas(value);
    }
  };
  function handleFile(event) {
    const filesize_max = 10 * 1000 * 1000;
    if (
      event.target.files[0] &&
      event.target.files[0].size > prettyBytes(filesize_max)
    ) {
      setFile(null);
      // enqueueSnackbar('O pdf é muito grande ( ' + prettyBytes(filesize_max) + ' )', {variant: 'error'})
    } else {
      if (event.target.files[0]) {
        setFile(event.target.files[0]);
        setButtonText(
          event.target.files[0].name +
            " - (" +
            prettyBytes(event.target.files[0].size) +
            " / " +
            prettyBytes(filesize_max) +
            ")"
        );
      } else {
        setFile(null);
        setButtonText(defaultButtonText);
      }
    }
  }

  function handleRemoveFile() {
    setButtonText(defaultButtonText);
    setFile(null);
  }

  function uploadDoc() {
    const formData = new FormData();
    formData.append("file", file);

    api
      .post(`oxetechwork/edital/docs`, formData)
      .then((response) => {
        if (response.data && response.data.data && response.data.data.uuid) {
          setFileId(response.data.data.uuid);
        }
      })
      .catch((err) => {});
  }

  const handleFinish = () => {
    if (file !== fileId) {
      // se um novo arquivo foi selecionado
      const formData = new FormData();
      formData.append("file", file);

      api
        .post(`oxetechwork/edital/docs`, formData)
        .then((response) => {
          if (response.data && response.data.data && response.data.data.uuid) {
            setFileId(response.data.data.uuid);
            const data = {
              title: title,
              qt_vagas: vagasTotal,
              qt_vagas_em_oferta: vagasOferta,
              qt_vagas_preenchidas: vagasPreenchidas,
              dt_inicio_edital: startDate,
              dt_encerramento_edital: endDate,
              dt_inicio_inscricao_empresa: empresaStart,
              dt_fim_inscricao_empresa: empresaEnd,
              dt_inicio_inscricao_aluno: alunoStart,
              dt_fim_inscricao_aluno: alunoEnd,
              file_id: response.data.data.uuid,
            };

            api.put(`/oxetechwork/edital?id=${editalId}`, data);
            enqueueSnackbar("Edital foi atualizado com sucesso!", {
              variant: "success",
            });
            setTimeout(() => {
              handleClose();
            }, 1000);
            // window.location.reload();
          }
        })
        .catch((err) => {
          alert("Falha ao carregar pdf");
        });
    }
    if (file) {
      // se nenhum arquivo foi selecionado
      const data = {
        title: title,
        qt_vagas: vagasTotal,
        qt_vagas_em_oferta: vagasOferta,
        qt_vagas_preenchidas: vagasPreenchidas,
        dt_inicio_edital: startDate,
        dt_encerramento_edital: endDate,
        dt_inicio_inscricao_empresa: empresaStart,
        dt_fim_inscricao_empresa: empresaEnd,
        dt_inicio_inscricao_aluno: alunoStart,
        dt_fim_inscricao_aluno: alunoEnd,
        file_id: fileId, // enviar o ID do arquivo original
      };

      api.put(`/oxetechwork/edital?id=${editalId}`, data);
      enqueueSnackbar("Edital foi atualizado com sucesso!", {
        variant: "success",
      });
      handleClose();
      //   window.location.reload();
    }
  };

  useEffect(() => {
    const updateState = () => {
      setTitle(props.edital.title);
      setVagasTotal(props.edital.qt_vagas);
      setVagasOferta(props.edital.qt_vagas_em_oferta);
      setVagasPreenchidas(props.edital.qt_vagas_preenchidas);
      setStartDate(props.edital.dt_inicio_edital);
      setEndDate(props.edital.dt_encerramento_edital);
      setEmpresaStart(props.edital.dt_inicio_inscricao_empresa);
      setEmpresaEnd(props.edital.dt_fim_inscricao_empresa);
      setAlunoStart(props.edital.dt_inicio_inscricao_aluno);
      setAlunoEnd(props.edital.dt_fim_inscricao_aluno);
      setEditalId(props.edital.id);
      setFileId(props.edital.file_id);
    };
    updateState();

    if (props.edital.id) {
      async function fetchPdf() {
        try {
          const response = await api.get(
            `/oxetechwork/edital/docs/${props.edital.id}`,
            {
              responseType: "arraybuffer",
            }
          );
          const blob = new Blob([response.data], { type: "application/pdf" });
          //   const fileData = await readBlobData(blob);
          var url = window.URL.createObjectURL(blob);

          setFile(url);
          setButtonText(`arquivo: ${props.edital.title}`);
        } catch (error) {
          console.log(error);
        }
      }

      fetchPdf();
    }
  }, [props.edital]);

  return (
    <Modal open={props.open}>
      <ClickAwayListener onClickAway={handleClose}>
        <Box>
          <IconButton
            size="large"
            sx={{ display: "flex", float: "right" }}
            component="label"
            onClick={handleClose}
          >
            <CloseIcon
              fontSize="large"
              sx={{ color: palette.profileWhiteShade }}
            />
          </IconButton>

          <Grid sx={styles.modal} container direction="row">
            <Grid container item xs={12} sm={12} md={12} lg={12}>
              <Paper
                sx={[
                  {
                    backgroundColor: palette.laranjaOXLTransparente,
                    height: "50px",
                  },
                  styles.paper,
                ]}
              >
                <Typography
                  sx={{
                    fontSize: "24px",
                    color: palette.preto,
                    fontFamily: "Poppins",
                    fontWeight: 500,
                  }}
                >
                  Edição do Edital
                </Typography>
              </Paper>
            </Grid>
            <DadosEdital
              title={"Dados gerais"}
              editalTitle={title}
              startDate={startDate}
              minDate={"01/01/2001"}
              endDate={endDate}
              handleStart={handleStartDate}
              handleEnd={handleEndDate}
              handleTitle={handleTitle}
            />

            <CardDatas
              title={"Empresas"}
              startDate={empresaStart}
              minDate={"01/01/2001"}
              endDate={empresaEnd}
              handleStart={handleEmpresaStart}
              handleEnd={handleEmpresaEnd}
            />
            <CardDatas
              title={"Candidatos"}
              startDate={alunoStart}
              minDate={"01/01/2001"}
              endDate={alunoEnd}
              handleStart={handleAlunoStart}
              handleEnd={handleAlunoEnd}
            />
            <Grid container item xs={12} sm={12} md={12} lg={12}>
              <DadosVagas
                title={"Vagas"}
                total={vagasTotal}
                oferta={vagasOferta}
                preenchidas={vagasPreenchidas}
                handleTotal={handleTotal}
                handleOferta={handleOferta}
                handlePreenchidas={handlePreenchidas}
              />
              <Grid item container xs={12} sm={12} md={6} lg={6}>
                <Paper sx={styles.paper}>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <input
                      accept="application/pdf"
                      style={{ display: "none" }}
                      id="raised-button-file"
                      type="file"
                      onChange={handleFile}
                    />

                    <label htmlFor="raised-button-file">
                      <Button
                        disabled={
                          buttonText !== defaultButtonText ? true : false
                        }
                        sx={{ m: 2, py: 2 }}
                        variant="outlined"
                        component="span"
                      >
                        <UploadIcon sx={{ mr: 1 }} />
                        {buttonText}
                      </Button>
                    </label>
                    <Button
                      sx={{
                        py: 2,
                        display:
                          buttonText === defaultButtonText && !file
                            ? "none"
                            : "flex",
                      }}
                      variant="text"
                      onClick={handleRemoveFile}
                    >
                      <DeleteIcon
                        sx={{
                          color: "red",
                          display:
                            buttonText === defaultButtonText && !file
                              ? "none"
                              : "flex",
                        }}
                      />
                    </Button>
                  </Box>
                </Paper>
              </Grid>
            </Grid>
            <Grid>
              <Button
                onClick={handleFinish}
                variant="contained"
                sx={{
                  height: "40px",
                  bgcolor: palette.laranjaSecundario,
                  "&:hover": {
                    bgcolor: palette.laranjaIntermediario2,
                  },
                  marginTop: "5px",
                  marginLeft: "15px",
                }}
              >
                Editar edital
              </Button>
            </Grid>
          </Grid>
        </Box>
      </ClickAwayListener>
    </Modal>
  );
}

export default ModalEditarEdital;

import { Box, Fab, Tab, Tabs, Tooltip } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { DataGrid } from "@mui/x-data-grid";
import { addDays, parseISO } from "date-fns";
import React, { useState, useEffect } from "react";
import helperTexts from "../../../utils/helperTexts";
import * as palette from "../../../utils/styledVariables";
import Matricula from "../Modais/MatricularTodos";
import NaoSelecionados from "../Modais/NaoSelecionados";

const style = {
  width: "100%",
  padding: "16px",
  justifyContent: "center",
  alignItems: "center",
  height: "80vh",
  fontSize: "26px",
  color: palette.preto,
  fontStyle: "normal",
  fontWeight: 450,
  background: "rgba(212, 109, 13, 0.1)",
  textAlign: "left",
  borderRadius: "15px",
  marginBottom: "10px",
};

function TableCard({ tables, turma, permissao, turmaIniciada }) {
  const listaNaoVazia = tables[1]?.rows.length > 0;
  const [chosenTable, setChosenTable] = useState(0);
  const [matriculaOn, setMatriculaOn] = useState(false);
  const [naoSelecionadosOn, setNaoSelecionadosOn] = useState(false);
  const today = new Date();
  const dataEncerramentoComHorario = addDays(
    parseISO(turma.data_encerramento_inscricoes),
    1
  );
  const posPrimeiroDiaDeAula = today > addDays(parseISO(turma.data_inicio), 1);
  const posSetimoDiaDeAula = today > addDays(parseISO(turma.data_inicio), 7);
  const [loading, setLoading] = useState(true);
  
  useEffect(() => {
    const timeout = setTimeout(() => {
      setLoading(false);
    }, 2000);

    return () => clearTimeout(timeout);
  }, []);
  return (
    <Box sx={style}>
      <Box sx={{ width: "100%" }}>
        <Tabs
          centered
          value={chosenTable}
          onChange={(event, newValue) => setChosenTable(newValue)}
          sx={{
            marginBottom: 1,
            "& .MuiButtonBase-root.Mui-selected": {
              color: palette.laranjaOxeLab,
            },
            "& .MuiTabs-indicator": {
              background: palette.laranjaOxeLab,
            },
          }}
        >
          {tables.map((table, index) => (
            <Tab value={index} label={table.name} key={index} />
          ))}
        </Tabs>
      </Box>
      {loading && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
          }}
        >
          <CircularProgress
            size={65}
            sx={{ color: palette.laranjaIntermediario }}
          />
        </Box>
      )}

      <DataGrid
        rows={tables[chosenTable].rows}
        columns={tables[chosenTable].columns}
        // components={{ Toolbar: GridToolbar }}
        // columnVisibilityModel={{ id: false }}
        sx={{
          height: "90%",
          background: palette.profileWhiteShadeTransp,
          borderRadius: "15px",
          "& .MuiDataGrid-toolbarContainer": {
            "& .MuiButton-text": {
              color: palette.laranjaOxeLab,
            },
          },
          "& .MuiDataGrid-columnHeaderTitle": {
            color: palette.preto,
          },
          "& .MuiDataGrid-cellContent": {
            color: palette.preto,
          },
        }}
        localeText={helperTexts.dataGridToolbarText}
      />
      {
      // !posPrimeiroDiaDeAula &&
        listaNaoVazia &&
        !turmaIniciada &&
        chosenTable === 1 &&
        permissao && (
          <>
            <Box
              sx={{
                display: "flex",
                position: "fixed",
                right: "20px",
                bottom: "30px",
                zIndex: 9999,
              }}
            >
              <Fab
                onClick={() => setMatriculaOn(true)}
                variant="extended"
                sx={{
                  background: "rgba(212, 109, 13, 1)",
                  color: "white",
                  "&:hover": { background: "rgba(212, 109, 13, 0.7)" },
                }}
              >
                Matricular alunos
              </Fab>
            </Box>
          </>
        )}
      {
      // posPrimeiroDiaDeAula &&
      permissao &&
      listaNaoVazia &&
      turmaIniciada &&
      chosenTable === 1 && (
        <Box
          sx={{
            display: "flex",
            position: "fixed",
            right: "20px",
            bottom: "30px",
            zIndex: 9999,
          }}
        >
          <Fab
            onClick={() => setNaoSelecionadosOn(true)}
            variant="extended"
            sx={{
              background: "rgba(212, 109, 13, 1)",
              color: "white",
              "&:hover": { background: "rgba(212, 109, 13, 0.7)" },
            }}
          >
            Informar não selecionados
          </Fab>
        </Box>
      )}
      <Matricula
        open={matriculaOn}
        handleClose={() => setMatriculaOn(false)}
        selecionados={tables[1]?.rows}
        idTurma={turma.id}
      />
      <NaoSelecionados
        open={naoSelecionadosOn}
        handleClose={() => setNaoSelecionadosOn(false)}
        idTurma={turma.id}
      />
    </Box>
  );
}

export default TableCard;

import React, { useEffect, useState } from "react";
import {
  Modal,
  Paper,
  TextField,
  Grid,
  Button,
  Box,
  Typography,
  useMediaQuery,
} from "@mui/material";
import * as palette from "../../../utils/styledVariables";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import CardDesempenho from "../CardDesempenho";

const styles = {
  modal: {
    width: "30%",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    padding: "10px",
    textAlign: "center",
    borderRadius: "2vh",
    justifyContent: "center",
  },
  paper: {
    width: "100%",
    marginBottom: "5px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
};

export const ModalDesempenhoAluno = ({ open, close, performance }) => {
  const isSmallScreen = useMediaQuery("(max-width:600px)");
  const handleClose = () => close();

  const [progresso, setProgresso] = useState("");
  const [notas, setNotas] = useState([]);
  const [mediaParcial, setMediaParcial] = useState("");

  useEffect(() => {
    if (performance) {
      setProgresso(performance.progresso);
      setNotas(performance.notas);
      setMediaParcial(performance.media_parcial);
    }
  }, [performance]);
  
  return (
    <Modal open={open} close={close}>
      <ClickAwayListener onClickAway={handleClose}>
        <Box>
          <IconButton
            size="large"
            sx={{ display: "flex", float: "right" }}
            component="label"
            onClick={handleClose}
          >
            <CloseIcon
              fontSize="large"
              sx={{ color: palette.profileWhiteShade }}
            />
          </IconButton>

          <Grid sx={styles.modal} container direction="row">
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <CardDesempenho 
                mediaParcial={mediaParcial}
                notas={notas}
                progresso={progresso}
              />
            </Grid>
          </Grid>
        </Box>
      </ClickAwayListener>
    </Modal>
  );
};

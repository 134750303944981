import React, { useEffect, useContext} from 'react'
import { Box } from '@mui/system'
import api from '../../../services/api'
import Typography from "@mui/material/Typography";
import { useDispatch, useSelector } from 'react-redux'
import Divider from "@mui/material/Divider";
import TextField from '@mui/material/TextField';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import MailIcon from '@mui/icons-material/Mail';
import BadgeIcon from '@mui/icons-material/Badge';
import FingerprintIcon from '@mui/icons-material/Fingerprint';
import InputMask from "react-input-mask";
import PhoneIcon from '@mui/icons-material/Phone';
import SchoolIcon from '@mui/icons-material/School';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { School } from '@mui/icons-material';
import Button from '@mui/material/Button';
import UploadIcon from '@mui/icons-material/Upload';
import DeleteIcon from '@mui/icons-material/Delete';
import { SnackbarProvider, useSnackbar } from 'notistack';
import LabelIcon from '@mui/icons-material/Label';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import prettyBytes from 'pretty-bytes';
import { Route, Redirect, useLocation } from 'react-router-dom'
import history from '../../../services/history'
import DownloadIcon from '@mui/icons-material/Download';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import SaveIcon from '@mui/icons-material/Save';
import PersonIcon from '@mui/icons-material/Person';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import iconeOxetechWork from "../../../assets/iconeOxetechWork.png"
import moment from 'moment';
import 'moment/locale/pt-br'
moment.locale('pt-br')

function Aluno(){
    const [inscritos, setInscritos] = React.useState([]);
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        api.get(`/oxetechwork/inscricao-aluno/all`).then(response => {
            setInscritos(response.data)
        }).catch((err) => {
            console.log(err)
        })
    }, [])

    function handleStatus(status, inscrito){
        const newArray = inscritos.map((item) => {
            if (item.aluno_id === inscrito.aluno_id){
                if (item.status !== status){
                    item.dataChanged = true
                    item.status = status
                }
            }
            return item
        })
        setInscritos(newArray)
    }

    function handleSave(e, inscrito){
        api.patch(`/oxetechwork/inscricao-aluno/status`, inscrito).then(response => {
            enqueueSnackbar("Inscrição atualizada com sucesso!", {variant: 'success'})
            const newArray = inscritos.map((item) => {
                if (item.aluno_id === inscrito.aluno_id){
                    item.dataChanged = false
                }
                return item
            })
            setInscritos(newArray)
        }).catch((err) => {
            console.log(err)
        })
    }

    const handleMotivoRejeicao = (e, inscrito) => {
        const newArray = inscritos.map((item, index) => {
            if (item.aluno_id === inscrito.aluno_id){
                if (item.motivo_de_rejeicao !== e.target.value){
                    item.dataChanged = true
                    item.motivo_de_rejeicao = e.target.value
                }
            }
            return item
        })
        setInscritos(newArray)
    }

    function getDocs(inscrito){
        api.get(`/oxetechwork/inscricao-aluno/docs/` + inscrito.file_id, {responseType: 'arraybuffer'}).then(response => {
            if (response.data){
                var blob = new Blob([response.data], {
                    type: 'application/pdf'
                });
                var url = window.URL.createObjectURL(blob)
                window.open(url);
            }else{
                enqueueSnackbar('Falha ao carregar PDF', {variant: 'error'})
            }
        }).catch((err) => {
            console.log(err)
            enqueueSnackbar('Falha ao carregar PDF', {variant: 'error'})
        })
    }


    return (
        <Box sx={{ p: 2 }}>
            {/* <pre>{ JSON.stringify(inscritos, null, 2) }</pre> */}
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <Typography sx={{ fontWeight: 'bold' }} variant="h6">
                    ALUNOS INSCRITOS
                </Typography>
            </Box>
            <Divider sx={{ my: 1 }} variant="fullWidth" />
            <Box sx={{ px: 2, display: 'flex', gap: 2, flexWrap: 'wrap', justifyContent: 'center' }}>
                {
                    inscritos.map((inscrito, index) => {
                        return (
                            <Card key={index} sx={{ maxWidth: 400 }}>
                                <CardMedia
                                    component="img"
                                    alt="iconeOxetechWork"
                                    width={400}
                                    height={30}
                                    image={iconeOxetechWork}
                                />
                                <CardContent sx={{ py: 1, px: 2 }}>
                                    <Box sx={{ display: 'flex', gap: 1, alignItems: 'center', justifyContent: 'space-between' }}>
                                        <Typography variant="caption">Nº de Inscrição: </Typography>
                                        <Typography sx={{ fontWeight: 'bold' }} variant="h6">#{inscrito.num_inscricao}</Typography>
                                    </Box>
                                    <Box sx={{ display: 'flex', gap: 1, alignItems: 'center', justifyContent: 'space-between' }}>
                                        <Typography variant="caption">Data de inscrição: </Typography>
                                        <Typography sx={{ fontWeight: 'bold' }} variant="body2">{moment(inscrito.createdAt).utc("America/Alagoas").format("lll")}</Typography>
                                    </Box>
                                    <Box sx={{ display: 'flex', gap: 1, alignItems: 'center', justifyContent: 'space-between' }}>
                                        <Typography variant="caption">Status: </Typography>
                                        <select
                                            onChange={(e) => handleStatus(e.target.value, inscrito)}
                                            value={inscrito.status}
                                        >
                                            <option>Em validação</option>
                                            <option>Validado</option>
                                            <option>Rejeitado</option>
                                        </select>
                                    </Box>
                                    <TextareaAutosize
                                        onChange={(e) => handleMotivoRejeicao(e, inscrito)}
                                        minRows={3}
                                        placeholder="Motivo de Rejeição"
                                        defaultValue={inscrito.motivo_de_rejeicao}
                                        style={{
                                            padding: '8px',
                                            width: '100%',
                                            marginTop: '8px',
                                            display: inscrito.status === 'Rejeitado' ? 'flex' : 'none'
                                        }}
                                    />
                                    <Box
                                        sx={{
                                            display: inscrito.dataChanged ? 'flex' : 'none',
                                            justifyContent: 'flex-end'
                                        }}
                                    >
                                        <Button
                                            size="small"
                                            onClick={(e) => { handleSave(e, inscrito) }}
                                        >
                                            <SaveIcon />
                                        </Button>
                                    </Box>
                                    <Divider sx={{ my: 2 }} variant="fullWidth" />
                                    <Box sx={{ height: 100 }}>
                                        <Box sx={{ display: 'flex', gap: 1, alignItems: 'center', justifyContent: 'space-between' }}>
                                            <Typography variant="caption">RG: </Typography>
                                            <Typography sx={{ fontWeight: 'bold' }} variant="body2">{inscrito.rg}</Typography>
                                        </Box>
                                        <Box sx={{ display: 'flex', gap: 1, alignItems: 'center', justifyContent: 'space-between' }}>
                                            <Typography variant="caption">Nível de Escolaridade: </Typography>
                                            <Typography sx={{ fontWeight: 'bold' }} variant="body2">{inscrito.nivel_escolaridade}</Typography>
                                        </Box>
                                        {
                                            inscrito.area !== '' ?
                                                <>
                                                    <Box sx={{ display: 'flex', gap: 1, alignItems: 'center', justifyContent: 'space-between' }}>
                                                        <Typography noWrap variant="caption">Área de atuação: </Typography>
                                                        <Typography sx={{ fontWeight: 'bold' }} noWrap variant="body2">{inscrito.area}</Typography>
                                                    </Box>
                                                </>
                                                : <></>
                                        }
                                        <Box sx={{ display: 'flex', gap: 1, alignItems: 'center', justifyContent: 'space-between' }}>
                                            <Typography variant="caption">Experiência TI: </Typography>
                                            <Typography sx={{ fontWeight: 'bold' }} variant="body2">{inscrito.experiencia_ti}</Typography>
                                        </Box>
                                        <Box sx={{ display: 'flex', gap: 1, alignItems: 'center', justifyContent: 'space-between' }}>
                                            <Typography variant="caption">Experiência Dev: </Typography>
                                            <Typography sx={{ fontWeight: 'bold' }} variant="body2">{inscrito.experiencia_dev}</Typography>
                                        </Box>
                                    </Box>
                                    <Divider sx={{ mt: 2 }} variant="fullWidth" />
                                </CardContent>
                                <CardActions sx={{ display: 'flex', p: 1, justifyContent: 'center' }}>
                                    <Button
                                        size="small"
                                        onClick={() => { getDocs(inscrito) }}
                                    >
                                        <PictureAsPdfIcon />
                                        Documentos do Aluno
                                    </Button>
                                    <Button
                                        size="small"
                                        onClick={() => { history.push('/aluno/' + inscrito.aluno_id) }}
                                    >
                                        <PersonIcon />
                                        Perfil do Aluno
                                    </Button>
                                </CardActions>
                            </Card>
                        )
                    })
                }
            </Box>
        </Box>
    )
}

export default function IntegrationNotistack() {
    return (
      <SnackbarProvider maxSnack={3}>
        <Aluno />
      </SnackbarProvider>
    );
  }
import styled from "styled-components";
import * as palette from "../../utils/styledVariables.js"

export const Container = styled.div`
  height: 33%;

  color: ${palette.profileWhiteShade};
  padding: 5% 16px 5% 16px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  text-align: flex-start;
  /* word-break: break-all; */

  background: ${palette.laranjaIntermediario2};
  border-radius: 1vh 1vh 0vh 0vh;
`;

export const Title = styled.span`

font-family: "Roboto";
font-style: normal;
font-weight: 500;
font-size: 22pt;
line-height: 24pt;

overflow: hidden;
text-overflow: ellipsis;
display: -webkit-box;
-webkit-line-clamp: 1;
        line-clamp: 1; 
-webkit-box-orient: vertical;`;
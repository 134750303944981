import React, { useState } from "react";
import {
  Modal,
  Typography,
  styled,
  Box,
  Button,
  TextField,
  SnackbarContent,
  Snackbar,
  IconButton,
} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import ReplyIcon from "@mui/icons-material/Reply";
import * as palette from "../../utils/styledVariables";
import CloseIcon from "@mui/icons-material/Close";

const SimpleButton = styled((props) => <Button {...props} />)(() => ({
  background: palette.roxoAzulado,
  color: palette.profileWhiteShade,
  margin: "10px 5px 0 5px",
  height: "35px",
}));

const styles = {
  main: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    minWidth: "60vw",
    bgcolor: "background.paper",
    padding: "20px",
    textAlign: "center",
    borderRadius: "1vh",
    justifyContent: "center",
  },

  text: { mb: "20px" },

  title: {
    fontSize: "22px",
    color: palette.preto,
    fontFamily: "Poppins",
    fontWeight: 500,
    bottom: "5px",
  },

  subtitle: {
    fontSize: "14px",
    color: palette.preto,
    fontFamily: "Poppins",
    fontWeight: 300,
  },

  textfield: { width: "100%" },

  buttonbox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
};

function ModalRejeicao(props) {
  const [error, setError] = useState(null);
  const [openError, setOpenError] = useState(false);
  //const [openSucess, setOpenSucess] = useState(false);
  const [motivo, setMotivo] = useState(null);

  const handleMotivo = (e) => {
    {
      const {
        target: { value },
      } = e;
      setMotivo(value);
      if (value.length < 1) {
        setError("Preencha esse campo.");
      } else {
        setError(false);
      }
    }
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={() => {
          setOpenError(false);
        }}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  const handleFinish = () => {
    if (error == false) {
      props.handleFinish(motivo);
      setMotivo(null);
      return;
    }
    setOpenError(true);
  };
  return (
    <Modal open={props.open}>
      <Box>
        <Box sx={styles.main}>
          <Box sx={styles.text}>
            <Typography sx={styles.title}>{props.title}</Typography>
            <Typography sx={styles.subtitle}>{props.subtitle}</Typography>
          </Box>

          <TextField
            multiline
            maxRows={10}
            value={motivo}
            onChange={handleMotivo}
            required
            error={error}
            helperText={error}
            sx={styles.textfield}
          />

          <Box sx={styles.buttonbox}>
            <SimpleButton onClick={handleFinish} startIcon={<SaveIcon />}>
              CONFIRMAR
            </SimpleButton>
            <SimpleButton
              onClick={props.handleRevert}
              startIcon={<ReplyIcon />}
            >
              VOLTAR
            </SimpleButton>
          </Box>
        </Box>
        <Snackbar open={openError} autoHideDuration={60} action={action}>
          <SnackbarContent
            style={{
              backgroundColor: "#D10000",
            }}
            message="Erro! É obrigatório informar o motivo da rejeição."
            action={action}
          />
        </Snackbar>

      </Box>
    </Modal>
  );
}

export default ModalRejeicao;

import AddIcon from "@mui/icons-material/Add";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import {
  Box,
  Button,
  createTheme,
  IconButton,
  SnackbarContent,
  Tabs,
  ThemeProvider,
} from "@mui/material";
import Tab from "@mui/material/Tab";
import { SnackbarProvider } from "notistack";
import React, { useEffect, useState } from "react";
import * as palette from "../../../utils/styledVariables";

import CloseIcon from "@mui/icons-material/Close";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import TableCard from "../../../components/DashUserAdm/TableCard";
import api from "../../../services/api";
import formatDate from "../../../utils/formartDate";
import Options from "../../../utils/options";
import ModalCadastroEscola from "./Modais/ModalCadastroEscola";
import ModalEditarEscola from "./Modais/ModalEditarEscola";

const styles = {
  cadastro: {
    height: "30px",
    margin: "2px",
    float: "right",
    bgcolor: palette.laranjaIntermediario2,
    "&:hover": {
      bgcolor: palette.laranjaSecundario,
    },
  },
  editalItem: {
    display: "flex",
    alignItems: "center",
    minHeight: "50px",
    "&:hover button": {
      display: "inline-block",
    },
  },
  titleContainer: {
    display: "block",
    whiteSpace: "normal",
  },
  editButton: {
    color: palette.laranjaIntermediario2,
    marginLeft: "8px",
    display: "block",
  },
};

function DashboardOXD() {
  const [editId, setEditId] = useState(null);
  const [editLabId, setEditLabId] = useState(null);
  const [escolas, setEscolas] = useState([]);
  const [turmas, setTurmas] = useState([]);
  const [chosenTable, setChosenTable] = useState(0);
  const [alertUnauthorized, setAlertUnauthorized] = useState("");
  const [turmaId, setTurmaId] = useState(null);
  const [openModalCadastroEscola, setOpenModalCadastroEscola] = useState(false);
  const [openModalEditarEscola, setOpenModalEditarEscola] = useState(false);
  const [escolaData, setEscolaData] = useState([]);
  const [openModalCadastroTurma, setOpenModalCadastroTurma] = useState(false);
  const [bolsistas, setBolsistas] = useState([]);
  const [loading, setLoading] = useState(true);
  const [atualiza, setAtualiza] = useState(false);
  const [openSucess, setOpenSucess] = useState(false);

  const theme = createTheme({
    palette: {
      secondary: {
        main: palette.laranjaIntermediario2,
      },
    },
  });

  const formatEscolasData = (escolasData) => {
    const formated = [];
    escolasData.forEach((escola) => {
      const data = {
        id: escola.id,
        unidade: escola.nome,
        cep: escola.cep,
        professor: escola.professor.name,
        bolsista: escola.BolsistaOxetechEdu.Aluno.name,
        bolsista_id: escola.bolsista_id,
      };

      formated.push(data);
    });

    setEscolas(formated);
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const escolasResponse = await api.get("/oxetechedu/escola/");
        formatEscolasData(escolasResponse.data);

        const turmasResponse = await api.get("/oxetechedu/turmas");
        setTurmas(turmasResponse.data);

        const bolsistasResponse = await api.get("/oxetechedu/bolsistas/");
        setBolsistas(bolsistasResponse.data);
      } catch (error) {
        console.error("Erro ao carregar dados", error);
      }
      setLoading(false);
    };

    fetchData();
    setAtualiza(false);
  }, [atualiza]);

  const handleEditClick = (id) => {
    getEscolaData(id);
    setOpenModalEditarEscola(true);
  };

  const getEscolaData = (id) => {
    api.get(`/oxetechedu/escola/${id}/escola`).then((res) => {
      setEscolaData(res.data);
    });
  };
  const escolaHeader = [
    { field: "unidade", headerName: "Unidade", width: 250 },
    {
      field: "editar_escola",
      headerName: "Editar escola",
      width: 150,
      renderCell: (params) => {
        return (
          <>
            <Button
              onClick={() => handleEditClick(params.id)}
              sx={{ color: palette.laranjaIntermediario2 }}
            >
              <ModeEditIcon />
            </Button>
          </>
        );
      },
    },
    { field: "bolsista", headerName: "Bolsista", width: 150 },
    { field: "professor", headerName: "Professor", width: 150 },
    { field: "cep", headerName: "CEP", width: 150 },
  ];

  const bolsistaHeader = [
    { field: "bolsista", headerName: "Bolsista", width: 150 },
    { field: "unidade", headerName: "Unidade", width: 250 },
    { field: "ch_semanal", headerName: "Carga Horária Semanal", width: 200 },
    {
      field: "carga_horaria",
      headerName: "Carga Horária",
      width: 200,
      renderCell: (params) => {
        const cargaHorariaStatus =
          Options.cargasHorariasStatuses[params.value] || "Desconhecido";
        return <div>{cargaHorariaStatus}</div>;
      },
    },
  ];

  const bolsistaRows = escolas?.map((escola, index) => {
    const bolsista = bolsistas.find(
      (bolsista) => bolsista.bolsista_id === escola.bolsista_id
    );
    let carga_horaria = "0";
    let ch_semanal = 0;
    if (bolsista) {
      // Verifica se a propriedade 'cumpriu' existe no objeto 'bolsista' antes de acessá-la
      if (bolsista.cumpriu !== undefined && bolsista.cumpriu !== null) {
        carga_horaria = bolsista.cumpriu ? "0" : "1";
        ch_semanal = bolsista.horas_trabalhadas;
      } else {
        carga_horaria = "2";
        ch_semanal = bolsista.horas_trabalhadas;
      }
    }

    return {
      id: index,
      unidade: escola.unidade,
      bolsista: escola.bolsista,
      ch_semanal: ch_semanal,
      carga_horaria: carga_horaria,
    };
  });

  const escolaRows = escolas?.map((escola) => {
    return {
      id: escola.id,
      unidade: escola.unidade,
      cep: escola.cep,
      professor: escola.professor,
      bolsista: escola.bolsista,
    };
  });
  const turmasRows = turmas?.map((turma) => {
    return {
      id: turma.id,
      titulo: turma.titulo,
      unidade: turma.Escola.nome,
      municipio: turma.Escola.bairro,
      status:
        Options.turmasStatuses[
          turma.em_selecao
            ? 1
            : turma.encerramento_inscricoes > new Date()
            ? 2
            : 0
        ],
      vagas_disponiveis: turma.qtd_vagas_total - turma.qtd_vagas_preenchidas,
      vagas_preenchidas: turma.qtd_vagas_preenchidas,
      dia_da_aula: formatDate(turma.dia_da_aula),
    };
  });

  const turmasHeader = [
    { field: "id", headerName: "ID", width: 100 },
    { field: "titulo", headerName: "Título", width: 400 },
    { field: "unidade", headerName: "Unidade", width: 150 },
    { field: "municipio", headerName: "Municipio", width: 150 },
    { field: "status", headerName: "Status", width: 100 },
    { field: "data_inicio", headerName: "Data de início", width: 100 },
    { field: "vagas_disponiveis", headerName: "Vagas Disponíveis", width: 150 },
    { field: "vagas_preenchidas", headerName: "Vagas Preenchidas", width: 150 },
    { field: "dia_da_aula", headerName: "Data da aula", width: 150 },
  ];

  const cadastrarEscolaButton = (
    <div style={{ float: "right" }}>
      <Button
        onClick={() => {
          setOpenModalCadastroEscola(true);
        }}
        variant="contained"
        startIcon={<AddIcon />}
        sx={styles.cadastro}
      >
        Cadastrar Escola
      </Button>
    </div>
  );

  const tables = [
    {
      name: "Escolas cadastradas",
      columns: escolaHeader,
      rows: escolaRows,
      buttonsBar: cadastrarEscolaButton,
    },
    {
      name: "Turmas cadastradas",
      columns: turmasHeader,
      rows: turmasRows,
    },
    {
      name: "Bolsistas cadastrados",
      columns: bolsistaHeader,
      rows: bolsistaRows,
    },
  ];

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={() => {
          setOpenSucess(false);
        }}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <Box>
      <ThemeProvider theme={theme}>
        <Box>
          <Tabs
            centered
            value={chosenTable}
            onChange={(event, newValue) => setChosenTable(newValue)}
            sx={{ marginBottom: 1 }}
            indicatorColor="secondary"
            textColor="secondary"
          >
            {tables.map((table, index) => (
              <Tab value={index} label={table.name} key={index} />
            ))}
          </Tabs>
        </Box>
      </ThemeProvider>

      <TableCard props={tables[chosenTable]} loading={loading} />
      <SnackbarProvider maxSnack={3}>
        <ModalCadastroEscola
          open={openModalCadastroEscola}
          isEdit={editId}
          close={() => {
            setOpenModalCadastroEscola(false);
            setAtualiza(true);
          }}
        />
      </SnackbarProvider>
      <SnackbarProvider maxSnack={3}>
        <ModalEditarEscola
          open={openModalEditarEscola}
          isEdit={editLabId}
          escolaData={escolaData}
          close={() => {
            setOpenModalEditarEscola(false);
            setAtualiza(true);
          }}
        />
      </SnackbarProvider>
      <Snackbar
        open={alertUnauthorized.length > 0}
        autoHideDuration={6000}
        onClose={() => setAlertUnauthorized("")}
      >
        <Alert
          variant="filled"
          onClose={() => setAlertUnauthorized("")}
          severity="error"
          sx={{ width: "100%" }}
        >
          {alertUnauthorized}
        </Alert>
      </Snackbar>
      <Snackbar open={openSucess} autoHideDuration={60} action={action}>
        <SnackbarContent
          style={{
            backgroundColor: "green",
          }}
          message="Sucesso!"
          action={action}
        />
      </Snackbar>
    </Box>
  );
}

export default DashboardOXD;

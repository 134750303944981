import styled from 'styled-components';
import { darken } from 'polished';

export const Container = styled.div`
  overflow: hidden;
  height: 100vh;
  width: 20vw;
  position: fixed;
  /* rgba(0, 20, 220, 0.3) */
  background: linear-gradient(-10deg, #000063, #741f8a);

  /* RGB : 233, 198, 116 */
  /* RGB : 227, 191, 105 */

  -moz-box-shadow: 3px 3px 5px 1px #ccc;
  -webkit-box-shadow: 3px 3px 5px 1px #ccc;
  box-shadow: 3px 3px 5px 1px #ccc;
`;

export const Content = styled.div`
  padding: 8px;

  nav {
    display: flex;
    flex-direction: column;

    text-align: center;
    color: rgba(0, 0, 0, 0.7);

    align-items: center;

    svg {
      position: relative;
      left: 120px;
    }

    img {
      width: 7vw;
      margin-top: 10px;
    }

    h1 {
      margin-top: 13px;
      font-size: 1.4rem;
      font-weight: 700;
      text-transform: capitalize;

      /* ::first-letter {
        text-transform:capitalize; // Primeira letra up
      }  */
    }

    h2 {
      font-size: 1.3rem;
      font-weight: 300;
      /* text-transform: uppercase; */
      /* margin-top: 2px; */
      color: rgba(0, 0, 0, 0.5);

      /* ::first-letter {
        text-transform:capitalize; // Primeira letra up
      }  */
    }

    h3 {
      font-size: 1rem;
      font-weight: 300;
    }
  }

  div {
    display: flex;
    justify-content: center;
    margin-top: 6vh;

    button {
      padding: 5px;
      height: 4vh;
      width: 10vw;
      background: #f64c75;
      color: #fff;
      border: 0;
      border-radius: 4px;
      font-size: 1.2rem;
      transition: background 0.2s;

      &:hover {
        background: ${darken(0.09, '#f64c75')};
      }

      strong {
        margin: 5px;
      }
    }
  }

  @media (max-width: 700px) {
    div {
      button {
        strong {
          display: none;
        }
      }
    }
  }
`;

export const Menu = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 85px;

  /* text-align: justify; */
  /* align-items: center; */

  a {
    text-decoration: none;
    margin-bottom: 8px;
    /* color: rgba(0, 80, 200, 1); */
    color: rgba(0, 0, 0, 0.6);
    border-radius: 5px;
    font-size: 1.3rem;

    &:hover {
      background: rgba(0, 0, 0, 0.1);
      padding: 0 5px;
      transition: 0.7s;
    }
  }
`;

export const Footer = styled.div`
  font-weight: 400;
  display: flex;
  flex-direction: column;
  text-align: center;

  align-items: center;

  img {
    width: 8vw;
    background: rgba(0, 0, 0, 0.7);
    margin-bottom: 10px;
  }

  a {
    display: block;
    color: rgba(0, 0, 0, 0.6);
    /* margin-top: 5px; */
    margin-bottom: 8px;
    font-size: 1.1rem;
    opacity: 0.8;
    text-decoration: none;

    &:hover {
      opacity: 1;
    }
  }

  p {
    font-size: 1.1rem;
    margin-top: 16px;
    color: rgba(0, 0, 0, 0.3);
  }

  @media (max-width: 700px) {
    img {
      width: 15vw;
    }
  }
`;

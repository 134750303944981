import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Statistic,
  Row,
  Col,
  Spin,
  Input,
  // Button,
  Tag,
  Table,
  Space,
  Breadcrumb,
  // message,
  // Popconfirm
} from 'antd';
import { HomeOutlined } from '@ant-design/icons';

import { FiEdit } from "react-icons/fi";

import { LoadingOutlined } from '@ant-design/icons';

import api from "../../services/api";

import { Container, Header } from './styles';

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

function PageAlunos() {

  const [alunos, setAlunos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState([]);
  // const [visible, setVisible] = useState(false);
  // const [confirmLoading, setConfirmLoading] = useState(false);

  const { Search } = Input;

  function handleSearch(e) {
    setSearch(e.target.value);
    setLoading(true);
  }

  useEffect(() => {
    api.get("alunos").then((response) => {
      setAlunos(response.data);
      setLoading(false);
    });
  }, []);

  // Search
  useEffect(() => {
    api
      .get(`search/?search=${search}`).then((response) => {
        setAlunos(response.data);
        setLoading(false);
      });
  }, [search]);

  const columns = [
    {
      title: 'Nome',
      dataIndex: 'name',
      key: 'name',
      render: text => <strong>{text}</strong>,
    },
    {
      title: 'CPF',
      dataIndex: 'cpf',
      key: 'cpf',
    },
    {
      title: 'Município',
      dataIndex: 'municipio',
      key: 'municipio',
    },
    {
      title: 'Status',
      key: 'status',
      dataIndex: 'status',
      render: status => (
        <>
          {Array(status).map(tag => {
            var color = 'warning';
            // tag.length > 14 ? 'volcano' : 'green';
            if ( tag.length === 8 ) {
              color = 'error';
            } else if ( tag.length === 6 ) {
              color = 'success'
            }
            return (
              <Tag color={color} key={tag}>
                {tag.toUpperCase()}
              </Tag>
            );
          })}
        </>
      ),
    },
    {
      title: 'Abrir',
      dataIndex: 'id',
      key: 'id',
      render: (id) => (
        <Space size="middle">
          <Link to={`aluno/${id}`}>
            <FiEdit />
          </Link>
        </Space>
      ),
    },
  ];

  // const showPopconfirm = () => {
  //   setVisible(true);
  // };

  // const handleOk = () => {
  //   setConfirmLoading(true);
  //   setTimeout(async () => {
  //     const data = { status: "VÁLIDO" };

  //     await api.patch("/allvalidation", data).then((response) => {
  //       message.success(response.data.message);
  //       setVisible(false);
  //       setConfirmLoading(false);
  //       window.location.reload();
  //     }).catch((err) => {
  //       const { status } = err.response;
  //       if (status === 404) {
  //         message.warn(err.response.data.error);
  //       } else {
  //         message.error(err.response.data.error);
  //       }
  //       setVisible(false);
  //       setConfirmLoading(false);
  //     });
  //   }, 2000);
    
  // };

  // const handleCancel = () => {
  //   setVisible(false);
  // };

  return (
    <Container>
      <div>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to={{ pathname: "/" }}>
              <HomeOutlined />
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item >
            Alunos
          </Breadcrumb.Item>
        </Breadcrumb>
      </div>

      <Header>
        <Row gutter={40}>
          <Col span={30}>
          { alunos.length === 0 ? 
            <Spin indicator={antIcon} /> 
            :
            <Statistic title="Alunos Cadastrados" value={alunos.length} /> 
          }
          </Col>
        </Row>

        {/* <Popconfirm
          title="Confirmar validação dos cadastros"
          visible={visible}
          onConfirm={handleOk}
          okButtonProps={{ loading: confirmLoading }}
          onCancel={handleCancel}
        >
          <Button
            onClick={showPopconfirm}
            style={{ background: "rgb(235, 76, 30)", border: 0, color: "#fff" }}
          >
            Validar todos os cadastros
          </Button>
        </Popconfirm> */}
        
        
        <div>
          <Search placeholder="Busca" loading={loading} onChange={handleSearch} enterButton />
        </div>
      </Header>

      <Table columns={columns} dataSource={alunos} pagination={{ pageSize: 6 }} />

    </Container>
  );
}

export default PageAlunos;

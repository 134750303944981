import {
  Avatar,
  Box,
  Button,
  Container,
  CssBaseline,
  Paper,
  Step,
  StepLabel,
  Stepper,
  Toolbar,
  Typography,
} from "@mui/material";

import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { makeStyles } from "@mui/styles";
import React, { useEffect } from "react";
import MainHeader from "../../components/MainHeader";
import About from "../../components/SignupAluno/About";
import Address from "../../components/SignupAluno/Address";
import PersonalData from "../../components/SignupAluno/PersonalData";

import ReviewConfirm from "../../components/SignupAluno/ReviewConfirm";
// import ErrorBoundary from "../../components/ErrorBoundary";
import api from "../../services/api";
import history from "../../services/history";
import {
  gradienteLaranja180,
  laranjaIntermediario,
  laranjaMain,
} from "../../utils/styledVariables";

const useStyles = makeStyles((theme) => ({
  container: {
    background: gradienteLaranja180,
    height: "100vh",
    position: "fixed",
  },
  paperContainer: {
    backgroundImage: `url(/assets/backgroundSelect.png)`,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    minWidth: "100vw",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  },
  stepper: {
    "& .MuiStepIcon-active": { background: "red" },
    "& .MuiStepIcon-completed": { color: "green" },
  },
  itens: {
    alignSelf: "center",
  },
}));

const steps = ["Dados Pessoais", "Residência", "Info", "Confirmação"];
// const steps = ["Sobre mim" ];

var personalData = {},
  addressData = {},
  infoData = {},
  passDet = {};
function getStepContent(step) {
  switch (step) {
    case 0:
      return <PersonalData />;
    case 1:
      return <Address />;
    case 2:
      return <About />;
    case 3:
      return <ReviewConfirm />;
    default:
      throw new Error("Etapa desconhecida");
  }
}

function SignUpAluno() {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const [alunoParams, setAlunoParams] = React.useState({});

  useEffect(() => {
    if (activeStep === 4) {
      api
        .post(
          "aluno/create",
          {
            params: {
              ...alunoParams.addressData,
              ...alunoParams.personalData,
              ...alunoParams.infoData,
              ...alunoParams.passDet,
              // comprovante_id: sessionStorage.getItem("comprovante_id"),
              accept_privacy_policies: true,
            },
          },
          {
            headers: { "Content-Type": "application/json" },
          }
        )
        .then(function (success) {
          sessionStorage.clear();
          history.push("/sucess");
        })
        .catch(function (error) {
          if (
            error &&
            error.response &&
            error.response.data &&
            error.response.data.error
          ) {
            alert("Erro no cadastro: " + error.response.data.error);
          } else {
            alert("Ocorreu um erro no cadastro!");
          }
          window.location.reload();
        });
    }
  }, [activeStep, alunoParams]);

  function saveData() {}

  const handleNext = () => {
    personalData = {
      name: sessionStorage.getItem("fullName"),
      email: sessionStorage.getItem("email"),
      telefone: sessionStorage.getItem("phoneNumber"),
      cpf: sessionStorage.getItem("cpf"),
      data_nascimento: sessionStorage.getItem("birthDate"),
      genero: sessionStorage.getItem("gender")
    };
    addressData = {
      rua: sessionStorage.getItem("rua"),
      bairro: sessionStorage.getItem("bairro"),
      municipio: sessionStorage.getItem("municipio"),
      cep: sessionStorage.getItem("cep"),
      estado: sessionStorage.getItem("estado"),
      numero: sessionStorage.getItem("numero"),
      // comprovante_id: sessionStorage.getItem("comprovante_id"),
    };

    infoData = {
      sobre_mim: sessionStorage.getItem("sobre_mim"),
      foco_carreira: sessionStorage.getItem("foco_carreira"),
      ocupacao: sessionStorage.getItem("ocupacao"),
    };
    passDet = {
      password: sessionStorage.getItem("pass"),
      accept_privacy_policies: sessionStorage.getItem("privacy_policies"),
    };
    if (sessionStorage.getItem("emailValido") === "incorreto") {
      alert("Não foi possível validar o email.");
    } else if (sessionStorage.getItem("cpfValido") === "incorreto") {
      alert("Não foi possível validar o cpf.");
    } else if (sessionStorage.getItem("birthDate") === null) {
      alert("Verifique sua data de nascimento.");
    } else if (Object.values(personalData).some((v) => v === null)) {
      alert("Preencha todos os campos para avançar obrigatórios.");
    } else if (
      activeStep === 1 &&
      Object.values(addressData).some((v) => v === null)
    ) {
      alert("Preencha todos os campos para avançar.");
    } else if (
      activeStep === 2 &&
      Object.values(infoData).some((v) => v === "")
    ) {
      alert("Preencha todos os campos para avançar.");
    } else if (
      activeStep === 3 &&
      Object.values(passDet).some((v) => v === null)
    ) {
      alert("Preencha todos os campos para avançar.");
    } else if (
      activeStep === 4 &&
      passDet.accept_privacy_policies === "false"
    ) {
      alert("Precisa aceitar as políticas de privacidade para avançar.");
    } else {
      personalData.nome_social =
        sessionStorage.getItem("socialName") === null
          ? ""
          : sessionStorage.getItem("socialName");
      setActiveStep(activeStep + 1);
      setAlunoParams({ personalData, addressData, infoData, passDet });
    }
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  return (
    <>
      <CssBaseline />
      <MainHeader />
      <main className={classes.container}>
        <div className={classes.paperContainer}>
          <Container component="main" maxWidth="lg" className={classes.itens}>
            <Paper
              elevation={24}
              sx={{
                maxHeight: "80vh",
                overflow: "scroll",
                p: { xs: 2, md: 3 },
                // opacity: 0.9,
              }}
            >
              <Box
                sx={{
                  alignItems: "center",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Avatar sx={{ m: 1, bgcolor: laranjaIntermediario }}>
                  <LockOutlinedIcon />
                </Avatar>
                <Typography
                  component="h1"
                  variant="h4"
                  color={laranjaIntermediario}
                >
                  Cadastro Pessoa Física
                </Typography>
              </Box>
              <Stepper
                className={classes.stepper}
                activeStep={activeStep}
                sx={{ pt: 3 }}
              >
                {steps.map((label) => (
                  <Step
                    key={label}
                    sx={{
                      "& .MuiStepLabel-root": {
                        ".Mui-active": {
                          color: laranjaIntermediario,
                        },
                        ".Mui-completed": {
                          color: laranjaIntermediario,
                        },
                      },
                    }}
                  >
                    <StepLabel>{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
              <>
                {activeStep === steps.length ? (
                  saveData()
                ) : (
                  <React.Fragment>
                    {getStepContent(activeStep)}
                    <Box sx={{ justifyContent: "flex-end" }}>
                      {activeStep !== 0 && (
                        <Button
                          onClick={handleBack}
                          sx={{ mt: 3, ml: 1, color: laranjaIntermediario }}
                        >
                          Voltar
                        </Button>
                      )}

                      <Button
                        variant="contained"
                        onClick={handleNext}
                        sx={{
                          mt: 3,
                          ml: 1,
                          backgroundColor: laranjaIntermediario,
                          "&:hover": {
                            backgroundColor: laranjaMain, // Change this to the desired hover color
                          },
                        }}
                      >
                        {activeStep === steps.length - 1 ? "Enviar" : "Próximo"}
                      </Button>
                    </Box>
                  </React.Fragment>
                )}
              </>
              <Toolbar />
            </Paper>
          </Container>
        </div>
      </main>
    </>
  );
}

export default SignUpAluno;

import React from 'react'
import { Link } from 'react-router-dom'
import {
  Container,
  SectionOne,
  LogoOxeDin,
  LogoOxeTechLab,
  LogoOxeTechWork,
  LogoOxeTechHat,
  TriangleFrame,
  InovationMap,
  Text
} from './styles'

import MainHeader from '../../components/MainHeader'

import triangleFrame from '../../assets/homeTriangle/triangleFrameG.png'
import oxeTechHat from '../../assets/homeTriangle/oxeTechHat.png'
import oxeDinLogoON from '../../assets/homeTriangle/oxeDinLogoON.png'
import oxeTechLabLogo from '../../assets/homeTriangle/oxeTechLabLogo.png'
import oxeTechWorkLogo from '../../assets/homeTriangle/oxeTechWorkLogo.png'
import InovationMapIcon from '../../assets/homeTriangle/InovationMapIcon.png'
import Typography from '@mui/material/Typography'

function Main(Props) {
  return (
    <Container>
      <MainHeader />

      <SectionOne>
        <TriangleFrame src={triangleFrame} layoutId="triangleFrame" />

        <Link
          to="/dash-oxe-tech"
          style={{
            marginLeft: '0%',
            marginTop: '-100%',
            position: 'absolute'
          }}
        >
          <LogoOxeTechHat src={oxeTechHat} layoutId="oxeTech" />
        </Link>

        <Link
          to="/"
          style={{
            position: 'absolute',
            left: '40%',
            top: '8%',
            width: '20%'
          }}
        >
          <LogoOxeDin src={oxeDinLogoON} layoutId="oxeDin" />
        </Link>

        <Link
          to="/dash-oxe-tech-lab"
          style={{
            marginLeft: '33%',
            marginTop: '-106%',
            position: 'absolute'
          }}
        >
          <LogoOxeTechLab src={oxeTechLabLogo} layoutId="oxeTechLab" />
        </Link>

        <Link
          to="/dash-oxe-tech-work"
          style={{
            marginLeft: '-40%',
            marginTop: '-80%',
            position: 'absolute'
          }}
        >
          <LogoOxeTechWork src={oxeTechWorkLogo} layoutId="oxeTechWork" />
        </Link>
        <Link
          to="/inovation-map"
          style={{
            marginLeft: '150vw',
            marginTop: '-100vh',
            position: 'absolute'
          }}
        >
          <InovationMap src={InovationMapIcon} layoutId="inovationMap" />
        </Link>
        <Text
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.2, duration: 0.2 }}
          style={{}}
        >
          <Typography
            className="typ"
            variant="body1"
            gutterBottom
            sx={{ textAlign: 'justify', fontWeight: '300' }}
          >
            O OxeDIN é a plataforma de contato entre alunos do programa OxeTech
            Lab e empresas.
            <br /> <br /> Quer achar um desenvolvedor e não sabe onde encontrar?
            Anuncie sua vaga, encontre profissionais qualificados e acompanhe os
            cursos feitos por alunos! Se inscreva na plataforma e faça parte do
            Ecossistema de Inovação de Alagoas, conhecendo os produtos,
            processos e serviços produzidos em nosso Estado.
            <br />{' '}
          </Typography>
          {/* <Link to="/dash-oxe-tech-work">
            <Tooltip className="tool" title="OxeTechWork" placement="bottom">
              <a>Próximo</a>
            </Tooltip>
          </Link> */}
        </Text>
        <Text
          onClick={() => {
            Props.history.goBack()
          }}
        >
          <button
            style={{
              position: 'absolute',
              marginTop: '93vh',
              marginLeft: '10vw'
            }}
          >
            Voltar
          </button>
        </Text>
        <Link to="/dash-inovation-map">
          <Text
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.5, duration: 0.5 }}
          >
            <button
              style={{
                position: 'absolute',
                marginTop: '93vh',
                marginLeft: '85vw'
              }}
            >
              Proximo
            </button>
          </Text>
        </Link>
      </SectionOne>
    </Container>
  )
}

export default Main

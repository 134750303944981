import React, { useState, useEffect } from 'react';
import * as palette from "../../utils/styledVariables";
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import helperTexts from '../../utils/helperTexts';
import {
    Box,
    Grid,
    Typography,
    styled,
    Paper,
    Button,
    
} from "@mui/material"
import Levels from '../../utils/levels';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import CardGrafico from '../CardGrafico';
import Curriculum from '../curriculum';
import { pdf } from '@react-pdf/renderer';
import ModalContratacao from './ModalContratacao';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import contratar from '../../assets/contratar.svg'

const styles = {
    title: {
        padding: "16px",
        height: "70px",
        justifyItems: 'center',
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: "30px",
        textAlign: 'left',
        color: palette.profileWhiteShade,
        background: palette.laranjaIntermediario2
    },
    card1: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontSize: "40px",
        padding: "auto",
        height: "150px",
        color: palette.preto,
        fontStyle: "normal",
        fontWeight: 500,
        background: palette.laranjaOXLTransparente,
        textAlign: 'center',
    },
    card2: {
        display: "flex",
        padding: "auto",
        justifyContent: "center",
        alignItems: "center",
        fontSize: "70px",
        height: "308px",
        color: palette.preto,
        fontStyle: "normal",
        fontWeight: 500,
        background: palette.laranjaOXLTransparente,
        textAlign: 'center',
    },
    card3: {
        padding: "16px",
        justifyContent: "center",
        alignItems: "center",
        height: "480px",
        fontSize: "30px",
        color: palette.preto,
        fontStyle: "normal",
        fontWeight: 450,
        background: palette.laranjaOXLTransparente,
        textAlign: 'left',
    },
    card4: {
        padding: "16px",
        height: "308px",
        justifyContent: "center",
        alignItems: "center",
        fontSize: "30px",
        color: palette.preto,
        fontStyle: "normal",
        fontWeight: 450,
        background: palette.laranjaOXLTransparente,
        textAlign: 'left',
    },
    textContent: {
        justifyContent: "center",
        alignItems: "center",
        margin: "auto",

    },
    subtext: {

        fontSize: "20px",
        fontStyle: "italic",
        color: palette.preto,
        fontWeight: 100,
        textAlign: 'center',
        marginTop: "-10px"
    },
    contratar:{
        background: palette.laranjaIntermediario2,
       ':hover':{
        background: palette.laranjaSecundario
       }
    }
};

const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,

    //padding: theme.spacing(2),
    justifyItems: 'center',
    color: theme.palette.text.secondary,
    borderRadius: '15px'
}));

function calcIncrease(candidates) {
    let lastAppliances = [];
    let lastWeek = new Date();
    lastWeek.setDate(lastWeek.getDate() - 7);

    for (let i = 0; i < candidates.length; i++) {

        let applianceDate = new Date(candidates[i].createdAt);

        if (applianceDate.getTime() >= lastWeek.getTime())
            lastAppliances.push(candidates[i]);
    }

    let increase = lastAppliances.length != candidates.length ? Math.round(lastAppliances.length / (candidates.length - lastAppliances.length) * 100) : 100 * candidates.length;

    return [lastAppliances, increase];

}

function createRows(candidates) {

    let candidatesListData = [];

    for (let i = 0; i < candidates.length; i++) {

        let currentCandidateData = {
            id: candidates[i].Aluno.id,
            candidato: candidates[i].Aluno.nome_social ? candidates[i].Aluno.nome_social : candidates[i].Aluno.name,
            curriculo: candidates[i].Aluno,
            tempo_experiencia: candidates[i].tempo_experiencia,
            telefone: candidates[i].Aluno.telefone,
            email: candidates[i].Aluno.email,
        }


        candidates[i].Aluno.skills?.forEach((skill) => {
            currentCandidateData[skill.name] = Levels.skillLevels[skill.rating]
        })

        candidatesListData.push(currentCandidateData);
    };

    return candidatesListData;
}

function handleMissingValues(params) { return params.value ? params.value : 'Não informado' }
function createHeader(jobName, requirements, qualification) {

    let candidatesListHeader = [];

    candidatesListHeader = [{
        field: 'curriculo', headerName: 'Curriculo', renderCell: (params) => {
            return (<Button sx={{ color: palette.roxoAzulado }} onClick={() => {
                pdf(<Curriculum candidate={params.value} jobName={jobName} />).toBlob().then((blob) => {
                    const url = URL.createObjectURL(blob);
                    const a = document.createElement('a');
                    a.setAttribute('href', url);
                    a.setAttribute('download', `${params.value.nome_social ? params.value.nome_social : params.value.name}.pdf`);
                    a.click();
                });

            }}>Baixar</Button>)
        }
    },
    {
        field: 'id', headerName: 'id',
    },
    {
        field: 'candidato', headerName: 'Candidato', width: 250
    },

    {
        field: 'tempo_experiencia', headerName: 'Anos de experiência', width: 200
    },
    {
        field: 'telefone', headerName: 'Telefone', width: 200
    },
    {
        field: 'email', headerName: 'Email', width: 200
    },
    ];


    requirements?.forEach((item) => {
        candidatesListHeader.push({ field: item, headerName: item, valueGetter: handleMissingValues, minWidth: 200 })
    });

    if (qualification)
        candidatesListHeader.push({ field: qualification, headerName: qualification, valueGetter: handleMissingValues, minWidth: 200 })



    return candidatesListHeader;
}

function buildExperienceFrequencies(candidates) {
    let freqs = {}

    Object.values(Levels.experienceLevels).forEach(item => {
        freqs[item] = 0
    })

    if (candidates.length > 0) {
        const experience = candidates.map(item => item.tempo_experiencia);
        experience.forEach(candidateExp => {
            freqs[Levels.experienceLevels[candidateExp]] = freqs[Levels.experienceLevels[candidateExp]] + 1
        })
    }
    return Object.values(freqs);
}

function getDaysLeft(endDateStr) {
    const today = new Date();
    const endDate = new Date(endDateStr);

    const timeDifference = endDate.getTime() - today.getTime();
    const dayDiferrence = Math.round(timeDifference / (1000 * 3600 * 24));
    return dayDiferrence >= 0 ? dayDiferrence : 0;
}

function getDaysLastApplication(appliances) {
    if (appliances.length > 0) {
        const today = new Date();
        const lastAppDate = new Date(appliances.at(-1).createdAt);

        const timeDifference = today.getTime() - lastAppDate.getTime();

        return Math.round(timeDifference / (1000 * 3600 * 24));
    }

    return "-";

}

function DashboardVaga(props) {
    const { candidatesNotHired ,candidates, job } = props;
    const experienceLabels = Object.values(Levels.experienceLevels);
    const experienceData = buildExperienceFrequencies(candidates);
    const candidatesListHeader = createHeader(job.titulo, job.requisitos, job.qualificacoes);
    const candidatesListData = createRows(candidates);
    const [lastAppliances, increase] = calcIncrease(candidates);
    const daysLeft = getDaysLeft(job.data_encerramento);
    const lastAppDate = getDaysLastApplication(candidates);

    const [openModalContratacao, setOpenModalContratacao] = useState(false);
    const handleCloseModalContratacao = () => setOpenModalContratacao(false);
    return (

        <Grid container spacing={1}>

            <Grid item xs={12} md={12}>
                <Item sx={styles.title}>{job.titulo}</Item>

            </Grid>
            <Grid container direction='row' spacing={1}
                item xs={12} sm={6} md={3}>
                <Grid item xs={6} >
                    <Item sx={styles.card1}><div>{candidates.length}<Typography style={styles.subtext}>candidatos</Typography></div></Item>
                </Grid>
                <Grid item xs={6}>
                    <Item sx={styles.card1}><div>{job.quantidade}<Typography style={styles.subtext}>vagas</Typography></div></Item>
                </Grid>
                <Grid item xs={6}>
                    <Item sx={styles.card1}><div>{lastAppDate}<Typography style={styles.subtext}>dias desde a <br /> última candidatura</Typography></div></Item>
                </Grid>
                <Grid item xs={6}>
                    <Item sx={styles.card1}><div>{daysLeft}<Typography style={styles.subtext}>dias restantes</Typography></div></Item>
                </Grid>
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
                <Item sx={styles.card2}>
                    <Box sx={styles.textContent}>
                        <Box sx={{
                            display: 'flex', "justify-content": "center",
                            "align-items": "center"
                        }}>
                            <KeyboardDoubleArrowUpIcon fontSize="inherit" sx={{
                                "justify-content": "center",
                                "align-items": "center"
                            }} />
                            {increase}%
                        </Box>
                        <Typography sx={styles.subtext}>
                            +{lastAppliances.length} candidaturas
                            <br />
                            na última semana
                        </Typography>
                    </Box>
                </Item>
            </Grid>

            <Grid item xs={12} sm={12} md={6}>
                <Item sx={styles.card4}>
                    <CardGrafico height={"210px"} title={"Experiência dos candidatos"} chartType={'bar'} columns={experienceLabels} rows={experienceData} />
                </Item>
            </Grid>

            <Grid item xs={12}>
                <Item sx={styles.card3}>
                    <div style={{display: 'flex', justifyContent: 'space-between', paddingBottom: '2%', alignItems: 'center'}} >
                        <h4>Lista de candidatos</h4>
                        {job.status == 'ABERTA'?                        
                        <Button style={{display: job.oxetech_work?'flex':' none'}} onClick={() => setOpenModalContratacao(true)} sx={styles.contratar} variant="contained" startIcon={<img src={contratar} alt='contratar' style={{marginRigth:'10%'}} />}>
                            Contratar
                        </Button>:
                        <div style={{display: job.oxetech_work?'flex':' none', width: '25%' }} >
                            <CheckCircleOutlineIcon color='success' />
                            <p style={{fontSize: '16px', marginLeft: '2%', marginBottom: 0}} >Todas as vagas foram preenchidas!</p></div>
                        }
                    </div>
                    <Box height={'375px'} >
                        <DataGrid rows={candidatesListData}
                            columns={candidatesListHeader}
                            components={{ Toolbar: GridToolbar }}
                            columnVisibilityModel={{ id: false }}
                            sx={{
                                background: palette.profileWhiteShadeTransp, borderRadius: '15px',
                                "& .MuiDataGrid-toolbarContainer": {
                                    '& .MuiButton-text': {
                                        color: palette.laranjaIntermediario2,
                                    },

                                }, "& .MuiDataGrid-columnHeaderTitle": {
                                    color: palette.preto,

                                }
                                , "& .MuiDataGrid-cellContent": {
                                    color: palette.preto,

                                }
                            }}
                            localeText={helperTexts.dataGridToolbarText}
                        />
                        
                    </Box>

                </Item>
            </Grid>

            {/* <Grid item xs={12} sm={6} md={6}>
                <Item sx={styles.card3}>
                    <CardGrafico 
                    height={"380px"}
                    title={"Status das candidaturas"}
                    chartType={'bar'}
                    columns={candidatesStatusColumns}
                    rows={candidatesStatusRows} />
                </Item>
            </Grid> */}
        <ModalContratacao  open={openModalContratacao} close={handleCloseModalContratacao} candidates={candidatesNotHired} job={job}/>
        </Grid>

    )
}

export default DashboardVaga;
import React, { useState, useEffect } from "react";
import logoOTW from "../../assets/LOGO_OXETECH_WORK.png";
import {
  Modal,
  Typography,
  styled,
  Box,
  Grid,
  Button,
  TextField,
} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import ReplyIcon from "@mui/icons-material/Reply";
import { Subtitles } from "./styles";
import * as palette from "../../utils/styledVariables";
import { isValidCPF } from "@brazilian-utils/brazilian-utils";
import InputMask from "react-input-mask";
import api from "../../services/api";
import prettyBytes from "pretty-bytes";
import UploadIcon from "@mui/icons-material/Upload";
import DeleteIcon from "@mui/icons-material/Delete";
import CircularProgress from "@mui/material/CircularProgress";

const SimpleButton = styled((props) => <Button {...props} />)(() => ({
  background: palette.laranjaIntermediario2,
  "&:hover": {
    background: palette.laranjaSecundario,
  },
  color: "white",
}));

const styleModal = {
  width: "60%",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  padding: "10px",
  textAlign: "center",
  borderRadius: "1vh",
  justifyContent: "center",
};

function ModalOTWork(props) {
  // const [name, setName] = useState(null);
  // const [cpf, setCPF] = useState(null);
  // const [email, setEmail] = useState(null);
  // const [cargo, setCargo] = useState(null);

  const [errorEmail, setErrorEmail] = React.useState(null);
  const [errorName, setErrorName] = React.useState(null);
  const [errorCPF, setErrorCPF] = React.useState(null);
  const [errorCargo, setErrorCargo] = React.useState(null);
  const [errorFound, setErrorFound] = React.useState(false);
  // const [fileCurriculo, setFileCurriculo] = React.useState(null);
  // const [fileIdCurriculo, setFileIdCurriculo] = React.useState(null);
  const [files, setFiles] = React.useState(null);
  const defaultButtonTextCurriculo = "Anexar (Máx 10MB)";
  const [buttonTextCurriculo, setButtonTextCurriculo] = React.useState(
    defaultButtonTextCurriculo
  );

  const getSessionStorage = (key) => {
    const storedValue = sessionStorage.getItem(key);
    return storedValue ? storedValue : "";
  };

  const [mentorNome, setMentorNome] = useState(
    getSessionStorage("mentor_nome")
  );
  const [mentorCPF, setMentorCPF] = useState(getSessionStorage("mentor_cpf"));
  const [mentorEmail, setMentorEmail] = useState(
    getSessionStorage("mentor_email")
  );
  const [mentorCargo, setMentorCargo] = useState(
    getSessionStorage("mentor_cargo")
  );
  function handleRemoveFiles() {
    setButtonTextCurriculo(defaultButtonTextCurriculo);
    setFiles(null);
    sessionStorage.removeItem("files");
  }

  useEffect(() => {
    sessionStorage.setItem("mentor_nome", mentorNome);
    sessionStorage.setItem("mentor_cpf", mentorCPF);
    sessionStorage.setItem("mentor_email", mentorEmail);
    sessionStorage.setItem("mentor_cargo", mentorCargo);
  }, [mentorNome, mentorCPF, mentorEmail, mentorCargo]);
  const handleCargo = (e) => {
    const {
      target: { value },
    } = e;
    // setCargo(value);
    setMentorCargo(value);
    setErrorCargo({ cargo: "" });
    if (value.length <= 0) setErrorCargo({ cargo: "Preencha esse campo." });
  };

  const handleEmail = (e) => {
    const {
      target: { value },
    } = e;
    // setEmail(value);
    setMentorEmail(value);
    setErrorEmail({ email: "" });

    if (value.trim().length > 0) {
      let reg = new RegExp(
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      ).test(value);
      if (!reg) setErrorEmail({ email: "Insira um email válido." });
    } else {
      setErrorEmail({ email: "Preencha esse campo." });
    }
  };

  const handleCPF = (e) => {
    const {
      target: { value },
    } = e;
    // setCPF(value);
    setMentorCPF(value);
    setErrorCPF({ cpf: "" });
    if (value.trim().length > 0) {
      let reg = new RegExp(/^\d{3}\.\d{3}\.\d{3}-\d{2}$/).test(value);
      if (!reg || !isValidCPF(value)) {
        setErrorCPF({ cpf: "CPF inválido." });
        sessionStorage.removeItem("cpf");
      }
    } else {
      setErrorCPF({ cpf: "Preencha esse campo." });
    }
  };

  const handleName = (e) => {
    const {
      target: { value },
    } = e;
    setMentorNome(value);
    setErrorName({ name: "" });
    if (value.length <= 0) setErrorName({ name: "Preencha esse campo." });
  };

  function handleFiles(event) {
    const filesize_max = 10 * 1000 * 1000;
    if (event.target.files[0] && event.target.files[0].size > filesize_max) {
      setFiles(null);
      alert("O pdf é muito grande ( máx " + prettyBytes(filesize_max) + " )");
    } else {
      if (event.target.files[0] !== null) {
        setFiles(event.target.files[0]);
        sessionStorage.setItem("files", files);
        setButtonTextCurriculo(
          event.target.files[0].name +
            " - (" +
            prettyBytes(event.target.files[0].size) +
            " / " +
            prettyBytes(filesize_max) +
            ")"
        );
      } else {
        setFiles(null);
        sessionStorage.removeItem("files");
        setButtonTextCurriculo(defaultButtonTextCurriculo);
      }
    }
  }

  const handleSubmit = () => {
    // let errorFound = false;
    setErrorFound(false);
    if (mentorCargo === "") {
      setErrorCPF({ cpf: "Preencha esse campo." });
      setErrorFound(true);
    }
    if (mentorNome === "") {
      setErrorName({ name: "Preencha esse campo." });
      setErrorFound(true);
    }
    if (mentorEmail === "") {
      setErrorEmail({ email: "Preencha esse campo." });
      setErrorFound(true);
    }
    if (mentorCargo === "") {
      setErrorCargo({ cargo: "Preencha esse campo." });
      setErrorFound(true);
    }

    if (files === null) {
      setErrorFound(true);
    }

    if (errorFound === false) {
      const payload = {
        mentorNome,
        mentorCPF,
        mentorEmail,
        mentorCargo,
      };
      if (files !== null) {
        props.handleSubmit(payload, files);
        props.handleModal();
      }
    } else {
    }
  };

  return (
    <Modal
      open={props.openModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{ justifyContent: "center" }}
    >
      <Box sx={styleModal}>
        <img src={logoOTW} alt="logo" style={{ width: "40%" }} />
        <Typography sx={{ color: palette.preto }}>
          Insira os dados do mentor do bolsista
        </Typography>

        <Grid container spacing={1} sx={{ padding: "10px" }}>
          <Grid item xs={12} sm={12} md={12} lg={6}>
            <Subtitles>Nome completo</Subtitles>
            <TextField
              hiddenLabel
              id="mentor-name"
              variant="filled"
              fullWidth
              value={mentorNome}
              onChange={handleName}
              onBlur={handleName}
              error={Boolean(errorName?.name)}
              helperText={errorName?.name}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={6}>
            <Subtitles>CPF</Subtitles>

            <InputMask
              mask="999.999.999-99"
              value={mentorCPF}
              disabled={false}
              maskChar=" "
              onBlur={handleCPF}
              onChange={handleCPF}
            >
              {() => (
                <TextField
                  variant="filled"
                  id="cpf"
                  name="cpf"
                  autoComplete="cpf"
                  error={Boolean(errorCPF?.cpf)}
                  helperText={errorCPF?.cpf}
                  required
                  fullWidth
                />
              )}
            </InputMask>
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={6}>
            <Subtitles>E-mail</Subtitles>
            <TextField
              hiddenLabel
              id="email"
              variant="filled"
              fullWidth
              value={mentorEmail}
              onBlur={handleEmail}
              onChange={handleEmail}
              error={Boolean(errorEmail?.email)}
              helperText={errorEmail?.email}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={6}>
            <Subtitles>Cargo</Subtitles>
            <TextField
              hiddenLabel
              id="cargo"
              variant="filled"
              fullWidth
              value={mentorCargo}
              onChange={handleCargo}
              onBlur={handleCargo}
              error={Boolean(errorCargo?.cargo)}
              helperText={errorCargo?.cargo}
            />
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            sx={{ display: "flex", justifyContent: "start" }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                justifyContent: "space-around",
              }}
            >
              <h3
                style={{ margin: "2% 0", textAlign: "justify", width: "100%" }}
              >
                Faça upload em arquivo único com o{" "}
                <strong>plano de trabalho da vaga</strong> e o{" "}
                <strong>currículo do mentor</strong>
              </h3>

              <input
                accept="application/pdf"
                style={{ display: "none" }}
                id="files"
                type="file"
                onChange={handleFiles}
              />
              <label
                htmlFor="files"
                style={{
                  padding: 0,
                  margin: 0,
                  width: "100%",
                  display: "flex",
                }}
              >
                <Button
                  disabled={
                    buttonTextCurriculo !== defaultButtonTextCurriculo
                      ? true
                      : false
                  }
                  sx={{ py: 2, width: "60%" }}
                  variant="outlined"
                  component="span"
                >
                  <UploadIcon sx={{ mr: 1 }} />
                  {buttonTextCurriculo.slice(0, 30)}
                </Button>
                <Button
                  sx={{
                    display:
                      buttonTextCurriculo === defaultButtonTextCurriculo
                        ? "none"
                        : "flex",
                  }}
                  variant="text"
                  onClick={handleRemoveFiles}
                >
                  <DeleteIcon
                    sx={{
                      color: "red",
                      display:
                        buttonTextCurriculo === defaultButtonTextCurriculo
                          ? "none"
                          : "flex",
                    }}
                  />
                </Button>
              </label>
            </Box>
          </Grid>
        </Grid>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <SimpleButton onClick={props.handleModal} startIcon={<ReplyIcon />}>
            {" "}
            VOLTAR{" "}
          </SimpleButton>
          <SimpleButton onClick={handleSubmit} startIcon={<SaveIcon />}>
            SUBMETER VAGA &nbsp;{" "}
            {props.loading && (
              <CircularProgress size={24} sx={{ color: "white" }} />
            )}
          </SimpleButton>
        </Box>
      </Box>
    </Modal>
  );
}

export default ModalOTWork;
// export default function IntegrationNotistack() {
//     return (
//       <SnackbarProvider maxSnack={3}>
//         <ModalOTWork />
//       </SnackbarProvider>
//     );
//   }

import {
  Avatar,
  Box,
  Button,
  Container,
  CssBaseline,
  Paper,
  Step,
  StepLabel,
  StepContent,
  Stepper,
  Stack,
  Typography,
  Toolbar,
} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import React, { useEffect } from "react";
import MainHeader from "../../components/MainHeader";
import IdEmpresaForm from "../../components/SignupEmpresa/IdEmpresa";
import AddressForm from "../../components/SignupEmpresa/Address";
import SocialNetworkForm from "../../components/SignupEmpresa/SocialNetwork";
import ReviewConfirm from "../../components/SignupAluno/ReviewConfirm";
import AuthenticationForm from "../../components/SignupEmpresa/Authentication";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import api from "../../services/api";
import history from "../../services/history";
import axios from "axios";
import {
  gradienteLaranja180,
  laranjaIntermediario,
  laranjaMain,
} from "../../utils/styledVariables";

const steps = [
  "Identificação da Empresa",
  "Endereço",
  "Redes Sociais",
  "Autenticação",
  "Finaliza Cadastro",
];
// auth: responsável, email, possui propriedade industrial

let companyData = {},
  addressData = {},
  socialData = {},
  authData = {},
  locationData = {},
  passDet = {};

function getStepContent(step) {
  switch (step) {
    case 0:
      return <IdEmpresaForm />;
    case 1:
      return <AddressForm />;
    case 2:
      return <SocialNetworkForm />;
    case 3:
      return <AuthenticationForm />;
    case 4:
      return <ReviewConfirm />;
    default:
      return <IdEmpresaForm />;
  }
}

const useStyles = makeStyles((theme) => ({
  container: {
    background: gradienteLaranja180,
    //"linear-gradient(180deg, rgba(116, 31, 138, 0.9) 35%, rgba(0, 0, 99, 1) 100%)",
    height: "100vh",
    position: "fixed",
  },
  paperContainer: {
    backgroundImage: `url(/assets/backgroundSelect.png)`,
    // display: "flex",
    height: "100vh",
    width: "100vw",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    display: 'flex',
    justifyContent: 'center',

  },
  stepper: {
    "& .MuiStepIcon-active": { color: "red" },
    "& .MuiStepIcon-completed": { color: "green" },
  },
  itens: {
    alignSelf: "center"
  },
}));

function SignUpEmpresa() {
  const theme_c = useTheme();
  const isSmallScreen = useMediaQuery(theme_c.breakpoints.up("sm"));
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const mykey = process.env.REACT_APP_GEOCODE_HEREKEY;

  useEffect(() => {
    if (activeStep === 5) {
      api
        .post(
          "empresa/create",
          {
            params: {
              ...companyData,
              ...addressData,
              ...passDet,
              ...socialData,
              ...authData,
              ...locationData,
              // estado: "AL",
              // accept_privacy_policies: true,
            },
          },
          {
            headers: { "Content-Type": "application/json" },
          }
        )
        .then(function (success) {
          sessionStorage.clear();
          history.push("/sucess");
        })
        .catch(function (error) {
          if (error && error.response && error.response.error) {
            alert("Erro no cadastro!\n" + error.response.error);
          }
          window.location.reload();
        });
    }
  }, [activeStep]);

  const saveData = () => {};

  const stepperProps = {
    orientation: isSmallScreen ? "horizontal" : "vertical",
  };

  const handleNext = () => {
    companyData = {
      cnpj: sessionStorage.getItem("cnpj"),
      razao_social: sessionStorage.getItem("institution"),
      sobre: sessionStorage.getItem("aboutcompany"),
      categoria: JSON.parse(sessionStorage.getItem("categories")),
    };
    addressData = {
      endereco: {
        cep: sessionStorage.getItem("cep"),
        numero: sessionStorage.getItem("numero"),
        rua: sessionStorage.getItem("rua"),
        bairro: sessionStorage.getItem("bairro"),
        municipio: sessionStorage.getItem("municipio"),
        estado: sessionStorage.getItem("estado"),
      },
      telefone: sessionStorage.getItem("telefone"),
    };
    socialData = {
      link_facebook: sessionStorage.getItem("facebook"),
      link_instagram: sessionStorage.getItem("instagram"),
      link_linkedin: sessionStorage.getItem("linkedin"),
    };
    authData = {
      email: sessionStorage.getItem("email"),
      email_responsavel: sessionStorage.getItem("email"),
      nome_responsavel: sessionStorage.getItem("fullName"),
      tem_propriedade_intelectual: sessionStorage.getItem("property_intelect"),
      //tem_interesse_transferir: sessionStorage.getItem("transfer_property"),
    };
    passDet = {
      password: sessionStorage.getItem("pass"),
      accept_privacy_policies: sessionStorage.getItem("privacy_policies"),
    };
    if (Object.values(companyData).some((v) => v === null || v === ""))
      alert("Preencha todos os campos para avançar.");
    else if (sessionStorage.getItem("emailConfirmValido") === "incorreto") {
      alert("Erro na confirmação de email!");
    } else if (sessionStorage.getItem("emailEmpValido") === "incorreto") {
      alert("Email inválido!");
    } else if (
      activeStep === 1 &&
      (Object.values(addressData.endereco).some(
        (v) => v === null || v === ""
      ) ||
        addressData.telefone == null)
    ) {
      alert("Preencha todos os campos para avançar.");
    } else if (
      activeStep === 3 &&
      Object.values(authData).some((v) => v === null || v === "")
    ) {
      alert("Preencha todos os campos para avançar.");
    } else if (
      activeStep === 4 &&
      (Object.values(passDet).some((v) => v === null || v === "") ||
        passDet.accept_privacy_policies === "false")
    ) {
      alert("Preencha todos os campos para avançar.");
    } else setActiveStep(activeStep + 1);

    if (activeStep === 2) {
      let temp = addressData.endereco;
      let temp_address = encodeURI(
        `${temp.rua} ${temp.numero}, ${temp.municipio}, ${temp.cep} Brazil`
      );
      axios
        .get(
          `https://geocode.search.hereapi.com/v1/geocode?q=${temp_address}&apiKey=${mykey}`
        )
        .then((response) => {
          locationData = {
            latitude: response.data.items[0].position.lat,
            longitude: response.data.items[0].position.lng,
          };
        })
        .catch();
    }
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  return (
    <>
      <CssBaseline />
      <MainHeader />
      <main className={classes.container}>
        <div className={classes.paperContainer}>
          <Container
            component="main"
            maxWidth="lg"
            className={classes.itens}
          >
            <Paper
              elevation={24}
              sx={{
                maxHeight: "80vh",
                overflow: "scroll",
                p: { xs: 2, md: 3 },
                opacity: 0.9,
              }}
            >
              <Box
                sx={{
                  alignItems: "center",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Avatar sx={{ m: 1, bgcolor: laranjaIntermediario }}>
                  <LockOutlinedIcon />
                </Avatar>
                <Typography
                  component="h1"
                  variant="h4"
                  color={laranjaIntermediario}
                >
                  Cadastro Pessoa Jurídica
                </Typography>
              </Box>
              <Stepper
                className={classes.stepper}
                activeStep={activeStep}
                sx={{ pt: 3, display: { xs: "none", md: "flex" } }}
                {...stepperProps}
              >
                {steps.map((label) => (
                  <Step
                    key={label}
                    sx={{
                      "& .MuiStepLabel-root": {
                        ".Mui-active": {
                          color: laranjaIntermediario,
                        },
                        ".Mui-completed": {
                          color: laranjaIntermediario,
                        },
                      },
                    }}
                  >
                    <StepLabel>{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>

              <Stepper
                className={classes.stepper}
                activeStep={activeStep}
                sx={{ pt: 3, display: { sm: "flex", md: "none" } }}
                orientation="vertical"
              >
                {steps.map((label) => (
                  <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                    <StepContent>
                      {activeStep === steps.length ? (
                        saveData()
                      ) : (
                        <>
                          <Box sx={{ mb: 2 }}>
                            {getStepContent(activeStep)}

                            <div>
                              <Button
                                disabled={activeStep === 0}
                                onClick={handleBack}
                                sx={{ mt: 1, mr: 1 }}
                              >
                                Voltar
                              </Button>
                              <Button
                                variant="contained"
                                onClick={handleNext}
                                sx={{
                                  mt: 1,
                                  mr: 1,
                                  justifyContent: "flex-end",
                                }}
                              >
                                {activeStep === steps.length - 1
                                  ? "Enviar"
                                  : "Próximo"}
                              </Button>
                            </div>
                          </Box>
                        </>
                      )}
                    </StepContent>
                  </Step>
                ))}
              </Stepper>

              <>
                {activeStep === steps.length ? (
                  // console.log("Manda pro banco de dados")
                  saveData()
                ) : (
                  <React.Fragment>
                    <Stack
                      spacing={2}
                      sx={{ display: { xs: "none", md: "flex" } }}
                    >
                      {getStepContent(activeStep)}

                      <Box
                        sx={{
                          justifyContent: "flex-end",
                        }}
                      >
                        {activeStep !== 0 && (
                          <Button
                            onClick={handleBack}
                            sx={{ mt: 3, ml: 1, color: laranjaIntermediario }}
                          >
                            Voltar
                          </Button>
                        )}

                        <Button
                          variant="contained"
                          onClick={handleNext}
                          sx={{
                            mt: 3,
                            ml: 1,
                            backgroundColor: laranjaIntermediario,
                            "&:hover": {
                              backgroundColor: laranjaMain, // Change this to the desired hover color
                            },
                          }}
                        >
                          {activeStep === steps.length - 1
                            ? "Enviar"
                            : "Próximo"}
                        </Button>
                      </Box>
                    </Stack>
                  </React.Fragment>
                )}
              </>
              <Toolbar />
            </Paper>
          </Container>
        </div>
      </main>
    </>
  );
}

export default SignUpEmpresa;

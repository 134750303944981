import * as React from "react";
import ReactApexChart from "react-apexcharts";
import * as palette from "../../utils/styledVariables"

function CardGrafico(props) {
    const { title, chartType, rows, columns, height } = props;
    const chartOptions = {
        plotOptions: {
            bar: {
                borderRadius: 4,
                horizontal: true,
            }
        },
        fill: {
            colors: [palette.roxoAzulado]
        },
        dataLabels: {
            enabled: false,
        },
        tooltip: {
            y: {
              formatter: function (val) {
                return `${val.toFixed(0)}`;
              }
            }
          },
        xaxis: {
            categories: columns,
            labels: {
                formatter: function (val) {
                    return val % 1 == 0 ? val : "";
                }
            },

        },
        
    };
    const series = [{
        name: 'Total',
        data: rows
    }]
    return (
        <div id='chart'>
            {title}
            <ReactApexChart
                options={chartOptions}
                series={series}
                type={chartType}
                height={height}

            />
        </div>
    );
}

export default CardGrafico;

import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import { store } from "../../../store";
import history from "../../../services/history";
import DoneIcon from "@mui/icons-material/Done";
import api from "../../../services/api";
import { SnackbarProvider, useSnackbar } from "notistack";
import { elementType } from "prop-types";
import { Container } from "./styles";

import moment from "moment";
import "moment/locale/pt-br";
import { useAuth } from "../../../hooks/auth/AuthContext";
moment.locale("pt-br");

function Inscrevase() {
  // verificando se o usuario está logado
  let profile = useSelector((state) => {
    if (
      state &&
      state.user &&
      state.user.profile &&
      state.user.profile.dataValues
    ) {
      return state.user.profile.dataValues;
    } else if (state && state.user && state.user.profile) {
      return state.user.profile;
    }
  });

  const { signed } = store.getState().auth;
  const { adminUser, sudoUser } = useAuth();

  const [editals, setEditals] = useState([]);
  const [ciclos, setCiclos] = useState([]);
  const [editalAberto, setEditalAberto] = useState(null);
  const [subscriptions, setSubscriptions] = useState([]);

  const handleMenuClick = (pageURL) => {
    history.push(pageURL);
  };

  function getProfileType() {
    let profileType = "VISITANTE";
    if (profile) {
      profileType = profile.reponsibility
        ? "ADMIN"
        : profile.razao_social
        ? "EMPRESA"
        : "ALUNO";
    }
    return profileType;
  }

  const { enqueueSnackbar } = useSnackbar();

  function check_if_is_subscribed(edital_id) {
    let subscribed = false;
    subscriptions.forEach((element) => {
      if (element.edital_id === edital_id) {
        subscribed = true;
      }
    });
    return subscribed;
  }

    useEffect(() => {
        if (getProfileType() === "ALUNO"){
            api.get(`/oxetechwork/inscricao-aluno`).then(response => {
                setSubscriptions(response.data)
            }).catch((err) => {
                console.log(err)
            })
        }
        
        api.get(`oxetechwork/edital/all`).then(response => {
            if (response.data && response.data.editals){
                setEditals(response.data.editals)
                response.data.editals.forEach((e) => {
                    if (e.status == true || e.status === "Aberto" ){
                        setEditalAberto(e.id)
                    }
                })
            }else{
                enqueueSnackbar('Falha ao carregar os editais', {variant: 'error'})
            }
        }).catch((err) => {
            console.log(err)
            enqueueSnackbar('Falha ao carregar os editais', {variant: 'error'})
        })
    }, [])

  useEffect(() => {
    if (editalAberto) {
      api
        .get(`oxetechwork/ciclo/edital/` + editalAberto)
        .then((response) => {
          if (response.data) {
            setCiclos(response.data);
          } else {
            enqueueSnackbar("Falha ao carregar os ciclos", {
              variant: "error",
            });
          }
        })
        .catch((err) => {
          console.log(err);
          enqueueSnackbar("Falha ao carregar os ciclos", { variant: "error" });
        });
    }
  }, [editalAberto]);

  return (
    <Container>
      {/* {console.log(ciclos)} */}
      <Box>
        <Typography sx={{ fontWeight: 500, color: "#FFFFFF" }} variant="h2">
          Inscreva-se!
        </Typography>
      </Box>
      <Box
        sx={{
          p: 3,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box>
          <Typography
            sx={{ textAlign: "center", fontWeight: "light", color: "#FFFFFF" }}
            variant="h6"
          >
            Uma nova rodada de contratação está em andamento e aguardamos sua
            inscrição.
          </Typography>
          <Typography
            sx={{ textAlign: "center", fontWeight: "light", color: "#FFFFFF" }}
            variant="h6"
          >
            É rápido! Com alguns clicks candidados e empresas podem fazer parte
            do OxeTech Work.
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          gap: 3,
          flexWrap: "wrap",
          justifyContent: "center",
        }}
      >
        <Box>
          <Box
            sx={{
              flexGrow: 1,
              py: 1,
              px: 6,
              background: "rgba(57, 108, 205, 0.55)",
            }}
          >
            <Typography
              sx={{ textAlign: "center", color: "white" }}
              variant="h4"
            >
              CICLOS DE CONTRATAÇÃO
            </Typography>
          </Box>
          <Box
            sx={{
              minHeight: 150,
              display: "flex",
              flexDirection: "column",
              background: "rgba(53, 84, 177, 0.29)",
              pb: 2,
            }}
          >
            {/* ciclos.map((ciclo, index) */}
            {ciclos
              .sort((a, b) => a.num_ciclo - b.num_ciclo)
              .map((ciclo, index) => {
                const currentDate = moment().utc("America/Alagoas");
                const cicloEndDate = moment(ciclo.end).utc("America/Alagoas");
                const isExpired = currentDate.isAfter(cicloEndDate);
                return (
                  <Box
                    key={index}
                    sx={{
                      display: "flex",
                      pt: 2,
                      px: 2,
                      gap: 1,
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        textAlign: "center",
                        fontFamily: "Marcellus",
                        color: "white",
                        textDecoration: isExpired ? "line-through" : "none",
                      }}
                      variant="body1"
                    >
                      CICLO Nº {ciclo.num_ciclo}, de{" "}
                      {moment(ciclo.start)
                        .utc("America/Alagoas")
                        .format("DD/MM/YYYY")}{" "}
                      à{" "}
                      {moment(ciclo.end)
                        .utc("America/Alagoas")
                        .format("DD/MM/YYYY")}
                    </Typography>
                  </Box>
                );
              })}
          </Box>
        </Box>
        <Box>
          <Box
            sx={{
              flexGrow: 1,
              py: 1,
              px: 6,
              background: "rgba(57, 108, 205, 0.55)",
            }}
          >
            <Typography
              sx={{ textAlign: "center", color: "white" }}
              variant="h4"
            >
              EDITAL ABERTO
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              p: 2,
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
              background: "rgba(53, 84, 177, 0.29)",
            }}
          >
            {editals.map((edital, index) => {
              if (edital.status == true || edital.status === "Aberto") {
                return (
                  <Box
                    key={index}
                    sx={{
                      display: "flex",
                      px: 2,
                      gap: 1,
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography
                      key={index}
                      sx={{ fontFamily: "Marcellus", color: "white" }}
                      variant="body1"
                    >
                      {edital.title}
                    </Typography>
                    <Box>
                      {signed ? (
                        check_if_is_subscribed(edital.id) ? (
                          <Button
                            onClick={() => {
                              handleMenuClick(
                                "/oxetechwork-inscricao/" + edital.id
                              );
                            }}
                            sx={{ color: "white" }}
                            color="success"
                            variant="contained"
                          >
                            <DoneIcon /> INSCRITO
                          </Button>
                        ) : (
                          <Button
                            onClick={() => {
                              handleMenuClick(
                                "/oxetechwork-inscricao/" + edital.id
                              );
                            }}
                            sx={{ color: "white" }}
                            variant="outlined"
                          >
                            INSCREVER-SE
                          </Button>
                        )
                      ) : adminUser ? (
                        <Button
                          onClick={() => {
                            handleMenuClick("/dashadmin");
                          }}
                          sx={{ color: "white" }}
                          variant="outlined"
                        >
                          VER INSCRITOS
                        </Button>
                      ) : (
                        <Button
                          onClick={() => {
                            handleMenuClick("/login");
                          }}
                          sx={{ color: "white" }}
                          variant="outlined"
                        >
                          LOGIN P/ INSCREVER-SE
                        </Button>
                      )}
                    </Box>
                  </Box>
                );
              }
            })}
          </Box>
        </Box>
      </Box>
    </Container>
  );
}

export default function IntegrationNotistack() {
  return (
    <SnackbarProvider maxSnack={3}>
      <Inscrevase />
    </SnackbarProvider>
  );
}

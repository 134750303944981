import IconButton from "@mui/material/IconButton";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";

import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputAdornment,
  Link,
} from "@mui/material";

import React from "react";
import { laranjaIntermediario, laranjaMain } from "../../utils/styledVariables";

export default function Review() {
  const [checked, setChecked] = React.useState([true, false]);
  const [error, setError] = React.useState();
  const [error1, setError1] = React.useState();
  // const [values, setValues] = React.useState({
  //   password: "",
  //   password1: "",
  // });
  const [passw, setValue] = React.useState({ showPassword: false, pass: "" });
  const [passw1, setValue1] = React.useState({ showPassword: false, pass: "" });

  const check = (value) => {
    if (passw.pass !== value && passw1.pass !== value) {
      setError1("Senhas diferentes.");
      sessionStorage.removeItem("pass");
    } else {
      sessionStorage.setItem("pass", value);
    }
  };

  const handlePassword = (prop) => (event) => {
    // setValues({ ...values, [prop]: event.target.value });
    const {
      target: { value },
    } = event;
    setValue({ ...passw, pass: value });
    setError("");
    // debugger;
    // if (values.password.length < 8) {
    if (value.length < 8) {
      setError("Senha deve ter no mínimo 8 caracteres!");
      sessionStorage.removeItem("pass");
    } else check(value);
  };

  const handleConfirm = (prop) => (event) => {
    // setValues({ ...values, [prop]: event.target.value });
    const {
      target: { value },
    } = event;
    setValue1({ ...passw1, pass: value });
    setError1("");
    if (value.length < 8) {
      //setError("Senha deve ter no mínimo 8 caracteres!");
      sessionStorage.removeItem("pass");
    } else check(value);
  };

  const handleClickShowPassword = () => {
    // setValues({
    //   ...values,
    //   showPassword: !values.showPassword,
    // });
    setValue({ ...passw, showPassword: !passw.showPassword });
    setValue1({ ...passw1, showPassword: !passw1.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleChecked = (event) => {
    setChecked([event.target.checked, event.target.checked]);
    sessionStorage.setItem("privacy_policies", event.target.checked);
  };
  return (
    <Grid container spacing={3} sx={{ my: 3 }}>
      <Grid item xs={12} align="center">
        <FormControl
          error={Boolean(error)}
          fullWidth
          sx={{ m: 1 }}
          variant="outlined"
        >
          <InputLabel>Senha</InputLabel>
          <OutlinedInput
            id="outlined-adornment-password"
            // type={values.showPassword ? "text" : "password"}
            type={passw.showPassword ? "text" : "password"}
            value={passw.pass}
            onChange={handlePassword("passw")}
            color="warning"
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {passw.showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            label="Senha"
          />

          {!!error && <FormHelperText id="pass-error">{error}</FormHelperText>}
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <FormControl
          error={Boolean(error1)}
          fullWidth
          sx={{ m: 1 }}
          variant="outlined"
        >
          <InputLabel>Confirmação de Senha</InputLabel>
          <OutlinedInput
            id="outlined-adornment-password1"
            type={passw1.showPassword ? "text" : "password"}
            value={passw1.pass}
            onChange={handleConfirm("password1")}
            color="warning"
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {passw1.showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            label="Confirmação de Senha"
          />
          <FormHelperText id="pass-error1">{error1}</FormHelperText>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <FormControlLabel
          // value=
          control={
            <Checkbox
              sx={{
                "&.Mui-checked": {
                  color: laranjaIntermediario,
                },
              }}
              checked={checked[1]}
              onChange={handleChecked}
            />
          }
          label="Declaro que aceito as"
          labelPlacement="end"
          sx={{ mx: 0.5 }}
        />
        <Link
          href="/politicas-privacidade"
          underline="none"
          rel="noreferrer"
          target="_blank"
          color={laranjaMain}
        >
          Políticas de privacidade.
        </Link>
      </Grid>
    </Grid>
  );
}

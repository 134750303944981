import styled from 'styled-components';
import { motion } from 'framer-motion';
import * as palette from '../../utils/styledVariables';

export const Container = styled.div``;

export const SectionOne = styled.div`
  -webkit-user-drag: none;
  background: ${palette.laranjaMain};
  background-size: cover;

  overflow: hidden;
  height: 100vh;
  padding: 10vh;
  display: flex;
  text-align: center;
  position: relative;
  p {
    color: rgba(255, 255, 255, 1);
    font-size: 1.5em;
    text-align-last: justify;
  }
`;

export const TriangleFrame = styled(motion.img)`
  position: absolute;
  margin-left: 70vw;
  margin-top: 0vh;
  width: 20vw;
`;

export const LogoOxeDin = styled(motion.img)`
  position: absolute;
  width: 3vw;
`;

export const LogoOxeTechLab = styled(motion.img)`
  width: 5vw;
`;

export const LogoOxeTechWork = styled(motion.img)`
  width: 5vw;
`;

export const LogoOxeTechHat = styled(motion.img)`
  width: 3vw;
`;

export const InovationMap = styled(motion.img)`
  position: absolute;
  width: 65vw;
`;

export const Text = styled(motion.div)`
  p {
    color: rgba(255, 255, 255, 0.7);
    font-size: 1.5em;

    transition: color 0.2s ease;
    &:hover {
      color: rgba(255, 255, 255, 1);
    }
  }
`;

export const navegator = styled(motion.p)``;

import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Tabs from '@mui/material/Tabs'

import Paper from '@mui/material/Paper';

export const BoxPubicoAlvo = styled('div')(({ theme }) => ({
  background: 'radial-gradient(98.59% 106.78% at 17.36% 11.16%, rgba(255, 255, 255, 0.42) 0%, rgba(255, 255, 255, 0.06) 100%)',
  padding: theme.spacing(1),
  borderRadius: '10px',
  margin: theme.spacing(4),
  textAlign: 'center',
  color: '#FFFFFF',
  flexGrow: 1,
}));

export const LeftContent = styled(Paper)(({ theme }) => ({
  backgroundColor: 'inherit',
  boxShadow: 'none',
  height: '100%',
  verticalAlign: 'middle',
  ...theme.typography.body2,
  padding: theme.spacing(2),
  margin: theme.spacing(2),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const Options = {
  optionsSkills: [
    {
      value: "Ajax",
    },
    {
      value: "Android",
    },
    {
      value: "Angular",
    },
    {
      value: "Apache",
    },
    {
      value: "Apex",
    },
    {
      value: "API",
    },
    {
      value: "APL",
    },
    {
      value: "Arduino",
    },
    {
      value: "ASP",
    },
    {
      value: "AWS",
    },
    {
      value: "Bash",
    },
    {
      value: "Big Data",
    },
    {
      value: "Bootstrap",
    },
    {
      value: "C",
    },
    {
      value: "C#",
    },
    {
      value: "C++",
    },
    {
      value: "CakePHP",
    },
    {
      value: "Cloud",
    },
    {
      value: "CSS",
    },
    {
      value: "Django",
    },
    {
      value: "Docker",
    },
    {
      value: "Elixir",
    },
    {
      value: "ES6",
    },
    {
      value: "Express",
    },
    {
      value: "Firebase",
    },
    {
      value: "Flutter",
    },
    {
      value: "Git",
    },
    {
      value: "Go",
    },
    {
      value: "Google Cloud",
    },
    {
      value: "GraphQL",
    },
    {
      value: "HTML",
    },
    {
      value: "HTTP",
    },
    {
      value: "Ionic",
    },
    {
      value: "Java",
    },
    {
      value: "JavaScript",
    },
    {
      value: "jQuery",
    },
    {
      value: "JSON",
    },
    {
      value: "JSX",
    },
    {
      value: "Kanban",
    },
    {
      value: "Kotlin",
    },
    {
      value: "Kubernetes",
    },
    {
      value: "Laravel",
    },
    {
      value: "MongoDB",
    },
    {
      value: ".NET",
    },
    {
      value: "Nginx",
    },
    {
      value: "Node.js",
    },
    {
      value: "NoSQL",
    },
    {
      value: "NPM",
    },
    {
      value: "PHP",
    },
    {
      value: "PLSQL",
    },
    {
      value: "PostgreSQL",
    },
    {
      value: "Python",
    },
    {
      value: "ReactJS",
    },
    {
      value: "React Native",
    },
    {
      value: "Redis",
    },
    {
      value: "Redux",
    },
    {
      value: "RESTful",
    },
    {
      value: "Ruby on Rails",
    },
    {
      value: "SCRUM",
    },
    {
      value: "SQL",
    },
    {
      value: "Styled-Components",
    },
    {
      value: "Swift",
    },
    {
      value: "Testes automatizados",
    },
    {
      value: "Testes Unitários",
    },
    {
      value: "TypeScript",
    },
    {
      value: "UML",
    },
    {
      value: "Unity",
    },
    {
      value: "Vue",
    },
    {
      value: "Vue.js",
    },
    {
      value: "Yarn",
    },
  ],
  optionsCarrer: [
    {
      value: "Analise de Sistemas",
    },
    {
      value: "Ciência de Dados",
    },
    {
      value: "Gestão de Projetos",
    },
    {
      value: "Analista de Testes",
    },
    {
      value: "DevOps",
    },
    {
      value: "Desenvolvimento Mobile",
    },
    {
      value: "Desenvolvimento Full-Stack",
    },
    {
      value: "Desenvolvimento Backend",
    },
    {
      value: "Desenvolvimento Frontend",
    },
  ],
  optionsGender: [
    {
      value: "Homem Cis"
    },
    {
      value: "Homem Trans"
    }, 
    {
      value: "Mulher Cis"
    },
    {
      value: "Mulher Trans"
    },
    {
      value: "Desconhecido"
    }
  ],
  ocupation: [
    {
      value: "Estudante de Ensino Médio",
    },
    {
      value: "Estudante de Ensino Superior",
    },
    {
      value: "Professor(a)",
    },
    {
      value: "Profissional de Educação",
    },
    {
      value: "Profissional em Geral",
    },
  ],

  qualifications: [
    {
      label: "Graduação em andamento",
      value: "Graduação em andamento",
    },
    {
      label: "Graduação concluída",
      value: "Graduação concluída",
    },
    {
      label: "Mestrado em andamento",
      value: "Mestrado em andamento",
    },
    {
      label: "Mestrado concluído",
      value: "Mestrado concluído",
    },
    {
      label: "Doutorado em andamento",
      value: "Doutorado em andamento",
    },
    {
      label: "Doutorado concluído",
      value: "Doutorado concluído",
    },
    {
      label: "Ensino técnico em andamento",
      value: "Ensino técnico em andamento",
    },
    {
      label: "Ensino técnico concluído",
      value: "Ensino técnico concluído",
    },
    {
      label: "Ensino médio em andamento",
      value: "Ensino médio em andamento",
    },
    {
      label: "Ensino médio concluído",
      value: "Ensino médio concluído",
    },
    {
      label: "Ensino médio técnico em andamento",
      value: "Ensino médio técnico em andamento",
    },
    {
      label: "Ensino médio técnico concluído",
      value: "Ensino médio técnico concluído",
    },
  ],

  languages: [
    { value: "Português" },
    { value: "Inglês" },
    { value: "Espanhol" },
    { value: "Russo" },
    { value: "Francês" },
    { value: "Japonês" },
    { value: "Mandarim Chinês" },
    { value: "Árabe padrão" },
    { value: "Hindi" },
    { value: "Bengali" },
    { value: "Indonésio" },
  ],

  modalities: [
    { label: "Híbrido", value: "Híbrido" },
    { label: "Presencial", value: "Presencial" },
    { label: "Remoto", value: "Remoto" },
  ],

  types: [
    { label: "Estágio", value: "Estágio" },
    { label: "Meio período", value: "Meio período" },
    { label: "CLT", value: "CLT" },
    { label: "Voluntário", value: "Voluntário" },
    { label: "Temporário", value: "Temporário" },
    { label: "Jovem Aprendiz", value: "Jovem Aprendiz" },
    { label: "Tempo integral", value: "Tempo integral" },
    { label: "Contrato", value: "Contrato" },
  ],

  formationTypes: [
    { value: "Ensino Fundamental" },
    { value: "Ensino Médio" },
    { value: "Graduação" },
    { value: "Pós-Graduação" },
    { value: "Curso Técnico" },
  ],

  experienceTypes: [
    { value: "Autônomo" },
    { value: "CLT" },
    { value: "Contratação PJ" },
    { value: "Voluntário" },
    { value: "Bolsa" },
    { value: "Jovem Aprendiz" },
    { value: "Estágio" },
  ],

  editalStatuses: ["Fechado", "Aberto"],

  subscriptionStatuses: ["Em análise", "Validado", "Rejeitado"],


  contratacoesStatuses: ["Rejeitado", "Em análise", "Contratado"],

  jobsStatuses: [
    "Em análise",
    "Aberta",
    "Preenchida",
    "Encerrada",
    "Encerrada pela empresa",
    "Rejeitada",
  ],

  turmasStatuses: ["Encerrada", "Em seleção", "Iniciada"],
  inscricoesOXLStatuses: ["Não selecionado", "Em análise", "Selecionado", "Matriculado"],

  profileResponsabilites: {
    "admin": "10",
    "aluno": "0",
    "sudo": "100"
  },

  matriculasStatuses: [
    "Em análise",
    "Validada",
    "Rejeitada",
  ],

  cargasHorariasStatuses: [
    "Não cumpriu",
    "Cumpriu",
    "Não foi computado ainda",
  ],
};

export default Options;

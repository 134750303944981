import React from "react";
import { Box, Container } from "@mui/material";
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import * as palette from "../../utils/styledVariables"
import helperTexts from '../../utils/helperTexts';

const style = {
    padding: "16px",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    fontSize: "26px",
    color: palette.preto,
    fontStyle: "normal",
    fontWeight: 450,
    background: palette.laranjaOXLTransparente,
    textAlign: 'left',
    borderRadius: "15px",
    marginBottom: "10px"
}
function TableCard(props) {

    return (<Container sx={style}><Box sx={{display: "inline-block", width: "100%"}}>{props.name}{props.buttonsBar}</Box>
        < Box height="90%">
           
            <DataGrid rows={props.rows}
                columns={props.columns}
                components={{ Toolbar: GridToolbar }}
                columnVisibilityModel={{ id: false }}
                sx={{
                    background: palette.profileWhiteShadeTransp, borderRadius: '15px',
                    "& .MuiDataGrid-toolbarContainer": {
                        '& .MuiButton-text': {
                            color: palette.laranjaIntermediario2,
                        },

                    }, "& .MuiDataGrid-columnHeaderTitle": {
                        color: palette.preto,

                    }
                    , "& .MuiDataGrid-cellContent": {
                        color: palette.preto,

                    }
                }}
                localeText={helperTexts.dataGridToolbarText}
            />
        </Box >
    </Container>)
}

export default TableCard;
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import api from "../../services/api";
import Box from "@mui/material/Box";


import Main from "./Main";

import ModalFirstTime from "./ModalFirstTime";

import { Container } from "./styles";
import Sidebar from "./Sidebar/Sidebar";
import {labProfileRegister} from "../../store/modules/user/actions";
import { laranjaSecundario } from "../../utils/styledVariables";

function DashboardAluno() {
  
  const profile = useSelector((state) => state.user.profile);
  const [sidebarCollapsed, setSidebarCollapsed] = useState(false);
  const [isCoordenador, setIsCoordenador] = useState(false);

  const dispatch = useDispatch();

  const [width, setWidth] = React.useState(window.innerWidth);

  const [open, setOpen] = useState(false)

  const onMobile = width <= 600;


  useEffect(() => {
      if (!localStorage.getItem('open')) {
        setOpen(true);
        localStorage.setItem('open', true);
      }
  
      // if (profile) {
      //     api.get(`/laboratorio/lab-by-coordenador?coord_id=${profile.id}`).then((response) => {
      //       dispatch(labProfileRegister({ isCoordenador: true, data: response.data }));
      //     }).catch((error) => {
      //       api.get(`/agente/agente-id?alunoId=${profile.id}`).then((response) => {
      //         dispatch(labProfileRegister({ isCoordenador: false, data: response.data }));
      //       }).catch((error) => {
      //         console.log(error);
      //       })
      //       console.log(error);
      //     })          
      // }
  }, []);



  const handleCloseModal = () => {
    setOpen(false);
  }

  return (
    <Container>
      <ModalFirstTime open={open} close={handleCloseModal} />
      <Box
        sx={{
          backgroundColor: laranjaSecundario,//"rgb(142 36 170)",
          display: "flex",
        }}
      >
        <Sidebar
          onMobile={onMobile}
          collapsed={sidebarCollapsed}
          setCollapsed={setSidebarCollapsed}
          setOpenModal={() => setOpen(true)}
        />
        <Main
          styles={{ marginLeft: onMobile || sidebarCollapsed ? "auto" : "240px" }}
        />
      </Box>
    </Container>
  );
}

export default DashboardAluno;

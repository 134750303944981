import styled from 'styled-components'
import { motion } from 'framer-motion'

export const Container = styled.div``

export const SectionOne = styled.div`
  -webkit-user-drag: none;
  background: linear-gradient(
    180deg,
    rgba(116, 31, 138, 0.9) 35%,
    rgba(0, 0, 99, 1) 100%
  );
  background-size: cover;

  overflow: hidden;
  height: 100vh;
  /* margin-Top */
  padding: 10vh;
  display: flex;
  text-align: center;
  position: relative;
  p {
    color: rgba(255, 255, 255, 1);
    font-size: 1.5em;
  }
`

export const TriangleFrame = styled(motion.img)`
  position: relative;
  left: 40rem;
  bottom: 30em;
  width: 120vw;
  height: 150vh;
`

export const LogoOxeDin = styled(motion.img)`
  position: absolute;
  width: 15vw;
`

export const LogoOxeTechLab = styled(motion.img)`
  width: 28vw;
`

export const LogoOxeTechWork = styled(motion.img)`
  width: 40vw;
`

export const LogoOxeTechHat = styled(motion.img)`
  width: 28vw;
`

export const InovationMap = styled(motion.img)`
  position: absolute;
  width: 50vw;
`

export const Text = styled(motion.div)`
  position: absolute;
  left: 13%;
  bottom: 20%;
  width: 60%;
  a {
    color: rgba(255, 255, 255, 0.7);
    font-size: 120%;
    transition: color 0.2s ease;
    &:hover {
      color: rgba(255, 255, 255, 1);
    }
  }
  .typ {
    @media (min-width: 1500px) {
      position: relative;
      bottom: 80%;
      width: 70%;
      font-size: 200%;
    }
  }
  .tool {
    position: relative;
    right: 48%;
    bottom: 20%;
    @media (min-width: 1500px) {
      font-size: 160%;
      right: 48%;
    }
  }
  .logo {
    position: absolute;
    bottom: 40vh;
    left: 10vw;
    @media (min-width: 1500px) {
      left: 2vw;
    }
  }
`

export const navegator = styled(motion.p)``

import React, { useEffect, useState } from "react";
import { Box, Fab } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import Checkbox from "@mui/material/Checkbox";
import api from "../../../../services/api";
import { blue, green, red } from "@mui/material/colors";
import RegistrarFrequencia from "../../../components/Bolsista/Modais/RegistrarFrequencia";
export default function Frequencia({ turmaId }) {
  const [alunosComFrequencia, setAlunosComFrequencia] = useState([]);
  const [frequenciaEnviar, setFrequenciaEnviar] = useState(false);

  useEffect(() => {
    api
      .get(`/oxetechedu/inscricoes/${turmaId}`)
      .then((response) => {
        const rows = response.data?.map((inscricao) => {
          return {
            id: inscricao.id,
            nome: inscricao.MatriculaOxetechEdu.Aluno.name,
            email: inscricao.MatriculaOxetechEdu.Aluno.email,
            presente: inscricao.presente,
          };
        });
        setAlunosComFrequencia(rows);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);



  const columns = [
    {
      field: "nome",
      headerName: "Nome",
      width: 400,
    },
    {
      field: "email",
      headerName: "Email",
      width: 400,
    },
    {
      field: "presente",
      headerName: "Presente",
      width: 380,
      renderCell: (params) => (
        <Checkbox
          checked={params.row.presente}
          onChange={(e) => {
            const newStatus = e.target.checked ? true : false;
            const updatedAluno = { ...params.row, presente: newStatus };
            setAlunosComFrequencia((prevAlunos) =>
              prevAlunos.map((aluno) =>
                aluno.id === updatedAluno.id ? updatedAluno : aluno
              )
            );
          }}
          sx={{
            "&.Mui-checked": {
              color: params.row.presente ? green[500] : "initial",
            },
          }}
        />
      ),
    },
  ];
  return (
    <Box sx={{ height: "70vh", width: "95%", background: "white" }}>
      <DataGrid
        rows={alunosComFrequencia}
        columns={columns}
        disableRowSelectionOnClick={true}
        components={{
          Toolbar: ({ components, ...props }) => (
            <div
              {...props}
              style={{
                backgroundColor: "white",
              }}
            >
              {components}
            </div>
          ),
        }}
      />

      <Box
        sx={{
          display: "flex",
          position: "fixed",
          right: "20px",
          bottom: "55px",
          zIndex: 9999,
        }}
      >
        <Fab
          onClick={() => setFrequenciaEnviar(true)}
          variant="extended"
          sx={{
            background: "rgba(212, 109, 13, 1)",
            color: "white",
            "&:hover": { background: "rgba(212, 109, 13, 0.7)" },
           
          }}
        >
          Registrar Frequência
        </Fab>
      </Box>

      <RegistrarFrequencia
        open={frequenciaEnviar}
        handleClose={() => setFrequenciaEnviar(false)}
        idTurma={turmaId}
        frequencia={alunosComFrequencia}
      />
    </Box>
  );
}

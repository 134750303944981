import React, {useState} from 'react'
import PropTypes from 'prop-types'
import SwipeableViews from 'react-swipeable-views'
import { autoPlay } from 'react-swipeable-views-utils';
import { useTheme } from '@mui/material/styles'
import AppBar from '@mui/material/AppBar'
import Tab from '@mui/material/Tab'
import Typography from '@mui/material/Typography'
import {Box, Button} from '@mui/material'
import { Tabline, useStyles, Title,ButtonLab } from './styles'
import Tabs from '@mui/material/Tabs'
import oxeTechLabLogo from '../../assets/homeTriangle/oxeTechLabLogoON.png'
import { Link } from 'react-router-dom'

// import oxeTechWorkLogo from '../../assets/homeTriangle/oxeTechWork.png'
import oxeDinLogo from '../../assets/logo_oxeDIN 3.png'
import oxeTechWorkLogo from '../../assets/LOGO_OXETECH_WORK.png'

import iconeOxetechLab from '../../assets/iconeOxetechLab.png'
import iconeOxedin from '../../assets/iconeOxeDin.png'

import iconeOxetechWork from '../../assets/iconeOxetechWork.png'
import 'animate.css'
import { laranjaMain } from '../../utils/styledVariables';



function TabPanel(props) {
  const { children, value, index, classes, ...other } = props
  

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }} className={classes}>
          {children}
        </Box>
      )}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
}

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`
  }
}

const oxetechLab = value => {
  if (value === 0) {
    return <img src={oxeTechLabLogo} alt="Logo do OxeTechLab" width="80%" />
  } else {
    return <img src={oxeTechLabLogo} alt="Logo do OxeTechLab" width="60%" />
  }
}
const oxedin = value => {
  if (value === 1) {
    return <img src={oxeDinLogo} alt="Logo do OxeDin" width="50%" />
  } else {
    return <img src={oxeDinLogo} alt="Logo do OxeDin" width="40%" />
  }
}
const oxetechWork = value => {
  if (value === 2) {
    return <img src={oxeTechWorkLogo} alt="Logo do OxeTechWork" width="90%" />
  } else {
    return <img src={oxeTechWorkLogo} alt="Logo do OxeTechWork" width="70%" />
  }
}
export default function FullWidthTabs() {
  const theme = useTheme()
  const [value, setValue] = React.useState(0)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  const handleChangeIndex = index => {
    setValue(index)
  }
  const classes = useStyles()
  const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

  return (
    <Tabline>
      <AppBar position="static" className={classes.app}>
        <Tabs
          value={value}
          onChange={handleChange}
          classes={{
            indicator: classes.indicator
          }}
          // className={classes.menu}
          // variant="fullWidth"
          // aria-label="full width tabs example"
        >
          <Tab
            label={oxetechLab(value)}
            // {...a11yProps(0)}
            className={value === 0 ? classes.tab1 : classes.taboff}
          ></Tab>
          <Tab
            label={oxedin(value)}
            className={value === 1 ? classes.tab2 : classes.taboff}
            // {...a11yProps(1)} className={classes.tab2}
          />
          <Tab
            label={oxetechWork(value)}
            className={value === 2 ? classes.tab3 : classes.taboff}
            // {...a11yProps(2)} className={classes.tab3}
          />
        </Tabs>
      </AppBar>
      <AutoPlaySwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={value}
        interval={10000}
        animateTransitions={true}
        onChangeIndex={handleChangeIndex}
      >
        <TabPanel
          value={value}
          index={0}
          dir={theme.direction}
          classes={classes.oxeLab}
        >
          <Title>
            <Box className="content">
              <h1 className="titulo">Um lab mais perto de você!</h1>
              <h4 className="subtitulo">
                O OxeTech Lab é um programa cujo propósito é interiorizar e
                popularizar a ciência, a tecnologia e a inovação através de
                cursos presenciais gratuitos, bem como tem o intuito de garantir
                espaços moderno e organizados, com vistas a estabelecer uma
                maior absorção de conhecimento por parte dos alunos que fazem
                uso do programa.
              </h4>
            <Link to="/oxetechlab">
                <ButtonLab  sx={{backgroundColor: laranjaMain, mt: '2%'}} variant="contained" >Turmas disponíveis</ButtonLab>
            </Link>
            </Box>
            <img
              src={iconeOxetechLab}
              alt="icone do OxetechLab"
              style={{
                width: '30%',
                animation: 'fadeIn',
                animationDuration: '2s'
              }}
            ></img>
          </Title>
        </TabPanel>
        <TabPanel
          value={value}
          index={1}
          dir={theme.direction}
          classes={classes.oxeDin}
        >
          <Title>
            <Box className="content">
              <h1 className="tituloDin">Encontre profissionais capacitados</h1>
              <h4 className="subtitulo ">
                O OxeDIN é a plataforma de contato entre alunos do programa
                OxeTech Lab e empresas.
              </h4>
              <h4
                className="subtitulo "
                style={{
                  color: '#fff',
                  textDecoration: 'underline',
                  animation: 'fadeIn',
                  animationDuration: '2s'
                }}
              >
                Quer achar um desenvolvedor e não sabe onde encontrar?
              </h4>
              <h4 className="subtitulo ">
                Anuncie sua vaga, encontre profissionais qualificados e
                acompanhe os cursos feitos por alunos! Se inscreva na plataforma
                e faça parte do Ecossistema de Inovação de Alagoas, conhecendo
                os produtos, processos e serviços produzidos em nosso Estado.
              </h4>
              <Link to="/oxedin">
                <Button sx={{backgroundColor: laranjaMain}} variant="contained" >Confira as vagas! </Button>
              </Link>
            </Box>
          </Title>
          <img
            src={iconeOxedin}
            alt="icone do OxetechDin"
            style={{
              width: '30%',
              marginRight: '8vw',
              animation: 'fadeIn',
              animationDuration: '2s'
            }}
          ></img>
        </TabPanel>
        <TabPanel
          value={value}
          index={2}
          dir={theme.direction}
          classes={classes.oxeWork}
        >
          <Title>
            <Box className="content">
              <h1 className="titulo">Encontre vagas de trabalho</h1>
              <h4 className="subtitulo ">
                O OxeTech Work nasce com o propósito de fomentar a economia
                alagoana, através de um programa que integre - de maneira direta
                - a capacitação de alunos com vagas de trabalho no mercado
                tecnológico e inovador, transferindo renda, gerando empregos e
                qualificando a população. O diferencial do OxeTech Work:
              </h4>
              <h4 className="listas">
                <ul>
                  <li>Portfólio com cursos mais específicos;</li>
                  <li>
                    Qualificação intensiva com Universidades, ICTs e empresas
                    locais;
                  </li>
                  <li>
                    Bolsa de estudos de 6 meses + contrato de trabalho de 12
                    meses.
                  </li>
                </ul>
              </h4>
              <Link to="/oxe-tech-work">
                <Button  sx={{backgroundColor: laranjaMain}} variant="contained">Confira a página!</Button>
              </Link>
            </Box>
            <img
              src={iconeOxetechWork}
              alt="icone do OxetechWork"
              style={{
                width: '30%',
                animation: 'fadeIn',
                animationDuration: '2s'
              }}
            ></img>
          </Title>
        </TabPanel>
      </AutoPlaySwipeableViews>
    </Tabline>
  )
}

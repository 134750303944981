import {
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Collapse,
} from "@mui/material"
import {
  ExpandLess as ExpandLessIcon,
  ExpandMore as ExpandMoreIcon,
} from "@mui/icons-material"
import { GloboOxlIcon } from "./GloboOxlIcon";
import { LabListItem } from "./LabListItem";
import { useState } from "react";

export const LabsList = ({ labs }) => {
  const [showLabs, setShowLabs] = useState(false);
  
  const handleShowLabs = (event) => {
    event.stopPropagation();
    setShowLabs(!showLabs);
  };

  return (
    <List disablePadding>
      <ListItemButton onClick={handleShowLabs} style={{ width: "100%" }}>
        <ListItemIcon sx={{minWidth: "20px", }}>
          <GloboOxlIcon />
        </ListItemIcon>
        <ListItemText primary="Perfil Laboratório" sx={{ marginLeft: "5px" }}/>
        {showLabs ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </ListItemButton>
      <Collapse in={showLabs} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {labs.map((lab) => (
            <LabListItem lab={lab} key={lab.lab_id} />
          ))}
        </List>
      </Collapse>
    </List>
  );
};

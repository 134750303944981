import { makeStyles} from '@mui/styles';
import { createTheme } from '@mui/material/styles';
import styled from "styled-components";
import { Tooltip } from "@mui/material";
import { withStyles } from '@mui/styles';
const theme = createTheme(); 

export const useStyles = makeStyles({
  videoCard: {
    backgroundColor:'#f2f2f2',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
    padding: '20px',
    borderRadius: '8px',
    height: '264px',
    maxWidth: '600px',
    marginBottom: '10px', 
  },
  thumbnailImage: {
    maxWidth: '500px',
    height: '200px',
    borderRadius: '8px',
  },
  playerWrapper: {
    width: '60%',
    height: '20rem',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      height: 'auto',
      marginBottom: '10px',
    },
  },
  informationContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    marginLeft: '1rem',
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
      marginTop: '1rem',
    },
  },
  informationText: {
    marginBottom: '10px',
    fontSize: '16px', 
    padding: '16px',
  },
  videoTitle: {
    fontWeight: 'normal',
    fontSize:'24px',
    marginTop: '10px',
    marginBottom: '10px',
    marginLeft: '30px',
    marginRight: '30x'
  },
  moduloTitle: {
    fontWeight: 'bold',
    fontFamily: 'Montserrat',
    fontSize:'36px',
    marginTop: '10px',
    marginBottom: '20px',
    marginLeft: '30px',
    marginRight: '30x'
  },
  playIcon: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    fontSize: '3rem', 
    color: 'white',
  },
  
});

export const Logo = styled.div`
  width: 100%;
  padding: 1%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  .img {
    width: 30%;
    
    @media (max-width: 700px) {
      width: 50%;
    }
  }
  .subtitulo {
    font-family: 'Karla', sans-serif;
    color: black;
    font-size: 1.2rem;
    font-weight: bold;
    text-align: center;
  }
`
export const Container = styled.div`

  width: 100%;
  padding: 3%;
  /* border: 1px solid red; */

  .listItemText {
  font-size: 2em; /* Ajuste o valor conforme necessário */
}
`

export const CustomTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: 'white', 
    color: 'black', 
    boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)', 
    // width: '40'
  },
}))(Tooltip);
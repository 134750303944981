import React from "react";
import {
  PDFDownloadLink,
  Document,
  Page,
  Text,
  View,
  Image,
  StyleSheet,
  //   Font
} from "@react-pdf/renderer";
import template from "../../assets/templates/CertificadoOXL.jpg";
import * as pallete from "../../utils/styledVariables";
import DownloadIcon from '@mui/icons-material/Download';
const buttonStyles = {
  backgroundColor: pallete.laranjaOxeLab,
  color: "white",
  padding: "7px 20px",
  borderRadius: "5px",
  textDecoration: "none",
  display: "inline-block",
};

const styles = StyleSheet.create({
  page: {
    flexDirection: "row",
    backgroundColor: "#FFFFFF",
  },
  section: {
    margin: 0,
    padding: 0,
    // flexGrow: 1,
  },
  section1: {
    margin: 0,
    padding: 0,
    position: "absolute",
    top: 200,
    left: 60,
    width: "85%",
    // flexGrow: 1,
  },
  title: {
    fontSize: 24,
    marginBottom: 10,
    textAlign: "justify",
    lineHeight: 1.5,
  },
  text: {
    fontSize: 12,
    display: "inline",
  },
  boldText: {
    fontFamily: "Helvetica-Bold",
  },
});

const MyPDFContent = ({
  nome,
  curso,
  dataInicio,
  dataEncerramento,
  cargaHoraria,
}) => (
  <Document>
    <Page size="A4" orientation="landscape" style={styles.page}>
      <View style={styles.section}>
        <Image style={styles.image} src={template} />
        <View style={styles.section1}>
          <Text style={styles.title}>
            Certificamos que <Text style={styles.boldText}>{nome}</Text>{" "}
            participou do Curso de <Text style={styles.boldText}>{curso}</Text>,
            de <Text style={styles.boldText}>{dataInicio}</Text> à{" "}
            <Text style={styles.boldText}>{dataEncerramento}</Text>, com{" "}
            <Text style={styles.boldText}>
              carga horária de {cargaHoraria}h
            </Text>
            .
          </Text>
        </View>
      </View>
    </Page>
  </Document>
);

export default function DownloadPDFButton({
  nome,
  curso,
  dataInicio,
  dataEncerramento,
  cargaHoraria,
}) {
  return (
    <PDFDownloadLink
      style={buttonStyles}
      document={
        <MyPDFContent
          nome={nome}
          curso={curso}
          dataInicio={dataInicio}
          dataEncerramento={dataEncerramento}
          cargaHoraria={cargaHoraria}
        />
      }
      fileName="certificado.pdf"
    >
      {({ blob, url, loading, error }) =>
        loading ? (
          "Carregando documento..."
        ) : (
          <div style={{display: 'flex', alignItems:'center', justifyContent: 'center'}} >
			<DownloadIcon/>
            <h4 style={{color: 'white', margin: '2px 0 0 2px'}} >Baixar Certificado</h4>
          </div>
        )
      }
    </PDFDownloadLink>
  );
}


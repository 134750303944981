import React, { useState, useEffect } from 'react';
import { Card, Badge, Comment, Avatar, Button, Input, Upload, Select, Space, Typography  } from 'antd';
import { AiFillStar } from 'react-icons/ai';
import { Row, Col } from 'antd';
import { ReactComponent as Send } from '../../assets/iconesOxeLabMural/send.svg';
import { ReactComponent as Like } from '../../assets/iconesOxeLabMural/like.svg';
import { ReactComponent as CommentUser } from '../../assets/iconesOxeLabMural/comment.svg';
import { ReactComponent as Clock } from '../../assets/iconesOxeLabMural/clock.svg';
import moment from 'moment';
import 'moment/locale/pt-br'; 

const { Dragger } = Upload;

const underlineButtonClass = 'underline-button';

const CardPosts = ({content, creator, comments, date }) => {
  const cardStyle = {
    borderRadius: '1rem 1rem 0 0',
    borderColor: 'rgba(0, 0, 0, 0.2)',
    marginBottom: '1rem',
    width: '100%',
    height: 'auto',
    bordered: 'false',
    // border:'1px solid red'
  };
  
  const creatorContainerStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding:"1rem 0"
  };
  
  const contentStyle = {
    fontSize: '1rem',
    color: '#000',
  };
  
  const avatarStyle = {
    marginRight: '1rem',
    marginTop: '0.5rem',
    marginBottom: '0',
  };

  const hrStyle = {
    margin: '0', 
    border: 'none', 
    borderTop: '1px solid #ddd',
    width:'100%'
  };

  const buttonStyle = {
    backgroundColor: 'transparent',
    border: 'none',
    boxShadow: 'none',
  };

  const [showComments, setShowComments] = useState(false);

  const toggleComments = () => {
    setShowComments(!showComments);
  };

  const [showCommentField, setShowCommentField] = useState(false);
  const [commentText, setCommentText] = useState('');

  const handleCommentButtonClick = () => {
    setShowCommentField(!showCommentField);
  };

  const handleCommentChange = (e) => {
    setCommentText(e.target.value);
  };


  const handleCommentSubmit = () => {
    console.log(commentText);
    setCommentText('');
  };

  return (
    <Card bodyStyle={{ padding: '16px 0' }} style={cardStyle}>
      
      <Row gutter={[16, 16]} >
      <Col xs={24} sm={24} md={20} lg={24}>
      <div style={{padding:'10px 17px'}}>
        
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent:'space-between' }}>
        <Badge showZero>
          <div style={{ backgroundColor: '#D46D0D38', padding: '2px 3px 3px 2px', borderRadius: '6px', display: 'flex', alignItems: 'center' }}>
            <AiFillStar style={{ marginRight: 4, color: '#D46D0D', fontSize: 12 }} />
            <Typography.Text style={{ color: '#000', fontSize: 12}}>Organizador</Typography.Text>
          </div>
        </Badge>
        <div style={{ marginLeft: '10px',  fontSize: 12, color: '#888' }}>
          
          <Clock/>
          
          {moment(date).format('DD [de] MMMM [de] YYYY')}
          
        </div>
    </div>
      
      </div>
      <div style={{padding:'10px 17px'}}>
        <p style={contentStyle}>{content}</p>
        </div>
        

        <div style={hrStyle}></div>
        <>
        <div style={{padding:'10px 17px'}}>
        <div style={creatorContainerStyle}>
          <div style={{ display: 'flex', alignItems: 'center'}}>
            <Avatar src={creator.avatar ?? ''} style={{padding: '0', ...avatarStyle }} />
            <span style={{ paddingTop: '4px' }}>{creator.name ?? ''}</span>
          </div>
          <div style={{ marginLeft: 'auto', justifyContent:'space-between' }}>
            <Button type="primary" ghost onClick={handleCommentButtonClick} style={buttonStyle}>
              <CommentUser style ={{width: '14px', marginRight:'0px',
              height: '14px',
              verticalAlign: 'middle',}}/>
            </Button>
          </div>

          <div style={{ justifyContent: 'flex-end'}}>
        {/* <Button type="primary" ghost onClick={handleCommentSubmit} style={buttonStyle}>
        <Like />
      </Button> */}
    </div>
        
      </div></div>
      <div style={hrStyle}></div>
      
          </>
        </Col>
      <div style={{padding:'5px 8px'}}>
      {comments.length > 0 && (
        <div style={{padding:'10px 15px'}}>
          {showComments ? (
            <>
              {comments.map((comment, index) => (
                <Comment
                  key={index}
                  avatar={<Avatar src={comment.avatar} />}
                  content={<p>{comment.text}</p>}
                  author={<span>{comment.name}</span>}
                  datetime={
                    moment(comment.date).format('DD [de] MMMM [de] YYYY')
                  } 
                  
                />
              ))}
              <Button type="text" onClick={toggleComments} className={`${underlineButtonClass} active`}>
                esconder comentários
              </Button>
            </>
          ) : (
            <Button type="text" onClick={toggleComments} className={underlineButtonClass}>
            {comments.length} comentários
            </Button>
          )}
        </div>
      )}</div>
      {!showCommentField && (
        <>
        <Space.Compact style={{ padding:'0 15px' ,width: '100%'}}>
          <Input
            placeholder="Digite seu comentário..."
            value={commentText}
            onChange={handleCommentChange}
            style={{ borderTopLeftRadius: '6px', borderBottomLeftRadius: '6px', borderTopRightRadius: 0, borderBottomRightRadius: 0, borderRight: '1px solid transparent'  }}
            
          />
          <Button type="primary"ghost onClick={handleCommentSubmit} style={{ backgroundColor: 'transparent', borderRight: '1px solid #ddd', borderTop: '1px solid #ddd',borderLeft: '1px solid transparent', borderBottom: '1px solid #ddd', borderTopRightRadius: '6px', borderBottomRightRadius: '6px' }}>
            <Send style={{ width: '14px', height: '14px', verticalAlign: 'middle' }}/>
          </Button></Space.Compact>
        </>
      )}
      </Row>
    </Card>
  );
};

export default CardPosts;

import React, { useEffect, useState } from 'react';
import Notificacao from '../Notificacoes/notificacao';
import api from "../../services/api";
import { PainelNotificacoes, Titulo, Notificacoes, HeaderNotificacoes, MensagemSemNotificacoes, Mensagem } from './styles';
import Badge from '@mui/material/Badge';


const Painel = ({ anchorNotif, handleCloseNotif, notificacoes }) => {
  return (
    <div>
      <PainelNotificacoes
        anchorEl={anchorNotif}
        open={Boolean(anchorNotif)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        onClose={handleCloseNotif}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        keepMounted
      >

        <Titulo>Notificações</Titulo>

        <Notificacoes>
          {notificacoes.length === 0 ? (
            <MensagemSemNotificacoes>
              <Mensagem>Você ainda não possui notificações</Mensagem>
            </MensagemSemNotificacoes>
          ) : (
            notificacoes.map(({ id, title, content, seen }, index) =>
              <Badge 
                key={index}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }} 
                color="secondary"
                badgeContent=" "
                overlap="rectangular"
                variant="dot"
                invisible={seen}
                style={{ marginTop: '12px' }}>
                <Notificacao id={index} titulo={title} descricao={content}/>
              </Badge>
            ))
            }
        </Notificacoes>

      </PainelNotificacoes >
    </div>
  );
};

export default Painel;

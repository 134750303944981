import React, {useEffect} from 'react';
import { withRouter } from 'react-router-dom';
import { useMediaQuery } from '@mui/material';
import {
  AppBar,
  Box,
  Container,
  IconButton,
  Grid,
  Tooltip,
  Typography
} from '@mui/material';
import { Button } from 'antd';
import MenuIcon from '@mui/icons-material/Menu';
import logo from  '../../assets/homeTriangle/oxeTechLabLogoON.png';
import { Link, NavLink } from 'react-router-dom';
import { ButtonOn, ButtonOff, Cadastrar } from './styles';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { ReactComponent as Logo } from '../../assets/iconesOxeLabMural/oxetechlablogo.svg';
import moment from 'moment';
import 'moment/locale/pt-br';



const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 800,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
});

const ResponsiveAppBar = (props) => {
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const { history, course, courseName, teacherName, date, closingDate } = props;
  


  const handleMenuClick = (pageURL) => {
    history.push(pageURL);
  };

  return (
    <ThemeProvider theme={theme}>
      <AppBar
         style={{
          background: 'linear-gradient(107.53deg, #D56D0D -14.59%, #B74E50 36.45%, #8E24AA 107.21%)',
          lineHeight: '50px',
        }}
        position="relative"
        elevation={0}
      >
        <Container maxWidth="100vw">
          <Box
            sx={{
              display: 'flex',
              justifyContent: isSmallScreen ? 'center' : 'space-between',
              flexDirection: isSmallScreen ? 'column' : 'row',
              // padding: '20px 20px 16px 34px', 
              p: '10px 5px',
            }}
          >
            <Box
              sx={{
                width: '60%',
                height: '70px',

              }}
            >
              <Typography variant="h6" component="span" color="inherit"  style={{
                  fontFamily: 'Karla',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  fontSize: isSmallScreen ? '2em': '2.5em',
                  // lineHeight: '15px',
                  position: 'absolute',
                  // width: '900px',
                  height: '10px',
                  // left: '68px',
                  // top: '58px',
                }}>
                {courseName} 
              </Typography>
              <br />
              <Typography variant="subtitle1" component="span" color="inherit"  style={{
                  fontFamily: 'Karla',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  fontSize: '16px',
                  color: 'rgba(255, 255, 255, 0.77)',
                  // lineHeight: '23px',
                  position: 'absolute',
                  // width: '503px',
                  // height: '25px',
                  // marginBottom: '15px'
                  // left: '68px',
                  // top: '103px',
                }} >
                {teacherName} | Período: {moment(date).format('DD/MM/YYYY')} à {moment(closingDate).format('DD/MM/YYYY')}
              </Typography>
            </Box>
            {/* ---------- Visível para Mobile ----------*/}
            <Box sx={{ flexGrow: 1, display: { xs: 'flex', sm: 'none' } }}>
              <Typography
                {...teacherName}
              >
              </Typography>
              
            </Box>

            {/* ---------- FIM Visível para Mobile ----------*/}
            <Box>
            {/* <Tooltip title="Logo OxeTechLab"> 
                  <div>
                  <Logo width='100px' />
                  </div>
              </Tooltip> */}
            </Box>
          </Box>
        </Container>
      </AppBar>
    </ThemeProvider>
  );
};

export default withRouter(ResponsiveAppBar);

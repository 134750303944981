import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import React, { createContext, useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import api from "../../services/api";
import history from "../../services/history";
import {
  signInFailure,
  signInSuccess,
  signOut,
} from "../../store/modules/auth/actions";
import Options from "../../utils/options";
const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const { signed } = useSelector((state) => state.auth);
  const [adminUser, setAdminUser] = useState(false);
  const [sudoUser, setSudoUser] = useState(false);
  const [error, setError] = useState("");
  const dispatch = useDispatch();

  const rehydrate = () => {
    if (signed) {
      api.get("/aluno/admin").then((res) => setAdminUser(res.data));
      api.get("/aluno/sudo").then((res) => setSudoUser(res.data));
    }
  };

  useEffect(() => {
    rehydrate();
  }, []);

  const handleLogout = () => {
    try {
      dispatch(signOut());
    } catch (error) {
      console.log("Erro");
    } finally {
      setAdminUser(false);
    }
  };

  const handleLogin = async ({ credential, password }) => {
    try {
      const response = await api.post("/usersessions", {
        credential,
        password,
      });

      const { token, labs_data, responsabilidade, ...data } = response.data;
      api.defaults.headers.Authorization = `Bearer ${token}`;

      dispatch(
        signInSuccess(token, data, {
          data: labs_data,
        })
      );

      const isAdmin =
        responsabilidade === Options.profileResponsabilites.admin ||
        responsabilidade === Options.profileResponsabilites.sudo;

      setAdminUser(isAdmin);
      setSudoUser(responsabilidade === Options.profileResponsabilites.sudo);
      isAdmin ? history.push("/dashadmin") : history.push("/dashboard");
    } catch (error) {
      setError(error.response.data.error);
      dispatch(signInFailure());
    }
  };

  return (
    <AuthContext.Provider
      value={{
        adminUser,
        sudoUser,
        logout: handleLogout,
        login: handleLogin,
      }}
    >
      {children}
      <Snackbar
        open={error?.length > 0}
        autoHideDuration={6000}
        onClose={() => setError("")}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        sx={{ mb: "5%" }}
      >
        <Alert
          onClose={() => setError("")}
          severity="warning"
          sx={{ width: "100%" }}
        >
          {error}
        </Alert>
      </Snackbar>
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);

import React, { useEffect, useState } from "react";
import { Grid, Paper, Typography, Modal, Box} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import * as palette from "../../utils/styledVariables"
import ClickAwayListener from '@mui/material/ClickAwayListener';
import formatDate from "../../utils/formartDate";
import TableCard from "../DashUserAdm/TableCard";
import api from "../../services/api";
import { differenceInYears } from 'date-fns';
const styles = {
    modal: {

        width: "50%",
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: "#f2f2f2",
        padding: "10px",
        textAlign: "center",
        borderRadius: "1vh",
        justifyContent: 'center'

    },

    paper: {
        width: "100%",
        margin: "5px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",

    },

    field: { padding: "5px" }

}

function ModalAlunosMatriculados(props) {
  const [open, setOpen] = useState(false);
  const [matriculados, setMatriculados] = useState([]);
  const [loading, setLoading] = useState(true);
  
  useEffect(() => {
    setLoading(true);
   
    if (props.turma_id !== null) {
      const fetchMatriculas = async () => {
        try {
          const responseMatriculas = await api.get(`/matriculas/turma/${props.turma_id}`);
          setMatriculados(responseMatriculas.data);
          setLoading(false);
        } catch(error) {
          console.log(error);
          setLoading(false);
        }
      };
    
      fetchMatriculas();
    } else {
      setLoading(false);
    }
  }, [props.turma_id]);
  

  const handleClose = () => {
    props.close();
  };

  const matriculadosHeader = [
    { field: "nome", headerName: "Aluno", width: 300 },
    { field: "idade", headerName: "Idade", width: 60},
    { field: "email", headerName: "E-mail", width: 150 },
    { field: "telefone", headerName: "Telefone", width: 150}
  ];

    const matriculadoRows = matriculados?.map((matriculado, index) => {

      const idade = differenceInYears(new Date(), new Date(matriculado.data_nascimento));
        return {
        id: matriculado.id,
        nome: matriculado.aluno,
        idade: idade,
        email: matriculado.email,
        telefone: matriculado.telefone
        };
    });


  const tables = [
    {
      name: "Alunos matriculados",
      columns: matriculadosHeader,
      rows: matriculadoRows
    },
]
  return (
    <Modal open={props.open}>
      <ClickAwayListener onClickAway={handleClose}>
        <Box>
          <IconButton size='large' sx={{ display: "flex", float: "right" }} component="label" onClick={handleClose}>
            <CloseIcon fontSize='large' sx={{ color: palette.profileWhiteShade }} />
          </IconButton>

          <Grid sx={styles.modal} container direction="row">
            <Grid container item xs={12} sm={12} md={12} lg={12}>
              <Paper sx={[{ backgroundColor: palette.laranjaOXLTransparente, height: "50px" }, styles.paper]}>
                <Typography sx={{ fontSize: "24px", color: palette.preto, fontFamily: "Poppins", fontWeight: 500 }}>Dados dos alunos</Typography>
              </Paper>
            </Grid>

            <TableCard props={tables[0]} loading={loading} />
           </Grid>

          
        </Box>
        </ClickAwayListener>
    </Modal>
  )
}

export default ModalAlunosMatriculados;
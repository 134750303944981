import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { 
  Breadcrumb,
  Tabs,
  Descriptions,
  Skeleton,
  Avatar,
  Card,
  Popconfirm,
  Button,
  message,
  Popover
} from 'antd';

import moment from "moment";

import { HomeOutlined, DeleteOutlined } from '@ant-design/icons';

import api from "../../services/api";
import history from "../../services/history";

import { Container, Content } from './styles';

function PageVagaAdm({ match }) {
  
  const { id } = match.params;

  const { TabPane } = Tabs;
  
  const { Meta } = Card;


  const [vaga, setVaga] = useState();
  const [loading, setLoading] = useState(false);

  const [candidatos, setCandidatos] = useState([]);

  useEffect(() => {
    const loadAluno = async () => {
      await api.get(`vagas/index?id=${id}`).then((response) => {
        setVaga(response.data.vaga);
        setCandidatos(response.data.candidatos);
        setLoading(true)
      });
    }
    loadAluno();
  }, [id]);

  const confirmDeleVaga = async (id) => {
    try {
      const response = await api.delete(`vagas/delete?id=${id}`);
      message.success(response.data.message);
      setTimeout(() => {
          history.push("/vagas")
      }, 1500);
  } catch (error) {
      if (error.response.status === 406 && error.response.data === "NO_AUTHORIZATION") {
          message.warning("Não é permitido excluir está vaga, pois ela tem candidatos!");
      } else {
          message.error(error.response.data);
      }
  }
  }


  return (
    <Container>
      <div>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to={{ pathname: "/" }}>
              <HomeOutlined />
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item >
            <Link to={{ pathname: "/vagas" }}>
              <span>Vagas</span>
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            {vaga ? vaga.titulo : < Skeleton />}
          </Breadcrumb.Item>
        </Breadcrumb>
      </div>

     
      { vaga ?
        <Content>
          <Popconfirm
            title="Deseja deletar essa vaga ?"
            onConfirm={() => confirmDeleVaga(vaga.id)}
            okText="Sim"
            cancelText="Não"
          >
            <Button
                  style={{
                      // background: "rgb(235, 91, 40)",
                      color: "#fff",
                      border: 0,
                      fontSize: "1.3rem",
                      fontWeight: "600",
                      position: "absolute",
                      right: "10vw",
                      background: "none"
                  }}
              >
                  {/* Deletar Vaga */}
                <Popover content="Deletar Vaga">
                  <DeleteOutlined style={{ fontSize: "2rem", color: "red" }} />
                </Popover>
              </Button>
          </Popconfirm> 
          <Descriptions 
            title={<h1>{vaga.titulo}</h1>} 
            size="middle" 
            layout="vertical"
          >
            <Descriptions.Item 
              span={3} 
              label={
                  <h2>Descrição</h2>
                }
              > 
                <p>{vaga.descricao}</p> 
              </Descriptions.Item>

            <Descriptions.Item
              span={3} 
              label={
                <h2>Requisitos</h2>
              }
            > 
              <p>{vaga.requisitos}</p> 
            </Descriptions.Item>

            <Descriptions.Item
              span={3} 
              label={
                <h2>Qualificações</h2>
              }
            > 
              <p>{vaga.qualificacoes}</p> 
            </Descriptions.Item>

            <Descriptions.Item
              span={3} 
              label={
                <h2>Informações Adicionais</h2>
              }
            > 
              <p>{vaga.info_adicionais}</p> 
            </Descriptions.Item>

            {/* <Descriptions.Item></Descriptions.Item> */}
            <Descriptions.Item
                label={
                    <h3>Empresa</h3>
                }
            >
                {vaga.empresa.razao_social}
            </Descriptions.Item>

            <Descriptions.Item
                span={2}
                label={
                    <h3>Sobre a instituição</h3>
                }
            >
                {vaga.empresa.sobre}
            </Descriptions.Item>
            
            <Descriptions.Item 
              label={
                <h3>Quantidade de Vagas</h3>
              }
            > 
              {vaga.quantidade ? vaga.quantidade : "Não informada"}
            </Descriptions.Item>
            <Descriptions.Item 
              label={
                <h3>Status</h3>
              }
            > 
              {vaga.status}
            </Descriptions.Item>
            <Descriptions.Item
              label={
                <h3>Data de Crição</h3>
              }
            > 
              {moment(vaga.createdAt).utc("America/Alagoas").format("DD/MM/YYYY")}
            </Descriptions.Item>
          </Descriptions>
          
        </Content> :
       <Skeleton />
      }

      <Tabs defaultActiveKey="1" centered>
        <TabPane tab="Candidados" key="1">
          {
            candidatos ?
              candidatos.map((candidato) => {
                return (
                  <Link to={{ pathname: `/aluno/${candidato.Aluno.id}` }}>
                    <Card style={{ width: 300, marginTop: 16 }} loading={!loading}>
                      <Meta
                        avatar={
                          <Avatar size={50} src={candidato.Aluno.avatar_url ? candidato.Aluno.avatar_url : ""} />
                        }
                        title={candidato.Aluno.nome_social ? candidato.Aluno.nome_social : candidato.Aluno.name}
                        description={
                          <p
                            style={{ fontSize: "1rem" }}
                          >
                            {candidato.Aluno.municipio}
                          </p>
                        }
                      />
                    </Card>
                  </Link>
                )
              })
            : <Skeleton />
          }
        </TabPane>
      </Tabs>
    </Container>
  );
}

export default PageVagaAdm;
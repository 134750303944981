import * as React from 'react'
import CssBaseline from '@mui/material/CssBaseline'
import { makeStyles } from '@mui/styles'
import Box from '@mui/material/Container'
import { Typography, ThemeProvider, createTheme } from '@mui/material'
import Button from '@mui/material/Button'
import { styled } from '@mui/material/styles'
import { Link } from 'react-router-dom'
import logo_oxe from '../../assets/logo_oxeTechBlue.png'
import * as palette from "../../utils/styledVariables";

import { Input, Form, Modal, message } from 'antd'
import { Tabs } from 'antd';
import { Content } from './styles'

import api from '../../services/api'
import history from '../../services/history'

const StyledTabs = styled(Tabs)`
  .ant-tabs-tab {
    color: ${palette.laranjaIntermediario2} !important;
  }

  .ant-tabs-ink-bar {
    background-color: ${palette.laranjaIntermediario2}
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: ${palette.laranjaIntermediario2} !important; 
 }
 .ant-tabs-nav .ant-tabs-tab-active {
  color: ${palette.laranjaIntermediario2} !important; 
}

`;

const WhiteBox = styled(Box)`
  width: 30%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  /* display: flex;
  justify-content: center;
  align-items: center; */
  padding: 5%;
  left: 0px;
  right: 0px;
  top: 13vh;
  bottom: 13vh;
  margin: auto;
  border-radius: 0.5rem;
  color: black;
  overflow: hidden;
  font-size: 20px;
  text-align: center;
  background: rgba(255, 255, 255, 89%);
  animation: fadeIn;
  animation-duration: 1s;

  @media (min-height: 800px) {

    max-height: 1000px;
  }

  @media (max-width: 700px) {
    width: 35rem;
    height: 40rem;
  }
  @media (max-width: 360px) {
    width: 33rem;
    height: 45rem;
  }
`

const ColorButton = styled(Button)(({ theme }) => ({
  color: '#fff',
  heith: '2vh',
  backgroundColor: palette.laranjaIntermediario2,
  "&:hover": {
    backgroundColor: palette.laranjaSecundario
}
  // backgroundColor: '#741f8a',
  // '&:hover': {
  //   backgroundColor: '#6B85C2'
  // }
}))

const UseStyles = makeStyles(theme => ({
  root: {
    overflow: 'hidden',
    background: palette.gradienteLaranja180,
      //'linear-gradient(180deg,rgba(116, 31, 138, 0.9) 35%,rgba(0, 0, 99, 1) 100%)',
    height: '100vh'
  },
  paperContainer: {
    backgroundImage: `url(/assets/backgroundSelect.png)`,
    height: '100vh',
    minWidth: '100vw',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat'
  }
}))




export default function ForgotPassword() {
  const theme = createTheme({
    palette: {
      secondary: {
        main: palette.laranjaIntermediario2, 
      },
     
    }
  })
  

  const onFinishPJ = async email => {
    try {
      await api.patch('forgotpassword/aluno', email)

      success()
    } catch (err) {
      message.error(err.response.data.error)
    }
  }

  const onFinishPF = async email => {
    try {
      await api.patch('forgotpassword/empresa', email)

      success()
    } catch (err) {
      message.error(err.response.data.error)
    }
  }

  const success = () => {
    Modal.success({
      content: `Verifique sua nova senha em seu email. Caso não encontre nosso email, verifique sua caixa de SPAM.`,
      onOk() {
        history.push('/')
      }
    })
  }

  const tabs = [
    {
      label:'PESSOA FÍSICA',
      componente: 
      <div style={{display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center'}}>
        <Content>
          <Form
            name="basic"
            initialValues={{
              remember: true
            }}
            layout="vertical"
            onFinish={onFinishPJ}
          >
            <Form.Item
              label="Email cadastrado:"
              name="email"
              required
              rules={[{ type: 'email', message: 'Email inválido!' }]}
              style={{
                width: '300px'
              }}
            >
              <Input
                size="large"
                style={{
                  width: '300px'
                }}
              />
            </Form.Item>
            <div style={{display: 'flex', justifyContent: 'space-around'}}>
            <ColorButton block type="submit" sx={{ mt: 1, mb: 2 }}>
              Recuperar senha
            </ColorButton>
          <Link to="/login" type="button" style={{ color: 'black' }}>
          <ColorButton block type="submit" sx={{ mt: 1, mb: 2 }}>
              Voltar
            </ColorButton>
          </Link>
            </div>
          </Form>
        </Content>
              
      </div>
    },
    {
      label:'PESSOA JURÍDICA',
      componente: <Content>
      <Form
        name="basic"
        initialValues={{
          remember: true
        }}
        layout="vertical"
        onFinish={onFinishPF}
      >
        <Form.Item
          label="Email cadastrado:"
          name="email"
          required
          rules={[{ type: 'email', message: 'Email inválido!' }]}
          style={{
            width: '300px'
          }}
        >
          <Input
            size="large"
            style={{
              width: '300px'
            }}
          />
        </Form.Item>
        <div style={{display: 'flex', justifyContent: 'space-around'}}>
        <ColorButton block type="submit" sx={{ mt: 1, mb: 2 }}>
          Recuperar senha
        </ColorButton>
      <Link to="/login" type="button" style={{ color: 'black' }}>
      <ColorButton block type="submit" sx={{ mt: 1, mb: 2 }}>
          Voltar
        </ColorButton>
      </Link>
        </div>
      </Form>
    </Content>
    }, 
  ]
  const classes = UseStyles()
  return (
    <React.Fragment>
      <CssBaseline />
      <div className={classes.root}>
        <div className={classes.paperContainer} />
        <WhiteBox>
        <Content>
        <img
            className="img-resp"
            src={logo_oxe}
            alt="Logo OxeTechLab"
          ></img>
          <Typography
            variant="h4"
            component="div"
            gutterBottom
            sx={{
              mt: 4,
              mb: 2,
              fontSize: '25px'
            }}
          >
            Recuperar acesso ao sistema
          </Typography>
        </Content>
          <StyledTabs
            className='tabs'
            defaultActiveKey="1"
            centered
            items={tabs.map((item, i) => {
            return {
                label: item.label,
                key: i,
                children: item.componente,
            };
            })}
                />
              
        </WhiteBox>
      </div>
    </React.Fragment>
  )
}

import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5px 15px;

  width: auto;
  height: 100vh;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 75vw;
  padding: 15px;
  border-bottom: 1px dashed #ccc;
  
`

import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

export const useLab = () => {
  const labs = useSelector((state) => state.user.labs_profile);
  const { labId } = useParams();
  if (labId) {
    const lab = labs.find((lab) => lab.lab_id == labId);
    return lab;
  }
  return null;
}

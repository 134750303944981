import React, { useState,useEffect } from "react";
import {
    Modal,
    Typography,
    styled,
    Box,
    Button,
} from "@mui/material";
import * as palette from "../../utils/styledVariables"
import { DataGrid, GridToolbar  } from '@mui/x-data-grid';
import api from "../../services/api";



const styleModal = {

    width: "45%",
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    padding: "15px",
    paddingTop: "20px",
    textAlign: "center",
    borderRadius: "1vh",
    justifyContent: 'center'

};



function ModalConfirmacao  (props) {
    const { open, close, contratos } = props;
  const [contratados, setContratados] = useState([]);

  const contratadosPorVaga = async (id) => {
    try {
      const response = await api.get(`contratacao/vaga/${id}`);
      return response.data;
    } catch (err) {
      console.error(err);
      return [];
    }
  };

  useEffect(() => {
    if (contratos) {
      contratadosPorVaga(contratos.id)
        .then((data) => setContratados(data))
        .catch((err) => console.error(err));
    }
  }, [contratos]);

  const columns = [
    {
      field: "nome",
      headerName: "Nome",
      width: 335,
    },
    {
      field: "email",
      headerName: "Email",
      width: 335,
    },
  ];

  const rows = contratados.map((candidato, index) => ({
    id: index,
    nome: candidato.Candidatura.Aluno.name,
    email: candidato.Candidatura.Aluno.email,
  }));
                return (
                    <Modal open={open} onClose={close}>

            <Box sx={styleModal}>
                <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '5%'}}>
                    <h2>Profissionais Contratados</h2>
                    <div style={{display: 'flex'}}>
                        <h4 style={{marginRight: '5%', marginBottom:0}} >Vagas Disponíveis: {contratos?.qtd_em_oferta}</h4>
                        <h4 style={{marginBottom:0}} >Vagas Preenchidas: {contratos?.qtd_preenchida}</h4> 
                    </div>
                </div>
            <div style={{height:300}} >
                <DataGrid
                rows={rows}
                // components={{ Toolbar: GridToolbar }}
                columns={columns}
                sx={{
                    background: palette.profileWhiteShadeTransp, borderRadius: '15px',
                    "& .MuiDataGrid-toolbarContainer": {
                        '& .MuiButton-text': {
                            color: palette.roxoAzulado,
                        },
                        
                    }, "& .MuiDataGrid-columnHeaderTitle": {
                        color: palette.preto,
                        
                    }
                    , "& .MuiDataGrid-cellContent": {
                        color: palette.preto,
                        
                    },
                    '& .MuiDataGrid-footerContainer':{
                        position: 'relative',
                    }
                }}
                />
                </div>
                
           
            </Box >
        </Modal >)
}


export default ModalConfirmacao;
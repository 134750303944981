import { makeStyles, styled } from '@material-ui/styles';
import { Button, Chip, Typography } from '@mui/material';

export const useStyles = makeStyles(() => ({
  modalContainer: {
    position: 'absolute',
    display: 'flex',
    paddingTop: '1.3rem',
    paddingRight: '1.5rem',
    paddingLeft: '1.5rem',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    height: '90vh',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '70%',
    borderRadius: '15px',
    overflow: 'auto',
  },

  goBackContainer: {
    display: 'flex',
    width: '100%',
    height: '0.5vh',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },

  goBackButton: {
    borderRadius: '50%',
    backGroundColor: '#D56D0D2E',
    '&:hover': {
      backgroundColor: '#D56D0D2E',
    }
  },

  modalHeader: {
    width: '100%',
    height: '20%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '1rem'
  },

  iconAndTitleContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flexGrow: 1,
    height: '100%',
    width: '65%',
    margin: '3vh'
  },

  icon: {
    margin: '0 1rem 0 1rem',
    backgroundColor: 'rgba(213, 109, 13, 0.44)',
    width: 'auto',
    height: '15vh',
    flexShrink: 0,
    aspectRatio: '1/1', 
  },

  iconImg: {
    width: '60%',
    height: '60%',
    objectFit: 'contain',
    color: '#A4560F'
  },

  title: {
    fontFamily: 'Roboto',
    fontWeight: 400,
    height: '100%',
    display: 'flex',
    alignItems: 'center',
  },

  formContainer: {
    display: 'inherit',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },

  inputContainer: {
    padding: '1rem',
    paddingRight: '1rem',
    paddingLeft: '1rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
  },

  submissionContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    width: '100%',
    marginBottom: '1rem'
  },

  submissionButton: {

  }

}));

export const UploadContainer = styled('div')({
  border: '2px dashed #aaa',
  borderRadius: '4px',
  padding: '2rem',
  textAlign: 'center',
  cursor: 'pointer',
  width: '100%',
  height: '30vh',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  '&:hover': {
    backgroundColor: '#f5f5f5',
  },
});

export const SubmissionButton = styled(Button)({
  background: 'linear-gradient(162.93deg, #D56D0D -10.26%, #8E24AA 276.84%)',
  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
  borderRadius: '15px',
  color: '#fff',
  width: '8vw',
  height: '4vh'
});

export const InputTitle = styled((props) =>
  <Typography {...props} variant='h4' align='justify'/>)({
    color: '#D46D0D',
  }
);
/** Actions UPDATE PROFILE ALUNO */

export function updateProfileRequest(data) {
  return {
    type: "@user/UPDATE_PROFILE_REQUEST",
    payload: { data },
  };
}

export function finishProfileRequest(data) {
  return {
    type: "@user/FINISH_PROFILE_REQUEST",
    payload: { data },
  };
}

export function updateProfileSuccess(user) {
  return {
    type: "@user/UPDATE_PROFILE_SUCCESS",
    payload: { user },
  };
}

export function updateProfileFailure() {
  return {
    type: "@user/UPDATE_PROFILE_FAILURE",
  };
}

/** Actions UPDATE PROFILE EMPRESA */

export function updateProfileEmpresaRequest(data) {
  return {
    type: "@user/UPDATE_PROFILE_EMPRESA_REQUEST",
    payload: { data },
  };
}

export function updateProfileEmpresaSuccess(user) {
  return {
    type: "@user/UPDATE_PROFILE_EMPRESA_SUCCESS",
    payload: { user },
  };
}

export function updateProfileEmpresaFailure() {
  return {
    type: "@user/UPDATE_PROFILE_EMPRESA_FAILURE",
  };
}

/** Actions UPDATE FIRST STEPS */

export function updateFirstStepsRequest(data) {
  return {
    type: "@user/UPDATE_FIRST_STEPS_REQUEST",
    payload: { data },
  };
}

export function updateFirstStepsSuccess(user) {
  return {
    type: "@user/UPDATE_FIRST_STEPS_SUCCESS",
    payload: { user },
  };
}

export function updateFirstStepsFailure() {
  return {
    type: "@user/UPDATE_FIRST_STEPS_FAILURE",
  };
}

/** Actions UPDATE OFFICE */

export function updateOfficeRequest(data) {
  return {
    type: "@user/UPDATE_OFFICE_REQUEST",
    payload: { data },
  };
}

export function updateOfficeSuccess(user) {
  return {
    type: "@user/UPDATE_OFFICE_SUCCESS",
    payload: { user },
  };
}

export function updateOfficeFailure() {
  return {
    type: "@user/UPDATE_OFFICE_FAILURE",
  };
}

export function seeNotifications(user, url) {
  return {
    type: "@user/SEE_NOTIFICATIONS",
    payload: { user, url },
  };
}

export function labProfileRegister(data) {
  const profile = {
    id: data.isCoordenador ? data.data[0]?.id : data.data?.Laboratorio.id,
    nome: data.isCoordenador ? data.data[0]?.nome : data.data?.Laboratorio.nome,
    municipio: data.isCoordenador
      ? data.data[0]?.municipio
      : data.data?.Laboratorio.municipio,
    rua: data.isCoordenador ? data.data[0]?.rua : data.data?.Laboratorio.rua,
    bairro: data.isCoordenador
      ? data.data[0]?.bairro
      : data.data?.Laboratorio.bairro,
    numero: data.isCoordenador
      ? data.data[0]?.numero
      : data.data?.Laboratorio.numero,
    cep: data.isCoordenador ? data.data[0]?.cep : data.data?.Laboratorio.cep,
    complemento: data.isCoordenador
      ? data.data[0]?.complemento
      : data.data?.Laboratorio.complemento,
    coordenador: data.isCoordenador
      ? data.data[0]?.Coordenador.name
      : data.data?.Laboratorio.Coordenador.name,
    isCoordenador: data.isCoordenador,
  };
  return {
    type: "@user/LAB_PROFILE",
    payload: { profile },
  };
}

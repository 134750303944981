// import { Card} from 'antd'
import { styled } from '@mui/material/styles'
import {Card, Grid,CardActions} from '@mui/material';
import { Modal } from 'antd';



export const InfoAdd = styled(Grid)`
    h3{
        font-family: 'Karla', sans-serif;
        font-weight: bold;
        margin-top: 1%;
    }
    p, li{
        font-family: 'Poppins', sans-serif;
        font-weight: light;

    }
    ul{
        margin-left: 4%;
    }
  

`

export const ContainerGrid = styled(Grid)`
    


`
export const ModalCard = styled(Modal)`
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #ffffff;
    padding: 0%;
    .ant-modal-wrap{
        border-radius: 150px;
        width: 60vw;

    }
    .ant-modal-content{
        width: 100%;
    }
    .ant-modal-body{
        padding: 0;
    }
    .header{
        display: flex;
        justify-content: space-between;
    }
    .title{
        font-size: 3em;
        padding-left: 2%;
        }
    .subtitle{
        padding-left: 3%;
        font-family: 'Karla', sans-serif;
        
    }
    .img{
        margin-right: 2%;
            @media (max-width: 768px) {
                width: 30%;
            }
    }
    .section{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .sectionName{
        font-family: 'Poppins', sans-serif;
        font-weight: 500;
        border-bottom: 4px solid #D46D0D;
        padding-bottom: 1%;
    }
    .text{
        font-family: 'Inter', sans-serif;
        text-align: justify;
        margin-top: 2%;
        margin-bottom: 2%;

    }
`

export const LinhaHorizontal = styled('div')`
    border-bottom: 1px solid rgba(0, 0, 0, 0.29);

`


export const OxeCard = styled(Card)`
    padding:0;
    background-color: #ffffff;
    /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); */
    -webkit-box-shadow: -1px -4px 25px -12px rgba(168,168,168,1);
    -moz-box-shadow: -1px -4px 25px -12px rgba(168,168,168,1);
    box-shadow: -1px -4px 25px -12px rgba(168,168,168,1);
    border-radius: 15px;
    &:hover{
        animation: myAnim 0.5s ease 0s 1 normal forwards;
    }

    width:80%;
    @media (max-width: 700px) {
        width:100%;
    }
    cursor: pointer;
    .title{
        display: -webkit-box;
        min-height: 28px;
        max-height: 28px;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }
    .subtitle{
        font-family: 'Karla', sans-serif;
        font-size: 1em;
    }
    .info{
        display: flex;
        flex-wrap: wrap;
        align-items: center;
    }
    .noWrap{
        font-family: 'Inter', sans-serif;
        color:rgba(0, 0, 0, 0.64);
        margin-top: 5%;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        @media (max-width: 720px) {
            font-size: 1em;
        }
    }
    @keyframes myAnim {
	0% {
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
		transform: scale(1);
	}

	100% {
		box-shadow: 0 0 20px 0px rgba(0, 0, 0, 0.35);
		transform: scale(1.02);
	}
}

`

export const Title = styled('div')`

    display: flex;
    justify-content: space-around;
    align-items: center;

    h1 {
        font-family: 'Poppins', sans-serif;
        font-weight: 30px;
        text-transform: capitalize;
        /* font-size: 1.7em; */
        font-size: 300%;
        word-break: break-word;
    }

    h5 {
        font-family: 'Poppins', sans-serif;
        color: #525252;
        text-align: center;
        text-transform: capitalize;
    }

    .foto{
        width: 8em;
        height: 8em;

        @media (max-width: 700px){
            width: 5em;
            height: 5em;
        }
    }

    .avatar-place{
        width: 12em; 
        height: 12em;
        @media (max-width: 700px) {
            width: 8em; 
            height: 7em;
            margin-right: 5% ;
        }
    }
    .avatar {
        background: #4F69AA;
        width: 100%;
        height: 100%; 

    }
    
    
`

export const VagaName = styled('div')`
    width: 70%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    h1{
        font-size: 1.53em;
        text-transform: capitalize;
        @media (max-width: 700px) {
            font-size: 1.4em;
            
        }
    }
    
    h5{
        text-transform: capitalize;

    }
    
`
export const Labels = styled('div')`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    .porcentagem{
        font-family: 'Poppins', sans-serif;
        color: #22DFB2;
        font-weight: bold   ;
    }
    
    `
export const Capa = styled('div')`
    width: 100%;

`
export const Submit = styled(CardActions)`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 4%;
    padding-bottom: 2%;
    /* margin-left: 2%; */
    
    .butaoNaoInscrito{
        background: linear-gradient(162.93deg, rgba(114, 67, 170, 0.87) 11.75%, rgba(213, 109, 13, 0.83) 298.85%);
        font-size: 0.75em;
        border-radius: 15px;
        color: #fff;
        font-weight: bold;
        font-family: 'Karla', sans-serif;
        &:hover{
            background: linear-gradient(162.93deg, #7243AA 11.75%, rgba(213, 109, 13, 0.83) 298.85%);
;
        }
    }

    .butaoInscrito{
        background: green;
        font-size: 0.75em;
        border-radius: 15px;
        cursor: default;
        color: #fff;
        font-weight: bold;
        font-family: 'Karla', sans-serif;
        &:hover{
            background: green;
        }
    }

    .butaoExtendido{
        background: linear-gradient(162.93deg, rgba(114, 67, 170, 0.87) 11.75%, rgba(213, 109, 13, 0.83) 298.85%);
        font-size: 0.75em;
        padding: 1% 2%;
        border-radius: 15px;
        color: #fff;
        font-weight: bold;
        font-family: 'Karla', sans-serif;
        &:hover{
            background: linear-gradient(162.93deg, #7243AA 11.75%, rgba(213, 109, 13, 0.83) 298.85%);
;
        }
    }
    .lastinfo{
        width: 50%;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: flex-end;
    }
    .encerramento{
        font-family: 'Poppins', sans-serif;
        font-weight: 300;
        margin-right: 5%;
    }
    
    
    `

export const ModalConfirm = styled(Modal)`
    .ant-modal-body{
        display: flex;
        justify-content:center;
        flex-direction:column;
        align-items: center;
        
        .option{
            width: 80%;
        }
        h3{
            margin-top: 2%;
            font-family: "Poppins", sans-serif;
            color: rgba(0, 0, 0, 0.8);
            text-align: center;
        }
        h5{
            font-family: "Poppins", sans-serif;
            color: rgba(0, 0, 0, 0.8);
            font-weight: 300;
            @media (max-width: 700px) {
                font-size: 0.6em;
            }
        }
        
        h4{
            color: #6B85C2;
        }
        .butoes{
            margin-top: 5%;
            width: 100%;
            display: flex;
            justify-content: space-around;   
        }

        .butao{
            border-radius: 5px;
            color: #fff;
            background: linear-gradient(142.98deg, #D56D0D -46.82%, #8E24AA 197.08%);

            font-weight: bold;
            font-family: 'Karla', sans-serif;
            &:hover{
                background: linear-gradient(142.98deg, #D56D0D -46.82%, #8E24AA 100.08%);

            }
        }
        
        .butaoCancelar{
            border-radius: 5px;
            background-color: #FFFFFF;
            /* border-color: #6B85C2; */
            color: #D46D0D;
            border: 1px solid;
            &:hover{
                color: #8E24AA;

        }        
        
    }
    }
`

export const NoVagas = styled('div')`
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    @media (max-width: 700px) {
        img{
            width: 15%;
        }
        h1{
            font-size: 0.8em;
            text-align: center;
        }
    }
`
export const Search = styled('div')`
    display: flex;
    justify-content: center;
    margin-bottom: 3%;
    width: 100%;
    /* img{
        /* width: 3%;
        @media (max-width: 700px) {
            
        }  */
    .input{
        background: rgba(212, 109, 13, 0.11);
        border-radius: 10px;
        border: 1px solid rgba(212, 109, 13, 0.6);
        width: 80%;
        margin-left: 2%;
        padding: 1%;
        color: rgba(0, 0, 0, 1);
        font-family: 'Poppins', sans-serif;
        font-size: 1em;
        line-height: 1%;
        
    }

    input:focus {
        border-color: #8E24AA;
    }
    input::placeholder {
        color: #00000078;
    }

`
export const GridCard = styled(Grid)`
    padding-left: 2%;
    padding-bottom: 5%;
    
    @media (max-width: 700px) {
        padding-left: 2%;
    }
`


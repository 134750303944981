import { styled } from '@mui/material/styles';
import { Menu } from '@mui/material';

export const PainelNotificacoes = styled(Menu)({
  '& .MuiMenu-paper': {
    minWidth: '400px',
    maxWidth: '400px',
    minHeight: '390px',
    maxHeight: '390px',
    backgroundColor: 'white',
    padding: '10px',
    borderRadius: '20px',
  },
});

export const Titulo = styled('h1')({
  fontSize: '20px',
  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  color: 'black',
  padding: '10px 10px 0px',
});


export const Notificacoes = styled('div')({
  display: 'flex',
  overflowY: 'auto',
  color: 'black',
  flexDirection: 'column',
  alignItems: 'center',
  width: '380px',
  height: '300px',
  padding: '0px 12px',
  '&::-webkit-scrollbar': {
    width: '6px',
  },
  '&::-webkit-scrollbar-thumb': {
    borderRadius: '4px',
    backgroundColor: 'white',
    '&:hover': {
      backgroundColor: '#D9D9D9',
    },
  },
});

export const MensagemSemNotificacoes = styled('div')({
  minWidth: '310px',
  minHeight: '300px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
});

export const Mensagem = styled('h2')({
  fontSize: '16px',
  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  color: '#b1b1b1',
});

import {
  Alert,
  Grid,
  Paper,
  Snackbar,
  Tooltip,
  Typography,
  styled,
  Menu,
  MenuItem,
} from "@mui/material";
import { Link, useParams } from "react-router-dom";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ClearIcon from "@mui/icons-material/Clear";

import IconButton from "@mui/material/IconButton";
import React, { useEffect, useState } from "react";
import api from "../../../../services/api";
import formatDate from "../../../../utils/formartDate";
import Grafico from "../../../components/Bolsista/Grafico";
import Tabela from "../../../components/Bolsista/Tabelas/FrequenciaTable";
import { styles } from "./styles";
import { differenceInYears } from "date-fns";
import { useHistory } from "react-router-dom";
import { set } from "date-fns/esm";
const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  justifyItems: "center",
  color: theme.palette.text.secondary,
  borderRadius: "15px",
}));

function DashboardTurma() {
  const { escolaId, turmaId } = useParams();
  const [turma, setTurma] = useState({});
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [atualizarTurma, setAtualizarTurma] = useState(false);
  const [alunosFrequencia, setAlunosFrequencia] = useState([]);
  const history = useHistory();

  const today = new Date();
  const dataEncerramento = new Date(turma.data_encerramento);

  const diasAteEncerramento = Math.floor(
    (dataEncerramento - today) / (1000 * 60 * 60 * 24)
  );

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleSair = () => {
    history.goBack();
  };

  useEffect(() => {
    const fetchTurma = async () => {
      try {
        const resTurma = await api.get(`/oxetechedu/turmas/${turmaId}`);
        setTurma(resTurma.data);
      } catch (error) {
        setError(error.response.data.error);
      }
    };

    fetchTurma();
    setAtualizarTurma(false)
  }, [success, atualizarTurma]);


  useEffect(() => {
    api
      .get(`/oxetechedu/inscricoes/${turmaId}`)
      .then((response) => {
        const rows = response.data?.map((inscricao) => {
          return {
            presente: inscricao.presente,
          };
        });
        setAlunosFrequencia(rows);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const diaAulasData =
    turma.dia_da_aula?.slice(8, 10) +
    "-" +
    turma.dia_da_aula?.slice(5, 7) +
    "-" +
    turma.dia_da_aula?.slice(0, 4);

  const encerramentoInscricoesData =
    turma.encerramento_inscricoes?.slice(8, 10) +
    "-" +
    turma.encerramento_inscricoes?.slice(5, 7) +
    "-" +
    turma.encerramento_inscricoes?.slice(0, 4);

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} md={12}>
        <Item sx={styles.titleCaixa}>
          <div>
            <h3 style={styles.title}>{turma?.titulo}</h3>
            <div style={{ display: "flex", width: "120%" }}>
              <h6 style={styles.subtitle}>
                Fim das inscrições:{" "}
                <strong>{encerramentoInscricoesData}</strong>
              </h6>
              <h6 style={styles.subtitle}>
                Dia da aula: <strong>{diaAulasData}</strong>
              </h6>
              <h6 style={styles.subtitle}>
                Horário:{" "}
                <strong>
                  {turma?.hora_inicio} até {turma?.hora_fim}
                </strong>
              </h6>
            </div>
          </div>
          <Tooltip title="Sair">
            <IconButton onClick={handleSair}>
              <ClearIcon style={{ color: "white" }} />
            </IconButton>
          </Tooltip>
        </Item>
      </Grid>
      
      {/* <Grid item xs={12} sm={12} md={8}>
        <Item sx={styles.card4}>
          <Grafico data={alunosFrequencia} />
        </Item>
      </Grid> */}

      <Grid
        item
        xs={12}
        sx={{
          background: "rgba(212, 109, 13, 0.1)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          borderRadius: "25px",
          marginTop: "8px",
          pb: "20px"
        }}
      >
        <h2>Frequência dos Alunos</h2>
        <Tabela
          turmaId={turmaId}
        />
      </Grid>

      <Snackbar
        open={success.length > 0}
        autoHideDuration={7000}
        onClose={() => setSuccess("")}
      >
        <Alert
          variant="filled"
          onClose={() => setSuccess("")}
          severity="success"
          sx={{ width: "100%" }}
        >
          {success}
        </Alert>
      </Snackbar>
      <Snackbar
        open={error.length > 0}
        autoHideDuration={7000}
        onClose={() => setError("")}
      >
        <Alert
          variant="filled"
          onClose={() => setError("")}
          severity="error"
          sx={{ width: "100%" }}
        >
          {error}
        </Alert>
      </Snackbar>
    </Grid>
  );
}

export default DashboardTurma;

import React, { useEffect } from 'react'
import { Box } from '@mui/system'
import Typography from "@mui/material/Typography";
import { useDispatch, useSelector } from 'react-redux'
import Divider from "@mui/material/Divider";
import TextField from '@mui/material/TextField';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import MailIcon from '@mui/icons-material/Mail';
import BadgeIcon from '@mui/icons-material/Badge';
import FingerprintIcon from '@mui/icons-material/Fingerprint';
import InputMask from "react-input-mask";
import PhoneIcon from '@mui/icons-material/Phone';
import SchoolIcon from '@mui/icons-material/School';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { School } from '@mui/icons-material';
import Button from '@mui/material/Button';
import UploadIcon from '@mui/icons-material/Upload';
import DeleteIcon from '@mui/icons-material/Delete';
import { SnackbarProvider, useSnackbar } from 'notistack';
import LabelIcon from '@mui/icons-material/Label';
import api from '../../../services/api'
import prettyBytes from 'pretty-bytes';
import { Route, Redirect, useLocation } from 'react-router-dom'
import history from '../../../services/history'
import DownloadIcon from '@mui/icons-material/Download';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import EngineeringIcon from '@mui/icons-material/Engineering';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import CardMedia from '@mui/material/CardMedia';
import { CircularProgress } from '@mui/material';
import iconeOxetechWork from "../../../assets/iconeOxetechWork.png"
import moment from 'moment';
import 'moment/locale/pt-br'
moment.locale('pt-br')

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 224,
    },
  },
};

function FormularioEmpresa(){
    let profile = useSelector((state) => {
        if (state && state.user && state.user.profile && state.user.profile.dataValues) {
            return state.user.profile.dataValues
        } else if (state && state.user && state.user.profile) {
            return state.user.profile
        }
    });

    const location = useLocation();

    const [numFuncionarios, setNumFuncionarios] = React.useState(null);
    const [tempoAtividade, setTempoAtividade] = React.useState(null);
    const [mentorName, setMentorName] = React.useState(null);
    const [trabalhos, setTrabalhos] = React.useState(null);
    const [objetivos, setObjetivos] = React.useState(null);
    const [areasBolsista, setAreasBolsista] = React.useState(null);
    const [atividadesBolsista, setAtividadesBolsista] = React.useState(null);
    const [file, setFile] = React.useState(null);
    const [fileId, setFileId] = React.useState(null);
    const [selectedEdital, setSelectedEdital] = React.useState(null);
    const [payload, setPayload] = React.useState({});
    const [inscricao, setInscricao] = React.useState(null);
    const [verificarInscricao, setVerificarInscricao] = React.useState(false);
    const defaultButtonText = 'Clique aqui para anexar um PDF com o comprovante de inscrição do CNPJ (Máx 10MB)';
    const [buttonText, setButtonText] = React.useState(defaultButtonText);
    const [loading, setLoading] = React.useState(false);

    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        if (verificarInscricao){
            api.get(`/oxetechwork/inscricao-empresa`).then(response => {
                if (response.data){
                    response.data.forEach((element) => {
                        if (element.edital_id === selectedEdital.id){
                            setInscricao(element)
                        }
                    })
                }
            }).catch((err) => {
                if (err.response && err.response.data && err.response.data.error){
                    enqueueSnackbar(err.response.data.error, { variant: 'error' })
                }
            })
        }
    }, [verificarInscricao])



    useEffect(() => {
        let matches = location.pathname.match(/\d+/g);
        if (matches && matches[0]){
            api.get(`oxetechwork/edital/all`).then(response => {
                let editalFound = false;
                response.data.editals.forEach(element => {
                    if (element.id === parseInt(matches[0])){
                        editalFound = true;
                        setSelectedEdital(element)
                    }
                });
                if (!editalFound){
                    history.push('/oxe-tech-work')
                }
            }).catch((err) => {
                history.push('/oxe-tech-work')
            })
        }else{
            history.push('/oxe-tech-work')
        }

        function myTimer() {
            if (inscricao === null){
                setVerificarInscricao(true)
            }else{
                clearInterval(myInterval)
            }
        }

        const myInterval = setInterval(myTimer, 500);
    }, [])

    useEffect(() => {
        if (fileId){
            setPayload({...payload, file_id : fileId})
        }
    }, [fileId])

    function cancelarInscricao(){
        api.delete(`/oxetechwork/inscricao-empresa/` + inscricao.num_inscricao).then(response => {
            if (response.data) {
                setInscricao(null)
                enqueueSnackbar(response.data, { variant: 'success' })
            }
        }).catch((err) => {
            if (err.response && err.response.data && err.response.data.error){
                enqueueSnackbar(err.response.data.error, { variant: 'error' })
            }
        })
    }

    useEffect(() => {
        if (payload.file_id !== undefined) {
            setLoading(true);
            api.post(`/oxetechwork/inscricao-empresa`, payload).then(response => {
                if (response.data && response.data.message) {
                    if (response.data.data){
                        setInscricao(response.data.data)
                        setLoading(false);
                    }
                    enqueueSnackbar(response.data.message, { variant: 'success' })
                } else {
                    enqueueSnackbar('Falha ao carregar PDF', { variant: 'error' })
                    setLoading(false);
                }
            }).catch((err) => {
                if (err.response && err.response.data && err.response.data.error){
                    enqueueSnackbar(err.response.data.error, { variant: 'error' })
                    setLoading(false);
                }
            })
        }
    }, [payload])

    function handleRemoveFile(){
        setButtonText(defaultButtonText)
        setFile(null)
    }

    function handleFile(event){
        const filesize_max = 10 * 1000 * 1000
        if (event.target.files[0] && (event.target.files[0].size > filesize_max) ){
            setFile(null)
            enqueueSnackbar('O pdf é muito grande ( ' + prettyBytes(filesize_max) + ' )', {variant: 'error'})
        }else{
            if (event.target.files[0]){
                setFile(event.target.files[0])
                setButtonText(event.target.files[0].name + ' - (' + prettyBytes(event.target.files[0].size) + ' / ' + prettyBytes(filesize_max) + ')')
            }else{
                setFile(null)
                setButtonText(defaultButtonText)
            }
        }
    }

    function handleDisabled(){
        let disabled = false;

        disabled = trabalhos === '' ? true : disabled
        disabled = objetivos === '' ? true : disabled
        disabled = areasBolsista === '' ? true : disabled
        disabled = atividadesBolsista === '' ? true : disabled
        disabled = numFuncionarios === '' ? true : disabled
        disabled = tempoAtividade === '' ? true : disabled
        disabled = file === null ? true : disabled

        return disabled
    }

    function getDocs(){
        api.get(`/oxetechwork/inscricao-empresa/docs/` + inscricao.anexo_comprovante_inscricao_cnpj, {responseType: 'arraybuffer'}).then(response => {
            if (response.data){
                var blob = new Blob([response.data], {
                    type: 'application/pdf'
                });
                var url = window.URL.createObjectURL(blob)
                window.open(url);
            }else{
                enqueueSnackbar('Falha ao carregar PDF', {variant: 'error'})
            }
        }).catch((err) => {
            console.log(err)
            enqueueSnackbar('Falha ao carregar PDF', {variant: 'error'})
        })
    }

    function getEdital(){
        api.get(`/oxetechwork/edital/docs/` + selectedEdital.id, {responseType: 'arraybuffer'}).then(response => {
            if (response.data){
                var blob = new Blob([response.data], {
                    type: 'application/pdf'
                });
                var url = window.URL.createObjectURL(blob)
                window.open(url);
            }else{
                enqueueSnackbar('Falha ao carregar PDF', {variant: 'error'})
            }
        }).catch((err) => {
            console.log(err)
            enqueueSnackbar('Falha ao carregar PDF', {variant: 'error'})
        })
    }

    function uploadDoc(){
        const formData = new FormData();
        formData.append("file", file);

        api.post(`oxetechwork/inscricao-empresa/docs`, formData).then(response => {
            if (response.data && response.data.data && response.data.data.uuid){
                setFileId(response.data.data.uuid)
            }else{
                enqueueSnackbar('Falha ao carregar PDF', {variant: 'error'})
            }
        }).catch((err) => {
            enqueueSnackbar('Falha ao carregar PDF', {variant: 'error'})
        })
    }

    function validate(){
        let data = {}

        // valida os campos e faz upload do pdf caso estejam ok
        data.trabalhos = trabalhos
        data.objetivos = objetivos
        data.areas_bolsista = areasBolsista
        data.atividades_bolsista = atividadesBolsista
        data.num_funcionarios = numFuncionarios
        data.tempo_de_atividade = tempoAtividade
        data.motivo_de_rejeicao = ''
        data.edital_id = selectedEdital.id
        
        setPayload(data)

        uploadDoc()
    }

    function handleNumFuncionarios(e){
        const onlyNums = e.target.value.replace(/[^0-9]/g, '');
        setNumFuncionarios(onlyNums);
    }
    function handleTempoAtividade(e){
      setTempoAtividade(e.target.value)
    }

    function handleTrabalhos(e){
      setTrabalhos(e.target.value)
    }
    
    function handleObjetivos(e){
      setObjetivos(e.target.value)
    }

    function handleAtividadesBolsista(e){
      setAtividadesBolsista(e.target.value)
    }

    function handleAreasBolsista(e){
      setAreasBolsista(e.target.value)
    }

    return (
        <Box>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <Typography sx={{ fontWeight: 'bold' }} variant="h6">
                    {
                        inscricao === null
                        ?
                            "FORMULÁRIO DE INSCRIÇÃO"
                        :
                            "CONFIRMAÇÃO DE INSCRIÇÃO"
                    }
                </Typography>
            </Box>
            {
                inscricao === null ?
                    <>
                        <Divider sx={{ my: 1 }} variant="fullWidth" />
                        <Box sx={{ pb: 1, gap: 2, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Button onClick={getEdital} target="_blank" variant="outlined" href={selectedEdital?.link_edital}>
                                <Typography variant="h6" sx={{ display: 'flex', alignItems: 'center', gap: 1, color: 'red' }} >
                                    {selectedEdital?.title}
                                    <DownloadIcon />
                                </Typography>
                            </Button>
                        </Box>
                        <Box sx={{ px: 2, display: 'flex', gap: 4, flexWrap: 'wrap', justifyContent: 'center' }}>
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                <Typography sx={{ display: 'flex', gap: 1, alignItems: 'center' }} variant="body1"><AccessTimeIcon /> Início:</Typography>
                                <Typography variant="h6">{(selectedEdital && selectedEdital.dt_inicio_edital) ? moment(selectedEdital.dt_inicio_edital).utc("America/Alagoas").format("DD/MM/YYYY") : ''}</Typography>
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                <Typography sx={{ display: 'flex', gap: 1, alignItems: 'center' }} variant="body1"><AccessTimeIcon /> Fim:</Typography>
                                <Typography variant="h6">{(selectedEdital && selectedEdital.dt_encerramento_edital) ? moment(selectedEdital.dt_encerramento_edital).utc("America/Alagoas").format("DD/MM/YYYY") : ''}</Typography>
                            </Box>
                        </Box>
                        <Divider sx={{ pb: 1 }} variant="fullWidth" />
                        <Typography sx={{ p: 2 }} variant="h6">
                            Informações do seu perfil OxeTech:
                        </Typography>
                        <Box
                            sx={{
                                display: 'flex',
                                flexWrap: 'wrap',
                                p: 2,
                                gap: 3,
                            }}
                            noValidate
                            autoComplete="off"
                        >
                            <TextField
                                sx={{ flexGrow: 1 }}
                                label="Razão Social"
                                defaultValue={profile.razao_social}
                                InputProps={{
                                    readOnly: true,
                                    disabled: true,
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <AccessTimeIcon />
                                        </InputAdornment>
                                    ),
                                }}
                            >
                                <AccountCircle />
                            </TextField>
                            <TextField
                                sx={{ flexGrow: 1 }}
                                label="Email"
                                defaultValue={profile.email}
                                InputProps={{
                                    readOnly: true,
                                    disabled: true,
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <MailIcon />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            <TextField
                                sx={{ flexGrow: 1 }}
                                label="Celular"
                                defaultValue={profile.telefone}
                                InputProps={{
                                    readOnly: true,
                                    disabled: true,
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <PhoneIcon />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            <TextField
                                sx={{ flexGrow: 1 }}
                                label="CNPJ"
                                defaultValue={profile.cnpj}
                                InputProps={{
                                    readOnly: true,
                                    disabled: true,
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <BadgeIcon />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            <TextField
                                sx={{ flexGrow: 1 }}
                                label="CEP"
                                defaultValue={profile.endereco.cep}
                                InputProps={{
                                    readOnly: true,
                                    disabled: true,
                                }}
                            />
                            <TextField
                                sx={{ flexGrow: 1 }}
                                label="Município"
                                defaultValue={profile.endereco.municipio}
                                InputProps={{
                                    readOnly: true,
                                    disabled: true,
                                }}
                            />
                            <TextField
                                sx={{ flexGrow: 1 }}
                                label="Bairro"
                                defaultValue={profile.endereco.bairro}
                                InputProps={{
                                    readOnly: true,
                                    disabled: true,
                                }}
                            />
                            <TextField
                                sx={{ flexGrow: 1 }}
                                label="Logradouro"
                                defaultValue={profile.endereco.rua}
                                InputProps={{
                                    readOnly: true,
                                    disabled: true,
                                }}
                            />
                            <TextField
                                sx={{ flexGrow: 1 }}
                                label="Número"
                                defaultValue={profile.endereco.numero}
                                InputProps={{
                                    readOnly: true,
                                    disabled: true,
                                }}
                            />
                        </Box>
                        <Divider sx={{ pb: 1 }} variant="fullWidth" />
                        <Typography sx={{ p: 2 }} variant="h6">
                            Informações adicionais necessárias:
                        </Typography>
                        <Box
                            sx={{
                                display: 'flex',
                                flexWrap: 'wrap',
                                px: 2,
                                gap: 3,
                            }}
                            noValidate
                            autoComplete="off"
                        >
                          <Box sx={{ flexGrow: 1 }}>
                            <Typography variant="caption">Trabalhos realizados pela empresa: </Typography>
                            <TextareaAutosize
                                onChange={handleTrabalhos}
                                minRows={3}
                                placeholder="Descreva os trabalhos realizados pela empresa atualmente"
                                defaultValue={trabalhos}
                                style={{
                                  padding: '8px',
                                  width: '100%',
                                  marginTop: '8px',
                                  display: 'flex'
                                }}
                            />
                          </Box>
                          <Box sx={{ flexGrow: 1 }}>
                            <Typography variant="caption">Objetivos da empresa: </Typography>
                            <TextareaAutosize
                                onChange={handleObjetivos}
                                minRows={3}
                                placeholder="Descreva os objetivos da empresa no programa OxeTech Work"
                                defaultValue={objetivos}
                                style={{
                                    padding: '8px',
                                    width: '100%',
                                    marginTop: '8px',
                                    display: 'flex'
                                }}
                            />
                          </Box>
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                flexWrap: 'wrap',
                                p: 2,
                                gap: 3,
                            }}
                            noValidate
                            autoComplete="off"
                        >
                          <Box sx={{ flexGrow: 1 }}>
                            <Typography variant="caption">Em quais atividades os bolsistas exercerão suas funções? Como ele poderá ajudar a empresa a desenvolver atividades em Ciência, Tecnologia e Inovação? </Typography>
                            <TextareaAutosize
                              onChange={handleAtividadesBolsista}
                              minRows={3}
                              placeholder="Em quais atividades os bolsistas exercerão suas funções? Como ele poderá ajudar a empresa a desenvolver atividades em Ciência, Tecnologia e Inovação?"
                              defaultValue={atividadesBolsista}
                              style={{
                                padding: '8px',
                                width: '100%',
                                marginTop: '8px',
                                display: 'flex'
                              }}
                            />
                          </Box>
                          <Box sx={{ flexGrow: 1 }}>
                            <Typography variant="caption">Em quais áreas os bolsistas irão se aprofundar para o desenvolvimento das atribuições? </Typography>
                            <TextareaAutosize
                              onChange={handleAreasBolsista}
                              minRows={3}
                              placeholder="Em quais áreas os bolsistas irão se aprofundar para o desenvolvimento das atribuições?"
                              defaultValue={areasBolsista}
                              style={{
                                padding: '8px',
                                width: '100%',
                                marginTop: '8px',
                                display: 'flex'
                              }}
                            />
                          </Box>
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                flexWrap: 'wrap',
                                p: 2,
                                gap: 3,
                            }}
                            noValidate
                            autoComplete="off"
                        >
                          <TextField
                            sx={{ flexGrow: 1 }}
                            value={numFuncionarios}
                            onChange={handleNumFuncionarios}
                            label="Numero de Funcionarios"
                            autoComplete="num_funcionarios"
                            required
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <EngineeringIcon />
                                </InputAdornment>
                              ),
                            }}
                          />
                          <TextField
                            sx={{ flexGrow: 1 }}
                            value={tempoAtividade}
                            onChange={handleTempoAtividade}
                            label="Tempo de atividade"
                            autoComplete="tempo_de_atividade"
                            required
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <AccessTimeIcon />
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Box>

                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <input
                                accept="application/pdf"
                                style={{ display: 'none' }}
                                id="raised-button-file"
                                type="file"
                                onChange={handleFile}
                            />
                            <label htmlFor="raised-button-file">
                                <Button
                                    disabled={buttonText !== defaultButtonText ? true : false}
                                    sx={{ m: 2, py: 2 }}
                                    variant="outlined"
                                    component="span"
                                >
                                    <UploadIcon sx={{ mr: 1 }} />
                                    {buttonText}
                                </Button>

                            </label>
                            <Button
                                sx={{ py: 2, display: buttonText === defaultButtonText ? 'none' : 'flex' }}
                                variant="text"
                                onClick={handleRemoveFile}
                            >
                                <DeleteIcon sx={{ color: 'red', display: buttonText === defaultButtonText ? 'none' : 'flex' }} />
                            </Button>
                        </Box>

                        <Box sx={{ m: 2, display: 'flex', justifyContent: 'flex-end' }}>
                          <Button
                            sx={{}}
                            onClick={() => validate()}
                            variant="contained"
                            disabled={handleDisabled()}
                          >
                            INSCREVER-SE &nbsp;
                            {loading && <CircularProgress size={24} sx={{color: 'white'}} />}
                          </Button>
                        </Box>
                    </>
                    :
                    <Box>
                        <Divider sx={{ my: 1 }} variant="fullWidth" />
                        <Box sx={{ px: 2, display: 'flex', justifyContent: 'center' }}>
                            <Card sx={{ maxWidth: 400 }}>
                                <CardMedia
                                    component="img"
                                    alt="iconeOxetechWork"
                                    width={400}
                                    height={125}
                                    image={iconeOxetechWork}
                                />
                                <CardContent sx={{ py: 1, px: 2 }}>
                                    <Box sx={{ display: 'flex', pb: 1, justifyContent: 'center' }}>
                                        <Button target="_blank" variant="text" sx={{ p: 0 }} href={selectedEdital?.link_edital}>
                                            <Typography variant="body2" sx={{ display: 'flex', alignItems: 'center', color: 'red' }} >
                                                {selectedEdital?.title}
                                            </Typography>
                                        </Button>
                                    </Box>
                                    <Box sx={{ display: 'flex', gap: 1, alignItems: 'center', justifyContent: 'space-between' }}>
                                        <Typography variant="caption">Nº de Inscrição: </Typography>
                                        <Typography sx={{ fontWeight: 'bold' }} variant="h6">#{inscricao.num_inscricao}</Typography>
                                    </Box>
                                    <Box sx={{ display: 'flex', gap: 1, alignItems: 'center', justifyContent: 'space-between' }}>
                                        <Typography variant="caption">Data de inscrição: </Typography>
                                        <Typography sx={{ fontWeight: 'bold' }} variant="body2">{moment(inscricao.createdAt).utc("America/Alagoas").format("lll")}</Typography>
                                    </Box>
                                    <Divider sx={{ my: 2 }} variant="fullWidth" />
                                    {
                                        inscricao.motivo_de_rejeicao !== '' & inscricao.motivo_de_rejeicao !== undefined
                                        ?
                                        <>
                                            <Typography sx={{ my: 2}} variant="body2" color="red">
                                                {inscricao.motivo_de_rejeicao }
                                            </Typography>
                                        </>
                                        :
                                        inscricao.status !== 'Validado'?
                                        <>
                                            <Typography sx={{ my: 2}} variant="body2" color="orange">
                                                Sua inscrição será validada assim que suas informações forem verificadas.
                                            </Typography>
                                            <Divider sx={{ my: 2 }} variant="fullWidth" />
                                        </>:<></>
                                    }
                                    <Box sx={{ display: 'flex', gap: 1, alignItems: 'center', justifyContent: 'space-between' }}>
                                        <Typography variant="caption">Status: </Typography>
                                        <Typography sx={{ fontWeight: 'bold', color: inscricao.status === "Em validação" ? 'orange' : inscricao.status === "Validado" ? 'green' : 'red' }} variant="body1">
                                            {inscricao.status}
                                        </Typography>
                                    </Box>
                                    <Box sx={{ display: 'flex', gap: 1, alignItems: 'center', justifyContent: 'space-between' }}>
                                        <Typography variant="caption">Última atualização: </Typography>
                                        <Typography sx={{ fontWeight: 'bold' }} variant="body2">{moment(inscricao.updatedAt).utc("America/Alagoas").format("lll")}</Typography>
                                    </Box>
                                    <Divider sx={{ my: 2 }} variant="fullWidth" />
                                </CardContent>
                                <CardActions sx={{ display: 'flex', p: 1, justifyContent: 'space-between'}}>
                                    <Button
                                        size="small"
                                        onClick={() => {getDocs()}}
                                    >
                                            COMPROVANTE CNPJ
                                    </Button>
                                    <Button
                                        size="small"
                                        disabled={inscricao.status === "Validado"}
                                        onClick={() => {cancelarInscricao()}}
                                    >
                                            Cancelar Inscrição
                                    </Button>
                                </CardActions>
                            </Card>
                        </Box>
                    </Box>
            }
        </Box>
    )
}

export default function IntegrationNotistack() {
    return (
      <SnackbarProvider maxSnack={3}>
        <FormularioEmpresa />
      </SnackbarProvider>
    );
  }
import React, { useEffect, useState, useMemo} from "react";

import { OxeCard,Title,VagaName,Labels, Info,Submit,ContainerGrid, ModalCard,InfoAdd,ModalConfirm,NoVagas,Search} from './styles'
import {CardContent, Grid,  Avatar,Alert , Button,Snackbar ,FormHelperText, Menu, TextField  } from '@mui/material';
import { useSelector } from 'react-redux';
import { Select } from 'antd';

import api from "../../services/api";
// icones

// import grafico from "../../assets/iconesOxedin/grafico.svg"
import BusinessIcon from '@mui/icons-material/Business';
import remoto from "../../assets/iconesOxeWork/remotoW.svg"
import horas from "../../assets/iconesOxeWork/horasW.svg"
// import naoSalvo from "../../assets/iconesOxedin/naoSalvo.svg"
import tipo from "../../assets/iconesOxeWork/tipoW.svg"
import remuneracao from "../../assets/iconesOxeWork/remuneracaoW.svg"
import noFolder from "../../assets/iconesOxeWork/noFolderW.svg"
import noResults from "../../assets/iconesOxeWork/noResultsW.svg"
import search from "../../assets/iconesOxeWork/searchW.svg"
import logo from '../../assets/LOGO_OXETECH_WORK.svg'
import history from '../../services/history'
import renderHTML from 'react-render-html'
import "./index.css"



function VagasOxedin() {
  const signed = useSelector(state => state.auth.signed);

  const [vagas, setVagas] = useState([]);
  const [open, setOpen] = useState(false);
  const [id, setId] = useState(-1);
  const [confirm, setConfirm] = useState(false);
  const [candidatar, setCandidatar] = useState(false);
  const [anos, setAnos] = useState(-1);
  const [error, setError] = useState(false);
  const [alertExists, setAlertExists] = useState(false);  
  const [alertSuccessful, setAlertSuccessful] = useState(false);
  const [alertUnauthorized, setAlertUnauthorized] = useState(false);
  const [alertGeneric, setAlertGeneric] = useState(false);
  const [alertGenericMessage, setAlertGenericMessage] = useState("");

  const [busca, setBusca] = useState('');

  const {Option} = Select


  useEffect(() => {
    api.get("vagas/oxetechwork/validated").then(async (response) => {
      for (let i = 0; i < response.data.length; i++) {
        try {
          const imgResponse = await api.get(`/vagas/avatar-empresa?id=${response.data[i].id}`, {
            responseType: 'arraybuffer'
          });

          if (imgResponse.data) {
            response.data[i].avatarUrl = URL.createObjectURL(
              new Blob([ imgResponse.data ], { type: "image" })
            );
          }
        } catch(err) {
          console.log(err);
        }
      }
      setVagas(response.data);
    });
  }, []);

  const handleNaoLogado = () =>{
    history.push('/select-cadastro')
  }
  const handleOpen = (id) => {
    setOpen(true);
    setId(id);
  }

  const handleClose = () => {   
    setAlertSuccessful(false)
    setAlertExists(false)
    setAlertUnauthorized(false)
    setAlertGeneric(false)
    setAnos(-1) 
    setError(false) 
    setOpen(false)
    setConfirm(false)
    setCandidatar(false)
    setId(-1)
  }


  const handleCandidatar = (e) => {
    setCandidatar(true)
    setConfirm(false)
    
    // e.stopPropagation();

  }
  const handleConfirmar = (e,i) => {
    setOpen(false)
    setConfirm(true)
    setId(i)
    e.stopPropagation();
  }

  const handleSelect = (value) => {
    setAnos(value)
  };
  
  const handleSubmit = async () => {
    if (anos === -1){
      setError(true)
    }else if( signed === false){
      handleNaoLogado()
    }else{
      try {
        setError(false)
        setCandidatar(false)
        setAnos(-1)
        const response = await api.post("candidatura/create", {vagaId: vagasSearch[id].id, data: {tempo_experiencia: anos, oxetech_work: true}} );
        setAlertSuccessful(true)
      }catch (err) {
        if(err.response.data === "APPLICATION_ALREADY_EXISTS"){
          setAlertExists(true)
        }else if(err.response.data.error === "Erro no servidor tente em alguns instantes."){
          setAlertUnauthorized(true)
        }else {
          setAlertGeneric(true)
          setAlertGenericMessage(err.response.data.error)
        }
      }
    } 
  }
  
const Confirmar = () =>{
  return (
  <ModalConfirm open={confirm} closable={false} centered onCancel={handleClose} footer={null}> 
    <img src={logo} className="img" alt="Oxe-tech_Oxe-Din" width="50%"/>
    <h3>Tem certeza que deseja aplicar para a vaga?</h3>
    <h5>Ao se candidatar, seu currículo será enviado para a empresa</h5>
    <div className="butoes">

      <Button onClick={handleCandidatar} className="butao" >Sim</Button>
      <Button onClick={handleClose} variant="outlined" className="butaoCancelar" >Cancelar</Button>
    </div>
  
  </ModalConfirm>)  
}


const Submeter = () =>{
  return (
  <ModalConfirm open={candidatar} transitionName="" closable={false} centered onCancel={handleClose} footer={null}> 
    <img src={logo} className="img" alt="Oxe-tech_Oxe-Din" width="50%"/>
    <h3>Quanto tempo de experiência você tem na vaga:</h3>
    <h4>{id === -1? "Sem vaga" : vagasSearch[id].titulo.toUpperCase()}</h4>
  {/* <select style={{width: "60%", borderColor: "#8E24AA", borderRadius: "5px"}} value={anos} onChange={handleSelect}>  #resolver problema do select!!!
          <option value={0}>Sem experiência</option>
          <option value={1}>1   ano</option>
          <option value={2}>2 anos</option>
          <option value={3}>3 anos</option>
          <option value={4}>4 anos</option>
          <option value={5}>5 anos ou mais</option>
    </select> */}
    <Select
      defaultValue={anos}
      className="option"
      onChange={handleSelect}>
      <Option style={{display: 'none'}} value={-1}> Escolha um tempo de experiência...  </Option>
      <Option  value={0}> Sem experiência </Option>
      <Option  value={1}> 1 ano </Option>
      <Option  value={2}> 2 anos </Option>
      <Option  value={3}> 3 anos  </Option>
      <Option  value={4}> 4 anos  </Option>
      <Option  value={5}> 5 ou mais anos </Option>

    </Select>
    {error && <FormHelperText error required>Você precisa selecionar um tempo de experiência</FormHelperText>}
        <div className="butoes">
              <Button onClick={handleSubmit} className="butao" >Candidatar-se</Button>
              <Button onClick={handleClose} variant="outlined" className="butaoCancelar" >Cancelar</Button>
        </div>
  </ModalConfirm>)  
}


const vagasSearch = useMemo(() => { //Para não precisar ficar recalculando a busca
  const lowerSearch = busca.toLowerCase()
  const busca1 = (vaga) => vaga.titulo.toLowerCase().includes(lowerSearch)
  // const busca2 = (vaga) =>{
  //   const a = vaga.requisitos.map((req) => {
  //   console.log(req)
  //   console.log(req.toLowerCase().includes(lowerSearch))
  //   if (req.toLowerCase().includes(lowerSearch)){
  //     return true
  //   }else{return false}

  // })
  //   console.log("vaga:" +vaga.titulo)
  //   console.log("aqui:" +a)
  //   if (a){
  //     return a
  //   }else{return false}

  // }  
  

  const titulo =  vagas.filter(busca1)
  // // const requisitos =  vagas.filter(busca2)
  // console.log(requisitos.map((req) => req.requisitos))
  // const finalSearch = titulo.concat(requisitos)
  // console.log(finalSearch) 
  return titulo
},[busca, vagas]) 


const CardExpandido = () => {
 
  if (id !== -1){

    return (
      <>
      <ModalCard open={open} onCancel={handleClose} width={800} footer={null}>
  
        <CardContent sx={{}}>   
          <Labels>
            <img src={logo} width="18%" height="18%"  alt="logo oxetechWork" />
            {/* <img src={grafico} width="5%" height="5%" style={{marginBottom:'1.2%', marginRight:'1%'}} alt="grafico de match" />
            <h3 className="porcentagem">30%</h3> */}
          </Labels>
          <Title>
            <div className="avatar-place"  >
            <Avatar 
              className="avatar" 
              sx={{}} alt="Logo da empresa" 
              src={vagasSearch[id].avatarUrl ? vagasSearch[id].avatarUrl : "/static/images/avatar/1.jpg"} 
            >
              <BusinessIcon fontSize="large"></BusinessIcon>
            </Avatar>
            </div>
            <div className="grow"></div>
            <VagaName>
              <h1 style={{margin:0, }}>{vagasSearch[id].titulo.toLowerCase()}</h1>
              <div className="horizontal"></div>
              <h5 style={{margin:0}}>{vagasSearch[id].empresa.razao_social.toLowerCase()} • {vagasSearch[id].empresa.endereco.municipio} , {vagasSearch[id].empresa.endereco.estado}</h5>
            </VagaName>
          </Title>
          <Info>
            <Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
              <Grid sx={{display:'flex', flexDirection:"row",  alignItems: "center", }} item xs={6}>
                <img  style={{marginRight: "2%"}} src={remoto} alt="modalidade" width="8%"/>
                <h5 style={{margin: 0}}>{vagasSearch[id].modalidade !== null? vagasSearch[id].modalidade: "Não informado" }</h5>
              </Grid>
              <Grid sx={{display:'flex', flexDirection:"row",  alignItems: "center", }} item xs={6}>
                <img  style={{marginRight: "2%"}} src={remuneracao} alt="remuneracao" width="8%"/>
                <h5 style={{margin: 0}}> {vagasSearch[id].remuneracao !== null? "R$" + vagasSearch[id].remuneracao: "Não informado" }</h5>
              </Grid> 
              <Grid sx={{display:'flex', flexDirection:"row",  alignItems: "center", }} item xs={6}>
                <img  style={{marginRight: "2%"}} src={tipo} alt="tipo" width="8%"/>
                <h5 style={{margin: 0}}>{vagasSearch[id].tipo !== null? vagasSearch[id].tipo: "Não informado" }</h5>
              </Grid>
              <Grid sx={{display:'flex', flexDirection:"row",  alignItems: "center", }} item xs={6}>
                <img  style={{marginRight: "2%"}} src={horas} alt="horas" width="8%"/>
                <h5 style={{margin: 0}}>{vagasSearch[id].carga_horaria !== null? vagasSearch[id].carga_horaria + " horas/semanais": "Não informado" }</h5>
              </Grid>
            </Grid>
          </Info>
          <div className="horizontal"></div>
          <InfoAdd>
            <h3>Descrição da vaga</h3>
            <div className="card-descricao">
              {renderHTML(vagasSearch[id].descricao !== null? vagasSearch[id].descricao : "Não informado.")}
            </div>
          </InfoAdd>
          <div className="horizontal"></div>
          <InfoAdd>
            <h3>Requisitos da vaga</h3>
            <ul>
                {vagasSearch[id].requisitos.map((req, i) => {
                  return <li key={i}>{req}</li>;
                })}
            </ul>
          </InfoAdd>
          <div className="horizontal"></div>
          <InfoAdd>
            <h3>Qualificação </h3>
            <h4>{vagasSearch[id].qualificacoes}</h4>
          </InfoAdd>
          <Submit>
            <Button htmlType="submit" onClick={(e)=>handleConfirmar(e,id)} className="butao"  >
                Candidatar-se
            </Button>
            <div className="lastinfo">
              <h5 className="encerramento"> {"Inscrições até " + vagasSearch[id].data_encerramento.slice(8,10) + "/" + vagasSearch[id].data_encerramento.slice(5,7) + "/"+ vagasSearch[id].data_encerramento.slice(0,4)} </h5>
              {/* <IconButton aria-label="delete">
                <img className="salvar" src={naoSalvo} alt="Iconde de salvar vaga" width={20}></img>
              </IconButton> */}
            </div>
          </Submit>
        </CardContent>
        
                    
      </ModalCard> 
      </>
    )
  }
  else{
    return <></>;
  }
}


if (vagas.length !== 0){
  return (
    <div>
      <Search>
        <img src={search} width="3%" alt="Busca"/>
        <input className="input" placeholder="Pesquise por empresa, nome de vaga, requisitos..." type="text" value={busca} onChange={(e) => setBusca(e.target.value)}/>
      </Search>
      { vagasSearch.length !== 0?
      <Grid container spacing={{ xs: 1, md: 2, lg: 2 }} columnSpacing={{ xs: 1, sm: 2, md: 3 }} >
        {vagasSearch.map((item,i) => {
          return(
            <ContainerGrid  item xl={4} lg={4} md={6} sm={12} xs={12} key={i}>
                <OxeCard sx={{width:"100%"}} onClick={() => {
                  handleOpen(i)}} >
                  <CardContent>
                    <Labels>
                      <img src={logo} width="18%" height="18%"  alt="logo oxetechWork" />
                      {/* <img src={grafico} width="5%" height="5%" style={{marginBottom:'1.2%', marginRight:'1%'}} alt="grafico de match" />
                      <h3 className="porcentagem">30%</h3> */}
                    </Labels>
                    <Title>
                      <div className="foto">
                      <Avatar 
                        className="avatar" 
                        alt="Logo da empresa" 
                        src={item.avatarUrl ? item.avatarUrl : "/static/images/avatar/1.jpg"} 
                      >
                        <BusinessIcon fontSize="large"></BusinessIcon>
                      </Avatar>
                      </div>
                      <div className="grow"></div>
                      <VagaName>

                        <h1 style={{margin:0}}>{item.titulo.toLowerCase()}</h1>
                        <div className="horizontal"></div>
                        <h5 style={{margin:0}}>{item.empresa.razao_social.toLowerCase()} • {item.empresa.endereco.municipio} , {item.empresa.endereco.estado}</h5>
                      </VagaName>
                    </Title>
                    <Info>
                      <Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                        <Grid sx={{display:'flex', flexDirection:"row",  alignItems: "center", }} item xs={6}>
                          <img  style={{marginRight: "2%"}} src={remoto} alt="modalidade" width="12%"/>
                          <h5 style={{margin: 0}}>{item.modalidade !== null? item.modalidade: "Não informado" }</h5>
                        </Grid>
                        <Grid sx={{display:'flex', flexDirection:"row",  alignItems: "center", }} item xs={6}>
                          <img  style={{marginRight: "2%"}} src={remuneracao} alt="remuneracao" width="12%"/>
                          <h5 style={{margin: 0}}> {item.remuneracao !== null? "R$" + item.remuneracao: "Não informado" }</h5>
                        </Grid>
                        <Grid sx={{display:'flex', flexDirection:"row",  alignItems: "center", }} item xs={6}>
                          <img  style={{marginRight: "2%"}} src={tipo} alt="tipo" width="12%"/>
                          <h5 style={{margin: 0}}>{item.tipo !== null? item.tipo: "Não informado" }</h5>
                        </Grid>
                        <Grid sx={{display:'flex', flexDirection:"row",  alignItems: "center", }} item xs={6}>
                          <img  style={{marginRight: "2%"}} src={horas} alt="horas" width="12%"/>
                          <h5 style={{margin: 0}}>{item.carga_horaria !== null? item.carga_horaria + " horas/semanais": "Não informado" }</h5>
                        </Grid>
                      </Grid>
                    </Info>
                    <Submit>
                      <Button onClick={(e)=>handleConfirmar(e,i)} className="butao"  >
                          Candidatar-se
                      </Button>
                      <div className="lastinfo">
                        <h5 className="encerramento"> {"Inscrições até " + item.data_encerramento.slice(8,10) + "/" + item.data_encerramento.slice(5,7) + "/"+ item.data_encerramento.slice(0,4)} </h5>
                        {/* <IconButton aria-label="delete">
                          <img className="salvar" src={naoSalvo} alt="Iconde de salvar vaga" width={20}></img>
                        </IconButton> */}
                      </div>
                    </Submit>
                  </CardContent>
                </OxeCard>
            </ContainerGrid>

          )
        })}


      {/* Modals */}
      <CardExpandido/>
      <Confirmar/>
      <Submeter/>

      {/* Alerts */}
      <Snackbar open={alertExists} autoHideDuration={6000} onClose={handleClose}>
        <Alert variant="filled" onClose={handleClose} severity="error" sx={{ width: '100%' }}>Você já se candidatou a esta vaga</Alert>
      </Snackbar>
      <Snackbar open={alertUnauthorized} autoHideDuration={6000} onClose={handleClose}>
        <Alert variant="filled" onClose={handleClose} severity="error" sx={{ width: '100%' }}>Não é possível se candidatar com um perfil institucional</Alert>
      </Snackbar>
      <Snackbar open={alertGeneric} autoHideDuration={6000} onClose={handleClose}>
        <Alert variant="filled" onClose={handleClose} severity="error" sx={{ width: '100%' }}>{alertGenericMessage}</Alert>
      </Snackbar>
      <Snackbar open={alertSuccessful} autoHideDuration={6000} onClose={handleClose}>
        <Alert variant="filled" onClose={handleClose} severity="success" sx={{ width: '100%' }}>Parabéns! Você se candidatou a vaga</Alert>
      </Snackbar>
                      
    </Grid>
    : <NoVagas>
      <img src={noResults} width="8%"  alt="Nenhuma vaga encontrada"/>
      <h1 style={{marginTop: '2%'}}>Nenhuma vaga encontrada para {busca}</h1>
    </NoVagas>   }
    </div>
  );
}else{
  return(
    <NoVagas>
      <img src={noFolder} width="8%"  alt="Sem vagas cadastradas no momento"/>
      <h3 style={{marginTop: '2%'}}>Sem vagas cadastradas no momento</h3>
    </NoVagas>   
  )
  
}
  
}




export default VagasOxedin;
import { makeStyles } from "@material-ui/styles";
import AddIcon from "@mui/icons-material/Add";
import ClearIcon from "@mui/icons-material/Clear";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import HelpIcon from "@mui/icons-material/Help";
import ListIcon from "@mui/icons-material/List";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import SaveIcon from "@mui/icons-material/Save";
import SearchOffIcon from "@mui/icons-material/SearchOff";
import StarIcon from "@mui/icons-material/Star";
import UndoIcon from "@mui/icons-material/Undo";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Checkbox from "@mui/material/Checkbox";
import Chip from "@mui/material/Chip";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import InputLabel from "@mui/material/InputLabel";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Modal from "@mui/material/Modal";
import OutlinedInput from "@mui/material/OutlinedInput";
import Rating from "@mui/material/Rating";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { SnackbarProvider, useSnackbar } from "notistack";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Options from "../../utils/options";

import { updateProfileRequest } from "../../store/modules/user/actions";
import {
    laranjaOXLTransparente,
    laranjaSecundarioTransp,
} from "../../utils/styledVariables";

const useStyles = makeStyles({
  CardRoot: {
    backgroundColor: laranjaOXLTransparente,
    borderRadius: "10px",
    boxShadow: "3px 3px 6px -2px #000000",
    height: "100% !important",
  },
});

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

function SkillCard() {
  const classes = useStyles();
  const profile = useSelector((state) => state.user.profile);
  const [name, setskillName] = React.useState([]);
  const [profileSkills, setProfileSkills] = React.useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [editable, setEditable] = React.useState(false);
  const [removable, setRemovable] = React.useState(false);
  const [addedable, setAddedable] = React.useState(false);
  const [listable, setListable] = React.useState(true);
  const [skillAddButtonDisabled, setSkillAddButtonDisabled] =
    React.useState(true);
  const [newCustomSkillHidden, setNewCustomSkillHidden] = React.useState(true);
  const [skillsChangeState, setSkillsChangeState] = React.useState(false);
  const [newSkills, setNewSkills] = React.useState([]);
  const [newCustomSkills, setNewCustomSkills] = React.useState([]);
  const [optionsSkills, setOptionsSkills] = React.useState(
    Options.optionsSkills
  );
  const [customSkillValue, setCustomSkillValue] = React.useState("");
  const [newStarLabel, setNewStarLabel] = React.useState("");
  const [newCustomSkillErrorMessage, setNewCustomSkillErrorMessage] =
    React.useState("");
  const [newCustomSkillErrorState, setNewCustomSkillErrorState] =
    React.useState(false);
  const [openHelpModal, setOpenHelpModal] = React.useState(false);
  const [saveState, setSaveState] = React.useState(false);
  const handleOpenHelpModal = () => setOpenHelpModal(true);
  const handleCloseHelpModal = () => setOpenHelpModal(false);
  const menuOpen = Boolean(anchorEl);
  const [itemRemoved, setItemRemoved] = React.useState(false);
  const [originalSkills, setOriginalSkills] = React.useState([]);

  const { enqueueSnackbar } = useSnackbar();

  const dispatch = useDispatch();

  function undoHandle() {
    setProfileSkills(originalSkills);
    setItemRemoved(false);
    setAnchorEl(null);
  }

  const customSkillHandleChange = (event) => {
    setCustomSkillValue(event.target.value);
  };

  const skillCheckboxHandleChange = (event) => {
    const newProfileSkills = profileSkills.map((item) => {
      if (item.name === event.target.name) {
        item.add_to_cv = event.target.checked;
      }
      return item;
    });
    setSkillsChangeState(true);
    setProfileSkills(newProfileSkills);
  };

  const newSkillCheckboxHandleChange = (event) => {
    const skillsToAdd = newSkills.map((item) => {
      if (item.name === event.target.name) {
        item.add_to_cv = event.target.checked;
      }
      return item;
    });
    setNewSkills(skillsToAdd);
  };

  const newCustomSkillCheckboxHandleChange = (event) => {
    const skillsToAdd = newCustomSkills.map((item) => {
      if (item.name === event.target.name) {
        item.add_to_cv = event.target.checked;
      }
      return item;
    });
    setNewCustomSkills(skillsToAdd);
  };

  function validate() {
    let levelEmpty;
    newSkills.map((item) => {
      if (item.rating === 0 || item.rating === null) {
        enqueueSnackbar(
          "Por favor, informe o nível da habilidade " + item.name + " !",
          { variant: "error" }
        );
        levelEmpty = true;
      }
    });
    newCustomSkills.map((item) => {
      if (item.rating === 0 || item.rating === null) {
        enqueueSnackbar(
          "Por favor, informe o nível da habilidade " + item.name + " !",
          { variant: "error" }
        );
        levelEmpty = true;
      }
    });
    profileSkills.map((item) => {
      if (item.rating === 0 || item.rating === null) {
        enqueueSnackbar(
          "Por favor, informe o nível da habilidade " + item.name + " !",
          { variant: "error" }
        );
        levelEmpty = true;
      }
    });

    if (!levelEmpty) {
      if (skillsChangeState) {
        profile.skills = profileSkills;

        dispatch(updateProfileRequest(profile));

        let optionsSkillsFiltered = optionsSkills.filter((item) => {
          let skillFound = false;
          profileSkills.map((profileItem) => {
            if (profileItem.name === item.value) {
              skillFound = true;
            }
          });

          if (!skillFound) return item;
        });
        setOptionsSkills(optionsSkillsFiltered);
      }

      setNewSkills([]);
      mergeSkills();
      setSkillAddButtonDisabled(true);
      setNewCustomSkills([]);
      setSaveState(true);
      showSkillList();
      setSkillsChangeState(false);
    }
  }

  function addNewCustomSkill() {
    if (customSkillValue.length < 1) return;

    let skillAlreadyInserted = false;

    optionsSkills.map((item) => {
      if (item.value.toLowerCase() === customSkillValue.toLowerCase()) {
        skillAlreadyInserted = true;
        setNewCustomSkillErrorState(true);
        setNewCustomSkillErrorMessage("Essa habilidade está na lista acima!");
      }
    });

    newCustomSkills.map((item) => {
      if (item.name.toLowerCase() === customSkillValue.toLowerCase()) {
        skillAlreadyInserted = true;
        setNewCustomSkillErrorState(true);
        setNewCustomSkillErrorMessage(
          "Habilidade já marcada para ser adicionada!"
        );
      }
    });

    profileSkills.map((item) => {
      if (item.name.toLowerCase() === customSkillValue.toLowerCase()) {
        skillAlreadyInserted = true;
        setNewCustomSkillErrorState(true);
        setNewCustomSkillErrorMessage(
          "Habilidade já está registrada no seu perfil!"
        );
      }
    });

    if (!skillAlreadyInserted) {
      setNewCustomSkills([
        { name: customSkillValue, rating: 0, add_to_cv: true },
        ...newCustomSkills,
      ]);
      setCustomSkillValue("");
      setNewCustomSkillErrorState(false);
      setNewCustomSkillErrorMessage("");
    } else {
      setTimeout(() => {
        setNewCustomSkillErrorState(false);
        setNewCustomSkillErrorMessage("");
      }, 5000);
    }
  }

  function mergeSkills() {
    Array.prototype.push.apply(newSkills, [
      ...newCustomSkills,
      ...profileSkills,
    ]);
    setProfileSkills(newSkills);
  }

  const skillMenuContextHandleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const menuContextHandleClose = () => {
    setAnchorEl(null);
  };

  const showSkillList = () => {
    setAddedable(false);
    setRemovable(false);
    setEditable(false);
    setListable(true);
  };

  const addHandleClose = () => {
    let optionsSkillsFiltered = optionsSkills.filter((item) => {
      let found = false;
      profileSkills.map((profileItem) => {
        if (profileItem.name === item.value) {
          found = true;
        }
      });

      if (!found) return item;
    });

    setOptionsSkills(optionsSkillsFiltered);

    setAddedable(true);
    setRemovable(false);
    setEditable(false);
    setListable(false);
  };

  const listHandleClose = () => {
    setAddedable(false);
    setRemovable(false);
    setEditable(false);
    setListable(true);
  };

  const editHandleClose = () => {
    setEditable(true);
    setAddedable(false);
    setRemovable(false);
    setListable(true);
  };

  const removeHandleClose = () => {
    setRemovable(true);
    setEditable(false);
    setAddedable(false);
    setListable(true);
  };

  useEffect(() => {
    if (profile.skills && profile.skills.length > 0) {
      if (profile.skills[0]?.name) {
        setProfileSkills(profile.skills);
        setOriginalSkills(profile.skills);
      }
    }
  }, []);

  useEffect(() => {
    if (saveState) {
      profile.skills = profileSkills;
      dispatch(updateProfileRequest(profile));
    }
  }, [saveState]);

  function setSkills(skills) {
    const newSkills = skills.map((item) => {
      return { name: item, rating: 0, add_to_cv: true };
    });
    let msg;
    if (skills.length === 0) {
      msg = "Nenhuma habilidade selecionada!";
    } else if (skills.length === 1) {
      msg = "Uma habilidade selecionada!";
    } else {
      msg = skills.length + " habilidades selecionadas!";
    }
    enqueueSnackbar(msg, { variant: "info" });
    setNewSkills(newSkills);
  }

  function setSkillRatingValue(newValue, name) {
    let newProfileSkills = profileSkills.map((item) => {
      if (item.name === name) {
        item.rating = newValue;
      }

      return item;
    });

    setProfileSkills(newProfileSkills);
  }

  function setNewSkillRatingValue(newValue, name) {
    const skillsToAdd = newSkills.map((item) => {
      if (item.name === name) {
        item.rating = newValue;
      }

      return item;
    });

    setNewSkills(skillsToAdd);
  }

  function setNewCustomSkillRatingValue(newValue, name) {
    const skillsToAdd = newCustomSkills.map((item) => {
      if (item.name === name) {
        item.rating = newValue;
      }

      return item;
    });

    setNewCustomSkills(skillsToAdd);
  }

  function removeSkill(skill) {
    let newProfileSkills = profileSkills.filter((item) => {
      if (item.name !== skill) return item;
    });
    setProfileSkills(newProfileSkills);
    setItemRemoved(true);
  }

  function removeCustomSkill(skill) {
    let newCustomSkillsFiltered = newCustomSkills.filter((item) => {
      if (item.name !== skill) return item;
    });
    setNewCustomSkills(newCustomSkillsFiltered);
  }

  const SkillSelectHandleChange = (event) => {
    const {
      target: { value },
    } = event;
    if (value.length === 0) {
      setSkillAddButtonDisabled(true);
    } else {
      setSkillAddButtonDisabled(false);
    }

    setSkills(value);
    setskillName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  return (
    <Box component="main" sx={{ flexGrow: 1, p: 0 }}>
      <Modal
        open={openHelpModal}
        onClose={handleCloseHelpModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Marque as opções de acordo com a legenda baixo:
          </Typography>
          <Box sx={{ mt: 1, display: "flex", alignItems: "center" }}>
            <Checkbox checked />
            <Typography variant="body2">
              Mostrar habilidade no currículo
            </Typography>
          </Box>
          <Box sx={{ mt: 0, display: "flex", alignItems: "center" }}>
            <Checkbox disabled />
            <Typography variant="body2">
              Não mostrar habilidade no currículo
            </Typography>
          </Box>
          <Box sx={{ mt: 1 }}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Rating
                emptyIcon={
                  <StarIcon style={{ opacity: 0.45 }} fontSize="inherit" />
                }
                readOnly
                value={1}
              />
              <Typography sx={{ ml: 1 }} variant="body2">
                Noções básicas
              </Typography>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Rating
                emptyIcon={
                  <StarIcon style={{ opacity: 0.45 }} fontSize="inherit" />
                }
                readOnly
                value={2}
              />
              <Typography sx={{ ml: 1 }} variant="body2">
                Iniciante
              </Typography>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Rating
                emptyIcon={
                  <StarIcon style={{ opacity: 0.45 }} fontSize="inherit" />
                }
                readOnly
                value={3}
              />
              <Typography sx={{ ml: 1 }} variant="body2">
                Intermediário
              </Typography>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Rating
                emptyIcon={
                  <StarIcon style={{ opacity: 0.45 }} fontSize="inherit" />
                }
                readOnly
                value={4}
              />
              <Typography sx={{ ml: 1 }} variant="body2">
                Avançado
              </Typography>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Rating
                emptyIcon={
                  <StarIcon style={{ opacity: 0.45 }} fontSize="inherit" />
                }
                readOnly
                value={5}
              />
              <Typography sx={{ ml: 1 }} variant="body2">
                Especialista
              </Typography>
            </Box>
          </Box>
        </Box>
      </Modal>
      <Card
        classes={{ root: classes.CardRoot }}
        sx={{ minHeight: "229px", height: "229px" }}
      >
        <CardContent>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography sx={{ fontWeight: 500 }} variant="h5" component="div">
              Habilidades
            </Typography>
            <Box>
              <MenuItem
                key="save"
                onClick={() => {
                  validate();
                  setAnchorEl(null);
                }}
                sx={{
                  display:
                    (removable && itemRemoved) || editable
                      ? "inline-flex"
                      : "none",
                }}
              >
                <Tooltip title={"Salvar Alterações"}>
                  <SaveIcon sx={{ mr: 1 }} />
                </Tooltip>
              </MenuItem>
              <MenuItem
                key="undo"
                onClick={undoHandle}
                sx={{ display: itemRemoved ? "inline-flex" : "none" }}
              >
                <Tooltip title={"Desfazer"}>
                  <UndoIcon />
                </Tooltip>
              </MenuItem>
              <IconButton
                aria-label="more"
                id="long-button"
                aria-controls={menuOpen ? "long-menu" : undefined}
                aria-expanded={menuOpen ? "true" : undefined}
                aria-haspopup="true"
                onClick={skillMenuContextHandleClick}
              >
                <MoreHorizIcon />
              </IconButton>
            </Box>
            <Menu
              id="long-menu"
              MenuListProps={{
                "aria-labelledby": "long-button",
              }}
              anchorEl={anchorEl}
              open={menuOpen}
              onClose={menuContextHandleClose}
              PaperProps={{
                style: {
                  maxHeight: ITEM_HEIGHT * 4.5,
                  width: "20ch",
                },
              }}
            >
              <MenuItem
                key="listarSkill"
                onClick={() => {
                  listHandleClose();
                  setAnchorEl(null);
                }}
                sx={{
                  display:
                    addedable || removable || editable ? "inline-flex" : "none",
                }}
              >
                <ListIcon sx={{ mr: 1 }} /> Listar
              </MenuItem>
              <MenuItem
                key="adicionarSkill"
                onClick={() => {
                  addHandleClose();
                  setAnchorEl(null);
                }}
              >
                <AddIcon sx={{ mr: 1 }} /> Adicionar
              </MenuItem>
              <MenuItem
                key="editarSkill"
                onClick={() => {
                  editHandleClose();
                  setSkillsChangeState(true);
                  setAnchorEl(null);
                }}
              >
                <EditIcon sx={{ mr: 1 }} /> Editar
              </MenuItem>
              <MenuItem
                key="removerSkill"
                onClick={() => {
                  removeHandleClose();
                  setSkillsChangeState(true);
                  setAnchorEl(null);
                }}
              >
                <DeleteIcon sx={{ mr: 1 }} /> Remover
              </MenuItem>
            </Menu>
          </Box>
          <Box>
            <FormControl sx={{ display: addedable ? "flex" : "none", m: 1 }}>
              <InputLabel id="demo-multiple-chip-label">
                Selecione Habilidades
              </InputLabel>
              <Select
                labelId="demo-multiple-chip-label"
                id="demo-multiple-chip"
                multiple
                value={name}
                onChange={SkillSelectHandleChange}
                input={
                  <OutlinedInput
                    id="select-multiple-chip"
                    label="Selecione Habilidades"
                  />
                }
                renderValue={(selected) => (
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                    {selected.map((value) => (
                      <Chip key={value} label={value} />
                    ))}
                  </Box>
                )}
                MenuProps={MenuProps}
              >
                {optionsSkills.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.value}
                  </MenuItem>
                ))}
              </Select>
              <Box
                sx={{
                  mt: 1,
                  display: addedable ? "block" : "none",
                  flexWrap: "wrap",
                }}
              >
                <Typography
                  sx={{ display: newSkills.length !== 0 ? "inline" : "none" }}
                  variant="caption"
                >
                  Habilidades selecionadas:
                </Typography>
                <Box
                  sx={{
                    gap: editable ? 2 : 0,
                    maxHeight: "136px",
                    overflowY: "scroll",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  {Array.from(newSkills).map((item, index) => {
                    return (
                      <Box key={"item-" + index}>
                        <Box sx={{ display: "flex", justifyContent: "end" }}>
                          <IconButton
                            onClick={handleOpenHelpModal}
                            aria-label="HelpModal"
                          >
                            <HelpIcon
                              sx={{
                                mr: 1,
                                mb: 1,
                                display: index === 0 ? "inline" : "none",
                              }}
                            />
                          </IconButton>
                        </Box>
                        <Box
                          sx={{
                            mb: 1,
                            display: "flex",
                            flexWrap: "wrap",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <Box
                            sx={{
                              //border: "0.5px solid #b9b9b9",
                              width: "120px",
                              minWidth: "120px",
                              maxWidth: "120px",
                              flexGrow: 1,
                              boxShadow: "3px 3px 6px -2px #000000",
                              fontWeight: 500,
                              textAlign: "center",
                              borderRadius: "5px",
                              backgroundColor:
                                laranjaSecundarioTransp /*'rgb(173, 137, 191, 0.5)'*/,
                              py: 0,
                            }}
                          >
                            <Tooltip title={item.name}>
                              <Typography
                                sx={{
                                  px: 1,
                                  width: "120px",
                                  minWidth: "120px",
                                  maxWidth: "120px",
                                }}
                                noWrap
                                variant="subtitle2"
                              >
                                {item.name}
                              </Typography>
                            </Tooltip>
                          </Box>
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <Tooltip title={newStarLabel}>
                              <Rating
                                size="small"
                                sx={{ pl: 1, py: 0.5 }}
                                value={item.rating}
                                onChangeActive={(event, newHover) => {
                                  let label;
                                  switch (true) {
                                    case newHover === 1: {
                                      label = "Noções Básicas";
                                      break;
                                    }
                                    case newHover === 2: {
                                      label = "Iniciante";
                                      break;
                                    }
                                    case newHover === 3: {
                                      label = "Intermediário";
                                      break;
                                    }
                                    case newHover === 4: {
                                      label = "Avançado";
                                      break;
                                    }
                                    case newHover === 5: {
                                      label = "Especialista";
                                      break;
                                    }
                                    default:
                                      label = "Nivel Desconhecido";
                                      break;
                                  }
                                  setNewStarLabel(label);
                                }}
                                onChange={(event, newValue) => {
                                  setNewSkillRatingValue(newValue, item.name);
                                }}
                              />
                            </Tooltip>
                            <IconButton
                              sx={{ display: removable ? "inline" : "none" }}
                              onClick={() => {
                                removeSkill(item.name);
                              }}
                              aria-label="delete"
                            >
                              <ClearIcon />
                            </IconButton>
                            <Tooltip
                              title={"Deixe marcado para mostrar no currículo"}
                            >
                              <Checkbox
                                checked={item.add_to_cv}
                                name={item.name}
                                onChange={newSkillCheckboxHandleChange}
                                inputProps={{ "aria-label": "controlled" }}
                              />
                            </Tooltip>
                          </Box>
                        </Box>
                      </Box>
                    );
                  })}
                </Box>
              </Box>
              <Button
                sx={{ display: !newCustomSkillHidden ? "none" : "flex" }}
                onClick={() => setNewCustomSkillHidden(false)}
                variant="text"
              >
                + habilidade não listada
              </Button>
              {/* custom skills list */}
              <Box
                sx={{
                  mt: 1,
                  alignItems: "center",
                  display: newCustomSkillHidden ? "none" : "flex",
                }}
              >
                <TextField
                  error={newCustomSkillErrorState}
                  helperText={newCustomSkillErrorMessage}
                  sx={{ flexGrow: 1 }}
                  value={customSkillValue}
                  onChange={customSkillHandleChange}
                  size="small"
                  id="outlined-basic"
                  label="Habilidade não listada"
                  variant="filled"
                />
                <IconButton
                  onClick={addNewCustomSkill}
                  size="medium"
                  aria-label="add"
                >
                  <AddIcon />
                </IconButton>
              </Box>
              <Box
                sx={{
                  mt: 1,
                  display: addedable ? "block" : "none",
                  flexWrap: "wrap",
                }}
              >
                <Typography
                  sx={{
                    display: newCustomSkills.length !== 0 ? "inline" : "none",
                  }}
                  variant="caption"
                >
                  Habilidades não listadas:
                </Typography>
                <Box
                  sx={{
                    gap: editable ? 2 : 0,
                    maxHeight: "136px",
                    overflowY: "scroll",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  {Array.from(newCustomSkills).map((item, index) => {
                    return (
                      <Box key={"item-" + index}>
                        <Box sx={{ display: "flex", justifyContent: "end" }}>
                          <IconButton
                            onClick={handleOpenHelpModal}
                            aria-label="HelpModal"
                          >
                            <HelpIcon
                              sx={{
                                mr: 1,
                                mb: 1,
                                display: index === 0 ? "inline" : "none",
                              }}
                            />
                          </IconButton>
                        </Box>
                        <Box
                          sx={{
                            mb: 1,
                            display: "flex",
                            flexWrap: "wrap",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <Box
                            sx={{
                              //border: "0.5px solid #b9b9b9",
                              width: "120px",
                              minWidth: "120px",
                              maxWidth: "120px",
                              flexGrow: 1,
                              boxShadow: "3px 3px 6px -2px #000000",
                              fontWeight: 500,
                              textAlign: "center",
                              borderRadius: "5px",
                              backgroundColor:
                                laranjaSecundarioTransp /*'rgb(173, 137, 191, 0.5)'*/,
                              py: 0,
                            }}
                          >
                            <Tooltip title={item.name}>
                              <Typography
                                sx={{
                                  px: 1,
                                  width: "120px",
                                  minWidth: "120px",
                                  maxWidth: "120px",
                                }}
                                noWrap
                                variant="subtitle2"
                              >
                                {item.name}
                              </Typography>
                            </Tooltip>
                          </Box>
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <Tooltip title={newStarLabel}>
                              <Rating
                                size="small"
                                sx={{ pl: 1, py: 0.5 }}
                                name="read-only"
                                value={item.rating}
                                onChangeActive={(event, newHover) => {
                                  let label;
                                  switch (true) {
                                    case newHover === 1: {
                                      label = "Noções Básicas";
                                      break;
                                    }
                                    case newHover === 2: {
                                      label = "Iniciante";
                                      break;
                                    }
                                    case newHover === 3: {
                                      label = "Intermediário";
                                      break;
                                    }
                                    case newHover === 4: {
                                      label = "Avançado";
                                      break;
                                    }
                                    case newHover === 5: {
                                      label = "Especialista";
                                      break;
                                    }
                                    default:
                                      label = "Nivel Desconhecido";
                                      break;
                                  }
                                  setNewStarLabel(label);
                                }}
                                onChange={(event, newValue) => {
                                  setNewCustomSkillRatingValue(
                                    newValue,
                                    item.name
                                  );
                                }}
                              />
                            </Tooltip>
                            <Tooltip
                              title={"Deixe marcado para mostrar no currículo"}
                            >
                              <Checkbox
                                checked={item.add_to_cv}
                                name={item.name}
                                onChange={newCustomSkillCheckboxHandleChange}
                                inputProps={{ "aria-label": "controlled" }}
                              />
                            </Tooltip>
                            <IconButton
                              onClick={() => {
                                removeCustomSkill(item.name);
                              }}
                              aria-label="delete"
                            >
                              <ClearIcon />
                            </IconButton>
                          </Box>
                        </Box>
                      </Box>
                    );
                  })}
                </Box>
              </Box>
              <Button
                onClick={() => {
                  validate();
                }}
                disabled={
                  skillAddButtonDisabled && newCustomSkills.length === 0
                }
                sx={{ mt: 1 }}
                variant="contained"
                color="success"
              >
                Adicionar Todas
              </Button>
            </FormControl>
          </Box>
          {profileSkills.length === 0 ? (
            <Box
              sx={{
                height: "150px",
                display: listable ? "flex" : "none",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Box
                sx={{
                  gap: 1,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <SearchOffIcon sx={{ fontSize: "48px" }} />
                <Typography sx={{ p: 1, borderRadius: "15px" }} variant="h6">
                  Nenhum registro encontrado!
                </Typography>
              </Box>
            </Box>
          ) : (
            <Box
              sx={{ display: listable ? "block" : "none", flexWrap: "wrap" }}
            >
              <Box
                sx={{
                  gap: editable ? 2 : 0,
                  maxHeight: "136px",
                  overflowY: "scroll",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                {Array.from(profileSkills).map((item, index) => {
                  return (
                    <Box
                      key={"item-" + index}
                      sx={{
                        mb: 1,
                        display: "flex",
                        flexWrap: "wrap",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Box
                        sx={{
                          //border: "0.5px solid #b9b9b9",
                          width: "120px",
                          minWidth: "120px",
                          maxWidth: "120px",
                          flexGrow: 1,
                          boxShadow: "3px 3px 6px -2px #000000",
                          fontWeight: 500,
                          textAlign: "center",
                          borderRadius: "5px",
                          backgroundColor:
                            laranjaSecundarioTransp /*'rgb(173, 137, 191, 0.5)'*/,
                          py: 0,
                        }}
                      >
                        <Tooltip title={item.name}>
                          <Typography
                            sx={{
                              px: 1,
                              width: "120px",
                              minWidth: "120px",
                              maxWidth: "120px",
                            }}
                            noWrap
                            variant="subtitle2"
                          >
                            {item.name}
                          </Typography>
                        </Tooltip>
                      </Box>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Tooltip title={newStarLabel}>
                          <Rating
                            size="small"
                            sx={{ pl: 1, py: 0.5 }}
                            name="read-only"
                            value={item.rating}
                            readOnly={editable ? false : true}
                            onChangeActive={(event, newHover) => {
                              let label;
                              switch (true) {
                                case newHover === 1: {
                                  label = "Noções Básicas";
                                  break;
                                }
                                case newHover === 2: {
                                  label = "Iniciante";
                                  break;
                                }
                                case newHover === 3: {
                                  label = "Intermediário";
                                  break;
                                }
                                case newHover === 4: {
                                  label = "Avançado";
                                  break;
                                }
                                case newHover === 5: {
                                  label = "Especialista";
                                  break;
                                }
                                default:
                                  label = "Nivel Desconhecido";
                                  break;
                              }
                              setNewStarLabel(label);
                            }}
                            onChange={(event, newValue) => {
                              setSkillRatingValue(newValue, item.name);
                            }}
                          />
                        </Tooltip>
                        <IconButton
                          sx={{ display: removable ? "inline" : "none" }}
                          onClick={() => {
                            removeSkill(item.name);
                          }}
                          aria-label="delete"
                        >
                          <ClearIcon />
                        </IconButton>
                        <Tooltip
                          sx={{
                            display: editable || listable ? "inline" : "none",
                          }}
                          title={"Deixe marcado para mostrar no currículo"}
                        >
                          <Checkbox
                            checked={item.add_to_cv}
                            name={item.name}
                            disabled={!editable}
                            onChange={skillCheckboxHandleChange}
                            inputProps={{ "aria-label": "controlled" }}
                          />
                        </Tooltip>
                      </Box>
                    </Box>
                  );
                })}
              </Box>
            </Box>
          )}
        </CardContent>
      </Card>
    </Box>
  );
}

export default function IntegrationNotistack() {
  return (
    <SnackbarProvider maxSnack={3}>
      <SkillCard />
    </SnackbarProvider>
  );
}

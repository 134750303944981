import BusinessIcon from "@mui/icons-material/Business";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import Button from "@mui/material/Button";
import Box from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import * as React from "react";
import { Link } from "react-router-dom";
import MainHeader from "../../components/MainHeader";
import {
  gradienteLaranja180,
  laranjaIntermediario,
  laranjaMain,
  profileWhiteShade
} from "../../utils/styledVariables";

const BlueBox = styled(Box)`
  width: 40rem;
  height: 28rem;
  position: absolute;
  left: 0px;
  right: 0px;
  top: 90px;
  bottom: 0px;
  margin: auto auto auto auto;
  border-radius: 0.5rem;
  color: #fff;
  overflow: hidden;
  text-align: center;
  background: ${profileWhiteShade};
  animation: fadeIn;
  animation-duration: 1s;

  @media (max-width: 700px) {
    width: 38rem;
    height: 45rem;
  }
  @media (max-width: 360px) {
    width: 33rem;
    height: 45rem;
  }
`;

const ColorButton = styled(Button)(({ theme }) => ({
  // color: theme.palette.getContrastText("#ffffff"),
  backgroundColor: laranjaIntermediario,
  color: profileWhiteShade,
  "&:hover": {
    backgroundColor: laranjaMain,
  },
}));

const UseStyles = makeStyles((theme) => ({
  root: {
    overflow: "hidden",
    background: gradienteLaranja180,
    //'linear-gradient(180deg,rgba(116, 31, 138, 0.9) 35%,rgba(0, 0, 99, 1) 100%)',
    height: "100vh",
  },
  paperContainer: {
    backgroundImage: `url(/assets/backgroundSelect.png)`,
    height: "100vh",
    minWidth: "100vw",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  },
}));

export default function selectSignUp() {
  const classes = UseStyles();
  return (
    <React.Fragment>
      <CssBaseline />
      <MainHeader />
      <div className={classes.root}>
        <div className={classes.paperContainer} />
        <BlueBox>
          <Typography
            variant="h4"
            component="div"
            gutterBottom
            sx={{
              mt: 4,
              color: laranjaIntermediario,
            }}
          >
            QUAL É O SEU TIPO DE CADASTRO?
          </Typography>
          <Stack spacing={6} sx={{ mt: 9 }}>
            <Link to="/cadastro">
              <ColorButton
                variant="contained"
                sx={{
                  width: "100%",
                }}
                size="large"
              >
                <PeopleAltIcon
                  sx={{ mr: 1, color: profileWhiteShade, fontSize: "32px" }}
                />
                Pessoa Física
              </ColorButton>
            </Link>
            <Link to="/empresa-cadastro">
              <ColorButton
                variant="contained"
                sx={{
                  width: "100%",
                }}
                size="large"
              >
                <BusinessIcon
                  sx={{ mr: 1, color: profileWhiteShade, fontSize: "32px" }}
                />
                Pessoa Jurídica
              </ColorButton>
            </Link>
            <Link to="/login">
              <ColorButton
                variant="contained"
                sx={{
                  width: "100%",
                }}
                size="large"
              >
                <ExitToAppIcon
                  sx={{ mr: 1, fontSize: "25px", color: profileWhiteShade }}
                />
                Já tem conta? Faça seu login!
              </ColorButton>
            </Link>
          </Stack>
        </BlueBox>
      </div>
    </React.Fragment>
  );
}

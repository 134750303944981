import {
  Box,
  Button,
  Grid,
  Modal,
  Paper,
  TextField,
  Typography,
  IconButton,
  Tooltip,
  CircularProgress,
  Snackbar,
} from "@mui/material";
import alterar from "../../assets/iconesOxeLab/alterar.svg";
import Alert from "@mui/material/Alert";
import { makeStyles } from "@mui/styles";
import cep from "cep-promise";
import React, { useEffect, useState } from "react";
import InputMask from "react-input-mask";
import api from "../../services/api";
import * as palette from "../../utils/styledVariables";
import Buscador from "../Buscador";

import EditAgentes from "./EditAgentes";
const useStyles = makeStyles({
  autocomplete: {
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "purple",
    },
    "& label.Mui-focused": {
      color: "purple",
    },
  },
});

const styles = {
  modal: {
    width: "50%",
    position: "absolute",
    top: "50%",
    marginTop: "10%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "#f2f2f2",
    padding: "14px",
    textAlign: "center",
    borderRadius: "1vh",
    justifyContent: "center",
    overflow: "auto",
  },

  uploadButton: {
    height: "40px",
    color: palette.laranjaSecundario,
    "&:hover": {
    color: palette.laranjaIntermediario2
  }
  },

  paper: {
    width: "100%",
    margin: "5px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  box: {
    backgroundColor: palette.laranjaOXLTransparente,
    padding: "10px",
    margin: "5px",
    borderRadius: "1vh",
    display: "flex",
    // flexDirection: "column",
  },
  subtitles: {
    fontFamily: "Roboto",
    fontStyle: "bold",
    fontWeight: "500",
    fontSize: "1.5vh",
    lineHeight: "2.5vh",
    margin: "0px 0px 5px 0px",
    textAlign: "left",
  },

  field: { padding: "5px" },
};

function ModalEditCadastroLab(props) {
  const [editLab, setEditLab] = useState(null);

  const [rua, setRua] = useState("");
  const [bairro, setBairro] = useState("");
  const [state, setState] = useState("");
  const [municipio, setMunicipio] = useState("");
  const [numero, setNumero] = useState("");
  const [complemento, setComplemento] = useState("");
  const [CEP, setCEP] = useState("");
  const [unidade, setUnidade] = useState("");
  const [coordenador, setCoordenador] = useState(null);

  const [ruaError, setRuaError] = useState("");
  const [bairroError, setBairroError] = useState("");
  const [municipioError, setMunicipioError] = useState("");
  const [numeroError, setNumeroError] = useState("");
  const [CEPError, setCEPError] = useState("");
  const [unidadeError, setUnidadeError] = useState("");
  const [coordenadorError, setCoordenadorError] = useState("");
  const [exibirTrocaCoordenador, setExibirTrocaCoordenador] = useState(false);

  const [alertSuccessful, setAlertSuccessful] = useState("");
  const [alertUnauthorized, setAlertUnauthorized] = useState("");

  const [address, setAddress] = useState(null);
  const [errorCep, setErrorCep] = useState(null);
  const [alunos, setAlunos] = useState([]);
  const [loading, setLoading] = useState(false);

  // const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
  const alunoAgentes = alunos.filter((aluno) => aluno.cpf !== coordenador?.cpf);
  const classes = useStyles();
  useEffect(() => {
    if (props.isEdit !== null) {
      api
        .get(`laboratorio/${props.isEdit}`)
        .then((response) => {
          setEditLab(response.data);
          setRua(response.data.rua);
          setBairro(response.data.bairro);
          setState(response.data.state);
          setMunicipio(response.data.municipio);
          setNumero(response.data.numero);
          setComplemento(response.data.complemento);
          setCEP(response.data.cep);
          setUnidade(response.data.nome);
          setCoordenador(response.data.Coordenador);
        })
        .catch((error) => {
          console.log(error);
        });
  
      api.get("alunos").then((res) => {
        setAlunos(res.data);
      });
    }
  }, [props.isEdit]);
  

  const handleClose = () => {
    props.close();
    setExibirTrocaCoordenador(false)
  };

  const handleCep = (event) => {
    setCEP(event.target.value);
    setErrorCep({ CEP: "" });

    let reg = new RegExp(/^[0-9]{2}\.[0-9]{3}-[0-9]{3}$/).test(
      event.target.value
    );
    if (reg) {
      cep(event.target.value)
        .then((response) => {
          const {
            street: rua,
            neighborhood: bairro,
            city: municipio,
            cep,
            state: estado,
          } = response;

          setRua(rua);
          setBairro(bairro);
          setMunicipio(municipio);
          setState(estado);
        })
        .catch((error) => {
          setErrorCep({ CEP: "Não conseguimos resgatar o seu CEP." });
          setAddress({
            rua: "",
            bairro: "",
            municipio: "",
            cep: "",
            state: "",
            numero: "",
          });
        });
    } else {
      setErrorCep({ CEP: "CEP inválido." });
    }
  };

  const handlerua = (event) => {
    setRua(event.target.value);
  };

  const handlebairro = (event) => {
    setBairro(event.target.value);
  };

  const handlenumero = (event) => {
    setNumero(event.target.value);
  };

  const handlemunicipio = (event) => {
    setMunicipio(event.target.value);
  };

  const handleFinish = async () => {
    let isValid = true;

    if (!coordenador) {
      // alert("Adicione um coordenador")
      setCoordenadorError("Campo obrigatório");
      isValid = false;
    } else {
      setCoordenadorError("");
    }

    if (!rua) {
      setRuaError("Campo obrigatório");
      isValid = false;
    } else {
      setRuaError("");
    }

    if (!bairro) {
      setBairroError("Campo obrigatório");
      isValid = false;
    } else {
      setBairroError("");
    }

    if (!municipio) {
      setMunicipioError("Campo obrigatório");
      isValid = false;
    } else {
      setMunicipioError("");
    }

    if (!numero) {
      setNumeroError("Campo obrigatório");
      isValid = false;
    } else {
      setNumeroError("");
    }

    if (!CEP) {
      setCEPError("Campo obrigatório");
      isValid = false;
    } else {
      setCEPError("");
    }

    if (!unidade) {
      setUnidadeError("Campo obrigatório");
      isValid = false;
    } else {
      setUnidadeError("");
    }

    if (isValid) {
      const data = {
        nome: unidade,
        municipio: municipio,
        rua: rua,
        bairro: bairro,
        numero: numero,
        cep: CEP.replace(/[.-]/g, ""),
        complemento: complemento,
        coordenador_id: coordenador.id,
      };
      setLoading(true);

      api
        .put(`/laboratorio/update?lab_id=${props.isEdit}`, data)
        .then((response) => {
          setLoading(false);
          setAlertSuccessful(response.data.message);
          setTimeout(() => {
            handleClose()
            setAlertSuccessful("")
          }, 1000);
        })
        .catch((err) => {
          setLoading(false);
          setAlertUnauthorized(err.response.data);
        });
    }
  };

  // const ConfirmationModal = () => {
  //   const   handleDelete = () => {
  //     api
  //     .delete(`/laboratorio/delete?lab_id=${props.isEdit}`)
  //     .then((response) => {
  //       setAlertSuccessful(response.data.message);
  //       setTimeout(() => {
  //         window.location.reload();
  //       }, 1000);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  //   };
  // console.log(openConfirmDelete)
  //   return (
  //     <Modal open={openConfirmDelete} onClose={()=>setOpenConfirmDelete(false)} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} >
  //       <div style={{ padding: '20px', backgroundColor: 'white' }}>
  //         <Typography variant="h6" gutterBottom>
  //           Confirmar exclusão de laboratório
  //         </Typography>
  //         <Typography variant="body1" gutterBottom>
  //           Tem certeza de que deseja excluir este laboratório?
  //         </Typography>
  //         <div style={{ marginTop: '20px', textAlign: 'right' }}>
  //           <Button variant="contained" color="secondary" onClick={()=> setOpenConfirmDelete(false)} style={{ marginRight: '10px' }}>
  //             Cancelar
  //           </Button>
  //           <Button variant="outlined" onClick={handleDelete} color="error" startIcon={<DeleteIcon />}>
  //             Excluir
  //           </Button>
  //         </div>
  //       </div>
  //     </Modal>
  //   );
  // };
  const onSelectCoordenador = (e, newValue) => {
    setCoordenador(newValue.value);
  };

  const dataBuscadorCoordenador = alunos.map((obj) => ({
    name: obj.name,
    label: obj.cpf,
    value: obj,
  }));

  return (
    <Modal
      open={props.open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        overflow: "auto",
      }}
    >
      <Box sx={{}}>
        <Grid sx={styles.modal} container>
          <Grid container item xs={12} sm={12} md={12} lg={12}>
            <Paper
              sx={[
                {
                  backgroundColor: palette.laranjaOXLTransparente,
                  height: "50px",
                },
                styles.paper,
              ]}
            >
              <Typography
                sx={{
                  fontSize: "24px",
                  color: palette.preto,
                  fontFamily: "Poppins",
                  fontWeight: 500,
                }}
              >
                Edição de Laboratório
              </Typography>
            </Paper>
          </Grid>
          <Grid container spacing={2} sx={styles.box}>
            <Typography
              sx={{
                fontSize: "20px",
                color: palette.preto,
                fontFamily: "Poppins",
                fontWeight: 500,
                margin: "2px",
                textAlign: "left",
              }}
            >
              Dados dos responsáveis
            </Typography>
            <Grid item xs={12} md={12} sx={styles.field}>
              <Typography sx={styles.subtitles}>
                Coordenador do Laboratório
              </Typography>
              <div style={{ display: exibirTrocaCoordenador? 'none': 'flex', alignItems: "center" }}>
                <h4
                  style={{
                    fontWeight: "initial",
                    textAlign: "start",
                    marginLeft: "8px",
                    marginBottom: 0,
                  }}
                >
                  {coordenador?.name} - {coordenador?.cpf}{" "}
                </h4>
                <Tooltip title="Alterar coordenador">
                  <IconButton
                    aria-label="change"
                    onClick={() => setExibirTrocaCoordenador(true)}
                  >
                    <img src={alterar} alt="alterar" width="15px" />
                  </IconButton>
                </Tooltip>
              </div>
              <div style={{display: exibirTrocaCoordenador? 'flex': 'none', width: '100%'}} >
              <Buscador
                onSelect={onSelectCoordenador}
                data={dataBuscadorCoordenador}
                multiple={false}
                label={"Selecione o coordenador por CPF"}
                
                sx={{width: '100%'}}
              />
              </div>
            </Grid>
            <EditAgentes
              alunos={alunoAgentes}
              styles={styles}
              classes={classes}
              isEdit={props.isEdit}
              coordenador={coordenador?.cpf}
            />
          </Grid>

          <Grid container sx={styles.box}>
            <Typography
              sx={{
                fontSize: "20px",
                color: palette.preto,
                fontFamily: "Poppins",
                fontWeight: 500,
                marginBottom: "6px",
                textAlign: "left",
              }}
            >
              Endereço do Laboratório
            </Typography>

            <Grid container columns={2} sx={{ padding: "10px" }} spacing={1}>
              <Grid item xs={2} sm={2} md={1}>
                <Typography sx={styles.subtitles}>CEP</Typography>

                <InputMask
                  mask="99.999-999"
                  value={CEP}
                  maskChar=" "
                  onBlur={handleCep}
                  onChange={handleCep}
                >
                  {() => (
                    <TextField
                      hiddenLabel
                      id="cep"
                      autoComplete="cep"
                      variant="filled"
                      fullWidth
                      error={Boolean(errorCep?.CEP) || Boolean(CEPError)}
                      helperText={errorCep?.CEP || CEPError}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  )}
                </InputMask>
              </Grid>

              <Grid item xs={2} sm={2} md={1}>
                <Typography sx={styles.subtitles}>Rua</Typography>
                <TextField
                  hiddenLabel
                  id="rua"
                  variant="filled"
                  fullWidth
                  value={rua}
                  onChange={handlerua}
                  error={Boolean(ruaError)}
                  helperText={ruaError}
                  required
                />
              </Grid>

              <Grid item xs={2} sm={2} md={1}>
                <Typography sx={styles.subtitles}>Município</Typography>
                <TextField
                  hiddenLabel
                  id="municipio"
                  variant="filled"
                  fullWidth
                  value={municipio}
                  onChange={handlemunicipio}
                  error={Boolean(municipioError)}
                  helperText={municipioError}
                  required
                />
              </Grid>
              <Grid item xs={2} sm={2} md={1}>
                <Typography sx={styles.subtitles}>Bairro</Typography>
                <TextField
                  hiddenLabel
                  id="bairro"
                  variant="filled"
                  fullWidth
                  value={bairro}
                  onChange={handlebairro}
                  error={Boolean(bairroError)}
                  helperText={bairroError}
                  required
                />
              </Grid>
              <Grid item xs={2} md={2}>
                <Typography sx={styles.subtitles}>Complemento</Typography>
                <TextField
                  hiddenLabel
                  id="municipio"
                  variant="filled"
                  fullWidth
                  value={complemento}
                  onChange={(e) => setComplemento(e.target.value)}
                />
              </Grid>
              <Grid item xs={2} sm={2} md={1}>
                <Typography sx={styles.subtitles}>Número</Typography>
                <TextField
                  hiddenLabel
                  id="numero"
                  variant="filled"
                  fullWidth
                  value={numero}
                  onChange={handlenumero}
                  error={Boolean(numeroError)}
                  helperText={numeroError}
                  required
                />
              </Grid>

              <Grid item xs={2} sm={2} md={1}>
                <Typography sx={styles.subtitles}>
                  Unidade{" "}
                  <span style={{ fontSize: "1em", color: "grey" }}>
                    (Benedito Bentes, Maceió...)
                  </span>{" "}
                </Typography>
                <TextField
                  hiddenLabel
                  id="municipio"
                  variant="filled"
                  fullWidth
                  value={unidade}
                  onChange={(e) => setUnidade(e.target.value)}
                  error={Boolean(unidadeError)}
                  helperText={unidadeError}
                  required
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            md={12}
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              padding: "0 1%",
            }}
          >
            {/* <Button variant="outlined" onClick={() => setOpenConfirmDelete(true)} color="error" startIcon={<DeleteIcon />}>
            Excluir Laboratório
          </Button> */}
            <Button
              variant="contained"
              onClick={handleFinish}
              startIcon={
                loading ? <CircularProgress color="inherit" size={20} /> : null
              }
              sx={{
                height: "40px",
                bgcolor: palette.laranjaIntermediario2,
    "&:hover": {
    bgcolor: palette.laranjaSecundario
  },
                marginTop: "5px",
              }}
            >
              Editar Laboratório
            </Button>
          </Grid>
        </Grid>
        <Snackbar
          open={alertUnauthorized.length > 0}
          autoHideDuration={6000}
          onClose={() => setAlertUnauthorized("")}
        >
          <Alert
            variant="filled"
            onClose={() => setAlertUnauthorized("")}
            severity="error"
            sx={{ width: "100%" }}
          >
            {alertUnauthorized}
          </Alert>
        </Snackbar>
        <Snackbar
          open={alertSuccessful.length > 0}
          autoHideDuration={6000}
          onClose={() => setAlertSuccessful("")}
        >
          <Alert
            variant="filled"
            onClose={() => setAlertSuccessful("")}
            severity="success"
            sx={{ width: "100%" }}
          >
            {alertSuccessful}
          </Alert>
        </Snackbar>
        {/* <ConfirmationModal/> */}
      </Box>
    </Modal>
  );
}

export default ModalEditCadastroLab;

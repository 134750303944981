import * as React from 'react'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'

import { Link } from 'react-router-dom'

import { Item, Caixa } from './styles'

import { Typography } from '@mui/material';

import lampada from '../../../../src/assets/lampada.png'

import './styles.css'

export default function QuerSaberMais() {
    return (
        <Caixa>
            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignContent: 'center'}}>
                <Typography sx={{ p: 2, textAlign: 'center', fontWeight: 500, fontFamily: 'Poppins', color: '#FFFFFF' }} variant="h2">
                    Quer saber mais?
                </Typography>
                <Box className='texto'>
                    <Typography className='tex' variant="subtitle1">
                        O OxeTech Work é uma política pública idealizada pela Secretaria de Estado da Ciência, da Tecnologia e da Inovação de Alagoas (SECTI) com o objetivo de fomentar a economia alagoana, através de um programa que integre, de maneira direta, a capacitação de alunos com vagas de trabalho no mercado tecnológico e inovador, transferindo renda, gerando empregos e qualificando a população. 
                    </Typography>
                    <Typography className='tex' variant="subtitle1">
                        Alagoas e seus Municípios tem demonstrado expressivo potencial não só em seu fator de crescimento econômico, como também em relação ao seu mercado de tecnologia e inovação. Assim, nasce como um programa que tem por meta, abraçar o cenário atual, que visa incentivar o movimento de capacitação técnica e profissional para o povo alagoano e joga-los diretamente no mercado.
                    </Typography>
                    <Typography className='tex'  variant="subtitle1">
                        A partir disso, o OxeTech Work pretende oferecer uma nova alternativa ao mercado tecnológico alagoano, para sensibilizar o ecossistema local, por meio de uma capacitação técnica e profissional em tecnologia da informação e segmentos correlatos, aos cidadãos locais, facilitando a integração e sensibilização de empresários, alunos, profissionais e professores, por meio da implantação de uma rede de polos de educação à distância. Destarte, o programa que em dois meses atingiu 1402 alunos, vem trazendo uma nova proposta: um intercâmbio de conhecimentos entre alunos e empresas, garantindo uma vaga no mercado e fomentando a economia alagoana.
                    </Typography>
                </Box>
            </Box>
            <Box className='lampada'>
                <img className='img' src={lampada} alt={lampada} />
            </Box>
        </Caixa>
    )
}

import { takeLatest, call, put, all } from "redux-saga/effects";
import { toast } from "react-toastify";
import { message } from "antd";

import history from "../../../services/history";
import api from "../../../services/api";

import {
  updateProfileSuccess,
  updateProfileFailure,
  updateFirstStepsSuccess,
  updateFirstStepsFailure,
  updateOfficeSuccess,
  updateOfficeFailure,
  updateProfileEmpresaSuccess,
  updateProfileEmpresaFailure,
} from "./actions";

export function* updateProfile({ payload }) {
  try {
    const { responsabilidade, ...data } = payload.data;
    const response = yield call(api.put, "aluno/update", data);

    yield put(updateProfileSuccess(response.data.dataValues));

    window.location.reload();
    message.success("Perfil atualizado com sucesso!");
  } catch (err) {
    message.error(err.response.data.error);
    yield put(updateProfileFailure());
  }
}

export function* finishProfile({ payload }) {
  try {
    const response = yield call(
      api.put,
      `aluno/finished/${payload.data.id_hash}`,
      payload.data.validationData
    );

    yield put(updateProfileSuccess(response.data.dataValues));

    message.success("Perfil atualizado com sucesso!");

    history.push("/");
  } catch (err) {
    message.error(err.response.data.error);
    yield put(updateProfileFailure());
  }
}

export function* updateProfileEmpresa({ payload }) {
  try {
    const response = yield call(api.put, "empresa/update", payload.data);

    yield put(updateProfileEmpresaSuccess(response.data));

    message.success("Cadastro atualizado com sucesso!");

    history.push("/");
  } catch (err) {
    message.error(err.response.data.error);
    yield put(updateProfileEmpresaFailure());
  }
}

export function* updateFirstSteps({ payload }) {
  try {
    const { data } = payload;

    const response = yield call(api.patch, "updatefirststeps", data);

    yield put(updateFirstStepsSuccess(response.data));
  } catch (err) {
    yield put(updateFirstStepsFailure());
  }
}

export function* updateOffice({ payload }) {
  try {
    const { data } = payload;

    const response = yield call(api.put, "escritorio", data);

    yield put(updateOfficeSuccess(response.data));

    toast.success("Perfil atualizado com sucesso!");

    history.push("/escritorio");
  } catch (err) {
    yield put(updateOfficeFailure());
  }
}

export function* seeNotifications({ payload }) {
  try {
    const { user, url } = payload;
    const response = yield call(api.patch, url, user);
    console.log(response);
  } catch (err) {
    console.log(err);
  }
}

export default all([
  takeLatest("@user/FINISH_PROFILE_REQUEST", finishProfile),
  takeLatest("@user/UPDATE_PROFILE_REQUEST", updateProfile),
  takeLatest("@user/UPDATE_FIRST_STEPS_REQUEST", updateFirstSteps),
  takeLatest("@user/UPDATE_OFFICE_REQUEST", updateOffice),
  takeLatest("@user/UPDATE_PROFILE_EMPRESA_REQUEST", updateProfileEmpresa),
  takeLatest("@user/SEE_NOTIFICATIONS", seeNotifications),
]);

import React, { useState, useEffect } from 'react';
import { 
  Modal,
  Form,
  Input,
  Button,
  message,
  Select,
  InputNumber
} from 'antd';
import { isValidPhone } from '@brazilian-utils/brazilian-utils'

import api from "../../services/api";

function CadastroVagaAdm() {

    const { Option } = Select;


    const [visible, setVisible] = useState();
    const [empresas, setEmpresas] = useState([]);
    
    const handleCancel = () => setVisible(false);
    const handleOk = () => setVisible(true);
  
    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 13 },
    };
  
    const tailLayout = {
      wrapperCol: { offset: 10, span: 16 },
    };
  
    const onFinish = async (data) => {
      try {
            const { titulo, descricao, info_adicionais, qualificacoes, requisitos, empresa, quantidade, ...rest } = data;
               
            const response = await api.post("vagas/admcreate", { 
                titulo,
                descricao,
                info_adicionais,
                qualificacoes,
                requisitos,
                quantidade,
                empresa: empresa === "OUTRA" ? rest : empresa
            });
            
            setVisible(false);
            message.success(response.data.message);
            window.location.reload();
        } catch (err) {
            if (err.response.data === "COMPANY_DOES_NOT_EXIST"){
                message.error("Empresa não Existe");
            } else {
                message.error(err.response.data);
            }
        }
    };

    useEffect(() => {
        api.get("empresa/empresas").then((response) => {
            setEmpresas(response.data)
        })
    }, []);
  
    return (
      <>
        <Modal
          className="modal"
          title="Cadastro de Vaga"
          visible={visible}
          onCancel={handleCancel}
          footer={ 
            <Button onClick={handleCancel} danger>Cancelar</Button>
          }
        >
        <Form
          {...layout}
          name="basic"
          initialValues={{ remember: true, quantidade: 1 }}
          onFinish={onFinish}
        >
            <Form.Item
                label="Titulo"
                name="titulo"
                rules={[{ required: true, message: 'O título é Obrigatório!' }]}
            >
                <Input />
            </Form.Item>
  
            <Form.Item
                label="Descrição"
                name="descricao"
                rules={[{ required: true, message: 'A Descrição é Obrigatória!' }]}
            >
                <Input.TextArea />
            </Form.Item>

            <Form.Item
                label="Requisitos"
                name="requisitos"
                rules={[{ required: true, message: 'Os Requisitos são Obrigatórios!' }]}
            >
                <Input.TextArea />
            </Form.Item>

            <Form.Item
                label="Quantidade de Vagas"
                name="quantidade"
                rules={[{ required: true, message: 'A Quantidade é Obrigatória!' }]}
                initialValue={1}
            >
                <InputNumber />
            </Form.Item>

            <Form.Item
                label="Qualificações necessárias"
                name="qualificacoes"
            >
                <Input.TextArea />
            </Form.Item>

            <Form.Item
                label="Informações adicionais"
                name="info_adicionais"
            >
                <Input.TextArea />
            </Form.Item>

            <Form.Item
                label="Empresa"
                name="empresa"
                rules={[{ required: true, message: 'Campo Obrigatório!' }]}
              >
                <Select
                    placeholder="Empresa"
                    allowClear
                >
                    {empresas.map((empresa) => {
                        return (
                            <Option value={empresa.id}>{empresa.razao_social}</Option>
                        )
                    })}
                    <Option value="OUTRA">EMPRESA SEM CADASTRO</Option>
                </Select>
              </Form.Item>
      
              <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) => prevValues.empresa !== currentValues.empresa}
              >
                {({ getFieldValue }) =>
                  getFieldValue('empresa') === 'OUTRA' ? (
                    <>
                        <Form.Item
                            label="Razão Sociall"
                            name="razao_social"
                            rules={[{ required: true, message: 'Campo Obrigatório!' }]}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            label="Email da instituição"
                            name="email"
                            rules={[{ required: true, type: "email", message: 'Email inválido!' }]}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            label="Sobre a instituição"
                            name="sobre"
                        >
                            <Input.TextArea />
                        </Form.Item>

                        <Form.Item
                            label="Telefone"
                            name="telefone"
                            rules={[
                                {
                                    required: true,
                                    message: 'Campo obrigatório!',
                                },
                                () => ({
                                    validator(rule, value) {
                                    if (value && value.replace(/\D/g, '') &&
                                        isValidPhone(value)) {
                                        return Promise.resolve();
                                        }  else {
                                        return Promise.reject("Telefone Inválido");
                                        }
                                    },
                                })
                            ]}
                                hasFeedback
                            >
                                <Input />
                        </Form.Item>

                        <Form.Item
                            label="CNPJ"
                            name="cnpj"
                        >
                            <Input />
                        </Form.Item> 
                    </>
                  ) : null
                }
              </Form.Item>  
      
          <Form.Item {...tailLayout}>
            <Button type="primary" htmlType="submit">
              Cadastrar
            </Button>
          </Form.Item>
        </Form>
          
        </Modal>
        <Button onClick={handleOk} >Cadastrar Vaga</Button>
  
      </>
    );
  }

export default CadastroVagaAdm;
import MenuIcon from "@mui/icons-material/Menu";
import {
  AppBar,
  Box,
  Container,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import * as React from "react";
import { NavLink, withRouter } from "react-router-dom";
import logoOxetech from "../../assets/logo_oxe-tech.png";
import logoSecti from "../../assets/secti_logo_2023.png";
import facebook from "../../assets/ÍCONE_FACEBOOK.png";
import instagram from "../../assets/ÍCONE_INSTAGRAM.png";
import twitter from "../../assets/ÍCONE_TWITTER.png";
import { gradienteLaranja } from "../../utils/styledVariables";
import { ButtonOff, ButtonOn, Cadastrar } from "./styles";

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 800,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
});
const pages = [
  { name: "Início", url: "/" },
  // { name: "Cursos", url: "/cursos" },
  { name: "Mapa de Inovação", url: "/inovation-map" },
  { name: "Login", url: "/login" },
  { name: "Cadastre-se", url: "/select-cadastro" },
];
const ResponsiveAppBar = (props) => {
  const { history } = props;
  const [anchorElNav, setAnchorElNav] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleMenuClick = (pageURL) => {
    handleCloseNavMenu();
    history.push(pageURL);
  };

  const isActive = window.location.pathname;

  return (
    <ThemeProvider theme={theme}>
      <AppBar
        style={{
          background: gradienteLaranja,
          lineHeight: "8%",
        }}
        position="relative"
        elevation={0}
      >
        <Container maxWidth="100vw">
          <Toolbar disableGutters sx={{ padding: "1" }}>
            <Box sx={{ flexGrow: 1, display: { xs: "none", sm: "flex", md: "flex" } }}>
              <Tooltip title="Logo OxeTech">
                <IconButton onClick={() => handleMenuClick("/")}>
                  <img
                    src={logoOxetech}
                    alt="OxeTech"
                    style={{ height: "7vh" }}
                  />
                  <img src={logoSecti} alt="Secti" style={{ height: "7vh" }} />
                </IconButton>
              </Tooltip>
            </Box>
            {/* ---------- Visível para Mobile ----------*/}
            <Box sx={{ flexGrow: 1, display: { xs: "flex", sm: "none" } }}>
              <IconButton
                aria-label="Páginas do OxeTech"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon fontSize="40px" />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: "block", sm: "none" },
                }}
              >
                {pages.map((page) => (
                  <MenuItem
                    key={page.name}
                    onClick={() => handleMenuClick(page.url)}
                  >
                    <Typography textAlign="center">{page.name}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>

            <Box sx={{ flexGrow: 2, display: { xs: "flex", sm: "none" } }}>
              <Tooltip title="Logo OxeTech">
                <IconButton onClick={() => handleMenuClick("/")}>
                  <img src={logoOxetech} alt="OxeTech" width={"80%"} />
                </IconButton>
              </Tooltip>
            </Box>
            {/* ---------- FIM Visível para Mobile ----------*/}

            <Box
              sx={{
                flexGrow: 0,
                display: { xs: "none", sm: "flex" },
              }}
            >
              {pages.map((page, i) => (
                <NavLink key={i} to={page.url}>
                  {page.url === "/select-cadastro" ? (
                    <Cadastrar>{page.name}</Cadastrar>
                  ) : isActive === page.url ? (
                    <ButtonOn>{page.name}</ButtonOn>
                  ) : (
                    <ButtonOff>{page.name}</ButtonOff>
                  )}
                </NavLink>
              ))}

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <a
                  style={{ margin: "10px" }}
                  href="https://pt-br.facebook.com/secti.alagoas"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={facebook}
                    alt="Facebook"
                    style={{ height: "30px" }}
                  />
                </a>
                <a
                  style={{ margin: "10px" }}
                  href="https://www.instagram.com/sectialagoas/"
                  target="_blank"
                  rel="noreferrer"
                >
                  {" "}
                  <img
                    src={instagram}
                    alt="Instagram"
                    style={{ height: "30px" }}
                  />
                </a>
                <a
                  style={{ margin: "10px" }}
                  href="https://twitter.com/sectialagoas"
                  target="_blank"
                  rel="noreferrer"
                >
                  {" "}
                  <img src={twitter} alt="Twitter" style={{ height: "30px" }} />
                </a>
              </div>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </ThemeProvider>
  );
};
export default withRouter(ResponsiveAppBar);

// enviando a requisição de login

// export function signInRequest({ success, data }) {
//   return {
//     type: "@auth/SIGN_IN_REQUEST",
//     payload: { success, data },
//   };
// }

// Resposta de sucesso para a requisição de login

export function signInSuccess(token, user, labs_profile) {
  return {
    type: '@auth/SIGN_IN_SUCCESS',
    payload: { token, user, labs_profile}
  };
}

// Resposta error para requisição

export function signInFailure() {
  return {
    type: "@auth/SIGN_IN_FAILURE",
  };
}

export function signOut() {
  return {
    type: "@auth/SIGN_OUT",
  };
}

export function mapaRequest() {
  return {
    type: "@auth/MAPA_IN_REQUEST",
  };
}

export function mapaInSucess(token) {
  return {
    type: "@auth/MAPA_SIGN_IN_SUCESS",
    payload: { token },
  };
}

export function mapaOut() {
  return {
    type: "@auth/MAPA_OUT",
  };
}

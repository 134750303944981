import * as React from 'react'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'

import { Link } from 'react-router-dom'

import { Item, Caixa, Mapa } from './styles'
// map_al
import MapaInovacaoFig1 from '../../../src/assets/MapaInovacaoFig1.svg'
// import MapaInovacaoFig2 from '../../../src/assets/MapaInovacaoFig2.svg'
// import MapaInovacaoFig from '../../../src/assets/MapaInovacaoFig3.svg'
import MapaInovacaoFig from '../../../src/assets/map_al.png'
import { gradienteLaranja } from '../../utils/styledVariables'

export default function BasicGrid() {
  return (
    <Caixa
      style={{
        background: gradienteLaranja
          // 'radial-gradient(228.19% 228.19% at 28.44% -27.58%, rgba(173, 137, 191, 0.7) 0%, rgba(0, 0, 99, 0.7) 100%)'
      }}
      // sx={{ flexGrow: 1 }}
    >
      <Grid container className="container">
        <Grid item xs={12} sm={12} md={12} lg={6}>
          <Grid
            container
            spacing={2}
            direction="column"
            alignItems="center"
            // justifyContent="center"
            // style={{ minHeight: '100vh' }}
          >
            <Item>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
              >
                <div
                  style={{
                    marginLeft: '20px'
                  }}
                >
                  <img
                    className="img"
                    src={MapaInovacaoFig1}
                    alt="MapaInovacaoFig1"
                  />
                </div>
                <div style={{}}>
                  <h2 className="titulo">Nosso mapa de inovação</h2>
                </div>
              </div>
            </Item>
            <Item>
              <div className="subtitulo" style={{fontSize: '1.5em', textAlign: 'justify'}}>
                O mapa da inovação foi desenvolvido, dentro da Plataforma do
                OxeTech, porque aqui reunimos - em um só lugar - profissionais,
                alunos, empresas e instituições do segmento da ciência, da
                tecnologia e da inovação em alagoas. Ele tem como propósito
                georeferenciar as empresas, instituições, mecanismos e demais
                atores do Ecossistema de Inovação alagoano. Ao acessar o mapa,
                você verá a descrição de cada uma das iniciativas, seus produtos
                e seu perfil institucional.

              </div>
            </Item>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={6}>
          <Grid container direction="column" alignItems="center">
            <Box component="div" justifyContent="center">
              <Link to="/inovation-map">
                <Mapa
                  src={MapaInovacaoFig}
                  alt="MapaInovacaoFig2"
                  width="100%"
                />
              </Link>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Caixa>
  )
}

import React, {useState} from "react";
import { Grid, TextField,InputLabel, Select,MenuItem,FormControl } from "@mui/material";
import Options from "../../utils/options";

export default function PersonalDataForm() {

  const [data,setData] = useState(
    {sobre: sessionStorage.getItem("sobre_mim") || "", 
    foco: sessionStorage.getItem("foco_carreira") || "", 
    ocupacao: sessionStorage.getItem("ocupacao") || ""}) 

  
  sessionStorage.setItem("sobre_mim", data.sobre)
  sessionStorage.setItem("foco_carreira", data.foco)
  sessionStorage.setItem("ocupacao", data.ocupacao)
  // snackbar
  const [messageInfo, setMessageInfo] = useState(undefined);



  return (
      <Grid sx={{p: "2% 1%"}} container spacing={2}>
        <Grid item xs={12} sm={12}>
          <TextField
            required
            multiline={true}
            rows={5}
            label="Sobre mim"
            id="sobre"
            placeholder="Fale um pouco sobre você..."
            value={data.sobre}
            fullWidth
            onChange={(e) => setData({...data, sobre: e.target.value})}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
        <FormControl required  fullWidth>
        <InputLabel id="demo-simple-select-label" sx={{background: '#fff', mr: 1}}>Foco de Carreira</InputLabel>
            <Select
                value={data.foco}
                label="Foco"
                onChange={(e) => setData({...data, foco: e.target.value})}
                fullWidth
            >
                {Options.optionsCarrer.map((option) => (
                    <MenuItem value={option.value}>{option.value}</MenuItem>
                ))}
            </Select>
        </FormControl>
        </Grid>
        <Grid item xs={12} sm={12}>
        <FormControl required fullWidth>
        <InputLabel id="demo-simple-select-label" sx={{background: '#fff', mr: 1}}>Ocupação</InputLabel>
            <Select
                value={data.ocupacao}
                label="Ocupação"
                onChange={(e) => setData({...data, ocupacao: e.target.value})}
                fullWidth
            >
                {Options.formationTypes.map((option) => (
                    <MenuItem value={option.value}>{option.value}</MenuItem>
                ))}
            </Select>
        </FormControl>
        </Grid>
        
        
        
      </Grid>
  );
}

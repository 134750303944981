import { makeStyles } from "@mui/styles";
import { createTheme } from "@mui/material/styles";
import styled from "styled-components";

const theme = createTheme();

export const useStyles = makeStyles({
  
  container: {
    width: "100vw",
    display: "flex",
    padding: " 0.6% 1%",
    justifyContent: "center",
  },
  videoCard: {
    // border: "1px solid red",

    backgroundColor: "#f2f2f2",
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    borderRadius: "8px",
    width: "100%",
    height: "500px",
    // overflow: 'auto',
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      padding: "10px",
      
    },
  },

  playerWrapper: {
    width: "100%",
    height: "100%",
    padding: "10px",
   
  },

  informationContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    height: "100%",
    flex: 1,
    [theme.breakpoints.down("sm")]: {
      height: "60%",      
    },
  },

  description: {
    marginBottom: "10px",
    fontWeight: "normal",
    fontSize: "20",
    fontFamily: "montserrat",
  },

  informationMinutes: {
    display: "flex",
    alignItems: "center",
    fontSize: "14px",
    color: "#555555",
    marginTop: "auto",
    //justifyContent: 'center',
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
    },
  },

  informationText: {
    marginBottom: "10px",
    marginRight: "40px",
    fontWeight: "normal",
    fontSize: "20px",
    fontFamily: "montserrat",
  },
  videoTitle: {
    fontWeight: "bold",
    marginBottom: "16px",
  },

  atvButton: {
    position: "fixed",
    bottom: "20px",
    right: "30px",
    padding: "15px",
    borderRadius: "10px",
    background: "#762F89",
    color: "#FFF",
    border: "none",
    cursor: "pointer",
  },

  nextButton: {
    width: "100%",
    display: "flex",
    paddingTop: "1%",
    justifyContent: "end",
  },
  lista: {
    overflow: "auto",
    height: "500px",
    "&::-webkit-scrollbar": {
      width: "3px",
    },

    "&::-webkit-scrollbar-track": {
      background: "#f1f1f1",
    },

    "&::-webkit-scrollbar-thumb": {
      background: "#888",
      borderRadius: "4px",
    },
  },
});

export const IconeAnimado = styled.div`
  .animated-sound-icon {
    display: flex;
    align-items: center;
    overflow: hidden;
    position: relative;
    margin-left: 15px;
    margin-right: 16px;
  }

  .animated-sound-icon::before {
    content: "";
    position: absolute;
    top: 0;
    left: -100%; /* Inicia fora da tela à esquerda */
    width: 100%;
    height: 100%;
    background: linear-gradient(
      to right,
      transparent,
      white
    ); /* Gradiente para criar o efeito de aparecimento/desaparecimento */
    animation: moveRight 2s linear infinite; /* Configura a animação */
  }

  @keyframes moveRight {
    0%,
    100% {
      left: 100%; /* Desloca para a direita */
    }
    50% {
      left: 0; /* Volta para a esquerda */
    }
  }
`;

import styled from 'styled-components';

export const Container = styled.div`
  padding: 5px 10vw;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 5vw;
  align-items: center;

  h3 {
    margin-bottom: -5px;

    a {
      color: rgb(235, 91, 40);
      margin-left: 5px;

      &:hover {
        color: rgba(235, 91, 40, 0.5);
      }
    }
  }
`;


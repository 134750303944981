import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import logoOXW from '../../assets/LOGO_OXETECH_WORK.png'
import logoOXL from '../../assets/homeTriangle/oxeTechLabLogoON.png'
import logoOXD from '../../OxeTechEdu/assets/logo.svg'

const useStyles = makeStyles(() => ({
  panel: {
    borderRadius: '0.5rem',
    minWidth: '330px',
    marginBottom: '2px',
    wordWrap: 'break-word',
  },
  titulo: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontSize: '18px',
    fontWeight: 'bold',
    marginBottom: '0.5rem',
    color: 'black',
  },
  descricao: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    color: 'black',
    fontSize: '12px',
    marginBottom: '0.5rem',
    fontWeight: 'normal',
  },
  separador: {
    border: '1px solid #D9D9D9',
    margin: '12px 0',
  },
  imagem: {
    marginBottom: '10px',
  }
}));


const Notificacao = ({ id, titulo, descricao }) => {
  const classes = useStyles();
  const logoPrograma = {
    src: titulo?.includes("OxetechLAB") ? logoOXL : (titulo?.includes("Oxetech Work") ? logoOXW : logoOXD),
    alt: titulo?.includes("OxetechLAB") ? 'OxetechLAB' : (titulo?.includes("Oxetech Work") ? 'Oxetech Work' : 'Oxetech Edu')
  }
  return (
    <div key={id} className={classes.panel}>
      <div>
        <img width={80} src={logoPrograma.src} alt={logoPrograma.alt} className={classes.imagem}/> 
        <h2 className={classes.titulo}>{titulo}</h2>
        <h3 className={classes.descricao}>{descricao}</h3>
      </div>
      <hr className={classes.separador} />
    </div>
  );
};


export default Notificacao;

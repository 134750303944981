import { Button } from '@mui/material'
import React from 'react'
import Carousel from 'react-material-ui-carousel'
import { Link } from 'react-router-dom'
import oxeTechWorkLogo from '../../assets/LOGO_OXETECH_WORK.png'
import oxeTechLabLogo from '../../assets/homeTriangle/oxeTechLabLogoON.png'
import iconeOxedin from '../../assets/iconeOxeDin.png'
import iconeOxetechLab from '../../assets/iconeOxetechLab.png'
import Oxedin from '../../assets/logo_oxeDIN 3.png'
import { Din, Lab, Title, Work } from './styles'

import iconeOxetechWork from '../../assets/iconeOxetechWork.png'
import { laranjaMain, profileWhiteShade } from '../../utils/styledVariables'
export default function Example(props) {
  return (
    <Carousel
      navButtonsAlwaysInvisible={true}
      swipe={true}
      animation="slide"
      sx={{ width: '100vw' }}
    >
        <Lab>
          <img src={oxeTechLabLogo} alt="Logo do OxeTechLab" width="70%"/>
          <Title>
            <h4 className="subtitulo ">
              O OxeTech Lab é um programa cujo propósito é interiorizar e
              popularizar a ciência, a tecnologia e a inovação através de cursos
              online gratuitos, bem como tem o intuito de garantir espaços
              moderno e organizados, com vistas a estabelecer uma maior absorção
              de conhecimento por parte dos alunos que fazem uso do programa.
            </h4>
          </Title>
          <Link to="/oxetechlab">
                <Button sx={{backgroundColor: laranjaMain, mb: '5%'}} variant="contained" >Turmas disponíveis</Button>
          </Link>
          <img
            src={iconeOxetechLab}
            alt="icone do OxetechLab"
            style={{
              width: '35%'
            }}
          ></img>
          <h5
            style={{
              color: profileWhiteShade,
              marginRight: '5%',
              marginTop: '3%'
            }}
          >
            Um lab mais perto de você!
          </h5>

          {/* </div> */}
        </Lab>

        <Din>
          <img src={Oxedin} alt="Logo do OxeDin" width="40%" />
          <Title>
            <h4 className="subtitulo ">
              O OxeDIN é a plataforma de contato entre alunos do programa
              OxeTech Lab e empresas.
            </h4>
            <h6
              className="subtitulo "
              style={{
                margin: '2%',
                textDecoration: 'underline',
                fontSize: '0.8rem'
              }}
            >
              Quer achar um desenvolvedor e não sabe onde encontrar?
            </h6>
            <h4 className="subtitulo ">
              Anuncie sua vaga, encontre profissionais qualificados e acompanhe
              os cursos feitos por alunos! Se inscreva na plataforma e faça
              parte do Ecossistema de Inovação de Alagoas, conhecendo os
              produtos, processos e serviços produzidos em nosso Estado.
            </h4>
            <Link to="/oxedin">
                <Button sx={{backgroundColor: laranjaMain}} variant="contained" >Confira as vagas! </Button>
            </Link>
          </Title>
          <img
            src={iconeOxedin}
            alt="icone do OxeDin"
            style={{
              width: '30%'
            }}
          ></img>
          <h5
            style={{
              color: profileWhiteShade,
              marginRight: '5%',
              marginTop: '3%'
            }}
          >
            Encontre profissionais capacitados
          </h5>
        </Din>
        <Work>
          <img src={oxeTechWorkLogo} alt="Logo do oxeTechWork" width="70%" />
          <Title>
            <h4 className="subtitulo ">
              O OxeTech Work nasce com o propósito de fomentar a economia
              alagoana, através de um programa que integre - de maneira direta -
              a capacitação de alunos com vagas de trabalho no mercado
              tecnológico e inovador, transferindo renda, gerando empregos e
              qualificando a população. O diferencial do OxeTech Work:
            </h4>
            <h4
              className="subtitulo "
              style={{
                margin: '2%',
                fontSize: '0.8rem',
                textAlign: 'left'
              }}
            >
              <ul>
                <li>Portfólio com cursos mais específicos;</li>
                <li>
                  Qualificação intensiva com Universidades, ICTs e empresas
                  locais;
                </li>
                <li>
                  Bolsa de estudos de 6 meses + contrato de trabalho de 12
                  meses.
                </li>
              </ul>
            </h4>
            <Link to="/oxe-tech-work">
                <Button  sx={{backgroundColor: laranjaMain}}variant="contained">Confira a página!</Button>
            </Link>
          </Title>
          <img
            src={iconeOxetechWork}
            alt="icone do OxetechWork"
            style={{
              width: '30%'
            }}
          ></img>
          <h5
            style={{
              color: profileWhiteShade,
              marginRight: '5%',
              marginTop: '3%'
            }}
          >
            Encontre vagas de trabalho
          </h5>
        </Work>
    </Carousel>
  )
}

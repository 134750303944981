import styled from "styled-components";
import { laranjaIntermediario, laranjaMain, profileWhiteShade, roxoAzulado } from "../../utils/styledVariables";

export const Container = styled.div`
  position: fixed;
  background-color: #303030;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  height: 2.5vh;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: auto;
 

  .texto {
    margin: 0;
    color: ${profileWhiteShade}
  }

  .botao {
    font-weight: bold;
    color: ${profileWhiteShade}
  }
`;

import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Container';
import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Content } from './styles';
import { gradienteLaranja180, laranjaIntermediario, laranjaMain, profileWhiteShade } from '../../utils/styledVariables';

const WhiteBox = styled(Box)`
  width: 34rem;
  height: 20rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  padding: 30px;
  left: 0px;
  right: 0px;
  top: 90px;
  bottom: 0px;
  margin: auto auto auto auto;
  border-radius: 0.5rem;
  color: black;
  overflow: hidden;
  font-size: 20px;
  text-align: center;
  background: ${profileWhiteShade};//rgba(255, 255, 255, 89%);

  @media (max-width: 700px) {
    width: 35rem;
    height: 40rem;
  }
  @media (max-width: 360px) {
    width: 33rem;
    height: 45rem;
  }
`;

const ColorButton = styled(Link)(({ theme }) => ({
  color: '#fff',
  heigth: '2vh',
  backgroundColor: laranjaIntermediario,//'#741f8a',
  '&:hover': {
    backgroundColor: laranjaMain,//'rgba(116, 31, 138, 0.4)',
    color: 'white',
  },
}));

const UseStyles = makeStyles((theme) => ({
  root: {
    overflow: 'hidden',
    background: gradienteLaranja180,
      //'linear-gradient(180deg,rgba(116, 31, 138, 0.9) 35%,rgba(0, 0, 99, 1) 100%)',
    height: '100vh',
  },
  iconCheck: {
    color: laranjaIntermediario, //'rgba(116, 31, 138, 0.9)',
    fontSize: '50px',
  },
}));

export default function ForgotPassword() {
  const classes = UseStyles();
  return (
    <React.Fragment>
      <CssBaseline />
      <div className={classes.root}>
        <WhiteBox>
          <Content>
            <CheckCircleIcon className={classes.iconCheck} fontSize="70px" />
            <Typography
              variant="h4"
              component="div"
              gutterBottom
              sx={{
                mt: 2,
                mb: 2,
                fontSize: '25px',
              }}
            >
              Cadastro realizado com sucesso!
            </Typography>
            <Typography
              variant="h4"
              component="div"
              gutterBottom
              sx={{
                mt: 2,
                mb: 2,
                fontSize: '15px',
              }}
            >
              Você receberá um email de boas vindas. Caso não encontre nosso email em sua caixa de entrada, verifique
              sua caixa de SPAM.
            </Typography>
            {/* <Typography
              variant="h4"
              component="div"
              gutterBottom
              sx={{
                mt: 2,
                mb: 1,
                fontSize: '12px',
                //background: 'rgba(116, 31, 138, 0.2)',
              }}
            >
              Caso não encontre nosso email em sua caixa de entrada, verifique
              sua caixa de SPAM.
            </Typography> */}
            <ColorButton
              to="/login"
              type="button"
              sx={{ mt: 3, mb: 2, p: 1, borderRadius: 1 }}
            >
              Login
            </ColorButton>
          </Content>
        </WhiteBox>
      </div>
    </React.Fragment>
  );
}

import styled from 'styled-components';
import * as palette from '../../utils/styledVariables';
export const Container = styled.div`
  section {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .ant-descriptions-bordered .ant-descriptions-view {
    border: 0;
  }

  @media (max-width: 700px) {
    section {
      display: block;
    }
  }
`;

export const Content = styled.div`
  border-radius: 10px;
  margin: 10px;

  div {
    .ant-descriptions-item-label {
      background: ${palette.profileMainColor};
    }
    .ant-descriptions-item-content {
    }
  }

  -moz-box-shadow: 3px 3px 5px 1px #ccc;
  -webkit-box-shadow: 3px 3px 5px 1px #ccc;
  box-shadow: 3px 3px 5px 1px #ccc;
`;

export const Profile = styled.div`
  border-radius: 10px;

  display: grid;
  grid-template-columns: 1fr 3fr;
  margin: 10px;

  div {
    .ant-descriptions-item-label {
      background: ${palette.profileMainColor};
    }

    .ant-descriptions-item-content {
    }
  }

  -moz-box-shadow: 3px 3px 5px 1px #ccc;
  -webkit-box-shadow: 3px 3px 5px 1px #ccc;
  box-shadow: 3px 3px 5px 1px #ccc;
`;

export const Description = styled.div`
  border-radius: 10px;
  margin: 10px;

  div {
    .ant-descriptions-item-label {
      background: ${palette.profileMainColor};
    }

    .ant-descriptions-item-content {
    }
  }

  -moz-box-shadow: 3px 3px 5px 1px #ccc;
  -webkit-box-shadow: 3px 3px 5px 1px #ccc;
  box-shadow: 3px 3px 5px 1px #ccc;
`;

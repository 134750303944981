import React, { useEffect } from 'react'
import { Box } from '@mui/system'
import Typography from "@mui/material/Typography";
import { useDispatch, useSelector } from 'react-redux'
import Divider from "@mui/material/Divider";
import TextField from '@mui/material/TextField';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import MailIcon from '@mui/icons-material/Mail';
import BadgeIcon from '@mui/icons-material/Badge';
import FingerprintIcon from '@mui/icons-material/Fingerprint';
import InputMask from "react-input-mask";
import PhoneIcon from '@mui/icons-material/Phone';
import SchoolIcon from '@mui/icons-material/School';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { School } from '@mui/icons-material';
import Button from '@mui/material/Button';
import UploadIcon from '@mui/icons-material/Upload';
import DeleteIcon from '@mui/icons-material/Delete';
import { SnackbarProvider, useSnackbar } from 'notistack';
import LabelIcon from '@mui/icons-material/Label';
import api from '../../../services/api'
import prettyBytes from 'pretty-bytes';
import { Route, Redirect, useLocation } from 'react-router-dom'
import history from '../../../services/history'
import DownloadIcon from '@mui/icons-material/Download';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { CircularProgress } from '@mui/material';
import iconeOxetechWork from "../../../assets/iconeOxetechWork.png"
import moment from 'moment';
import EditFormulario from './EditFormularioAluno'
import 'moment/locale/pt-br'
import { Link } from 'react-router-dom';

moment.locale('pt-br')

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 224,
    },
  },
};

const niveis_list = [
    'Ensino Médio completo',
    'Graduação em andamento',
    'Graduação completa',
    'Pós-Graduação em andamento',
    'Pós-Graduação completa',
  ];

const experiencia_ti_list = [
    'Não',
    'Sim, até 1 ano',
    'Sim, entre 1 e 3 anos',
    'Sim, entre 3 e 5 anos',
    'Sim, entre 5 e 10 anos',
    'Sim, mais de 10 anos',
  ];

function FormularioAluno(){
    let profile = useSelector((state) => {
        if (state && state.user && state.user.profile && state.user.profile.dataValues) {
            return state.user.profile.dataValues
        } else if (state && state.user && state.user.profile) {
            return state.user.profile
        }
    });

    const location = useLocation();

    const [rg, setRG] = React.useState(sessionStorage.getItem("rg") || '');
    const [errorRG, setErrorRG] = React.useState(null);
    const [nivelEscolaridade, setNivelEscolaridade] = React.useState('');
    const [area, setArea] = React.useState('');
    const [experienciaTi, setExperienciaTi] = React.useState('');
    const [experienciaDev, setExperienciaDev] = React.useState('');
    const [file, setFile] = React.useState(null);
    const [fileId, setFileId] = React.useState(null);
    const [selectedEdital, setSelectedEdital] = React.useState(null);
    const [payload, setPayload] = React.useState({});
    const [inscricao, setInscricao] = React.useState(null);
    const [update, setUpdate] = React.useState(false);
    const [editInscricao, setEditInscricao] = React.useState(false);
    const [verificarInscricao, setVerificarInscricao] = React.useState(false);
    const defaultButtonText = 'Adicione aqui o documento (Máx 10MB)';
    const [buttonText, setButtonText] = React.useState(defaultButtonText);
    const [loading, setLoading] = React.useState(false);

    const { enqueueSnackbar } = useSnackbar();

    const handleNivelEscolaridade = (event) => {
        setNivelEscolaridade(event.target.value);
    };

    useEffect(() => {
        if (verificarInscricao){
            api.get(`/oxetechwork/inscricao-aluno`).then(response => {
                if (response.data){
                    response.data.forEach((element) => {
                        if (element.edital_id === selectedEdital.id){
                            setInscricao(element)
                        }
                    })
                }
            }).catch((err) => {
                if (err.response && err.response.data && err.response.data.error){
                    enqueueSnackbar(err.response.data.error, { variant: 'error' })
                }
            })
        }
    }, [verificarInscricao])

    useEffect(() => {
        let matches = location.pathname.match(/\d+/g);
        if (matches && matches[0]){
            api.get(`oxetechwork/edital/all`).then(response => {
                let editalFound = false;
                response.data.editals.forEach(element => {
                    if (element.id === parseInt(matches[0])){
                        editalFound = true;
                        setSelectedEdital(element)
                    }
                });
                if (!editalFound){
                    history.push('/oxe-tech-work')
                }
            }).catch((err) => {
                history.push('/oxe-tech-work')
            })
        }else{
            history.push('/oxe-tech-work')
        }

        function myTimer() {
            if (inscricao === null){
                setVerificarInscricao(true)
            }else{
                clearInterval(myInterval)
            }
        }

        const myInterval = setInterval(myTimer, 500);
    }, [])

    useEffect(() => {
        if (fileId){
            setPayload({...payload, file_id : fileId})
        }
    }, [fileId])

    function UpdateFormulario(){
        setUpdate(true)
        setEditInscricao(inscricao)
    }

  
    useEffect(() => {
        if (payload.file_id !== undefined) {
            setLoading(true);
            api.post(`/oxetechwork/inscricao-aluno`, payload).then(response => {
                if (response.data && response.data.message) {
                    if (response.data.data){
                        setInscricao(response.data.data)
                        setLoading(false);
                    }
                    enqueueSnackbar(<h4 style={{color: 'white', margin:0}}>{response.data.message}! Você já pode se candidatar para vagas do programa!  
                    <Link to='/oxetechwork' style={{color: 'purple'}}>  <u><strong>Ver</strong></u></Link></h4>, { variant: 'success' })
                } else {
                    enqueueSnackbar('Falha ao carregar PDF', { variant: 'error' })
                    setLoading(false);
                }
            }).catch((err) => {
                if (err.response && err.response.data && err.response.data.error){
                    enqueueSnackbar(err.response.data.error, { variant: 'error' })
                    setLoading(false);
                }
            })
        }
    }, [payload])

    function handleExperienciaTi(event){
        setExperienciaTi(event.target.value)
    }

    function handleExperienciaDev(event){
        setExperienciaDev(event.target.value)
    }

    function handleArea(event){
        setArea(event.target.value)
    }

    function handleRemoveFile(){
        setButtonText(defaultButtonText)
        setFile(null)
    }

    function handleFile(event){
         const filesize_max = 10 * 1000 * 1000
        if (event.target.files[0] && (event.target.files[0].size > filesize_max)){
            setFile(null)
            enqueueSnackbar('O pdf é muito grande ( máx ' + prettyBytes(filesize_max) + ' )', {variant: 'error'})
        }else{
            if (event.target.files[0]){
                setFile(event.target.files[0])
                setButtonText(event.target.files[0].name + ' - (' + prettyBytes(event.target.files[0].size) + ' / ' + prettyBytes(filesize_max) + ')')
            }else{
                setFile(null)
                setButtonText(defaultButtonText)
            }
        }
    }

    const handleRG = (event) => {
        const {
            target: { value },
        } = event;
        setRG(value);
        // setErrorRG({ rg: "" });
        // if (value.trim().length > 0) {
        //     let reg = new RegExp(/^\d{3}\-\d{3}\.\d{2}$/).test(value);
        //     if (!reg) {
        //         setErrorRG({ rg: "RG inválido." });
        //         sessionStorage.removeItem("rg");
        //     }else{
        //         setErrorRG(null)
        //     }
        // } else {
        //     setErrorRG({ rg: "Preencha esse campo." });
        // }
    };

    function handleDisabled(){
        let disabled = false;

        disabled = (rg === '' | errorRG !== null) ? true : disabled

        disabled = nivelEscolaridade === '' ? true : disabled
        disabled = (nivelEscolaridade !== 'Ensino Médio completo' & area === '') ? true : disabled
        disabled = experienciaTi === '' ? true : disabled
        disabled = experienciaDev === '' ? true : disabled
        disabled = file === null ? true : disabled

        return disabled
    }

    function getDocs(){
        api.get(`/oxetechwork/inscricao-aluno/docs/` + inscricao.file_id, {responseType: 'arraybuffer'}).then(response => {
            if (response.data){
                var blob = new Blob([response.data], {
                    type: 'application/pdf'
                });
                var url = window.URL.createObjectURL(blob)
                window.open(url);
            }else{
                enqueueSnackbar('Falha ao carregar PDF', {variant: 'error'})
            }
        }).catch((err) => {
            console.log(err)
            enqueueSnackbar('Falha ao carregar PDF', {variant: 'error'})
        })
    }

    function uploadDoc(){
        const formData = new FormData();
        formData.append("file", file);

        api.post(`oxetechwork/inscricao-aluno/docs`, formData).then(response => {
            if (response.data && response.data.data && response.data.data.uuid){
                setFileId(response.data.data.uuid)
            }else{
                enqueueSnackbar('Falha ao carregar PDF', {variant: 'error'})
            }
        }).catch((err) => {
            enqueueSnackbar('Falha ao carregar PDF', {variant: 'error'})
        })
    }

    function getEdital(){
        api.get(`/oxetechwork/edital/docs/` + selectedEdital.id, {responseType: 'arraybuffer'}).then(response => {
            if (response.data){
                var blob = new Blob([response.data], {
                    type: 'application/pdf'
                });
                var url = window.URL.createObjectURL(blob)
                window.open(url);
            }else{
                enqueueSnackbar('Falha ao carregar PDF', {variant: 'error'})
            }
        }).catch((err) => {
            console.log(err)
            enqueueSnackbar('Falha ao carregar PDF', {variant: 'error'})
        })
    }

    function validate(){
        let data = {}

        // valida os campos e faz upload do pdf caso estejam ok
        data.rg = rg
        data.nivel_escolaridade = nivelEscolaridade
        data.area = area
        data.experiencia_ti = experienciaTi
        data.experiencia_dev = experienciaDev
        data.edital_id = selectedEdital.id
        
        setPayload(data)

        uploadDoc()
    }

    return (
        <Box>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <Typography sx={{ fontWeight: 'bold' }} variant="h6">
                    {
                        inscricao === null
                        ?
                            "FORMULÁRIO DE INSCRIÇÃO"
                        :update?"EDIÇÃO DO FORMULÁRIO DE INSCRIÇÃO":
                            "CONFIRMAÇÃO DE INSCRIÇÃO"
                    }
                </Typography>
            </Box>
            {
                inscricao === null ?
                    <>
                        <Divider sx={{ my: 1 }} variant="fullWidth" />
                        <Box sx={{ pb: 1, gap: 2, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Button onClick={getEdital} target="_blank" variant="outlined" href={selectedEdital?.link_edital}>
                                <Typography variant="h6" sx={{ display: 'flex', alignItems: 'center', gap: 1, color: 'red' }} >
                                    {selectedEdital?.title}
                                    <DownloadIcon />
                                </Typography>
                            </Button>
                        </Box>
                        <Box sx={{ px: 2, display: 'flex', gap: 4, flexWrap: 'wrap', justifyContent: 'center' }}>
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                <Typography sx={{ display: 'flex', gap: 1, alignItems: 'center' }} variant="body1"><AccessTimeIcon /> Início:</Typography>
                                <Typography variant="h6">{(selectedEdital && selectedEdital.dt_inicio_edital) ? moment(selectedEdital.dt_inicio_edital).utc("America/Alagoas").format("DD/MM/YYYY") : ''}</Typography>
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                <Typography sx={{ display: 'flex', gap: 1, alignItems: 'center' }} variant="body1"><AccessTimeIcon /> Fim:</Typography>
                                <Typography variant="h6">{(selectedEdital && selectedEdital.dt_encerramento_edital) ? moment(selectedEdital.dt_encerramento_edital).utc("America/Alagoas").format("DD/MM/YYYY") : ''}</Typography>
                            </Box>
                        </Box>
                        <Divider sx={{ pb: 1 }} variant="fullWidth" />
                        <Typography sx={{ p: 2 }} variant="h6">
                            Informações do seu perfil OxeTech:
                        </Typography>
                        <Box
                            component="form"
                            // sx={{
                            //     '& .MuiTextField-root': { m: 1, width: '25ch' },
                            // }}
                            sx={{
                                display: 'flex',
                                flexWrap: 'wrap',
                                p: 2,
                                gap: 3,
                            }}
                            noValidate
                            autoComplete="off"
                        >
                            <TextField
                                sx={{ flexGrow: 1 }}
                                label="Nome Completo"
                                defaultValue={profile.nome_social ? profile.nome_social : profile.name}
                                InputProps={{
                                    readOnly: true,
                                    disabled: true,
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <AccountCircle />
                                        </InputAdornment>
                                    ),
                                }}
                            >
                                <AccountCircle />
                            </TextField>
                            <TextField
                                sx={{ flexGrow: 1 }}
                                label="Email"
                                defaultValue={profile.email}
                                InputProps={{
                                    readOnly: true,
                                    disabled: true,
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <MailIcon />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            <TextField
                                sx={{ flexGrow: 1 }}
                                label="Celular"
                                defaultValue={profile.telefone}
                                InputProps={{
                                    readOnly: true,
                                    disabled: true,
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <PhoneIcon />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            <TextField
                                sx={{ flexGrow: 1 }}
                                label="CPF"
                                defaultValue={profile.cpf}
                                InputProps={{
                                    readOnly: true,
                                    disabled: true,
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <BadgeIcon />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            <TextField
                                sx={{ flexGrow: 1 }}
                                label="CEP"
                                defaultValue={profile.cep}
                                InputProps={{
                                    readOnly: true,
                                    disabled: true,
                                }}
                            />
                            <TextField
                                sx={{ flexGrow: 1 }}
                                label="Município"
                                defaultValue={profile.municipio}
                                InputProps={{
                                    readOnly: true,
                                    disabled: true,
                                }}
                            />
                            <TextField
                                sx={{ flexGrow: 1 }}
                                label="Bairro"
                                defaultValue={profile.bairro}
                                InputProps={{
                                    readOnly: true,
                                    disabled: true,
                                }}
                            />
                            <TextField
                                sx={{ flexGrow: 1 }}
                                label="Logradouro"
                                defaultValue={profile.rua}
                                InputProps={{
                                    readOnly: true,
                                    disabled: true,
                                }}
                            />
                            <TextField
                                sx={{ flexGrow: 1 }}
                                label="Número"
                                defaultValue={profile.numero}
                                InputProps={{
                                    readOnly: true,
                                    disabled: true,
                                }}
                            />
                        </Box>
                        <Divider sx={{ pb: 1 }} variant="fullWidth" />
                        <Typography sx={{ p: 2 }} variant="h6">
                            Informações adicionais necessárias:
                        </Typography>
                        <Box
                            component="form"
                            // sx={{
                            //     '& .MuiTextField-root': { m: 1, width: '25ch' },
                            // }}
                            sx={{
                                display: 'flex',
                                flexWrap: 'wrap',
                                p: 2,
                                gap: 3,
                            }}
                            noValidate
                            autoComplete="off"
                        >
                           
                                    <TextField
                                        sx={{ flexGrow: 1 }}
                                        value={rg}
                                        onBlur={handleRG}
                                        onChange={handleRG}
                                        label="RG"
                                        type="text"
                                        autoComplete="rg"
                                        error={Boolean(errorRG?.rg)}
                                        helperText={errorRG?.rg}
                                        required
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <FingerprintIcon />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                            <Box sx={{ flexGrow: 3 }}>
                                <FormControl sx={{ width: '100%' }}>
                                    <InputLabel id="demo-multiple-name-label"><Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}><SchoolIcon /> Nível de Escolaridade</Box></InputLabel>
                                    <Select
                                        labelId="demo-multiple-name-label"
                                        id="demo-multiple-name"
                                        value={nivelEscolaridade}
                                        onChange={handleNivelEscolaridade}
                                        input={<OutlinedInput label="Icon Nível de Escolaridade" />}
                                        MenuProps={MenuProps}
                                    >
                                        {niveis_list.map((name) => (
                                            <MenuItem
                                                key={name}
                                                value={name}
                                            >
                                                {name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Box>
                            <Box sx={{ flexGrow: 1, display: (nivelEscolaridade === 'Ensino Médio completo' | nivelEscolaridade === '') ? 'none' : 'flex' }}>
                                <TextField
                                    sx={{ flexGrow: 1 }}
                                    label="Em qual área?"
                                    autoComplete="area"
                                    value={area}
                                    onChange={handleArea}
                                    required
                                />
                            </Box>
                        </Box>
                        <Box sx={{ p: 2 }}>
                            <Box sx={{ flexGrow: 1 }}>
                                <FormControl sx={{ width: '100%' }}>
                                    <InputLabel id="experiencia-ti">Possui experiência profissional na área de TI e segmentos correlatos?</InputLabel>
                                    <Select
                                        labelId="experiencia-ti"
                                        value={experienciaTi}
                                        onChange={handleExperienciaTi}
                                        input={<OutlinedInput label="Possui experiência profissional na área de TI e segmentos correlatos?" />}
                                        MenuProps={MenuProps}
                                    >
                                        {experiencia_ti_list.map((name) => (
                                            <MenuItem
                                                key={name}
                                                value={name}
                                            >
                                                {name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Box>
                        </Box>
                        <Box sx={{ p: 2 }}>
                            <Box sx={{ flexGrow: 1 }}>
                                <FormControl sx={{ width: '100%' }}>
                                    <InputLabel id="experiencia-dev">Possui experiência profissional na área de desenvolvimento de software?</InputLabel>
                                    <Select
                                        labelId="experiencia-dev"
                                        value={experienciaDev}
                                        onChange={handleExperienciaDev}
                                        input={<OutlinedInput label="Possui experiência profissional na área de desenvolvimento de software?" />}
                                        MenuProps={MenuProps}
                                    >
                                        {experiencia_ti_list.map((name) => (
                                            <MenuItem
                                                key={name}
                                                value={name}
                                            >
                                                {name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Box>
                        </Box>

                        <Box sx={{ display: 'flex', flexDirection:'column' ,  alignItems: 'flex-start'}}>
                            <Box sx={{p: "1% 2%"}}>
                            <Typography variant='h5'>Adicione em um único pdf os seguintes documentos:</Typography>
                            <ul style={{paddingLeft: '2%'}}>
                                <li><strong>Um documento oficial com foto;</strong></li>
                                <li><strong>Comprovante de residência;</strong></li>
                                <li><strong>Certificados que comprovem as informações do currículo, caso existam.</strong></li>
                            </ul>
                            </Box>
                            <div style={{display: 'flex'}} >
                            <input
                                accept="application/pdf"
                                style={{ display: 'none' }}
                                id="raised-button-file"
                                type="file"
                                onChange={handleFile}
                            />
                            <label htmlFor="raised-button-file">
                                <Button
                                    disabled={buttonText !== defaultButtonText ? true : false}
                                    sx={{ ml: 3,py: 2 }}
                                    variant="outlined"
                                    component="span"
                                >
                                    <UploadIcon sx={{ mr: 1 }} />
                                    {buttonText}
                                </Button>   

                            </label>
                            <Button
                                sx={{ py: 2, display: buttonText === defaultButtonText ? 'none' : 'flex' }}
                                variant="text"
                                onClick={handleRemoveFile}
                            >
                                <DeleteIcon sx={{ color: 'red', display: buttonText === defaultButtonText ? 'none' : 'flex' }} />
                            </Button>
                            </div>
                           
                        </Box>

                        <Box sx={{ m: 2, display: 'flex', justifyContent: 'flex-end' }}>
                            <Button
                                sx={{}}
                                onClick={() => validate()}
                                variant="contained"
                                disabled={handleDisabled()}
                            >
                                INSCREVER-SE &nbsp;
                                {loading && <CircularProgress size={24} sx={{color: 'white'}} />}
                            </Button>
                        </Box>
                    </>
                    :!update?
                    <Box>
                        <Divider sx={{ my: 1 }} variant="fullWidth" />
                        <Box sx={{ px: 2, display: 'flex', justifyContent: 'center' }}>
                            <Card sx={{ maxWidth: 400 }}>
                                <CardMedia
                                    component="img"
                                    alt="iconeOxetechWork"
                                    width={400}
                                    height={125}
                                    image={iconeOxetechWork}
                                />
                                <CardContent sx={{ py: 1, px: 2 }}>
                                    <Box sx={{ display: 'flex', pb: 1, justifyContent: 'center' }}>
                                        <Button target="_blank" variant="text" sx={{ p: 0 }} href={selectedEdital?.link_edital}>
                                            <Typography variant="body2" sx={{ display: 'flex', alignItems: 'center', color: 'red' }} >
                                                {selectedEdital?.title}
                                            </Typography>
                                        </Button>
                                    </Box>
                                    <Box sx={{ display: 'flex', gap: 1, alignItems: 'center', justifyContent: 'space-between' }}>
                                        <Typography variant="caption">Nº de Inscrição: </Typography>
                                        <Typography sx={{ fontWeight: 'bold' }} variant="h6">#{inscricao.num_inscricao}</Typography>
                                    </Box>
                                    <Box sx={{ display: 'flex', gap: 1, alignItems: 'center', justifyContent: 'space-between' }}>
                                        <Typography variant="caption">Data de inscrição: </Typography>
                                        <Typography sx={{ fontWeight: 'bold' }} variant="body2">{moment(inscricao.createdAt).utc("America/Alagoas").format("lll")}</Typography>
                                    </Box>
                                    <Divider sx={{ my: 2 }} variant="fullWidth" />
                                    {
                                        inscricao.motivo_de_rejeicao !== ''
                                        ?
                                        <>
                                            <Typography sx={{ my: 2}} variant="body2" color="red">
                                                {inscricao.motivo_de_rejeicao }
                                            </Typography>
                                        </>
                                        :
                                        <>
                                            <Typography sx={{ my: 2}} variant="body2" color="orange">
                                                Sua inscrição será validada assim que suas informações forem verificadas.
                                            </Typography>
                                        </>
                                    }
                                    <Divider sx={{ my: 2 }} variant="fullWidth" />
                                    <Box sx={{ display: 'flex', gap: 1, alignItems: 'center', justifyContent: 'space-between' }}>
                                        <Typography variant="caption">Status: </Typography>
                                        <Typography sx={{ fontWeight: 'bold', color: inscricao.status === "Em validação" ? 'orange' : inscricao.status === "Validado" ? 'green' : 'red' }} variant="body1">
                                            {inscricao.status}
                                        </Typography>
                                    </Box>
                                    <Box sx={{ display: 'flex', gap: 1, alignItems: 'center', justifyContent: 'space-between' }}>
                                        <Typography variant="caption">Última atualização: </Typography>
                                        <Typography sx={{ fontWeight: 'bold' }} variant="body2">{moment(inscricao.updatedAt).utc("America/Alagoas").format("lll")}</Typography>
                                    </Box>
                                    <Divider sx={{ my: 2 }} variant="fullWidth" />
                                    <Box sx={{ display: 'flex', gap: 1, alignItems: 'center', justifyContent: 'space-between' }}>
                                        <Typography variant="caption">RG: </Typography>
                                        <Typography sx={{ fontWeight: 'bold' }} variant="body2">{inscricao.rg}</Typography>
                                    </Box>
                                    <Box sx={{ display: 'flex', gap: 1, alignItems: 'center', justifyContent: 'space-between' }}>
                                        <Typography variant="caption">Nível de Escolaridade: </Typography>
                                        <Typography sx={{ fontWeight: 'bold' }} variant="body2">{inscricao.nivel_escolaridade}</Typography>
                                    </Box>
                                    {
                                        inscricao.area !== '' ?
                                            <>
                                                <Box sx={{ display: 'flex', gap: 1, alignItems: 'center', justifyContent: 'space-between' }}>
                                                    <Typography noWrap variant="caption">Área de atuação: </Typography>
                                                    <Typography sx={{ fontWeight: 'bold' }} noWrap variant="body2">{inscricao.area}</Typography>
                                                </Box>
                                            </>
                                            : <></>
                                    }
                                    <Box sx={{ display: 'flex', gap: 1, alignItems: 'center', justifyContent: 'space-between' }}>
                                        <Typography variant="caption">Experiência TI: </Typography>
                                        <Typography sx={{ fontWeight: 'bold' }} variant="body2">{inscricao.experiencia_ti}</Typography>
                                    </Box>
                                    <Box sx={{ display: 'flex', gap: 1, alignItems: 'center', justifyContent: 'space-between' }}>
                                        <Typography variant="caption">Experiência Dev: </Typography>
                                        <Typography sx={{ fontWeight: 'bold' }} variant="body2">{inscricao.experiencia_dev}</Typography>
                                    </Box>
                                    <Divider sx={{ mt: 2 }} variant="fullWidth" />
                                </CardContent>
                                <CardActions sx={{ display: 'flex', p: 1, justifyContent: 'space-between'}}>
                                    <Button
                                        size="small"
                                        onClick={() => {getDocs()}}
                                    >
                                            SEUS DOCS
                                    </Button>
                                    <Button
                                        size="small"
                                        onClick={() => {UpdateFormulario()}}
                                    >
                                        Editar Dados
                                    </Button>
                                </CardActions>
                            </Card>
                        </Box>
                    </Box>
                    : <>
                    <EditFormulario />
                    </>
            }
        </Box>
    )
}

export default function IntegrationNotistack() {
    return (
      <SnackbarProvider maxSnack={3}>
        <FormularioAluno />
      </SnackbarProvider>
    );
  }
import { styled } from '@mui/material/styles'
import {Box} from '@mui/material';


export const Container = styled(Box)`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 70%;
    /* border-radius: 15px; */
    overflow-y: scroll;
    height: 90vh;
    
    .content{
    background-color: #f5f5f5; 
    /* margin-right: 1%; */
    /* border-radius: 15px; */

    padding: 16px; 
    }
    .intro{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        h3{
            color: #8E24AA;
        }
    }
`

export const ContainerConfirm = styled(Box)`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    borderRadius: 15px;
    .content{
        background-color: #f5f5f5; 
        padding: 16px; 
    }
    .intro{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        h5{
            color: rgba(0, 0, 0, 0.58);

        }
    }
`
import React from 'react'
import 'animate.css'
import { Box } from '@mui/system'
import Header from '../../components/HeaderOxeTechWork'
import Rodape from '../../components/Rodape'
import Formulario from './Formulario'

import { createTheme, ThemeProvider } from '@mui/material/styles'
const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 800,
      md: 960,
      lg: 1280,
      xl: 1920
    }
  }
})

function Main() {
  return (
    <ThemeProvider theme={theme}>
      <Box>
        <Header />
        <Formulario />
        <Rodape />
      </Box>
    </ThemeProvider>
  )
}

export default Main

import React, { useState } from "react";
import {
    Modal,
    Typography,
    styled,
    Box,
    Button,
    Grid,
    CardContent,
    Avatar
} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import ReplyIcon from '@mui/icons-material/Reply';
import * as palette from "../../utils/styledVariables"
import {Labels, VagaName,Info,InfoAdd,Title,ModalCard} from "./styles"
import logo from "../../assets/homeTriangle/oxeTechWorkLogoON.png"
import renderHTML from 'react-render-html'


import BusinessIcon from '@mui/icons-material/Business';
import remoto from "../../assets/iconesOxeWork/remotoW.svg"
import horas from "../../assets/iconesOxeWork/horasW.svg"
// import naoSalvo from "../../assets/iconesOxedin/naoSalvo.svg"
import tipo from "../../assets/iconesOxeWork/tipoW.svg"
import remuneracao from "../../assets/iconesOxeWork/remuneracaoW.svg"
import noFolder from "../../assets/iconesOxeWork/noFolderW.svg"
import noResults from "../../assets/iconesOxeWork/noResultsW.svg"
import search from "../../assets/iconesOxeWork/searchW.svg"
import history from '../../services/history'
import { height } from "@mui/system";

const SimpleButton = styled((props) => <Button {...props} />)(() => ({
    background:
        palette.roxoAzulado,
    color: palette.profileWhiteShade,
    margin: "10px 5px 0 5px",
    height: "35px"
}));


const styleModal = {

    width: "300px",
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    padding: "10px",
    textAlign: "center",
    borderRadius: "1vh",
    justifyContent: 'center'

};


function ModalDetalhes(props) {

    const detalhes = props.detalhes?.value

    return (
            <ModalCard open={props.open} onClose={props.close} footer={null}   >
            <CardContent sx={{background:"#fff", borderRadius: '15px', mt: 2 ,height: "100%",overflow: "auto"}}>   
                <Labels>
                    <img src={logo} width="18%" height="18%"  alt="logo oxetechWork" />
                    <h3>Quantidade de Vagas: <strong>{detalhes?.quantidade}</strong> </h3>
                </Labels>
                <Title>
                    <div className="avatar-place"  >
                    <Avatar 
                        className="avatar" 
                        sx={{}} 
                        alt="Logo da empresa" 
                        src={detalhes?.avatarEmpresa ? detalhes?.avatarEmpresa : "/static/images/avatar/1.jpg"} 
                    >
                    <BusinessIcon fontSize="large"></BusinessIcon>
                    </Avatar>
                    </div>
                    <div className="grow"></div>
                    <VagaName>
                    <h1 style={{margin:0, }}>{detalhes?.titulo.toLowerCase()}</h1>
                    <div className="horizontal"></div>
                    {/* <h5 style={{margin:0}}      >{detalhes?.empresa.razao_social.toLowerCase()} • {detalhes?.empresa.endereco.municipio} , {vagasSearch[id].empresa.endereco.estado}</h5> */}
                    </VagaName>
                </Title>
                <Info>
                    <Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid sx={{display:'flex', flexDirection:"row",  alignItems: "center", }} item xs={6}>
                        <img  style={{marginRight: "2%"}} src={remoto} alt="modalidade" width="8%"/>
                        <h5 style={{margin: 0}}>{detalhes?.modalidade !== null? detalhes?.modalidade: "Não informado" }</h5>
                    </Grid>
                    <Grid sx={{display:'flex', flexDirection:"row",  alignItems: "center", }} item xs={6}>
                        <img  style={{marginRight: "2%"}} src={remuneracao} alt="remuneracao" width="8%"/>
                        <h5 style={{margin: 0}}> {detalhes?.remuneracao !== null? "R$" + detalhes?.remuneracao: "Não informado" }</h5>
                    </Grid> 
                    <Grid sx={{display:'flex', flexDirection:"row",  alignItems: "center", }} item xs={6}>
                        <img  style={{marginRight: "2%"}} src={tipo} alt="tipo" width="8%"/>
                        <h5 style={{margin: 0}}>{detalhes?.tipo !== null? detalhes?.tipo: "Não informado" }</h5>
                    </Grid>
                    <Grid sx={{display:'flex', flexDirection:"row",  alignItems: "center", }} item xs={6}>
                        <img  style={{marginRight: "2%"}} src={horas} alt="horas" width="8%"/>
                        <h5 style={{margin: 0}}>{detalhes?.carga_horaria !== null? detalhes?.carga_horaria + " horas/semanais": "Não informado" }</h5>
                    </Grid>
                    </Grid>
                </Info>
                <div className="horizontal"></div>
                <InfoAdd>
                    <h3>Descrição da vaga</h3>
                    <p>
                        {
                        detalhes?
                        renderHTML(detalhes.descricao !== null? detalhes.descricao : "Não informado."):
                        "Não informado."
                        }
                    </p>
                </InfoAdd>
                <div className="horizontal"></div>
                <InfoAdd>
                    <h3>Requisitos da vaga</h3>
                    <ul>
                        {detalhes?.requisitos.map((req, i) => {
                        return <li key={i}>{req}</li>;
                        })}
                    </ul>
                </InfoAdd>
                <div className="horizontal"></div>
                <InfoAdd>
                    <h3>Qualificação </h3>
                    <h4>{detalhes?.qualificacoes}</h4>
                </InfoAdd>
        </CardContent>
        </ModalCard >
        )
}

export default ModalDetalhes;
import MonitorIcon from '@mui/icons-material/Monitor';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React from 'react';
import './styles.css';

function Ocupacao() {

    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                flexWrap: 'wrap',
            }}
        >
            <Box
                sx={{
                    display: 'flex'
                }}
            >
                <MonitorIcon
                    sx={{
                        color: '#FFFFFF',
                    }}
                />
                <Typography
                    tabIndex="0"
                    noWrap
                    variant="body2"
                    sx={{
                        p: 0.5,
                        color: '#FFFFFF',
                        fontFamily: 'Roboto',
                    }}
                >
                    {"Administração OxeTech"}
                </Typography>
            </Box>
        </Box>
    );
}

export default Ocupacao
import { makeStyles } from '@material-ui/styles'
import { Button } from '@mui/material'
import Box from '@mui/material/Box'
import { styled } from '@mui/material/styles'

export const Tabline = styled(Box)`
  width: 80vw;
  height: auto;
`
export const useStyles = makeStyles(theme => ({
  app: {
    backgroundColor: 'transparent',
    boxShadow: 'none'
  },
  tab1: {
    backgroundColor: "#89008B",//"rgb(170, 75, 27)",
    padding: 0,
    width: '20%',
    borderRadius: '20px 20px 0px 0px'
  },
  tab2: {
    backgroundColor: "#A6036D",
    padding: 0,
    width: '20%',
    borderRadius: '20px 20px 0px 0px'
  },
  tab3: {
    backgroundColor: "#B60F4D",
    padding: 0,
    width: '20%',
    borderRadius: '20px 20px 0px 0px'
  },
  taboff: {
    backgroundColor: 'rgba(255, 255, 255, 0.49)',
    padding: 0,
    width: '20%',
    borderRadius: '20px 20px 0px 0px'
  },
  indicator: {
    display: 'none'
    // backgroundColor: '#fff',
    // height: '20%'
  },
  oxeLab: {
    background: "linear-gradient(-282deg, #741F8A 10%, #D46D0D 110.33%);",//'linear-gradient(51.28deg, #D56D0D -41.49%, #741F8A 111.44%)',
    borderColor: 'rgba(0, 0, 0, 0.49)',
    boxShadow: '2px 4px 4px rgba(0, 0, 0, 0.5)',
    borderRadius: '0px 15px 15px 15px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: '70vh',
    maxHeight: '1080px'
  },
  oxeDin: {
    background: "linear-gradient(-282deg, #741F8A 10%, #D46D0D 110.33%);",
    borderColor: 'rgba(0, 0, 0, 0.49)',
    boxShadow: '2px 4px 4px rgba(0, 0, 0, 0.5)',
    borderRadius: '0px 15px 15px 15px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: '70vh',
    maxHeight: '1080px'
  },
  oxeWork: {
    background: "linear-gradient(-282deg, #741F8A 10%, #D46D0D 110.33%);",
    borderColor: 'rgba(0, 0, 0, 0.49)',
    boxShadow: '2px 4px 4px rgba(0, 0, 0, 0.5)',
    borderRadius: '0px 15px 15px 15px',
    height: '70vh',
    maxHeight: '1080px'
  },
  Title: {
    width: '50vw',
    paddingRight: '5vw',
    margin: '2vh',
    fontFamily: ['"Poppins", sans-serif'].join(',')
  },
  titulo: {
    color: '#fff',
    fontSize: '450%',
    animation: 'fadeInUp',
    animationDuration: '2s'
  },
}))

export const ButtonLab = styled(Button)`
  :hover{
    background-color: #DD7A1E;
  }
`

export const Title = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  .content {
    width: 50vw;
    padding-right: 5vw;
    margin: 2vh;
    font-family: 'Poppins', sans-serif;
    
  }
  .titulo {
    color: #fff;
    font-size: 450%;
    animation: fadeIn;
    animation-duration: 2s;
    /* @media (max-width: 800px) {
        text-align: center;
      } */
    @media (min-width: 1500px) {
      font-size: 4rem;
    }
  }
  .tituloDin {
    color: #fff;
    font-size: 400%;
    animation: fadeIn;
    animation-duration: 2s;
  }

  .subtitulo {
    color: rgba(255, 255, 255, 0.9);
    font-family: 'Poppins', sans-serif;
    font-size: 0.9rem;
    animation: fadeIn;
    animation-duration: 2s;
    text-align: justify;
    
    /* @media (max-width: 800px) {
      text-align: center;
    } */
    @media (min-width: 1800px) {
      font-size: 1.5rem;
    }
  }
  /* @media (max-width: 800px) {
    width: 90vw;
  } */
  .oxedin {
    width: 60vw;
  }

  .listas {
    color: #fff;
    margin-left: 1vw;
    animation: fadeIn;
    animation-duration: 2s;
    @media (min-width: 1800px) {
      font-size: 1.5rem;
    }
  }
`



import React from "react";

import { useAuth } from "../../hooks/auth/AuthContext";
import DashUserAdm from "../../components/DashUserAdm";
import { Container } from "./styles";

function DashAdmin() {
  const { adminUser } = useAuth();

  return <Container>{adminUser && <DashUserAdm />} </Container>;
}

export default DashAdmin;

import React, { useState } from "react";
import { useSelector } from "react-redux";
import MainHeader from "../../components/MainHeader";

import logo from "../../assets/homeTriangle/oxeTechLabLogoON.png";

import Turmas from "../../components/TurmasOxetechLab";
import Card from "../../components/VagasOxetechLab";

import { createTheme } from "@mui/material";
import { useAuth } from "../../hooks/auth/AuthContext";
import { Container, Logo, TabsCursos } from "./styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#D46D0D",
    },
  },
});

function OxetechLab() {
  const signed = useSelector((state) => state.auth.signed);
  const { adminUser } = useAuth();
  const tabs = [
    {
      label: "Turmas Disponíveis",
      componente: <Card className="card" />,
    },
    {
      label: "Minhas Turmas",
      componente: <Turmas className="card" />,
    },
  ];

  const [professor, setProfessor] = useState(false);
  const toggleProfessor = () => setProfessor(!professor);

  return (
    <Container>
      {!signed && !adminUser ? <MainHeader /> : ""}
      <Logo>
        <img src={logo} className="img" alt="Oxe-tech_Oxe-Din" />
        <p className="subtitulo">Encontre turmas presenciais na sua cidade!</p>
      </Logo>
      {!signed ? (
        <Card className="card" />
      ) : (
        <TabsCursos
          className="tabs"
          defaultActiveKey="1"
          centered
          items={tabs.map((item, i) => {
            return {
              label: item.label,
              key: i,
              children: item.componente,
            };
          })}
        />
      )}
      {/* <ThemeProvider theme={theme}>
      <Button onClick={toggleProfessor}>{professor ? 'Professor' : 'Aluno'}</Button>
      <AtividadesDaTurma professor={professor} />
     </ThemeProvider> */}
    </Container>
  );
}

export default OxetechLab;

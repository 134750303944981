import React from "react";
import { Container } from "./styles";
import { Typography } from "@mui/material";

export default function FooterLaccan() {
  
  const getCurrentYear = () => {
    return new Date().getFullYear();
  };

  return (
    <Container>
      <Typography className="texto">
      {"© " + getCurrentYear() + " Desenvolvido pelo "}
        <a
          href="https://sites.google.com/laccan.ufal.br/laccan"
          target="_blank"
          className="botao"
        >
          LaCCAN
        </a>
        {/* . Todos os direitos reservados. */}
      </Typography>
    </Container>
  );
}

import styled, {keyframes} from "styled-components";
import { Card, Tabs } from "@mui/material";
export const Container = styled.div`
  //background: linear-gradient(90deg, #F6931C 0.38%, #F4851E 34.3%, #F05E23 99.59%);
  background: white;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;  
  .hearTrilha {
    display: flex;
    width: 600px;
    height: 168px;
    justify-content: center;
    flex-shrink: 0;
    /* padding: 80px; */

    color: black;
    //font-family: 'Montserrat';
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;
    justify-content: center;
    background: none;
  }
`;

export const Search = styled("div")`
  display: flex;
  justify-content: center;
  margin-bottom: 1%;
  width: 100%;
  /* img{
        /* width: 3%;
        @media (max-width: 700px) {
            
        }  */
  .input {
    background: rgba(212, 109, 13, 0.11);
    border-radius: 10px;
    border: 1px solid rgba(212, 109, 13, 0.6);
    width: 80%;
    margin-left: 2%;
    padding: 1%;
    color: rgba(0, 0, 0, 1);
    font-family: "Poppins", sans-serif;
    font-size: 1em;
    line-height: 1%;
  }

  input:focus {
    border-color: #8e24aa;
  }
  input::placeholder {
    color: #00000078;
  }
`;
const scaleIn = keyframes`
  from {
    transform: scale(1);
  }
  to {
    transform: scale(1.04);
  }
`;

const scaleOut = keyframes`
  from {
    transform: scale(1.04);
  }
  to {
    transform: scale(1);
  }
`;

export const CardTrilha = styled(Card)`
  width: 393px;
  height: auto;
  border-radius: 5px;
  background: #fff;
  margin: 10px;
  transition: transform 0.3s ease-in-out; 

&:hover {
  animation: ${scaleIn} 0.3s ease-in-out;
  transform: scale(1.04); 
}

&:not(:hover) {
  animation: ${scaleOut} 0.3s ease-in-out;
}
`;

export const TabsTrilhas = styled(Tabs)`
  .ant-tabs-tab-btn{
    color: rgba(0, 0, 0, 0.77);
    font-family: 'Karla', sans-serif;
    font-weight: 700;
    font-size: 1.1em;
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
   color: rgba(212, 109, 13, 1) !important; 
   font-weight: 700;
  }
  .ant-tabs-ink-bar {
   position: absolute;
   background: rgba(212, 109, 13, 1);
   pointer-events: none;
  }
  .ant-tabs-nav-wrap{
    border: 1px solid white !important;
  }

`
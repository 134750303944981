import React, { useState } from "react";

import {
  Avatar,
  Paper,
  Typography,
  useMediaQuery,
} from '@mui/material';

import {
  useStyles,
  ChipPendente,
  ChipConcluido,
} from '../styles';

import IconeAtividadePendente from '../../../assets/iconesAtividadesDaTurma/pending-activities-icon.svg';
import IconeAtividadeConcluida from '../../../assets/iconesAtividadesDaTurma/done-activities-icon.svg';
import IconeDeadline from '../../../assets/iconesAtividadesDaTurma/deadline-icon.svg';

import { ModalDetalhesAtividadeAluno } from "../ModalDetalhesAtividadeAluno";

export const CardAtividadeAluno = ({ atividade }) => {
    const classes = useStyles();
    const isSmallScreen = useMediaQuery('(max-width:600px)');
  
    const [openModal, setOpenModal] = useState(false);
    const handleOpenModal = (e) => setOpenModal(true);
    const handleCloseModal = () => setOpenModal(false);
  
    return (
      <>
        <Paper elevation={6} className={classes.root} onClick={handleOpenModal} >
          <div className={classes.iconAndTitleContainer}>
            <Avatar
              className={classes.icon}
              src={atividade.concluida ? IconeAtividadeConcluida : IconeAtividadePendente}
              alt={atividade.concluida ? 'Ícone de atividade concluída' : 'Ícone de atividade pendente'}
              classes={{ img: classes.iconImg }}
            />
            <Typography
              variant='h5'
              align='left'
              className={classes.title}
              style={{ fontSize: isSmallScreen ? '2.0vh' : '3.0vh' }}
            >
              {atividade.titulo}
            </Typography>
          </div>
          <div className={classes.statusAndDateContainer}>
            {atividade.concluida ?
              <ChipConcluido sx={{fontSize: isSmallScreen ? '2.5vw' : '1.2vw'}}/> :
              <ChipPendente sx={{fontSize: isSmallScreen ? '2.5vw' : '1.2vw'}}/>
            }
            <div className={classes.dateContainer}>
              <img alt='Icone de relógio' src={IconeDeadline} />
              <Typography variant='caption' sx={{ paddingRight: '0.5rem', paddingLeft: '0.5rem' }} >05/05/2023 às 16h</Typography>
            </div>
          </div>
        </Paper>
  
        <ModalDetalhesAtividadeAluno
          open={openModal}
          close={handleCloseModal}
          atividade={atividade}
        />
      </>
    );
  };
import { makeStyles } from "@material-ui/styles";
import { ExpandLess, ExpandMore, TableChart } from "@mui/icons-material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import BadgeIcon from "@mui/icons-material/Badge";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import SaveIcon from "@mui/icons-material/Save";
import { Button } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Tooltip from "@mui/material/Tooltip";
import { SnackbarProvider, useSnackbar } from "notistack";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import logoSecti from "../../assets/brasao_governo_200.png";
import { updateProfileRequest } from "../../store/modules/user/actions";
import * as palette from "../../utils/styledVariables";
import {
  laranjaIntermediario2,
  laranjaSecundario,
} from "../../utils/styledVariables";
import AccountMenu from "../AccountMenu";
import Municipio from "./Municipio";
import Name from "./Name";
import Ocupacao from "./Ocupacao";
import { useAuth } from "../../hooks/auth/AuthContext";
const drawerWidth = 240;

const useStyles = makeStyles({
  paper: {
    position: "static !important",
    backgroundColor: laranjaSecundario, //"rgb(142 36 170) !important"
  },
  "@global": {
    "*::-webkit-scrollbar": {
      width: "0.4em",
    },
    "*::-webkit-scrollbar-track": {
      "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "*::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0,0,0,.3)",
    },
  },
  thumb: {
    color: "#e9e9e9",
  },
  rail: {
    boxShadow: "0px 4px 6px rgb(0 0 0)",
  },
  ListPadding: {
    padding: "0 !important",
  },
  SliderRoot: {
    padding: "7px 0 !important",
  },
  underlineNone: {
    "&:hover": {
      color: "red",
    },
  },
});

function SidebarAdmin({ onMobile, collapsed, setCollapsed }) {
  const { enqueueSnackbar } = useSnackbar();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [dataChanged, setDataChanged] = React.useState(false);
  const profile = useSelector((state) => state.user);
  const [listQuadros, setListQuadros] = React.useState(true);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const { sudoUser } = useAuth();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  // const SmallAvatar = styled(Avatar)(({ theme }) => ({
  //   width: 20,
  //   height: 20,
  //   // border: `0.5px solid rgba(22, 9, 178, 0.79)`,
  // }));

  const location = useLocation();

  const dispatch = useDispatch();

  const classes = useStyles();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleDrawerCollapsed = () => {
    setCollapsed(!collapsed);
  };
  function getName() {
    let name = "?";
    
    if (profile.nome_social) {
      name = profile.nome_social[0].toUpperCase();
    } else if (profile.name) {
      name = profile.name[0].toUpperCase();
    }

    return name;
  }
  const routes = [
    {
      name: "Quadros administrativos",
      open: listQuadros,
      changeState: () => {
        setListQuadros(!listQuadros);
      },
      icon: TableChart,
      children: [
        {
          name: "Quadro Oxetech Work",
          link: "/dashadmin",
          // icon: BadgeIcon,
        },
        {
          name: "Quadro OxeTech LAB",
          link: "/oxetechlab-dashboard",
          // icon: DomainAddIcon,
        },
        { name: "Quadro OxeTrilhas", link: "/trilhas-admin"},
        { name: "Quadro OxetechEdu", link: "/oxetechedu-dashboard"}
      ],
    },
    { name: "OxeTech Work", link: "/oxe-tech-work", icon: BadgeIcon },
  ];

  useEffect(() => {
    profile.dataChanged = false;

    setInterval(() => {
      if (profile.dataChanged) {
        setDataChanged(profile.dataChanged);
      }
    }, 500);
  }, []);

  function saveChanges() {
    if (profile.name.length < 5) {
      enqueueSnackbar("O nome precisa ter 5 caracteres no mínimo!", {
        variant: "error",
      });
      return;
    }
    dispatch(updateProfileRequest(profile));
  }

  const drawer = (
    <Box sx={{ backgroundColor: laranjaSecundario }}>
      <Box sx={{ position: "relative" }}>
        <Box
          sx={{
            position: "absolute",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Tooltip title={"Salvar alterações"}>
            <IconButton
              sx={{
                display: dataChanged ? "flex" : "none",
                color: "white",
              }}
              onClick={() => saveChanges()}
            >
              <SaveIcon fontSize={"large"} />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <IconButton
          variant="text"
          component="span"
          sx={{
            mt: 1,
            ml: 3,
            mr: 3,
            width: "100px",
            height: "100px",
          }}
        >
          <Tooltip arrow placement="right" title="Minha Conta">
            <IconButton
              onClick={handleClick}
              size="small"
              sx={{ ml: 2 }}
              aria-controls={open ? "account-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
            >
              <Avatar
                src={logoSecti}
                sx={{
                  mt: 1,
                  ml: 3,
                  mr: 3,
                  width: "100px",
                  height: "100px",
                  fontSize: "48px",
                }}
              >
                {getName()}
              </Avatar>
            </IconButton>
          </Tooltip>
        </IconButton>

        <AccountMenu
          anchorEl={anchorEl}
          onClick={handleClose}
          current={"admin"}
        />
      </Box>
      <Name />
      <Box
        sx={{
          ml: 2,
        }}
      >
        <Ocupacao />
        <Municipio />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          justifyContent: "center",
        }}
      ></Box>
      <List component="nav">
        {routes.map((route, index) => (
          <div key={route.name}>
            <ListItem
              selected={location.pathname === route.link}
              dense
              component={route.link ? Link : Button}
              to={route.link ? route.link : null}
              key={index}
              onClick={route.children ? route.changeState : null}
              sx={{ textTransform: "none" }}
            >
              <ListItemIcon sx={{ color: palette.profileWhiteShade }}>
                <route.icon />
              </ListItemIcon>
              <ListItemText
                primary={route.name}
                sx={{ color: palette.profileWhiteShade }}
              />
              {route.children &&
                (route.open ? (
                  <ExpandLess sx={{ color: palette.profileWhiteShade }} />
                ) : (
                  <ExpandMore sx={{ color: palette.profileWhiteShade }} />
                ))}
            </ListItem>
            {route.children && (
              <Collapse in={route.open} timeout="auto" unmountOnExit>
                <List
                  component="div"
                  disablePadding
                  // sx={{ background: palette.roxoEscuro }}
                >
                  {route.children?.map((child, child_index) => (
                    <ListItem
                      selected={location.pathname === child.link}
                      dense
                      component={Link}
                      to={child.link}
                      key={child_index}
                    >
                      {/* <ListItemIcon sx={{ color: palette.profileWhiteShade }}>
                        <child.icon />
                      </ListItemIcon> */}
                      <ListItemText
                        primary={child.name}
                        sx={{ color: palette.profileWhiteShade }}
                      />
                    </ListItem>
                  ))}
                </List>
              </Collapse>
            )}
          </div>
        ))}
        {sudoUser && (
          // { name: "Gerenciar administradores", link: "/administradores", icon: PersonAddIcon }
          <ListItem
            selected={location.pathname === "/administradores"}
            dense
            component={Link}
            to={"/administradores"}
            key={routes.length}
            sx={{ textTransform: "none" }}
          >
            <ListItemIcon sx={{ color: palette.profileWhiteShade }}>
              <PersonAddIcon />
            </ListItemIcon>
            <ListItemText
              primary={"Gerenciar administradores"}
              sx={{ color: palette.profileWhiteShade }}
            />{" "}
          </ListItem>
        )}
      </List>
    </Box>
  );

  return (
    <Box sx={{ position: "fixed", zIndex: 5 }}>
      <Box
        sx={{
          display: { xs: "flex", sm: "none" },
          position: "relative",
        }}
      >
        <Box sx={{ position: "absolute" }}>
          <IconButton
            size="small"
            sx={{
              mt: 1,
              left: "15px",
              color: "white",
              backgroundColor: mobileOpen ? "inherit" : laranjaIntermediario2,
            }}
            onClick={handleDrawerToggle}
          >
            <ArrowForwardIcon />
          </IconButton>
        </Box>
      </Box>
      <Box
        sx={{
          backgroundColor: laranjaSecundario,
          position: "relative",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {!onMobile && (
          <Box
            sx={{
              left: !collapsed ? "calc(100% - 35px)" : "",
              position: "absolute",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <IconButton
              size="small"
              sx={{
                mt: 1,
                ml: collapsed ? 0.5 : -0.5,
                color: "white",
                backgroundColor: !collapsed ? "inherit" : laranjaIntermediario2,
                "&:hover": {
                  backgroundColor: laranjaIntermediario2,
                  boxShadow: "5",
                },
              }}
              onClick={handleDrawerCollapsed}
            >
              {!collapsed ? <ArrowBackIcon /> : <ArrowForwardIcon />}
            </IconButton>
          </Box>
        )}
        <Box sx={{ display: "flex", height: "100vh" }}>
          <Collapse orientation="horizontal" in={!collapsed}>
            <Box
              component="nav"
              sx={{
                backgroundColor: laranjaSecundario,
                width: { sm: drawerWidth },
                flexShrink: { sm: 0 },
              }}
              aria-label="left navigation drawer"
            >
              <SwipeableDrawer
                classes={{ paper: classes.paper }}
                variant="temporary"
                open={mobileOpen}
                onClose={handleDrawerToggle}
                onOpen={handleDrawerToggle}
                ModalProps={{
                  keepMounted: true, // Better open performance on mobile.
                }}
                sx={{
                  display: { xs: "block", sm: "none" },
                  "& .MuiDrawer-paper": {
                    boxSizing: "border-box",
                    width: drawerWidth,
                  },
                }}
              >
                {drawer}
              </SwipeableDrawer>

              <SwipeableDrawer
                classes={{ paper: classes.paper }}
                variant="permanent"
                onClose={handleDrawerToggle}
                onOpen={handleDrawerToggle}
                sx={{
                  display: { xs: "none", sm: "block" },
                  "& .MuiDrawer-paper": {
                    boxSizing: "border-box",
                    width: drawerWidth,
                  },
                }}
                open
              >
                {drawer}
              </SwipeableDrawer>
            </Box>
          </Collapse>
        </Box>
      </Box>
    </Box>
  );
}

export default function IntegrationNotistack({
  onMobile,
  collapsed,
  setCollapsed,
}) {
  return (
    <SnackbarProvider maxSnack={3}>
      <SidebarAdmin
        onMobile={onMobile}
        collapsed={collapsed}
        setCollapsed={setCollapsed}
      />
    </SnackbarProvider>
  );
}

import React from 'react';
import { Card, Row } from 'antd';

import { cursos } from './Cursos';

import { Container } from './styles';
import { useSelector } from 'react-redux';

import './styles.css'

import MainHeader from '../../components/MainHeader'

function PageCursos() {
  const { Meta } = Card;
  const signed = useSelector(state => state.auth.signed);
  return (
    <Container style={{margin: '0px', padding: '0px'}}>
      { !signed ? <MainHeader /> : ''}
      <Row style={{
        borderTop: '1px solid rgb(115 2 145)',
        paddingTop: '15px',
        marginRight: '0',
        background: "linear-gradient(180deg, #741f8a 48.1%, #340e74 89.08%,#000063 122.33%)",
        // backgroundColor: '#9216b3',
        // '-webkit-box-shadow': 'rgb(30 30 30) 0px -1px 10px 0px',
        // '-moz-box-shadow': 'rgb(30 30 30) 0px -1px 10px 0px',
        // 'box-shadow': 'rgb(30 30 30) 0px -1px 10px 0px',
        }} gutter={{xs: 8, sm: 16, md: 24, lg: 32 }} className="cards">
        {cursos.map((curso, index) => {
          return (
            <a
              key={index}
              href={signed ? `https://web.digitalinnovation.one/course/${curso.slug}/learning/${curso.id}?ref=alagoas` : '/login'}
              target={signed ? "_blank" : "_self"}
              rel="noreferrer"
            >
              <Card
                className='cursos'
                style={{
                  // backgroundColor: '#fff2c0',
                  backgroundColor: '#fffff',
                  borderRadius: '10px',
                  width: 300,
                  height: 350,
                  '-webkit-box-shadow': 'rgb(191 84 219) 0 0 10px 3px',
                  '-moz-box-shadow': 'rgb(191 84 219) 0 0 10px 3px',
                  'box-shadow': 'rgb(191 84 219) 0 0 10px 3px',
                }}
                cover={<img style={{borderRadius: '10px'}} alt="example" src={curso.cover} />}
              >
                <Meta
                  title={
                    <strong>
                      <small>{curso.name}</small>
                    </strong>
                  }
                  description={curso.description}
                />
              </Card>
            </a>
          );
        })}
      </Row>
    </Container>
  );
}

export default PageCursos;

import { makeStyles, styled } from '@material-ui/styles';
import { Button, Chip } from '@mui/material';

export const useStyles = makeStyles(() => ({
  modalContainer: {
    position: 'absolute',
    display: 'flex',
    paddingTop: '1.3rem',
    paddingRight: '1.5rem',
    paddingLeft: '1.5rem',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    height: '90vh',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    borderRadius: '15px',
    overflow: 'auto',
  },

  goBackContainer: {
    display: 'flex',
    width: '100%',
    height: '0.5vh',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },

  goBackButton: {
    borderRadius: '50%',
    backGroundColor: '#D56D0D2E',
    '&:hover': {
      backgroundColor: '#D56D0D2E',
    }
  },

  modalHeader: {
    width: '100%',
    height: '20%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '1rem'
  },

  iconAndTitleContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flexGrow: 1,
    height: '100%',
    width: '65%',
    margin: '3vh'
  },

  icon: {
    margin: '0 1rem 0 1rem',
    backgroundColor: '#D9D9D978',
    width: 'auto',
    height: 'auto',
    flexShrink: 0,
    aspectRatio: '1/1', 
  },

  iconImg: {
    width: '60%',
    height: '60%',
    objectFit: 'contain'
  },

  title: {
    fontFamily: 'Roboto',
    fontWeight: 400,
    height: '100%',
    display: 'flex',
    alignItems: 'center',
  },

  statusAndDateContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignContent: 'flex-end',
    height: '100%',
    maxWidth: '35%'
  },

  modalDateContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'inherit',
  }, 

  descriptionContainer: {
    padding: '3rem',
    paddingRight: '4rem',
    paddingLeft: '4rem'
  },

  description: {
    fontSize: '3vh',
    textAlign: 'justify',
    textJustify: 'inter-word'
  },

  submissionContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    width: '70%',
    marginBottom: '1rem'
  },

  submissionButton: {

  }

}));

const LabelChip = styled((props) =>
  <Chip {...props} variant='filled' />)({    
    fontWeight: 400,
    textAlign: 'center',
    fontSize: '2vh',
    width: '11vw',
    padding: '1rem'
  });

export const ChipPendente = styled((props) =>
  <LabelChip {...props} label="Pendente" />)({
    color: '#D46D0D',
    backgroundColor: '#D56D0D2E',
  }
);

export const ChipConcluido = styled((props) =>
  <LabelChip {...props} label='Concluído' />)({
    backgroundColor: '#D46D0D',
    color: 'white',
  }
);

export const UploadContainer = styled('div')({
  border: '2px dashed #aaa',
  borderRadius: '4px',
  padding: '2rem',
  textAlign: 'center',
  cursor: 'pointer',
  width: '70%',
  height: '50vh',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  '&:hover': {
    backgroundColor: '#f5f5f5',
  },
});

export const SubmissionButton = styled(Button)({
  background: 'linear-gradient(162.93deg, #D56D0D -10.26%, #8E24AA 276.84%)',
  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
  borderRadius: '15px',
  color: '#fff',
  width: '8vw',
  height: '4vh'
});
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Container, Introducao, Title, Programas } from "./styles";
import "animate.css";
import MainHeader from "../../components/MainHeader";
import NewsFeed from "../../components/NewsFeed";
import Programs from "../../components/Programs";
import ProgramsMobile from "../../components/ProgramsMobile";

import PublicoAlvo from "../../components/PublicoAlvo";
import MapaInovacao from "../../components/MapaInovacao";
import SobreNos from "../../components/SobreNos";
import Introducao2 from "../../components/Introducao";
import Rodape from "../../components/Rodape";
import banner from "../../assets/banner.png";
import { store } from "../../store";

import { Box } from "@mui/system";
import { createTheme, ThemeProvider } from "@mui/material/styles";
const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 800,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
});

function Main() {
  const { signed } = store.getState().auth;

  return (
    <ThemeProvider theme={theme}>
      <Container>
        {!signed && <MainHeader />}
        <Introducao style={{ boxShadow: "1px 14px 27px -1px rgba(0,0,0,0.1)" }}>
          {/* <Title>
            <h1 className="titulo">
              A plataforma de tecnologia e inovação de Alagoas
            </h1>
            <h4 className="subtitulo ">
              O OxeTech é resultado de uma política pública que tem por objetivo
              difundir a cultura de empreendedorismo e inovação através da
              capacitação no segmento de tecnologia da informação.
            </h4>
          </Title> */}

          <img src={banner} style={{ width: "100vw"}} />

          {/* <div className="grow"></div> */}
          {/* <NewsFeed /> */}
        </Introducao>
        <Programas>
          <h1 className="titulo">Programas</h1>
          <Box sx={{ width: "100%", display: { xs: "flex", sm: "none" } }}>
            <ProgramsMobile />
          </Box>
          <Box sx={{ display: { xs: "none", sm: "flex" } }}>
            <Programs />
          </Box>
        </Programas>

        <PublicoAlvo />

        <MapaInovacao />
        <SobreNos />
        <Rodape />
      </Container>
    </ThemeProvider>
  );
}

export default Main;

import React from "react";
import { Link } from "react-router-dom";

import logo from "../../assets/logo_oxe-tech.png";
import logoSecti from "../../assets/secti_logo.png";
import { Container, Header, Content, Footer } from "./styles";
import { store } from "../../store";

function PrivacyPolices() {
  const getCurrentYear = () => {
    return new Date().getFullYear();
  };
  const { signed } = store.getState().auth;

  return (
    <Container>
      {!signed && (
        <Header>
          <div>
            <Link to="/">
              <img src={logo} alt="OxeTech" />
            </Link>
          </div>
        </Header>
      )}

      <Content>
        <h1>Politicas de privacidade</h1>
        <div>
          <p>
            A Secretaria de Estado da Ciência, de Tecnologia e Inovação de
            Alagoas, pessoa jurídica de direito privado inscrita no CNPJ sob o
            n°xxx, com sede em Maceió/AL, entendemos como sensível e relevante
            os registros eletrônicos e dados pessoais deixados por você na
            utilização deste site e seus serviços, servindo a presente Política
            de Privacidade para regular, de forma simples, transparente e
            objetiva, quais dados e informações serão obtidos, assim como quando
            os mesmos poderão ser utilizados.
          </p>
          <p>
            A presente Política se aplica à(s) Página(s) relacionada(s) a
            quaisquer das marcas do Programa OxeTech Lab, entendendo como tal
            todas aquelas elencadas no site oficial no endereço
            https://oxetech.al.gov.br, englobando todos os seus produtos. Para
            melhor ilustrar a forma como realizamos o tratamento de dados,
            apresentamos um resumo de nossa Política de Privacidade e Proteção
            de Dados Pessoais:
          </p>
          <strong>a) quais dados utilizamos:</strong>
          <p>
            A SECTI coletará as informações inseridas ativamente pelo usuário no
            momento do cadastro e ainda, informações coletadas automaticamente
            quando da utilização das páginas e da rede. Há assim, o tratamento
            de dois tipos de dados pessoais:
            <br />
            (i) aqueles fornecidos pelo próprio usuário; e (ii) aqueles
            coletados automaticamente.
          </p>
          <p>
            (i) informações fornecidas pelo usuário: A SECTI coleta todas as
            informações inseridas ativamente pelo usuário nas páginas, tais como
            nome completo, email, gênero, data de nascimento, e cidade e estado,
            mediante comprovação por documento, quando do preenchimento de
            formulários nas páginas pelo usuário.
          </p>
          <p>
            (ii) dados coletados automaticamente: a SECTI também coletará
            informações de modo automático, tais como características do
            dispositivo de acesso, navegador, IP (com data e hora), origem do
            IP, informações sobre cliques, páginas acessadas, as páginas
            seguintes acessadas após a saída das páginas, ou qualquer termo de
            procura digitado nos sites ou em referência a este, dentre outros.
            Para tal coleta, a SECTI fará uso de algumas tecnologias padrões,
            como cookies, pixel tags, beacons e local shared objects, que são
            utilizadas com o propósito de melhorias a experiência de navegação
            do usuário nas páginas, de acordo com seus hábitos e suas
            preferências.
          </p>
          <p>
            É possível desabilitar, por meio das configurações de seu navegador
            de internet, a coleta automática de informações por meio de algumas
            tecnologias, como cookies e caches, bem como em nosso próprio
            website, especificamente quanto aos cookies. No entanto, o Usuário
            deve estar ciente de que, se desabilitadas estas tecnologias, alguns
            recursos oferecidos pelo site, que dependem do tratamento dos
            referidos dados, poderão não funcionar corretamente.
          </p>
          <strong>b) como utilizamos os dados</strong>
          <p>
            As informações coletadas apenas serão utilizadas pela Secretaria de
            Estado da Ciência, da Tecnologia e da Inovação de Alagoas, para
            adequadas prestações dos serviços relacionados ao programa OxeTech
            Lab. As informações coletadas pela SECTI tem como finalidade o
            estabelecimento de melhorias relacionadas à gestão, administração,
            prestação, ampliação e melhorias das Páginas ao usuário,
            adequando-as às suas preferências, gostos, bem como a criação de
            novos serviços a serem oferecidos aos usuários. As informações
            coletadas poderão, ainda, ser utilizadas para fins de comunicação,
            tendo em vista que serão enviados por este canal a divulgação das
            informações, eventos e demais programas relacionados ao OxeTech Lab.
            Também serão utilizadas para a validação do usuário, em sua
            integração à Plataforma OxeTech Lab, para a participação dos cursos.
          </p>
          <strong>c) como mantemos os dados seguros</strong>
          <p>
            A Secretaria de Estado da Ciência, da Tecnologia e da Inovação
            (SECTI) armazenará as informações coletadas em suas páginas em seus
            próprios servidores.
            <br />
            A SECTI utiliza os métodos padrão para criptografar e anonimizar os
            dados coletados;
            <br />
            A SECTI possui proteção contra acesso não autorizado a seus
            sistemas;
            <br />
            Aqueles que entrarem em contato com as informações deverão se
            comprometer a manter sigilo absoluto. A quebra do sigilo acarretará
            responsabilidade civil e o responsável será responsabilizado nos
            moldes da legislação brasileira;
            <br />A SECTI adota os melhores esforços, no sentido de preservar a
            privacidade dos dados dos Usuários. Entretanto, nenhum site é
            totalmente seguro e a SECTI não pode garantir integralmente que
            todas as informações que trafegam nas Páginas não sejam alvo de
            acessos não autorizados perpetrados por meio de métodos
            desenvolvidos para obter informações de forma indevida. Por esse
            motivo, nós incentivamos os Usuários a tomar as medidas apropriadas
            para se proteger, como, por exemplo, mantendo confidenciais todos os
            nomes de usuário e senhas.
          </p>
          <strong>f) retenção das informações</strong>
          <p>
            Reteremos as informações referentes a:
            <br />
            i. Identificação do usuário: nome e sobrenome, username, e-mail,
            número de IP e dispositivo de acesso à internet: os dados estarão em
            sigilo;
            <br />
            ii. Endereço: esta informação, também sigilosa, é requisitada apenas
            para a validação do usuário na plataforma, tendo em vista que serão
            integrados ao programa apenas os brasileiros residentes em Alagoas.
          </p>
          <strong>g) seus direitos</strong>
          <p>
            Em cumprimento à regulamentação aplicável, no que diz respeito ao
            tratamento de dados pessoais, a SECTI respeita e garante ao Usuário,
            a possibilidade de apresentação de solicitações baseadas nos
            seguintes direitos:
            <br />
            i) a confirmação da existência de tratamento;
            <br />
            ii) o acesso aos dados;
            <br />
            iii) a correção de dados incompletos, inexatos ou desatualizados;
            <br />
            iv) a anonimização, bloqueio ou eliminação de dados desnecessários,
            excessivos ou tratados em desconformidade;
            <br />
            v) a portabilidade de seus dados a outro fornecedor de serviço ou
            produto, mediante requisição expressa pelo Usuário;
            <br />
            vi) a eliminação dos dados tratados com consentimento do Usuário;
            <br />
            vii) a revogação do consentimento.
            <br />
            Parte destes direitos poderá ser exercida diretamente pelo Usuário,
            a partir da gestão de informações sobre a conta, na página de seus
            dados. As demais alterações dependerão do envio de solicitação para
            posterior avaliação e adoção de demais providências pela SECTI. Caso
            necessite de qualquer auxílio para exercer seus direitos, o Usuário
            pode entrar em contato com a SECTI, conforme orientações nesta
            Política. O Usuário fica ciente de que a exclusão das informações
            essenciais para gestão de sua conta junto à SECTI implicará no
            término de seu cadastro, com consequente cancelamento dos serviços
            então prestados. A SECTI empreenderá todos os esforços para atender
            tais pedidos no menor espaço de tempo possível. No entanto, mesmo em
            caso de requisição de exclusão, será respeitado o prazo de
            armazenamento mínimo de informações de usuários de aplicações de
            Internet, determinado pela legislação brasileira.
          </p>
          <strong>h) legislação e foro</strong>
          <p>
            Esta Política será regida, interpretada e executada de acordo com as
            Leis da República Federativa do Brasil, especialmente a Lei nº
            13.709/2018, independentemente das Leis de outros estados ou Países,
            sendo competente o foro de domicílio do Usuário para dirimir
            qualquer dúvida decorrente deste documento.
          </p>
        </div>
      </Content>
      <Footer>
        <a href="http://www.secti.al.gov.br/" target="_blanck">
          <img src={logoSecti} alt="SECTI-AL" />
        </a>
        <p>© {getCurrentYear()} Todos os direitos reservados</p>
      </Footer>
    </Container>
  );
}

export default PrivacyPolices;

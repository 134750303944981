import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import Header from '../../../components/Header'
import HeaderOXL from '../../../components/PerfilOxetechLab/Header'
// import HeaderEmpresa from '../../../components/HeaderEmpresa'

import Siderbar from '../../../components/Siderbar'

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';

import {
  ContentSider,
  ContentHeader,
  WrapperSider,
  WrapperHeader,
  Background
} from './styles'

function DefaultLayout({ children, profile }) {
  const isOxetechLab = window.location.pathname
  if (profile) {
    return (

      <Container disableGutters maxWidth={false}>
       <Header profile={profile} />
        <Box>{children}</Box>
      </Container>
    )
  } else {
    return (
      <WrapperSider>
        <Background>{children}</Background>
      </WrapperSider>
    )
  }
}

export default connect(state => ({ profile: state.user.profile }))(
  DefaultLayout
)
DefaultLayout.propTypes = {
  children: PropTypes.element.isRequired
}

import { message } from "antd";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { Redirect, Route} from "react-router-dom";
import DefaultLayout from "../pages/_layouts/default";
import Error from "../pages/Error";
import { useAuth } from "../hooks/auth/AuthContext";

import api from "../services/api";
import { store } from "../store";

export default function RouteWrapper({
  component: Component,
  isSudo,
  ...rest
}) {
  const { signed, token } = store.getState().auth;
  const { logout, adminUser} = useAuth();


  useEffect(() => {
    if (signed) {
      const payload = JSON.parse(atob(token.split(".")[1]));
      const currentTime = Math.round(new Date().getTime() / 1000);
      const expirationTime = payload.exp;
      const leeway = 600;

      if (currentTime - leeway - expirationTime > 1) {
        message.warn("Tempo de sessão expirou!");
        logout();
      }
    }
  }, [signed, token]);

  if(!signed){
    return <Redirect to="/" />;
  }
  //permissao de aluno e empresa
  if (!adminUser) {
    return <Redirect to="/dashboard" />;
  }

  return (
    <Route
      {...rest}
      render={(props) => (
        <DefaultLayout>
          <Component {...props} />
        </DefaultLayout>
      )}
    />
  );
}

RouteWrapper.propTypes = {
  isSudo: PropTypes.bool,
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.func])
    .isRequired,
};

RouteWrapper.defaultProps = {
  isSudo: false,
};

import React from "react";
import { Link } from "react-router-dom";
import {
  Container,
  SectionOne,
  LogoOxeDin,
  LogoOxeTechLab,
  LogoOxeTechWork,
  LogoOxeTechHat,
  TriangleFrame,
  InovationMap,
  Text,
} from "./styles";

import MainHeader from "../../components/MainHeader";

import triangleFrame from "../../assets/homeTriangle/triangleFrameG.png";

import oxeTechHat from "../../assets/homeTriangle/oxeTechHat.png";

import oxeDinLogo from "../../assets/homeTriangle/oxeDinLogo.png";

import oxeTechLabLogo from "../../assets/homeTriangle/oxeTechLabLogo.png";

import oxeTechWorkLogo from "../../assets/homeTriangle/oxeTechWorkLogo.png";

import inovationMapIconON from "../../assets/homeTriangle/inovationMapIconON.png";

function Main(Props) {
  return (
    <Container>
      <MainHeader />

      <SectionOne>
        <Link
          to="/"
          style={{
            marginLeft: "0vw",
            marginTop: "0vh",
            position: "absolute",
          }}
        >
          {/* //corrigir proporcionalidade */}
          <TriangleFrame src={triangleFrame} layoutId="triangleFrame" />
          <LogoOxeTechHat
            src={oxeTechHat}
            layoutId="oxeTech"
            style={{
              marginLeft: "78vw",
              marginTop: "11vh",
              position: "absolute",
            }}
          />
          <LogoOxeDin
            src={oxeDinLogo}
            layoutId="oxeDin"
            style={{
              marginLeft: "86vw",
              marginTop: "20vh",
              position: "absolute",
            }}
          />
          <LogoOxeTechLab
            src={oxeTechLabLogo}
            layoutId="oxeTechLab"
            style={{
              marginLeft: "78.5vw",
              marginTop: "4.5vh",
              position: "absolute",
            }}
          />
          <LogoOxeTechWork
            src={oxeTechWorkLogo}
            layoutId="oxeTechWork"
            style={{
              marginLeft: "70vw",
              marginTop: "11vh",
              position: "absolute",
            }}
          />
        </Link>
        <Link
          to="/inovation-map"
          style={{
            marginLeft: "0vw",
            marginTop: "0vh",
            position: "absolute",
          }}
        >
          <InovationMap
            src={inovationMapIconON}
            layoutId="inovationMap"
            style={{
              marginLeft: "0vw",
              marginTop: "10vh",
              position: "absolute",
            }}
          />
        </Link>
        <Text
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.2, duration: 0.2 }}
          onClick={() => {
            Props.history.goBack();
          }}
        >
          <p
            style={{
              position: "absolute",
              marginTop: "83vh",
              marginLeft: "0vw",
            }}
          >
            Voltar
          </p>
        </Text>
        <Link to="/dash-oxe-tech">
          <Text
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.5, duration: 0.5 }}
          >
            <p
              style={{
                position: "absolute",
                marginTop: "83vh",
                marginLeft: "85vw",
              }}
            >
              Proximo
            </p>
          </Text>
        </Link>
      </SectionOne>
    </Container>
  );
}

export default Main;

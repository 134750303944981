import React, { useState, useEffect } from "react";
import { Grid, Button, Tooltip, IconButton } from "@mui/material";
import TextField from "@mui/material/TextField";
import close from "../../../../../assets/iconesOxeLab/close.svg";
import { Content, ModalEdit} from "./styles";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import ptLocale from "date-fns/locale/pt-BR";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { addDays, subDays, parseISO } from "date-fns";
import Autocomplete from "@mui/material/Autocomplete";
import api from "../../../../../services/api";
import { useSelector } from "react-redux";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import CircularProgress from "@mui/material/CircularProgress";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { TimePicker } from "@mui/lab";
const theme = createTheme({
  palette: {
    primary: {
      main: "rgba(212, 109, 13, 0.5)",
      darker: "rgba(212, 109, 13, 1)",
    },
    secondary: {
      main: "rgba(212, 109, 13, 1)",
      darker: "rgba(212, 109, 13, 1)",
    },
  },
});
export default function ModalEditTurma({ open, onHandleClose, turmaId }) {
  const { escolaId } = useParams();
  const [turma, setTurma] = useState([]);

  const [titulo, setTitulo] = useState("");
  const [descricao, setDescricao] = useState("");
  const [qntVagas, setQntVagas] = useState(null);
  const [diaAula, setDiaAula] = useState("");
  const [horarioInicio, setHorarioInicio] = useState("");
  const [horarioFim, setHorarioFim] = useState("");
  const [dataFimInsc, setDataFimInsc] = useState("");
  const [cargaHoraria, setCargaHoraria] = useState("");

  const [errorTitulo, setErrorTitulo] = useState("");
  const [errorDescricao, setErrorDescricao] = useState("");
  const [errorQnt_Vagas, setErrorQnt_Vagas] = useState("");
  const [errorDiaAula, setErrorDiaAula] = useState("");
  const [errorCargaHoraria, setErrorCargaHoraria] = useState("");
  const [errorHorarioInicio, setErrorHorarioInicio] = useState("");
  const [errorHorarioFim, setErrorHorarioFim] = useState("");
  const [errorData_encerramento_insc, setErrorData_encerramento_insc] =
    useState("");
  const today = new Date();
  const [alertSuccessful, setAlertSuccessful] = useState("");
  const [alertUnauthorized, setAlertUnauthorized] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    api
      .get(`oxetechedu/turmas/${turmaId}`)
      .then((response) => {
        setTitulo(response.data.titulo);
        setDescricao(response.data.descricao);
        setQntVagas(response.data.qtd_vagas_total);
        setDiaAula(response.data.dia_da_aula);
        setHorarioInicio(response.data.hora_inicio);
        setHorarioFim(response.data.hora_fim);
        setDataFimInsc(response.data.encerramento_inscricoes);
        setCargaHoraria(response.data.carga_horaria);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const handleCheckError = () => {
    if (
      !titulo ||
      !descricao ||
      !qntVagas ||
      !diaAula ||
      !horarioInicio ||
      !horarioFim ||
      !dataFimInsc ||
      !cargaHoraria
    ) {
      !titulo && setErrorTitulo("Você não informou o nome da turma!");
      !descricao && setErrorDescricao("Você não preencheu a descrição!");
      !dataFimInsc &&
        setErrorData_encerramento_insc(
          "Você não informou a data do encerramento das inscrições"
        );
      !horarioInicio &&
        setErrorHorarioInicio("Você não informou o horário do início do curso");
      !horarioFim &&
        setErrorHorarioFim("Você não informou o horário do fim do curso");
      !qntVagas && setErrorQnt_Vagas("Você não informou o total de aulas!");
      !cargaHoraria &&
        setErrorCargaHoraria("Você não informou a carga horária!");
    } else {
      handleSubmit();
    }
  };

  const handleSubmit = () => {
    const data_form = {
      titulo: titulo,
      descricao: descricao,
      qtd_vagas_total: qntVagas,
      dia_da_aula: diaAula,
      hora_inicio: horarioInicio,
      hora_fim: horarioFim,
      encerramento_inscricoes: dataFimInsc,
      carga_horaria: cargaHoraria,
      escola_id: escolaId,
    };

    setLoading(true);
    api
      .patch(`/oxetechedu/turmas/${turmaId}`, data_form)
      .then((response) => {
        setLoading(false);
        setAlertSuccessful(response.data.message);
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((err) => {
        setLoading(false);
        setAlertUnauthorized(err.response.data.error);
      });
  };

  const handleTituloChange = (e) => {
    const { value } = e.target;
    setTitulo(value);
    setErrorTitulo("");

    if (value.length === 0) {
      setErrorTitulo("Preencha este campo.");
    }
  };

  const handleDescricaoChange = (e) => {
    const { value } = e.target;
    const truncatedValue = value.slice(0, 255);
    setDescricao(truncatedValue);

    setErrorDescricao("");

    if (truncatedValue.length === 0) {
      setErrorDescricao("Preencha este campo.");
    } else if (truncatedValue.length === 255) {
      setAlertUnauthorized("Limite máximo de 255 caracteres atingido.");
    }
  };

  const handleDiaAulaChange = (date) => {
    setDiaAula(new Date(date).toJSON());
    setErrorDiaAula("");
  };

  const handleCargaHorariaChange = (e) => {
    setCargaHoraria(e.target.value);

    setErrorCargaHoraria("");

    if (e.target.value.length === 0) {
      setErrorCargaHoraria("Preencha este campo.");
    }
  };
  const handleHorarioInicioChange = (e) => {
    setHorarioInicio(e.target.value);

    setErrorHorarioInicio("");

    if (!e.target.value) {
      setErrorHorarioInicio("Preencha este campo.");
    }
  };

  const handleHorarioFimChange = (e) => {
    setHorarioFim(e.target.value);

    setErrorHorarioFim("");
    if (!e.target.value) {
      setErrorHorarioFim("Preencha este campo.");
    }
  };

  const handleQuantVagasChange = (e) => {
    const { value } = e.target;
    setQntVagas(value);

    setErrorQnt_Vagas("");

    if (value.length === 0) {
      setErrorQnt_Vagas("Preencha este campo.");
    } else if (value <= 0) {
      setErrorQnt_Vagas("A quantidade de vagas deve ser maior que zero.");
    }
  };

  const handleDataEncerramentoInscChange = (e) => {
    setDataFimInsc(new Date(e).toJSON());

    setErrorData_encerramento_insc("");

    if (e.length === 0) {
      setErrorData_encerramento_insc("Preencha este campo.");
    }
  };

  return (
    <ModalEdit
      open={open}
      onClose={onHandleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Content>
        <ThemeProvider theme={theme}>
          <div className="header">
            <h1>Editar Turma</h1>
            <img
              className="close"
              src={close}
              alt="Fechar"
              onClick={onHandleClose}
            />
          </div>
          <div style={{ padding: "2%" }}>
            <Grid sx={{ p: "2% 1%" }} container spacing={2}>
              <Grid item xs={12} md={12}>
                <h3 className="label">Nome da Turma</h3>
                <TextField
                  sx={{
                    bgcolor: errorTitulo
                      ? "transparent"
                      : "rgba(212, 109, 13, 0.1)",
                  }}
                  placeholder="Digite o nome da turma"
                  focused
                  error={Boolean(errorTitulo)}
                  helperText={errorTitulo}
                  value={titulo}
                  fullWidth
                  onChange={handleTituloChange}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <h3 className="label">Descrição</h3>
                <TextField
                  multiline
                  rows={3}
                  placeholder="Descreva informações sobre o curso"
                  focused
                  sx={{
                    bgcolor: errorDescricao
                      ? "transparent"
                      : "rgba(212, 109, 13, 0.1)",
                  }}
                  error={Boolean(errorDescricao)}
                  helperText={errorDescricao}
                  value={descricao}
                  fullWidth
                  onChange={handleDescricaoChange}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <h3 className="label">Data de encerramento das inscrições</h3>
                <LocalizationProvider
                  locale={ptLocale}
                  dateAdapter={AdapterDateFns}
                >
                  <DatePicker
                    value={dataFimInsc}
                    onChange={handleDataEncerramentoInscChange}
                    renderInput={(params) => (
                      <TextField
                        size="large"
                        sx={{
                          bgcolor: errorData_encerramento_insc
                            ? "transparent"
                            : "rgba(212, 109, 13, 0.1)",
                        }}
                        focused
                        fullWidth
                        {...params}
                        error={Boolean(errorData_encerramento_insc)}
                        helperText={errorData_encerramento_insc}
                      />
                    )}
                    minDate={today}
                    maxDate={subDays(parseISO(diaAula), 1)}
                    inputFormat="dd/MM/yyyy"
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12} md={4}>
                <h3 className="label">Dia da aula</h3>
                <LocalizationProvider
                  locale={ptLocale}
                  dateAdapter={AdapterDateFns}
                >
                  <DatePicker
                    value={new Date(diaAula)}
                    onChange={handleDiaAulaChange}
                    renderInput={(params) => (
                      <TextField
                        size="large"
                        sx={{
                          bgcolor: errorDiaAula
                            ? "transparent"
                            : "rgba(212, 109, 13, 0.1)",
                        }}
                        focused
                        fullWidth
                        {...params}
                        error={Boolean(errorDiaAula)}
                        helperText={errorDiaAula}
                      />
                    )}
                    minDate={addDays(parseISO(dataFimInsc), 1)}
                    inputFormat="dd/MM/yyyy"
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12} md={4}>
                <h3 className="label">Carga Horária</h3>
                <TextField
                  type="number"
                  placeholder="Digite a carga horária"
                  focused
                  sx={{
                    bgcolor: errorCargaHoraria
                      ? "transparent"
                      : "rgba(212, 109, 13, 0.1)",
                  }}
                  error={Boolean(errorCargaHoraria)}
                  helperText={errorCargaHoraria}
                  value={cargaHoraria}
                  fullWidth
                  onChange={handleCargaHorariaChange}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <h3 className="label">Horário do início da aula</h3>
                <TextField
                  type="time"
                  value={horarioInicio}
                  onChange={handleHorarioInicioChange}
                  fullWidth
                  sx={{
                    bgcolor: errorHorarioInicio
                      ? "transparent"
                      : "rgba(212, 109, 13, 0.1)",
                  }}
                  focused
                  error={Boolean(errorHorarioInicio)}
                  helperText={errorHorarioInicio}
                  color="primary"
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <h3 className="label">Horário do fim da aula</h3>
                <TextField
                  type="time"
                  value={horarioFim}
                  onChange={handleHorarioFimChange}
                  fullWidth
                  sx={{
                    bgcolor: errorHorarioFim
                      ? "transparent"
                      : "rgba(212, 109, 13, 0.1)",
                  }}
                  focused
                  error={Boolean(errorHorarioFim)}
                  helperText={errorHorarioFim}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <h3 className="label">Quantidade de Vagas</h3>
                <TextField
                  type="number"
                  focused
                  sx={{
                    bgcolor: errorQnt_Vagas
                      ? "transparent"
                      : "rgba(212, 109, 13, 0.1)",
                  }}
                  error={Boolean(errorQnt_Vagas)}
                  helperText={errorQnt_Vagas}
                  value={qntVagas}
                  size="large"
                  fullWidth
                  onChange={handleQuantVagasChange}
                />
              </Grid>
            </Grid>

            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                startIcon={
                  loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null
                }
                variant="contained"
                color="secondary"
                onClick={handleCheckError}
              >
                Editar Turma
              </Button>
            </div>
          </div>
        </ThemeProvider>
        <Snackbar
          open={alertUnauthorized.length > 0}
          autoHideDuration={6000}
          onClose={() => setAlertUnauthorized("")}
        >
          <Alert
            variant="filled"
            onClose={() => setAlertUnauthorized("")}
            severity="error"
            sx={{ width: "100%" }}
          >
            {alertUnauthorized}
          </Alert>
        </Snackbar>
        <Snackbar
          open={alertSuccessful.length > 0}
          autoHideDuration={6000}
          onClose={() => setAlertSuccessful("")}
        >
          <Alert
            variant="filled"
            onClose={() => setAlertSuccessful("")}
            severity="success"
            sx={{ width: "100%" }}
          >
            {alertSuccessful}
          </Alert>
        </Snackbar>
      </Content>
    </ModalEdit>
  );
}

import {
  Grid,
  TextField,
  Link,
  IconButton,
  CircularProgress,
  Box,
} from "@mui/material";
import { green } from "@mui/material/colors";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import DeleteIcon from "@mui/icons-material/Delete";
import InputMask from "react-input-mask";
import cep from "cep-promise";
import crypto from "crypto";
import React from "react";
import { storage } from "../../services/firebase";
import api from "../../services/api";

const Input = styled("input")({
  display: "none",
});

export default function AddressForm() {
  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const [hash, setHash] = React.useState(
    sessionStorage.getItem("comprovante_hash")
  );
  const [idFile, setIdFile] = React.useState(
    sessionStorage.getItem("comprovante_id")
  );
  const [numcep, setCep] = React.useState(sessionStorage.getItem("cep") || '');
  const [numero, setNumero] = React.useState(sessionStorage.getItem("numero") || '');
  const [file_temp, setFile] = React.useState({
    name: sessionStorage.getItem("file_name"),
    size: "",
    url: "",
  });
  const [errorCep, setErrorCep] = React.useState(null);
  const [UseEndereco, setUseEndereco] = React.useState({
    rua: sessionStorage.getItem("rua") || '',
    bairro: sessionStorage.getItem("bairro") || '',
    municipio: sessionStorage.getItem("municipio") || '',
    estado: sessionStorage.getItem("estado") || '',
  });

  const handleNumero = (event) => {
    const {
      target: { value },
    } = event;
    setNumero(value);
    if (value.length > 0) sessionStorage.setItem("numero", value);
    else {
      sessionStorage.removeItem("numero");
    }
  };

  const handleAddressField = (event, field) => {
    const {
      target: { value },
    } = event;

    const currentAddress = {...UseEndereco}
    currentAddress[field] = value;
    setUseEndereco(currentAddress);

    if (value.length > 0) sessionStorage.setItem(field, value);
    else {
      sessionStorage.removeItem(field);
    }
  };

  const handleCep = (event) => {
    const {
      target: { value },
    } = event;
    setCep(value);
    setErrorCep({ numcep: "" });

    let reg = new RegExp(/^[0-9]{2}\.[0-9]{3}-[0-9]{3}$/).test(value);
    if (reg) {
      cep(value)
        .then((response) => {
          const {
            street: rua,
            neighborhood: bairro,
            city: municipio,
            cep,
            state: estado,
          } = response;
          
          setUseEndereco({
            rua,
            bairro,
            municipio,
            cep,
            estado,
          });
          sessionStorage.setItem("cep", cep);
          sessionStorage.setItem("estado", estado);
          sessionStorage.setItem("municipio", municipio);
          sessionStorage.setItem("bairro", bairro);
          sessionStorage.setItem("rua", rua);
        })
        .catch((error) => {
          setErrorCep({ numcep: "Não conseguimos resgatar o seu CEP." });
          setUseEndereco({
            rua: "",
            bairro: "",
            municipio: "",
            cep: "",
            estado: "",
          });
          sessionStorage.removeItem("cep");
          sessionStorage.removeItem("estado");
          sessionStorage.removeItem("municipio");
          sessionStorage.removeItem("bairro");
          sessionStorage.removeItem("rua");
        });
    } else {
      setErrorCep({ numcep: "CEP inválido." });
      sessionStorage.removeItem("cep");
      sessionStorage.removeItem("estado");
      sessionStorage.removeItem("municipio");
      sessionStorage.removeItem("bairro");
      sessionStorage.removeItem("rua");
    }
  };

  const handleFile = async (info) => {
    const file = info.target.files[0];
    const isFile = file.type.indexOf("application/pdf") === 0;
    const isLt5M = file.size / 1024 / 1024 < 5;
    setLoading(true);
    setSuccess(true);
    // debugger;
    if (!isFile) {
      alert("Arquivo precisa ser PDF!");
      setLoading(false);
      setSuccess(false);
      sessionStorage.removeItem("comprovante_id");
    } else if (!isLt5M) {
      alert("Arquivo deve ter tamanho máximo de 5MB!");
      setLoading(false);
      setSuccess(false);
      sessionStorage.removeItem("comprovante_id");
    } else {
      const newHash = crypto.randomBytes(4).toString("hex");
      const storageRef = await storage.ref();
      // debugger;
      var File = storageRef.child(`/teste/${file.name}-${newHash}`).put(file);
      setHash(newHash);
      sessionStorage.setItem("comprovante_hash", newHash);

      File.on(
        "state_changed",
        function (snapshot) {},
        function (error) {
          alert(error);
          setLoading(false);
          setSuccess(false);
        },
        function () {
          File.snapshot.ref.getDownloadURL().then(async function (downloadURL) {
            // onSuccess(null, downloadURL);

            await api
              .post("postfile", { name: file.name, downloadURL })
              .then((response) => {
                setIdFile(response.data);
                sessionStorage.setItem("comprovante_id", response.data);
                sessionStorage.setItem("file_name", file.name);
                setLoading(false);
                setFile({
                  ...file_temp,
                  name: file.name,
                  size: file.size,
                  url: downloadURL,
                });
              });
          });
        }
      );
    }

    // return isFile && isLt5M;
  };

  const handleDelFile = async (data) => {
    // debugger;
    const storageRef = await storage.ref();
    var desertRef = storageRef.child(`teste/${data.name}-${hash}`);
    // desertRef.delete();

    desertRef
      .delete()
      .then(async function () {
        await api.delete(`deletefile?id=${idFile}`);
        sessionStorage.removeItem("comprovante_id");
        sessionStorage.removeItem("comprovante_hash");
        sessionStorage.removeItem("file_name");
        setLoading(false);
        setSuccess(false);
        setIdFile(null);
        setHash(null);
        setFile({
          ...file_temp,
          name: null,
          size: null,
          url: null,
        });
      })
      .catch(function (error) {
        // Uh-oh, an error occurred!
        alert("Não conseguimos atender ao seu pedido.");
        sessionStorage.removeItem("comprovante_id");
        sessionStorage.removeItem("comprovante_hash");
        sessionStorage.removeItem("file_name");
        setIdFile(null);
        setHash(null);
        setFile({
          ...file_temp,
          name: null,
          size: null,
          url: null,
        });
      });
  };

  return (
    <Grid container spacing={3} sx={{ my: 3 }}>
      <Grid item xs={12} sm={6}>
        <InputMask
          mask="99.999-999"
          value={numcep}
          disabled={false}
          maskChar=" "
          onBlur={handleCep}
          onChange={handleCep}
        >
          {() => (
            <TextField
              color="warning"
              id="cep"
              label="CEP"
              name="cep"
              autoComplete="cep"
              error={Boolean(errorCep?.numcep)}
              helperText={errorCep?.numcep}
              required
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
        </InputMask>
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          required
          id="rua"
          name="rua"
          label="Rua"
          fullWidth
          autoComplete="address"
          color="warning"
          value={UseEndereco.rua}
          onChange={e => handleAddressField(e, 'rua')}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          required
          id="numero"
          name="numero"
          label="Número"
          fullWidth
          autoComplete="number"
          color="warning"
          value={numero}
          onChange={handleNumero}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          required
          id="bairro"
          name="bairro"
          label="Bairro"
          fullWidth
          autoComplete="neighborhood"
          color="warning"
          value={UseEndereco.bairro}
          onChange={e => handleAddressField(e, 'bairro')}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          required
          id="municipio"
          name="municipio"
          label="Município"
          fullWidth
          autoComplete="city"
          color="warning"
          value={UseEndereco.municipio}
          onChange={e => handleAddressField(e, 'municipio')}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          required
          id="estado"
          name="estado"
          label="Estado"
          fullWidth
          autoComplete="state"
          color="warning"
          value={UseEndereco.estado}
          onChange={e => handleAddressField(e, 'estado')}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>
      {/* <Grid item xs={12} sm={6}>
        <Box>
          <label htmlFor="contained-button-file">
            <Input
              accept="pdf"
              id="contained-button-file"
              multiple
              type="file"
              onChange={handleFile}
              fullWidth
            />
            <Button
              variant="contained"
              component="span"
              color="warning"
              disabled={success}
              startIcon={<FileUploadOutlinedIcon />}
            >
              Comprovante de Residência
            </Button>
            {loading && (
              <CircularProgress
                size={24}
                sx={{
                  color: green[500],
                  position: "absolute",
                  top: "50%",
                  left: "75%",
                  marginTop: "12px",
                  marginLeft: "12px",
                }}
              />
            )}
          </label>
          <Link href={idFile}>{file_temp.name}</Link>
          <IconButton
            color="warning"
            onClick={() => handleDelFile(file_temp)}
            aria-label="Apagar comprovante"
          >
            <DeleteIcon />
          </IconButton>
        </Box>
      </Grid> */}
    </Grid>
  );
}
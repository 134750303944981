import styled from 'styled-components';
import * as palette from '../../utils/styledVariables';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5px 15px;

  height: auto;

  @media (max-width: 700px) {
    width: 100vw;
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: center;

  img {
    width: 15vw;
    margin-bottom: 5px;
  }

  @media (max-width: 700px) {
    img {
      width: 38vw;
      margin-top: -10px;
    }
  }
`;

export const Vagas = styled.div`
  border: 1px solid ${palette.profileMainColor};
  padding: 10px;
  border-radius: 10px;
`;

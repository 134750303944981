import React, { useState } from "react";
import {
    Modal,
    Typography,
    styled,
    Box,
    Button,
} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import ReplyIcon from '@mui/icons-material/Reply';
import * as palette from "../../utils/styledVariables"

const SimpleButton = styled((props) =>
    <Button
        {...props}
        sx={{ "&:hover": { backgroundColor: palette.laranjaIntermediario2 } }}
    />)(() => (
        {
            background:
           palette.laranjaSecundario,
            color: palette.profileWhiteShade,
            margin: "10px 5px 0 5px",
            height: "35px",
        }
    )
);

const styleModal = {
    width: "300px",
    top: "50%",
    left: "50%",
    transform: 'translate(-50%, -50%)',
    position: 'absolute',
    bgcolor: 'background.paper',
    padding: "10px",
    textAlign: "center",
    borderRadius: "1vh",
    justifyContent: 'center'
};

const styleImg = { height: "7vh", objectFit: "contain", marginBottom: "20px" }

export const ModalConfirmar = (props) => {
    return (
        <Modal open={props.open}>
            <Box sx={styleModal} xs={12} sm={12} md={4} lg={3}>
                <img src={props.image} style={styleImg} />

                <Typography sx={{ color: palette.preto }}> {props.text} </Typography>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <SimpleButton onClick={props.handleConfirm} startIcon={<SaveIcon />}> SIM </SimpleButton>
                    <SimpleButton onClick={props.close} startIcon={<ReplyIcon />}> NÃO </SimpleButton>
                </Box>
            </Box>
        </Modal>
    );
}
import React, { useState, useEffect } from "react";
import { Modal, Tooltip } from "@mui/material";
import { useStyles, SubmitButton, TextInput } from "./style";
import { CircularProgress, Grid, Button, MenuItem, Select} from "@mui/material";
import { useTheme, useMediaQuery } from "@material-ui/core";
import { useForm, Controller } from "react-hook-form";
import api from "../../../services/api";
import { useSnackbar, SnackbarProvider } from "notistack";
import ModalCadastroAtividade from "../ModalCadastroAtividade";
import AssignmentIcon from "@mui/icons-material/Assignment";
import { ThemeProvider, createTheme } from "@mui/material/styles";

const theme1 = createTheme({
  palette: {
    primary: {
      main: "rgba(212, 109, 13, 1)",
      darker: "rgba(212, 109, 13, 0.5)",
    },
  },
});

export const ModalCadastro = ({ open, close }) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [openModalCadastroAtividade, setOpenModalCadastroAtividade] =
    useState(false);
  const [activityCreated, setActivityCreated] = useState(
    JSON.parse(sessionStorage.getItem("atividade")) || false
  );

  const [requestError, setRequestError] = useState(null);
  const [atividades, setAtividades] = useState(null);
  const [atividade, setAtividade] = useState({});
  const [title, setTitle] = useState(sessionStorage.getItem("title") || "");
  const [url, setUrl] = useState(sessionStorage.getItem("url") || "");
  const [description, setDescription] = useState(
    sessionStorage.getItem("description") || ""
  );

  const [titleError, setTitleError] = useState("");
  const [urlError, setUrlError] = useState("");
  const [descriptionError, setDescriptionError] = useState("");
  const [activityCreatedError, setActivityCreatedError] = useState("");
  const { control, handleSubmit, formState } = useForm();
  const maxLength = 250;
  const remainingChars = maxLength - description.length;
  const theme = useTheme();
  const onMobile = useMediaQuery(theme.breakpoints.down("sm"));


  useEffect(() => {
    api.get("/atividade").then((response) => {
      setAtividades(response.data);
    });
  }, []);

  const handleChangeAtividade = (atividadeId) => {
    const atividadeSelecionada = atividades.find(atividade => atividade.id === atividadeId);
    setAtividade(atividadeSelecionada || {}); 
  };
  
  

  const onSubmit = async () => {
    if (!title || !url || !description || (!activityCreated && !atividade) ) {
      !title && setTitleError("Preencha esse campo");
      !url && setUrlError("Preencha esse campo");
      !description && setDescriptionError("Preencha esse campo");
      (!activityCreated && !atividade) && setActivityCreatedError("Adicione uma atividade.");
    } else {
      const data = {
        titulo: title,
        playlistUrl: url,
        descricao: description,
      };

      if (atividade && !atividade.id) {
        data.atividade = activityCreated;
        
      } else {
        data.atividade = {
          questoes: atividade.questoes.map(questao => {
            const { id, questoes_por_atividades, ...questaoSemId } = questao;
            return questaoSemId;
          }),
        };
      }
  

      try {
        setLoading(true);
        setRequestError(null);
        await api.post("cursos/", data);
        sessionStorage.removeItem("atividade");
        sessionStorage.removeItem("title");
        sessionStorage.removeItem("url");
        sessionStorage.removeItem("description");
        sessionStorage.removeItem("questions");
        // window.location.reload();
        enqueueSnackbar("O curso foi criado com sucesso.", {
          variant: "success",
        });
        setTimeout(() => {
          close();
        }, 1000);
      } catch (error) {
        if (error.response.data.error) {
          setRequestError(error.response.data.error);
          enqueueSnackbar(error.response.data.error, {
            variant: "error",
          });
        }
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    title && setTitleError("");
    url && setUrlError("");
    description && setDescriptionError("");
    activityCreated && setDescriptionError("");
    sessionStorage.setItem("atividade", JSON.stringify(activityCreated));
  }, [title, url, description, activityCreated]);


  return (
    <ThemeProvider theme={theme1}>
      <Modal open={open} onClose={close}>
        <div
          className={classes.modalContainer}
          style={{
            height: onMobile ? "50vh" : "80vh",
          }}
        >
          <div
            className={classes.headerContainer}
            style={{
              height: onMobile ? "5vh" : "10vh",
              // display: 'flex',
            }}
          >
            <h1
              style={{
                fontWeight: "550",
                fontSize: onMobile ? "2.5vh" : "4.5vh",
                margin: 0,
              }}
            >
              Cadastrar Curso
            </h1>
            {/* <Tooltip title='Sair' onClick={close} sx={{cursor: 'pointer'}}  >
            <CancelIcon/>
          </Tooltip> */}
          </div>
          <div
            className={classes.formContainer}
            style={{
              height: onMobile ? "30vh" : "70vh",
              // width: onMobile ? "90%" : "60%",
            }}
          >
            <div className={classes.inputsContainer}>
              <TextInput
                value={title}
                label="Título"
                color="primary"
                error={titleError.length > 0}
                helperText={titleError}
                sx={{
                  marginBottom: onMobile ? theme.spacing(0.1) : 0,
                }}
                onChange={(e) => {
                  setTitle(e.target.value);
                  sessionStorage.setItem("title", e.target.value);
                }}
              />
              <TextInput
                value={url}
                label="URL do Youtube"
                color="primary"
                error={urlError.length > 0}
                helperText={urlError}
                sx={{
                  marginBottom: onMobile ? theme.spacing(0.1) : 0,
                }}
                onChange={(e) => {
                  setUrl(e.target.value);
                  sessionStorage.setItem("url", e.target.value);
                }}
              />
              <div style={{ width: "100%" }}>
                <TextInput
                  value={description}
                  multiline
                color="primary"
                  rows={onMobile ? 2 : 4}
                  label="Descrição do curso"
                  error={descriptionError.length > 0}
                  helperText={descriptionError}
                  // inputProps={{ maxLength: maxLength }}
                  onChange={(e) => {
                    const inputText = e.target.value.slice(0, 250);
                    setDescription(inputText);
                    sessionStorage.setItem("description", e.target.value);
                  }}
                />
                <p>
                  {remainingChars}/{maxLength} caracteres restantes
                </p>
                {remainingChars === 0 && (
                  <p style={{ color: "red" }}>
                    Você atingiu o limite de caracteres.
                  </p>
                )}
              </div>
            </div>
            <Grid item xs={6} sm={6} md={3} lg={3}>
              {activityCreated ? (
                <Button
                  startIcon={<AssignmentIcon />}
                  onClick={() => setOpenModalCadastroAtividade(true)}
                  variant="outlined"
                  color="primary"
                >
                  Ver atividade
                </Button>
              ) : (
                <div>
                  <Button
                    // sx={styles.button}
                    onClick={() => setOpenModalCadastroAtividade(true)}
                    variant="contained"
                    color="primary"
                  >
                    + Criar Atividade
                  </Button>
                  <h6
                    style={{
                      display:
                        activityCreatedError.length > 0 ? "flex" : "none",
                      color: "red",
                      fontWeight: "lighter",
                    }}
                  >
                    {activityCreatedError}
                  </h6>
                </div>
              )}
            </Grid>
          </div>
          <div
            style={{ display: "flex", justifyContent: "end", width: "100%" }}
          >
            <Button
              color="primary"
              variant="contained"
              endIcon={
                loading && <CircularProgress size={25} sx={{ color: "#fff" }} />
              }
              onClick={onSubmit}
            >
              Cadastrar
            </Button>
          </div>
          {/* {requestError !== null && (
          <p className={classes.requestError}>{requestError}</p>
        )} */}
          <ModalCadastroAtividade
            onOpen={openModalCadastroAtividade}
            onClose={() => {
              setOpenModalCadastroAtividade(false);
            }}
            onActivityCreated={(atividade) => setActivityCreated(atividade)}
            edit={activityCreated}
          />
        </div>
      </Modal>
    </ThemeProvider>
  );
};

import { Grid, TextField, InputLabel, Select, MenuItem, OutlinedInput, FormControl } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { MobileDatePicker } from "@mui/x-date-pickers";
import ptLocale from "date-fns/locale/pt-BR";
import React, { useEffect } from "react";
import api from "../../services/api";
import InputMask from "react-input-mask";
import { isValidCPF } from "@brazilian-utils/brazilian-utils";
import Options from "../../utils/options";

export default function PersonalDataForm() {
  // calendar mobile
  const [birthDate, setBirthDate] = React.useState(
    sessionStorage.getItem("birthDate")
      ? sessionStorage.getItem("birthDate")
      : new Date()
  );
  const [email, setEmail] = React.useState(
    sessionStorage.getItem("email") || ""
  );
  const [confirmEmail, setConfirmEmail] = React.useState("");
  const [phoneNumber, setPhoneNumber] = React.useState(
    sessionStorage.getItem("phoneNumber") || ""
  );
  const [fullName, setFullName] = React.useState(
    sessionStorage.getItem("fullName") || ""
  );
  const [socialName, setSocialName] = React.useState(
    sessionStorage.getItem("socialName") || ""
  );
  const [gender, setGender] = React.useState(
    sessionStorage.getItem("gender") || ""
  );
  const [cpf, setCPF] = React.useState(sessionStorage.getItem("cpf") || "");
  const [errors, setErrors] = React.useState({
    phoneNumber: "",
    email: "",
  });
  const [errorEmail, setErrorEmail] = React.useState(null);
  const [errorConfirmEmail, setErrorConfirmEmail] = React.useState(null);
  const [errorFullName, setErrorFullName] = React.useState(null);
  const [errorCPF, setErrorCPF] = React.useState(null);
  // snackbar
  const [messageInfo, setMessageInfo] = React.useState(undefined);
  const [state, setState] = React.useState({
    open: false,
    vertical: "top",
    horizontal: "center",
  });
  const { vertical, horizontal, open } = state;

  const handleClose = () => {
    setState({ ...state, open: false });
  };

  const handleBirthDate = (newValue) => {
    setBirthDate(newValue);
    sessionStorage.setItem("birthDate", new Date(newValue).toJSON());
  };
  
  const handleEmail = (event) => {
    const {
      target: { value },
    } = event;
    setEmail(value);
    setErrorEmail({ email: "" });
    if (value.trim().length > 0) {
      let reg = new RegExp(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      ).test(value);
      if (!reg) {
        sessionStorage.setItem("emailValido", "incorreto");
        setErrorEmail({ email: "Insira um email válido." });
      } else
        api
          .get("aluno/verify-email", {
            params: {
              email: value,
            },
          })
          .then((resp) => {
            setMessageInfo(resp.data.message);
            const novo = {
              vertical: "bottom",
              horizontal: "center",
            };
            setState({ open: true, ...novo });

            sessionStorage.setItem("email", value);
            sessionStorage.setItem("emailValido", "correto");
          })
          .catch((error) => {
            if (
              error &&
              error.response &&
              error.response.data &&
              error.response.data.error
            ) {
              sessionStorage.setItem("emailValido", "incorreto");
              setErrorEmail({ email: error.response.data.error });
            }
          });
    } else {
      setErrorEmail({ email: "Preencha esse campo.", ...phoneNumber });
    }
  };

  const handleConfirmEmail = (event) => {
    const {
      target: { value },
    } = event;
    setConfirmEmail(value);
    setErrorConfirmEmail({ email: "" });
    if (value !== email) {
      setErrorConfirmEmail({ email: "Emails diferentes" });
      sessionStorage.setItem("emailValido", "incorreto");
    } else {
      sessionStorage.setItem("emailValido", "correto");
    }
  };

  const handleFullName = (event) => {
    const {
      target: { value },
    } = event;
    setFullName(value);
    setErrorFullName({ fullName: "" });
    if (value.trim().length < 1) {
      setErrorFullName({ fullName: "Preencha esse campo." });
    } else {
      sessionStorage.setItem("fullName", value);
    }
  };

  const handleSocialName = (event) => {
    const {
      target: { value },
    } = event;
    setSocialName(value);
    sessionStorage.setItem("socialName", value);
  };

  const handleGender = (event) => {
    const {
      target: { value },
    } = event;
    setGender(value);
    sessionStorage.setItem("gender", value);
  };

  const handlePhoneNumber = (event) => {
    const {
      target: { value },
    } = event;
    setErrors({ phoneNumber: "", ...email });
    setPhoneNumber(value);

    if (value.trim().length > 0) {
      if (value.trim().length < 16)
        setErrors({ phoneNumber: "Número inválido.", ...email });
      else {
        sessionStorage.setItem("phoneNumber", value);
      }
    } else {
      setErrors({ phoneNumber: "Preencha esse campo.", ...email });
    }
    // helperHandleSetPhoneNumber(event.target.value)
  };

  const handleCPF = (event) => {
    const {
      target: { value },
    } = event;
    setCPF(value);
    setErrorCPF({ cpf: "" });
    if (value.trim().length > 0) {
      let reg = new RegExp(/^\d{3}\.\d{3}\.\d{3}-\d{2}$/).test(value);
      if (!reg || !isValidCPF(value)) {
        setErrorCPF({ cpf: "CPF inválido." });
        sessionStorage.removeItem("cpf");
      } else {
        api
          .get("aluno/verify-cpf", {
            params: {
              cpf: value,
            },
          })
          .then((resp) => {
            sessionStorage.setItem("cpfValido", "correto");
            sessionStorage.setItem("cpf", value);
          })
          .catch((error) => {
            if (
              error &&
              error.response &&
              error.response.data &&
              error.response.data.error
            ) {
              setErrorCPF({ cpf: error.response.data.error });
              sessionStorage.setItem("cpfValido", "incorreto");
            }
          });
      }
    } else {
      sessionStorage.setItem("cpfValido", "incorreto");
      setErrorCPF({ cpf: "Preencha esse campo." });
    }
  };

  return (
    <>
      <Grid container spacing={3} sx={{ my: 3 }}>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            fullWidth
            error={Boolean(errorEmail?.email)}
            helperText={errorEmail?.email}
            id="outlined-error-helper-text"
            label="Email"
            name="email"
            autoComplete="email"
            color="warning"
            value={email}
            onBlur={handleEmail}
            onChange={handleEmail}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="fullName"
            name="fullName"
            label="Nome Completo"
            fullWidth
            autoComplete="given-name"
            color="warning"
            value={fullName}
            onBlur={handleFullName}
            onChange={handleFullName}
            error={Boolean(errorFullName?.fullName)}
            helperText={errorFullName?.fullName}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            fullWidth
            error={Boolean(errorConfirmEmail?.email)}
            onPaste={(e) => e.preventDefault()}
            helperText={errorConfirmEmail?.email}
            id="outlined-error-helper-text"
            label="Repetir Email"
            onpaste=""
            name="email"
            autoComplete="email"
            color="warning"
            value={confirmEmail}
            onBlur={handleConfirmEmail}
            onChange={handleConfirmEmail}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <InputMask
            mask="(99) 9 9999 9999"
            value={phoneNumber}
            disabled={false}
            maskChar=" "
            onChange={handlePhoneNumber}
            onBlur={handlePhoneNumber}
          >
            {() => (
              <TextField
                color="warning"
                id="phoneNumber"
                name="phone"
                label="Telefone"
                autoComplete="phone"
                error={Boolean(errors?.phoneNumber)}
                helperText={errors?.phoneNumber}
                required
                fullWidth
              />
            )}
          </InputMask>
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputMask
            mask="999.999.999-99"
            value={cpf}
            disabled={false}
            maskChar=" "
            onBlur={handleCPF}
            onChange={handleCPF}
          >
            {() => (
              <TextField
                color="warning"
                id="cpf"
                name="cpf"
                label="CPF"
                autoComplete="cpf"
                error={Boolean(errorCPF?.cpf)}
                helperText={errorCPF?.cpf}
                required
                fullWidth
              />
            )}
          </InputMask>
        </Grid>
        <Grid item xs={12} sm={6}>
          <LocalizationProvider
            adapterLocale={ptLocale}
            dateAdapter={AdapterDateFns}
          >
            <MobileDatePicker
              disableFuture
              cancelText="Cancelar"
              label="Data de Nascimento"
              inputFormat="dd/MM/yyyy"
              value={birthDate}
              onChange={handleBirthDate}
              renderInput={(params) => (
                <TextField {...params} color="warning" fullWidth />
              )}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            id="socialName"
            name="SocialName"
            label="Nome Social (opcional)"
            fullWidth
            autoComplete="given-name"
            color="warning"
            value={socialName}
            onBlur={handleSocialName}
            onChange={handleSocialName}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
        <FormControl required fullWidth>
       
        <InputLabel id="demo-simple-select-label" sx={{background: '#fff', mr: 1}}>Gênero</InputLabel>
            <Select
                value={gender}
                label="Gênero"
                labelId="demo-simple-select-label"
                input={<OutlinedInput label="Gênero" />}
                onChange={handleGender}
                fullWidth
            >
                {Options.optionsGender.map((option) => (
                    <MenuItem value={option.value}>{option.value}</MenuItem>
                ))}
            </Select>
            </FormControl>
        </Grid>
      </Grid>
    </>
  );
}

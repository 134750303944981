import React, { useState, useEffect } from "react";
import {
  Modal,
  Paper,
  TextField,
  Divider,
  Grid,
  Button,
  Box,
  Typography,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import * as palette from "../../utils/styledVariables";
import { useSelector } from "react-redux";
import CardDatas from "./CardDatas";
import api from "../../services/api";
import DadosEdital from "./DadosEdital";
import DadosVagas from "./DadosVagas";
import UploadIcon from "@mui/icons-material/Upload";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { useSnackbar } from "notistack";
import prettyBytes from "pretty-bytes";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import ptLocale from "date-fns/locale/pt-BR";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { margin } from "polished";

const styles = {
  modal: {
    width: "50%",
    top: "10%",
    position: "absolute",
    left: "50%",
    marginTop: "60vh",
    transform: "translate(-50%, -50%)",
    bgcolor: "#f2f2f2",
    padding: "10px",
    textAlign: "center",
    borderRadius: "1vh",
    justifyContent: "center",
    overflow: "auto",
  },

  uploadButton: {
    height: "40px",
    color: palette.roxoMedio,
  },

  paper: {
    width: "100%",
    margin: "5px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  field: {
    paddingTop: "12px",
    paddingRight: "12px",
    paddingBottom: "12px",
    paddingLeft: "12px",
  },
};

function ModalCadastroEdital(props) {
  const { enqueueSnackbar } = useSnackbar();

  const [title, setTitle] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const [empresaStart, setEmpresaStart] = useState(null);
  const [empresaEnd, setEmpresaEnd] = useState(null);

  const [alunoStart, setAlunoStart] = useState(null);
  const [alunoEnd, setAlunoEnd] = useState(null);

  const [vagasTotal, setVagasTotal] = useState(null);
  const [vagasOferta, setVagasOferta] = useState(null);
  const [vagasPreenchidas, setVagasPreenchidas] = useState(null);

  const [file, setFile] = React.useState(null);
  const [fileId, setFileId] = React.useState(null);

  const defaultButtonText = "Anexar PDF do edital";
  const [buttonText, setButtonText] = React.useState(defaultButtonText);

  const [cicloStart, setCicloStart] = useState(null);
  const [cicloEnd, setCicloEnd] = useState(null);
  const [numeroCiclo, setNumeroCiclo] = useState(null);
  const today = new Date();
  const [edital, setEdital] = useState([]);

  const handleNumeroCiclo = (e) => {
    setNumeroCiclo(e.target.value);
  };

  useEffect(() => {
    setVagasOferta(0);
    setVagasPreenchidas(0);
    setVagasTotal(0);
  }, []);

  const handleClose = () => {
    props.close();
  };

  const handleTitle = (e) => {
    const {
      target: { value },
    } = e;

    setTitle(value);
  };

  const handleStartDate = (value) => {
    setStartDate(value);
  };

  const handleEndDate = (value) => {
    setEndDate(value);
  };

  const handleCicloStart = (value) => {
    setCicloStart(value);
  };

  const handleCicloEnd = (value) => {
    setCicloEnd(value);
  };
  const handleEmpresaStart = (value) => {
    setEmpresaStart(value);
  };

  const handleEmpresaEnd = (value) => {
    setEmpresaEnd(value);
  };

  const handleAlunoStart = (value) => {
    setAlunoStart(value);
  };

  const handleEdital = (e) => {
    setEdital(e.target.value);
  };

  function handleRemoveFile() {
    setButtonText(defaultButtonText);
    setFile(null);
  }

  function handleFile(event) {
    const filesize_max = 10 * 1000 * 1000;
    if (
      event.target.files[0] &&
      event.target.files[0].size > prettyBytes(filesize_max)
    ) {
      setFile(null);
      // enqueueSnackbar('O pdf é muito grande ( ' + prettyBytes(filesize_max) + ' )', {variant: 'error'})
    } else {
      if (event.target.files[0]) {
        setFile(event.target.files[0]);
        setButtonText(
          event.target.files[0].name +
            " - (" +
            prettyBytes(event.target.files[0].size) +
            " / " +
            prettyBytes(filesize_max) +
            ")"
        );
      } else {
        setFile(null);
        setButtonText(defaultButtonText);
      }
    }
  }

  function uploadDoc() {
    const formData = new FormData();
    formData.append("file", file);

    api
      .post(`oxetechwork/edital/docs`, formData)
      .then((response) => {
        if (response.data && response.data.data && response.data.data.uuid) {
          setFileId(response.data.data.uuid);
        }
      })
      .catch((err) => {});
  }

  const handleAlunoEnd = (value) => {
    setAlunoEnd(value);
  };

  const handleTotal = (e) => {
    const {
      target: { value },
    } = e;
    if (value > -1 || value == "") {
      setVagasTotal(value);
    }
  };

  const handleOferta = (e) => {
    const {
      target: { value },
    } = e;

    if (value > -1 || value == "") {
      setVagasOferta(value);
    }
  };

  const handlePreenchidas = (e) => {
    const {
      target: { value },
    } = e;

    if (value > -1 || value == "") {
      setVagasPreenchidas(value);
    }
  };

  const handleFinish = () => {
    const formData = new FormData();
    formData.append("file", file);

    api
      .post(`oxetechwork/edital/docs`, formData)
      .then((response) => {
        if (response.data && response.data.data && response.data.data.uuid) {
          setFileId(response.data.data.uuid);
          try {
            const data = {
              title: title,
              qt_vagas: vagasTotal,
              qt_vagas_em_oferta: vagasOferta,
              qt_vagas_preenchidas: vagasPreenchidas,
              dt_inicio_edital: startDate,
              dt_encerramento_edital: endDate,
              dt_inicio_inscricao_empresa: empresaStart,
              dt_fim_inscricao_empresa: empresaEnd,
              dt_inicio_inscricao_aluno: alunoStart,
              dt_fim_inscricao_aluno: alunoEnd,
              ultimo_ciclo: numeroCiclo,
              file_id: response.data.data.uuid,
              status: true,
              ciclo: {
                num_ciclo: numeroCiclo,
                start: cicloStart,
                end: cicloEnd,
              },
            };

            api.post(`/oxetechwork/edital`, data);
            enqueueSnackbar("Edital cadastrado com sucesso!", {
              variant: "success",
            });
            setTimeout(() => {
              handleClose();
            }, 1000);
          } catch (err) {
            console.log(err);
          }
        }
      })
      .catch((err) => {
        alert("Falha ao carregar pdf");
      });
  };

  return (
    <Modal open={props.open} sx={{ overflow: "auto" }}>
      <Box>
        <IconButton
          size="large"
          sx={{ display: "flex", float: "right" }}
          component="label"
          onClick={handleClose}
        >
          <CloseIcon
            fontSize="large"
            sx={{ color: palette.profileWhiteShade }}
          />
        </IconButton>

        <Grid sx={styles.modal} container direction="row">
          <Grid container item xs={12} sm={12} md={12} lg={12}>
            <Paper
              sx={[
                {
                  backgroundColor: palette.laranjaOXLTransparente,
                  height: "50px",
                },
                styles.paper,
              ]}
            >
              <Typography
                sx={{
                  fontSize: "24px",
                  color: palette.preto,
                  fontFamily: "Poppins",
                  fontWeight: 500,
                }}
              >
                Cadastro de Edital
              </Typography>
            </Paper>
          </Grid>

          <DadosEdital
            title={"Dados gerais"}
            editalTitle={title}
            startDate={startDate}
            minDate={new Date()}
            endDate={endDate}
            handleStart={handleStartDate}
            handleEnd={handleEndDate}
            handleTitle={handleTitle}
          />

          <CardDatas
            title={"Empresas"}
            startDate={empresaStart}
            minDate={new Date()}
            endDate={empresaEnd}
            handleStart={handleEmpresaStart}
            handleEnd={handleEmpresaEnd}
          />
          <CardDatas
            title={"Candidatos"}
            startDate={alunoStart}
            minDate={new Date()}
            endDate={alunoEnd}
            handleStart={handleAlunoStart}
            handleEnd={handleAlunoEnd}
          />

          <DadosVagas
            title={"Vagas"}
            total={vagasTotal}
            oferta={vagasOferta}
            preenchidas={vagasPreenchidas}
            handleTotal={handleTotal}
            handleOferta={handleOferta}
            handlePreenchidas={handlePreenchidas}
          />

          <Grid item container xs={12} sm={12} md={6} lg={6}>
            <Paper sx={styles.paper}>
              {/* <Button variant="outlined" sx={styles.uploadButton} startIcon={<UploadIcon />}>Adicionar documentação</Button> */}
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <input
                  accept="application/pdf"
                  style={{ display: "none" }}
                  id="raised-button-file"
                  type="file"
                  onChange={handleFile}
                />
                <label htmlFor="raised-button-file">
                  <Button
                    disabled={buttonText !== defaultButtonText ? true : false}
                    sx={{ m: 2, py: 2 }}
                    variant="outlined"
                    component="span"
                  >
                    <UploadIcon sx={{ mr: 1 }} />
                    {buttonText}
                  </Button>
                </label>

                <Button
                  sx={{
                    py: 2,
                    display: buttonText === defaultButtonText ? "none" : "flex",
                  }}
                  variant="text"
                  onClick={handleRemoveFile}
                >
                  <DeleteIcon
                    sx={{
                      color: "red",
                      display:
                        buttonText === defaultButtonText ? "none" : "flex",
                    }}
                  />
                </Button>
              </Box>
            </Paper>
          </Grid>
          <Grid sx={styles.test} container direction="row">
            <Paper sx={styles.paper}>
              <Grid container item xs={12} sm={12} md={12} lg={12}>
                <Grid item xs={12} sm={12} md={12} lg={12} sx={styles.field}>
                  {"Número do ciclo"}
                  <TextField
                    hiddenLabel
                    id="nome-edital"
                    variant="filled"
                    fullWidth
                    value={numeroCiclo}
                    onChange={handleNumeroCiclo}
                    onBlur={handleNumeroCiclo}
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={6} lg={6} sx={styles.field}>
                  {"Data de abertura"}
                  <LocalizationProvider
                    locale={ptLocale}
                    dateAdapter={AdapterDateFns}
                  >
                    <DatePicker
                      value={cicloStart}
                      onChange={handleCicloStart}
                      renderInput={(params) => (
                        <TextField
                          variant="filled"
                          fullWidth
                          {...params}
                          sx={styles.field}
                        />
                      )}
                      minDate={today}
                      inputFormat="dd/MM/yyyy"
                    />
                  </LocalizationProvider>
                </Grid>

                <Grid item xs={12} sm={12} md={6} lg={6} sx={styles.field}>
                  {"Data de encerramento"}
                  <LocalizationProvider
                    locale={ptLocale}
                    dateAdapter={AdapterDateFns}
                  >
                    <DatePicker
                      value={cicloEnd}
                      onChange={handleCicloEnd}
                      renderInput={(params) => (
                        <TextField
                          variant="filled"
                          fullWidth
                          {...params}
                          sx={styles.field}
                        />
                      )}
                      minDate={startDate}
                      inputFormat="dd/MM/yyyy"
                    />
                  </LocalizationProvider>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid>
            <Button
              onClick={handleFinish}
              variant="contained"
              sx={{
                height: "40px",
                bgcolor: palette.laranjaSecundario,
                "&:hover": {
                  bgcolor: palette.laranjaIntermediario2,
                },
                marginTop: "5px",
              }}
            >
              Cadastrar edital
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
}

export default ModalCadastroEdital;

import React from 'react'
import {Button} from '@mui/material';



const styles =
{
    botao:{
        background: '#741F8A',
        '&:hover': {
            background:"rgba(116, 31, 138, 0.6)"
        } 
    }
}


export default function SessionRescisao (props) {
    const {selectedCandidates,candidates,titulo} = props


    const selecionados = []
    for (const candidate of selectedCandidates) {
        selecionados.push(candidates[candidate])
    }
    return (
        <>
            <h2>Confirmação</h2>
            <h4 style={{color: "rgba(0, 0, 0, 0.62)", fontWeight:'1px'}} >Você está contratando este(s) candidato(s) para a vaga : "<span style={{color: "#741F8A"}} >{titulo}</span>"</h4>
            <ul style={{marginLeft: '5%',marginTop: '2%'}}>
                {selecionados.map((item, i) => {
                    return <li key={i}><h5>{item.Aluno.nome_social ? item.Aluno.nome_social : item.Aluno.name}</h5></li>
                })}
            </ul>
            <p style={{color: "red", fontWeight:'5px'}} >ATENÇÃO: a seleção de contratados poderá ser feita apenas uma vez. Ao clicar em contratar, a solicitação de contratações será confirmada e a vaga encerrada. Não será possível efetuar contratações posteriores.</p>
        </>
    )
}

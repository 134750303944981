import React, { useState } from "react";

import {
  Button,
} from '@mui/material';

import {
  useStyles,
} from './styles';

import { ModalCriarAtividade } from "./ModalCriarAtividade";
import { CardAtividadeAluno } from "./CardAtividadeAluno";
import { CardAtividadeProfessor } from "./CardAtividadeProfessor"

export const AtividadesDaTurma = (props) => {
  const professor = props.professor;

  const classes = useStyles();

  const [openModalCriar, setOpenModalCriar] = useState(false);
  const handleOpenModal = () => setOpenModalCriar(true);
  const handleCloseModal = () => setOpenModalCriar(false);

  const testes = [
    { entregues: 21, pendentes: 9, id: 1, titulo: 'Exercícios de CSS', concluida: false, descricao: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi volutpat nisi a leo sodales rhoncus. Nunc et interdum justo, a euismod lacus. Phasellus ipsum erat, interdum at odio quis, ullamcorper tempor dolor. Curabitur ullamcorper quam ac varius ultricies. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Phasellus nec massa ac orci auctor fringilla in eu purus. Proin ornare erat sit amet quam faucibus, sed convallis mauris semper. Suspendisse scelerisque ligula vulputate efficitur faucibus. Fusce aliquet, mi pretium interdum congue, tellus sapien aliquam nisi, id facilisis eros dolor sed elit. In vehicula neque iaculis libero porttitor posuere. Vestibulum molestie arcu faucibus turpis lacinia, ac porta velit sollicitudin. Aenean a magna tempus, rutrum risus at, tincidunt lacus. Suspendisse vehicula nibh est, eu tempor metus vehicula vitae. Cras pulvinar hendrerit lorem, a molestie risus. Cras volutpat laoreet risus, sit amet consequat risus auctor in.' },
    { entregues: 21, pendentes: 9, id: 2, titulo: 'Lorem ipsum dolor sit amet', concluida: false, descricao: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi volutpat nisi a leo sodales rhoncus. Nunc et interdum justo, a euismod lacus. Phasellus ipsum erat, interdum at odio quis, ullamcorper tempor dolor. Curabitur ullamcorper quam ac varius ultricies. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Phasellus nec massa ac orci auctor fringilla in eu purus. Proin ornare erat sit amet quam faucibus, sed convallis mauris semper. Suspendisse scelerisque ligula vulputate efficitur faucibus. Fusce aliquet, mi pretium interdum congue, tellus sapien aliquam nisi, id facilisis eros dolor sed elit. In vehicula neque iaculis libero porttitor posuere. Vestibulum molestie arcu faucibus turpis lacinia, ac porta velit sollicitudin. Aenean a magna tempus, rutrum risus at, tincidunt lacus. Suspendisse vehicula nibh est, eu tempor metus vehicula vitae. Cras pulvinar hendrerit lorem, a molestie risus. Cras volutpat laoreet risus, sit amet consequat risus auctor in.' },
    { entregues: 21, pendentes: 9, id: 3, titulo: 'Exercícios de CSS', concluida: true, descricao: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi volutpat nisi a leo sodales rhoncus. Nunc et interdum justo, a euismod lacus. Phasellus ipsum erat, interdum at odio quis, ullamcorper tempor dolor. Curabitur ullamcorper quam ac varius ultricies. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Phasellus nec massa ac orci auctor fringilla in eu purus. Proin ornare erat sit amet quam faucibus, sed convallis mauris semper. Suspendisse scelerisque ligula vulputate efficitur faucibus. Fusce aliquet, mi pretium interdum congue, tellus sapien aliquam nisi, id facilisis eros dolor sed elit. In vehicula neque iaculis libero porttitor posuere. Vestibulum molestie arcu faucibus turpis lacinia, ac porta velit sollicitudin. Aenean a magna tempus, rutrum risus at, tincidunt lacus. Suspendisse vehicula nibh est, eu tempor metus vehicula vitae. Cras pulvinar hendrerit lorem, a molestie risus. Cras volutpat laoreet risus, sit amet consequat risus auctor in.' },
    { entregues: 21, pendentes: 9, id: 4, titulo: 'Construa um cabeçalho com HTML', concluida: false, descricao: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi volutpat nisi a leo sodales rhoncus. Nunc et interdum justo, a euismod lacus. Phasellus ipsum erat, interdum at odio quis, ullamcorper tempor dolor. Curabitur ullamcorper quam ac varius ultricies. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Phasellus nec massa ac orci auctor fringilla in eu purus. Proin ornare erat sit amet quam faucibus, sed convallis mauris semper. Suspendisse scelerisque ligula vulputate efficitur faucibus. Fusce aliquet, mi pretium interdum congue, tellus sapien aliquam nisi, id facilisis eros dolor sed elit. In vehicula neque iaculis libero porttitor posuere. Vestibulum molestie arcu faucibus turpis lacinia, ac porta velit sollicitudin. Aenean a magna tempus, rutrum risus at, tincidunt lacus. Suspendisse vehicula nibh est, eu tempor metus vehicula vitae. Cras pulvinar hendrerit lorem, a molestie risus. Cras volutpat laoreet risus, sit amet consequat risus auctor in.' },
  ];

  const [atividades, setAtividades] = useState(testes);
  const addAtividade = (atividade) => setAtividades([...atividades, atividade]);
  

  if (professor) {
    return (
      <>
        <div style={{padding: '2rem', display: 'flex', flexDirection: 'column', margin: '1rem'}}>
          <div>
            <Button 
              variant='contained'
              className={classes.createButton} 
              onClick={handleOpenModal} 

            >
            + Criar
          </Button>
          </div>
          {atividades.map((teste, i) => <CardAtividadeProfessor atividade={teste} key={i} />)}
        </div>
        <ModalCriarAtividade 
          open={openModalCriar}
          close={handleCloseModal}
          atividades={atividades}
          addAtividade={addAtividade}
        />
      </>
    );
  }

  return (
    <div style={{padding: '2rem'}}>
      {atividades.map((teste, i) => <CardAtividadeAluno atividade={teste} key={i} />)}
    </div>
  )
};
import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from "@mui/material/Typography";
import Slide from '@mui/material/Slide';
import {Container,Textos,Figura,GridItem, GridBox} from './styles'

import bolsa from '../../../assets/iconesOxeWork/bolsa.svg'
import qualificacao from '../../../assets/iconesOxeWork/qualificacao.svg'
import portfolio from '../../../assets/iconesOxeWork/portfolio.svg'
import iconeOxetechWork from '../../../../src/assets/iconeOxetechWork.png'


const icones = [
    {icon: portfolio, alt: 'Portfólio com cursos',  discription: 'Portfólio com cursos mais específicos.'},
    {icon: qualificacao, alt: 'Qualificação intensiva',  discription: 'Qualificação intensiva com Universidades, ICTs e empresas locais.'},
    {icon: bolsa, alt: 'Bolsa de estudos',discription: 'Bolsa de estudos de 6 meses + contrato de trabalho de 12 meses.'},
]
export default function Introducao(){

    return(
        <Container>
            <Textos>
                <Typography variant='h2' className='titulo'>
                    Encontre e oferte oportunidades de trabalho!
                </Typography>
                <Typography variant='h5' className='subtitulo'>
                    O OxeTech Work é um programa que integra - de maneira direta -  a capacitação de alunos com vagas de trabalho no mercado tecnológico e inovador, transferindo renda, gerando empregos e qualificando a população. 
                </Typography>
                <GridBox container spacing={1} >
                    {icones.map((ic) =>{
                        return <GridItem item xs={4} md={4} lg={4}>
                            <img className='img' src={ic.icon} alt={ic.alt}/>
                            <h6 className='desc'>{ic.discription}</h6>
                        </GridItem>
                    } )}
                </GridBox>

            </Textos>
            <Figura><img src={iconeOxetechWork} alt='Icone' width="60%" /></Figura>

        </Container>
    )


}

import React, {useState,useEffect} from 'react';
import Modal from '@mui/material/Modal';
import {Grid, Button, Tooltip, IconButton} from '@mui/material'
import TextField from '@mui/material/TextField';
import close from '../../../../../assets/iconesOxeLab/close.svg'
import {Content} from './styles'
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import ptLocale from "date-fns/locale/pt-BR";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { addDays,subDays,parseISO } from "date-fns";
import Autocomplete from '@mui/material/Autocomplete';
import api from '../../../../../services/api';
import { useSelector } from "react-redux";
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';
import InfoIcon from '@mui/icons-material/Info';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { v4 as uuidv4 } from 'uuid';


const theme = createTheme({
 
    palette: {
      primary: {
        main: 'rgba(212, 109, 13, 0.5)',
        darker: 'rgba(212, 109, 13, 1)',
      },
      secondary: {
        main: 'rgba(212, 109, 13, 1)',
        darker: 'rgba(212, 109, 13, 1)',
      },
    
    },
  });
export default function ModalCadastro(props) {
    const { labId } = useParams();
    const labs = useSelector(state => state.user.labs_profile);
    const lab = labs.find((lab) => lab.lab_id == labId);
    const perguntasSalvas = sessionStorage.getItem('perguntas') || [];
    const [data, setData] = useState({
        titulo: sessionStorage.getItem('titulo') || '' , 
        descricao: sessionStorage.getItem('descricao') || '', 
        instrutores: [],
        qnt_Vagas: sessionStorage.getItem('quant_vagas') || null, 
        data_inicio:sessionStorage.getItem('data_inicio_curso') || '', 
        quant_aulas: sessionStorage.getItem('quant_aulas') || null,
        data_encerramento: sessionStorage.getItem('data_fim_curso') || '',
        data_resultado: sessionStorage.getItem('data_resultado') || '',
        data_encerramento_insc: sessionStorage.getItem('data_fim_insc') || '', 
        informacao_adicional: sessionStorage.getItem('info') || '', 
        carga_horaria: sessionStorage.getItem('carga_horaria') || null, 
        perguntas: [],})
    const [open, setOpen] = useState(true)
    const [errorTitulo, setErrorTitulo] = useState("")
    const [errorDescricao, setErrorDescricao] = useState("")
    const [errorInformacaoAdicional, setErrorInformacaoAdicional] = useState("")
    const [errorCargaHoraria, setErrorCargaHoraria] = useState("")
    const [errorInstrutores, setErrorInstrutores] = useState("")
    const [errorQnt_Vagas, setErrorQnt_Vagas] = useState("")
    const [errorData_inicio, setErrorData_inicio] = useState("")
    const [errorData_resultado, setErrorData_resultado] = useState("")
    const [errorData_encerramento, setErrorData_encerramento] = useState("")
    const [errorData_encerramento_insc, setErrorData_encerramento_insc] = useState("")
    const [errorTotalAulas, setErrorTotalAulas] = useState("")
    const [errorPergunta, setErrorPergunta] = useState("")
    const maxLength = 50;
    const today = new Date();
    const [options, setOptions] = useState([]);
    const [alertSuccessful, setAlertSuccessful] = useState('');
    const [alertUnauthorized, setAlertUnauthorized] = useState('');
    const [loading, setLoading] = useState(false);
    const [perguntas, setPerguntas] = useState([{ id: uuidv4(), texto: '' }]);

    

    const handleCheckError = () => {
      if (
        !data.titulo ||
        !data.descricao ||
        !data.carga_horaria ||
        data.instrutores.length === 0 ||
        !data.qnt_Vagas ||
        !data.data_inicio ||
        !data.data_resultado ||
        !data.data_encerramento ||
        !data.data_encerramento_insc ||
        !data.quant_aulas ||
        data.perguntas.length === 0
      ) {
        !data.titulo && setErrorTitulo("Você não informou o nome da turma!");
        !data.descricao && setErrorDescricao("Você não preencheu a descrição!");
        !data.informacao_adicional && setErrorInformacaoAdicional("Você não preencheu esse campo!");
        !data.carga_horaria && setErrorCargaHoraria("Você não informou a carga horária");
        data.instrutores.length === 0 && setErrorInstrutores("Você não informou os instrutores");
        !data.qnt_Vagas && setErrorQnt_Vagas("Você não informou a quantidade de vagas");
        !data.data_inicio && setErrorData_inicio("Você não informou a data de início do curso");
        !data.data_resultado && setErrorData_resultado("Você não informou a data do resultado da seleção");
        !data.data_encerramento && setErrorData_encerramento("Você não informou a data de encerramento do curso");
        !data.data_encerramento_insc && setErrorData_encerramento_insc("Você não informou a data de encerramento das inscrições");
        !data.quant_aulas && setErrorTotalAulas("Você não informou o total de aulas!");
        data.perguntas.length === 0 && setErrorPergunta("Você não informou nenhuma pergunta!");
      } else {
        handleSubmit();
      }
    };
    
    const handleSubmit = () => {
      const data_form = {
        titulo: data.titulo,
        descricao: data.descricao,
        info_adicionais: data.informacao_adicional,
        id_list: data.instrutores.map((ins) => ins.aluno_id),
        data_inicio: data.data_inicio,
        data_encerramento: data.data_encerramento,
        data_encerramento_inscricoes: data.data_encerramento_insc,
        data_resultado: data.data_resultado,
        qtd_vagas_total: data.qnt_Vagas,
        qtd_aulas: data.quant_aulas,
        carga_horaria: data.carga_horaria,
        perguntas: data.perguntas.map((pergunta) => pergunta.texto),
        laboratorio_id: lab.lab_id,
      };
      setLoading(true);
      api
        .post("turma/", data_form)
        .then((response) => {
          setLoading(false);
          setAlertSuccessful(response.data.message);
          sessionStorage.removeItem("titulo");
          sessionStorage.removeItem("descricao");
          sessionStorage.removeItem("quant_vagas");
          sessionStorage.removeItem("data_inicio_curso");
          sessionStorage.removeItem("quant_aulas");
          sessionStorage.removeItem("data_fim_curso");
          sessionStorage.removeItem("data_fim_insc");
          sessionStorage.removeItem("info");
          sessionStorage.removeItem("carga_horaria");
          sessionStorage.removeItem("pergunta");
    
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        })
        .catch((err) => {
          setLoading(false);
          setAlertUnauthorized(err.response.data.error);
        });
    };
    
    

    useEffect(() => {
        api.get(`/agente/agentes-lab?labId=${lab.lab_id}`).then((response) => {
            setOptions(response.data)
        }).catch((error) => {
            console.log(error)
        })
    }, [])
    

    const handleTituloChange = (e) => {
        const { value } = e.target;
        setData((prevData) => ({ ...prevData, titulo: value }));
        setErrorTitulo("");
        sessionStorage.setItem("titulo", value);
        
        if (value.length === 0) {
          setErrorTitulo("Preencha este campo.");
        }
      };

      const handleDescricaoChange = (e) => {
        const { value } = e.target;
        setData((prevData) => ({ ...prevData, descricao: value }));
        setErrorDescricao("");
        sessionStorage.setItem("descricao", value);
        
        if (value.length === 0) {
          setErrorDescricao("Preencha este campo.");
        }
      };
      
      const handleInformacaoAdicionalChange = (e) => {
        const { value } = e.target;
        setData((prevData) => ({ ...prevData, informacao_adicional: value }));
        setErrorInformacaoAdicional("");
        sessionStorage.setItem("info", value);
      
        if (value.length === 0) {
          setErrorInformacaoAdicional("Preencha este campo.");
        }
      };
      
      const handleInstrutoresChange = (event, newValue) => {
        setData((prevData) => ({ ...prevData, instrutores: newValue }));
        setErrorInstrutores("");
      };
      const handleCargaHorariaChange = (e) => {
        const { value } = e.target;
        setData((prevData) => ({ ...prevData, carga_horaria: value }));
        setErrorCargaHoraria("");
        sessionStorage.setItem("carga_horaria", value);
      
        if (value.length === 0) {
          setErrorCargaHoraria("Preencha este campo.");
        } else if (value <= 0) {
          setErrorCargaHoraria("A carga horária deve ser maior que zero.");
        }
      };
      
      const handleQuantAulasChange = (e) => {
        const { value } = e.target;
        setData((prevData) => ({ ...prevData, quant_aulas: value }));
        setErrorTotalAulas("");
        sessionStorage.setItem("quant_aulas", value);
      
        if (value.length === 0) {
          setErrorTotalAulas("Preencha este campo.");
        } else if (value <= 0) {
          setErrorTotalAulas("O número de aulas deve ser maior que zero.");
        }
      };
      const handleDataInicioChange = (date) => {
        setData((prevData) => ({ ...prevData, data_inicio : new Date(date).toJSON() }));
        setErrorData_inicio("");
        sessionStorage.setItem("data_inicio_curso", new Date(date).toJSON());
      };
      const handleDataResultadoChange = (date) => {
        setData((prevData) => ({ ...prevData, data_resultado : new Date(date).toJSON() }));
        setErrorData_resultado("");
        sessionStorage.setItem("data_resultado", new Date(date).toJSON());
      };
      const handleDataEncerramentoChange = (date) => {
        setData((prevData) => ({ ...prevData, data_encerramento: new Date(date).toJSON() }));
        sessionStorage.setItem("data_fim_curso", new Date(date).toJSON());
        setErrorData_encerramento("");
      
        if (!date) {
            setErrorData_encerramento("Preencha este campo.");
        }
      };
      const handleQuantVagasChange = (e) => {
        const { value } = e.target;
        setData((prevData) => ({ ...prevData, qnt_Vagas: value }));
        setErrorQnt_Vagas("");
        sessionStorage.setItem("quant_vagas", value);
        
        if (value.length === 0) {
          setErrorQnt_Vagas("Preencha este campo.");
        }else if (value <= 0) {
            setErrorQnt_Vagas("A quantidade de vagas deve ser maior que zero.");
          }
      };
      
      const handleDataEncerramentoInscChange = (e) => {
        setData((prevData) => ({ ...prevData, data_encerramento_insc: new Date(e).toJSON() }));
        setErrorData_encerramento_insc("");
        sessionStorage.setItem("data_fim_insc", new Date(e).toJSON());
      
        if (e.length === 0) {
            setErrorData_encerramento_insc("Preencha este campo.");
        }
      };
      
      const handlePerguntaChange = (id, e) => {
        const { value } = e.target;
        const updatedPerguntas = perguntas.map((pergunta) =>
          pergunta.id === id ? { ...pergunta, texto: value } : pergunta
        );
        
        setPerguntas(updatedPerguntas);
        setData((prevData) => ({ ...prevData, perguntas: updatedPerguntas }));
        setErrorPergunta("");
      
        const perguntasTextos = updatedPerguntas.map((pergunta) => pergunta.texto);
        sessionStorage.setItem("perguntas", JSON.stringify(perguntasTextos));
      };
      
      
      const handleAdicionarNovaPergunta = () => {
        const novaPergunta = {
          id: uuidv4(),
          texto: '',
        };
        setPerguntas([...perguntas, novaPergunta]);
      };
      
      
      
  return (
      <Modal
        open={props.open}
        onClose={props.onHandleClose}
        sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: "15%"}}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Content>
        <ThemeProvider theme={theme}>

            <div className="header">
                <h1>Nova Turma</h1>
                <img className='close' src={close}  alt="Fechar" onClick={props.onHandleClose}/>
            </div>
            <div style={{ padding: '2%'}}>
                <div className='area'>
                    <h2 className='titulo'>Sobre o Curso</h2>
                    <Grid sx={{p: "2% 1%"}} container spacing={2}>
                    <Grid item xs={12} md={12}>
                            <h3 className='label'>Nome da Turma</h3>
                            <TextField
                            sx={{
                                bgcolor: errorTitulo ? "transparent" : "rgba(212, 109, 13, 0.1)",
                            }}
                            placeholder="Digite o nome da turma"
                            focused
                            error={Boolean(errorTitulo)}
                            helperText={errorTitulo}
                            value={data.titulo}
                            fullWidth
                            onChange={handleTituloChange}
                            />
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <h3 className='label'>Descrição</h3>
                            <TextField
                            multiline
                            rows={3}
                            placeholder="Descreva informações sobre o curso"
                            focused
                            sx={{
                                bgcolor: errorDescricao ? "transparent" : "rgba(212, 109, 13, 0.1)",
                            }}
                            error={Boolean(errorDescricao)}
                            helperText={errorDescricao}
                            value={data.descricao}
                            fullWidth
                            onChange={handleDescricaoChange}
                            />
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <h3 className='label'>Informações adicionais</h3>
                            <TextField
                            multiline
                            rows={2}
                            placeholder="Informe os dias de curso e horários..."
                            focused
                            sx={{
                                bgcolor: errorInformacaoAdicional
                                ? "transparent"
                                : "rgba(212, 109, 13, 0.1)",
                            }}
                            error={Boolean(errorInformacaoAdicional)}
                            helperText={errorInformacaoAdicional}
                            value={data.informacao_adicional}
                            fullWidth
                            onChange={handleInformacaoAdicionalChange}
                            />
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <h3 className='label'>Instrutores</h3>
                            <Autocomplete
                            multiple
                            value={data.instrutores}
                            onChange={handleInstrutoresChange}
                            options={options}
                            getOptionLabel={(option) => option.Aluno.name}
                            renderInput={(params) => (
                                <TextField
                                {...params}
                                label=""
                                focused
                                sx={{
                                    bgcolor: errorInstrutores ? "transparent" : "rgba(212, 109, 13, 0.1)",
                                }}
                                error={Boolean(errorInstrutores)}
                                helperText={errorInstrutores}
                                variant="outlined"
                                />
                            )}
                            />

                        </Grid>
                        <Grid item xs={12} md={3}>
                            <h3 className='label'>Carga Horária</h3>
                            <TextField 
                            focused
                            value={data.carga_horaria}
                            type="number"
                            sx={{
                                bgcolor: errorCargaHoraria ? "transparent" : "rgba(212, 109, 13, 0.1)",
                            }}
                            error={Boolean(errorCargaHoraria)}
                            helperText={errorCargaHoraria}
                            size="large"
                            fullWidth
                            onChange={handleCargaHorariaChange}
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <h3 className='label'>Total de aulas</h3>
                            <TextField
                            focused
                            value={data.quant_aulas}
                            type="number"
                            sx={{
                                bgcolor: errorTotalAulas ? "transparent" : "rgba(212, 109, 13, 0.1)",
                            }}
                            error={Boolean(errorTotalAulas)}
                            helperText={errorTotalAulas}
                            size="large"
                            fullWidth
                            onChange={handleQuantAulasChange}
                            />
                        </Grid>
                        
                        <Grid item xs={12} md={3}>
                            <h3 className='label'>Data de início do curso</h3>
                            <LocalizationProvider locale={ptLocale} dateAdapter={AdapterDateFns}>
                            <DatePicker
                                value={new Date(data.data_inicio)}
                                onChange={handleDataInicioChange}
                                renderInput={(params) => (
                                <TextField
                                    size="large"
                                    sx={{
                                    bgcolor: errorData_inicio ? "transparent" : "rgba(212, 109, 13, 0.1)",
                                    }}
                                    focused
                                    fullWidth
                                    {...params}
                                    error={Boolean(errorData_inicio)}
                                    helperText={errorData_inicio}
                                />
                                )}
                                minDate={today}
                                inputFormat="dd/MM/yyyy"
                            />
                            </LocalizationProvider>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <h3 className='label'>Data de encerramento do curso</h3>
                            <LocalizationProvider locale={ptLocale} dateAdapter={AdapterDateFns}>
                            <DatePicker
                                value={data.data_encerramento}
                                onChange={handleDataEncerramentoChange}
                                renderInput={(params) => (
                                <TextField
                                    size="large"
                                    sx={{
                                    bgcolor: errorData_encerramento ? "transparent" : "rgba(212, 109, 13, 0.1)",
                                    }}
                                    focused
                                    fullWidth
                                    {...params}
                                    error={Boolean(errorData_encerramento)}
                                    helperText={errorData_encerramento}
                                    />
                                    )}
                                    minDate={data.data_inicio}
                                inputFormat="dd/MM/yyyy"
                            />
                            </LocalizationProvider>

                        </Grid>
                    </Grid>
                </div>
                <div className='area' >
                    <h2 className='titulo'>Sobre as Inscrições</h2>
                    <Grid sx={{p: "2% 1%"}} container spacing={2}>
                        <Grid item xs={12} md={4}>
                            <h3 className='label'>Quantidade de Vagas</h3>
                            <TextField 
                            type="number"
                            focused
                            sx={{bgcolor: errorQnt_Vagas ? "transparent" : "rgba(212, 109, 13, 0.1)"}}
                            error={Boolean(errorQnt_Vagas)}
                            helperText={errorQnt_Vagas}
                            value={data.qnt_Vagas}
                            size="large"
                            fullWidth
                            onChange={handleQuantVagasChange}
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <h3 className='label'>Data de encerramento das inscrições</h3>
                            <LocalizationProvider locale={ptLocale} dateAdapter={AdapterDateFns}>
                            <DatePicker
                                value={data.data_encerramento_insc}
                                onChange={handleDataEncerramentoInscChange}
                                renderInput={(params) => (
                                <TextField
                                    size="large"
                                    sx={{
                                    bgcolor: errorData_encerramento_insc
                                        ? "transparent"
                                        : "rgba(212, 109, 13, 0.1)",
                                    }}
                                    focused
                                    fullWidth
                                    {...params}
                                    error={Boolean(errorData_encerramento_insc)}
                                    helperText={errorData_encerramento_insc}
                                />
                                )}
                                minDate={today}
                                maxDate={subDays(parseISO(data.data_inicio),2)}
                                inputFormat="dd/MM/yyyy"
                            />
                            </LocalizationProvider>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <div style={{display:'flex', alignItems: 'center'}} >
                            <h3 className='label'>Data do resultado da seleção</h3>
                            <Tooltip  title='A data do resultado da seleção deve estar entre o fim das inscrições e o início das aulas' style={{marginBottom: '2%'}}>
                              <InfoIcon fontSize='small' />
                            </Tooltip>
                            </div>
                            <LocalizationProvider locale={ptLocale} dateAdapter={AdapterDateFns}>
                            <DatePicker
                                value={data.data_resultado}
                                onChange={handleDataResultadoChange}
                                renderInput={(params) => (
                                <TextField
                                    size="large"
                                    sx={{
                                    bgcolor: errorData_resultado
                                        ? "transparent"
                                        : "rgba(212, 109, 13, 0.1)",
                                    }}
                                    focused
                                    fullWidth
                                    {...params}
                                    error={Boolean(errorData_resultado)}
                                    helperText={errorData_resultado}
                                />
                                )}
                                minDate={addDays(parseISO(data.data_encerramento_insc),1)}
                                maxDate={subDays(parseISO(data.data_inicio),1)}
                                inputFormat="dd/MM/yyyy"
                            />
                            </LocalizationProvider>

                            
                        </Grid>
                        <Grid item xs={12} md={12}>
                          {perguntas.map((pergunta) => (
                            <div key={pergunta.id}>
                              <h3 className="label">Pergunta</h3>
                              <TextField
                                fullWidth
                                multiline
                                inputProps={{ maxLength: 50 }}
                                sx={{
                                  bgcolor: errorPergunta ? 'transparent' : 'rgba(212, 109, 13, 0.1)',
                                }}
                                placeholder="Adicione uma pergunta para os inscritos que possa auxiliar na avaliação da seleção."
                                focused
                                error={Boolean(errorPergunta)}
                                helperText={errorPergunta}
                                value={pergunta.texto}
                                onChange={(e) => handlePerguntaChange(pergunta.id, e)}
                              />

                              <p style={{ textAlign: 'end' }}>
                                {maxLength - pergunta.texto.length}/{maxLength} caracteres restantes
                              </p>
                            </div>
                          ))}
                          <Button
                            variant="contained"
                            color="secondary"
                            onClick={handleAdicionarNovaPergunta}
                          >
                            Adicionar nova pergunta
                          </Button>
                        </Grid>

                    </Grid>
                </div>
            <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                <Button startIcon={loading ? <CircularProgress color='inherit' size={20} /> : null} variant='contained' color='secondary' onClick={handleCheckError} >Criar Turma</Button>
            </div>
            </div>
        </ThemeProvider>
            <Snackbar open={alertUnauthorized.length>0} autoHideDuration={6000} onClose={() => setAlertUnauthorized('')}>
                <Alert variant="filled" onClose={() => setAlertUnauthorized('')} severity="error" sx={{ width: '100%' }}>{alertUnauthorized}</Alert>
            </Snackbar>
            <Snackbar open={alertSuccessful.length>0} autoHideDuration={6000} onClose={() => setAlertSuccessful('')}>
                <Alert variant="filled" onClose={() => setAlertSuccessful('')} severity="success" sx={{ width: '100%' }}>{alertSuccessful}</Alert>
            </Snackbar>
        </Content>
      </Modal>
  );
}
import React, { useEffect, useState } from "react";
import api from "../../services/api";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import { Container, Title } from "./styles";
import Button from "@mui/material/Button";
import InsightsIcon from "@mui/icons-material/Insights";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import { Popover } from "@mui/material";
import { Tooltip } from 'antd';
import { Link } from "react-router-dom";
import CardEditVaga from "../CardEditVaga"
import * as palette from "../../utils/styledVariables.js"
import logo from "../../assets/homeTriangle/oxeTechWork.png"

const styles = {
  text:
  {
    fontSize: "16pt",
    fontWeight: "300",
    fontStyle: "italic",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden"
  },
  popover:
  {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },


}


// const LabelOxetechWork = () => { return <img src={logo} alt="logo" width="30%"/>}
const LabelOxetechWorkAberta = () => {
  return(
    <div style={{background: "green", borderRadius: "5px", padding: '0 2%'}} > 
    <Tooltip color="purple" title="Sua vaga foi validada!">
      <h6 style={{color: '#fff', textAlign: 'center', margin:'0'}}>Aberta</h6>
    </Tooltip></div>)}
                                
const LabelOxetechWorkEmAnalise = () => {
  return <div style={{background: "rgba(232, 188, 31, 1)", borderRadius: "5px", padding: '0 2%'}} > 
    <Tooltip color="purple" title="Sua vaga está em análise!">
      <h6 style={{color: '#000', textAlign: 'center', margin:'0'}}>Em análise</h6></Tooltip></div>}

function CardVagas(props) {
  const { vaga } = props;
  const [candidates, setCandidates] = useState(null);
  useEffect(() => {
    api.get(`candidatura/candidatosmyvaga?id=${vaga.id}`).then((response) => {
      setCandidates(response.data);
    })
  }
    , []);

  const data = new Date(vaga.data_encerramento);
  const endDate = ((data.getDate())) + "/" + ((data.getMonth() + 1)) + "/" + data.getFullYear();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };

  function handleCadastroVaga(event) {
    setAnchorEl(event.currentTarget);
  }

  return (
    <Card
      sx={{
        height: "240px",
        backgroundColor: palette.laranjaOXLTransparente,
        position: "relative",
        borderRadius: "10px",
      }}
      >
      <Container>
      {vaga.status_oxetech_work==="Aberta" || (!vaga.oxetech_work&& vaga.status ==="ABERTA")?
      <Tooltip placement="top" title="Vaga validada"><LabelOxetechWorkAberta/></Tooltip>: vaga.status_oxetech_work==="Em análise" && vaga.oxetech_work? <LabelOxetechWorkEmAnalise/>: <></>}
        <Title>{vaga.titulo}</Title>
        </Container>
      <CardContent sx={{ height: "42%", paddingTop: '1%' }}>
        <Typography
          variant="body2"
          color={palette.laranjaIntermediario2}
          sx={styles.text}
        >
          Nº de candidatos: {candidates?.length}
        </Typography>
        <Typography
          variant="body2"
          color={palette.laranjaIntermediario2}
          sx={styles.text}
        >
          Inscrições até: {endDate}
        </Typography>
      </CardContent>
      <Divider variant="middle" />
      <CardActions
        sx={{
          position: "absolute",
          bottom: "0px",
          height: "25%",
          display: "flex",
          margin: "0",
          left: "50%",
          transform: "translate(-50%, 0)",
        }}
      >
        <Popover
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorReference={"none"}
            sx={styles.popover}
          >
            <CardEditVaga close={handleClose} vaga={vaga} />
          </Popover>
        <Button sx={{ color: palette.transparente }} onClick={handleCadastroVaga}>
          <VisibilityIcon  sx={{ fontSize: "35px" }} />
        </Button>
        <Divider variant="middle" orientation="vertical" flexItem />
        <Button >
          <Link to={{ pathname: `minha-vaga/${vaga.id}` }} style={{ color: palette.transparente }}>
            <InsightsIcon sx={{ fontSize: "35px" }} /></Link>
        </Button>
      </CardActions>
    </Card>
  );
}

export default CardVagas;
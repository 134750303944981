import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'

export const Caixa = styled(Box)`
  min-height: 500px;
  display: flex;
  gap: 3px;
  align-items: center;
  justify-content: center;

  .texto{
    padding: 5% 2%;
    width: 50vw;
    .tex{
      margin-bottom: 2%;
      font-weight: light;
      text-align: justify;
      font-family: 'Poppins', sans-serif;
      color: #FFFFFF;
      @media (max-width: 800px) {
        text-align: justify;
    }
    }
    @media (max-width: 800px) {
      padding: 5%;
      width: 100vw;

    }
  }
  .lampada{
    display: flex;
    justify-content: center;
    padding: 5px;
    margin-top: 4px;
    width: 50vw;
    .img{
      width: 60%;
    }
  }

  @media (max-width: 800px) {
    flex-direction: column-reverse;
  }
`

import React from "react";
import * as palette from "../../utils/styledVariables";
import {
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Card,
  CardContent,
  useMediaQuery,
} from "@mui/material";

const CardRanking = (props) => {
  const { ranking } = props;
  const isSmallScreen = useMediaQuery("(max-width:600px)");

  if (!ranking || ranking.length === 0) {
    return (
      <Card style={{ backgroundColor: "rgba(212, 109, 13, 0.1)"}}>
        <CardContent sx={{ height: isSmallScreen ? "90vh" : "57vh" }}>
          <Typography
            sx={{
              fontSize: isSmallScreen ? "1.0vh" : "2.0vh",
              color: palette.preto,
              fontFamily: "Poppins",
              fontWeight: "500",
              marginBottom: "2px",
            }}
          >
            Ranking da Trilha
          </Typography>
          <Typography
            sx={{
              fontSize: "2.0vh",
              color: palette.preto,
              fontFamily: "Poppins",
              marginTop: "10px",
            }}
          >
            Nenhum ranking disponível.
          </Typography>
        </CardContent>
      </Card>
    );
  }

  return (
    <Card style={{ backgroundColor: palette.laranjaOXLTransparente }}>
      <CardContent sx={{ height: isSmallScreen ? "90vh" : "57vh" }}>
        <Typography
          sx={{
            fontSize: isSmallScreen ? "1.0vh" : "2.0vh",
            color: palette.preto,
            fontFamily: "Poppins",
            fontWeight: "500",
            marginBottom: "2px",
          }}
        >
          Ranking da Trilha
        </Typography>
        <TableContainer
          component={Paper}
          style={{
            backgroundColor: palette.laranjaOXLTransparente,
            overflowX: "auto",
          }}
        >
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Nome do Aluno</TableCell>
                <TableCell>Média</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {ranking.map((row) => (
                <TableRow key={row.id}>
                  <TableCell>{row.Aluno.name}</TableCell>
                  <TableCell>{row.media}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
    </Card>
  );
};

export default CardRanking;

import React from 'react'
import { DataGrid, GridToolbar  } from '@mui/x-data-grid';
import {Box,Button,Checkbox} from '@mui/material';
import * as palette from "../../../utils/styledVariables";






export default function SessionSelecionar (props){
    const {candidates, quantidade, onNext} = props
    const [selectedNumber, setSelectedNumber] = React.useState(0);
    const [chosenCandidates, setChosenCandidates] = React.useState([]);
    const styles =
    {
        vagas: {
            backgroundColor: 'rgba(22, 9, 178, 0.28)',
            borderRadius: '5px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding:'5px'
        },
    }

    const columns = [
        {
          field: 'name',
          headerName: 'Nome',
          width:500,
        },
        {
          field: 'email',
          headerName: 'Email',
          width: 500,
        },
        
      ];
    const rows = []
    for (let i = 0; i < candidates.length; i++) {

        let currentCandidateData = {
            id: i, 
            name: candidates[i].Aluno.nome_social ? candidates[i].Aluno.nome_social : candidates[i].Aluno.name,
            email: candidates[i].Aluno.email,
        }   

        rows.push(currentCandidateData);
    };

    React.useEffect(() => {  

        const quantRestantes = quantidade - chosenCandidates.length
        onNext(chosenCandidates)
        setSelectedNumber(quantRestantes) 
    }, [chosenCandidates, selectedNumber])
    
   

    return (
        <>
            <Box sx={{ width: '100%' }}>
                <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                    <h2>Selecionar candidatos</h2>
                    <div style={styles.vagas}>
                        <h4 style={{marginBottom: '0'}}><strong>Vagas a serem preenchidas: </strong> {selectedNumber>0?selectedNumber: "0" }</h4></div>
                </div>
            <div style={{height:400}} >
            <DataGrid
                rows={rows}
                components={{ Toolbar: GridToolbar }}
                columns={columns}
                sx={{
                    background: palette.profileWhiteShadeTransp, borderRadius: '15px',
                    "& .MuiDataGrid-toolbarContainer": {
                        '& .MuiButton-text': {
                            color: palette.roxoAzulado,
                        },

                    }, "& .MuiDataGrid-columnHeaderTitle": {
                        color: palette.preto,

                    }
                    , "& .MuiDataGrid-cellContent": {
                        color: palette.preto,

                    },
                    '& .MuiDataGrid-footerContainer':{
                        position: 'relative',
                    }
                }}
                pageSize={5}
                onSelectionModelChange={itm => setChosenCandidates(itm)}
                rowsPerPageOptions={[5]}
                checkboxSelection   
            />
                </div>  
            
            </Box> 
            

        </>
      );
}

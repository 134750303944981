import { makeStyles } from "@material-ui/styles";
import AddIcon from "@mui/icons-material/Add";
import ClearIcon from "@mui/icons-material/Clear";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import HelpIcon from "@mui/icons-material/Help";
import LanguageIcon from "@mui/icons-material/Language";
import ListIcon from "@mui/icons-material/List";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import SaveIcon from "@mui/icons-material/Save";
import SearchOffIcon from "@mui/icons-material/SearchOff";
import UndoIcon from "@mui/icons-material/Undo";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Checkbox from "@mui/material/Checkbox";
import Chip from "@mui/material/Chip";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import InputLabel from "@mui/material/InputLabel";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Modal from "@mui/material/Modal";
import OutlinedInput from "@mui/material/OutlinedInput";
import Rating from "@mui/material/Rating";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { SnackbarProvider, useSnackbar } from "notistack";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Options from "../../utils/options";

import { updateProfileRequest } from "../../store/modules/user/actions";
import { laranjaOXLTransparente, laranjaSecundarioTransp } from "../../utils/styledVariables";

const useStyles = makeStyles({
  CardRoot: {
    backgroundColor: laranjaOXLTransparente,
    borderRadius: "10px",
    boxShadow: "3px 3px 6px -2px #000000",
    height: "100% !important",
  },
});

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

function LanguageCard() {
  const classes = useStyles();
  const profile = useSelector((state) => state.user.profile);
  const [name, setLanguageName] = React.useState([]);
  const [profileLanguages, setProfileLanguages] = React.useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [editable, setEditable] = React.useState(false);
  const [removable, setRemovable] = React.useState(false);
  const [addedable, setAddedable] = React.useState(false);
  const [newStarLabel, setNewStarLabel] = React.useState("");
  const [listable, setListable] = React.useState(true);
  const [addButtonDisabled, setLanguageAddButtonDisabled] =
    React.useState(true);
  const [newCustomLanguageHidden, setNewCustomLanguageHidden] =
    React.useState(true);
  const [languagesChangeState, setLanguagesChangeState] = React.useState(false);
  const [newLanguages, setNewLanguages] = React.useState([]);
  const [newCustomLanguages, setNewCustomLanguages] = React.useState([]);
  const [optionsLanguages, setOptionsLanguages] = React.useState(
    Options.languages
  );
  const [customLanguageValue, setCustomLanguageValue] = React.useState("");
  const [newCustomLanguageErrorMessage, setNewCustomLanguageErrorMessage] =
    React.useState("");
  const [newCustomLanguageErrorState, setNewCustomLanguageErrorState] =
    React.useState(false);
  const [openHelpModal, setOpenHelpModal] = React.useState(false);
  const [saveState, setSaveState] = React.useState(false);
  const handleOpenHelpModal = () => setOpenHelpModal(true);
  const handleCloseHelpModal = () => setOpenHelpModal(false);
  const menuOpen = Boolean(anchorEl);
  const [itemRemoved, setItemRemoved] = React.useState(false);
  const [originalLanguages, setOriginalLanguages] = React.useState([]);

  const { enqueueSnackbar } = useSnackbar();

  const dispatch = useDispatch();

  const customLanguageHandleChange = (event) => {
    setCustomLanguageValue(event.target.value);
  };

  function undoHandle() {
    setProfileLanguages(originalLanguages);
    setItemRemoved(false);
    setAnchorEl(null);
  }

  function getLabelByRating(rating) {
    let label;
    switch (true) {
      case rating === 1: {
        label = "Iniciante";
        break;
      }
      case rating === 2: {
        label = "Básico";
        break;
      }
      case rating === 3: {
        label = "Intermediário";
        break;
      }
      case rating === 4: {
        label = "Avançado";
        break;
      }
      case rating === 5: {
        label = "Fluente";
        break;
      }
      default:
        label = "Nivel Desconhecido";
        break;
    }
    return label;
  }

  const checkboxHandleChange = (event) => {
    const newProfileLanguages = profileLanguages.map((item) => {
      if (item.name === event.target.name) {
        item.add_to_cv = event.target.checked;
      }
      return item;
    });
    setLanguagesChangeState(true);
    setProfileLanguages(newProfileLanguages);
  };

  const newLanguageCheckboxHandleChange = (event) => {
    const itemsToAdd = newLanguages.map((item) => {
      if (item.name === event.target.name) {
        item.add_to_cv = event.target.checked;
      }
      return item;
    });
    setNewLanguages(itemsToAdd);
  };

  const newCustomLanguageCheckboxHandleChange = (event) => {
    const itemsToAdd = newCustomLanguages.map((item) => {
      if (item.name === event.target.name) {
        item.add_to_cv = event.target.checked;
      }
      return item;
    });
    setNewCustomLanguages(itemsToAdd);
  };

  function validate() {
    let levelEmpty;
    newLanguages.map((item) => {
      if (item.rating === 0 || item.rating === null) {
        enqueueSnackbar(
          "Por favor, informe o nível do idioma " + item.name + " !",
          { variant: "error" }
        );
        levelEmpty = true;
      }
    });
    newCustomLanguages.map((item) => {
      if (item.rating === 0 || item.rating === null) {
        enqueueSnackbar(
          "Por favor, informe o nível do idioma " + item.name + " !",
          { variant: "error" }
        );
        levelEmpty = true;
      }
    });
    profileLanguages.map((item) => {
      if (item.rating === 0 || item.rating === null) {
        enqueueSnackbar(
          "Por favor, informe o nível do idioma " + item.name + " !",
          { variant: "error" }
        );
        levelEmpty = true;
      }
    });

    if (!levelEmpty) {
      if (languagesChangeState) {
        profile.languages = profileLanguages;
        dispatch(updateProfileRequest(profile));

        let optionsLanguagesFiltered = optionsLanguages.filter((item) => {
          let found = false;
          profileLanguages.map((profileItem) => {
            if (profileItem.name === item.value) {
              found = true;
            }
          });

          if (!found) return item;
        });

        setOptionsLanguages(optionsLanguagesFiltered);
      }

      setNewLanguages([]);
      mergeLanguages();
      setLanguageAddButtonDisabled(true);
      setNewCustomLanguages([]);
      setSaveState(true);
      showList();
      setLanguagesChangeState(false);
    }
  }

  function addNewCustomLanguage() {
    if (customLanguageValue.length < 1) return;

    let itemAlreadyInserted = false;

    optionsLanguages.map((item) => {
      if (item.value.toLowerCase() === customLanguageValue.toLowerCase()) {
        itemAlreadyInserted = true;
        setNewCustomLanguageErrorState(true);
        setNewCustomLanguageErrorMessage("Esse idioma está na lista acima!");
      }
    });

    newCustomLanguages.map((item) => {
      if (item.name.toLowerCase() === customLanguageValue.toLowerCase()) {
        itemAlreadyInserted = true;
        setNewCustomLanguageErrorState(true);
        setNewCustomLanguageErrorMessage(
          "Idioma já marcado para ser adicionado!"
        );
      }
    });

    profileLanguages.map((item) => {
      if (item.name.toLowerCase() === customLanguageValue.toLowerCase()) {
        itemAlreadyInserted = true;
        setNewCustomLanguageErrorState(true);
        setNewCustomLanguageErrorMessage(
          "Idioma já está registrado no seu perfil!"
        );
      }
    });

    if (!itemAlreadyInserted) {
      setNewCustomLanguages([
        { name: customLanguageValue, rating: 0, add_to_cv: true },
        ...newCustomLanguages,
      ]);
      setCustomLanguageValue("");
      setNewCustomLanguageErrorState(false);
      setNewCustomLanguageErrorMessage("");
    } else {
      setTimeout(() => {
        setNewCustomLanguageErrorState(false);
        setNewCustomLanguageErrorMessage("");
      }, 5000);
    }
  }

  function mergeLanguages() {
    Array.prototype.push.apply(newLanguages, [
      ...newCustomLanguages,
      ...profileLanguages,
    ]);
    setProfileLanguages(newLanguages);
  }

  const menuContextHandleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const menuContextHandleClose = () => {
    setAnchorEl(null);
  };

  const showList = () => {
    setAddedable(false);
    setRemovable(false);
    setEditable(false);
    setListable(true);
  };

  const addHandleClose = () => {
    let optionsLanguagesFiltered = optionsLanguages.filter(
      (optionLanguageItem) => {
        let found = false;
        profileLanguages.map((profileItem) => {
          if (profileItem.name === optionLanguageItem.value) {
            found = true;
          }
        });

        if (!found) return optionLanguageItem;
      }
    );

    setOptionsLanguages(optionsLanguagesFiltered);

    setAddedable(true);
    setRemovable(false);
    setEditable(false);
    setListable(false);
  };

  const listHandleClose = () => {
    setAddedable(false);
    setRemovable(false);
    setEditable(false);
    setListable(true);
  };

  const editHandleClose = () => {
    setEditable(true);
    setAddedable(false);
    setRemovable(false);
    setListable(true);
  };

  const removeHandleClose = () => {
    setRemovable(true);
    setEditable(false);
    setAddedable(false);
    setListable(true);
  };

  useEffect(() => {
    if (profile.languages && profile.languages.length > 0) {
      if (profile.languages[0].name) {
        setProfileLanguages(profile.languages);
        setOriginalLanguages(profile.languages);
      }
    }
  }, []);

  useEffect(() => {
    if (saveState) {
      profile.languages = profileLanguages;
      dispatch(updateProfileRequest(profile));
    }
  }, [saveState]);

  function setLanguages(idiomas) {
    const newArray = idiomas.map((item) => {
      return { name: item, rating: 0, add_to_cv: true };
    });
    let msg;
    if (idiomas.length === 0) {
      msg = "Nenhum idioma selecionado!";
    } else if (idiomas.length === 1) {
      msg = "Um idioma selecionado!";
    } else {
      msg = idiomas.length + " idiomas selecionados!";
    }
    enqueueSnackbar(msg, { variant: "info" });
    setNewLanguages(newArray);
  }

  function setRatingValue(newValue, name) {
    let newProfileLanguages = profileLanguages.map((item) => {
      if (item.name === name) {
        item.rating = newValue;
      }

      return item;
    });

    setProfileLanguages(newProfileLanguages);
  }

  function setNewLanguageRatingValue(newValue, name) {
    const itemsToAdd = newLanguages.map((item) => {
      if (item.name === name) {
        item.rating = newValue;
      }

      return item;
    });

    setNewLanguages(itemsToAdd);
  }

  function setNewCustomLanguageRatingValue(newValue, name) {
    const itemsToAdd = newCustomLanguages.map((item) => {
      if (item.name === name) {
        item.rating = newValue;
      }

      return item;
    });

    setNewCustomLanguages(itemsToAdd);
  }

  function removeLanguage(language) {
    let newProfileLanguages = profileLanguages.filter((item) => {
      if (item.name !== language) return item;
    });
    setProfileLanguages(newProfileLanguages);
    setItemRemoved(true);
  }

  function removeCustomLanguage(language) {
    let newCustomLanguagesFiltered = newCustomLanguages.filter((item) => {
      if (item.name !== language) return item;
    });
    setNewCustomLanguages(newCustomLanguagesFiltered);
  }

  const LanguageSelectHandleChange = (event) => {
    const {
      target: { value },
    } = event;
    if (value.length === 0) {
      setLanguageAddButtonDisabled(true);
    } else {
      setLanguageAddButtonDisabled(false);
    }

    setLanguages(value);
    setLanguageName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  return (
    <Box component="main" sx={{ flexGrow: 1, p: 0 }}>
      <Modal
        open={openHelpModal}
        onClose={handleCloseHelpModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Marque as opções de acordo com a legenda baixo:
          </Typography>
          <Box sx={{ mt: 1, display: "flex", alignItems: "center" }}>
            <Checkbox checked />
            <Typography variant="body2">Mostrar idioma no currículo</Typography>
          </Box>
          <Box sx={{ mt: 0, display: "flex", alignItems: "center" }}>
            <Checkbox disabled />
            <Typography variant="body2">
              Não mostrar idioma no currículo
            </Typography>
          </Box>
          <Box sx={{ mt: 1 }}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Rating readOnly value={1} />
              <Typography sx={{ ml: 1 }} variant="body2">
                Iniciante
              </Typography>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Rating readOnly value={2} />
              <Typography sx={{ ml: 1 }} variant="body2">
                Básico
              </Typography>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Rating readOnly value={3} />
              <Typography sx={{ ml: 1 }} variant="body2">
                Intermediário
              </Typography>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Rating readOnly value={4} />
              <Typography sx={{ ml: 1 }} variant="body2">
                Avançado
              </Typography>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Rating readOnly value={5} />
              <Typography sx={{ ml: 1 }} variant="body2">
                Fluente
              </Typography>
            </Box>
          </Box>
        </Box>
      </Modal>
      <Card classes={{ root: classes.CardRoot }} sx={{}}>
        <CardContent>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography sx={{ fontWeight: 500 }} variant="h5" component="div">
              Idiomas
            </Typography>
            <Box>
              <MenuItem
                key="save"
                onClick={() => {
                  validate();
                  setAnchorEl(null);
                }}
                sx={{ display: removable || editable ? "inline-flex" : "none" }}
              >
                <Tooltip title={"Salvar Alterações"}>
                  <SaveIcon sx={{ mr: 1 }} />
                </Tooltip>
              </MenuItem>
              <MenuItem
                key="undo"
                onClick={undoHandle}
                sx={{ display: itemRemoved ? "inline-flex" : "none" }}
              >
                <Tooltip title={"Desfazer"}>
                  <UndoIcon />
                </Tooltip>
              </MenuItem>
              <IconButton
                aria-label="more"
                id="long-button"
                aria-controls={menuOpen ? "long-menu" : undefined}
                aria-expanded={menuOpen ? "true" : undefined}
                aria-haspopup="true"
                onClick={menuContextHandleClick}
              >
                <MoreHorizIcon />
              </IconButton>
            </Box>
            <Menu
              id="long-menu"
              MenuListProps={{
                "aria-labelledby": "long-button",
              }}
              anchorEl={anchorEl}
              open={menuOpen}
              onClose={menuContextHandleClose}
              PaperProps={{
                style: {
                  maxHeight: ITEM_HEIGHT * 4.5,
                  width: "20ch",
                },
              }}
            >
              <MenuItem
                key="listarLanguage"
                onClick={() => {
                  listHandleClose();
                  setAnchorEl(null);
                }}
                sx={{
                  display: addedable || removable || editable ? "flex" : "none",
                }}
              >
                <ListIcon sx={{ mr: 1 }} /> Listar
              </MenuItem>
              <MenuItem
                key="adicionarLanguage"
                onClick={() => {
                  addHandleClose();
                  setAnchorEl(null);
                }}
                sx={{ display: !addedable ? "flex" : "none" }}
              >
                <AddIcon sx={{ mr: 1 }} /> Adicionar
              </MenuItem>
              <MenuItem
                key="editarLanguage"
                onClick={() => {
                  editHandleClose();
                  setLanguagesChangeState(true);
                  setAnchorEl(null);
                }}
                sx={{ display: !editable ? "flex" : "none" }}
              >
                <EditIcon sx={{ mr: 1 }} /> Editar
              </MenuItem>
              <MenuItem
                key="removerLanguage"
                onClick={() => {
                  removeHandleClose();
                  setLanguagesChangeState(true);
                  setAnchorEl(null);
                }}
                sx={{ display: !removable ? "flex" : "none" }}
              >
                <DeleteIcon sx={{ mr: 1 }} /> Remover
              </MenuItem>
            </Menu>
          </Box>
          <Box sx={{ minHeight: "229px" }}>
            <FormControl sx={{ display: addedable ? "flex" : "none", m: 1 }}>
              <InputLabel id="demo-multiple-chip-label">
                Selecione Idiomas
              </InputLabel>
              <Select
                labelId="demo-multiple-chip-label"
                id="demo-multiple-chip"
                multiple
                value={name}
                onChange={LanguageSelectHandleChange}
                input={
                  <OutlinedInput
                    id="select-multiple-chip"
                    label="Selecione Idiomas"
                  />
                }
                renderValue={(selected) => (
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                    {selected.map((value) => (
                      <Chip key={value} label={value} />
                    ))}
                  </Box>
                )}
                MenuProps={MenuProps}
              >
                {optionsLanguages.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.value}
                  </MenuItem>
                ))}
              </Select>
              <Box
                sx={{
                  mt: 1,
                  display: addedable ? "block" : "none",
                  flexWrap: "wrap",
                }}
              >
                <Typography
                  sx={{
                    display: newLanguages.length !== 0 ? "inline" : "none",
                  }}
                  variant="caption"
                >
                  Idiomas selecionados:
                </Typography>
                <Box
                  sx={{
                    gap: editable ? 2 : 0,
                    maxHeight: "260px",
                    overflowY: "scroll",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  {Array.from(newLanguages).map((item, index) => {
                    return (
                      <Box key={"item-" + index}>
                        <Box sx={{ display: "flex", justifyContent: "end" }}>
                          <IconButton
                            onClick={handleOpenHelpModal}
                            aria-label="HelpModal"
                          >
                            <HelpIcon
                              sx={{
                                mr: 1,
                                mb: 1,
                                display: index === 0 ? "inline" : "none",
                              }}
                            />
                          </IconButton>
                        </Box>
                        <Box
                          sx={{
                            mb: 1,
                            display: "flex",
                            flexWrap: "wrap",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <Box
                            sx={{
                            //   border: "0.5px solid #b9b9b9",
                              width: "120px",
                              minWidth: "120px",
                              maxWidth: "120px",
                              flexGrow: 1,
                              boxShadow: "3px 3px 6px -2px #000000",
                              fontWeight: 500,
                              textAlign: "center",
                              borderRadius: "5px",
                              backgroundColor: laranjaSecundarioTransp,//"rgb(173, 137, 191, 0.5)",
                              py: 0,
                            }}
                          >
                            <Tooltip title={item.name}>
                              <Typography
                                sx={{
                                  px: 1,
                                  width: "120px",
                                  minWidth: "120px",
                                  maxWidth: "120px",
                                }}
                                noWrap
                                variant="subtitle2"
                              >
                                {item.name}
                              </Typography>
                            </Tooltip>
                          </Box>
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <Tooltip title={newStarLabel}>
                              <Rating
                                size="small"
                                sx={{ pl: 1, py: 0.5 }}
                                name="read-only"
                                value={item.rating}
                                onChangeActive={(event, newHover) => {
                                  let label;
                                  switch (true) {
                                    case newHover === 1: {
                                      label = "Iniciante";
                                      break;
                                    }
                                    case newHover === 2: {
                                      label = "Básico";
                                      break;
                                    }
                                    case newHover === 3: {
                                      label = "Intermediário";
                                      break;
                                    }
                                    case newHover === 4: {
                                      label = "Avançado";
                                      break;
                                    }
                                    case newHover === 5: {
                                      label = "Fluente";
                                      break;
                                    }
                                    default:
                                      label = "Nivel Desconhecido";
                                      break;
                                  }
                                  setNewStarLabel(label);
                                }}
                                onChange={(event, newValue) => {
                                  setNewLanguageRatingValue(
                                    newValue,
                                    item.name
                                  );
                                }}
                              />
                            </Tooltip>
                            <IconButton
                              sx={{ display: removable ? "inline" : "none" }}
                              onClick={() => {
                                removeLanguage(item.name);
                              }}
                              aria-label="delete"
                            >
                              <ClearIcon />
                            </IconButton>
                            <Tooltip
                              title={"Deixe marcado para mostrar no currículo"}
                            >
                              <Checkbox
                                checked={item.add_to_cv}
                                name={item.name}
                                onChange={newLanguageCheckboxHandleChange}
                                inputProps={{ "aria-label": "controlled" }}
                              />
                            </Tooltip>
                          </Box>
                        </Box>
                      </Box>
                    );
                  })}
                </Box>
              </Box>
              <Button
                sx={{ display: !newCustomLanguageHidden ? "none" : "flex" }}
                onClick={() => setNewCustomLanguageHidden(false)}
                variant="text"
              >
                + idioma não listado
              </Button>
              {/* custom languages list */}
              <Box
                sx={{
                  mt: 1,
                  alignItems: "center",
                  display: newCustomLanguageHidden ? "none" : "flex",
                }}
              >
                <TextField
                  error={newCustomLanguageErrorState}
                  helperText={newCustomLanguageErrorMessage}
                  sx={{ flexGrow: 1 }}
                  value={customLanguageValue}
                  onChange={customLanguageHandleChange}
                  size="small"
                  id="outlined-basic"
                  label="Idioma não listado"
                  variant="filled"
                />
                <IconButton
                  onClick={addNewCustomLanguage}
                  size="medium"
                  aria-label="add"
                >
                  <AddIcon />
                </IconButton>
              </Box>
              <Box
                sx={{
                  mt: 1,
                  display: addedable ? "block" : "none",
                  flexWrap: "wrap",
                }}
              >
                <Typography
                  sx={{
                    display:
                      newCustomLanguages.length !== 0 ? "inline" : "none",
                  }}
                  variant="caption"
                >
                  Idiomas não listados:
                </Typography>
                <Box
                  sx={{
                    gap: editable ? 2 : 0,
                    maxHeight: "260px",
                    overflowY: "scroll",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  {Array.from(newCustomLanguages).map((item, index) => {
                    return (
                      <Box key={"item-" + index}>
                        <Box sx={{ display: "flex", justifyContent: "end" }}>
                          <IconButton
                            onClick={handleOpenHelpModal}
                            aria-label="HelpModal"
                          >
                            <HelpIcon
                              sx={{
                                mr: 1,
                                mb: 1,
                                display: index === 0 ? "inline" : "none",
                              }}
                            />
                          </IconButton>
                        </Box>
                        <Box
                          sx={{
                            mb: 1,
                            display: "flex",
                            flexWrap: "wrap",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <Box
                            sx={{
                            //   border: "0.5px solid #b9b9b9",
                              width: "120px",
                              minWidth: "120px",
                              maxWidth: "120px",
                              flexGrow: 1,
                              boxShadow: "3px 3px 6px -2px #000000",
                              fontWeight: 500,
                              textAlign: "center",
                              borderRadius: "5px",
                              backgroundColor: laranjaSecundarioTransp,//"rgb(173, 137, 191, 0.5)",
                              py: 0,
                            }}
                          >
                            <Tooltip title={item.name}>
                              <Typography
                                sx={{
                                  px: 1,
                                  width: "120px",
                                  minWidth: "120px",
                                  maxWidth: "120px",
                                }}
                                noWrap
                                variant="subtitle2"
                              >
                                {item.name}
                              </Typography>
                            </Tooltip>
                          </Box>
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <Tooltip title={newStarLabel}>
                              <Rating
                                size="small"
                                sx={{ pl: 1, py: 0.5 }}
                                name="read-only"
                                value={item.rating}
                                onChangeActive={(event, newHover) => {
                                  let label;
                                  switch (true) {
                                    case newHover === 1: {
                                      label = "Iniciante";
                                      break;
                                    }
                                    case newHover === 2: {
                                      label = "Básico";
                                      break;
                                    }
                                    case newHover === 3: {
                                      label = "Intermediário";
                                      break;
                                    }
                                    case newHover === 4: {
                                      label = "Avançado";
                                      break;
                                    }
                                    case newHover === 5: {
                                      label = "Fluente";
                                      break;
                                    }
                                    default:
                                      label = "Nivel Desconhecido";
                                      break;
                                  }
                                  setNewStarLabel(label);
                                }}
                                onChange={(event, newValue) => {
                                  setNewCustomLanguageRatingValue(
                                    newValue,
                                    item.name
                                  );
                                }}
                              />
                            </Tooltip>
                            <Tooltip
                              title={"Deixe marcado para mostrar no currículo"}
                            >
                              <Checkbox
                                checked={item.add_to_cv}
                                name={item.name}
                                onChange={newCustomLanguageCheckboxHandleChange}
                                inputProps={{ "aria-label": "controlled" }}
                              />
                            </Tooltip>
                            <IconButton
                              onClick={() => {
                                removeCustomLanguage(item.name);
                              }}
                              aria-label="delete"
                            >
                              <ClearIcon />
                            </IconButton>
                          </Box>
                        </Box>
                      </Box>
                    );
                  })}
                </Box>
              </Box>
              <Button
                onClick={() => {
                  validate();
                }}
                disabled={addButtonDisabled && newCustomLanguages.length === 0}
                sx={{ mt: 1 }}
                variant="contained"
                color="success"
              >
                Adicionar Todas
              </Button>
            </FormControl>
            {profileLanguages.length === 0 ? (
              <Box
                sx={{
                  height: "150px",
                  display: listable ? "flex" : "none",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <Box
                  sx={{
                    gap: 1,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <SearchOffIcon sx={{ fontSize: "48px" }} />
                  <Typography sx={{ p: 1, borderRadius: "15px" }} variant="h6">
                    Nenhum registro encontrado!
                  </Typography>
                </Box>
              </Box>
            ) : (
              <Box
                sx={{ display: listable ? "block" : "none", flexWrap: "wrap" }}
              >
                <Box
                  sx={{
                    gap: editable ? 2 : 0,
                    maxHeight: "260px",
                    overflowY: "scroll",
                    gap: 2,
                    display: "flex",
                    flexWrap: "wrap",
                  }}
                >
                  {Array.from(profileLanguages).map((item, index) => {
                    return (
                      <Box
                        key={"item-" + index}
                        sx={{
                          mr: 2,
                          mb: 1,
                          display: "flex",
                          flexGrow: 1,
                          flexWrap: "wrap",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Box
                          sx={{
                            // border: "0.5px solid #b9b9b9",
                            flexGrow: 1,
                            boxShadow: "3px 3px 6px -2px #000000",
                            fontWeight: 500,
                            borderRadius: "5px",
                            backgroundColor: laranjaSecundarioTransp, //"rgb(173, 137, 191, 0.5)",
                            py: 0,
                          }}
                        >
                          <Box
                            sx={{
                              ml: 1,
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <LanguageIcon />
                            <Typography sx={{ p: 1 }} noWrap variant="h6">
                              {item.name}
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "flex-end",
                            }}
                          >
                            <Box>
                              <Box sx={{ mt: 1 }}>
                                <Typography
                                  variant="subtitle2"
                                  sx={{ fontWeight: "bold", ml: 1 }}
                                >
                                  {getLabelByRating(item.rating)}
                                </Typography>
                              </Box>
                              <Tooltip title={newStarLabel}>
                                <Rating
                                  sx={{ pl: 1, py: 0.5 }}
                                  name="read-only"
                                  value={item.rating}
                                  readOnly={editable ? false : true}
                                  onChangeActive={(event, newHover) => {
                                    let label;
                                    switch (true) {
                                      case newHover === 1: {
                                        label = "Iniciante";
                                        break;
                                      }
                                      case newHover === 2: {
                                        label = "Básico";
                                        break;
                                      }
                                      case newHover === 3: {
                                        label = "Intermediário";
                                        break;
                                      }
                                      case newHover === 4: {
                                        label = "Avançado";
                                        break;
                                      }
                                      case newHover === 5: {
                                        label = "Fluente";
                                        break;
                                      }
                                      default:
                                        label = "Nivel Desconhecido";
                                        break;
                                    }
                                    setNewStarLabel(label);
                                  }}
                                  onChange={(event, newValue) => {
                                    setRatingValue(newValue, item.name);
                                  }}
                                />
                              </Tooltip>
                            </Box>
                            <Box>
                              <Box>
                                <Tooltip
                                  sx={{
                                    display:
                                      editable || listable ? "inline" : "none",
                                  }}
                                  title={
                                    "Deixe marcado para mostrar no currículo"
                                  }
                                >
                                  <Checkbox
                                    sx={{
                                      display:
                                        editable || !removable
                                          ? "inline"
                                          : "none",
                                    }}
                                    checked={item.add_to_cv}
                                    name={item.name}
                                    disabled={!editable}
                                    onChange={checkboxHandleChange}
                                    inputProps={{ "aria-label": "controlled" }}
                                  />
                                </Tooltip>
                              </Box>
                              <Box>
                                <IconButton
                                  sx={{
                                    display: removable ? "inline" : "none",
                                    pb: 0,
                                  }}
                                  onClick={() => {
                                    removeLanguage(item.name);
                                  }}
                                  aria-label="delete"
                                >
                                  <ClearIcon />
                                </IconButton>
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    );
                  })}
                </Box>
              </Box>
            )}
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
}

export default function IntegrationNotistack() {
  return (
    <SnackbarProvider maxSnack={3}>
      <LanguageCard />
    </SnackbarProvider>
  );
}

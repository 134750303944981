import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import {
  Breadcrumb,
  Tabs,
  Descriptions,
  Skeleton,
  Button,
  Divider,
  Avatar,
  Modal,
  Form,
  Select,
  message,
  Tag
} from 'antd'
import moment from 'moment'

import {
  HomeOutlined,
  UserOutlined,
  DownloadOutlined,
  GithubFilled,
  LinkedinFilled,
  LinkOutlined
} from '@ant-design/icons'

import api from '../../services/api'

import { Container, Content } from './styles'

function ProfileAlunoAdm({ match }) {
  const { id } = match.params

  const { TabPane } = Tabs
  const { Option } = Select

  const [aluno, setAluno] = useState()
  const [isModalVisible, setIsModalVisible] = useState(false)

  const showModal = () => {
    setIsModalVisible(true)
  }

  const handleCancel = () => {
    setIsModalVisible(false)
  }

  useEffect(() => {
    const loadAluno = async () => {
      await api.get(`perfilaluno?id=${id}`).then(response => {
        setAluno(response.data)
      })
    }
    loadAluno()
  }, [id])

  const layout = {
    labelCol: {
      span: 8
    },
    wrapperCol: {
      span: 16
    }
  }
  const tailLayout = {
    wrapperCol: {
      offset: 8,
      span: 16
    }
  }

  const onFinish = async values => {
    await api
      .patch(`/validation?id=${id}`, values)
      .then(response => {
        message.success(response.data.message)
        setIsModalVisible(false)
        window.location.reload()
      })
      .catch(err => {
        message.err(err.response.data.error)
      })
  }

  return (
    <Container>
      <div>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to={{ pathname: '/' }}>
              <HomeOutlined />
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to={{ pathname: '/alunos' }}>
              <span>Alunos</span>
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>{aluno ? aluno.nome_social ? aluno.nome_social : aluno.name : <Skeleton />}</Breadcrumb.Item>
        </Breadcrumb>
      </div>

      {aluno ? (
        <Content>
          <div id="validacao">
            <Button type="primary" onClick={showModal}>
              Validar Aluno
            </Button>
            <Modal
              className="modal"
              title="Validação do Aluno"
              visible={isModalVisible}
              width={250}
              onCancel={handleCancel}
              footer={
                <Button onClick={handleCancel} danger>
                  Cancelar
                </Button>
              }
            >
              <Form
                {...layout}
                name="basic"
                initialValues={{
                  remember: true
                }}
                onFinish={onFinish}
              >
                <Form.Item
                  label="Status"
                  name="status"
                  rules={[{ required: true, message: 'Campo Obrigatório!' }]}
                >
                  <Select placeholder="Status">
                    <Option value="VÁLIDO">VÁLIDO</Option>
                    <Option value="INVÁLIDO">INVÁLIDO</Option>
                  </Select>
                </Form.Item>

                <Form.Item {...tailLayout}>
                  <Button type="primary" htmlType="submit">
                    Submit
                  </Button>
                </Form.Item>
              </Form>
            </Modal>
          </div>
          <Avatar size={80} icon={<UserOutlined />} />
          <Divider />
          <Descriptions title={aluno.nome_social ? aluno.nome_social : aluno.nome} size="middle">
            <Descriptions.Item label="Email"> {aluno.email} </Descriptions.Item>
            <Descriptions.Item label="Telefone">
              {' '}
              {aluno.telefone}{' '}
            </Descriptions.Item>
            <Descriptions.Item label="Data de Nascimento">
              {' '}
              {moment(aluno.data_nascimento)
                .utc('America/Alagoas')
                .format('DD/MM/YYYY')}{' '}
            </Descriptions.Item>
            <Descriptions.Item label="CPF"> {aluno.cpf} </Descriptions.Item>
            <Descriptions.Item label="Status">
              {' '}
              {aluno.status}{' '}
            </Descriptions.Item>
            <Descriptions.Item>
              <h3>
                {aluno.linkedin ? (
                  <a href={aluno.linkedin} target="_blank" rel="noreferrer">
                    <LinkedinFilled style={{ fontSize: '2rem' }} />
                  </a>
                ) : (
                  ''
                )}
                {aluno.github ? (
                  <a href={aluno.github} target="_blank" rel="noreferrer">
                    <GithubFilled style={{ fontSize: '2rem' }} />
                  </a>
                ) : (
                  ''
                )}
                {aluno.outro ? (
                  <a href={aluno.outro} target="_blank" rel="noreferrer">
                    <LinkOutlined style={{ fontSize: '2rem' }} />
                  </a>
                ) : (
                  ''
                )}
              </h3>
            </Descriptions.Item>

            {/* <Descriptions.Item>
            </Descriptions.Item> */}
          </Descriptions>

          <Divider />

          <Descriptions title={<strong>Endereço</strong>} size="middle">
            <Descriptions.Item label="Rua">{aluno.rua}</Descriptions.Item>
            <Descriptions.Item label="Número">{aluno.numero}</Descriptions.Item>
            {aluno.complemento ? (
              <Descriptions.Item label="Complemento">
                {aluno.complemento}
              </Descriptions.Item>
            ) : (
              ''
            )}
            <Descriptions.Item label="Bairro">{aluno.bairro}</Descriptions.Item>
            <Descriptions.Item label="CEP">{aluno.cep}</Descriptions.Item>
            <Descriptions.Item label="Município">
              {aluno.municipio}
            </Descriptions.Item>
            <Descriptions.Item>
              {aluno.comprovante ? (
                <Button
                  id="btn-comprovante"
                  type="primary"
                  shape="round"
                  icon={<DownloadOutlined />}
                >
                  <a
                    href={aluno.comprovante.path}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Comprovante de Residencia
                  </a>
                </Button>
              ) : (
                'Sem Comprovante'
              )}
            </Descriptions.Item>
          </Descriptions>

          <Divider />

          <Descriptions title={<strong>Sobre mim</strong>} size="middle">
            <Descriptions.Item label="" style={{ width: '60vw' }}>
              {aluno.sobre_mim}
            </Descriptions.Item>
          </Descriptions>

          <Descriptions size="middle">
            <Descriptions.Item label={<strong>Foco na carreira</strong>}>
              {aluno.foco_carreira}
            </Descriptions.Item>
          </Descriptions>

          {/* <Descriptions size="middle">
            <Descriptions.Item label={<strong>Habilidades</strong>}>
              {aluno.skills
                ? aluno.skills.map(skill => {
                    return <Tag color="orange">{skill}</Tag>
                  })
                : null}
            </Descriptions.Item>
          </Descriptions> */}
        </Content>
      ) : (
        <Skeleton />
      )}

      <Tabs defaultActiveKey="1" centered>
        <TabPane tab="Conquistas" key="1">
          <p>Conquistas</p>
        </TabPane>
        <TabPane tab="Cursos" key="2">
          <p>Cursos</p>
        </TabPane>
      </Tabs>
    </Container>
  )
}

export default ProfileAlunoAdm

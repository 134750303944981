import * as palette from "../../utils/styledVariables";
import React, { useEffect, useState } from "react";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import { updateProfileEmpresaRequest } from "../../store/modules/user/actions";
import { useSelector, useDispatch } from "react-redux";
import cep from "cep-promise";
import InstitutionalCategories from "../../utils/InstitutionalCategories";
import InputMask from "react-input-mask";
import {
    Typography,
    Card,
    Fab,
    Divider,
    Checkbox,
    styled,
    Grid,
    Box,
    Chip,
    AutoComplete,
    Popover,
    Button,
    TextField,
    FormControlLabel,
} from "@mui/material";


const Title = styled((props) => <Typography {...props} />)(() => ({
    fontFamily: "Roboto",
    fontStyle: "bold",
    fontWeight: "500",
    fontSize: "3vh",
    lineHeight: "3vh",
    margin: "10px 0px 0px 10px",
}));

const Subtitles = styled((props) => <Typography {...props} />)(() => ({
    fontFamily: "Roboto",
    fontStyle: "bold",
    fontWeight: "500",
    fontSize: "2.5vh",
    lineHeight: "2.5vh",
    margin: "0px 0px 5px 0px",
}));

const StyledCard = styled((props) => <Card {...props} />)(() => ({
    margin: "20px",
    // background: "rgba(173, 137, 191, 0.22)",
    background: palette.laranjaOXLTransparente
}));

const ChangeButton = styled((props) => <Button {...props} />)(() => ({
    // background:
    //     "radial-gradient(292.41% 335.33% at 41.6% 50%, #741F8A 2.22%, #1609B3 100%)",
    background: palette.laranjaIntermediario2,
    ':hover':{
     background: palette.laranjaSecundario
    },
    color: palette.profileWhiteShade,
    float: "right",
    "margin-right": "10px",
    "margin-bottom": "10px",
}));

function DadosCadastraisEmpresa({styles}) {
    let profile = useSelector((state) => state.user.profile.dataValues ? state.user.profile.dataValues : state.user.profile);

    const [intelectualProp, setIntelectualProp] = useState(
        profile.propriedade_intelectual
    );
    const [categories, setCategories] = useState(
        profile.categoria.map((category) => {
            return { label: category, value: category };
        })
    );

    const [email, setEmail] = useState(profile.email);
    const [address, setAddress] = useState(profile.endereco);
    const [about, setAbout] = useState(profile.sobre);
    const [phoneNumber, setPhoneNumber] = useState(profile.telefone);
    const [name, setName] = useState(profile.razao_social);
    const [linkedin, setLinkedin] = useState(profile.link_linkedin);
    const [facebook, setFacebook] = useState(profile.link_facebook);
    const [instagram, setInstagram] = useState(profile.link_instagram);
    const [numcep, setCep] = useState(profile.endereco.cep);

    const [errorCep, setErrorCep] = useState(null);
    const [errorEmail, setErrorEmail] = useState(null);
    const [errorName, setErrorName] = useState(null);
    const [errorAbout, setErrorAbout] = useState(null);
    const [errorCategories, setErrorCategories] = React.useState(null);

    const dispatch = useDispatch();
    const filter = createFilterOptions();

    const handleCep = (event) => {
        const {
            target: { value },
        } = event;
        setCep(value);
        setErrorCep({ numcep: "" });

        let reg = new RegExp(/^[0-9]{2}\.[0-9]{3}-[0-9]{3}$/).test(value);
        if (reg) {
            cep(value)
                .then((response) => {
                    const {
                        street: rua,
                        neighborhood: bairro,
                        city: municipio,
                        cep,
                        state: estado,
                    } = response;

                    setAddress({
                        rua: rua,
                        bairro: bairro,
                        municipio: municipio,
                        cep: cep,
                        testado: estado,
                        numero: address.numero,
                    });
                })
                .catch((error) => {
                    setErrorCep({ numcep: "Não conseguimos resgatar o seu CEP." });
                    setAddress({
                        rua: "",
                        bairro: "",
                        municipio: "",
                        cep: "",
                        estado: "",
                        numero: address.numero,
                    });
                });
        } else {
            setErrorCep({ numcep: "CEP inválido." });
        }
    };

    const handleAddressField = (event, field) => {
        const {
            target: { value },
        } = event;

        const currentAddress = { ...address };
        currentAddress[field] = value;
        setAddress(currentAddress);
    };

    const handlePhoneNumber = (event) => {
        const {
            target: { value },
        } = event;
        setPhoneNumber(value);
    };

    const handleEmail = (event) => {
        const {
            target: { value },
        } = event;
        setEmail(value);
        setErrorEmail({ email: "" });

        if (value.trim().length > 0) {
            let reg = new RegExp(
                /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            ).test(value);
            if (!reg)
                setErrorEmail({ email: "Insira um email válido." });

        } else {
            setErrorEmail({ email: "Preencha esse campo." });
        }
    };

    const handleCategories = (e, value) => {
        setErrorCategories({ categories: "" });
        if (e.type === "blur") {
            if (value.length === 0) {
                setCategories([]);
                setErrorCategories({ categories: "Preencha esse campo." });
            }
        } else {
            if (!Array.isArray(value)) {
                value = [value];
            }
            if (typeof value[value.length - 1] != "object") {
                value.splice(-1, 1, {
                    label: value[value.length - 1],
                    value: value[value.length - 1],
                });
            }
            setCategories(value);
        }
    };

    const handleName = (event) => {
        const {
            target: { value },
        } = event;
        setName(value);
        setErrorName({ name: "" });
        if (value.length <= 0) setErrorName({ name: "Preencha esse campo." });
    };

    const handleAbout = (event) => {
        const {
            target: { value },
        } = event;
        setAbout(value);
        setErrorAbout({ about: "" });
        if (value.length <= 0) setErrorAbout({ about: "Preencha esse campo." });
    };

    const handleChecked = (event) => {
        setIntelectualProp(event.target.checked);
    };

    const onFinish = async () => {
        const data = {
            email: email,
            categoria: categories.map((x) => x.label),
            razao_social: name,
            sobre: about,
            endereco: address,
            telefone: phoneNumber,
            propriedade_intelectual: intelectualProp,
            link_facebook: facebook,
            link_linkedin: linkedin,
            link_instagram: instagram,
        };

        dispatch(updateProfileEmpresaRequest(data));
    };



    return (
        <Box component="main"
            sx={{ ...styles, flexGrow: 1, p: 1, width: { sm: `calc(100% - 20vh)` } }}>
            <StyledCard >
                <Title>Identificação da empresa</Title>

                <Grid container columns={2} sx={{ padding: "10px" }} spacing={1}>
                    <Grid item xs={2} sm={2} md={1}>
                        <Subtitles>CNPJ</Subtitles>

                        <InputMask
                            mask="99.999.999/9999-99"
                            value={profile.cnpj}
                            disabled={true}
                            maskChar=" "
                        >
                            {() => (
                                <TextField
                                    hiddenLabel
                                    id="cnpj"
                                    variant="filled"
                                    fullWidth
                                    disabled
                                />
                            )}
                        </InputMask>
                    </Grid>

                    <Grid item xs={2} sm={2} md={1}>
                        <Subtitles>Nome da empresa</Subtitles>
                        <TextField
                            hiddenLabel
                            id="nome-empresa"
                            variant="filled"
                            fullWidth
                            value={name}
                            onChange={handleName}
                            onBlur={handleName}
                            error={Boolean(errorName?.name)}
                            helperText={errorName?.name}
                        />
                    </Grid>
                    <Grid item xs={2} sm={2} md={2}>
                        <Subtitles>Categorias da empresa</Subtitles>

                        <Autocomplete
                            id="categories"
                            multiple
                            freeSolo
                            onChange={(event, value) => handleCategories(event, value)}
                            //onBlur={(event, value) => handleCategories(event, value)}
                            options={InstitutionalCategories}
                            value={categories}
                            disableCloseOnSelect
                            filterOptions={(options, params) => {
                                const filtered = filter(options, params);

                                const { inputValue } = params;

                                const isExisting = options.some(
                                    (option) => inputValue === option.label
                                );

                                if (inputValue !== "" && !isExisting) {
                                    filtered.push({
                                        value: inputValue,
                                        label: `Adicionar "${inputValue}"`,
                                    });
                                }

                                return filtered;
                            }}
                            getOptionLabel={(option) => {
                                if (typeof option === "string") return option;

                                return option.value;
                            }}
                            renderOption={(props, option) => (
                                <li {...props}>{option.label}</li>
                            )}
                            renderValue={(selected) => (
                                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                                    {selected.map((value) => (
                                        <Chip key={value} label={value} />
                                    ))}
                                </Box>
                            )}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    fullWidth={true}
                                    required
                                    hiddenLabel
                                    variant="filled"
                                    placeholder="Pesquisar categoria"
                                    error={Boolean(errorCategories?.categories)}
                                    helperText={errorCategories?.categories}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={2} sm={2} md={2}>
                        <Subtitles>Sobre a instituição</Subtitles>
                        <TextField
                            hiddenLabel
                            id="descricao"
                            variant="filled"
                            fullWidth
                            value={about}
                            onChange={handleAbout}
                            onBlur={handleAbout}
                            error={Boolean(errorAbout?.about)}
                            helperText={errorAbout?.about}
                        />
                    </Grid>
                </Grid>
            </StyledCard>

            <StyledCard>
                <Title>Endereço</Title>

                <Grid container columns={2} sx={{ padding: "10px" }} spacing={1}>
                    <Grid item xs={2} sm={2} md={1}>
                        <Subtitles>CEP</Subtitles>

                        <InputMask
                            mask="99.999-999"
                            value={numcep}
                            maskChar=" "
                            onBlur={handleCep}
                            onChange={handleCep}
                        >
                            {() => (
                                <TextField
                                    hiddenLabel
                                    id="cep"
                                    variant="filled"
                                    fullWidth
                                    error={Boolean(errorCep?.numcep)}
                                    elperText={errorCep?.numcep}
                                />
                            )}
                        </InputMask>
                    </Grid>

                    <Grid item xs={2} sm={2} md={1}>
                        <Subtitles>Rua</Subtitles>
                        <TextField
                            hiddenLabel
                            id="rua"
                            variant="filled"
                            fullWidth
                            value={address.rua}
                            onChange={(e) => handleAddressField(e, "rua")}
                        />
                    </Grid>
                    <Grid item xs={2} sm={2} md={1}>
                        <Subtitles>Número</Subtitles>
                        <TextField
                            hiddenLabel
                            id="numero"
                            variant="filled"
                            fullWidth
                            value={address.numero}
                            onChange={(e) => handleAddressField(e, "numero")}
                        />
                    </Grid>
                    <Grid item xs={2} sm={2} md={1}>
                        <Subtitles>Bairro</Subtitles>
                        <TextField
                            hiddenLabel
                            id="cnpj"
                            variant="filled"
                            fullWidth
                            value={address.bairro}
                            onChange={(e) => handleAddressField(e, "bairro")}
                        />
                    </Grid>
                    <Grid item xs={2} sm={2} md={1}>
                        <Subtitles>Município</Subtitles>
                        <TextField
                            hiddenLabel
                            id="cnpj"
                            variant="filled"
                            fullWidth
                            value={address.municipio}
                            onChange={(e) => handleAddressField(e, "municipio")}
                        />
                    </Grid>
                    <Grid item xs={2} sm={2} md={1}>
                        <Subtitles>Telefone</Subtitles>
                        <InputMask
                            mask="(99) 9 9999 9999"
                            value={phoneNumber}
                            maskChar=" "
                            onChange={handlePhoneNumber}
                            onBlur={handlePhoneNumber}
                        >
                            {() => (
                                <TextField
                                    hiddenLabel
                                    id="telefone"
                                    variant="filled"
                                    fullWidth
                                />
                            )}
                        </InputMask>
                    </Grid>
                </Grid>
            </StyledCard>

            <StyledCard>
                <Title>Acesso</Title>
                <Grid container columns={2} sx={{ padding: "10px" }} spacing={1}>
                    <Grid item xs={2} sm={2} md={1}>
                        <Subtitles>e-mail</Subtitles>
                        <TextField
                            hiddenLabel
                            id="email"
                            variant="filled"
                            fullWidth
                            value={email}
                            onBlur={handleEmail}
                            onChange={handleEmail}
                            error={Boolean(errorEmail?.email)}
                            helperText={errorEmail?.email}
                        />
                    </Grid>
                </Grid>
            </StyledCard>

            <StyledCard>
                <Title>Propriedade intelectual</Title>

                <Grid container columns={2} sx={{ padding: "10px" }} spacing={1}>
                    <Grid item xs={2} sm={2} md={1}>
                        <FormControlLabel
                            label="A empresa tem propriedade intelectual"
                            control={
                                <Checkbox
                                    defaultChecked
                                    sx={{
                                        color: palette.laranjaIntermediario2,
                                        "&.Mui-checked": {
                                            color: palette.laranjaIntermediario2,
                                        },
                                    }}
                                    checked={intelectualProp}
                                    onChange={handleChecked}
                                />
                            }
                        />
                    </Grid>
                </Grid>
            </StyledCard>

            <StyledCard>
                <Title>Redes sociais</Title>

                <Grid container columns={2} sx={{ padding: "10px" }} spacing={1}>
                    <Grid item xs={2} sm={2} md={1}>
                        <Subtitles>LinkedIn</Subtitles>
                        <TextField
                            hiddenLabel
                            id="cnpj"
                            variant="filled"
                            fullWidth
                            value={linkedin}
                            onChange={(e) => {
                                setLinkedin(e.target.value);
                            }}
                        />
                    </Grid>

                    <Grid item xs={2} sm={2} md={1}>
                        <Subtitles>Instagram</Subtitles>
                        <TextField
                            hiddenLabel
                            id="cnpj"
                            variant="filled"
                            fullWidth
                            value={instagram}
                            onChange={(e) => {
                                setInstagram(e.target.value);
                            }}
                        />
                    </Grid>
                    <Grid item xs={2} sm={2} md={1}>
                        <Subtitles>Facebook</Subtitles>
                        <TextField
                            hiddenLabel
                            id="cnpj"
                            variant="filled"
                            fullWidth
                            value={facebook}
                            onChange={(e) => {
                                setFacebook(e.target.value);
                            }}
                        />
                    </Grid>
                </Grid>
            </StyledCard>

            <ChangeButton sx={{ marginRight: "20px" }} size="large" onClick={onFinish}>Salvar alterações</ChangeButton>
        </Box>
    );
}
export default DadosCadastraisEmpresa;

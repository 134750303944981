import { makeStyles, styled } from '@material-ui/styles';

export const useStyles = makeStyles(() => ({
  modalContainer: {
    position: 'absolute',
    display: 'flex',
    paddingTop: '1.3rem',
    paddingRight: '1.5rem',
    paddingLeft: '1.5rem',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    height: '90vh',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    borderRadius: '15px',
    overflow: 'auto',
  },

  goBackContainer: {
    display: 'flex',
    width: '100%',
    height: '0.5vh',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },

  goBackButton: {
    borderRadius: '50%',
    backGroundColor: '#D56D0D2E',
    '&:hover': {
      backgroundColor: '#D56D0D2E',
    }
  },

  modalHeader: {
    width: '100%',
    height: '20%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '1rem'
  },

  iconAndTitleContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flexGrow: 1,
    height: '100%',
    width: '65%',
    margin: '3vh'
  },

  icon: {
    margin: '0 1rem 0 1rem',
    backgroundColor: '#D9D9D978',
    width: 'auto',
    height: 'auto',
    flexShrink: 0,
    aspectRatio: '1/1', 
  },

  iconImg: {
    width: '60%',
    height: '60%',
    objectFit: 'contain'
  },

  title: {
    fontFamily: 'Roboto',
    fontWeight: 400,
    height: '100%',
    display: 'flex',
    alignItems: 'center',
  },

  statusAndDateContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignContent: 'flex-end',
    height: '100%',
    maxWidth: '35%'
  },

  modalDateContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%'
  }, 

  descriptionContainer: {
    padding: '3rem',
    paddingRight: '4rem',
    paddingLeft: '4rem'
  },

  description: {
    fontSize: '3vh',
    textAlign: 'justify',
    textJustify: 'inter-word'
  },
  
  submissionContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    width: '100%',
    marginBottom: '1rem'
  },

  filterContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginTop: '2rem',
    marginBottom: '3rem'
  },

  select: {
    width: '30vh',
  },

  cardBody: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },

  cardFooter: {
    marginTop: '1rem',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },

}));
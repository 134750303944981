import React from 'react'
import { useSelector } from 'react-redux'
import Header from '../../components/Header'
import DashAluno from '../../components/DashboardAluno'
import DashEmpresa from '../../components/DashEmpresa'
import { Container } from './styles'

function Dashboard() {
  const profile = useSelector(state => state.user.profile)
  if (profile) {
    return (
      <Container>{profile.cpf ? <DashAluno /> : <DashEmpresa />}</Container>
    )
  } else {
    return (
      <Container>
        <Header />
      </Container>
    )
  }
}

export default Dashboard

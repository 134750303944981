import React, { useEffect, useState } from 'react'
import Carousel from 'react-material-ui-carousel'
import { Paper, Button, styled, Box } from '@mui/material'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { makeStyles } from '@mui/styles'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'

import api from '../../services/api'

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 800,
      md: 960,
      lg: 1280,
      xl: 1920
    }
  }
})

const Barra = styled(Button)`
  padding: 1px;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: center;
  .titulo {
    color: white;
    width: 70vw;
    text-align: left;
  }
  .noticia {
    color: white;
  }
`

const ConteudoMob = styled('div')({
  display: 'flex',
  backgroundColor: '#4F69AA',
  padding: '0 5px'
})

const Butao = styled(Button)({
  border: '2px solid white',
  color: 'white',
  '&:hover': {
    backgroundColor: 'white',
    color: 'black',
    fontWeight: 'bold'
  }
})

export default function News(props) {
  const [posts, setPosts] = useState([])

  useEffect(() => {

    api
      .get('https://oxetech.al.gov.br/blog/wp-json/wp/v2/posts')
      .then(response => {
        setPosts(response.data.slice(0, 4))
      })
  }, [])

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: { xs: 'none', sm: 'flex' } }}>
        <Carousel
          fullHeightHover={false}
          sx={{
            width: '30vw',
            height: '50vh'
          }}
        >
          {posts.map((item, i) => (
            <ItemDesktop key={i} item={item}>
              {i}
            </ItemDesktop>
          ))}
        </Carousel>
      </Box>
      {/* -------- PARA MOBILE ------ */}
      <Box sx={{ display: { xs: 'flex', sm: 'none' } }}>
        <Carousel
          sx={{ width: '100vw', height: '5vh' }}
          indicators={false}
          animation="slide"
          navButtonsAlwaysInvisible={true}
        >
          {posts.map((item, i) => (
            <ItemMobile key={i} item={item} />
          ))}
        </Carousel>
      </Box>
    </ThemeProvider>
  )
}

function ItemDesktop(props) {
  const colors = ['#4C0027', '#041C32', '#46244C', '#2E0249']

  const ConteudoDesk = styled(Paper)({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    backgroundColor: colors[props.children],
    height: '100%',
    padding: '15px',
    '.titulo': {
      color: 'white',
      height: '10vh'
    }
  })

  return (
    <ConteudoDesk>
      <h2 className="titulo" style={{}}>
        {props.item.title.rendered}
      </h2>

      <p
        style={{
          fontSize: '1rem',
          color: 'white',
          height: '20vh'
        }}
      >
        {props.item.excerpt.rendered.replace(/<(?!br\s*\/?)[^>]+>/g, '')}
      </p>
      <div
        style={{
          height: '3rem'
        }}
      >
        <Butao href={props.item.link}>VER NOTÍCIA</Butao>
      </div>
    </ConteudoDesk>
  )
}

function ItemMobile(props) {
  return (
    <ConteudoMob>
      <Barra>
        <h4 className="titulo">{props.item.name}</h4>
        <h4 className="noticia">
          <u>Ver Notícia</u>
          <OpenInNewIcon sx={{ ml: '2px', mt: '2px' }} fontSize="50px" />
        </h4>
      </Barra>
    </ConteudoMob>
  )
}

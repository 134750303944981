// import { Card} from 'antd'
import { styled } from '@mui/material/styles'
import {Card, Grid} from '@mui/material';
import { Modal } from 'antd';



export const InfoAdd = styled(Grid)`
    h3{
        font-family: 'Karla', sans-serif;
        font-weight: bold;
        margin-top: 1%;
    }
    p, li{
        font-family: 'Poppins', sans-serif;
        font-weight: light;

    }
    ul{
        margin-left: 4%;
    }
  

`

export const ContainerGrid = styled(Grid)`
    


`
export const ModalCard = styled(Modal)`
    background-color: #ffffff;
    border-radius: 20px;
    padding: 0%;
    .ant-modal-wrap{
        width: 60vw;

    }
    .ant-modal-content{
        
        width: 100%;
    }
    .ant-modal-body{
        padding: 0 4%;
    }
    .horizontal{
        margin-top: 1.4%;
        margin-bottom: 1.4%;
        width: 100%;
        height: 0.12em;
        background: linear-gradient(90.07deg, rgba(22, 9, 179, 0.65) 7.23%, rgba(142, 36, 170, 0.65) 96.61%);



    }
`


export const OxeCard = styled(Card)`

    background-color: #ffffff;
    /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); */
    -webkit-box-shadow: -1px -4px 25px -12px rgba(168,168,168,1);
    -moz-box-shadow: -1px -4px 25px -12px rgba(168,168,168,1);
    box-shadow: -1px -4px 25px -12px rgba(168,168,168,1);
    border-radius: 15px;
    &:hover{
        animation: myAnim 0.5s ease 0s 1 normal forwards;
    }

    width: 100%;
    cursor: pointer;

    .horizontal{
        margin: 1.4%;
        width: 100%;
        height: 0.3vh;
        background: linear-gradient(90.07deg, rgba(22, 9, 179, 0.65) 7.23%, rgba(142, 36, 170, 0.65) 96.61%);

    }

    @keyframes myAnim {
	0% {
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
		transform: scale(1);
	}

	100% {
		box-shadow: 0 0 20px 0px rgba(0, 0, 0, 0.35);
		transform: scale(1.02);
	}
}

`

export const Title = styled('div')`

    display: flex;
    justify-content: space-around;
    align-items: center;

    h1 {
        font-family: 'Poppins', sans-serif;
        font-weight: 30px;
        text-transform: capitalize;
        font-size: 1.7em; 
        /* font-size: 300%; */
        word-break: break-word;
    }

    h5 {
        font-family: 'Poppins', sans-serif;
        color: #525252;
        text-align: center;
        text-transform: capitalize;
    }

    .foto{
        width: 8em;
        height: 8em;

        @media (max-width: 700px){
            width: 5em;
            height: 5em;
        }
    }

    .avatar-place{
        width: 12em; 
        height: 12em;
        @media (max-width: 700px) {
            width: 8em; 
            height: 7em;
            margin-right: 5% ;
        }
    }
    .avatar {
        background: rgba(22, 9, 178, 0.71);
        width: 100%;
        height: 100%; 

    }
    
    
`

export const VagaName = styled('div')`
    width: 70%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    h1{
        font-size: 1.53em;
        text-transform: capitalize;
        @media (max-width: 700px) {
            font-size: 1.4em;
            
        }
    }
    
    h5{
        text-transform: capitalize;

    }
    
`
export const Labels = styled('div')`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 2%;
    .porcentagem{
        font-family: 'Poppins', sans-serif;
        color: #22DFB2;
        font-weight: bold   ;
    }
    
    `
export const Info = styled('div')`
    margin-top: 4%;
    margin-left: 2%;
    margin-bottom: 5%;
    
    h5{
        font-family: 'Poppins', sans-serif;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.8);
    }
    
    `
    export const Submit = styled('div')`
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-top: 4%;
        /* margin-left: 2%; */
        
        .butao{
            background: linear-gradient(107.49deg, #1609B3 -47.43%, rgba(87, 23, 174, 0.758865) 64.08%, rgba(142, 36, 170, 0.55) 176.49%);

            border-radius: 5px;
            color: #fff;
            font-family: 'Poppins', sans-serif;
            &:hover{
                background: linear-gradient(93.12deg, #1609B3 26.82%, rgba(87, 23, 174, 0.758865) 92.03%, rgba(142, 36, 170, 0.55) 106.04%);

            }
            
        }
        .lastinfo{
            width: 50%;
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            align-items: flex-end;
        }
        .encerramento{
            font-family: 'Poppins', sans-serif;
            font-weight: 300;
            margin-right: 5%;
        }
      
        
        `

export const ModalConfirm = styled(Modal)`
    .ant-modal-body{
        display: flex;
        justify-content:center;
        flex-direction:column;
        align-items: center;
        .option{
            width: 80%;
        }
        h3{
            margin-top: 2%;
            font-family: "Poppins", sans-serif;
            color: rgba(0, 0, 0, 0.8);
            text-align: center;
        }
        h5{
            font-family: "Poppins", sans-serif;
            color: rgba(0, 0, 0, 0.8);
            font-weight: 300;
            @media (max-width: 700px) {
                font-size: 0.6em;
            }
        }
        
        h4{
            color: #1609B2;
        }
        .butoes{
            margin-top: 5%;
            width: 100%;
            display: flex;
            justify-content: space-around;   
        }

        .butao{
            background: linear-gradient(107.49deg, #1609B3 -47.43%, rgba(87, 23, 174, 0.758865) 64.08%, rgba(142, 36, 170, 0.55) 176.49%);
            border-radius: 5px;
            color: #fff;
            font-family: 'Poppins', sans-serif;
            &:hover{
                background: linear-gradient(93.12deg, #1609B3 26.82%, rgba(87, 23, 174, 0.758865) 92.03%, rgba(142, 36, 170, 0.55) 106.04%);
            }
            
        }
        
        .butaoCancelar{
            border-radius: 5px;
            background-color: #FFFFFF;
            /* border-color: #6B85C2; */
            color: #6B85C2;
            border: 1px solid;
            &:hover{
                color: #8E24AA;

        }        
        
    }
    }
`

export const NoVagas = styled('div')`
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    @media (max-width: 700px) {
        img{
            width: 15%;
        }
        h1{
            font-size: 0.8em;
            text-align: center;
        }
    }
`
export const Search = styled('div')`
    display: flex;
    justify-content: center;
    margin-bottom: 3%;
    width: 100%;
    /* img{
        /* width: 3%;
        @media (max-width: 700px) {
            
        }  */
    .input{
        background: rgba(79, 105, 170, 0.19);
        border-radius: 10px;
        border: 1px solid #4F69AA;
        width: 80%;
        margin-left: 2%;
        padding: 1%;
        color: rgba(0, 0, 0, 1);
        font-family: 'Poppins', sans-serif;
        font-size: 1em;
        line-height: 1%;
        
    }

    input:focus {
        border-color: #8E24AA;
    }
    input::placeholder {
        color: #00000078;
    }

`



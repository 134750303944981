import * as React from 'react'
import CssBaseline from '@mui/material/CssBaseline'
import { makeStyles } from '@mui/styles'
import Box from '@mui/material/Container'
import { styled } from '@mui/material/styles'
import { Link } from 'react-router-dom'
import { Result } from 'antd'

const WhiteBox = styled(Box)`
  width: 50vw;
  /* max-width: 400px; */
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  border-radius: 0.5rem;
  color: black;
  overflow: hidden;
  font-size: 20px;
  text-align: center;
  background: rgba(255, 255, 255, 0.89);
  padding: 20px;
`

const ColorButton = styled(Link)(({ theme }) => ({
  color: '#000',
  height: 'auto',
  backgroundColor: '#ffff',
  padding: '10px 20px',
  textDecoration: 'none',
  borderRadius: '5px',
  '&:hover': {
    backgroundColor: 'rgba(116, 31, 138, 0.4)',
    color: 'white'
  }
}))

const useStyles = makeStyles(theme => ({
  root: {
    overflow: 'hidden',
    background: 'linear-gradient(-90deg, rgba(246,147,28,1) 0%, rgba(240,94,35,1) 50%)',
    minHeight: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  paperContainer: {
    backgroundImage: `url(/assets/backgroundSelect.png)`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: -1
  }
}))

export default function ForgotPassword() {
  const classes = useStyles()
  return (
    <React.Fragment>
      <CssBaseline />
      <div className={classes.root}>
        <div className={classes.paperContainer} />
        {/* <WhiteBox> */}
          <Result
            status="404"
            title={<strong style={{color: 'white'}}>404</strong>}
            subTitle={<span style={{color: 'white'}}>Ops! Página não encontrada.</span>}
            extra={[
              <ColorButton
                to="/"
                key="home"
              >
                Início
              </ColorButton>
            ]}
          />
        {/* </WhiteBox> */}
      </div>
    </React.Fragment>
  )
}

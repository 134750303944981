import React from "react";
import logoOxetechRoxo from '../../../assets/logoOxetechRoxo.svg'
import {Grid, Button} from '@mui/material';
import {ModalEmpresa} from './styles'
import info from "../../../assets/ModalFirst/info.svg"
import { Link } from 'react-router-dom';

import PersonIcon from '../../../assets/iconesOxeWork/person.svg';
import MenuBookIcon from '../../../assets/iconesOxeWork/book.svg';
import WorkIcon from '../../../assets/iconesOxeWork/job.svg';
import DownloadIcon from '../../../assets/iconesOxeWork/download.svg';
import mala from '../../../assets/iconesOxeWork/mala.svg'

export default function ModalFirst (props){

    return(
        <ModalEmpresa open={props.open} onCancel={props.close} footer={false} width="100vw">
            <div className="header">
                <img src={logoOxetechRoxo} alt="logoOxetechRoxo" className="logo" />
                <h2>Olá! Bem-vindo(a) à plataforma OxeTech!</h2>
            </div>
            <Grid container spacing={2}>
                <Grid item xs={12} md={12} className="info" >
                    <img src={info} alt="info" style={{marginRight: '2%'}} />
                    <h3>Ser usuário da plataforma OxeTech é o primeiro passo para crescer em sua carreira em tecnologia, e estamos muito felizes por tê-lo(a) a bordo! Nosso objetivo é apoiá-lo(a) em todas as etapas do processo, desde a formação do seu perfil até a candidatura a vagas de emprego.</h3>
                </Grid>
                <Grid item xs={12} md={12} className="info" >
                    <img src={info} alt="info" style={{marginRight: '2%'}} />
                    <div>
                        <h3>Conheça as funcionalidades</h3>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={12}  className="botoes">
                                <div style={{display: 'flex', justifyContent:'start', alignItens: 'center'}}>    
                                    <img src={PersonIcon} alt="mala" style={{marginRight: '2%'}} />
                                    <h3>Sobre mim</h3>
                                </div>
                                <h4>Nesta seção, você pode adicionar informações sobre si mesmo(a), suas habilidades, formações, idiomas, experiência e cursos que já fez. As informações que você adicionar aparecerão no seu currículo, que pode ser visualizado pelas empresas interessadas.</h4>
                            </Grid>
                            <Grid item xs={12} md={12} className="botoes">
                                <div style={{display: 'flex', alignContent: 'center'}}>  
                                    <img src={MenuBookIcon} alt="mala" style={{marginRight: '2%'}} />
                                    <h3>Cursos</h3>
                                </div>
                                <h4>Com a nossa parceria com a plataforma da DIO, você pode investir no seu conhecimento em tecnologia. Temos uma grande variedade de cursos para atender às suas necessidades de aprendizado.</h4>
                            </Grid>
                            <Grid item xs={12} md={12} className="botoes">
                                <div style={{display: 'flex', alignContent: 'center'}}>    
                                <img src={WorkIcon} alt="mala" style={{marginRight: '2%'}} />
                                    <h3>OxeDin</h3>
                                </div>
                                <h4>Oxedin é o programa que procura aproximar empresas recrutadoras com os candidatos. Aqui você pode se candidatar em alguma vaga de emprego e seu currículo será enviado automaticamente para a empresa.</h4>
                                <h4>Para se candidatar:</h4>
                                <ol>
                                    <li>Você pode se candidatar em uma das vagas do Programa OxeDin, é só acessar a aba <Link to='/oxedin' style={{color: '#6B85C2', textDecoration:'underline'}}>OxeDin</Link></li>
                                    <li>Você precisará informar o tempo de experiência naquela vaga.</li>
                                    <li>Seu currículo gerado pela plataforma será enviado automaticamente para a empresa recrutadora.</li>
                                </ol>
                            </Grid>
                            <Grid item xs={12} md={12} className="botoes">
                            <div style={{display: 'flex', alignContent: 'center'}}>    
                                <img src={mala} alt="mala" style={{marginRight: '2%'}} />
                                    <h3>OxeTech Work</h3>
                                </div>
                                <h4>É um programa governamental que integra a capacitação de alunos com vagas de trabalho no mercado tecnológico.</h4>
                                <h4>Para que você consiga se inscrever no <Link to='/oxe-tech-work' style={{color: '#1609B2', textDecoration:'underline'}}>OxeTech Work</Link> , você precisará seguir o seguinte fluxo:</h4>
                                <ol>
                                    <li>Você precisa ter 18+ anos.</li>
                                    <li>Acesse a aba do programa no menu lateral.</li>
                                    <li>Realize sua inscrição no edital através do botão "Inscreva-se" na aba de Edital Aberto.</li>
                                </ol>
                                <h4>Para se candidatar:</h4>
                                <ol>
                                    <li>Você já poderá se candidatar em uma das vagas do Programa OxeTech Work, é só acessar a aba “vagas” na página do <Link to='/oxe-tech-work' style={{color: '#1609B2', textDecoration:'underline'}} >OxeTech Work</Link></li>
                                    <li>Você precisará informar o tempo de experiência naquela vaga.</li>
                                    <li>Seu currículo gerado pela plataforma será enviado automaticamente para a empresa recrutadora.</li>
                                </ol>

                            </Grid>
                            <Grid item xs={12} md={12} className="botoes">
                                <div style={{display: 'flex', alignContent: 'center'}}>    
                                <img src={DownloadIcon} alt="mala" style={{marginRight: '2%'}} />
                                    <h3>Exportar Currículo</h3>
                                </div>                        
                                <h4>Aqui você consegue baixar o seu currículo em formato de PDF e visualizar o Currículo OxeTech.   </h4>
                            </Grid>
                        </Grid>
                    </div>
                </Grid>
                <Grid item xs={12} md={12} sx={{display: 'flex', justifyContent:'center', marginBottom: '2%'}}>
                    <h5 style={{ fontSize:'1.1em'}}>Estamos sempre à disposição para ajudá-lo(a) em sua jornada na plataforma OxeTech. Se você tiver alguma dúvida ou precisar de ajuda em qualquer momento, não hesite em entrar em contato conosco pelo email <u color='red'>oxetech@secti.al.gov.br</u>.</h5>
                </Grid>
                <div style={{width: '100%', display: "flex", justifyContent: 'flex-end'}}>
                    <Button variant="contained" className="botao" onClick={props.close}>Vamos lá</Button>
                </div>
            </Grid>
            
      </ModalEmpresa>
    )

}
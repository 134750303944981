import React, { useEffect, useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import InsightsIcon from "@mui/icons-material/Insights";
import {
  Typography,
  Tooltip,
  Snackbar,
  Alert,
} from "@mui/material";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import { Link, useParams } from "react-router-dom";
import * as palette from "../../../../utils/styledVariables";
import { Container, Title } from "./styles";
import ModalEditTurma from "../Modais/ModalEditTurma";
import api from "../../../../services/api";
const styles = {
  text: {
    fontSize: "16pt",
    fontWeight: "300",
    fontStyle: "italic",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  popover: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
};
function CardTurmas(props) {
  const { labId } = useParams();
  const { turmas } = props;
  const [turmaId, setTurmaId] = useState(0);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openEditTurma, setOpenEditTurma] = useState(false);
  const [openCertificadoTurma, setOpenCertificadoTurma] = useState(false);
  const [numeroInscritos, setNumeroInscritos] = useState(0);
  const [error, setError] = useState("");

  const prefix = `/laboratorio/${labId}`;

  const handleCloseModal = () => {
    setOpenEditTurma(false);
  };

  const [anchorEl1, setAnchorEl1] = React.useState(null);
  const open = Boolean(anchorEl1);
  const handleClickMore = (event) => {
    setAnchorEl1(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl1(null);
  };

  useEffect(() => {
    if (!openEditTurma) {
      setAnchorEl1(null);
    }
  }, [openEditTurma]);

  // const handleEncerrarTurma = () => {
  //   api
  //     .get(`turma/encerrar/${turmas.id}`)
  //     .then((res) => {
  //       window.location.reload();
  //     })
  //     .catch((error) => {
  //       setError(error.response.data.error);
  //     });
  // };
  useEffect(() => {
    api
      .get(`/inscricoes-oxetechlab/turma/${turmas.id}`)
      .then((response) => {
        setNumeroInscritos(response.data.length);
      })
      .catch((error) => {
        console.log(error.message);
      });
  }, []);
  return (
    <Card
      sx={{
        backgroundColor: "white",
        position: "relative",
        borderRadius: "10px",
      }}
    >
      <Container>
        <Title>{turmas.titulo}</Title>
      </Container>
      <CardContent sx={{ height: "42%" }}>
        <Typography
          variant="body2"
          color="rgba(0, 0, 0, 0.67)"
          sx={styles.text}
        >
          {turmas.status !== "1" ? (
            <Typography
              variant="body2"
              color="rgba(0, 0, 0, 0.67)"
              sx={styles.text}
            >
              Início:{" "}
              {turmas.data_inicio.slice(8, 10) +
                "/" +
                turmas.data_inicio.slice(5, 7) +
                "/" +
                turmas.data_inicio.slice(0, 4)}
            </Typography>
          ) : (
            <Typography
              variant="body2"
              color="rgba(0, 0, 0, 0.67)"
              sx={styles.text}
            >
              Nº de inscritos: {numeroInscritos}
            </Typography>
          )}
        </Typography>
        {turmas.status !== "1" ? (
          <Typography
            variant="body2"
            color="rgba(0, 0, 0, 0.67)"
            sx={styles.text}
          >
            Encerramento:{" "}
            {turmas.data_encerramento.slice(8, 10) +
              "/" +
              turmas.data_encerramento.slice(5, 7) +
              "/" +
              turmas.data_encerramento.slice(0, 4)}
          </Typography>
        ) : (
          <Typography
            variant="body2"
            color="rgba(0, 0, 0, 0.67)"
            sx={styles.text}
          >
            Inscrições até:{" "}
            {turmas.data_encerramento_inscricoes.slice(8, 10) +
              "/" +
              turmas.data_encerramento_inscricoes.slice(5, 7) +
              "/" +
              turmas.data_encerramento_inscricoes.slice(0, 4)}
          </Typography>
        )}
      </CardContent>
      <Divider variant="middle" />
      {turmas.status === "1" || turmas.status === "2" ? (
        <CardActions
          sx={{
            position: "relative",
            bottom: "0px",
            display: "flex",
            margin: "0",
          }}
        >
          <Button
            sx={{ color: palette.transparente, width: "50%" }}
            onClick={() => {
              setOpenEditTurma(true);
              setTurmaId(turmas.id);
            }}
          >
            <EditIcon sx={{ fontSize: "35px", color: "GrayText" }} />
          </Button>
          <Divider variant="middle" orientation="vertical" flexItem />
          <Tooltip
            title={
              turmas.status === "1" ? "Painel de Seleção" : "Painel Principal"
            }
          >
            <Link
              to={
                turmas.status === "1"
                  ? `${prefix}/turma-selecao-painel/${turmas.id}`
                  : `${prefix}/turma-iniciada-painel/${turmas.id}`
              }
              style={{ width: "50%" }}
            >
              <Button sx={{ width: "100%" }}>
                <InsightsIcon sx={{ fontSize: "35px", color: "GrayText" }} />
              </Button>
            </Link>
          </Tooltip>
        </CardActions>
      ) : (
        <CardActions
          sx={{
            position: "relative",
            bottom: "0px",
            display: "flex",
            margin: "0",
          }}
        >
          <Tooltip title="Painel Principal">
            <Link
              to={`${prefix}/turma-iniciada-painel/${turmas.id}`}
              style={{ width: "100%" }}
            >
              <Button sx={{ width: "100%" }}>
                <InsightsIcon sx={{ fontSize: "35px", color: "GrayText" }} />
              </Button>
            </Link>
          </Tooltip>
        </CardActions>
      )}
      <ModalEditTurma
        open={openEditTurma}
        closeEdit={() => handleCloseModal()}
        turmaId={turmaId}
      />

      <Snackbar
        open={error.length > 0}
        autoHideDuration={7000}
        onClose={() => setError("")}
      >
        <Alert
          variant="filled"
          onClose={() => setError("")}
          severity="error"
          sx={{ width: "100%" }}
        >
          {error}
        </Alert>
      </Snackbar>
    </Card>
  );
}

export default CardTurmas;

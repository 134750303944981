import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import { makeStyles } from "@mui/styles";
import Box from "@mui/material/Container";
import { useSelector, useDispatch } from "react-redux";
import { Typography } from "@mui/material";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import background from "../../assets/backgroundSelect.png";
import logo_oxe from "../../assets/logo_oxeTechBlue.png";
import { useParams } from "react-router-dom";
import { Input, Form, message, Select } from "antd";
import Options from "../../utils/options";
import { Content } from "./styles";

import { finishProfileRequest } from "../../store/modules/user/actions";

const WhiteBox = styled(Box)`
  width: 30%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  padding: 30px;
  .img-resp{
    @media (max-width: 700px) {
      width: 50%;
  }
  }
  border-radius: 0.5rem;
  color: black;
  overflow: auto;
  font-size: 20px;
  text-align: center;
  background: rgba(255, 255, 255, 89%);
  animation: fadeIn;
  animation-duration: 1s;
  @media (min-width: 1600px) {
    width: 600px;
    
  }
  
  @media (max-width: 700px) {
    width: 80%;
    padding: 2%;
  }
  @media (max-width: 360px) {
    width: 90%;
    padding: 2%;

  }
`;

const ColorButton = styled(Button)(({ theme }) => ({
  color: "#fff",
  heith: "2vh",
  backgroundColor: "#741f8a",
  "&:hover": {
    backgroundColor: "#6B85C2",
  },
}));

const UseStyles = makeStyles((theme) => ({
  root: {
    overflow: "hidden",
    display: "flex",
    // flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    background:
      "linear-gradient(180deg,rgba(116, 31, 138, 0.9) 35%,rgba(0, 0, 99, 1) 100%)",
    height: "100vh",
  },
}));

export default function SignUpFinished() {
  const id_hash = useParams().id;
  const profile = useSelector((state) => state.user.profile);
  const dispatch = useDispatch();

  const onFinish = async (data) => {
    try {
      dispatch(
        finishProfileRequest({
          id_hash: id_hash,
          validationData: {
            sobre_mim: data.sobre_mim,
            foco_carreira: data.foco_carreira,
            ocupacao: data.ocupacao,
          },
        })
      );

      if (profile) {
        profile.sobre_mim = data.sobre_mim;
        profile.ocupacao = data.ocupacao;
        profile.foco_carreira = data.foco_carreira;
      }
    } catch (error) {
      message.error(`${error.response.data.error}`);
    }
  };

  const classes = UseStyles();
  return (
    <React.Fragment>
      <CssBaseline />
      <div className={classes.root}>
        <div sx={{ position: "absolute", overflow: "hidden" }}>
          <img
            src={background}
            alt="Background"
            style={{
              height: "100%",
            }}
          />
        </div>
        <WhiteBox>
          <Content>
            <img
              className="img-resp"
              src={logo_oxe}
              alt="Logo OxeTechLab"
            ></img>
            <Typography
              variant="h4"
              component="div"
              gutterBottom
              sx={{
                mt: 4,
                mb: 2,
                fontSize: "25px",
              }}
            >
              Finalize seu cadastro
            </Typography>
            <Form
              layout="vertical"
              name="basic"
              initialValues={{
                remember: true,
              }}
              onFinish={onFinish}
            >
              <Form.Item
                label="Sobre mim:"
                name="sobre_mim"
                rules={[
                  {
                    required: true,
                    message: "Campo obrigatório!",
                  },
                ]}
              >
                <Input.TextArea style={{ width: "95%" }} />
              </Form.Item>
              <Form.Item
                label="Foco de Carreira:"
                name="foco_carreira"
                rules={[
                  {
                    required: true,
                    message: "Campo obrigatório!",
                  },
                ]}
              >
                <Select
                  placeholder="Selecione seu foco de carreira"
                  style={{ width: "95%" }}
                  options={Options.optionsCarrer}
                ></Select>
              </Form.Item>

              {/* <Form.Item name="skills" label="Habilidades:">
                <Select
                  mode="multiple"
                  placeholder="Selecione suas habilidades"
                  style={{ width: '95%' }}
                  options={Options.optionsSkills}
                ></Select>
              </Form.Item> */}

              <Form.Item
                label="Ocupação:"
                name="ocupacao"
                rules={[
                  {
                    required: true,
                    message: "Campo obrigatório!",
                  },
                ]}
              >
                <Select
                  placeholder="Selecione sua ocupação"
                  style={{ width: "95%" }}
                  options={Options.ocupation}
                ></Select>
              </Form.Item>

              <Form.Item>
                <ColorButton block type="primary" htmlType="submit">
                  Finalizar
                </ColorButton>
              </Form.Item>
            </Form>
          </Content>
        </WhiteBox>
      </div>
    </React.Fragment>
  );
}

import React, { useEffect, useState } from "react";
import logo from "../../../../assets/logo.svg";
import {
  Grid,
  Button,
  Alert,
  Snackbar,
  Autocomplete,
  TextField,
  ThemeProvider,
  createTheme,
} from "@mui/material";
import { ModalFirst, SelectSchool } from "./styles";
import { Link } from "react-router-dom";
import api from "../../../../../services/api";
import { SnackbarProvider, useSnackbar } from "notistack";
import * as palette from "../../../../../utils/styledVariables.js";
import { useHistory } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";

const theme = createTheme({
  palette: {
    primary: {
      main: "rgba(212, 109, 13, 1)",
    },
  },
});
export default function ModalFirstTimeOxd(props) {
  const [schoolId, setSchoolId] = useState();
  const [profileId, setProfileId] = useState();
  const [alertSuccessful, setAlertSuccessful] = useState(false);
  const [alertFailed, setAlertFailed] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [schools, setSchools] = useState([]);
  const history = useHistory();
  // console.log(props.matricula)
  const emAnalise =
    props.matricula !== ""
      ? props.matricula.find((matricula) => matricula.status === "0")
      : null;

  useEffect(() => {
    const fetchSchools = async () => {
      try {
        setProfileId(props.profile.id);
        const response = await api.get(`/oxetechedu/escola/`);

        const escolasFormatadas = response.data.map((escola) => ({
          value: escola.id,
          label: escola.nome,
        }));

        setSchools(escolasFormatadas);
      } catch (error) {
        console.error("Erro ao obter dados das escolas:", error);
      }
    };

    fetchSchools();
  }, [props.profile.id]);

  const cancelarMatricula = async () => {
    await api
      .delete(`/oxetechedu/matricula/?id=${emAnalise.id}`)
      .then((response) => {
        enqueueSnackbar(response.data, { variant: "success" });
        window.location.reload();
      })
      .catch((error) => {
        console.log(error.data);
      });
  };

  const onFinish = async (data) => {
    try {
      const response = await api.post(
        `/oxetechedu/matricula/criar/${schoolId}`,
        {
          userId: profileId,
          escola_id: schoolId,
        }
      );

      setAlertSuccessful("Matrícula bem-sucedida");
      enqueueSnackbar("Matrícula bem-sucedida!", { variant: "success" });
      props.close();
      window.location.reload();
    } catch (err) {
      setAlertFailed("Erro ao realizar matrícula");
      enqueueSnackbar("Erro ao realizar matrícula!", { variant: "error" });
      if (err.response.data === "SCHOOL_DOES_NOT_EXIST") {
        console.log("Escola não Existe");
      } else {
        console.log(err.response.data);
      }
    }
  };

  const componenteNaoMatriculado = () => (
    <ThemeProvider theme={theme}>
      <div className="caixa">
        <div className="header">
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img src={logo} alt="logoOxetechRoxo" className="logo" />
          </div>
          <h2 style={{ marginBottom: "1%" }}>Selecione a sua escola</h2>
        </div>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <Autocomplete
              disablePortal
              options={schools}
              color="primary"
              onChange={(event, value) => {
                setSchoolId(value.value);
              }}
              sx={{ width: "100%" }}
              renderInput={(params) => (
                <TextField {...params} label="Pesquise o nome da escola" />
              )}
            />
          </Grid>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              // flexDirection: "column",
              alignItems: "center",
              width: "100%",
              marginTop: "2%",
              
            }}
          >
            <Link
              to="/dashboard"
              style={{ color: "#f05e23", fontWeight: "bold", textDecoration: "underline", marginLeft: '4%' }}
            >
              Voltar ao meu perfil
            </Link>
            <Button color="primary" variant="contained" onClick={onFinish}>
              Se inscrever
            </Button>
            
          </div>
        </Grid>
      </div>
    </ThemeProvider>
  );

  const componenteMatriculaEmAnalise = () => (
    <ThemeProvider theme={theme}>
      <div className="caixa">
        <div className="header">
          <img src={logo} alt="logoOxetechRoxo" className="logo" />
          <h2>Sua matrícula está em análise</h2>
          <h4>
            Você será notificado por e-mail e receberá uma notificação assim que
            sua matrícula for verificada. Por favor, aguarde.{" "}
          </h4>
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              width: "80%",
              marginTop: "2%",
            }}
          >
            <Button
              color="primary"
              variant="contained"
              onClick={() => history.push("/dashboard")}
            >
              Meu Perfil
            </Button>
            <Button
              color="error"
              variant="outlined"
              onClick={cancelarMatricula}
            >
              Cancelar Matrícula
            </Button>
          </div>
        </div>
      </div>
    </ThemeProvider>
  );
  return (
    <ModalFirst
      open={props.open}
      width="100vw"
    >
      {props.matricula.length === 0 || !emAnalise?.id
        ? componenteNaoMatriculado()
        : emAnalise && componenteMatriculaEmAnalise()}
    </ModalFirst>
  );
}

import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import { SnackbarProvider, useSnackbar } from 'notistack';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateProfileRequest } from '../../../store/modules/user/actions';

function Name() {
    const { enqueueSnackbar } = useSnackbar();
    const profile = useSelector(state => state.user.profile);
    const [newName, setNewName] = React.useState(
        profile.nome_social ? profile.nome_social : profile.name
    );
    const [originalName, setOriginalName] = React.useState('');
    const [dashedState, setDashedState] = React.useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        setOriginalName(profile.nome_social ? profile.nome_social : profile.name);
    }, [])

    function saveName() {
        if (newName.length < 5) {
            enqueueSnackbar('5 caracteres no mínimo!', { variant: 'error' })
            return;
        }
        if (profile.nome_social) {
            profile.nome_social = newName.trim();
            dispatch(updateProfileRequest(profile));
        }
        else {
            profile.name = newName.trim();
            dispatch(updateProfileRequest(profile));
        }
    }

    function capitalizeWords(arr) {
        return arr.split(" ").map(element => {
            return element.charAt(0).toUpperCase() + element.slice(1).toLowerCase();
        }).join(" ");
    }

    function validateNewName(name) {
        profile.dataChanged = true;
        name = name.replaceAll('  ', ' ');
        if (name === originalName){
            return false;
        }
        if (/\d/.test(name)) {
            enqueueSnackbar('Números não são permitidos!', { variant: 'error' })
            return false;
        }
        if (/[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(name)) {
            enqueueSnackbar('Caracteres especiais não são permitidos!', { variant: 'error' })
            return false;
        }
        name = capitalizeWords(name.replace(/\[\d+\]/g, ""));
        setNewName(name);
        
        if (profile.nome_social) {
            profile.nome_social = name;
        }
        else {
            profile.name = name;
        }
        
        return true;
    }

    return (
        <Box sx={{
            mt: 1,
            mx: 1.5,
            mb: 0.5,
            borderRadius: '5px',
            bgcolor: "#f27420",//'#741F8A',
            boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)'
        }}>
            <Tooltip
                arrow
                placement="top"
                title={'Clique para editar'}
            >
                <input
                    style={{
                        border: dashedState ? '1px dashed pink' : 'none',
                        width: '100%',
                        backgroundColor: 'transparent',
                        color: 'white',
                        textAlign: 'center',
                        fontWeight: 'bold',
                        fontStyle: 'italic',
                        fontFamily: 'Roboto',
                        textOverflow: 'ellipsis',
                        paddingLeft: '10px',
                        paddingRight: '10px'
                    }}
                    type="text"
                    id="name"
                    value={newName}
                    onChange={(e) => { validateNewName(e.target.value) }}
                    onClick={() => { document.getElementById('name').select(); setDashedState(true); }}
                    onKeyPress={(e) => {
                        if (e.charCode === 13) {
                            e.preventDefault();

                            if (validateNewName(e.target.value)) {
                                saveName();
                            }
                        }
                    }}
                    onBlur={() => {setDashedState(false);}}
                />
            </Tooltip>
        </Box>
    );
}

export default function IntegrationNotistack() {
    return (
      <SnackbarProvider maxSnack={3}>
        <Name />
      </SnackbarProvider>
    );
  }
import React from 'react'
import { Container } from './styles'
import 'animate.css'
import Header from '../../components/HeaderOxeTechWork'
import Inscrevase from './Inscrevase'
import Introducao from './Introducao'
import QuemEstaContratando from './QuemEstaContratando'
import QuerSaberMais from './QuerSaberMais'
import Rodape from '../../components/Rodape'

import { createTheme, ThemeProvider } from '@mui/material/styles'
const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 800,
      md: 960,
      lg: 1280,
      xl: 1920
    }
  }
})

function Main() {
  return (
    <ThemeProvider theme={theme}>
      <Container>
        <Header />
        <Introducao />
        <Inscrevase />
        <QuemEstaContratando />
        <QuerSaberMais />
        <Rodape />
      </Container>
    </ThemeProvider>
  )
}

export default Main

import styled from 'styled-components'
import Box from '@mui/material/Box';

export const Content = styled(Box)`
    background: #fff;
    width: 80%;
    height: 90vh;
    overflow: auto;
    @media (max-width: 760px){
        width: 90%;
    } 
    .header{
        padding: 1%;
        display: flex;
        justify-content: space-between;
        height: 70px;
        background: linear-gradient(127.29deg, #D56D0D -46.23%, #1609B3 227.66%);
        position: relative;
        h1{
            color: white;
            margin-left: 15px;
        }
    }
    .input{
        color:#fff;
        width: 80%;
        font-size: 2em;
        border: none;
        @media (max-width: 760px){
            font-size: 1.5em;
        } 
    }
    .close{
        cursor: pointer; 
        margin-right: 2%;
        width: 3%;
        @media (max-width: 760px){
            width: 10%;
        } 
    }
    .ant-input:focus{
        box-shadow: none;
    }
    .label{
        font-family: 'Roboto', sans-serif;
        font-size: 1.3em;
    }
    .area{
        border: 1px solid rgb(0,0,0,0.4);
        border-radius: 15px;
        margin-bottom: 2%;
    }
    .titulo{
        font-size: 2em;
        color: rgb(0,0,0,0.6);
        font-family: 'Karla', sans-serif;
        margin: 0;
        padding: 1%;
        padding-bottom: 0;
        @media (max-width: 760px){
            font-size: 1.3em;
            text-align: center;
        } 
    }
    .button{
        background-color: rgba(116, 31, 138, 1); 
        color: #fff;
    }
    .button:hover{
        background-color: rgba(116, 31, 138, 0.7); 
    }
    
`


import styled from 'styled-components'
import { Tabs } from 'antd';

export const TabsCursos = styled(Tabs)`
  .ant-tabs-tab-btn{
    color: rgba(212, 109, 13, 1);
    font-family: 'Karla', sans-serif;
    font-weight: 700;
    font-size: 1.4em;
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
   color: rgba(212, 109, 13, 1) !important; 
   font-weight: 700;
  }
  .ant-tabs-ink-bar {
   position: absolute;
   background: rgba(212, 109, 13, 1);
   pointer-events: none;
  }
  .ant-tabs-nav-wrap{
    border: 1px solid white !important;
  }

`

export const Add = styled('div')`
  display: flex;
  /* position: relative; */
  /* height: 7%; */
  width: auto;
  justify-content: flex-start;
  margin-bottom: 1%;

  background: 'white';
  border-radius: 10px;

  @media(max-width: 900px) {
    height: 7vh;
    margin-bottom: 2%;
  }

`


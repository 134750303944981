import React, { useEffect, useState, useMemo } from "react";

import {
  OxeCard,
  LinhaHorizontal,
  VagaName,
  Labels,
  Capa,
  Submit,
  ContainerGrid,
  GridCard,
  ModalCard,
  InfoAdd,
  ModalConfirm,
  NoVagas,
  Search,
} from "./styles";
import {
  CardContent,
  Grid,
  Avatar,
  Alert,
  Button,
  Snackbar,
  FormHelperText,
  Typography,
  CardMedia,
  CardActions,
} from "@mui/material";
import { useSelector } from "react-redux";
import logo from "../../assets/homeTriangle/oxeTechLabLogoON.png";
import api from "../../services/api";
import history from "../../services/history";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import TextField from "@mui/material/TextField";

// icones

import capa from "../../assets/iconesOxeLab/capa.svg";
import homem from "../../assets/iconesOxeLab/homem.svg";
import local from "../../assets/iconesOxeLab/local.svg";
import search from "../../assets/iconesOxeLab/search.svg";
import noFolder from "../../assets/iconesOxeLab/noFolder.svg";
import noResults from "../../assets/iconesOxeLab/noResults.svg";
import CircularProgress from "@mui/material/CircularProgress";
import { parseISO, addDays, isAfter, isSameDay } from "date-fns";

function VagasOXL() {
  const signed = useSelector((state) => state.auth.signed);

  const [turmas, setTurmas] = useState([]);
  const [open, setOpen] = useState(false);
  const [id, setId] = useState(-1);
  const [confirm, setConfirm] = useState(false);
  const [candidatar, setCandidatar] = useState(false);
  const [anos, setAnos] = useState(-1);
  const [error, setError] = useState(false);
  const [alertExists, setAlertExists] = useState(false);
  const [alertSuccessful, setAlertSuccessful] = useState("");
  const [alertUnauthorized, setAlertUnauthorized] = useState("");
  const [busca, setBusca] = useState("");
  const [instrutores, setInstrutores] = useState(null);
  const [loading, setLoading] = useState(false);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    if (signed) {
      api
        .get("turma/disponiveis-private")
        .then(async (response) => {
          const turmasData = response.data.filter((turma) => {
            const encerramentoInscricoes = parseISO(
              turma.data_encerramento_inscricoes
            );
            const dataAtual = new Date();
            return (
              isAfter(encerramentoInscricoes, dataAtual) ||
              isSameDay(encerramentoInscricoes, dataAtual)
            );
          });

          const turmasComInstrutores = await Promise.all(
            turmasData.map(async (turma) => {
              try {
                const res = await api.get(
                  `/instrutor/by-turma?turmaId=${turma.id}`
                );
                return { ...turma, instrutor: res.data };
              } catch (error) {
                console.log("Erro ao buscar instrutor:", error);
                return { ...turma, instrutor: null };
              }
            })
          );
          setTurmas(turmasComInstrutores);
        })
        .catch((err) => {
          console.log(err.response.data.error);
        });
    } else {
      api
        .get("turma/disponiveis")
        .then(async (response) => {
          const turmasData = response.data.filter((turma) => {
            const encerramentoInscricoes = parseISO(
              turma.data_encerramento_inscricoes
            );
            const dataAtual = new Date();
            return (
              isAfter(encerramentoInscricoes, dataAtual) ||
              isSameDay(encerramentoInscricoes, dataAtual)
            );
          });

          const turmasComInstrutores = await Promise.all(
            turmasData.map(async (turma) => {
              try {
                const res = await api.get(
                  `/instrutor/by-turma?turmaId=${turma.id}`
                );
                return { ...turma, instrutor: res.data };
              } catch (error) {
                console.log("Erro ao buscar instrutor:", error);
                return { ...turma, instrutor: null };
              }
            })
          );
          setTurmas(turmasComInstrutores);
        })
        .catch((err) => {
          console.log(err.response.data.error);
        });
    }
  }, [alertSuccessful]);

  const handleNaoLogado = () => {
    history.push("/select-cadastro");
  };
  const handleOpen = (id) => {
    setOpen(true);
    setId(id);
  };

  const handleClose = () => {
    setAlertSuccessful(false);
    setAlertExists(false);
    setAlertUnauthorized(false);
    setAnos(-1);
    setError(false);
    setOpen(false);
    setConfirm(false);
    setCandidatar(false);
    setId(-1);
  };

  const handleConfirmar = (e, i) => {
    setOpen(false);
    setConfirm(true);
    setId(i);
    e.stopPropagation();
  };

  const handleSubmit = async (data) => {
    if (signed === false) {
      handleNaoLogado();
    } else {
      setLoading(true);
      api
        .post(`/inscricoes-oxetechlab/turma/${turmas[id].id}`, data)
        .then((response) => {
          setAlertSuccessful(response.data.message);
          sessionStorage.removeItem(`resposta-${id}`);
          setLoading(false);
          setConfirm(false);
          setTimeout(() => {
            window.location.reload();
          }, 100);
        })
        .catch((error) => {
          sessionStorage.removeItem(`resposta-${id}`);
          setConfirm(false);
          setAlertUnauthorized(error.response.data.error);
        });
    }
  };

  const Confirmar = () => {
    const [respostas, setRespostas] = useState([]);

    const [errorRespostas, setErrorRespostas] = useState(
      Array(respostas.length).fill("")
    );
    const maxLength = 200;
    // const remainingChars = maxLength - respostas[index].length;

    const handleRespostaChange = (index, resposta) => {
      const novasRespostas = [...respostas];
      novasRespostas[index] = resposta;
      setRespostas(novasRespostas);
      sessionStorage.setItem(`resposta-${id}`, JSON.stringify(novasRespostas));

      const novosErros = [...errorRespostas];
      novosErros[index] = "";
      setErrorRespostas(novosErros);
    };

    const validar = () => {
      const novosErros = respostas.map((resposta, index) => {
        if (!resposta) {
          return "Adicione uma resposta.";
        }
        return "";
      });

      setErrorRespostas(novosErros);
      const todasRespostasPreenchidas = novosErros.every((erro) => erro === "");
      if (todasRespostasPreenchidas) {
        if (turmas[id] && turmas[id].perguntas) {
          const respostasFormatadas = turmas[id].perguntas.reduce(
            (acc, pergunta, index) => {
              acc[pergunta] = respostas[index];
              return acc;
            },
            {}
          );

          handleSubmit(respostasFormatadas);
        }
      }
    };

    return (
      <ModalConfirm
        open={confirm}
        closable={false}
        centered
        onCancel={handleClose}
        footer={null}
      >
        <img src={logo} className="img" alt="OxetechLab" width="40%" />
        {turmas[id]?.perguntas.map((pergunta, index) => (
          <div key={index} style={{ width: "100%" }}>
            <h3>{pergunta}</h3>
            <TextField
              multiline
              inputProps={{ maxLength: maxLength }}
              value={respostas[index]}
              error={errorRespostas[index] !== ""}
              helperText={errorRespostas[index]}
              // sx={{bgcolor: errorResposta ? "transparent": "rgba(212, 109, 13, 0.1)", }}
              onChange={(e) => handleRespostaChange(index, e.target.value)}
              size="large"
              fullWidth
            />
            <p>
              {respostas[index]
                ? `${
                    maxLength - respostas[index].length
                  }/${maxLength} caracteres restantes`
                : `${maxLength}/${maxLength}`}
            </p>
            {respostas[index] && respostas[index].length === maxLength && (
              <p style={{ color: "red" }}>
                Você atingiu o limite de caracteres.
              </p>
            )}
          </div>
        ))}
        <h5 style={{ textAlign: "center" }}>
          Ao se inscrever, sua inscrição ficará em análise e terá que ser
          validada pelo organizador.
        </h5>
        <div className="butoes">
          <Button
            startIcon={
              loading ? <CircularProgress color="inherit" size={20} /> : null
            }
            onClick={validar}
            className="butao"
          >
            Sim
          </Button>
          <Button
            onClick={handleClose}
            variant="outlined"
            className="butaoCancelar"
          >
            Cancelar
          </Button>
        </div>
      </ModalConfirm>
    );
  };

  const turmasSearch = useMemo(() => {
    const lowerSearch = busca.toLowerCase();
    const busca1 = (turma) => turma.titulo.toLowerCase().includes(lowerSearch);
    const titulo = turmas.filter(busca1);
    return titulo;
  }, [busca, turmas]);

  useEffect(() => {
    if (id !== -1) {
      api
        .get(`/instrutor/by-turma?turmaId=${turmas[id].id}`)
        .then((response) => {
          setInstrutores(response.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [id]);

  function capitalizeWords(str) {
    return str.toLowerCase().replace(/(?:^|\s)\S/g, function (firstLetter) {
      return firstLetter.toUpperCase();
    });
  }

  const CardExpandido = () => {
    if (id !== -1) {
      return (
        <>
          <ModalCard
            open={open}
            onCancel={handleClose}
            width={800}
            footer={null}
            closable={false}
          >
            <CardMedia
              component="img"
              alt="Capa Oxetech Lab"
              height="100"
              image={capa}
            />
            <CardContent sx={{ p: " 5% 0 " }} className="header">
              <div style={{ width: "60%" }}>
                <Typography
                  className="title"
                  gutterBottom
                  variant="h5"
                  component="div"
                >
                  {turmasSearch[id]?.titulo}
                </Typography>
                <LinhaHorizontal />
                <Typography
                  className="subtitle"
                  sx={{ fontSize: "1.5em" }}
                  gutterBottom
                  variant="h5"
                  component="div"
                >
                  {instrutores
                    ? "Prof. " +
                      capitalizeWords(turmasSearch[id]?.instrutor[0].name)
                    : ""}
                </Typography>
                <div style={{ marginTop: "5%" }}></div>
                {turmasSearch[id].data_resultado && (
                  <Typography
                    className="subtitle"
                    sx={{ fontSize: "1.2em" }}
                    gutterBottom
                    variant="h5"
                    component="div"
                  >
                    <strong>Resultado da seleção dos inscritos:</strong>{" "}
                    {turmasSearch[id].data_resultado.slice(8, 10) +
                      "/" +
                      turmasSearch[id].data_resultado.slice(5, 7) +
                      "/" +
                      turmasSearch[id].data_resultado.slice(0, 4)}
                  </Typography>
                )}
                <Typography
                  className="subtitle"
                  sx={{ fontSize: "1.2em" }}
                  gutterBottom
                  variant="h5"
                  component="div"
                >
                  <strong>Início:</strong>{" "}
                  {turmasSearch[id].data_inicio.slice(8, 10) +
                    "/" +
                    turmasSearch[id].data_inicio.slice(5, 7) +
                    "/" +
                    turmasSearch[id].data_inicio.slice(0, 4)}
                </Typography>

                <Typography
                  className="subtitle"
                  sx={{ fontSize: "1.2em" }}
                  gutterBottom
                  variant="h5"
                  component="div"
                >
                  <strong>Fim:</strong>{" "}
                  {turmasSearch[id].data_encerramento.slice(8, 10) +
                    "/" +
                    turmasSearch[id].data_encerramento.slice(5, 7) +
                    "/" +
                    turmasSearch[id].data_encerramento.slice(0, 4)}
                </Typography>
                <Typography
                  className="subtitle"
                  sx={{ fontSize: "1.2em" }}
                  gutterBottom
                  variant="h5"
                  component="div"
                >
                  <strong>Carga Horária:</strong>{" "}
                  {turmasSearch[id]?.carga_horaria}h
                </Typography>
              </div>
              <img className="img" src={homem} alt="Icone do OxetechLab" />
            </CardContent>
            <CardContent sx={{ p: " 2% 4% " }} className="section">
              <Typography variant="h5" component="div" className="sectionName">
                Descrição do curso
              </Typography>
              <Typography variant="body1" component="div" className="text">
                {turmasSearch[id]?.descricao}
              </Typography>
              <Typography variant="h5" component="div" className="sectionName">
                Informações adicionais
              </Typography>
              <Typography variant="body1" component="div" className="text">
                {turmasSearch[id]?.info_adicionais}
              </Typography>
              <Typography
                variant="h5"
                component="div"
                className="sectionName"
                sx={{ width: "15%", textAlign: "center" }}
              >
                Local
              </Typography>
              <Typography variant="body1" component="div" className="text">
                {turmasSearch[id]?.Laboratorio.rua},{" "}
                {turmasSearch[id]?.Laboratorio.bairro},{" "}
                {turmasSearch[id]?.Laboratorio.numero},{" "}
                {turmasSearch[id]?.Laboratorio.complemento !== ""
                  ? turmasSearch[id]?.Laboratorio.complemento + ", "
                  : ""}{" "}
                {turmasSearch[id]?.Laboratorio.municipio},{" "}
                {turmasSearch[id]?.Laboratorio.cep} - Alagoas
              </Typography>
            </CardContent>

            <Submit>
              {turmasSearch[id].inscricao_status ? (
                <Button className={"butaoInscrito"}>Inscrito</Button>
              ) : (
                <Button
                  onClick={(e) => handleConfirmar(e, id)}
                  className={"butaoNaoInscrito"}
                >
                  Inscrever-se
                </Button>
              )}
              <div className="lastinfo">
                <h5 className="encerramento">
                  {" "}
                  {"Inscrições até " +
                    turmasSearch[id].data_encerramento_inscricoes.slice(8, 10) +
                    "/" +
                    turmasSearch[id].data_encerramento_inscricoes.slice(5, 7) +
                    "/" +
                    turmasSearch[id].data_encerramento_inscricoes.slice(
                      0,
                      4
                    )}{" "}
                </h5>
              </div>
            </Submit>
          </ModalCard>
        </>
      );
    } else {
      return <></>;
    }
  };

  if (turmas.length !== 0) {
    return (
      <div style={{ width: "90vw", maxWidth: "2000px", marginBottom: "3%" }}>
        <Search>
          <img src={search} width="3%" alt="Busca" />
          <input
            className="input"
            placeholder="Pesquise o nome da turma.."
            type="text"
            value={busca}
            onChange={(e) => setBusca(e.target.value)}
          />
        </Search>
        {turmasSearch.length !== 0 ? (
          <GridCard container spacing={{ xs: 1, md: 3, lg: 3 }}>
            {turmasSearch.map((item, i) => {
              return (
                <ContainerGrid
                  item
                  xl={3}
                  lg={4}
                  md={6}
                  sm={12}
                  xs={12}
                  key={i}
                >
                  <OxeCard
                    sx={{}}
                    onClick={() => {
                      handleOpen(i);
                    }}
                  >
                    <CardMedia
                      component="img"
                      alt="Capa Oxetech Lab"
                      height="100"
                      image={capa}
                    />
                    <CardContent>
                      <Typography
                        variant="h5"
                        component="div"
                        className="title"
                        sx={{
                          fontSize:
                            item?.titulo.length > 30 ? "1.3em" : "1.6em",
                        }}
                      >
                        {item?.titulo}
                      </Typography>
                      <LinhaHorizontal />
                      <div className="info">
                        {/* <Typography className="subtitle" gutterBottom variant="h5" component="div">
                                Prof. Albert Einstein
                            </Typography> */}
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <img src={local} alt="Local" />
                          <Typography
                            className="subtitle"
                            sx={{ pl: "5px", color: "#000", fontWeight: 700 }}
                            variant="h5"
                            component="div"
                          >
                            {item?.Laboratorio.municipio}
                          </Typography>
                        </div>
                      </div>

                      <Typography
                        className="noWrap"
                        variant="body2"
                        sx={{ minHeight: "60px", maxHeight: "60px" }}
                      >
                        {item?.descricao}
                      </Typography>
                    </CardContent>
                    <Submit>
                      {item.inscricao_status ? (
                        <Button className={"butaoInscrito"}>Inscrito</Button>
                      ) : (
                        <Button
                          onClick={(e) => handleConfirmar(e, i)}
                          className={"butaoNaoInscrito"}
                        >
                          Inscrever-se
                        </Button>
                      )}
                      <div className="lastinfo">
                        <h5 className="encerramento">
                          {" "}
                          {"Inscrições até " +
                            item.data_encerramento_inscricoes.slice(8, 10) +
                            "/" +
                            item.data_encerramento_inscricoes.slice(5, 7) +
                            "/" +
                            item.data_encerramento_inscricoes.slice(0, 4)}{" "}
                        </h5>
                      </div>
                    </Submit>
                  </OxeCard>
                </ContainerGrid>
              );
            })}

            {/* Modals */}
            <CardExpandido />
            <Confirmar />

            <Snackbar
              open={alertUnauthorized.length > 0}
              autoHideDuration={6000}
              onClose={() => setAlertUnauthorized("")}
            >
              <Alert
                variant="filled"
                onClose={() => setAlertUnauthorized("")}
                severity="error"
                sx={{ width: "100%" }}
              >
                {alertUnauthorized}
              </Alert>
            </Snackbar>
            <Snackbar
              open={alertSuccessful.length > 0}
              autoHideDuration={6000}
              onClose={() => setAlertSuccessful("")}
            >
              <Alert
                variant="filled"
                onClose={() => setAlertSuccessful("")}
                severity="success"
                sx={{ width: "100%" }}
              >
                {alertSuccessful}
              </Alert>
            </Snackbar>
          </GridCard>
        ) : (
          <NoVagas>
            <img src={noResults} width="8%" alt="Nenhuma turma encontrada" />
            <h1 style={{ marginTop: "2%" }}>
              Nenhuma turma encontrada para {busca}
            </h1>
          </NoVagas>
        )}
      </div>
    );
  } else {
    return (
      <NoVagas>
        <img
          src={noFolder}
          width="90px"
          alt="Sem turmas cadastradas no momento"
        />
        <h2 style={{ marginTop: "2%", fontSize: "1em" }}>
          Sem turmas cadastradas no momento
        </h2>
      </NoVagas>
    );
  }
}

export default VagasOXL;

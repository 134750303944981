import firebase from "firebase/app";
import "firebase/storage";

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAvftCdnJdBQzE-B2s8qRdgTMRHOiUu4sc",
  authDomain: "oxe-tech.firebaseapp.com",
  projectId: "oxe-tech",
  storageBucket: "oxe-tech.appspot.com",
  messagingSenderId: "295676724089",
  appId: "1:295676724089:web:1b19dcc4368a6c4e33e5a8",
  measurementId: "G-923GC3MRPS"
};

firebase.initializeApp(firebaseConfig);

const storage = firebase.storage();

export { storage, firebase as default };

import React, { useEffect, useState } from "react";
import {
  Modal,
  Paper,
  TextField,
  Grid,
  Button,
  Box,
  Typography,
  useMediaQuery,
  FormGroup,
  Checkbox,
  Snackbar,
  Alert
} from "@mui/material";
import * as palette from "../../../utils/styledVariables";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import ClickAwayListener from "@mui/material/ClickAwayListener";
// import CardDesempenho from "../CardDesempenho"
import api from "../../../services/api";
import AssignmentIcon from "@mui/icons-material/Assignment";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
const styles = {
  modal: {
    width: "60%",
    minHeight: "60vh",
    maxHeight: "80vh",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    padding: "10px",
    textAlign: "center",
    // borderRadius: "2vh",
    justifyContent: "center",
    background: "white",
    overflow: "auto",
    "&::-webkit-scrollbar": {
      width: "3px",
    },

    "&::-webkit-scrollbar-track": {
      background: "#f1f1f1",
    },

    "&::-webkit-scrollbar-thumb": {
      background: "#888",
      borderRadius: "4px",
    },
  },
  paper: {
    width: "100%",
    marginBottom: "5px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  pergunta: {
    fontSize: "1.8em",
    fontWeight: "lighter",
    fontFamily: "Karla, sans-serif",
    textAlign: "start",
  },
  questao: {
    width: "90%",
    height: "100%",
    // border: "1px solid red",
    display: "flex",
    flexDirection: "column",
    justifyContent: "start",
  },
};

function QuestaoUnicaEscolha({ dados, i, onResposta, isAprovado }) {
  const [resposta, setResposta] = useState(null);
  const [hoveredOption, setHoveredOption] = useState(null);


  const handleRespostaChange = (event) => {
    const index = parseInt(event.target.value, 10);
    setResposta(index);
    onResposta([index]);
  };

  return (
    <div style={styles.questao}>
      <h3 style={styles.pergunta}>
        {i}. {dados.pergunta}
      </h3>
      <RadioGroup
        value={resposta}
        onChange={handleRespostaChange}
        sx={{ marginLeft: "8%" }}
      >
        {dados.alternativas.map((opcao, index) =>
          isAprovado ? (
            <FormControlLabel
              key={index}
              value={index}
              control={<Radio />}
              label={opcao}
              disabled={true}
              style={{
                border: dados.resposta.includes(index)
                  ? "1px solid green"
                  : resposta === index? "rgba(183, 78, 80, 1)": "none",
                backgroundColor: dados.resposta.includes(index)
                  ? "rgba(72, 223, 34, 0.20)"
                  : resposta === index? "rgba(183, 78, 80, 0.51)": "none",

                padding: "5px",
              }}
            />
          ) : (
            <FormControlLabel
              key={index}
              value={index}
              control={<Radio />}
              label={opcao}
              onMouseEnter={() => setHoveredOption(index)}
              onMouseLeave={() => setHoveredOption(null)}
              style={{
                border: hoveredOption === index ? "1px dashed #BD6F57" : "none",
                padding: "5px",
              }}
            />
          )
        )}
      </RadioGroup>
    </div>
  );
}

function QuestaoMultiplaEscolha({ dados, i, onResposta, isAprovado }) {
  const [respostas, setRespostas] = useState([]);
  const [hoveredOption, setHoveredOption] = useState(null);

  const handleRespostaChange = (index) => {
    if (respostas.includes(index)) {
      setRespostas(respostas.filter((item) => item !== index));
    } else {
      setRespostas([...respostas, index]);
      onResposta([...respostas, index]);
    }
  };

  return (
    <div style={styles.questao}>
      <h3 style={styles.pergunta}>
        {i}. {dados.pergunta}
      </h3>
      <FormGroup sx={{ marginLeft: "8%" }}>
        {dados.alternativas.map((opcao, index) =>
          isAprovado ? (
            <FormControlLabel
              key={index}
              control={
                <Checkbox
                  checked={respostas.includes(index)}
                  onChange={() => handleRespostaChange(index)}
                />
              }
              disabled={true}
              onMouseEnter={() => setHoveredOption(index)}
              onMouseLeave={() => setHoveredOption(null)}
              label={opcao}
              style={{
                border: dados.resposta.includes(index)
                  ? "1px solid green"
                  : respostas.includes(index)? "rgba(183, 78, 80, 1)": "none",
                backgroundColor: dados.resposta.includes(index)
                  ? "rgba(72, 223, 34, 0.20)"
                  : respostas.includes(index)? "rgba(183, 78, 80, 0.51)": "none",
                padding: "5px",
              }}
            />
          ) : (
            <FormControlLabel
              key={index}
              control={
                <Checkbox
                  checked={respostas.includes(index)}
                  onChange={() => handleRespostaChange(index)}
                />
              }
              onMouseEnter={() => setHoveredOption(index)}
              onMouseLeave={() => setHoveredOption(null)}
              label={opcao}
              style={{
                border: hoveredOption === index ? "1px dashed #BD6F57" : "none",
                padding: "5px",
              }}
            />
          )
        )}
      </FormGroup>
    </div>
  );
}

function ModalAtividade({ open, close, curso, trilhaId}) {
  // console.log('aaaaa',curso)
  const isSmallScreen = useMediaQuery("(max-width:600px)");
  const [atividade, setAtividade] = useState(null);
  const [sucessoMensagem, setSucessoMensagem] = useState('');
  const [erroMensagem, setErroMensagem] = useState('');
  const [respostas, setRespostas] = useState([]);
  const [isAprovado, setIsAprovado] = useState(false);

  const handleRespostas = (index, resposta) => {
    const novasRespostas = [...respostas];

    novasRespostas[index].resposta = resposta;

    setRespostas(novasRespostas);
  };

  const handleEnviar = () => {
    const data = {
      trilhaId: trilhaId,
      moduloId: curso.id,
      respostas: respostas
    };
    api
      .patch("/trilhas-de-conhecimento/inscricao/nota-atividade", data)
      .then((response) => {
        setIsAprovado(response.data.aprovado);
        if(response.data.aprovado){
          setSucessoMensagem(`Parabéns! Você passou na atividade com nota ${response.data.nota_atividade} !` )
        }else{
          setErroMensagem(`Sua nota foi ${response.data.nota_atividade}. Tente Novamente!` )
        }
      })
      .catch((err) => {
        setErroMensagem(err.response.data.error)
      });
  };

  useEffect(() => {
    if (curso && curso.curso_id) {
      api
        .get(`/cursos/${curso.curso_id}/atividade`)
        .then((res) => {
          setAtividade(res.data.activity);
          setRespostas(
            res.data.activity.questoes.map((res) => {
              return { resposta: "" };
            })
          );
        })
        .catch((err) => console.log(err));
    }
  }, [curso]);

  return (
    atividade && (
      <Modal open={open} onClose={() => close()}>
      <>

        <Box sx={styles.modal}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              margin: "6%",
              marginLeft: "4%",
              marginRight: "2%",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <AssignmentIcon
                sx={{
                  color: "#BD6F57",
                  fontSize: "50px",
                }}
                //   fontSize="large"
              />
              <h1 style={{ marginBottom: 0, color: "#BD6F57" }}>
                Questionário de {curso.curso.titulo}{" "}
              </h1>
            </div>
            {/* <h4 style={{ display: !isAprovado?'flex': 'none' marginBottom: 0, color: "#BD6F57" }}>
              Nota {nota}/10
            </h4> */}
          </div>
          <Grid
            container
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            {atividade.questoes.map((questao, i) => (
              <Grid
                key={i}
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                sx={{ display: "flex", justifyContent: "center" }}
              >
                {questao.tipo === "RESP_UNICA" ? (
                  <QuestaoUnicaEscolha
                    dados={questao}
                    i={i + 1}
                    onResposta={(res) => handleRespostas(i, res)}
                    isAprovado={isAprovado}
                  />
                ) : (
                  <QuestaoMultiplaEscolha
                    dados={questao}
                    i={i + 1}
                    onResposta={(res) => handleRespostas(i, res)}
                    isAprovado={isAprovado}
                  />
                )}
              </Grid>
            ))}
          </Grid>
          <div
            style={{
              display: "flex",
              justifyContent: "end",
              marginRight: "5%",
            }}
          >
            <Button
        variant="contained"
        color="primary"
        onClick={close}
        style={{ marginRight: "10px" }}
      >
        Fechar
      </Button>
      {!isAprovado && (
        <Button
          variant="contained"
          color="primary"
          onClick={handleEnviar}
        >
          Enviar
        </Button>
      )}
        
          </div>
        </Box>
        <Snackbar  anchorOrigin={{vertical: 'top', horizontal: 'center'}} open={sucessoMensagem.length > 0} autoHideDuration={6000} onClose={() => setSucessoMensagem('')}>    
          <Alert  variant="filled" onClose={() => setSucessoMensagem('')} severity="success" sx={{ width: '100%' }}>
            {sucessoMensagem}
          </Alert>
      </Snackbar>
      <Snackbar  anchorOrigin={{vertical: 'top', horizontal: 'center'}} open={erroMensagem.length > 0} autoHideDuration={6000} onClose={() => setErroMensagem('')}>    
          <Alert  variant="filled" onClose={() => setErroMensagem('')} severity="error" sx={{ width: '100%' }}>
            {erroMensagem}
          </Alert>
      </Snackbar>
      </>
      </Modal>
    )
  );
}

export default ModalAtividade;

import React from "react";
import ReactApexChart from "react-apexcharts";
const FrequencyChart = ({ data}) => {
  const chartData = {
    categories: [],
    series: [
      {
        name: "Frequência",
        data: [],
      },
    ],
  };

  if (data.length > 0) {
    data.forEach((aula, index) => {
      chartData.categories.push(index + 1);
      chartData.series[0].data.push(aula.frequencia);
    })
  }

  const options = {
    chart: {
      type: "line",
    },
    xaxis: {
      categories: chartData.categories,
      labels: {
        style: {
          fontSize: "12px",
        },
      },
      title: {
        text: "Aulas",
      },
    },
    yaxis: {
      title: {
        text: "Frequência (%)",
      },
      max: data.length >0? 100 :'',
    },
    colors: ["#D46D0D"],
  };

  return (
    <div>
      <h6>Frequência das aulas</h6>
      <ReactApexChart
        options={options}
        series={chartData.series}
        type="line"
        height={250}
      />
    </div>
  );
};



const App = ({aulas}) => {
  return (
    <div>
      <FrequencyChart data={aulas} />
    </div>
  );
};

export default App;

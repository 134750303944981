import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import MainHeader from '../../../../../src/components/MainHeader';
import { Container, Logo, TabsCursos, Titulo, Seletor, SelectButton } from './styles';
import logo from "../../../assets/logo.svg";
import api from "../../../../../src/services/api"

import Card from '../../../components/Estudante/Cards'
import Turmas from '../../../components/Estudante/Turmas'
import ModalFirstTimeOxd from '../../../components/Estudante/Modais/ModalFirstTimeOxd';
import { SnackbarProvider, useSnackbar } from "notistack";

function OxetechEdu() {
  const signed = useSelector((state) => state.auth.signed);
  const signedAdm = useSelector((state) => state.auth.signedAdm);
  const profile = useSelector((state) => state.user.profile)
  const [matricula, setMatricula] = useState('')
  const [open, setOpen] = useState(false)
  const [schools, setSchools] = useState([])
  const handleCloseModal = () => {
    setOpen(false);
  }
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.get(`/oxetechedu/matricula/matricula`);
        const validado = response.data.find(matricula => matricula.status === "1");
        if(validado){
          setOpen(false);
        }else{
          setOpen(true);
          setMatricula(response.data)
        }
      } catch (error) {
        setOpen(true);
      }
    };

    fetchData();
  }, []);

  const tabs = [
    {
      label: 'Turmas Disponí­veis',
      component: <Turmas className="card" />,
    },
    {
      label: 'Minhas Turmas',
      component: <Card className="card" />,
    
    },
    
  ];

  return (
    <Container>
      {!signed && !signedAdm ? <MainHeader /> : ''}
      <Logo>
        <img src={logo} className="img" alt="Oxe-tech_Oxe-Din" />
      </Logo>
      
        <TabsCursos
          className="tabs"
          defaultActiveKey="1"
          centered
          items={tabs.map((item, i) => {
            return {
              label: item.label,
              key: i,
              children: item.component,
            };
          })}
        />
        <SnackbarProvider maxSnack={3}>
           <ModalFirstTimeOxd open={open} profile={profile.id} close={handleCloseModal} matricula={matricula} />
          </SnackbarProvider>
        
      
    </Container>
  );
}


export default OxetechEdu;
import React, { useEffect, useMemo, useState, useRef } from "react";
import formatDate from "../../utils/formartDate";
import {
  Alert,
  Button,
  CardContent,
  CardMedia,
  Snackbar,
  Tooltip,
  Typography,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import {
  ContainerGrid,
  GridCard,
  LinhaHorizontal,
  ModalCard,
  ModalConfirm,
  NoVagas,
  OxeCard,
  Search,
  Status,
  Submit,
} from "./styles";

import MeuCertificado from "../Certificado";
import { useSelector } from "react-redux";
import logo from "../../assets/homeTriangle/oxeTechLabLogoON.png";
import api from "../../services/api";
import history from "../../services/history";
import FiltroTurmas from "./FiltroTurmas";
// icones

import VisibilityIcon from "@mui/icons-material/Visibility";
import capa from "../../assets/iconesOxeLab/capa.svg";
import homem from "../../assets/iconesOxeLab/homem.svg";
import local from "../../assets/iconesOxeLab/local.svg";
import noFolder from "../../assets/iconesOxeLab/noFolder.svg";
import noResults from "../../assets/iconesOxeLab/noResults.svg";
import search from "../../assets/iconesOxeLab/search.svg";

function TurmasInscritas() {
  const signed = useSelector((state) => state.auth.signed);

  const [turmas, setTurmas] = useState([]);
  const [open, setOpen] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [error, setError] = useState(false);
  const [alertExists, setAlertExists] = useState(false);
  const [alertSuccessful, setAlertSuccessful] = useState(false);
  const [alertUnauthorized, setAlertUnauthorized] = useState(false);
  const [busca, setBusca] = useState("");
  const [id, setId] = useState(-1);
  const [loading, setLoading] = useState(false);
  // const [turmasMatriculadas, setTurmasMatriculadas] = useState(true);
  const [selectedOption, setSelectedOption] = useState("");

  const handleOptionSelect = (option) => {
    setSelectedOption(option);
  };
  const today = new Date();

  useEffect(() => {
    if (selectedOption === "Matriculado") {
      api
        .get("turma/aluno-matriculado")
        .then((response) => {
          setTurmas(response.data);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      api
        .get("turma/aluno-inscrito")
        .then((response) => {
          setTurmas(response.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [selectedOption]);

  const handleOpen = () => {
    history.push("/oxetechlab-mural");
  };

  const handleDetails = (i) => {
    setOpen(true);
    setId(i);
  };

  const handleClose = () => {
    setAlertSuccessful(false);
    setAlertExists(false);
    setAlertUnauthorized(false);
    setError(false);
    setOpen(false);
    setConfirm(false);
  };

  const handleCancelar = (e, i) => {
    setOpen(false);
    setConfirm(true);
    setId(i);
    e.stopPropagation();
  };

  const handleSubmit = async () => {
    setLoading(true);
    api
      .delete(`/inscricoes-oxetechlab/${turmas[id].id}`)
      .then((response) => {
        setAlertSuccessful(response.data.message);
        setTimeout(() => {
          setLoading(false);
          setConfirm(false);
          window.location.reload();
        }, 700);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const Cancelar = () => {
    return (
      <ModalConfirm
        open={confirm}
        closable={false}
        centered
        onCancel={handleClose}
        footer={null}
      >
        <img src={logo} className="img" alt="OxetechLab" width="40%" />
        <h3>Tem certeza que deseja cancelar sua inscrição?</h3>
        <div className="butoes">
          <Button
            startIcon={
              loading ? <CircularProgress color="inherit" size={20} /> : null
            }
            onClick={handleSubmit}
            className="butao"
          >
            Sim
          </Button>
          <Button
            onClick={handleClose}
            variant="outlined"
            className="butaoCancelar"
          >
            Cancelar
          </Button>
        </div>
      </ModalConfirm>
    );
  };

  const turmasSearch = useMemo(() => {
    //Para não precisar ficar recalculando a busca
    const lowerSearch = busca.toLowerCase();
    const busca1 = (turma) =>
      turma.Turma.titulo.toLowerCase().includes(lowerSearch);

    if (Array.isArray(turmas)) {
        const titulo = turmas.filter(busca1);
        return titulo;
      } else {
        return [];
      }
  }, [busca, turmas]);

  const botoesStatusInscrito = [
    {
      background:
        "linear-gradient(162.93deg, #581780 -10.26%, rgba(213, 109, 13, 0.83) 276.84%)",
      title: "Não selecionado(a)",
      tooltip:
        "Infelizmente você não foi selecionado. Mas você pode tentar novamente em uma próxima turma.",
    },
    {
      background:
        "linear-gradient(162.93deg, #6B85C2 -10.26%, rgba(213, 109, 13, 0.83) 276.84%)",
      title: "Em análise",
      tooltip:
        "Sua inscrição está em análise. Logo você receberá um e-mail do resultado da seleção, aguarde!",
    },
    {
      background:
        "linear-gradient(162.93deg, #6B85C2 -10.26%, rgba(213, 109, 13, 0.83) 276.84%)",
      title: "Em análise",
      tooltip:
        "Sua inscrição está em análise. Logo você receberá um e-mail do resultado da seleção, aguarde!",
    },
    {
      background:
        "linear-gradient(162.93deg, rgba(34, 223, 178, 0.79) -10.26%, rgba(213, 109, 13, 0.83) 276.84%)",
      title: "Selecionado(a)",
      tooltip:
        "Você foi selecionado para participar dessa turma! Atente-se às informações da turma e aproveite.",
    },
  ];

  const botoesStatusTurma = [
    {
      background:
        "linear-gradient(162.93deg, #c62828 -10.26%, rgba(213, 109, 13, 0.83) 276.84%)",
      title: "Encerrada",
    },
    {
      background:
        "linear-gradient(162.93deg, #6B85C2 -10.26%, rgba(213, 109, 13, 0.83) 276.84%)",
      title: "Em Seleção",
    },
    {
      background:
        "linear-gradient(162.93deg, rgba(34, 223, 178, 0.79) -10.26%, rgba(213, 109, 13, 0.83) 276.84%)",
      title: "Iniciada",
    },
  ];
  const botoesStatusMatriculado = [
    {
      background:
        "linear-gradient(162.93deg, #581780 -10.26%, rgba(213, 109, 13, 0.83) 276.84%)",
      title: "Reprovado(a)",
      tooltip: "Infelizmente você foi reprovado nesta turma.",
    },
    {
      background:
        "linear-gradient(162.93deg, #6B85C2 -10.26%, rgba(213, 109, 13, 0.83) 276.84%)",
      title: "Matriculado(a)",
      tooltip:
        "Você foi selecionado para participar dessa turma! Atente-se às informações da turma e aproveite.",
    },
    {
      background:
        "linear-gradient(162.93deg, rgba(34, 223, 178, 0.79) -10.26%, rgba(213, 109, 13, 0.83) 276.84%)",
      title: "Aprovado(a)",
      tooltip: "Você foi aprovado nesta turma! Baixe já o seu certificado",
    },
  ];
  const StatusIncritoButton = (id) => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "flex-end",
          width: "100%",
        }}
      >
        <Status style={{ background: botoesStatusInscrito[id].background }}>
          <Tooltip title={botoesStatusInscrito[id].tooltip}>
            <h5 style={{ color: "#fff", margin: 0 }}>
              {botoesStatusInscrito[id].title}
            </h5>
          </Tooltip>
        </Status>
      </div>
    );
  };

  const StatusMatriculadoButton = (id) => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "flex-end",
          width: "100%",
        }}
      >
        <Status style={{ background: botoesStatusMatriculado[id].background }}>
          <Tooltip title={botoesStatusMatriculado[id].tooltip}>
            <h5 style={{ color: "#fff", margin: 0 }}>
              {botoesStatusMatriculado[id].title}
            </h5>
          </Tooltip>
        </Status>
      </div>
    );
  };

  const StatusTurmaButton = (id) => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "flex-start",
          width: "100%",
        }}
      >
        <Status style={{ background: botoesStatusTurma[id].background }}>
          <h5 style={{ color: "#fff", margin: 0 }}>
            {botoesStatusTurma[id].title}
          </h5>
        </Status>
      </div>
    );
  };
  function podeCancelarInscricao(date1, date2) {
    const day1 = date1.getDate();
    const month1 = date1.getMonth();
    const year1 = date1.getFullYear();

    const day2 = date2.getDate();
    const month2 = date2.getMonth();
    const year2 = date2.getFullYear();

    if (year1 === year2 && month1 === month2 && day1 === day2) {
      return 0; // Datas são iguais
    } else if (
      year1 < year2 ||
      (year1 === year2 && month1 < month2) ||
      (year1 === year2 && month1 === month2 && day1 < day2)
    ) {
      return -1; // A primeira data é anterior à segunda
    } else {
      return 1; // A primeira data é posterior à segunda
    }
  }
  const CardExpandido = () => {
    if (id !== -1) {
      return (
        <>
          <ModalCard
            open={open}
            onCancel={handleClose}
            width={800}
            footer={null}
            closable={false}
          >
            <CardMedia
              component="img"
              alt="Capa Oxetech Lab"
              height="100"
              image={capa}
            />
            {StatusTurmaButton(parseInt(turmasSearch[id].Turma.status))}
            <CardContent sx={{ p: " 5% 0 " }} className="header">
              <div style={{ width: "60%" }}>
                <Typography
                  className="title"
                  gutterBottom
                  variant="h5"
                  component="div"
                >
                  {turmasSearch[id]?.Turma.titulo}
                </Typography>
                <LinhaHorizontal />
                <Typography
                  className="subtitle"
                  sx={{ fontSize: "1.5em" }}
                  gutterBottom
                  variant="h5"
                  component="div"
                >
                  {/* {instrutores? "Prof. " + capitalizeWords(instrutores[0].name): ""} */}
                </Typography>
                <div style={{ marginTop: "5%" }}></div>
                <Typography
                  className="subtitle"
                  sx={{ fontSize: "1.2em" }}
                  gutterBottom
                  variant="h5"
                  component="div"
                >
                  <strong>Resultado da seleção dos inscritos:</strong>{" "}
                  {turmasSearch[id].Turma.data_resultado.slice(8, 10) +
                    "/" +
                    turmasSearch[id].Turma.data_resultado.slice(5, 7) +
                    "/" +
                    turmasSearch[id].Turma.data_resultado.slice(0, 4)}
                </Typography>
                <Typography
                  className="subtitle"
                  sx={{ fontSize: "1.2em" }}
                  gutterBottom
                  variant="h5"
                  component="div"
                >
                  <strong>Início:</strong>{" "}
                  {turmasSearch[id].Turma.data_inicio.slice(8, 10) +
                    "/" +
                    turmasSearch[id].Turma.data_inicio.slice(5, 7) +
                    "/" +
                    turmasSearch[id].Turma.data_inicio.slice(0, 4)}
                </Typography>
                <Typography
                  className="subtitle"
                  sx={{ fontSize: "1.2em" }}
                  gutterBottom
                  variant="h5"
                  component="div"
                >
                  <strong>Fim:</strong>{" "}
                  {turmasSearch[id].Turma.data_encerramento.slice(8, 10) +
                    "/" +
                    turmasSearch[id].Turma.data_encerramento.slice(5, 7) +
                    "/" +
                    turmasSearch[id].Turma.data_encerramento.slice(0, 4)}
                </Typography>
                <Typography
                  className="subtitle"
                  sx={{ fontSize: "1.2em" }}
                  gutterBottom
                  variant="h5"
                  component="div"
                >
                  <strong>Carga Horária:</strong>{" "}
                  {turmasSearch[id].Turma.carga_horaria}h
                </Typography>
              </div>
              <img className="img" src={homem} alt="Icone do OxetechLab" />
            </CardContent>
            <CardContent sx={{ p: " 2% 4% " }} className="section">
              <Typography variant="h5" component="div" className="sectionName">
                Descrição do curso
              </Typography>
              <Typography variant="body1" component="div" className="text">
                {turmasSearch[id]?.Turma.descricao}
              </Typography>
              <Typography variant="h5" component="div" className="sectionName">
                Informações adicionais
              </Typography>
              <Typography variant="body1" component="div" className="text">
                {turmasSearch[id]?.Turma.info_adicionais}
              </Typography>
              <Typography
                variant="h5"
                component="div"
                className="sectionName"
                sx={{ width: "15%", textAlign: "center" }}
              >
                Local
              </Typography>
              <Typography variant="body1" component="div" className="text">
                {turmasSearch[id]?.Turma.Laboratorio.rua},{" "}
                {turmasSearch[id]?.Turma.Laboratorio.bairro},{" "}
                {turmasSearch[id]?.Turma.Laboratorio.numero},{" "}
                {turmasSearch[id]?.Turma.Laboratorio.complemento !== ""
                  ? turmasSearch[id]?.Turma.Laboratorio.complemento + ", "
                  : ""}
                {turmasSearch[id]?.Turma.Laboratorio.cep} - Alagoas
              </Typography>
            </CardContent>

            <Submit>
              <div className="lastinfo">
                <h5 className="encerramento">
                  {" "}
                  {"Inscrições até " +
                    turmasSearch[id].Turma.data_encerramento.slice(8, 10) +
                    "/" +
                    turmasSearch[id].Turma.data_encerramento.slice(5, 7) +
                    "/" +
                    turmasSearch[id].Turma.data_encerramento.slice(0, 4)}{" "}
                </h5>
              </div>
            </Submit>
          </ModalCard>
        </>
      );
    } else {
      return <></>;
    }
  };

  return (
    <div style={{ width: "90vw", maxWidth: "2000px",  marginBottom: "3%" }}>
      <Search>
        <img src={search} width="3%" alt="Busca" />
        <input
          className="input"
          placeholder="Pesquise o nome da turma.."
          type="text"
          value={busca}
          onChange={(e) => setBusca(e.target.value)}
        />
      </Search>
      <FiltroTurmas onOptionSelect={handleOptionSelect} />
      {turmasSearch.length !== 0 ? (
        <GridCard container spacing={{ xs: 1, md: 3, lg: 3 }}>
          {turmasSearch.map((item, i) => {
            return (
              <ContainerGrid item xl={4} lg={4} md={6} sm={12} xs={12} key={i}>
                {/* <OxeCard sx={{}} onClick={() => {handleOpen(i)}} > */}
                <OxeCard>
                  <CardMedia
                    component="img"
                    alt="Capa Oxetech Lab"
                    height="100"
                    image={capa}
                  />
                  <div style={{ display: "flex" }}>
                    {item.status
                      ? StatusIncritoButton(parseInt(item.status))
                      : StatusMatriculadoButton(
                          parseInt(item.statusMatriculado)
                        )}
                    <Tooltip title="Ver detalhes da turma">
                      <VisibilityIcon
                        sx={{ mr: "2%", cursor: "pointer" }}
                        onClick={() => handleDetails(i)}
                        color="disabled"
                      />
                    </Tooltip>
                  </div>

                  <CardContent sx={{ p: "0 3%" }}>
                    <Typography
                      variant="h5"
                      component="div"
                      className="title"
                      sx={{
                        fontSize:
                          item.Turma.titulo.length > 20 ? "1.3em" : "1.6em",
                      }}
                    >
                      {item.Turma.titulo}
                    </Typography>
                    <LinhaHorizontal />
                    <div className="info">
                      <Typography
                        className="subtitle"
                        gutterBottom
                        variant="h5"
                        component="div"
                      >
                        {/* Prof. Albert Einstein */}
                      </Typography>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <img src={local} alt="Local" />
                        <Typography
                          className="subtitle"
                          sx={{ pl: "5px", color: "#000", fontWeight: 700 }}
                          variant="h5"
                          component="div"
                        >
                          {item.Turma.Laboratorio.municipio}
                        </Typography>
                      </div>
                    </div>
                    <Typography
                      className="noWrap"
                      variant="body2"
                      sx={{ minHeight: "60px", maxHeight: "60px" }}
                    >
                      {item.Turma.descricao}
                    </Typography>
                  </CardContent>
                  <Submit>
                    <div className="lastinfo">
                      {item.statusMatriculado && item.statusMatriculado==='2' ? (
                        <div>
                        <MeuCertificado
                          nome={item.Aluno.name}
                          curso={item.Turma.titulo}
                          dataInicio={formatDate(item.Turma.data_inicio)}
                          dataEncerramento={formatDate(
                            item.Turma.data_encerramento
                          )}
                          cargaHoraria={item.Turma.carga_horaria}
                        />
                      </div>
                        
                      ) : (
                        <h5
                          className="encerramento"
                          style={{
                            display: item.Turma.status === 0 ? "none" : "flex",
                          }}
                        >
                          {" "}
                          {"Inscrições até " +
                            item.Turma.data_encerramento_inscricoes.slice(
                              8,
                              10
                            ) +
                            "/" +
                            item.Turma.data_encerramento_inscricoes.slice(
                              5,
                              7
                            ) +
                            "/" +
                            item.Turma.data_encerramento_inscricoes.slice(
                              0,
                              4
                            )}{" "}
                        </h5>
                      )}
                      {/* <Button
                          onClick={(e) => handleBaixarCertificado(e, i)}
                          startIcon={<FileDownloadIcon />}
                          // sx={{display: item.Turma.status === 0 ? "flex" : "none"}}
                          className="butao"
                        >
                          Baixar Certificado
                        </Button> */}
                    </div>
                    <Button
                      onClick={(e) => handleCancelar(e, i)}
                      sx={{
                        display:
                          today >
                          new Date(item.Turma.data_encerramento_inscricoes)
                            ? "none"
                            : "flex",
                      }}
                      className="butao"
                    >
                      Cancelar inscrição
                    </Button>
                  </Submit>
                </OxeCard>
              </ContainerGrid>
            );
          })}
          {/* <MeuCertificado /> */}
          {/* Modals */}
          <Cancelar />
          <CardExpandido />

          <Snackbar
            open={alertSuccessful.length > 0}
            autoHideDuration={6000}
            onClose={() => setAlertSuccessful("")}
          >
            <Alert
              variant="filled"
              onClose={() => setAlertSuccessful("")}
              severity="success"
              sx={{ width: "100%" }}
            >
              {alertSuccessful}
            </Alert>
          </Snackbar>
        </GridCard>
      ) : (
        <NoVagas>
          <img src={noResults} width="8%" alt="Nenhuma turma encontrada" />
          <h1 style={{ marginTop: "2%" }}>
            Nenhuma turma encontrada para {busca}
          </h1>
        </NoVagas>
      )}
    </div>
  );
  // } else {
  //   return (
  //     <NoVagas>
  //       <img src={noFolder} width="25%" alt="Sem turmas" />
  //       <h2 style={{ marginTop: "2%", fontSize: "1em" }}>
  //         Você ainda não está inscrito em nenhuma turma.
  //       </h2>
  //     </NoVagas>
  //   );
  // }
}

export default TurmasInscritas;

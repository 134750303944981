import React, {useState, useEffect} from "react";
import { useSelector } from "react-redux";
import Sidebar from "../../components/DashboardAluno/Sidebar/Sidebar";
import { Box, CssBaseline } from "@mui/material";
import DadosCadastraisAluno from "../../components/DadosCadastraisAluno";

const headerHeight = "8vh";

function EditProfileAluno() {
  let profile = useSelector((state) =>
    state.user.profile.dataValues
      ? state.user.profile.dataValues
      : state.user.profile
  );

  const [width, setWidth] = useState(window.innerWidth);
  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);


  const onMobile = width <= 600;
  const [sidebarCollapsed, setSidebarCollapsed] = useState(false);


  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
    
      <Sidebar
        onMobile={onMobile}
        collapsed={sidebarCollapsed}
        setCollapsed={setSidebarCollapsed}
      />

      <DadosCadastraisAluno profile={profile} styles={{ marginLeft: onMobile || sidebarCollapsed ? "0" : "240px",}}/>
    </Box>
  );
}

export default EditProfileAluno;
import { message } from "antd";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, Route, useLocation, useHistory } from "react-router-dom";
import DefaultLayout from "../pages/_layouts/default";
import Error from "../pages/Error";
import { useAuth } from "../hooks/auth/AuthContext";

import api from "../services/api";
import { store } from "../store";
import { signOut } from "../store/modules/auth/actions";

export default function RouteWrapper({
  component: Component,
  isWork,
  ...rest
}) {
  const { signed, token } = store.getState().auth;
  const profile = useSelector((state) => state.user.profile);
  const location = useLocation();
  const history = useHistory();
  const id = location.pathname.split("/")[2];
  const { logout} = useAuth();

  useEffect(() => {
    if (signed) {
      const payload = JSON.parse(atob(token.split(".")[1]));
      const currentTime = Math.round(new Date().getTime() / 1000);
      const expirationTime = payload.exp;
      const leeway = 600;
      
      if (currentTime - leeway - expirationTime > 1) {
        message.warn("Tempo de sessão expirou!");
        logout();
      }
    }
  }, [signed, token]);

  useEffect(() => {
    const fetchData = async () => {
        try {
          const response = await api.get(`vagas/vaga-empresa-index?id=${id}`);
          if (!response.data.vaga) {
            history.push("/dashboard");
            window.location.reload();
          }
        } catch (error) {
          console.error(error);
        }
    };

    if (location.pathname.includes("/minha-vaga/") && profile.cnpj) {
        fetchData();
    }
  }, [location.pathname, id]);



  if(!signed){
    return <Redirect to="/" />;
  }

  if(location.pathname.includes("/minha-vaga/") && profile.cpf){
    return <Redirect to="/dashboard" />;
  }

  if (
    (isWork && !profile.oxetech_work) ||
    (!profile.cnpj && location.pathname === "/empresa-account")
  ) {
    return <Redirect to="/dashboard" />;
  }



  return (
    <Route
      {...rest}
      render={(props) => (
        <DefaultLayout>
          <Component {...props} />
        </DefaultLayout>
      )}
    />
  );
}

RouteWrapper.propTypes = {
  isWork: PropTypes.bool,
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.func])
    .isRequired,
};

RouteWrapper.defaultProps = {
  isWork: false,
};

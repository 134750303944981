import React from "react";

import { Redirect } from "react-router-dom";
import { useAuth } from "../../hooks/auth/AuthContext";

function SignOut() {
  const { logout } = useAuth();

  logout();

  return <Redirect to="/" />;
}

export default SignOut;

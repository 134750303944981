import styled from 'styled-components'
import { motion } from 'framer-motion'
import oxeTechHatON from '../../assets/homeTriangle/oxeTechHatON.png'
import oxeTechLabLogoON from '../../assets/homeTriangle/oxeTechLabLogoON.png'
import oxeTechWorkLogoON from '../../assets/homeTriangle/oxeTechWorkLogoON.png'

export const Container = styled.div``

export const SectionOne = styled.div`
  -webkit-user-drag: none;
  background: linear-gradient(
    180deg,
    rgba(116, 31, 138, 0.9) 35%,
    rgba(0, 0, 99, 1) 100%
  );
  background-size: cover;

  overflow: hidden;
  height: 100vh;
  display: flex;
  text-align: center;
  position: relative;
  p {
    color: rgba(255, 255, 255, 1);
    font-size: 1.5em;
  }
`

export const TriangleFrame = styled(motion.img)`
  position: relative;
  bottom: 90%;
  right: 50%;
  width: 120vw;
  height: 150vh;
`

export const LogoOxeDin = styled(motion.img)`
  position: absolute;
  width: 20vw;
`

export const LogoOxeTechLab = styled(motion.img)`
  width: 40vw;

  &:hover {
    width: 16.6vw;
    content: url(${oxeTechLabLogoON});
  }
`

export const LogoOxeTechWork = styled(motion.img)`
  width: 40vw;
  &:hover {
    width: 23vw;
    content: url(${oxeTechWorkLogoON});
  }
`

export const LogoOxeTechHat = styled(motion.img)`
  width: 40vw;

  &:hover {
    width: 11.4vw;
    content: url(${oxeTechHatON});
  }
`

export const InovationMap = styled(motion.img)`
  position: absolute;
  width: 40vw;
`

export const Text = styled(motion.div)`
  position: absolute;
  top: 30%;
  left: 40vw;
  right: 5vw;
  width: 50vw;
  a {
    color: rgba(255, 255, 255, 0.7);
    font-size: 120%;
    transition: color 0.2s ease;
    &:hover {
      color: rgba(255, 255, 255, 1);
    }
  }
  .tool {
    position: absolute;
    top: 100%;
    left: 0;
    @media (min-width: 1500px) {
      font-size: 200%;
    }
  }
  .typ {
    @media (min-width: 1500px) {
      font-size: 200%;
    }
  }
  @media (min-width: 1500px) {
    width: 40vw;
    left: 45vw;
  }
`

export const navegator = styled(motion.p)``

import AddCircleIcon from "@mui/icons-material/AddCircle";
import ErrorIcon from "@mui/icons-material/Error";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import api from "../../../../services/api";
import CardTurmas from "../Cards/Turmas";
import ModalCadastro from "../Modais/CadastroTurma";
import { TabsCursos } from "./styles";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

// import { useLab } from "../../../hooks/useLab";

const theme = createTheme({
  palette: {
    primary: {
      main: "rgba(212, 109, 13, 0.5)",
      darker: "rgba(212, 109, 13, 1)",
    },
  },
});

function ProfileAluno() {
  const [turmas, setTurmas] = useState([]);

  const escola = useSelector((state) => state.user.escola_profile);
  const { escolaId } = useParams();

  useEffect(() => {    
      api.get(`/oxetechedu/turmas/by-school/${escolaId}`).then((response) => {
        setTurmas(response.data)
      }).catch((error) => {console.log(error)});
  }, []);
 
  // const selectTurmas = () => {
  //   if (labsAndTurmas) {
  //     const labTurmas = labsAndTurmas.find((labObj) => labObj.lab_id === lab.lab_id);
  //     setTurmas(labTurmas.turmas);
  //   }
  // };

  // useEffect(() => {
  //   if (labsAndTurmas)
  //     selectTurmas();
  // }, [labsAndTurmas]);

  // useEffect(() => {
  //   selectTurmas();
  // }, [lab]);

  const turmasAbertas = turmas.filter((turma) => !turma.encerrada);
  const turmasFechadas = turmas.filter((turma) => turma.encerrada);
  const [openCadastroTurma, setOpenCadastroTurma] = useState(false);


  const tabs = [
    {
      label: "Abertas",
      componente: (
        <ThemeProvider theme={theme}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
              paddingBottom: '3%'
            }}
          >
            {turmasAbertas.length > 0 ? (
              <Grid container rowSpacing={2} spacing={2}>
                {turmasAbertas.map((turma) => (
                  <Grid item key={turma.id} xs={12} sm={12} md={3}>
                    <CardTurmas key={turma.id} turma={turma} />
                  </Grid>
                ))}
                <Grid item xs={12} sm={12} md={3}>
                  <Tooltip title="Abrir nova turma" placement="right">
                    <Button
                      sx={{
                        color: "rgba(212, 109, 13, 0.5)",
                        border: "1px solid rgba(9, 7, 7, 0.1)",
                        borderRadius: "15px",
                        width: "100%",
                        height: "200px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                      variant="outlined"
                      onClick={() => setOpenCadastroTurma(true)}
                    >
                      <AddCircleIcon fontSize="large" />
                    </Button>
                  </Tooltip>
                </Grid>
              </Grid>
            ) : (
              <Grid container rowSpacing={2} spacing={2}>
                <Grid item xs={12} sm={12} md={3}>
                  <Tooltip title="Abrir nova turma" placement="right">
                    <Button
                      sx={{
                        color: "rgba(212, 109, 13, 0.5)",
                        border: "1px solid rgba(9, 7, 7, 0.1)",
                        borderRadius: "15px",
                        width: "100%",
                        height: "200px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                      variant="outlined"
                      onClick={() => setOpenCadastroTurma(true)}
                    >
                      <AddCircleIcon fontSize="large" />
                    </Button>
                  </Tooltip>
                </Grid>
              </Grid>
            )}
          </div>
        </ThemeProvider>
      ),
    },
    {
      label: "Fechadas",
      componente: (
        <ThemeProvider theme={theme}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
              paddingBottom: '3%'
            }}
          >
            {turmasFechadas.length > 0 ? (
              <Grid container rowSpacing={2} spacing={2}>
                {turmasFechadas.map((turma) => (
                  <Grid item xs={12} sm={12} md={3}>
                    <CardTurmas key={turma.id} turma={turma} />
                  </Grid>
                ))}
              </Grid>
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  marginTop: "20%",
                }}
              >
                <ErrorIcon color="primary" fontSize="large" />
                <p>Não há turmas fechadas no momento.</p>
              </div>
            )}
          </div>
        </ThemeProvider>
      ),
    },
  ];

  const handleCloseModal = () => {
    setOpenCadastroTurma(false);
  };
  return (
    <Box component="main" sx={{ flexGrow: 1, p: 0 }}>
      <TabsCursos
        className="tabs"
        defaultActiveKey="1"
        centered
        items={tabs.map((item, i) => {
          return {
            label: item.label,
            key: i,
            children: item.componente,
          };
        })}
      />

      <ModalCadastro
        open={openCadastroTurma}
        onHandleClose={() => handleCloseModal()}
      />
    </Box>
  );
}

export default ProfileAluno;

import styled from 'styled-components';
import * as palette from '../../utils/styledVariables';
import 'animate.css'

export const Container = styled.div`
  margin-top: 0.5rem;
  .cards {
    display: flex;
    justify-content: center;
    
  }
  a {
    padding: 5px;

    &:hover {
      // border: 2px solid ${palette.profileMainColor};
      border-radius: 10px;
    }
  }
  .cursos {
    
    &:hover{
      animation: myAnim 1s ease 0s 1 normal forwards;
    }

    @keyframes myAnim {
	0% {
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
	}

	100% {
		box-shadow: 0 0 20px 0px rgba(0, 0, 0, 0.35);
	}
}
  }
`;

import { message } from "antd";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Redirect, Route, useLocation } from "react-router-dom";
import AuthLayout from "../pages/_layouts/auth";
import DefaultLayout from "../pages/_layouts/default";
import api from "../services/api";
import { store } from "../store";
import { signOut } from "../store/modules/auth/actions";

export default function RouteWrapper({
  component: Component,
  isPrivate,
  isAdm,
  isLab,
  ...rest
}) {
  const { signed, token } = store.getState().auth;
  const dispatch = useDispatch();
  const location = useLocation();

  if (signed) {
    const payload = JSON.parse(atob(token.split(".")[1]));
    const time_now = Math.round(new Date().getTime() / 1000);
    const time_expired = payload.exp;
    const leeway = 600;

    if (time_now - leeway - time_expired > 1) {
      message.warn("Tempo de sessão expirou!");
      dispatch(signOut());
    }
  }

  // não redirecionar caso a rota atual esteja presente na lista abaixo
  let non_redirects_list = [
    "/",
    "/inovation-map",
    "/oxe-tech-work",
    "/oxetechwork",
    "/politicas-privacidade",
    "/oxedin",
    "/oxetechlab",
    "/oxetechedu",
    "/oxetrilhas",
  ];

  if (!non_redirects_list.includes(location.pathname) && signed) {
    return <Redirect to="/" />;
  }

  // Condição para renderizar layout

  const Layout = signed ? DefaultLayout : AuthLayout;
  return (
    <Route
      {...rest}
      render={(props) => (
        <Layout>
          <Component {...props} />
        </Layout>
      )}
    />
  );
}

RouteWrapper.propTypes = {
  isPrivate: PropTypes.bool,
  isAdm: PropTypes.bool,
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.func])
    .isRequired,
};

RouteWrapper.defaultProps = {
  isPrivate: false,
  isAdm: false,
};

import React, { useState, useEffect } from "react";
import {
    Modal,
    styled,
    Box,
    MenuItem,
    Select,
    Button,
    RadioGroup,
    FormControlLabel,
    Radio,
    FormLabel,
    FormControl,
    Typography
} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import ReplyIcon from '@mui/icons-material/Reply';
import * as palette from "../../utils/styledVariables"
import api from "../../services/api";
import { set } from "date-fns";


const SimpleButton = styled((props) => <Button {...props} />)(() => ({
    background: palette.laranjaIntermediario2,
    "&:hover": {
      background: palette.laranjaSecundario
  },
    color: palette.profileWhiteShade,
    margin: "10px 5px 0 5px",
}));


const styleModal = {

  
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    textAlign: "center",
    borderRadius: "1vh",
    justifyContent: 'center',
    display: "flex",
    flexDirection: "column",
    padding: "20px",
    alignItems: "center"

};

const styleImg = { height: "7vh", objectFit: "contain", marginBottom: "20px" }

function ModalEncerramento(props) {

    const [contratacao, setContratacao] = useState(null);
    const [cadastrado, setCadastrado] = useState(null);
    const [contratado, setContratado] = useState(null);

    const handleContratado = (e) => {
        const {
            target: { value },
        } = e;
        setContratado(value)
    }
    const handleCadastrado = (e) => {
        const {
            target: { value },
        } = e;
        if (value == "0"){
            setContratado(null)
        }
        setCadastrado(value)
    }

    const handleContratacao = (e) => {
        const {
            target: { value },
        } = e;

        if (value == "0"){
            setCadastrado(null)
            setContratado(null)
        }
        
        setContratacao(value)
    }

    const handleJobClosure = async () => {
        try {
          const data = {
            houve_contratacao: null,
            contratacao_via_oxetech: null,
            contratado_id: null
          };
    
          api.patch(`vagas/close?id=${props.vagaId}`, data).then((res) => {
            props.close();
            window.location.reload();
          })
    
        } catch (err) {
          console.log(err);
        }
      }
    


    return (<Modal
        open={props.openModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ justifyContent: 'center' }}

    >
        <Box sx={styleModal} xs={12} sm={12} md={4} lg={3}>
            <img src={props.image} style={styleImg} />

            {/* <FormControl>
                <FormLabel >Houve seleção para a vaga?</FormLabel>
                <RadioGroup
                    row
                    value={contratacao}
                    onChange={handleContratacao}
                    sx={{ display: "flex", justifyContent: "center" }}
                >
                    <FormControlLabel value="1" control={<Radio />} label="Sim" />
                    <FormControlLabel value="0" control={<Radio />} label="Não" />

                </RadioGroup>
            </FormControl> */}

            {/* { contratacao === "1" && <FormControl>
                <FormLabel >O candidato selecionado possui cadastro na plataforma?</FormLabel>
                <RadioGroup
                    row
                    value={cadastrado}
                    onChange={handleCadastrado}
                    sx={{ display: "flex", justifyContent: "center" }}
                >
                    <FormControlLabel value="1" control={<Radio />} label="Sim" />
                    <FormControlLabel value="0" control={<Radio />} label="Não" />

                </RadioGroup>
            </FormControl>}

            {cadastrado === "1" && contratacao === "1" && <FormControl variant="filled"  >
                <FormLabel >Se sim, indique o candidato selecionado</FormLabel>
                <Select
                    value={contratado}
                    onChange={handleContratado}
                >
                    {props.candidates?.map((aln) => (
                        <MenuItem
                            key={aln.Aluno?.name}
                            value={aln.Aluno.id}
                        >
                            {`${aln.Aluno?.name}`}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>} */}

            <Typography sx={{ color: palette.preto }}> Deseja encerrar a vaga? </Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <SimpleButton onClick={handleJobClosure} startIcon={<SaveIcon />}> SIM </SimpleButton>
                <SimpleButton onClick={props.close} startIcon={<ReplyIcon />}> NÃO </SimpleButton>
            </Box>
        </Box>
    </Modal>
    )
}

export default ModalEncerramento;
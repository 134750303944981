import React, { useState, useEffect } from "react";
import Sidebar from "../../components/Bolsista/SideBar/index.js"
import {
  Box,
  CssBaseline,
  IconButton,
  Button,
  Tabs,
  Tab,
  ThemeProvider,
  createTheme,
} from "@mui/material";
import * as palette from "../../../utils/styledVariables.js";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import TableCard from "../../../components/DashUserAdm/TableCard.js";
// import ModalConfirmacao from "../ModalConfirmacao";
import api from "../../../services/api.js";
import Options from "../../../utils/options.js";
import { useDispatch, useSelector } from "react-redux";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import formatDate from "../../../utils/formartDate";
import ModalConfirmacao from "../../../components/ModalConfirmacao/index.js";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";


export const ValidarAlunos = () => {
  const theme = createTheme({
    palette: {
      secondary: {
        main: palette.laranjaIntermediario2,
      },
    },
  });
 ;

  const [width, setWidth] = useState(window.innerWidth);
  const onMobile = width <= 600;
  const [sidebarCollapsed, setSidebarCollapsed] = useState(false);
  const [openModalConfirmMatricula, setOpenModalConfirmMatricula] = useState(false);
  const [currentNumeroInscricao, setCurrentNumeroInscricao] = useState(null);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  const escola = useSelector((state) => state.user.escola_profile);
  const [inscritos, setInscritos] = useState([])
  const [currentMatricula, setCurrentMatricula] = useState(null);
  const [loading, setLoading] = useState(true);
  const [escolaId, setEscolaId] = useState(escola.id);

  const formatInscritosData = (inscritosData) => {
    const formated = [];
    inscritosData.forEach((inscrito) => {
      const data = {
        id: inscrito.id,
        status: inscrito.status,
        nome: inscrito.Aluno.name,
        numero_inscricao: inscrito.id,
        createdAt: formatDate(inscrito.createdAt),
        email: inscrito.Aluno.email,
        quantidade_faltas: inscrito.faltas
      };

      formated.push(data);
    });

    setInscritos(formated);
  };
  const changeEditalStatus = (e, id, matricula_id) => {
    const {
      target: { value },
    } = e;

    const updatedInscritos = inscritos.map((item) =>
      item.id == id ? { ...item, status: value } : item
    );
    setInscritos(updatedInscritos)
    setCurrentMatricula(id);
    setCurrentNumeroInscricao(matricula_id)
    setOpenModalConfirmMatricula(true);
  };


  const submitChangesMatricula = (id, numeroInscricao) => {
    try {
      const data = {
        matricula_id: numeroInscricao,
        validado: inscritos.find((item) => item.id === id).status === 1 ? true : false,
      };
      api
        .patch(`/oxetechedu/matricula/validar/${escola.id}`, data)
        .then((res) => {
          setCurrentMatricula(null);
          setCurrentNumeroInscricao(null);
          setOpenModalConfirmMatricula(false);
        });
    } catch (err) {
      console.log(err);
    }
  };
  
  const getInscritos = () => {
    api.get(`/oxetechedu/matricula/escola/${escola.id}`).then((response) => {
    formatInscritosData(response.data)
      setOpenModalConfirmMatricula(false);
    });
  };


  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  useEffect(() => {
    setLoading(true)
    api.get(`/oxetechedu/matricula/escola/${escola.id}`).then((response) => {
    formatInscritosData(response.data)
    setLoading(false)
    });
   

  }, []);
  const cursosHeader = [
    
    {
      field: "status",
      headerName: "Status",
      width: 150,
      renderCell: (params) => {
        return (
          <FormControl variant="filled" sx={{ width: "100%" }}>
            <Select
              value={params.value ? params.value : 0} 
              onChange={(e) => {
                changeEditalStatus(e, params.row.id, params.row.numero_inscricao);
              }}
            >
              {Options.matriculasStatuses.map((item, index) => (
                <MenuItem key={item} value={index} disabled={index === 0}>
                  {item}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        );
      },
    },
    
    {
      field: "numero_inscricao",
      headerName: "Matrícula",
      width: 120,
    },
    {
      field: "nome",
      headerName: "Nome",
      width: 200,
    },
    {
      field: "email",
      headerName: "E-mail",
      width: 200,
    },
  ];

  const table = [
    {
      name: "Alunos Inscritos",
      columns: cursosHeader,
      rows: inscritos,
    },
  ]
  const [chosenTable, setChosenTable] = useState(0);

  return (
    <Box
    sx={{
      display: "flex",
      flexGrow: 1,
    }}
  >
    <CssBaseline />

    <Sidebar
      onMobile={onMobile}
      collapsed={sidebarCollapsed}
      setCollapsed={setSidebarCollapsed}
    />
    <ThemeProvider theme={theme}>
      <Box
        component="main"
        sx={{
          backgroundColor: "#FFFFFF",
          flexGrow: 2,
          p: 1,
          width: { xs: `calc(100% - 20vh)` },
          marginLeft: onMobile || sidebarCollapsed ? "auto" : "240px",
        }}
      >
        <Tabs
          centered
          value={chosenTable}
          onChange={(event, newValue) => setChosenTable(newValue)}
          sx={{ marginBottom: 1 }}
          indicatorColor="secondary"
          textColor="secondary"
        >
          {table.map((table, index) => (
            <Tab value={index} label={table.name} key={index} />
          ))}
        </Tabs>
        <TableCard props={table[chosenTable]} loading={loading} />
      </Box>
    </ThemeProvider>

    <ModalConfirmacao
              text={`Você confirma a ${inscritos.find(item => item.id === currentMatricula)?.status === 1 ? 'VALIDAÇÃO' : 'REJEIÇÃO'} de ${inscritos.find(item => item.id === currentMatricula)?.nome}?`}
              open={openModalConfirmMatricula}
              close={() => {
                setOpenModalConfirmMatricula(false);
              }}
              handleChange={() => submitChangesMatricula(currentMatricula, currentNumeroInscricao)}
              handleRevert={getInscritos}
  />
   


  </Box>

  )
};

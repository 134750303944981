import React, { useEffect, useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import InsightsIcon from "@mui/icons-material/Insights";
import {
  Typography,
  Tooltip,
  Snackbar,
  Alert,
} from "@mui/material";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import { Link, useParams } from "react-router-dom";
import * as palette from "../../../../../utils/styledVariables";
import { Container, Title } from "./styles";
import ModalEditTurma from "../../Modais/EditTurma";
import api from "../../../../../services/api";
const styles = {
  text: {
    fontSize: "16pt",
    fontWeight: "300",
    fontStyle: "italic",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  popover: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
};
function CardTurmas(props) {
  const { escolaId } = useParams();
  const { turma } = props;
  const [turmaId, setTurmaId] = useState(0);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openEditTurma, setOpenEditTurma] = useState(false);
  const [openCertificadoTurma, setOpenCertificadoTurma] = useState(false);
  const [numeroInscritos, setNumeroInscritos] = useState(0);
  const [error, setError] = useState("");
  
  const handleCloseModal = () => {
    setOpenEditTurma(false);
  };

  const [anchorEl1, setAnchorEl1] = React.useState(null);
  const open = Boolean(anchorEl1);
  const handleClickMore = (event) => {
    setAnchorEl1(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl1(null);
  };

  useEffect(() => {
    if (!openEditTurma) {
      setAnchorEl1(null);
    }
  }, [openEditTurma]);

  // const handleEncerrarTurma = () => {
  //   api
  //     .get(`turma/encerrar/${turma.id}`)
  //     .then((res) => {
  //       window.location.reload();
  //     })
  //     .catch((error) => {
  //       setError(error.response.data.error);
  //     });
  // };
  return (
    <Card
      sx={{
        backgroundColor: "white",
        position: "relative",
        borderRadius: "10px",
      }}
    >
      <Container>
        <Title>{turma.titulo}</Title>
      </Container>
      <CardContent sx={{ height: "42%" }}>
        <Typography
          variant="body2"
          color="rgba(0, 0, 0, 0.67)"
          sx={styles.text}
        >
          <Typography
              variant="body2"
              color="rgba(0, 0, 0, 0.67)"
              sx={styles.text}
            >
              <strong>N° de inscritos:</strong> {turma.qtd_vagas_preenchidas}
            </Typography>
            <Typography
              variant="body2"
              color="rgba(0, 0, 0, 0.67)"
              sx={styles.text}
            >
              <strong>Data:</strong>{" "}
              {turma.dia_da_aula.slice(8, 10) +
                "/" +
                turma.dia_da_aula.slice(5, 7) +
                "/" +
                turma.dia_da_aula.slice(0, 4)}
            </Typography>
            <Typography
              variant="body2"
              color="rgba(0, 0, 0, 0.67)"
              sx={styles.text}
            >
              <strong>Horário:</strong> {turma.hora_inicio} - {turma.hora_fim} 
            </Typography>
        </Typography>
        
      </CardContent>
      <Divider variant="middle" />
      {!turma.encerrada && (
        <CardActions
          sx={{
            position: "relative",
            bottom: "0px",
            display: "flex",
            margin: "0",
          }}
        >
           <Tooltip
            title="Editar Turma"
          >
          <Button
             sx={{ color: palette.transparente, width: "50%" }}
             onClick={() => {
               setOpenEditTurma(true);
               setTurmaId(turma.id);
             }}
           >
            <EditIcon sx={{ fontSize: "35px", color: "GrayText" }} />
           </Button>
          </Tooltip>
            <Divider variant="middle" orientation="vertical" flexItem />
          <Tooltip
            title="Painel Principal"
          >
            <Link
              to={`/dash/${escolaId}/turma/${turma.id}`}
              style={{ width: "50%" }}
            >
              <Button sx={{ width: "100%" }}>
                <InsightsIcon sx={{ fontSize: "35px", color: "GrayText" }} />
              </Button>
            </Link>
          </Tooltip>
        </CardActions>
      )}
       <ModalEditTurma
        open={openEditTurma}
        onHandleClose={() => handleCloseModal()}
        turmaId={turma.id}
      /> 

      <Snackbar
        open={error.length > 0}
        autoHideDuration={7000}
        onClose={() => setError("")}
      >
        <Alert
          variant="filled"
          onClose={() => setError("")}
          severity="error"
          sx={{ width: "100%" }}
        >
          {error}
        </Alert>
      </Snackbar>
    </Card>
  );
}

export default CardTurmas;

import styled from 'styled-components';

export const Container = styled.div`
  padding: 5px 15px;

`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 5vw;
  align-items: center;
`;

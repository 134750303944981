import Options from "../../../utils/options";
const INITIAL_STATE = {
  profile: null,
  labs_profile: null,
  escola_profile: null
};

export default function user(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "@user/UPDATE_PROFILE_REQUEST": {
      return { ...state };
    }
    case "@auth/SIGN_IN_SUCCESS": {
      const { data } = action.payload.labs_profile;
      const labs_profile = data ? 
        data.map((lab) => data && ({
          lab_id: lab.Laboratorio.id,
          nome: lab.Laboratorio.nome,
          municipio: lab.Laboratorio.municipio,
          rua: lab.Laboratorio.rua,
          bairro: lab.Laboratorio.bairro,
          numero: lab.Laboratorio.numero,
          cep: lab.Laboratorio.cep,
          complemento: lab.Laboratorio.complemento,
          coordenador: lab.Laboratorio.Coordenador.name,
          isCoordenador: lab.coordenador,
          super_agente: lab.coordenador ? null : lab.super_agente,
        })) : null;
        const escola_profile = action.payload.user.escola_oxetech_edu? action.payload.user.escola_oxetech_edu : null;
      return {
        ...state,
        profile: action.payload.user,
        labs_profile,
        escola_profile
      };
   
    }
    case "@auth/SIGN_IN_ADM_SUCCESS": {
      return { ...state, profile: action.payload.user };
    }
    case "@user/UPDATE_PROFILE_SUCCESS": {
      return { ...state, profile: action.payload.user };
    }
    case "@user/UPDATE_PROFILE_EMPRESA_SUCCESS": {
      return { ...state, profile: action.payload.user };
    }
    case "@user/UPDATE_FIRST_STEPS_SUCCESS": {
      return { ...state, profile: action.payload.user };
    }
    case "@user/UPDATE_OFFICE_SUCCESS": {
      return { ...state, profile: action.payload.user };
    }
    case "@auth/SIGN_OUT": {
      return { ...state, profile: null, lab_profile: null };
    }
    case "@user/SEE_NOTIFICATIONS": {
      return { ...state, profile: action.payload.user };
    }
    case "@user/LAB_PROFILE": {
      return { ...state, labs_profile: action.payload.profile };
    }
    default:
      return state;
  }
  // return produce(state, draft => {
  //   switch (action.type) {
  //     case '@user/UPDATE_PROFILE_REQUEST': {
  //       draft.profile = action.payload.data
  //       break
  //     }
  //     case '@auth/SIGN_IN_SUCCESS': {
  //       draft.profile = action.payload.user
  //       break
  //     }
  //     case '@auth/SIGN_IN_ADM_SUCCESS': {
  //       draft.profile = action.payload.user
  //       break
  //     }
  //     case '@user/UPDATE_PROFILE_SUCCESS': {
  //       draft.profile = action.payload.user
  //       break
  //     }
  //     case '@user/UPDATE_PROFILE_EMPRESA_SUCCESS': {
  //       draft.profile = action.payload.user
  //       break
  //     }
  //     case '@user/UPDATE_FIRST_STEPS_SUCCESS': {
  //       draft.profile = action.payload.user
  //       break
  //     }
  //     case '@user/UPDATE_OFFICE_SUCCESS': {
  //       draft.profile = action.payload.user
  //       break
  //     }
  //     case 'auth/SIGN_OUT': {
  //       draft.profile = null
  //       break
  //     }
  //     default:
  //       return state
  //   }
  // })
}

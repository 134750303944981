import React, { useState, useEffect, useMemo } from "react";
import {
  CardContent,
  CardMedia,
  Typography,
  CardActionArea,
  Button,
  Grid,
  IconButton,
  useMediaQuery
} from "@mui/material";
import { Container, Search, CardTrilha, TabsTrilhas } from "./styles";
import { useSelector } from "react-redux";
import api from "../../services/api";
import capaTrilha from "../../assets/capaTrilha.png";
import search from "../../assets/iconesOxeLab/search.svg";
import history from "../../services/history";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import ContentPasteSearchIcon from "@mui/icons-material/ContentPasteSearch";
import FiltroTrilhas from "./FiltroTrilhas";
import CircularProgress from "@mui/material/CircularProgress";
import { ModalDesempenhoAluno } from "../../components/PageTrilhaDeConhecimento/ModalDesempenhoAluno";
import { CustomTooltip, Logo } from "../../components/PageTrilhaDeConhecimento/styles";
import logo from '../../assets/logo_OXETRILHAS.png'
import { DownloadCertificadoButton } from './Certificado/index'
const theme = createTheme({
  palette: {
    primary: {
      main: "rgba(212, 109, 13, 1)",
      darker: "rgba(212, 109, 13, 1)",
    },
  },
});

function PageTrilhaNew() {
  const [trilhas, setTrilhas] = useState([]);
  const [busca, setBusca] = useState("");
  const [filtroIniciadas, setFiltroIniciadas] = useState(false);
  const [openModalDesempenho, setOpenModalDesempenho] = useState(false);
  const [selectedPerformance, setSelectedPerformance] = useState(null);
  let profile = useSelector((state) => state.user.profile.dataValues ? state.user.profile.dataValues : state.user.profile);
  const handleCloseModalDesempenho = () => setOpenModalDesempenho(false);
  const signed = useSelector((state) => state.auth.signed);
  const [loading, setLoading] = useState(false);
  const isSmallScreen = useMediaQuery('(max-width:600px)');

  useEffect(() => {
    const fetchTrilhas = async () => {
      if (signed && !filtroIniciadas) {
        try {
          setLoading(true);
          const response = await api.get("/trilhas-de-conhecimento/aluno");
          setTrilhas(response.data);
          setLoading(false);
        } catch (error) {
          console.error("Erro ao buscar trilhas de conhecimento:", error);
        }
      } else if (signed && filtroIniciadas) {
        try {
          setLoading(true);
          const response = await api.get(
            "/trilhas-de-conhecimento/inscricao/trilhas-by-aluno"
          );
          setTrilhas(response.data);
          setLoading(false);
        } catch (error) {
          console.error("Erro ao buscar trilhas de conhecimento:", error);
        }
      } else {
        try {
          setLoading(true);
          const response = await api.get("/trilhas-de-conhecimento/");
          setTrilhas(response.data);
          setLoading(false);
        } catch (error) {
          console.error("Erro ao buscar trilhas de conhecimento:", error);
        }
      }
    };

    fetchTrilhas();
  }, [filtroIniciadas]);

  const handleComecarTrilha = (trilha_id) => {
    api
      .post(`/trilhas-de-conhecimento/inscricao`, { trilhaId: trilha_id })
      .then((response) => {
        history.push(`/oxetrilha/${trilha_id}`);
      })
      .catch((err) => {
        console.log(err.response.data.error);
        alert(err.response.data.error);
      });
  };

  const trilhasSearch = useMemo(() => {
    //Para não precisar ficar recalculando a busca
    const lowerSearch = busca.toLowerCase();
    const busca1 = (turma) => turma.titulo.toLowerCase().includes(lowerSearch);
    const titulo = trilhas.filter(busca1);
    return titulo;
  }, [busca, trilhas]);

  const handleOpenModalDesempenho = (event, idx) => {
    event.stopPropagation();
    setOpenModalDesempenho(true);
    setSelectedPerformance(trilhasSearch[idx].performance);
  };

  const handleNaoLogado = () => {
    history.push("/select-cadastro");
  };

  const handleAcessarTrilha = (id) => {
    history.push(`/oxetrilha/${id}`);
  };

  return (
    <ThemeProvider theme={theme}>
      <Container>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
            padding: "15px",
          }}
        >
          <Logo>
          <img src={logo} className="img" alt="logo-oxetrilhas" />
          <h4 style={{ fontSize: isSmallScreen ? "0.8em": "1.4em",}}>Qualifique-se com nossas melhores trilhas de conhecimento</h4>
          </Logo>
        </div>
        <div style={{ width: isSmallScreen? "99vw":"90vw", maxWidth: "2000px" }}>
          <Search>
            <img src={search} width="3%" alt="Busca" />
            <input
              className="input"
              placeholder="Pesquise o nome de uma trilha..."
              type="text"
              value={busca}
              onChange={(e) => setBusca(e.target.value)}
            />
          </Search>
          <FiltroTrilhas
            onOptionSelect={(option) => setFiltroIniciadas(option)}
          />
          <Grid
            container
            spacing={{ xs: 1, md: 3, lg: 3 }}
            sx={{ marginBottom: 5 }}
          >
            {loading && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100vw",
                  height: "50vh",
                }}
              >
                <CircularProgress color="primary" />
              </div>
            )}
            {trilhasSearch.map((trilha, i) => (
              <Grid item xl={4} lg={4} md={6} sm={12} xs={12} key={i}>
                <CardTrilha
                  style={{
                    cursor: trilha.inscrito ? "pointer" : "default",
                  }}
                  onClick={() =>
                    trilha.inscrito && handleAcessarTrilha(trilha.id)
                  }
                >
                  <CardMedia
                    component="img"
                    height="120"
                    image={capaTrilha}
                    alt={trilha.titulo}
                  />
                  <CardContent
                    sx={{ flex: 1, display: "flex", flexDirection: "column" }}
                  >
                    <Typography gutterBottom variant={isSmallScreen? "h4":"h6"}>
                      {trilha.titulo}
                    </Typography>
                    <Typography
                      variant={isSmallScreen? "h6":"body2"}
                      color="text.secondary"
                      sx={{ flex: 1, minHeight: 100, maxHeight: 100 }}
                    >
                      {trilha.descricao}
                    </Typography>
                  </CardContent>
                  <div style={{ display: "flex", justifyContent: "end" }}>
                    {!trilha.inscrito ? (
                      <CardActionArea
                        sx={{
                          marginRight: 1.5,
                          width: "30%",
                          display: "flex",
                          justifyContent: "end",
                        }}
                      >
                        <Button
                          variant="contained"
                          sx={{ marginBottom: 1,   width: isSmallScreen ? "50%" : "70%",
                        }}
                          onClick={(e) => {
                            e.stopPropagation();
                            signed
                              ? handleComecarTrilha(trilha.id)
                              : handleNaoLogado();
                          }}
                        >
                          Começar
                        </Button>
                      </CardActionArea>
                    ) : (
                      <div style={{ display: "flex" }}>
                        <IconButton
                          onClick={(e) => handleOpenModalDesempenho(e, i)}
                          sx={{ padding: "0" }}
                        >
                          <CustomTooltip title="Desempenho">
                            <ContentPasteSearchIcon sx={{ color: "#BD6F57" }} />
                          </CustomTooltip>
                        </IconButton>

                        <CardActionArea>
                          <Button
                            sx={{ marginBottom: 1,fontSize:isSmallScreen && "1.1em" }}
                            startIcon={<PlayArrowIcon />}
                            onClick={(e) => {
                              handleAcessarTrilha(trilha.id);
                            }}
                          >
                            Continuar
                          </Button>
                        </CardActionArea>
                        {trilha.concluido && (
                        <div style={{ marginRight: "5px" }}>
                          <DownloadCertificadoButton
                          nome={profile.name}
                          curso={trilha.titulo}
                          cargaHoraria={trilha.carga_horaria}
                        />
                        </div>
                        
                      )}
                      </div>
                    )}
                  </div>
                </CardTrilha>
              </Grid>
            ))}
          </Grid>
        </div>
      </Container>
      <ModalDesempenhoAluno
        open={openModalDesempenho}
        close={handleCloseModalDesempenho}
        performance={selectedPerformance}
      />
    </ThemeProvider>
  );
}

export default PageTrilhaNew;

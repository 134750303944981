import * as React from "react";
import Box from "@mui/material/Box";

import OxeTechEduTurmas from "../../../components/Bolsista/Turmas";
import { useLocation, useParams } from "react-router-dom";
import { ValidarAlunos } from "../../ValidarAlunos";

function Main({ styles }) {

  const location = useLocation();
  const { escolaId } = useParams();

  const prefix = `/escola/${escolaId}`;

  let content = "";
    if (location.pathname.startsWith(prefix)) {
    content = <OxeTechEduTurmas />;
  } else if (location.pathname.startsWith(prefix + "/turma-selecao-painel/")) {
    // content = <DashboardTurmaSelecao/>;
  } else if (location.pathname.startsWith(prefix + "/turma-iniciada-painel/")) {
    // content = <DashboardTurmaIniciada/>;
  }else if (location.pathname.startsWith(prefix + "/validar/")) {
    content = <ValidarAlunos/>;
  }  else {
    content = <OxeTechEduTurmas />;
  }

  return (
    <Box
      component="main"
      sx={{ ...styles, backgroundColor: "#FFFFFF", flexGrow: 1, p: 1 }}
    >
      {content}
    </Box>
  );
}

export default Main;

import {
  Alert,
  Grid,
  Paper,
  Snackbar,
  Tooltip,
  Typography,
  styled,
  Menu,
  MenuItem,
} from "@mui/material";
import { Link, useParams } from "react-router-dom";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import IconButton from "@mui/material/IconButton";
import React, { useEffect, useState } from "react";
import api from "../../services/api";
import formatDate from "../../utils/formartDate";
import Grafico from "./Grafico";
import TableCard from "./TableCard";
import { styles } from "./styles";
import { useLab } from "../../hooks/useLab";
import { differenceInYears } from 'date-fns';

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  justifyItems: "center",
  color: theme.palette.text.secondary,
  borderRadius: "15px",
}));

function DashboardTurma() {
  const lab = useLab();
  const { labId, id } = useParams();
  const [turma, setTurma] = useState({});
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [atualizarTurma, setAtualizarTurma] = useState(false);
  const [matriculados, setMatriculados] = useState([]);
  const [aulas, setAulas] = useState([]);

  const today = new Date();
  const dataEncerramento = new Date(turma.data_encerramento);

  const diasAteEncerramento = Math.floor(
    (dataEncerramento - today) / (1000 * 60 * 60 * 24)
  );

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    const fetchTurma = async () => {
      try {
        const resTurma = await api.get(`/turma/${id}`);
        setTurma(resTurma.data);
      } catch (error) {
        setError(error.response.data.error);
      }
    };

    fetchTurma();
  }, [success, atualizarTurma]);

  useEffect(() => {
    const fetchAulasEMatriculas = async () => {
      try {
        const responseAulas = await api.get(`/aula/turmas/${id}`);
        setAulas(responseAulas.data);

        const responseMatriculas = await api.get(`/matriculas/turma/${id}`);
        setMatriculados(responseMatriculas.data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchAulasEMatriculas();
  }, []);

  const tabAulas = {
    name: "Aulas",
    rows: aulas?.map((aula) => {
      return {
        id: aula.id,
        titulo: aula.titulo,
        assunto: aula.assunto,
        data: formatDate(aula.data),
        frequencia: aula.frequencia,
      };
    }),
    columns: [
      {
        field: "titulo",
        headerName: "Título",
        width: 500,
      },
      {
        field: "assunto",
        headerName: "Assuntos",
        width: 500,
      },
      {
        field: "data",
        headerName: "Data",
        width: 150,
      },
      {
        field: "frequencia",
        headerName: "Frequência (%)",
        width: 200,
      },
    ],
  };



  const inicioAulasData =
    turma.data_inicio?.slice(8, 10) +
    "-" +
    turma.data_inicio?.slice(5, 7) +
    "-" +
    turma.data_inicio?.slice(0, 4);

  const encerramentoAulasData =
    turma.data_encerramento?.slice(8, 10) +
    "-" +
    turma.data_encerramento?.slice(5, 7) +
    "-" +
    turma.data_encerramento?.slice(0, 4);

  const tabSituacaoIniciada = {
    name: "Situação dos alunos",
    rows: matriculados?.map((insc) => {
      const idade = differenceInYears(new Date(), new Date(insc.data_nascimento));
      return {
        id: insc.id,
        status: insc.status,
        nome: insc.aluno,
        email: insc.email,
        idade: idade,
        telefone: insc.telefone,
        percentual_faltas: insc.percentual_faltas,
      };
    }),
    columns: [
      {
        field: "nome",
        headerName: "Nome",
        width: 500,
      },
      {
        field: "email",
        headerName: "Email",
        width: 500,
      }, 
      {
        field: "idade",
        headerName: "Idade",
        width: 80,
      },
      {
        field: "telefone",
        headerName: "Telefone",
        width: 300,
      },
      {
        field: "percentual_faltas",
        headerName: "Faltas (%)",
        width: 500,
      },
    ],
  };
  const tabSituacaoEncerrada = {
    name: "Situação dos alunos",
    rows: matriculados?.map((insc) => {
      const idade = differenceInYears(new Date(), new Date(insc.data_nascimento));
      return {
        id: insc.id,
        status: insc.status,
        nome: insc.aluno,
        email: insc.email,
        idade: idade,
        telefone: insc.telefone,
        percentual_faltas: insc.percentual_faltas,
      };
    }),
    columns: [
      {
        field: "nome",
        headerName: "Nome",
        width: 500,
      },
      {
        field: "email",
        headerName: "Email",
        width: 500,
      },
      {
        field: "idade",
        headerName: "Idade",
        width: 80,
      },
      {
        field: "telefone",
        headerName: "Telefone",
        width: 300,
      },
      {
        field: "percentual_faltas",
        headerName: "Faltas (%)",
        width: 300,
      },
      // {
      //   field: "status",
      //   headerName: "Situação",
      //   width: 400,
      //   renderCell: (params) => {
      //     return params.value === "2"
      //       ? aprovado
      //       : params.value === "0"
      //       ? reprovado
      //       : matriculado;
      //   },
      // },
    ],
  };
  const tablesTurmaIniciada = [tabAulas, tabSituacaoIniciada];
  const tablesTurmaEncerrada = [tabAulas, tabSituacaoEncerrada];

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} md={12}>
        <Item sx={styles.titleCaixa}>
          <div>
            <h3 style={styles.title}>{turma?.titulo}</h3>
            <div style={{ display: "flex", width: "120%" }}>
              <h6 style={styles.subtitle}>
                Início: <strong>{inicioAulasData}</strong>
              </h6>
              <h6 style={styles.subtitle}>
                Encerramento: <strong>{encerramentoAulasData}</strong>
              </h6>
            </div>
          </div>
          <Tooltip title="Mais">
            <IconButton
              aria-label="more"
              id="long-button"
              aria-controls={open ? "long-menu" : undefined}
              aria-expanded={open ? "true" : undefined}
              aria-haspopup="true"
              onClick={handleClick}
            >
              <MoreVertIcon style={{ color: "white" }} />
            </IconButton>
          </Tooltip>
          <Menu
            id="long-menu"
            MenuListProps={{
              "aria-labelledby": "long-button",
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            PaperProps={{
              style: {
                width: "auto",
              },
            }}
          >
            <Link
              to={`/laboratorio/${labId}/turma-selecao-painel/${turma.id}`}
              style={{ color: "black" }}
            >
              <MenuItem
              // onClick={handleEncerrarTurma}
              >
                Painel de Seleção
              </MenuItem>
            </Link>
            <Link to={`/perfil-laboratorio/${labId}`} style={{ color: "black" }}>
              <MenuItem>Fechar</MenuItem>
            </Link>
          </Menu>
          {/* <div onClick={() => history.goBack()} style={{ cursor: "pointer" }}>
            <Tooltip title="Sair">
              <CloseIcon />
            </Tooltip>
          </div> */}
        </Item>
      </Grid>
      <Grid container direction="row" spacing={1} item xs={12} sm={6} md={4}>
        <Grid item xs={12}>
          <Item sx={styles.card1}>
            <div>
              {turma.qtd_vagas_preenchidas}
              <Typography style={styles.subtext}>alunos</Typography>
            </div>
          </Item>
        </Grid>
        <Grid item xs={12}>
          <Item sx={styles.card1}>
            <div>
              {diasAteEncerramento < 0 ? 0 : diasAteEncerramento}
              <Typography style={styles.subtext}>
                dias até o encerramento{" "}
              </Typography>
            </div>
          </Item>
        </Grid>
      </Grid>

      <Grid item xs={12} sm={12} md={8}>
        <Item sx={styles.card4}>
          <Grafico aulas={aulas} />
        </Item>
      </Grid>

      <Grid item xs={12}>
        <TableCard
          tables={
            turma.status === "2" ? tablesTurmaIniciada : tablesTurmaEncerrada
          }
          turma={turma}
          permissao={lab.isCoordenador || lab.super_agente}
          aulasNumero={aulas.length}
        />
      </Grid>

      <Snackbar
        open={success.length > 0}
        autoHideDuration={7000}
        onClose={() => setSuccess("")}
      >
        <Alert
          variant="filled"
          onClose={() => setSuccess("")}
          severity="success"
          sx={{ width: "100%" }}
        >
          {success}
        </Alert>
      </Snackbar>
      <Snackbar
        open={error.length > 0}
        autoHideDuration={7000}
        onClose={() => setError("")}
      >
        <Alert
          variant="filled"
          onClose={() => setError("")}
          severity="error"
          sx={{ width: "100%" }}
        >
          {error}
        </Alert>
      </Snackbar>
    </Grid>
  );
}

export default DashboardTurma;

import React, { useEffect, useState } from "react";
import {
  Modal,
  Paper,
  TextField,
  Grid,
  Button,
  Box,
  Typography,
  Snackbar,
  SnackbarContent,
} from "@mui/material";
import * as palette from "../../utils/styledVariables";
import api from "../../services/api";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import ptLocale from "date-fns/locale/pt-BR";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { useSnackbar, SnackbarProvider } from "notistack";
import { id } from "date-fns/locale";

const styles = {
  modal: {
    width: "50%",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "#f2f2f2",
    padding: "10px",
    textAlign: "center",
    borderRadius: "1vh",
    justifyContent: "center",
  },

  uploadButton: {
    height: "40px",
    color: palette.roxoMedio,
  },

  paper: {
    width: "100%",
    margin: "5px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  field: { padding: "5px" },
};

function ModalEditarCiclo(props) {
  const { enqueueSnackbar } = useSnackbar();
  const today = new Date();
  const [numeroCiclo, setNumeroCiclo] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [id, setId] = useState(null);
  const [openSucess, setOpenSucess] = useState(false);
  const [messageSuccess, setMessageSuccess] = useState("");
  const [edital, setEdital] = useState(null);
  const [open, setOpen] = useState(false);

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={() => {
          setOpenSucess(false);
        }}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  const handleCloseModal = () => {
    setOpen(false);
  };

  const handleClose = () => {
    props.onClose();
  };

  const handleNumeroCiclo = (e) => {
    const { value } = e.target;
    setNumeroCiclo(value);
  };

  const handleStartDate = (value) => {
    setStartDate(value);
  };

  const handleEndDate = (value) => {
    setEndDate(value);
  };

  const handleFinish = async () => {
    try {
      const data = {
        num_ciclo: numeroCiclo,
        start: startDate,
        end: endDate,
        edital_id: edital,
      };

      const response = await api.put(`oxetechwork/ciclo/${id}`, data);

      if (response.status === 200) {
        setOpenSucess(true);
        setMessageSuccess("O ciclo foi editado com sucesso!");
        setTimeout(() => {
            handleClose();
          }, 1000);
      } else {
        enqueueSnackbar("Erro durante a edição do ciclo", { variant: "error" });
        handleClose();
      }
    } catch (err) {
      console.log(err);
      enqueueSnackbar("Erro durante a edição do ciclo", { variant: "error" });
    }
  };

  useEffect(() => {
    const updateState = () => {
      setNumeroCiclo(props.ciclo.num_ciclo);
      setStartDate(props.ciclo.start);
      setEndDate(props.ciclo.end);
      setId(props.ciclo.id);
      setEdital(props.ciclo.edital_id);
    };
    updateState();
  }, [props.ciclo]);

  return (
    <Modal open={props.open}>
      <ClickAwayListener onClickAway={handleClose}>
        <Box>
          <IconButton
            size="large"
            sx={{ display: "flex", float: "right" }}
            component="label"
            onClick={handleClose}
          >
            <CloseIcon
              fontSize="large"
              sx={{ color: palette.profileWhiteShade }}
            />
          </IconButton>

          <Grid sx={styles.modal} container direction="row">
            <Grid container item xs={12} sm={12} md={12} lg={12}>
              <Paper
                sx={[
                  {
                    backgroundColor: palette.laranjaOXLTransparente,
                    height: "50px",
                  },
                  styles.paper,
                ]}
              >
                <Typography
                  sx={{
                    fontSize: "24px",
                    color: palette.preto,
                    fontFamily: "Poppins",
                    fontWeight: 500,
                  }}
                >
                  Edição de Ciclo
                </Typography>
              </Paper>
            </Grid>

            <Paper sx={styles.paper}>
              <Grid container item xs={12} sm={12} md={12} lg={12}>
                <Grid item xs={12} sm={12} md={6} lg={6} sx={styles.field}>
                  {"Número do ciclo"}
                  <TextField
                    hiddenLabel
                    id="nome-edital"
                    variant="filled"
                    fullWidth
                    value={numeroCiclo}
                    onChange={handleNumeroCiclo}
                    onBlur={handleNumeroCiclo}
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={6} lg={6} sx={styles.field}>
                  {"Data de abertura"}
                  <LocalizationProvider
                    locale={ptLocale}
                    dateAdapter={AdapterDateFns}
                  >
                    <DatePicker
                      value={startDate}
                      onChange={handleStartDate}
                      renderInput={(params) => (
                        <TextField
                          variant="filled"
                          fullWidth
                          {...params}
                          sx={styles.field}
                        />
                      )}
                      minDate={"01/01/2001"}
                      startDate={startDate}
                      inputFormat="dd/MM/yyyy"
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} sx={styles.field}>
                  {"Data de encerramento"}
                  <LocalizationProvider
                    locale={ptLocale}
                    dateAdapter={AdapterDateFns}
                  >
                    <DatePicker
                      value={endDate}
                      onChange={handleEndDate}
                      renderInput={(params) => (
                        <TextField
                          variant="filled"
                          fullWidth
                          {...params}
                          sx={styles.field}
                        />
                      )}
                      minDate={"01/01/2001"}
                      endDate={endDate}
                      inputFormat="dd/MM/yyyy"
                    />
                  </LocalizationProvider>
                </Grid>
              </Grid>
            </Paper>

            <Button
              onClick={handleFinish}
              variant="contained"
              sx={{
                height: "40px",
                bgcolor: palette.laranjaSecundario,
                "&:hover": {
                  bgcolor: palette.laranjaIntermediario2,
                },
                marginTop: "5px",
              }}
            >
              Editar ciclo
            </Button>
          </Grid>
          <Snackbar open={openSucess} autoHideDuration={60} action={action}>
            <SnackbarContent
              style={{
                backgroundColor: "green",
              }}
              message={`Sucesso! ${messageSuccess}`}
              action={action}
            />
          </Snackbar>
        </Box>
      </ClickAwayListener>
    </Modal>
  );
}

export default ModalEditarCiclo;

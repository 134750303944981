import React, { useState } from 'react';
import { styled, alpha } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import * as palette from "../../utils/styledVariables"
const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    PaperProps={{ // Estilos de papel do menu
        style: {
          boxShadow: '0 2px 5px rgba(0, 0, 0, 0.2)', 
        },
      }}
    {...props}
  />
  ))({
    minWidth: '30px', 
    marginTop: 4, 
  });
const CustomButton = styled(Button)({
    background: palette.laranjaOxeLab,
    borderRadius: 3,
    border: 0,
    color: 'white',
    height: 36,
    padding: '0 20px',
    '&:hover': {
        background: '#ED7E39', 
      },
    boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
  });
function FiltroTurmas({ onOptionSelect }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedOption, setSelectedOption] = useState('Inscrito'); // Estado para rastrear a opção selecionada

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    onOptionSelect(option);
    handleClose();
  };

  return (
    <div style={{display: 'flex', width: '92%', justifyContent: 'end', marginBottom: '7px'}}>
      <CustomButton
        id="demo-customized-button"
        aria-controls={open ? 'demo-customized-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="contained"
        disableElevation
        onClick={handleClick}
        
        endIcon={<KeyboardArrowDownIcon />}
      >
        {selectedOption}
      </CustomButton>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={() => handleOptionClick('Inscrito')} disableRipple>
          Inscrito
        </MenuItem>
        <MenuItem onClick={() => handleOptionClick('Matriculado')} disableRipple>
          Matriculado
        </MenuItem>
      </StyledMenu>
    </div>
  );
}

export default FiltroTurmas;

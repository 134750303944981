import cep from "cep-promise";
import React, { useState } from "react";
import InputMask from "react-input-mask";
import { useDispatch, useSelector } from "react-redux";
import { updateProfileRequest } from "../../store/modules/user/actions";
import * as palette from "../../utils/styledVariables";

import {
    Box,
    Button,
    Card,
    Grid,
    TextField,
    Typography,
    styled,
    Select,
    FormControl,
    InputLabel,
    MenuItem,
    OutlinedInput

} from "@mui/material";

import { LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import ptLocale from "date-fns/locale/pt-BR";
import Options from "../../utils/options";


const Title = styled((props) => <Typography {...props} />)(() => ({
    fontFamily: "Roboto",
    fontStyle: "bold",
    fontWeight: "500",
    fontSize: "3vh",
    lineHeight: "3vh",
    margin: "10px 0px 0px 10px",
}));

const Subtitles = styled((props) => <Typography {...props} />)(() => ({
    fontFamily: "Roboto",
    fontStyle: "bold",
    fontWeight: "500",
    fontSize: "2.5vh",
    lineHeight: "2.5vh",
    margin: "0px 0px 5px 0px",
}));

const StyledCard = styled((props) => <Card {...props} />)(() => ({
    margin: "20px",
    background: palette.laranjaOXLTransparente//"rgba(173, 137, 191, 0.22)",
}));

const ChangeButton = styled((props) => <Button {...props} />)(() => ({
    background: palette.gradienteLaranja,
        //"radial-gradient(292.41% 335.33% at 41.6% 50%, #741F8A 2.22%, #1609B3 100%)",
    color: palette.profileWhiteShade,
    float: "right",
    "margin-right": "10px",
    "margin-bottom": "10px",
}));

function DadosCadastraisAluno({styles}) {
    let profile = useSelector((state) => state.user.profile.dataValues ? state.user.profile.dataValues : state.user.profile);
    const [rua, setrua] = useState(profile.rua);
    const [bairro, setbairro ] = useState(profile.bairro);

    const [municipio, setmunicipio] = useState(profile.municipio);
    const [CEP, setCEP] = useState(profile.cep);
    const [state, setState] = useState(profile.estado);
    const [numero, setnumero] = useState(profile.numero)

    const [socialName, setSocialName] = useState(profile.nome_social);
    const [genero, setGender] = useState(profile.genero);
    const [email, setEmail] = useState(profile.email);
    const [sobre_mim, setsobre_mim] = useState(profile.sobre_mim);
    const [telefone, settelefone] = useState(profile.telefone);
    const [name, setName] = useState(profile.name);
    const [linkedin, setLinkedin] = useState(profile.linkedin);
    const [github, setGithub] = useState(profile.github)
    const [data_nascimento, setdata_nascimento] = useState(profile.data_nascimento)
    const [errorCep, setErrorCep] = useState(null);
    const [errorEmail, setErrorEmail] = useState(null);
    const [errorName, setErrorName] = useState(null);
    const [address, setAddress] = useState(null);

    const [newPass, setNewPass] = React.useState({showPassword: false, pass: ''});
    const [confirmPass, setConfirmPass] = React.useState({showPassword: false, pass: ''});
    const [oldPass, setOldPass] = React.useState({showPassword: false, pass: ''});
    const [error, setError] = React.useState();
    const [error1, setError1] = React.useState();

    const [password, setPassword] = React.useState(null);
    

    const dispatch = useDispatch();
    
    const handleSocialName = (event) => {
        const { target: { value }} = event;
        setSocialName(value);
        // sessionStorage.setItem("socialName", value);
    };    
    const handleGender = (event) => {
        setGender(event.target.value);
        // sessionStorage.setItem("gender", value);
      };
    
    
    const handleCep = (event) => {
        setCEP(event.target.value);
        setErrorCep({ CEP: "" });

        let reg = new RegExp(/^[0-9]{2}\.[0-9]{3}-[0-9]{3}$/).test(event.target.value);
        if (reg) {
            cep(event.target.value)
                .then((response) => {
                    const {
                        street: rua,
                        neighborhood: bairro,
                        city: municipio,
                        cep,
                        state: estado,
                    } = response;

                    setrua(rua);
                    setbairro(bairro);
                    setmunicipio(municipio);  
                    setState(estado);         
                    
                })
                .catch((error) => {
                    setErrorCep({ CEP: "Não conseguimos resgatar o seu CEP." });
                    setAddress({
                        rua: "",
                        bairro: "",
                        municipio: "",
                        cep: "",
                        state: "",
                        numero: "",
                    });
                    
                });
        } else {
            setErrorCep({ CEP: "CEP inválido." });
        }
    };

    const handletelefone = (event) => {
        settelefone(event.target.value);
    };

    const handleEmail = (event) => {
        const {
            target: { value },
        } = event;
        setEmail(value);
        setErrorEmail({ email: "" });

        if (value.trim().length > 0) {
            let reg = new RegExp(
                /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            ).test(value);
            if (!reg)
                setErrorEmail({ email: "Insira um email válido." });

        } else {
            setErrorEmail({ email: "Preencha esse campo." });
        }
    };

    
    const handleName = (event) => {
        setName(event.target.value);
        setErrorName({ name: "" });
        if (event.target.value.length <= 0) setErrorName({ name: "Preencha esse campo." });
    };

    const handlerua = (event) => {
        setrua(event.target.value);
    };

    const handlebairro = (event) => {
        setbairro(event.target.value);
    };

    const handlenumero = (event) => {
        setnumero(event.target.value);
        if (event.target.value.length > 0) sessionStorage.setItem("numero", event.target.value);
        else {
          sessionStorage.removeItem("numero");
        }
    };

    const handlemunicipio = (event) => {
        setmunicipio(event.target.value);
    };

    
      const handledata_nascimento = (newValue) => {
        setdata_nascimento(newValue);
        sessionStorage.setItem("data_nascimento", new Date(newValue).toJSON());
      };
    
      const handlePassword =(event) => {
        setNewPass({...newPass,pass:event.target.value});
        
        setError("");
     
        if (event.target.value.length < 8) {
          setError("Senha deve ter no mínimo 8 caracteres!");
          sessionStorage.removeItem("pass");
        } else {
            if (newPass.pass !== event.target.value && confirmPass.pass !== event.target.value) {
                setError1("Senhas diferentes.");
                sessionStorage.removeItem("pass");
              } else {
                sessionStorage.setItem("pass", event.target.value);
              };
        };
      };

    const handleOldPassword = (event) =>{
        setOldPass({...oldPass, pass: event.target.value})
    }
    const handlePass =(event) => {
        setConfirmPass({...confirmPass, pass: event.target.value});
        setError1("");
        
        if (newPass.pass !== event.target.value && confirmPass.pass !== event.target.value) {
            setError1("Senhas diferentes.");
            sessionStorage.removeItem("pass");
          } else {
            sessionStorage.setItem("pass", event.target.value);
          };
      };

    const handleClickShowPassword = () => {
        setNewPass({ ...newPass, showPassword: !newPass.showPassword });
        setConfirmPass({ ...confirmPass, showPassword: !confirmPass.showPassword });
      };
    
      const handleMouseDownPassword = (event) => {
        event.preventDefault();
      };

    const TransformData = (cpf, email, name, rua, bairro, cep, data_nascimento, sobre_mim, municipio, telefone, numero, linkedin, github, oldPass, newPass, confirmPass, socialName, genero) => {
        const data = {}

        // if (!cpf) return;
        // data.cpf = cpf;
        if (name) data.name =name;
        
        if (email) data.email = email;

        if (rua) data.rua = rua;

        if (bairro) data.bairro = bairro;

        if (cep) data.cep = cep;

        if (data_nascimento) data.data_nascimento = data_nascimento;

        if (sobre_mim) data.sobre_mim = sobre_mim;

        if (municipio) data.municipio = municipio;

        if (telefone) data.telefone = telefone;

        if (numero) data.numero = numero;

        if (linkedin) data.linkedin = linkedin;

        if (github) data.github = github;

        if (numero) data.numero = numero;

        data.nome_social = socialName;

        if (genero) data.genero = genero

        // if (oldPass && newPass && confirmPass && newPass === confirmPass) {
        //     data.password = {
        //         old: oldPass,
        //         new: newPass
        //     }
        // }
        return data;
    }

    const onFinish = async () => {
        const data = TransformData(profile.cpf, email, name, rua, bairro, CEP, data_nascimento, sobre_mim, municipio, telefone, numero, linkedin, github, oldPass.pass, newPass.pass, confirmPass.pass, socialName, genero);
        
        dispatch(updateProfileRequest(data));
    };

    return (
        <Box component="main"
            sx={{ ...styles, flexGrow: 1, p: 1, width: { sm: `calc(100% - 20vh)` } }}>
            <StyledCard >
                <Title>Dados do aluno</Title>

                <Grid container columns={2} sx={{ padding: "10px" }} spacing={1}>                   

                    <Grid item xs={2} sm={2} md={1}>
                        <Subtitles>Nome</Subtitles>
                        <TextField
                            hiddenLabel
                            id="nome-aluno"
                            variant="filled"
                            fullWidth
                            value={name}
                            onChange={handleName}
                            onBlur={handleName}
                            error={Boolean(errorName?.name)}
                            helperText={errorName?.name}
                            
                        />
                    </Grid>

                    <Grid item xs={2} sm={2} md={1}>
                        <Subtitles>Nome Social</Subtitles>
                        <TextField
                            hiddenLabel
                            id="socialName"
                            variant="filled"
                            fullWidth
                            value={socialName}
                            onChange={handleSocialName}
                            onBlur={handleSocialName}
                        />
                    </Grid>
                    
                    <Grid item xs={2} sm={2} md={1}>
                        <Subtitles>CPF
                        </Subtitles>

                        <InputMask
                            mask="999.999.999-99"
                            value={profile.cpf}
                            disabled={true}
                            maskChar=" "
                        >
                            {() => (
                                <TextField
                                    hiddenLabel
                                    id="cpf"
                                    variant="filled"
                                    fullWidth
                                    disabled
                                />
                            )}
                        </InputMask>
                    </Grid>
                    
                    <Grid item xs={2} sm={2} md={1}>
                        <Subtitles>Telefone</Subtitles>
                        <InputMask
                            mask="(99) 9 9999 9999"
                            value={telefone}
                            maskChar=" "
                            onChange={handletelefone}
                            onBlur={handletelefone}
                        >
                            {() => (
                                <TextField
                                    hiddenLabel
                                    id="telefone"
                                    variant="filled"
                                    fullWidth
                                />
                            )}
                        </InputMask>
                    </Grid>

                    <Grid item xs={2} sm={2} md={1}>
                        <Subtitles>Gênero</Subtitles>
                        <FormControl required fullWidth>
                            <Select
                                value={genero}
                                label="Gênero"
                                labelId="demo-simple-select-label"
                                onChange={handleGender}
                                fullWidth
                            >
                                {Options.optionsGender.map((option) => (
                                    <MenuItem value={option.value}>{option.value}</MenuItem>
                                ))}
                            </Select>
                            </FormControl>
                    </Grid>

                    <Grid item xs={2} sm={2} md={1}>
                    <Subtitles>Data de Nascimento</Subtitles>
                    <LocalizationProvider adapterLocale={ptLocale} dateAdapter={AdapterDateFns}>
                        <MobileDatePicker
                        id="data_nascimento"
                        disableFuture
                        cancelText="Cancelar"
                        inputFormat="dd/MM/yyyy"
                        value={data_nascimento}                        
                        onChange={(data_nascimento) => {
                            setdata_nascimento(data_nascimento);
                          }}
                          renderInput={(params) => <TextField {...params} />}
                        />
                    </LocalizationProvider>
            
                    </Grid>

                    </Grid>
                </StyledCard>
            

            <StyledCard>
                <Title>Endereço</Title>

                <Grid container columns={2} sx={{ padding: "10px" }} spacing={1}>
                    <Grid item xs={2} sm={2} md={1}>
                        <Subtitles>CEP</Subtitles>

                        <InputMask
                            mask="99.999-999"
                            value={CEP}
                            maskChar=" "
                            onBlur={handleCep}
                            onChange={handleCep}
                        >
                            {() => (
                                <TextField
                                    hiddenLabel
                                    id="cep"
                                    autoComplete="cep"
                                    variant="filled"
                                    fullWidth
                                    error={Boolean(errorCep?.CEP)}
                                    helperText={errorCep?.CEP}
                                    InputLabelProps={{
                                        shrink: true,
                                      }}
                                />
                            )}
                        </InputMask>
                    </Grid>

                    <Grid item xs={2} sm={2} md={1}>
                        <Subtitles>Rua</Subtitles>
                        <TextField
                            hiddenLabel
                            id="rua"
                            variant="filled"
                            fullWidth
                            value={rua}
                            onChange={handlerua}
                            
                        />
                    </Grid>
                    <Grid item xs={2} sm={2} md={1}>
                        <Subtitles>Número</Subtitles>
                        <TextField
                            hiddenLabel
                            id="numero"
                            variant="filled"
                            fullWidth
                            value={numero}
                            onChange={handlenumero}
                        />
                    </Grid>
                    <Grid item xs={2} sm={2} md={1}>
                        <Subtitles>Bairro</Subtitles>
                        <TextField
                            hiddenLabel
                            id="bairro"
                            variant="filled"
                            fullWidth
                            value={bairro}
                            onChange={handlebairro}
                        />
                    </Grid>
                    <Grid item xs={1} sm={1} md={1}>
                        <Subtitles>Município</Subtitles>
                        <TextField
                            hiddenLabel
                            id="municipio"
                            variant="filled"
                            fullWidth
                            value={municipio}
                            onChange={handlemunicipio}
                        />
                    </Grid>
                    
                </Grid>
            </StyledCard>

            <StyledCard>
                <Title>Acesso</Title>
                <Grid container columns={2} sx={{ padding: "10px" }} spacing={1}>
                    <Grid item xs={2} sm={2} md={1}>
                        <Subtitles>E-mail</Subtitles>
                        <TextField
                            hiddenLabel
                            id="email"
                            variant="filled"
                            fullWidth
                            value={email}
                            onBlur={handleEmail}
                            onChange={handleEmail}
                            error={Boolean(errorEmail?.email)}
                            helperText={errorEmail?.email}
                        />
                    </Grid>

                    {/* <Grid item xs={2} sm={2} md={1}>
                        <Subtitles name="currentPassword" >Senha Atual</Subtitles>
                        <FormControl fullWidth>
                        <FilledInput
                            hiddenLabel
                            fullWidth
                            id="oldPassword"
                            type={newPass.showPassword ? 'text' : 'password'}
                            value={password}
                            onChange={handleOldPassword}
                            variant="filled"
                            
                            name="currentPassword"
                            required

                            endAdornment={
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                  >
                                    {newPass.showPassword ? <VisibilityOff /> : <Visibility />}
                                  </IconButton>
                                </InputAdornment>
                              }
                        />
                        </FormControl>
                    </Grid>
                    

                    <Grid item xs={2} sm={2} md={1}>
                    <Subtitles label="Nova Senha" name="newPassword">Nova Senha</Subtitles>
                    <FormControl fullWidth>
                        <FilledInput
                        id="password"
                        type={newPass.showPassword ? 'text' : 'password'}
                        value={password}
                        onChange={handlePassword}
                        
                        endAdornment={
                            <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                            >
                                {newPass.showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                            </InputAdornment>
                        }
                        required
                        inputProps={{ minLength: 8 }}
                        />
                    </FormControl>
                    </Grid>
                        
                    

                    <Grid item xs={2} sm={2} md={1}>
                        <Subtitles>Confirme sua nova senha</Subtitles>
                        <FormControl fullWidth>
                        <FilledInput
                            hiddenLabel
                            id="confirmNewPassword"
                            value={password}
                            type={confirmPass.showPassword ? 'text' : 'password'}
                            variant="filled"
                            fullWidth
                            onChange = {handlePass}
                            dependencies={['password']}
                            name="confirmPassword"
                         
                            endAdornment={
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                  >
                                    {newPass.showPassword ? <VisibilityOff /> : <Visibility />}
                                  </IconButton>
                                </InputAdornment>
                              }
                         hasFeedback
                         />
                         </FormControl>
                    </Grid>
                     */}
                </Grid>
            </StyledCard>

            <StyledCard>
                <Title>Redes sociais</Title>

                <Grid container columns={2} sx={{ padding: "10px" }} spacing={1}>
                    <Grid item xs={2} sm={2} md={1}>
                        <Subtitles>LinkedIn</Subtitles>
                        <TextField
                            hiddenLabel
                            id="linkedin"
                            variant="filled"
                            fullWidth
                            value={linkedin}
                            onChange={(e) => {
                                setLinkedin(e.target.value);
                            }}
                        />
                    </Grid>

                    <Grid item xs={2} sm={2} md={1}>
                        <Subtitles>Github</Subtitles>
                        <TextField
                            hiddenLabel
                            id="github"
                            variant="filled"
                            fullWidth
                            value={github}
                            onChange={(e) => {
                                setGithub(e.target.value);
                            }}
                        />
                    </Grid>
                </Grid>
            </StyledCard>

            <ChangeButton sx={{ marginRight: "20px" }} size="large" onClick={onFinish}>Salvar alterações</ChangeButton>
        </Box>
    );
}
export default DadosCadastraisAluno;
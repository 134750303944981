import React, { useState, useEffect } from "react";
import { Grid, Button, Tooltip, IconButton } from "@mui/material";
import TextField from "@mui/material/TextField";
import close from "../../../../../assets/iconesOxeLab/close.svg";
import { Content, ModalCadastro } from "./styles";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import ptLocale from "date-fns/locale/pt-BR";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { addDays, subDays, parseISO } from "date-fns";
import Autocomplete from "@mui/material/Autocomplete";
import api from "../../../../../services/api";
import { useSelector } from "react-redux";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import CircularProgress from "@mui/material/CircularProgress";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { TimePicker } from "@mui/lab";
const theme = createTheme({
  palette: {
    primary: {
      main: "rgba(212, 109, 13, 0.5)",
      darker: "rgba(212, 109, 13, 1)",
    },
    secondary: {
      main: "rgba(212, 109, 13, 1)",
      darker: "rgba(212, 109, 13, 1)",
    },
  },
});
export default function ModalCadastroTurma(props) {
  const { escolaId } = useParams();
  // const labs = useSelector(state => state.user.labs_profile);
  // const lab = labs.find((lab) => lab.lab_id == escolaId);
  const [data, setData] = useState({
    titulo: sessionStorage.getItem("titulo") || "",
    descricao: sessionStorage.getItem("descricao") || "",
    qnt_Vagas: sessionStorage.getItem("quant_vagas") || null,
    dia_aula: sessionStorage.getItem("dia_aula") || "",
    horarioInicio: sessionStorage.getItem("horario_inicio") || "",
    horarioFim: sessionStorage.getItem("horario_fim") || "",
    dataFim_insc: sessionStorage.getItem("data_fim_inscricoes") || "",
    carga_horaria: sessionStorage.getItem("carga_horaria") || "",
  });
  const [open, setOpen] = useState(true);
  const [errorTitulo, setErrorTitulo] = useState("");
  const [errorDescricao, setErrorDescricao] = useState("");
  const [errorQnt_Vagas, setErrorQnt_Vagas] = useState("");
  const [errorDiaAula, setErrorDiaAula] = useState("");
  const [errorCargaHoraria, setErrorCargaHoraria] = useState("");
  const [errorHorarioInicio, setErrorHorarioInicio] = useState("");
  const [errorHorarioFim, setErrorHorarioFim] = useState("");
  const [errorData_encerramento_insc, setErrorData_encerramento_insc] =
    useState("");
  const today = new Date();
  const [alertSuccessful, setAlertSuccessful] = useState("");
  const [alertUnauthorized, setAlertUnauthorized] = useState("");
  const [loading, setLoading] = useState(false);

  const handleCheckError = () => {
    // console.log(data);
    if (
      !data.titulo ||
      !data.descricao ||
      !data.qnt_Vagas ||
      !data.dia_aula ||
      !data.horarioInicio ||
      !data.horarioFim ||
      !data.dataFim_insc ||
      !data.carga_horaria
    ) {
      !data.titulo && setErrorTitulo("Você não informou o nome da turma!");
      !data.descricao && setErrorDescricao("Você não preencheu a descrição!");
      !data.dataFim_insc &&
        setErrorData_encerramento_insc(
          "Você não informou a data do encerramento das inscrições"
        );
      !data.horarioInicio &&
        setErrorHorarioInicio("Você não informou o horário do início do curso");
      !data.horarioFim &&
        setErrorHorarioFim("Você não informou o horário do fim do curso");
      !data.qnt_Vagas &&
        setErrorQnt_Vagas("Você não informou o total de aulas!");
      !data.carga_horaria &&
        setErrorQnt_Vagas("Você não informou a carga horária!");
    } else {
      handleSubmit();
    }
  };

  const handleSubmit = () => {
    const data_form = {
      titulo: data.titulo,
      descricao: data.descricao,
      qtd_vagas_total: data.qnt_Vagas,
      dia_da_aula: data.dia_aula,
      hora_inicio: data.horarioInicio,
      hora_fim: data.horarioFim,
      encerramento_inscricoes: data.dataFim_insc,
      carga_horaria: data.carga_horaria,
      escola_id: escolaId,
    };
    // console.log('aaa',data_form);
    setLoading(true);
    api
      .post("/oxetechedu/turmas/", data_form)
      .then((response) => {
        setLoading(false);
        setAlertSuccessful(response.data.message);
        sessionStorage.removeItem("titulo");
        sessionStorage.removeItem("descricao");
        sessionStorage.removeItem("quant_vagas");
        sessionStorage.removeItem("dia_aula");
        sessionStorage.removeItem("horario_inicio");
        sessionStorage.removeItem("horario_fim");
        sessionStorage.removeItem("data_fim_inscricoes");
        sessionStorage.removeItem("carga_horaria");

        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((err) => {
        setLoading(false);
        setAlertUnauthorized(err.response.data.error);
      });
  };

  const handleTituloChange = (e) => {
    const { value } = e.target;
    setData((prevData) => ({ ...prevData, titulo: value }));
    setErrorTitulo("");
    sessionStorage.setItem("titulo", value);

    if (value.length === 0) {
      setErrorTitulo("Preencha este campo.");
    }
  };

  const handleDescricaoChange = (e) => {
    const { value } = e.target;
    const truncatedValue = value.slice(0, 255);
    setData((prevData) => ({ ...prevData, descricao: truncatedValue }));
    setErrorDescricao("");
    sessionStorage.setItem("descricao", truncatedValue);
  
    if (truncatedValue.length === 0) {
      setErrorDescricao("Preencha este campo.");
    } else if (truncatedValue.length === 255) {
      setAlertUnauthorized("Limite máximo de 255 caracteres atingido.");
    }
  };

  const handleDiaAulaChange = (date) => {
    setData((prevData) => ({
      ...prevData,
      dia_aula: new Date(date).toJSON(),
    }));
    setErrorDiaAula("");
    sessionStorage.setItem("dia_aula", new Date(date).toJSON());
  };

  const handleCargaHorariaChange = (e) => {
    setData((prevData) => ({
      ...prevData,
      carga_horaria: e.target.value,
    }));
    setErrorCargaHoraria("");
    sessionStorage.setItem("carga_horaria", e.target.value);

    if (e.target.value.length === 0) {
      setErrorCargaHoraria("Preencha este campo.");
    }
  };
  const handleHorarioInicioChange = (e) => {
    setData((prevData) => ({
      ...prevData,
      horarioInicio: e.target.value,
    }));
    sessionStorage.setItem("horario_inicio", e.target.value);
    setErrorHorarioInicio("");

    if (!e.target.value) {
      setErrorHorarioInicio("Preencha este campo.");
    }
  };

  const handleHorarioFimChange = (e) => {
    setData((prevData) => ({
      ...prevData,
      horarioFim: e.target.value,
    }));
    sessionStorage.setItem("horario_fim", e.target.value);
    setErrorHorarioFim("");
    if (!e.target.value) {
      setErrorHorarioFim("Preencha este campo.");
    }
  };

  const handleQuantVagasChange = (e) => {
    const { value } = e.target;
    setData((prevData) => ({ ...prevData, qnt_Vagas: value }));
    setErrorQnt_Vagas("");
    sessionStorage.setItem("quant_vagas", value);

    if (value.length === 0) {
      setErrorQnt_Vagas("Preencha este campo.");
    } else if (value <= 0) {
      setErrorQnt_Vagas("A quantidade de vagas deve ser maior que zero.");
    }
  };

  const handleDataEncerramentoInscChange = (e) => {
    setData((prevData) => ({
      ...prevData,
      dataFim_insc: new Date(e).toJSON(),
    }));
    setErrorData_encerramento_insc("");
    sessionStorage.setItem("data_fim_inscricoes", new Date(e).toJSON());

    if (e.length === 0) {
      setErrorData_encerramento_insc("Preencha este campo.");
    }
  };

  return (
    <ModalCadastro
      open={props.open}
      onClose={props.onHandleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Content>
        <ThemeProvider theme={theme}>
          <div className="header">
            <h1>Nova Turma</h1>
            <img
              className="close"
              src={close}
              alt="Fechar"
              onClick={props.onHandleClose}
            />
          </div>
          <div style={{ padding: "2%" }}>
            <Grid sx={{ p: "2% 1%" }} container spacing={2}>
              <Grid item xs={12} md={12}>
                <h3 className="label">Nome da Turma</h3>
                <TextField
                  sx={{
                    bgcolor: errorTitulo
                      ? "transparent"
                      : "rgba(212, 109, 13, 0.1)",
                  }}
                  placeholder="Digite o nome da turma"
                  focused
                  error={Boolean(errorTitulo)}
                  helperText={errorTitulo}
                  value={data.titulo}
                  fullWidth
                  onChange={handleTituloChange}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <h3 className="label">Descrição</h3>
                <TextField
                  multiline
                  rows={3}
                  placeholder="Descreva informações sobre o curso"
                  focused
                  sx={{
                    bgcolor: errorDescricao
                      ? "transparent"
                      : "rgba(212, 109, 13, 0.1)",
                  }}
                  error={Boolean(errorDescricao)}
                  helperText={errorDescricao}
                  value={data.descricao}
                  fullWidth
                  onChange={handleDescricaoChange}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <h3 className="label">Data de encerramento das inscrições</h3>
                <LocalizationProvider
                  locale={ptLocale}
                  dateAdapter={AdapterDateFns}
                >
                  <DatePicker
                    value={data.dataFim_insc}
                    onChange={handleDataEncerramentoInscChange}
                    renderInput={(params) => (
                      <TextField
                        size="large"
                        sx={{
                          bgcolor: errorData_encerramento_insc
                            ? "transparent"
                            : "rgba(212, 109, 13, 0.1)",
                        }}
                        focused
                        fullWidth
                        {...params}
                        error={Boolean(errorData_encerramento_insc)}
                        helperText={errorData_encerramento_insc}
                      />
                    )}
                    minDate={today}
                    maxDate={subDays(parseISO(data.dia_aula), 1)}
                    inputFormat="dd/MM/yyyy"
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12} md={4}>
                <h3 className="label">Dia da aula</h3>
                <LocalizationProvider
                  locale={ptLocale}
                  dateAdapter={AdapterDateFns}
                >
                  <DatePicker
                    value={new Date(data.dia_aula)}
                    onChange={handleDiaAulaChange}
                    renderInput={(params) => (
                      <TextField
                        size="large"
                        sx={{
                          bgcolor: errorDiaAula
                            ? "transparent"
                            : "rgba(212, 109, 13, 0.1)",
                        }}
                        focused
                        fullWidth
                        {...params}
                        error={Boolean(errorDiaAula)}
                        helperText={errorDiaAula}
                      />
                    )}
                    minDate={addDays(parseISO(data.dataFim_insc), 1)}
                    inputFormat="dd/MM/yyyy"
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12} md={4}>
                <h3 className="label">Carga Horária</h3>
                <TextField
                type="number"
                  placeholder="Digite a carga horária"
                  focused
                  sx={{
                    bgcolor: errorCargaHoraria
                      ? "transparent"
                      : "rgba(212, 109, 13, 0.1)",
                  }}
                  error={Boolean(errorCargaHoraria)}
                  helperText={errorCargaHoraria}
                  value={data.carga_horaria}
                  fullWidth
                  onChange={handleCargaHorariaChange}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <h3 className="label">Horário do início da aula</h3>
                <TextField
                  type="time"
                  value={data.horarioInicio}
                  onChange={handleHorarioInicioChange}
                  fullWidth
                  sx={{
                    bgcolor: errorHorarioInicio
                      ? "transparent"
                      : "rgba(212, 109, 13, 0.1)",
                  }}
                  focused
                  error={Boolean(errorHorarioInicio)}
                  helperText={errorHorarioInicio}
                  color="primary"
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <h3 className="label">Horário do fim da aula</h3>
                <TextField
                  type="time"
                  value={data.horarioFim}
                  onChange={handleHorarioFimChange}
                  fullWidth
                  sx={{
                    bgcolor: errorHorarioFim
                      ? "transparent"
                      : "rgba(212, 109, 13, 0.1)",
                  }}
                  focused
                  error={Boolean(errorHorarioFim)}
                  helperText={errorHorarioFim}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <h3 className="label">Quantidade de Vagas</h3>
                <TextField
                  type="number"
                  focused
                  sx={{
                    bgcolor: errorQnt_Vagas
                      ? "transparent"
                      : "rgba(212, 109, 13, 0.1)",
                  }}
                  error={Boolean(errorQnt_Vagas)}
                  helperText={errorQnt_Vagas}
                  value={data.qnt_Vagas}
                  size="large"
                  fullWidth
                  onChange={handleQuantVagasChange}
                />
              </Grid>
            </Grid>

            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                startIcon={
                  loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null
                }
                variant="contained"
                color="secondary"
                onClick={handleCheckError}
              >
                Criar Turma
              </Button>
            </div>
          </div>
        </ThemeProvider>
        <Snackbar
          open={alertUnauthorized.length > 0}
          autoHideDuration={6000}
          onClose={() => setAlertUnauthorized("")}
        >
          <Alert
            variant="filled"
            onClose={() => setAlertUnauthorized("")}
            severity="error"
            sx={{ width: "100%" }}
          >
            {alertUnauthorized}
          </Alert>
        </Snackbar>
        <Snackbar
          open={alertSuccessful.length > 0}
          autoHideDuration={6000}
          onClose={() => setAlertSuccessful("")}
        >
          <Alert
            variant="filled"
            onClose={() => setAlertSuccessful("")}
            severity="success"
            sx={{ width: "100%" }}
          >
            {alertSuccessful}
          </Alert>
        </Snackbar>
      </Content>
    </ModalCadastro>
  );
}

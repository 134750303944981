import React from 'react'
import {TextField, Button} from '@mui/material';
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import ptLocale from "date-fns/locale/pt-BR";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

const styles =
{
    botao:{
        background: '#741F8A',
        '&:hover': {
            background:"rgba(116, 31, 138, 0.6)"
        } 
    }
}


export default function SessionRescisao (props) {
    const {onNext} = props
    const [endDate, setEndDate] = React.useState('')
    const [errorEndDate, setErrorEndDate] = React.useState('Erro')
    const today = new Date();



    const handleEndDate = (value) =>{
        setEndDate(new Date(value).toJSON());
        setErrorEndDate({ endDate: "" });
        if (value) sessionStorage.setItem("endDate", new Date(value).toJSON());
        else {
            setErrorEndDate({ endDate: "Preencha esse campo." });
            sessionStorage.removeItem("endDate");
        }
    }

    React.useEffect(() => {  
      onNext(endDate) 
  }, [endDate])

   
    return (
        <>
            <h2>Rescisão de Contrato</h2>
            <LocalizationProvider locale={ptLocale} dateAdapter={AdapterDateFns}>
            <DatePicker
              value={endDate}
              onChange={handleEndDate}
              label="Fim de contrato"

              renderInput={(params) => (
                <TextField
                  variant="outlined"
                //   fullWidth
                  {...params}
                  error={Boolean(errorEndDate?.endDate)}
                  helperText={errorEndDate?.endDate}
                />
              )}
              minDate={today}
              inputFormat="dd/MM/yyyy"
            />
          </LocalizationProvider>
        </>
    )
}

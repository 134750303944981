import * as palette from "../../utils/styledVariables";
import React, { useEffect, useState, useRef } from "react";
import api from "../../services/api";
import { useSelector } from "react-redux";
import CardVagas from "../CardVagas";
import Sidebar from "../SidebarEmpresa";
import CardCadastroVaga from "../CardCadastroVaga";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import SearchIcon from "@mui/icons-material/Search";
import MuiAlert from "@mui/material/Alert";
import {
  Typography,
  Tabs,
  Tab,
  styled,
  Box,
  Grid,
  Popover,
  Button,
  Divider,
  CssBaseline,
  Container,
  Snackbar,
  Alert,
  CircularProgress,
  AlertTitle,
} from "@mui/material";
import PostAddIcon from "@mui/icons-material/PostAdd";
import ModalFirst from "./ModalFirstTime";
import { Link } from "react-router-dom";
import { SnackbarProvider } from "notistack";

const StyledTabs = styled(Tabs)({
  "& .MuiTabs-indicator": {
    backgroundColor: palette.laranjaSecundario,
  },
});

const ButtonsTopContainer = styled(Box)({
  display: "flex",
  height: "60px",
  // width: "130px",
  marginTop: "10px",
  marginBottom: "10px",
  alignItems: "center",
  bgcolor: palette.laranjaSecundario,
  "&:hover": {
    bgcolor: palette.laranjaIntermediario2,
  },
  borderRadius: "10px",
});

const ButtonsBottomContainer = styled(Box)({
  display: "flex",
  height: "60px",
  // width: "130px",
  alignItems: "center",
  bgcolor: palette.laranjaSecundario,
  "&:hover": {
    bgcolor: palette.laranjaIntermediario2,
  },
  borderRadius: "10px",
  position: "fixed",
  bottom: "20px",
});

const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: "none",
    minWidth: 0,
    [theme.breakpoints.up("sm")]: {
      minWidth: 0,
    },
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(1),
    color: palette.laranjaSecundario,
    "&:hover": {
      color: palette.laranjaSecundario,
      opacity: 1,
    },
    "&.Mui-selected": {
      color: palette.laranjaIntermediario2,
      fontWeight: theme.typography.fontWeightMedium,
    },
    "&.Mui-focusVisible": {
      backgroundColor: palette.profileSecondaryColor,
    },
  })
);

function listaVagas(vagas) {
  return vagas.map((vaga, index) => {
    return (
      <Grid item xs={6} sm={3} md={1.2} key={index}>
        <CardVagas vaga={vaga} />
      </Grid>
    );
  });
}
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function DashEmpresa() {
  const [value, setValue] = useState(0);
  const [vagas, setVagas] = useState([]);
  const [visible, setVisible] = useState();
  const [loading, setLoading] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [snackbarSuccessOTW, setSnackbarSuccessOTW] = useState(false);
  const [snackbarSuccessOxe, setSnackbarSuccessOxe] = useState(false);

  const profile = useSelector((state) => state.user.profile);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const open = Boolean(anchorEl);
  const [sidebarCollapsed, setSidebarCollapsed] = useState(false);

  const [width, setWidth] = React.useState(window.innerWidth);

  useEffect(() => {
    if (!localStorage.getItem("isModalOpen")) {
      setIsModalOpen(true);
      localStorage.setItem("isModalOpen", true);
    }
  }, []);

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const onMobile = width <= 600;

  const handlePopoverStatus = (event) => {
    setAnchorEl(open);
  };

  useEffect(() => {
    api.get("vagas/vagasaempresa").then((response) => {
      setVisible(profile.tem_propriedade_intelectual == null);
      setVagas(response.data);
      setLoading(false);
    });
  }, []);

  function handleCadastroVaga(event) {
    setAnchorEl(event.currentTarget);
  }

  const handleSubmit = (otw) => {
    setAnchorEl(null);
    api.get("vagas/vagasaempresa").then((response) => {
      setVisible(profile.tem_propriedade_intelectual == null);
      setVagas(response.data);
    });
    otw ? setSnackbarSuccessOTW(true) : setSnackbarSuccessOxe(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const SemVagas = () => {
    return (
      <div
        style={{
          marginTop: "20%",
          width: "100",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <PostAddIcon sx={{ fontSize: 60 }} />
        <h3>Sem vagas cadastradas</h3>
      </div>
    );
  };
  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarSuccessOTW(false);
    setSnackbarSuccessOxe(false);
  };

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const vagasAbertas = listaVagas(
    vagas.filter(
      (vaga) =>
        vaga.status !== "ARQUIVADA" &&
        (vaga.status_oxetech_work === "Aberta" ||
          vaga.status_oxetech_work === "Em análise" ||
          vaga.status === "ABERTA")
    )
  );
  const vagasFechadas = listaVagas(
    vagas.filter(
      (vaga) =>
        vaga.status !== "ARQUIVADA" &&
        (vaga.status_oxetech_work === "Encerrada pela empresa" ||
          vaga.status === "FECHADA")
    )
  );

  const vagasArquivadas = listaVagas(
    vagas.filter((vaga) => vaga.status === "ARQUIVADA")
  );

  return (
    <Box sx={{ display: "inline-flex" }}>
      <CssBaseline />

      <Sidebar
        onMobile={onMobile}
        collapsed={sidebarCollapsed}
        setCollapsed={setSidebarCollapsed}
        setOpenModal={handleOpenModal}
        setCloseModal={handleCloseModal}
      />
      <Box
        component="main"
        sx={{
          flexGrow: 2,
          width: { xs: `calc(100% - 20vh)` },
          marginLeft: onMobile || sidebarCollapsed ? "auto" : "240px",
        }}
      >
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "center",
            horizontal: "center",
          }}
        >
          <SnackbarProvider>
            <CardCadastroVaga
              close={handleClose}
              submit={handleSubmit}
              onOxetechWork={profile.oxetech_work}
            />
          </SnackbarProvider>
        </Popover>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            position: "fixed",
            width: "100%",
            zIndex: 2,
            bgcolor: palette.profileMainBrightColor,
            height: "73px",
          }}
        >
          <StyledTabs
            value={value}
            onChange={handleChange}
            sx={{
              position: "fixed",
              left: onMobile || sidebarCollapsed ? "50%" : "calc(50% + 240px)",
              transform:
                onMobile || sidebarCollapsed
                  ? "translateX(-50%)"
                  : "translateX(calc(-50% - 120px))",
            }}
            centered
            variant="fullWidth"
          >
            <StyledTab label="ABERTAS" />
            <StyledTab label="FECHADAS" />
            <StyledTab label="ARQUIVADAS" />
          </StyledTabs>

          <ButtonsTopContainer
            sx={{
              display: { xs: "none", sm: "none", md: "flex" },
              marginRight: onMobile || sidebarCollapsed ? "25px" : "265px",
              marginLeft: "auto",
            }}
          >
            <Button
              sx={{ color: palette.laranjaIntermediario2 }}
              onClick={handleCadastroVaga}
            >
              <AddCircleIcon sx={{ padding: "5px", fontSize: "50px" }} />
            </Button>
            {/* <Divider variant="middle" orientation="vertical" flexItem />
              <Button sx={{ color: palette.profileMainColor }}>
                <SearchIcon sx={{ padding: "5px", fontSize: "50px" }} />
              </Button> */}
          </ButtonsTopContainer>
        </Box>

        <Box
          sx={{
            marginTop: "60px",
            width:
              onMobile || sidebarCollapsed ? "100vw" : "calc(100vw - 240px)",
          }}
        >
          <TabPanel value={value} index={0}>
            {loading ? (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <CircularProgress
                  size={85}
                  sx={{ color: palette.laranjaIntermediario }}
                />
              </Box>
            ) : vagasAbertas.length > 0 ? (
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                columns={6}
                spacing={1}
              >
                {vagasAbertas}
              </Grid>
            ) : (
              <SemVagas />
            )}
          </TabPanel>

          <TabPanel value={value} index={1}>
            {loading ? (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <CircularProgress
                  size={95}
                  sx={{ color: palette.profileMainColor }}
                />
              </Box>
            ) : (
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                columns={6}
                spacing={1}
              >
                {vagasFechadas}
              </Grid>
            )}
          </TabPanel>
          <TabPanel value={value} index={2}>
            {loading ? (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <CircularProgress
                  size={95}
                  sx={{ color: palette.profileMainColor }}
                />
              </Box>
            ) : (
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                columns={6}
                spacing={1}
              >
                {vagasArquivadas}
              </Grid>
            )}
          </TabPanel>
        </Box>

        <Box
          sx={{
            display: { xs: "flex", sm: "flex", md: "none" },
            background: palette.profileWhiteShade,
            // width: "100%",
            height: "100px",
            position: "stiky",
            bottom: 0,
          }}
        >
          <Divider variant="middle" sx={{ width: "100%" }} flexItem />
          <ButtonsBottomContainer
            sx={{
              left: onMobile || sidebarCollapsed ? "50%" : "calc(50% + 240px)",
              transform:
                onMobile || sidebarCollapsed
                  ? "translateX(-50%)"
                  : "translateX(calc(-50% - 120px))",
              marginLeft: "auto",
            }}
          >
            <Button
              onClick={handleCadastroVaga}
              sx={{ color: palette.laranjaIntermediario2 }}
            >
              <AddCircleIcon sx={{ padding: "5px", fontSize: "50px" }} />
            </Button>
            {/* <Divider variant="middle" orientation="vertical" flexItem />
              <Button sx={{ color: palette.profileMainColor }}>
                <SearchIcon sx={{ padding: "5px", fontSize: "50px" }} />
              </Button> */}
          </ButtonsBottomContainer>
        </Box>
      </Box>

      <Snackbar
        open={snackbarSuccessOxe}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Link to="/oxedin">
          <Alert
            onClose={handleCloseSnackbar}
            severity="success"
            sx={{ width: "100%" }}
          >
            Parabéns! Você publicou uma vaga no Oxedin! - Ver página!
          </Alert>
        </Link>
      </Snackbar>
      <Snackbar
        open={snackbarSuccessOTW}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity="warning"
          sx={{ width: "30%", backgroundColor: "#ffe066", color: "black" }}
        >
          <AlertTitle>Sua vaga está em análise!</AlertTitle>
          Ao publicar uma vaga no Oxetech Work, ela é enviada automaticamente
          para a nossa equipe, onde passará por uma validação. Dentro de até 24
          horas, enviaremos uma resposta para você com a aprovação ou algum
          feedback, caso seja necessário.
        </Alert>
      </Snackbar>
      <ModalFirst open={isModalOpen} close={handleCloseModal} />
    </Box>
  );
}

export default DashEmpresa;

import * as palette from "../../utils/styledVariables";
import CancelIcon from "@mui/icons-material/Cancel";
import Options from "../../utils/options";
import api from "../../services/api";
import ptLocale from "date-fns/locale/pt-BR";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { Container, Subtitles } from "./styles";
import React, { useEffect, useState} from "react";
import { useSelector } from "react-redux";
import SaveIcon from "@mui/icons-material/Save";
import TimerIcon from "@mui/icons-material/Timer";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import logoOTW from "../../assets/LOGO_OXETECH_WORK.png"
import logoOXEDIN from "../../assets/logo_oxeDIN 3.png"
import {
  Chip,
  //Modal,
  Typography,
  Card,
  styled,
  Box,
  Grid,
  Button,
  TextField,
  FormControlLabel,
  Checkbox
} from "@mui/material";
import ModalEncerramento from "./ModalEncerramento";

import { RemoveCircle, Delete, FolderOff } from "@mui/icons-material";

import { ModalConfirmar } from "./ModalConfirmar";

import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css';


const MainTextField = styled((props) => <TextField {...props} />)(() => ({
  input: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "30px",
    lineHeight: "35px",
    display: "flex",
    alignItems: "center",
    color: "#F8F8F8",
  },
}));

const PublishButton = styled((props) => <Button {...props} />)(() => ({
  // background:
  //   "radial-gradient(292.41% 335.33% at 41.6% 50%, #741F8A 2.22%, #1609B3 100%)",
  background: palette.laranjaIntermediario2,
  "&:hover": {
    background: palette.laranjaSecundario
},
  color: palette.profileWhiteShade,
  float: "right",
  "margin-right": "10px",
  "margin-bottom": "10px",
}));

function formatStoredValues(data) {
 
  let currentValues = [];
  if (Array.isArray(data)) {
    for (let i = 0; i < data.length; i++) {
      currentValues.push({
        label: data[i],
        value: data[i],
      });
    }
  }
  else if (data != null) {
    currentValues = { label: data, value: data }
  }

  return currentValues;
}
const SimpleButton = styled((props) => <Button {...props} />)(() => ({
  background:
    "radial-gradient(292.41% 335.33% at 41.6% 50%, #741F8A 2.22%, #1609B3 100%)",
  color: palette.profileWhiteShade,
  margin: "10px 5px 0 5px"
}));

const styleModal = {

  width: 350,
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  padding: "10px",
  textAlign: "center",
  borderRadius: "1vh",
  justifyContent: 'center'

};

function CardEditVaga({ close, vaga }) {
  let profile = useSelector((state) => {
    if (
      state &&
      state.user &&
      state.user.profile &&
      state.user.profile.dataValues
    ) {
      return state.user.profile.dataValues;
    } else if (state && state.user && state.user.profile) {
      return state.user.profile;
    }
  });
  const [jobTitle, setJobTitle] = useState(vaga.titulo);
  const [description, setDescription] = useState(
    vaga.descricao
  );
  const [selectedRequirements, setSelectedRequirements] = useState(
    formatStoredValues(vaga.requisitos)
  );
  const [qualifications, setQualifications] = useState(
    formatStoredValues(vaga.qualificacoes)
  );

  const [jobsAmount, setJobsAmount] = useState(
    vaga.quantidade
  );
  const [endDate, setEndDate] = useState(vaga.data_encerramento);

  const [remuneration, setRemuneration] = useState(vaga.remuneracao);
  const [workLoad, setWorkLoad] = useState(vaga.carga_horaria);
  const [jobType, setJobType] = useState(formatStoredValues(vaga.tipo));
  const [jobModality, setJobModality] = useState(formatStoredValues(vaga.modalidade));
  const [oxetechWork, setOxetechWork] = useState(vaga.oxetech_work);
  //const [openModal, setOpenModal] = useState(false);
  const [OpenModalEncerramento, setOpenModalEncerramento] = useState(false);
  const [candidates, setCandidates] = useState(null);

  const [openModalArquivar, setOpenModalArquivar] = useState(false);
  const [openModalDesarquivar, setOpenModalDesarquivar] = useState(false);
  const [openModalExcluir, setOpenModalExcluir] = useState(false);

  useEffect(() => {
    api.get(`/candidatura/candidatosmyvaga?id=${vaga.id}`).then((response) => {
      setCandidates(response.data);
    })
  }, []);


  const [errorJobTitle, setErrorJobTitle] = React.useState(null);
  const [errorDescription, setErrorDescription] = React.useState(null);
  const [errorRequirements, setErrorRequirements] = React.useState(null);
  const [errorQualifications, setErrorQualifications] = React.useState(null);
  const [errorJobModality, setErrorJobModality] = React.useState(null);
  const [errorJobsAmount, setErrorJobsAmount] = React.useState(null);
  const [errorEndDate, setErrorEndDate] = React.useState(null);
  const [errorJobType, setErrorJobType] = React.useState(null);

  const today = new Date();
  const textLength = 4000;
  const titleLength = 64;
  const filter = createFilterOptions();

  const skills = Options.optionsSkills.map((skill) => {
    return { label: skill.value, value: skill.value };
  });

  const handleArquivarVaga = async () => {
    try {
        await api.patch(`vagas/arquivar?id=${vaga.id}`);
        setOpenModalArquivar(false);
        window.location.reload();
    } catch(err) {
        console.log(err);
    }
  }

  const handleExcluirVaga = async () => {
    try {
      await api.delete(`vagas/delete-empresa?id=${vaga.id}`);
      setOpenModalExcluir(false);
      window.location.reload();
    } catch(err) {
      console.log(err);
    }
  }

  const handleDesarquivarVaga = async () => {
    try {
      await api.patch(`vagas/desarquivar?id=${vaga.id}`);
      setOpenModalDesarquivar(false);
      window.location.reload();
    } catch(err) {
      console.log(err);
    }
  }

  const handleClose = (e) => {
    close();
    e.preventDefault();
  };

  const handleJobTitle = (e) => {
    const {
      target: { value },
    } = e;
    setJobTitle(value);
    setErrorJobTitle({ jobTitle: "" });
    if (value.length <= 0) {
      setErrorJobTitle({ jobTitle: "Preencha esse campo." });
    }
  };

  // const handleDescription = (e) => {
  //   const {
  //     target: { value },
  //   } = e;
  //   setDescription(value);
  //   setErrorDescription({ description: "" });
  //   if (value.length <= 0) {
  //     setErrorDescription({ description: "Preencha esse campo." });
  //   }
  // };
  const handleRequirements = (e, value) => {
    setErrorRequirements({ requirements: "" });
    if (e.type === "blur") {
      if (!selectedRequirements)
        setErrorRequirements({ requirements: "Preencha esse campo." });
    } else if (value.length === 0) {
      setSelectedRequirements([]);
      setErrorRequirements({ requirements: "Preencha esse campo." });
    } else {
      if (!Array.isArray(value)) {
        value = [value];
      }
      if (typeof value[value.length - 1] != "object") {
        value.splice(-1, 1, {
          label: value[value.length - 1],
          value: value[value.length - 1],
        });
      }
      setSelectedRequirements(value);
    }
  };


  const handleJobsAmount = (e) => {
    const {
      target: { value },
    } = e;
    setJobsAmount(value);
    setErrorJobsAmount({ jobsAmount: "" });
    if (value.length <= 0) {
      setErrorJobsAmount({ jobsAmount: "Preencha esse campo." });
    }
  };

  const handleQualifications = (e, value) => {
    setQualifications(value);
    setErrorQualifications({ qualifications: "" });
    if (!value) {
      setErrorQualifications({ qualifications: "Preencha esse campo." });
    }
  };

  const handleJobModality = (e, value) => {
    setJobModality(value);
    setErrorJobModality({ jobModality: "" });
    if (!value) {
      setErrorJobModality({ jobModality: "Preencha esse campo." });
    }
  };

  const handleJobType = (e, value) => {
    setJobType(value);
    setErrorJobType({ jobType: "" });
    if (!value) {
      setErrorJobType({ jobType: "Preencha esse campo." });
    }
  };


  const handleRemuneration = (e) => {
    const {
      target: { value },
    } = e;
    if (value >= 1 || value === "") {
      setRemuneration(value);
    }
  };



  const handleWorkLoad = (e) => {
    const {
      target: { value },
    } = e;
    setWorkLoad(value);

  };

  const modules = {
    toolbar: [
    [{ 'header': [1, 2, false] }],
    ['bold', 'italic', 'underline','strike'],
    [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
    ['clean']
    ],
  }

  const handleBlur = (range, source, quill) => {
    setDescription(quill.getHTML());
  };

  const editorStyle = {
    height: '300px', // altura fixa para o editor
    overflowY: 'auto' // habilita a barra de rolagem vertical
  };


  const handleEndDate = (value) => {
    setEndDate(value);
    setErrorEndDate({ endDate: "" });
    if (!value) {
      setErrorEndDate({ endDate: "Preencha esse campo." });
    }
  };

  const handleFinish = async () => {
    try {
      const data = {
        titulo: jobTitle,
        descricao: description,
        requisitos: JSON.stringify(selectedRequirements.map(item => item.value)),
        qualificacoes: JSON.stringify(qualifications.value),
        quantidade: jobsAmount,
        data_encerramento: endDate,
        remuneracao: remuneration,
        carga_horaria: workLoad,
        tipo: jobType.value,
        modalidade: jobModality.value,
        oxetech_work: oxetechWork,
      };

      const response = await api.put(`vagas/update?id=${vaga.id}`, data);

      close();
      window.location.reload();
    } catch (err) {
      console.log(err.response.data);
    }
  };
  
  // const handleJobClosure = async (closureData) => {
  //   try {
  //     const data = {
  //       ...closureData,
  //       status: 'FECHADA'
  //     };

  //     api.put(`vagas/update?id=${vaga.id}`, data).then((res) => {
  //       setOpenModal(false);
  //       close();
  //       window.location.reload();
  //     })

  //   } catch (err) {
  //     console.log(err);
  //   }
  // }

  return (
    <Card sx={{ background: palette.laranjaOXLTransparente }}>
      <Container>
        <MainTextField
          hiddenLabel
          id="nome-vaga"
          placeholder="Título da vaga"
          variant="standard"
          inputProps={{ maxLength: titleLength }}
          InputProps={{ disableUnderline: true }}
          fullWidth
          value={jobTitle}
          onChange={handleJobTitle}
          onBlur={handleJobTitle}
          error={Boolean(errorJobTitle?.JobTitle)}
          helperText={errorJobTitle?.JobTitle}
          required
        />
        <Button onClick={handleClose}>
          <CancelIcon
            sx={{ color: palette.profileWhiteShade }}
            fontSize="large"
          />
        </Button>
      </Container>
      <Grid container spacing={1} sx={{ padding: "10px" }}>
        <Grid item xs={12} md={12} lg={12}>
          <Subtitles>Descrição</Subtitles>

          <ReactQuill
          id="descricao-vaga"
          value={description}
          onChange={setDescription}
          onBlur={handleBlur}
          theme="snow"
          modules={modules}
          style={editorStyle}
          />
          
        </Grid>
        <Grid item xs={12} sm={12} md={3} lg={3}>
          <Subtitles>Requisitos</Subtitles>
          <Autocomplete
            id="requisitos"
            multiple
            freeSolo
            onChange={(event, value) => handleRequirements(event, value)}
            // onBlur={(event, value) => handleCategories(event, value)}
            options={skills}
            value={selectedRequirements}
            disableCloseOnSelect
            filterOptions={(options, params) => {
              const filtered = filter(options, params);

              const { inputValue } = params;

              const isExisting = options.some(
                (option) => inputValue === option.label
              );

              if (inputValue !== "" && !isExisting) {
                filtered.push({
                  value: inputValue,
                  label: `Adicionar "${inputValue}"`,
                });
              }

              return filtered;
            }}
            getOptionLabel={(option) => {
              if (typeof option === "string") return option;

              return option.value;
            }}
            renderOption={(props, option) => <li {...props}>{option.label}</li>}
            renderValue={(selected) => (
              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                {selected.map((value) => (
                  <Chip key={value} label={value} />
                ))}
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth={true}
                required
                color="secondary"
                placeholder="Pesquisar requisito"
                //onBlur={(event, value) => handleCategories(event, value)}
                error={Boolean(errorRequirements?.requirements)}
                helperText={errorRequirements?.requirements}
                hiddenLabel
                variant="filled"
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={3} lg={3}>
          <Subtitles>Nível de formação</Subtitles>
          <Autocomplete
            id="qualificacoes"
            onChange={(event, value) => handleQualifications(event, value)}
            // onBlur={(event, value) => handleCategories(event, value)}
            options={Options.qualifications}
            value={qualifications.value}
            disableCloseOnSelect

            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth={true}
                required
                color="secondary"
                //onBlur={(event, value) => handleCategories(event, value)}
                error={Boolean(errorQualifications?.qualifications)}
                helperText={errorQualifications?.qualifications}
                hiddenLabel
                variant="filled"
              />
            )} />
        </Grid>
        <Grid item xs={12} sm={12} md={3} lg={3}>
          <Subtitles>Modalidade</Subtitles>
          <Autocomplete
            id="modalidade"
            onChange={(e, value) => handleJobModality(e, value)}
            options={Options.modalities}
            value={jobModality.value}
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth={true}
                required
                color="secondary"
                //onBlur={(event, value) => handleCategories(event, value)}
                error={Boolean(errorJobModality?.jobModality)}
                helperText={errorJobModality?.jobModality}
                hiddenLabel
                variant="filled"
              />
            )} />
        </Grid>
        <Grid item xs={12} sm={12} md={3} lg={3}>
          <Subtitles>Tipo</Subtitles>
          <Autocomplete
            id="tipo"
            onChange={(e, value) => handleJobType(e, value)}
            options={Options.types}
            value={jobType.value}
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth={true}
                required
                color="secondary"
                //onBlur={(event, value) => handleCategories(event, value)}
                error={Boolean(errorJobType?.jobType)}
                helperText={errorJobType?.jobType}
                hiddenLabel
                variant="filled"
              />
            )} />
        </Grid>
        <Grid item xs={12} sm={12} md={3} lg={3}>
          <Subtitles>Quantidade de vagas</Subtitles>
          <TextField
            hiddenLabel
            id="qtd-vagas"
            variant="filled"
            type="number"
            fullWidth
            value={jobsAmount}
            onChange={handleJobsAmount}
            onBlur={handleJobsAmount}
            error={Boolean(errorJobsAmount?.jobsAmount)}
            helperText={errorJobsAmount?.jobsAmount}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={3} lg={3}>
          <Subtitles>Data de encerramento</Subtitles>
          <LocalizationProvider locale={ptLocale} dateAdapter={AdapterDateFns}>
            <DatePicker
              value={endDate}
              onChange={handleEndDate}
              renderInput={(params) => (
                <TextField
                  variant="filled"
                  fullWidth
                  {...params}
                />
              )}
              minDate={today}
              inputFormat="dd/MM/yyyy"
              error={Boolean(errorEndDate?.endDate)}
              helperText={errorEndDate?.endDate}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12} sm={12} md={3} lg={3} >
          <Subtitles>Remuneração</Subtitles>
          <TextField
            hiddenLabel
            id="remuneracao"
            variant="filled"
            type="number"
            fullWidth
            disabled={oxetechWork ? true : false}
            value={remuneration}
            onChange={handleRemuneration}
            onBlur={handleRemuneration}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={3} lg={3}>
          <Subtitles>Carga horária semanal</Subtitles>
          <TextField
            hiddenLabel
            id="carga_horaria"
            variant="filled"
            type="number"
            fullWidth
            value={workLoad}
            onChange={handleWorkLoad}
            onBlur={handleWorkLoad}
          />
        </Grid>
      </Grid>
      <Box 
        sx={{ 
          display: 'flex', 
          justifyContent: vaga.oxetech_work ? 'space-between' : 'flex-end', 
          alignItems: 'center' }}
      >  
        {vaga.oxetech_work ?
        <FormControlLabel

          label={<Typography component="h3"> A vaga está vinculada ao OxeTech Work? </Typography>}
          control={
            <Checkbox
              checked={oxetechWork}
              disabled
              onChange={() => { setOxetechWork(!oxetechWork) }}
              sx={{ margin: "10px 0 10px 10px" }}

              inputProps={{ 'aria-label': 'controlled' }}
            />
          }
        />:<></>}
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          {
            vaga.status === 'ARQUIVADA' && (
              <>
                <PublishButton startIcon={<FolderOff/>} onClick={() => setOpenModalDesarquivar(true)}>
                  Desarquivar Vaga
                </PublishButton>
                <PublishButton startIcon={<Delete />} onClick={() => setOpenModalExcluir(true) }>
                  Excluir Vaga
                </PublishButton>
              </>
            )
          }

          {
            (vaga.status === 'FECHADA' || vaga.status_oxetech_work === 'Encerrada pela empressa') && 
            <PublishButton startIcon={<RemoveCircle/>} onClick={() => setOpenModalArquivar(true)} >
              Arquivar vaga
            </PublishButton>
          }

          {
            (vaga.status === 'ABERTA' || vaga.status_oxetech_work === "Aberta") && 
            <PublishButton onClick={() => { setOpenModalEncerramento(true) }} startIcon={<TimerIcon />}>
              Encerrar vaga
            </PublishButton>
          }

          {
            vaga.status !== 'ARQUIVADA' &&
            <PublishButton onClick={handleFinish} startIcon={<SaveIcon />}>
              Salvar alterações
            </PublishButton>
          }
        </Box>
      </Box>

      <ModalEncerramento 
        openModal={OpenModalEncerramento}
        candidates={candidates}
        image={oxetechWork ? logoOTW : logoOXEDIN}
        vagaId={vaga.id}
        close={()=>{setOpenModalEncerramento(false)}}
      />
      
      <ModalConfirmar // arquivar 
        open={openModalArquivar}
        close={() => setOpenModalArquivar(false)}
        handleConfirm={handleArquivarVaga}
        text="Deseja arquivar a vaga?"
        image={oxetechWork ? logoOTW : logoOXEDIN}
      />
      
      <ModalConfirmar //desarquivar 
        open={openModalDesarquivar}
        close={() => setOpenModalDesarquivar(false)}
        handleConfirm={handleDesarquivarVaga}
        text="Deseja desarquivar a vaga?"
        image={oxetechWork ? logoOTW : logoOXEDIN}
      />

      <ModalConfirmar // excluir 
        open={openModalExcluir}
        close={() => setOpenModalExcluir(false)}
        handleConfirm={handleExcluirVaga}
        text="Deseja excluir a vaga?"
        image={oxetechWork ? logoOTW : logoOXEDIN}
      />

      {/* <Modal
        open={openModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ justifyContent: 'center' }}

      >
        <Box sx={styleModal}>
          <Typography sx={{ color: palette.preto, fontWeight: '500' }} >Confirma o encerramento da vaga?</Typography>


          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <SimpleButton onClick={() => {setOpenModal(true)}} > SIM </SimpleButton>
            <SimpleButton onClick={() => { setOpenModal(false) }} > NÃO </SimpleButton>
          </Box>

        </Box>
      </Modal> */}

    </Card >
  );
}
export default CardEditVaga;
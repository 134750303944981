import styled from 'styled-components'
import background from '../../assets/page_oxe_tech_lab.jpg'

export const Container = styled.div`
  height: 100vh;

  display: flex;
  align-items: stretch;
`

export const Content = styled.div`
  width: 90%;
  height: 100%;
  img {
    width: 15vw;
  }
  .ant-form-item-label > label {
    width: 320px;
    font-size: 16px;
  }

  .ant-form-item-control-input-content {
    display: flex;
    justify-content: center;
  }

  @media (max-width: 700px) {
    img {
      width: 25vw;
    }
    .ant-form-item-label > label {
      width: 200px;
      font-size: 16px;
    }
  }

  @media (min-width: 1500px) {
    .ant-form-item-label > label {
      width: 500px;
      font-size: 22px;
    }
  }
`

export const Background = styled.div`
  flex: 1;
  background: url(${background}) no-repeat;
  background-size: cover;
`

import React, { useState, useRef } from "react";

import {
  Avatar,
  Modal,
  Paper,
  Typography,
  useMediaQuery,
  IconButton,
  Button
} from '@mui/material';

import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

import {
  useStyles,
  ChipPendente,
  ChipConcluido,
  UploadContainer,
  SubmissionButton,
} from './styles';

import IconeAtividadePendente from '../../../assets/iconesAtividadesDaTurma/pending-activities-icon.svg';
import IconeAtividadeConcluida from '../../../assets/iconesAtividadesDaTurma/done-activities-icon.svg';

export const ModalDetalhesAtividadeAluno = ({ atividade, open, close }) => {
  const classes = useStyles();
  const isSmallScreen = useMediaQuery('(max-width:600px)');

  const fileInputRef = useRef(null);
  const [dragOver, setDragOver] = useState(false);
  const [fileName, setFileName] = useState(null);

  const handleDragOver = (event) => {
    event.preventDefault();
    setDragOver(true);
  };

  const handleDragLeave = (event) => {
    event.preventDefault();
    setDragOver(false);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    setDragOver(false);
    const file = event.dataTransfer.files[0];
    setFileName(file.name);
    // Process the file here (e.g., upload to server)
    console.log('File dropped:', file);
  };

  const handleFileInputChange = (event) => {
    const file = event.target.files[0];
    setFileName(file.name);
    // Process the file here (e.g., upload to server)
    console.log('File selected:', file);
  };

  return (
    <Modal
      open={open}
      onClose={close}
    >
      <Paper elevation={6} className={classes.modalContainer}>
        <div className={classes.goBackContainer}>
          <IconButton className={classes.goBackButton} color="#D56D0D2E" onClick={close}>
            <ArrowBackIosNewIcon sx={{color: "#D46D0D"}}/>
          </IconButton>

        </div>
        <div className={classes.modalHeader}>
          <div className={classes.iconAndTitleContainer}>
            <Avatar
              className={classes.icon}
              src={atividade.concluida ? IconeAtividadeConcluida : IconeAtividadePendente}
              alt={atividade.concluida ? 'Ícone de atividade concluída' : 'Ícone de atividade pendente'}
              classes={{ img: classes.iconImg }}
            />
            <Typography
              variant='h5'
              align='left'
              className={classes.title}
              style={{ fontSize: isSmallScreen ? '3.0vh' : '5.0vh' }}
            >
              {atividade.titulo}
            </Typography>
          </div>
          <div className={classes.statusAndDateContainer}>
            <div style={{display:'flex', flexDirection: 'row', justifyContent: 'flex-end', width: '100%'}}>
                {atividade.concluida ?
                  <ChipConcluido /> :
                  <ChipPendente />
                }
            </div>
            <div className={classes.modalDateContainer}>
              <Typography variant='h6'>
                  <span style={{color: '#D56D0D', fontWeight: 700}}>Data de entrega:</span> 05/05/2023 às 16h
                </Typography> 
            </div>
          </div>
        </div>
        <div className={classes.descriptionContainer}>
          <Typography variant="body1" className={classes.description}>{atividade.descricao}</Typography>
        </div>
        {/* <div className={classes.submissionContainer}> */}
        <UploadContainer
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
          onClick={() => {
            fileInputRef.current.click();
          }}
          style={{ backgroundColor: dragOver ? '#f5f5f5' : 'transparent', marginBottom: '1rem' }}
        >
          <input 
            type="file"
            accept="application/pdf"
            style={{ display: 'none' }}
            onChange={handleFileInputChange}
            ref={fileInputRef}
          />
          <CloudUploadIcon sx={{width: '30%', height: '50%', color: '#D46D0DB5'}} />
          <Typography variant='h4'>Arraste sua atividade aqui!</Typography>
        </UploadContainer>
          {fileName &&
            <>
              <Typography variant='h6'>{fileName}</Typography>
              <Button onClick={() => setFileName(null)}>Cancelar upload</Button>
            </>
          }
        <div className={classes.submissionContainer}>
          <SubmissionButton disabled={fileName === null}>Submeter</SubmissionButton>
        </div>
      </Paper>
    </Modal>
  );
};
import * as palette from "../../../utils/styledVariables";
import { makeStyles, styled } from "@material-ui/core";
import { Button, TextField } from "@mui/material";
import React from "react";

export const useStyles = makeStyles(() => ({
  modalContainer: {
    backgroundColor: "#EDE8EE",
    padding: "15px",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    border: "1px solid #000",
    borderRadius: "20px",
    boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
    height: "75%",
    width: "50%",
    
    
  },
  headerContainer: {
    backgroundColor: "#DFD3E4",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "95%",
    borderRadius: "10px",
    boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
  },
  formContainer: {
    padding: "1rem",
    backgroundColor: "#DFD3E4",
    borderRadius: "10px",
    width: "95%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-begin",
    boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
    marginTop: "2vh",
    marginBottom: "4vh",
  },
  inputsContainer: {
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-evenly",
  },
  requestError: {
    color: 'red',
    marginTop: '0.5rem',
  }
}));

export const SubmitButton = styled((props) => (
  <Button {...props} variant="contained" type="submit" />
))({
  borderRadius: "10px",
  margin: "10px",
  backgroundColor: palette.laranjaIntermediario2,
      "&:hover": {
        backgroundColor: palette.laranjaSecundario
    },
});

const TextInputForm = styled((props) => (
  <TextField
    {...props}
    color="secondary"
    fullWidth
    variant="outlined"
    size="small"
    // inputProps={{
    //   sx: { height: "2vh" },
    // }}
    // InputLabelProps={{
    //   sx: { display: "flex", justifyContent: ""}
    // }}
  />
))({});

export const TextInput = React.forwardRef((props, ref) => (
  <TextInputForm {...props} inputRef={ref} />
));
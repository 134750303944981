import React from 'react'
import { Row, Col } from 'antd'
import { Link } from 'react-router-dom'
import {
  Container,
  SectionOne,
  LogoOxeDin,
  LogoOxeTechLab,
  LogoOxeTechWork,
  LogoOxeTechHat,
  TriangleFrame,
  InovationMap,
  Text
} from './styles'

import MainHeader from '../../components/MainHeader'

import triangleFrame from '../../assets/homeTriangle/triangleFrameG.png'
import oxeTechHatON from '../../assets/homeTriangle/oxeTechHatON.png'
import oxeDinLogo from '../../assets/homeTriangle/oxeDinLogo.png'
import oxeTechLabLogo from '../../assets/homeTriangle/oxeTechLabLogo.png'
import oxeTechWorkLogo from '../../assets/homeTriangle/oxeTechWorkLogo.png'
import InovationMapIcon from '../../assets/homeTriangle/InovationMapIcon.png'

import Empresas from '../../assets/homeTriangle/Empresas.png'
import Contratacoes from '../../assets/homeTriangle/Contratacoes.png'
import Alunos from '../../assets/homeTriangle/Alunos.png'
import Bolsas from '../../assets/homeTriangle/Bolsas.png'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'

const divStyle1 = {
  overflowY: 'auto',
  border: '1px',
  width: '100%'
}
function Main(Props) {
  return (
    <Container>
      <MainHeader />

      <SectionOne>
        <TriangleFrame src={triangleFrame} layoutId="triangleFrame" />

        <Link to="/" className="hat">
          <LogoOxeTechHat src={oxeTechHatON} layoutId="oxeTech" />
        </Link>

        <LogoOxeDin
          src={oxeDinLogo}
          layoutId="oxeDin"
          style={{
            marginLeft: '90vw',
            marginTop: '100vh',
            position: 'absolute'
          }}
        />

        <LogoOxeTechLab
          src={oxeTechLabLogo}
          layoutId="oxeTechLab"
          style={{
            marginLeft: '33%',
            marginTop: '-50vh',
            position: 'absolute'
          }}
        />

        <Link
          to="/dash-oxe-tech-work"
          style={{
            marginLeft: '-50vw',
            marginTop: '18vh',
            position: 'absolute'
          }}
        >
          <LogoOxeTechWork src={oxeTechWorkLogo} layoutId="oxeTechWork" />
        </Link>

        <InovationMap
          src={InovationMapIcon}
          layoutId="inovationMap"
          style={{
            marginLeft: '100vw',
            marginTop: '-10vh',
            position: 'absolute'
          }}
        />
        <Text
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.2, duration: 0.2 }}
        >
          <Typography
            className="typ"
            variant="body1"
            gutterBottom
            sx={{ textAlign: 'justify', fontWeight: '300' }}
          >
            Alagoas e seus Municípios têm demonstrado expressivo potencial não
            só em seu fator de crescimento econômico, como também em relação ao
            seu mercado de tecnologia e inovação. Assim, nasce como um programa
            que tem por meta, abraçar o cenário atual, e incentivar o movimento
            de capacitação técnica e profissional para o povo alagoano.
            <br />
            <br />O OxeTech é resultado de uma política pública que tem por
            objetivo difundir a cultura de empreendedorismo e inovação nos
            municípios, através de metodologias de capacitação no segmento de
            tecnologia da informação e segmentos correlatos. Com ela, está sendo
            ampliado o contato com diversos modelos de políticas públicas
            executados em outras regiões do país, garantindo com isso um diálogo
            constante e permanente na busca de um alinhamento com a realidade
            nacional.
            <br />{' '}
          </Typography>
          <div className="publico">
            <Typography
              variant="h5"
              gutterBottom
              sx={{ marginBottom: '5%', color: 'rgba(255, 255, 255, 1)' }}
            >
              Público-alvo do programa OxeTech
            </Typography>
            <Grid
              container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <Grid item xs={6}>
                <img
                  style={{
                    width: '5vw'
                  }}
                  src={Alunos}
                  alt="Alunos"
                />
                <Typography className="body1" variant="body1" gutterBottom>
                  Estudantes de Ensino Médio e Ensino Superior
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <img
                  style={{
                    width: '5vw'
                  }}
                  src={Contratacoes}
                  alt="Contratacoes"
                />
                <Typography className="body1" variant="body1" gutterBottom>
                  Professores e Profissionais da Educação
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <img
                  style={{
                    width: '5vw'
                  }}
                  src={Empresas}
                  alt="Empresas"
                />
                <Typography className="body1" variant="body1" gutterBottom>
                  Startups, Empresas de Base Tecnológica e Economia Criativa
                </Typography>
              </Grid>
              <Grid item xs={6}>
                {' '}
                <img
                  style={{
                    width: '5vw'
                  }}
                  src={Bolsas}
                  alt="Bolsas"
                />
                <Typography className="body1" variant="body1" gutterBottom>
                  Profissionais interessados no Setor de T.I. e segmentos
                  correlatos{' '}
                </Typography>
              </Grid>
            </Grid>
          </div>
        </Text>
        <div style={divStyle1}>
          <Row>
            <Col flex="1 1 240px">
              <div style={{ marginTop: '23vh' }}>
                <Row>
                  <Col span={24}>
                    <Text
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      transition={{ delay: 0.2, duration: 0.2 }}
                    >
                      <p
                        style={{
                          textTransform: 'uppercase'
                        }}
                      >
                        Público-alvo do programa OxeTech
                      </p>
                    </Text>
                  </Col>
                </Row>
                <Row gutter={[16, 16]}>
                  <Col span={12}>
                    <Text
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      transition={{ delay: 0.2, duration: 0.2 }}
                    >
                      <img
                        style={{
                          // position: "absolute",
                          // marginLeft: "70vw",
                          // marginTop: "10vh",
                          width: '5vw'
                        }}
                        src={Alunos}
                        alt="Alunos"
                      />
                      <p
                        style={{
                          // position: "absolute",
                          // marginLeft: "72vw",
                          // marginTop: "34.6vh",
                          fontSize: '14px',
                          textAlign: 'center',
                          textAlignLast: 'middle'
                        }}
                      >
                        ESTUDANTES DE ENSINO MÉDIO E ENSINO SUPERIOR
                      </p>
                    </Text>
                  </Col>
                  <Col span={12}>
                    <Text
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      transition={{ delay: 0.2, duration: 0.2 }}
                    >
                      <img
                        style={{
                          // position: "absolute",
                          // marginLeft: "50vw",
                          // marginTop: "45vh",
                          width: '5vw'
                        }}
                        src={Contratacoes}
                        alt="Contratacoes"
                      />
                      <p
                        style={{
                          // position: "absolute",
                          // marginLeft: "51vw",
                          // marginTop: "72.6vh",
                          fontSize: '14px',
                          textAlign: 'center'
                        }}
                      >
                        PROFESSORES E PROFISSIONAIS DA EDUCAÇÃO
                      </p>
                    </Text>
                  </Col>
                  <Col span={12}>
                    <Text
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      transition={{ delay: 0.2, duration: 0.2 }}
                    >
                      <img
                        style={{
                          // position: "absolute",
                          // marginLeft: "70vw",
                          // marginTop: "45vh",
                          width: '5vw'
                        }}
                        src={Empresas}
                        alt="Empresas"
                      />
                      <p
                        style={{
                          // position: "absolute",
                          // marginLeft: "72%",
                          // marginTop: "72.6vh",
                          textTransform: 'uppercase',
                          fontSize: '14px',
                          textAlign: 'center'
                        }}
                      >
                        Startups, Empresas de Base Tecnológica e Economia
                        Criativa
                      </p>
                    </Text>
                  </Col>

                  <Col span={12}>
                    <Text
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      transition={{ delay: 0.2, duration: 0.2 }}
                    >
                      <img
                        style={{
                          // position: "absolute",
                          // marginLeft: "50vw",
                          // marginTop: "10vh",
                          width: '5vw'
                        }}
                        src={Bolsas}
                        alt="Bolsas"
                      />
                      <p
                        style={{
                          // position: "absolute",
                          // marginLeft: "50.6vw",
                          // marginTop: "34.6vh",
                          textTransform: 'uppercase',
                          fontSize: '14px',
                          textAlign: 'center',
                          textAlignLast: 'middle'
                        }}
                      >
                        Profissionais interessados no Setor de T.I. e segmentos
                        correlatos
                      </p>
                    </Text>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </div>

        <Text
          onClick={() => {
            Props.history.goBack()
          }}
        >
          <button
            style={{
              position: 'absolute',
              marginTop: '83vh',
              marginLeft: '-90vw'
            }}
          >
            Voltar
          </button>
        </Text>

        <Link to="/dash-oxe-tech-lab">
          <Text>
            <button
              style={{
                position: 'absolute',
                marginTop: '83vh',
                marginLeft: '-5vw'
              }}
            >
              Proximo
            </button>
          </Text>
        </Link>
      </SectionOne>
    </Container>
  )
}

export default Main

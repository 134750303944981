import MenuIcon from "@mui/icons-material/Menu";
import {
  AppBar,
  Box,
  Container,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import * as React from "react";
import { useLocation, withRouter } from "react-router-dom";
import logo from "../../../assets/perfilOXL/logo.svg";
// import { ButtonOn, ButtonOff, Cadastrar,ButtonLink } from './styles'
import { createTheme, ThemeProvider } from "@mui/material/styles";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import { useAuth } from "../../../hooks/auth/AuthContext";
import { store } from "../../../store";

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 800,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
});

function ElevationScroll(props) {
  const { children, window } = props;
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

const pages = [
  { name: "Turmas", url: "/perfil-laboratorio" },
  { name: "Editar dados", url: "/perfil-laboratorio" },
  { name: "Sair", url: "sair" },
];

const isActive = window.location.pathname;

const ResponsiveAppBar = (props) => {

  // let profile = useSelector((state) => state.user.profile.dataValues ? state.user.profile.dataValues : state.user.profile);

  const { logout } = useAuth();

  const { history } = props;
  const [anchorElNav, setAnchorElNav] = React.useState(null);

  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const location = useLocation();

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleMenuClick = (pageURL) => {
    handleCloseNavMenu();
    history.push(pageURL);
  };

  function handleSignOut() {
    logout();
  }

  return (
    <ThemeProvider theme={theme}>
      <ElevationScroll {...props}>
        <AppBar
          style={{
            background: "#D46D0D",
            lineHeight: "8%",
            position: "sticky",
          }}
          elevation={0}
        >
          <Container maxWidth="100vw">
            <Toolbar disableGutters sx={{ padding: "1" }}>
              <Box sx={{ flexGrow: 1, display: { xs: "none", sm: "flex" } }}>
                <Tooltip title="Logo OxeTechLab">
                  <IconButton onClick={() => handleMenuClick("/")}>
                    <img src={logo} alt="OxeTechLab" width={"80%"} />
                  </IconButton>
                </Tooltip>
              </Box>
              {/* ---------- Visível para Mobile ----------*/}
              <Box sx={{ flexGrow: 1, display: { xs: "flex", sm: "none" } }}>
                <IconButton
                  aria-label="Páginas do OxeTech"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleOpenNavMenu}
                  color="inherit"
                  // sx={{ display: location.pathname !== "/dashboard" && location.pathname !== "/empresa-account" && location.pathname !== "/dashadmin" ? { xs: 'flex', sm: 'none' }: { xs: 'none', sm: 'none' }  }}
                >
                  <MenuIcon fontSize="40px" />
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorElNav}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  open={Boolean(anchorElNav)}
                  onClose={handleCloseNavMenu}
                  sx={{
                    display: { xs: "block", sm: "none" },
                  }}
                >
                  {pages.map((page) => (
                    <MenuItem
                      key={page.name}
                      onClick={() =>
                        page.name !== "Sair"
                          ? handleMenuClick(page.url)
                          : handleSignOut()
                      }
                    >
                      <Typography textAlign="center">{page.name}</Typography>
                    </MenuItem>
                  ))}
                </Menu>
              </Box>

              <Box
                sx={{
                  flexGrow:
                    location.pathname !== "/dashboard" &&
                    location.pathname !== "/empresa-account" &&
                    location.pathname !== "/dashadmin"
                      ? 2
                      : 1,
                  display: { xs: "flex", sm: "none" },
                }}
              >
                <Tooltip title="Logo OxeTech">
                  <IconButton onClick={() => handleMenuClick("/")}>
                    <img src={logo} alt="OxeTechLab" width={"80%"} />
                  </IconButton>
                </Tooltip>
              </Box>
              {/* ---------- FIM Visível para Mobile ----------*/}

              <Box
                sx={{
                  flexGrow: 0,
                  display:
                    location.pathname !== "/dashboard" &&
                    location.pathname !== "/empresa-account" &&
                    location.pathname !== "/dashadmin"
                      ? { xs: "none", sm: "flex" }
                      : { xs: "none", sm: "none" },
                }}
              ></Box>
            </Toolbar>
          </Container>
        </AppBar>
      </ElevationScroll>
    </ThemeProvider>
  );
};
export default withRouter(ResponsiveAppBar);

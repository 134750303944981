import React, { useState, useEffect } from 'react';
import CardPosts from './CardPosts'
import { useSelector } from 'react-redux';
import MainHeader from '../../components/MainHeader'
import { Card, Badge, Comment, Avatar, Button, Input, Upload, Select, Space, Typography  } from 'antd';
import { Tabs } from 'antd';
import { ReactComponent as Send } from '../../assets/iconesOxeLabMural/send.svg';
import UndoIcon from '@mui/icons-material/Undo';
import { Container, TabsPage } from './styles'
import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css';
import {AtividadesDaTurma} from '../../components/AtividadesDaTurma';

import HeaderOxeTechLab from './HeaderOxeTechLab';
import { ThemeProvider, createTheme } from '@mui/material';
import { useMediaQuery } from '@mui/material';

const theme = createTheme({
  palette: {
    primary: {
      main: '#D46D0D'
    }
  }
});


const posts = [
  {
    title: 'Post 1',
    content: 'Maecenas convallis erat vestibulum orci tincidunt feugiat. Suspendisse non ligula sed magna pretium eleifend. Nam in tincidunt dui. Fusce feugiat ante sit amet ante sollicitudin, ac laoreet nibh semper. Vestibulum aliquam nunc vel risus ultricies, rhoncus ultrices magna consequat. Nullam efficitur felis molestie, finibus felis nec, varius urna. Nam cursus odio quis nunc iaculis dignissim. In vitae porttitor libero. Sed feugiat sed justo at molestie. Donec eget laoreet ipsum. Suspendisse sapien neque, tempor at faucibus a, tincidunt non eros. Nulla ut consectetur eros, vel sollicitudin arcu. Etiam pellentesque ante ligula.',
    creator: {
      name: 'Secretaria de Estado da Ciência, Tecnologia, Inovação',
      avatar: 'https://i.pravatar.cc/40',
    },
    comments: [
      { text: 'Comentário 1', avatar: 'https://i.pravatar.cc/40', name: 'Maria', date: '2023-05-16' },
      { text: 'Comentário 2', avatar: 'https://i.pravatar.cc/40', name: 'Pedro', date: '2023-05-18' },
    ],
    date: '2023-05-16',
  },
  {
    title: 'Post 2',
    content: 'Conteúdo do post 2',
    creator: {
      name: 'Ana',
      avatar: 'https://i.pravatar.cc/40',
    },
    comments: [
      { text: 'Comentário 3', avatar: 'https://i.pravatar.cc/40', name: 'Paulo', date: '2023-05-19' },
    ],
    date: '2023-05-15',
  },
  {
    title: 'Post 3',
    content: 'Conteúdo do post 3',
    creator: {
      name: 'Mariana',
      avatar: 'https://i.pravatar.cc/40',
    },
    comments: [],
    date: '2023-05-14',
  },
];

const course =[
  {
    courseName: 'Curso de HTML e CSS5',
    teacherName: 'Prof. Albert Einstein',
    date: '2023-05-15', 
    closingDate: '2023-07-15'
  }
]

  const cardStyle = {
    width: '100%',
  };

 
  
  const editorContainerStyle = {
    maxHeight: '300px', // Altura máxima desejada
    overflow: 'auto',
  };
function App(){
const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const signed = useSelector(state => state.auth.signed);
  const [commentText, setCommentText] = useState('');
  const { TabPane } = Tabs;
  const [professor, setProfessor] = useState(false);
  const toggleProfessor = () => setProfessor(!professor);

  const [showEditor, setShowEditor] = useState(false);
  // const [commentText, setCommentText] = useState('');

  const handleEditorClick = () => {
    setShowEditor(true);
  };

  // const handleCommentChange = (value) => {
  //   setCommentText(value);
  // };

  // const handleCommentSubmit = () => {
  //   // Lógica para enviar o comentário
  // };

  const editorContainerStyle = {
    position: 'relative',
    // border: '1px solid red',
    overflow: 'hidden',
    height: showEditor? 'auto' : '50px',
    borderRadius: '6px', border: '1px solid #ddd', 
    width: '100%'
  };

  const editorStyle = {
    display: showEditor ? 'block' : 'none',
    borderRadius: '6px', 
    //   border: '1px solid #ddd', 
    height: isSmallScreen? '130px' :'200px',
    overflow: 'auto',
    maxWidth: '100%',
    width: '100%',
  };

  const placeholderStyle = {
    display: showEditor ? 'none' : 'block',
    position: 'absolute',
    top: '50%',
    left: '10px',
    transform: 'translateY(-50%)',
    color: '#aaa',
    width: '80%',
    cursor: 'text'
  };

  const modules = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],
      [{ 'link': 'Formatar link' }],
      [{ 'list': 'ordered' }, { 'list': 'bullet' }],
      ['clean'],
    ],
  };

  // const editorStyle = {
  //   borderRadius: '6px', 
  //   border: '1px solid #ddd', 
  //   padding: '8px 40px 8px 8px', 
  //   width: '100%',
  //   height: isSmallScreen? '50px': '100px'

  // };

  const onChange = (key) => {
    console.log(key);
  };


  const handleCommentSubmit = () => {
    setCommentText('');
  };

  const handleCommentChange = (value) => {
    setCommentText(value);
  };
  // console.log(course[0].closingDate)
  return (
    <Container>
      {/* { !signed ? <MainHeader /> : ''} */}
      <HeaderOxeTechLab
      date ={course[0].date}
      closingDate={course[0].closingDate}
      teacherName={course[0].teacherName}
      courseName={course[0].courseName}
                />
      <div style={{padding: '20px'}}>

      <TabsPage centered onChange={onChange} destroyInactiveTabPane={true}>
          <TabPane tab="Mural" key="1"  style={{maxWidth: '70rem', }}>
            <div style={{maxWidth:'100%', display: 'flex', justifyContent: 'center', flexDirection: 'column'}} >
          <div style={{padding: '20px'}}>
   
          <Space.Compact style={{ padding: '0', width: '100%', boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',borderRadius: '6px',  }}>
            <div style={{ position: 'relative', width: '100%' }}>
      <div style={editorContainerStyle} onClick={handleEditorClick}>
        {showEditor ? (
          <>
          <ReactQuill
            value={commentText} 
            onChange={handleCommentChange}
            theme="snow"
            modules={modules}
            style={editorStyle}
            placeholder="Escreva algo para a sua turma..."
          />
          
          </>
        ) : (
          <div style={placeholderStyle}>Escreva algo para a sua turma...</div>
        )}
      </div>
      {showEditor && (
        <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}} >
              <Button
                type="primary"
                ghost
                onClick={() => setShowEditor(false)}
                style={{
                  // left: '3px',
                  color: '#D46D0D',
                  // transform: 'translateY(-50%)',
                  backgroundColor: 'transparent',
                  border: 'none',
                  boxShadow: 'none',
                }}
              >Cancelar</Button>
              <Button
              onClick={handleCommentSubmit}
              style={{
                backgroundColor: 'transparent',
                border: 'none',
                cursor: 'pointer',
                boxShadow: 'none',
              }}
              >
              <Send style={{ width: '14px', height: isSmallScreen && showEditor? '60px' : '14px', verticalAlign: 'middle' }} />
              </Button>
             </div> 
      )}
      {/* <Button
        type="primary"
        ghost
        
      > */}
        <div
        onClick={handleCommentSubmit}
        style={{
          display: showEditor ? 'none' : 'flex',
          position: 'absolute',
          top: isSmallScreen && showEditor? '70%': '50%',
          right: '20px',
          transform: 'translateY(-50%)',
          backgroundColor: 'transparent',
          border: 'none',
          cursor: 'pointer',
          boxShadow: 'none',
        }}
        >
        <Send style={{ width: '14px', height: isSmallScreen && showEditor? '60px' : '14px', verticalAlign: 'middle' }} />
        </div>
      {/* </Button> */}
    </div>
      </Space.Compact>
        </div>
            {posts.map((post, index) => (
              <CardPosts
                style={cardStyle}
                key={index}
                posts={posts}
                title={post.title}
                content={post.content}
                creator={post.creator}
                date={post.date}
                comments={post.comments}
                dateComment={post.comments.date}
              />
              ))}
              </div>
          </TabPane>
          <TabPane tab="Atividades" key="2" style={{width: isSmallScreen?'95%': '70rem', maxWidth: '70rem',  border: '1px solid red' }}>
             <ThemeProvider theme={theme}>
                <Button onClick={toggleProfessor}>{professor ? 'Professor' : 'Aluno'}</Button>
                <AtividadesDaTurma professor={professor} />
            </ThemeProvider>
          </TabPane>
        </TabsPage>
      </div>
      
        
    </Container>
  );
};

export default App;

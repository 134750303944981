import React, { useEffect, useState } from "react";
import {
  Modal,
  Grid,
  Typography,
  Paper,
  TextField,
  Box,
  Tooltip,
  Radio,
  Button,
  Checkbox,
  FormControlLabel,
  IconButton,
  Divider,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import * as palette from "../../../utils/styledVariables";
import { SubmitButton } from "./style";
import { useTheme, useMediaQuery } from "@material-ui/core";
import { useStyles, TextInput } from "./style";
import api from "../../../services/api";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { useSnackbar, SnackbarProvider } from "notistack";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { ThemeProvider, createTheme } from "@mui/material/styles";

const theme1 = createTheme({
  palette: {
    primary: {
      main: "rgba(212, 109, 13, 1)",
      darker: "rgba(212, 109, 13, 0.5)",
    },
  },
});


export const ModalEditarAtividade = ({
  onOpen,
  onClose,
  atividade,
  edit,
}) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [requestError, setRequestError] = useState(null);
  const [showOptions, setShowOptions] = useState(false);
  const [options, setOptions] = useState(["", "", "", ""]);
  const [questions, setQuestions] = useState(
    JSON.parse(sessionStorage.getItem("questions")) || [
      {
        pergunta: "",
        alternativas: ["", "", ""],
        tipo: "RESP_UNICA",
        resposta: [],
      },
    ]
  );
  const { enqueueSnackbar } = useSnackbar();
  const [atividadeTitle, setAtividadeTitle] = useState("");
  const [scoreMinimo, setScoreMinimo] = useState();
  const [respostasCorretas, setRespostasCorretas] = useState([]);
  const theme = useTheme();
  const onMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const styles = {
    modal: {
      width: "50vw",
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      bgcolor: "#EDE8EE",
      textAlign: "center",
      borderRadius: "1vh",
      padding: "1rem",
      justifyContent: "center",
      maxHeight: "100%",
      overflow: "auto",
      "@media (max-width: 1280px)": {
        width: "48vw",
        height: "100vh",
      },
    },
    paper: {
      width: "100%",
      margin: "5px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "8vh",
      marginTop: "15px",
      "@media (max-width: 1280px)": {
        height: "10vh",
      },
    },
    container: {
      width: "100%",
      marginBottom: "10px",
      backgroundColor: palette.laranjaOXLTransparente,
      borderRadius: "1vh",
    },
    field: {
      textAlign: "left",
      padding: "10px",
      // width: "30vw",
      color: "black",
      "@media (max-width: 1280px)": {
        width: "30vw",
      },
    },
    button: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      bgcolor: palette.roxoAzulado,
      marginTop: "20px",
      padding: "6px 10px",
      marginBottom: "20px",
    },
    scrollable: {
      padding: "10px",
      backgroundColor: "#F7F7F7",
      borderRadius: "8px",
      marginTop: "10px",
    },
  };

  useEffect(() => {
    if (atividade && atividade.questoes) {
      const questoesMapeadas = atividade.questoes.map(q => ({
        id: q.id,
        pergunta: q.pergunta,
        alternativas: q.alternativas,
        resposta: q.resposta,
        tipo: q.tipo
      }));
  
      setQuestions(questoesMapeadas);
  
      const respostasIniciais = questoesMapeadas.map(questao => {
        if (questao.tipo === "RESP_MULTIPLA") {
          return questao.resposta;
        }
        else if (questao.tipo === "RESP_UNICA") {
          return questao.resposta.length > 0 ? [questao.resposta[0]] : [];
        }
        return [];
      });
  
      setRespostasCorretas(respostasIniciais);
    }
  }, [atividade]);
  
  const handleInputChange = (e) => {
    const inputValue = e.target.value.replace(/[^0-9]/g, "");
    e.target.value = inputValue;
    setScoreMinimo(parseInt(inputValue));
  };

  const handleCorrectOptionChange = (event, questionIndex, optionIndices) => {
    const updatedQuestions = [...questions];

    updatedQuestions[questionIndex].resposta = [optionIndices];
    setQuestions(updatedQuestions);

    const updatedRespostasCorretas = [...respostasCorretas];
    updatedRespostasCorretas[questionIndex] = optionIndices;
    setRespostasCorretas(updatedRespostasCorretas);
  };

  const handleMultipleChoiceOptionChange = (e, questionIndex, optionIndex) => {
    const updatedRespostasCorretas = [...respostasCorretas];
    if (!Array.isArray(updatedRespostasCorretas[questionIndex])) {
      updatedRespostasCorretas[questionIndex] = [];
    }

    const existingIndex =
      updatedRespostasCorretas[questionIndex].indexOf(optionIndex);

    if (existingIndex === -1) {
      updatedRespostasCorretas[questionIndex].push(optionIndex);
    } else {
      updatedRespostasCorretas[questionIndex].splice(existingIndex, 1);
    }

    setQuestions((prevQuestions) => {
      const updatedQuestions = [...prevQuestions];
      updatedQuestions[questionIndex] = {
        ...updatedQuestions[questionIndex],
        resposta: updatedRespostasCorretas[questionIndex],
      };
      return updatedQuestions;
    });

    setRespostasCorretas(updatedRespostasCorretas);
  };

  const handleAddQuestion = () => {
    if (
      questions[questions.length - 1].resposta.length !== 0 ||
      !questions[questions.length - 1].resposta
    ) {
      setQuestions([
        ...questions,
        {
          pergunta: "",
          alternativas: ["", "", ""],
          tipo: "RESP_UNICA",
          resposta: [],
        },
      ]);
      setRespostasCorretas([...respostasCorretas, []]);
    } else {
      enqueueSnackbar(
        "Você precisa adicionar uma resposta na Questão " + questions.length,
        {
          variant: "error",
        }
      );
    }
  };

  const handleAddOption = (questionIndex) => {
    const updatedQuestions = [...questions];
    updatedQuestions[questionIndex].alternativas.push("");
    setQuestions(updatedQuestions);
  };
  const handleDeleteQuestion = (questionIndex) => {
    const updatedQuestions = [...questions];
    updatedQuestions.splice(questionIndex, 1);
    setQuestions(updatedQuestions);
  };
  const handleOptionChange = (questionIndex, optionIndex, value) => {
    const updatedQuestions = [...questions];
    updatedQuestions[questionIndex].alternativas[optionIndex] = value;
    setQuestions(updatedQuestions);
  };

  const handleQuestionChange = (questionIndex, value) => {
    const updatedQuestions = [...questions];
    updatedQuestions[questionIndex].pergunta = value;

    setQuestions(updatedQuestions);
  };

  const handleQuestionTypeChange = (questionIndex, type) => {
    const updatedQuestions = [...questions];
    updatedQuestions[questionIndex].tipo = type;
    setQuestions(updatedQuestions);
  };

  const handleClose = () => {
    setShowOptions(false);
    onClose();
  };

  const onFinish = async () => {
    for (let question of questions) {
      if (question.resposta.length === 0) {
        enqueueSnackbar("Adicione uma resposta na sua questão.", { variant: "error" });
        return; 
      }
    }

    const atividadeData = {
      questoes: questions.map(question => ({
        pergunta: question.pergunta,
        tipo: question.tipo,
        alternativas: question.alternativas,
        resposta: question.resposta,
      })),
    };

    try {
      setLoading(true);
      setRequestError(null);

      const response = await api.put(`/atividade-trilha/${atividade.id}`, atividadeData);
      sessionStorage.removeItem("atividade");
      sessionStorage.removeItem("title");
      sessionStorage.removeItem("url");
      sessionStorage.removeItem("description");
      sessionStorage.removeItem("questions");
      if (response.status === 200) {
        enqueueSnackbar('A atividade foi editada com sucesso.', { variant: 'success' });
        window.location.reload();
      }
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        setRequestError(error.response.data.error);
        enqueueSnackbar('Ocorreu um erro ao editar a atividade.', { variant: 'error' });
      }
    } finally {
      setLoading(false);
    }

};


  useEffect(() => {
    sessionStorage.setItem("questions", JSON.stringify(questions));
  }, [questions]);
 
  return (
    <ThemeProvider  theme={theme1}>

    <Modal open={onOpen} onClose={onClose} sx={{ overflow: "auto" }}>
      <ClickAwayListener onClickAway={handleClose}>
        <Box>
          <IconButton
            size="large"
            sx={{ display: "flex", float: "right" }}
            component="label"
            onClick={handleClose}
          >
            <CloseIcon
              fontSize="large"
              sx={{ color: palette.profileWhiteShade }}
            />
          </IconButton>
          <Grid
            sx={styles.modal}
            container
            direction="row"
            style={{
              height: onMobile ? "50vh" : "80vh",
              width: onMobile ? "50wh" : "40wh",
            }}
          >
            <Grid container item xs={12} sm={12} md={12} lg={12}>
              <Paper
                sx={[
                  {
                    backgroundColor: palette.laranjaOXLTransparente,
                    height: "50px",
                  },
                  styles.paper,
                ]}
              >
                <Typography
                  sx={{
                    fontSize: "24px",
                    color: palette.preto,
                    fontFamily: "Poppins",
                    fontWeight: 500,
                  }}
                >
                  Atividade
                </Typography>
              </Paper>
            </Grid>
            {questions.map((question, questionIndex) => (
              <>
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <h3>Questão {questionIndex + 1}</h3>
                  <Tooltip
                    title="Excluir"
                    style={{
                      display: questionIndex !== 0 ? "flex" : "none",
                      cursor: "pointer",
                      marginRight: "5px",
                    }}
                    onClick={() => handleDeleteQuestion(questionIndex)}
                  >
                    <DeleteForeverIcon color="primary" />
                  </Tooltip>
                </div>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    ...styles.container,
                    padding: "1%",
                    minHeight: "40%",
                    justifyContent: "space-evenly",
                  }}
                  key={questionIndex}
                >
                  <Grid container spacing={2} key={questionIndex}>
                    <Grid item xs={10} sm={5} md={5} lg={9.6}>
                      <Box sx={{ width: "100%" }}>
                        <TextInput
                          id={`pergunta-${questionIndex}`}
                          variant="filled"
                          color="primary"
                          sx={{ marginBottom: "2%" }}
                          label="Adicione a pergunta"
                          value={question.pergunta}
                          onChange={(e) => {
                            const inputText = e.target.value.slice(0, 500); 
                            handleQuestionChange(questionIndex, inputText);
                          }}
                          rows={4}
                          fullWidth
                          helperText={`${question.pergunta.length}/500 caracteres`}
                        />
                      </Box>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      sm={6}
                      md={6}
                      lg={2}
                      container
                      alignItems="center"
                    >
                      <Box
                        sx={{
                          width: "40px",
                          bgcolor: "rgba(212, 109, 13, 1)",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          borderRadius: "10px",
                          alignItems: "center",
                          marginBottom: "2%",
                          marginLeft: "10px",
                          "@media (max-width: 1280px)": {
                            width: "40px",
                            height: onMobile ? "6vh" : "20vh",
                            marginLeft: "0px",
                          },
                        }}
                      >
                        <Tooltip title="Seleção Única">
                          <IconButton
                            onClick={() =>
                              handleQuestionTypeChange(
                                questionIndex,
                                "RESP_UNICA"
                              )
                            }
                          >
                            <RadioButtonCheckedIcon
                              style={{ color: "#fff" }}
                            />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Múltipla Escolha">
                          <IconButton
                            onClick={() =>
                              handleQuestionTypeChange(
                                questionIndex,
                                "RESP_MULTIPLA"
                              )
                            }
                          >
                            <CheckBoxOutlineBlankIcon
                              style={{ color: "#fff" }}
                            />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    </Grid>
                  </Grid>
                  {question.alternativas.map((option, optionIndex) => (
                    <div key={optionIndex} style={{ display: "flex" }}>
                      <TextInput
                        required
                        label={`Opção ${optionIndex + 1}`}
                        value={option}
                        sx={{ width: "60%", marginBottom: "2%" }}
                        onChange={(e) => {
                          const inputText = e.target.value.slice(0, 100); // Limit to 255 characters
                          handleOptionChange(
                            questionIndex,
                            optionIndex,
                            inputText
                          );
                        }}
                      />
                      {question.tipo === "RESP_UNICA" && (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "start",
                            marginBottom: "2%",
                            marginLeft: "1%",
                          }}
                        >
                          <FormControlLabel
                            required
                            value={optionIndex}
                            control={
                              <Radio style={{ color:" rgba(212, 109, 13, 1)" }} />
                            }
                            label={`Opção ${optionIndex + 1}`}
                            onChange={(e) =>
                              handleCorrectOptionChange(
                                e,
                                questionIndex,
                                optionIndex
                              )
                            }
                            checked={question.resposta[0] === optionIndex}
                          />
                          <Tooltip
                            title="Nova opçao"
                            sx={{
                              display:
                                question.alternativas.length - 1 === optionIndex
                                  ? "flex"
                                  : "none",
                            }}
                          >
                            <IconButton
                              onClick={() => handleAddOption(questionIndex)}
                            >
                              <AddCircleIcon
                                style={{ color: "rgba(212, 109, 13, 1)" }}
                              />
                            </IconButton>
                          </Tooltip>
                        </div>
                      )}
                      {question.tipo === "RESP_MULTIPLA" && (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "start",
                            marginBottom: "2%",
                            marginLeft: "1%",
                          }}
                        >
                          <FormControlLabel
                            required
                            value={optionIndex}
                            control={
                              <Checkbox style={{ color: "rgba(212, 109, 13, 1)"}}
                              />
                            }
                            checked={question.resposta.includes(optionIndex)}
                            label={`Opção ${optionIndex + 1}`}
                            onChange={(e) =>
                              handleMultipleChoiceOptionChange(
                                e,
                                questionIndex,
                                optionIndex
                              )
                            }
                          />
                          <Tooltip
                            title="Nova opção"
                            sx={{
                              display:
                                question.alternativas.length - 1 === optionIndex
                                  ? "flex"
                                  : "none",
                            }}
                          >
                            <IconButton
                              onClick={() => handleAddOption(questionIndex)}
                            >
                              <AddCircleIcon
                                style={{ color: "rgba(212, 109, 13, 1)" }}
                              />
                            </IconButton>
                          </Tooltip>
                        </div>
                      )}
                    </div>
                  ))}
                </Box>
              </>
            ))}
            <Button
              variant="outlined"
              sx={{ width: "100%" }}
              color="primary"
              onClick={handleAddQuestion}
            >
              + Adicionar Questão
            </Button>
            <Grid
              container
              direction="column"
              alignItems="center"
              spacing={2}
              marginTop="5px"
            >
              <Grid
                item
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={onFinish}
                  >
                    Editar atividade
                  </Button>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </ClickAwayListener>
    </Modal>
    </ThemeProvider>

  );
};

export default ModalEditarAtividade;

import React from 'react'
import { useSelector } from 'react-redux'
import Header from '../../components/Header'
import DashAluno from '../../components/DashboardAluno'
import Talentos from '../../components/Talentos'
import { Container } from './styles'
import Rodape from '../../components/Rodape'
import talento from '../../assets/bancoTalentos.svg'
function BancoTalentos() {
  const profile = useSelector(state => state.user.profile)
    return (
      <>
        <Container>
            <div className='title'>
            <img width="10%" src={talento} alt="talento"/>
            <h1 >Banco de Talentos do Oxetech Work</h1>
            <h2 >Ache talentos no nosso banco de inscritos do programa!</h2>    
            </div>
            <Talentos/>
        </Container>
        <Rodape/>
      </>  
    )
  }

export default BancoTalentos

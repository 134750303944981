import React, { useState, useEffect } from 'react';
import { Card, Row } from 'antd';
import { Container } from './styles';
//import { Redirect } from 'react-router-dom';
import {  useHistory} from 'react-router-dom'; 
import api from '../../services/api';

function PageTrilha() {
  const { Meta } = Card;
  const [cursos, setCursos] = useState([]);
  const history = useHistory();



  useEffect(() => {
    (async () => {
      try {
        const resp = await api.get('/trilhas-de-conhecimento/:id');
        setCursos(resp.data);
      } catch (error) {
        console.error('Erro ao obter trilhas: ', error);
      }
    })();
  }, []);

  const handleTrilhaClick = (curso_id) => {
    history.push(`/trilha-de-conhecimento/${curso_id}`);  
  };

  // if (redirectTo) {
  //   return <edirect to={redirectTo} />;
  // }

  return (
    <Container style={{ margin: '0px', padding: '0px', height:'100vh' }}>
      <Row
        style={{
          
        }}
        gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
        className="cards"
      >

      <div>
        <h1  className='hearTrilha'>Trilhas de Conhecimentos</h1>
      </div>
        {cursos.map((curso, index) => {
          return (
            <Card
              key={index}
              className='trilhaConhecimento'
              style={{
                backgroundColor: '#fffff',
                borderRadius: '10px',
                width: 300,
                height: 350,
                '-webkit-box-shadow': 'rgb(191 84 219) 0 0 10px 3px',
                '-moz-box-shadow': 'rgb(191 84 219) 0 0 10px 3px',
                'box-shadow': 'rgb(191 84 219) 0 0 10px 3px',
              }}
              cover={<img style={{ borderRadius: '10px' }} alt="example" src={""} />}
              onClick={() => handleTrilhaClick(curso.curso_id)}
            >
              <Meta
                title={
                  <strong>
                    <small>{curso.titulo}</small>
                  </strong>
                }
                description={curso.descricao}
              />
            </Card>
          );
        })}
      </Row>
    </Container>
  );
}

export default PageTrilha;

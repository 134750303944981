import styled from 'styled-components'

// import background from "/assets/page_oxe_tech_lab.jpg";

export const Container = styled.div`
  display: flex;
  place-content: center;
  align-items: center;
  height: 100vh;

  background-color: linear-gradient(
    180deg,
    rgba(116, 31, 138, 0.9) 35%,
    rgba(0, 0, 99, 1) 100%
  );
  background-size: cover;

  div {
    background: rgba(255, 255, 255, 0.9);
    border-radius: 10px;
  }
`
export const Content = styled.div`
  img {
    width: 15vw;
  }
  .ant-form-item-label > label {
    width: 340px;
    font-size: 16px;
  }

  @media (max-width: 700px) {
    img {
      width: 25vw;
    }
    /* .ant-form-item-control-input-content {
      width: '99%';
    } */
  }
`

import styled from 'styled-components';

export const Container = styled.div`
    margin-top: 4%;
    display: flex;
    flex-direction: column;
    justify-content:center;
    align-items: center;

    .title{
        padding: 0 4%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content:center;
        h1{
            font-size: 2.5em;
            text-align: center;
            margin-bottom: 1px;
            @media (max-width:750px) {
                font-size: 2.2em;
            }
        }
        h3{
            text-align: center;
            @media (max-width:750px) {
                font-size: 1.2em;
            }
        }
    }
`;

import React, { useState, useEffect } from "react";
import Modal from "@mui/material/Modal";
import { Grid, Button, Tooltip } from "@mui/material";
import TextField from "@mui/material/TextField";
import close from "../../../../../assets/iconesOxeLab/close.svg";
import { Content } from "./styles";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import ptLocale from "date-fns/locale/pt-BR";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import api from "../../../../../services/api";
import { useSelector } from "react-redux";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import CircularProgress from "@mui/material/CircularProgress";
import { addDays, subDays, parseISO } from "date-fns";
import InfoIcon from "@mui/icons-material/Info";
import EditInstrutores from "./EditInstrutor";
import ConfirmationModalDelete from "./DeleteTurma";
import { useParams } from "react-router-dom";

const theme = createTheme({
  palette: {
    primary: {
      main: "rgba(212, 109, 13, 0.5)",
      darker: "rgba(212, 109, 13, 1)",
    },
    secondary: {
      main: "rgba(212, 109, 13, 1)",
      darker: "rgba(212, 109, 13, 1)",
    },
  },
});

export default function EditModalCadastro(props) {
  const { open, closeEdit, turmaId } = props;
  const { labId } = useParams();
  const labs = useSelector((state) => state.user.labs_profile);
  const lab = labs.find((lab) => lab.lab_id == labId);
  const [titulo, setTitulo] = useState("");
  const [descricao, setDescricao] = useState("");
  const [instrutores, setInstrutores] = useState([]);
  const [qtd_vagas_total, setQtdVagasTotal] = useState(null);
  const [data_inicio, setDataInicio] = useState("");
  const [data_resultado, setDataResultado] = useState("");
  const [qtd_aulas, setQtdAulas] = useState(null);
  const [data_encerramento, setDataEncerramento] = useState("");
  const [data_encerramento_inscricoes, setDataEncerramentoInscricoes] =
    useState("");
  const [info_adicionais, setInfoAdicionais] = useState("");
  const [carga_horaria, setCargaHoraria] = useState(null);
  const [pergunta, setPergunta] = useState("");
  const [statusTurma, setStatusTurma] = useState("");

  useEffect(() => {
    if (turmaId) {
      api
        .get(`turma/${turmaId}`)
        .then((response) => {
          setTitulo(response.data.titulo);
          setDescricao(response.data.descricao);
          setQtdVagasTotal(response.data.qtd_vagas_total);
          setQtdAulas(response.data.qtd_aulas);
          setDataInicio(parseISO(response.data.data_inicio));
          setDataEncerramento(parseISO(response.data.data_encerramento));
          setDataResultado(parseISO(response.data.data_resultado));
          setDataEncerramentoInscricoes(
            parseISO(response.data.data_encerramento_inscricoes)
          );
          setInfoAdicionais(response.data.info_adicionais);
          setCargaHoraria(response.data.carga_horaria);
          setPergunta(response.data.perguntas[0]);
          setStatusTurma(response.data.status);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [turmaId]);

  const [errorTitulo, setErrorTitulo] = useState("");
  const [errorDescricao, setErrorDescricao] = useState("");
  const [errorInformacaoAdicional, setErrorInformacaoAdicional] = useState("");
  const [errorCargaHoraria, setErrorCargaHoraria] = useState("");
  const [errorInstrutores, setErrorInstrutores] = useState("");
  const [errorqtd_vagas_total, setErrorqtd_vagas_total] = useState("");
  const [errorData_inicio, setErrorData_inicio] = useState("");
  const [errorData_resultado, setErrorData_resultado] = useState("");
  const [errorData_encerramento, setErrorData_encerramento] = useState("");
  const [
    errordata_encerramento_inscricoes,
    setErrordata_encerramento_inscricoes,
  ] = useState("");
  const [errorTotalAulas, setErrorTotalAulas] = useState("");
  const [errorPergunta, setErrorPergunta] = useState("");
  const maxLength = 50;
  const remainingChars = maxLength - pergunta.length;
  const today = new Date();
  const [options, setOptions] = useState([]);
  const [alertSuccessful, setAlertSuccessful] = useState("");
  const [alertUnauthorized, setAlertUnauthorized] = useState("");
  const [loading, setLoading] = useState(false);
  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
  const handleCheckError = () => {
    if (
      !titulo ||
      !descricao ||
      !carga_horaria ||
      !instrutores.length === 0 ||
      !qtd_vagas_total ||
      !data_inicio ||
      !data_resultado ||
      !data_encerramento ||
      !data_encerramento_inscricoes ||
      !qtd_aulas ||
      !pergunta
    ) {
      !titulo && setErrorTitulo("Você não informou o nome da turma!");
      !descricao && setErrorDescricao("Você não preencheu a descrição!");
      !info_adicionais &&
        setErrorInformacaoAdicional("Você não preencheu esse campo!");
      !carga_horaria &&
        setErrorCargaHoraria("Você não informou a carga horária");
      !instrutores.length === 0 &&
        setErrorInstrutores("Você não informou os instrutores");
      !qtd_vagas_total &&
        setErrorqtd_vagas_total("Você não informou a quantidade de vagas");
      !data_inicio &&
        setErrorData_inicio("Você não informou a data de início do curso");
      !data_resultado &&
        setErrorData_resultado("Você não informou a data de início do curso");
      !data_encerramento &&
        setErrorData_encerramento(
          "Você não informou a data de encerramento do curso"
        );
      !data_encerramento_inscricoes &&
        setErrordata_encerramento_inscricoes(
          "Você não informou a data de encerramento das inscrições"
        );
      !qtd_aulas && setErrorTotalAulas("Você não informou o total de aulas!");
      !pergunta && setErrorPergunta("Você não informou a pergunta!");
    } else {
      handleSubmit();
    }
  };

  const handleSubmit = () => {
    const data_form = {
      titulo: titulo,
      descricao: descricao,
      info_adicionais: info_adicionais,
      data_inicio: data_inicio,
      // data_resultado: data_resultado,
      data_encerramento: data_encerramento,
      data_encerramento_inscricoes: data_encerramento_inscricoes,
      qtd_vagas_total: qtd_vagas_total,
      qtd_aulas: qtd_aulas,
      carga_horaria: carga_horaria,
      perguntas: [pergunta],
      // perguntas: "pergunta",
    };
    if (data_resultado.length > 0 && data_resultado !== null) {
      data_form.data_resultado = data_resultado;
    }

    setLoading(true);
    api
      .patch(`turma/${turmaId}`, data_form)
      .then((response) => {
        setLoading(false);
        setAlertSuccessful(response.data.message);
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((err) => {
        setLoading(false);
        setAlertUnauthorized(err.response.data.error);
      });
  };

  useEffect(() => {
    api
      .get(`/agente/agentes-lab?labId=${lab.lab_id}`)
      .then((response) => {
        setOptions(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const handleTituloChange = (e) => {
    const { value } = e.target;
    setTitulo(value);
    setErrorTitulo("");

    if (value.length === 0) {
      setErrorTitulo("Preencha este campo.");
    }
  };

  const handleDescricaoChange = (e) => {
    const { value } = e.target;
    setDescricao(value);
    setErrorDescricao("");

    if (value.length === 0) {
      setErrorDescricao("Preencha este campo.");
    }
  };

  const handleInformacaoAdicionalChange = (e) => {
    const { value } = e.target;
    setInfoAdicionais(value);
    setErrorInformacaoAdicional("");

    if (value.length === 0) {
      setErrorInformacaoAdicional("Preencha este campo.");
    }
  };

  const handleCargaHorariaChange = (e) => {
    const { value } = e.target;
    setCargaHoraria(value);
    setErrorCargaHoraria("");

    if (value.length === 0) {
      setErrorCargaHoraria("Preencha este campo.");
    } else if (value <= 0) {
      setErrorCargaHoraria("A carga horária deve ser maior que zero.");
    }
  };

  const handleQuantAulasChange = (e) => {
    const { value } = e.target;
    setQtdAulas(value);
    setErrorTotalAulas("");

    if (value.length === 0) {
      setErrorTotalAulas("Preencha este campo.");
    } else if (value <= 0) {
      setErrorTotalAulas("O número de aulas deve ser maior que zero.");
    }
  };

  const handleDataInicioChange = (date) => {
    setDataInicio(new Date(date).toJSON());
    setErrorData_inicio("");

    // setInscricoesDataMaxima(subDays(new Date(date).toJSON(), 2))
  };

  const handleDataResultadoChange = (date) => {
    setDataResultado(new Date(date).toJSON());
    setErrorData_resultado("");
  };

  const handleDataEncerramentoChange = (date) => {
    setDataEncerramento(new Date(date).toJSON());
    setErrorData_encerramento("");

    if (!date) {
      setErrorData_encerramento("Preencha este campo.");
    }
  };

  const handleQuantVagasChange = (e) => {
    const { value } = e.target;
    setQtdVagasTotal(value);
    setErrorqtd_vagas_total("");

    if (value.length === 0) {
      setErrorqtd_vagas_total("Preencha este campo.");
    } else if (value <= 0) {
      setErrorqtd_vagas_total("A quantidade de vagas deve ser maior que zero.");
    }
  };

  const handleDataEncerramentoInscChange = (e) => {
    setDataEncerramentoInscricoes(new Date(e).toJSON());
    setErrordata_encerramento_inscricoes("");

    if (e.length === 0) {
      setErrordata_encerramento_inscricoes("Preencha este campo.");
    }
  };

  const handlePerguntaChange = (e) => {
    const { value } = e.target;
    setPergunta(value);
    setErrorPergunta("");

    if (value.length === 0) {
      setErrorPergunta("Preencha este campo.");
    } else if (remainingChars < 0) {
      setErrorPergunta("Limite de caracteres excedido.");
    }
  };

  return (
    <Modal
      open={open}
      onClose={closeEdit}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "15%",
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Content>
        <ThemeProvider theme={theme}>
          <div className="header">
            <h1>Editar Turma</h1>
            <img
              className="close"
              src={close}
              alt="Fechar"
              onClick={closeEdit}
            />
          </div>
          <div style={{ padding: "2%" }}>
            <div className="area">
              <h2 className="titulo">Sobre o Curso</h2>
              <Grid sx={{ p: "2% 1%" }} container spacing={2}>
                <Grid item xs={12} md={12}>
                  <h3 className="label">Nome da Turma</h3>
                  <TextField
                    sx={{
                      bgcolor: errorTitulo
                        ? "transparent"
                        : "rgba(212, 109, 13, 0.1)",
                    }}
                    placeholder="Digite o nome da turma"
                    focused
                    error={Boolean(errorTitulo)}
                    helperText={errorTitulo}
                    value={titulo}
                    fullWidth
                    onChange={handleTituloChange}
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <h3 className="label">Descrição</h3>
                  <TextField
                    multiline
                    rows={3}
                    placeholder="Descreva informações sobre o curso"
                    focused
                    sx={{
                      bgcolor: errorDescricao
                        ? "transparent"
                        : "rgba(212, 109, 13, 0.1)",
                    }}
                    error={Boolean(errorDescricao)}
                    helperText={errorDescricao}
                    value={descricao}
                    fullWidth
                    onChange={handleDescricaoChange}
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <h3 className="label">Informações adicionais</h3>
                  <TextField
                    multiline
                    rows={2}
                    placeholder="Informe os dias de curso e horários..."
                    focused
                    sx={{
                      bgcolor: errorInformacaoAdicional
                        ? "transparent"
                        : "rgba(212, 109, 13, 0.1)",
                    }}
                    error={Boolean(errorInformacaoAdicional)}
                    helperText={errorInformacaoAdicional}
                    value={info_adicionais}
                    fullWidth
                    onChange={handleInformacaoAdicionalChange}
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <h3 className="label">Instrutores</h3>
                  <EditInstrutores agentes={options} turmaId={turmaId} />
                </Grid>
                <Grid item xs={12} md={3}>
                  <h3 className="label">Carga Horária</h3>
                  <TextField
                    focused
                    value={carga_horaria}
                    type="number"
                    sx={{
                      bgcolor: errorCargaHoraria
                        ? "transparent"
                        : "rgba(212, 109, 13, 0.1)",
                    }}
                    error={Boolean(errorCargaHoraria)}
                    helperText={errorCargaHoraria}
                    size="large"
                    fullWidth
                    onChange={handleCargaHorariaChange}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <h3 className="label">Total de aulas</h3>
                  <TextField
                    focused
                    value={qtd_aulas}
                    type="number"
                    sx={{
                      bgcolor: errorTotalAulas
                        ? "transparent"
                        : "rgba(212, 109, 13, 0.1)",
                    }}
                    error={Boolean(errorTotalAulas)}
                    helperText={errorTotalAulas}
                    size="large"
                    fullWidth
                    onChange={handleQuantAulasChange}
                  />
                </Grid>

                <Grid item xs={12} md={3}>
                  <h3 className="label">Data de início do curso</h3>
                  <LocalizationProvider
                    locale={ptLocale}
                    dateAdapter={AdapterDateFns}
                  >
                    <DatePicker
                      value={new Date(data_inicio)}
                      onChange={handleDataInicioChange}
                      renderInput={(params) => (
                        <TextField
                          size="large"
                          sx={{
                            bgcolor: errorData_inicio
                              ? "transparent"
                              : "rgba(212, 109, 13, 0.1)",
                          }}
                          focused
                          fullWidth
                          {...params}
                          error={Boolean(errorData_inicio)}
                          helperText={errorData_inicio}
                        />
                      )}
                      minDate={today}
                      inputFormat="dd/MM/yyyy"
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={12} md={3}>
                  <h3 className="label">Data de encerramento do curso</h3>
                  <LocalizationProvider
                    locale={ptLocale}
                    dateAdapter={AdapterDateFns}
                  >
                    <DatePicker
                      value={data_encerramento}
                      onChange={handleDataEncerramentoChange}
                      renderInput={(params) => (
                        <TextField
                          size="large"
                          sx={{
                            bgcolor: errorData_encerramento
                              ? "transparent"
                              : "rgba(212, 109, 13, 0.1)",
                          }}
                          focused
                          fullWidth
                          {...params}
                          error={Boolean(errorData_encerramento)}
                          helperText={errorData_encerramento}
                        />
                      )}
                      minDate={data_inicio}
                      inputFormat="dd/MM/yyyy"
                    />
                  </LocalizationProvider>
                </Grid>
              </Grid>
            </div>
            <div className="area">
              <h2 className="titulo">Sobre as Inscrições</h2>
              <Grid sx={{ p: "2% 1%" }} container spacing={2}>
                <Grid item xs={12} md={4}>
                  <h3 className="label">Quantidade de Vagas</h3>
                  <TextField
                    type="number"
                    focused
                    disabled={statusTurma === "2"}
                    sx={{
                      bgcolor: errorqtd_vagas_total
                        ? "transparent"
                        : "rgba(212, 109, 13, 0.1)",
                    }}
                    error={Boolean(errorqtd_vagas_total)}
                    helperText={errorqtd_vagas_total}
                    value={qtd_vagas_total}
                    size="large"
                    fullWidth
                    onChange={handleQuantVagasChange}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <h3 className="label">Data de encerramento das inscrições</h3>
                  <LocalizationProvider
                    locale={ptLocale}
                    dateAdapter={AdapterDateFns}
                  >
                    <DatePicker
                      value={data_encerramento_inscricoes}
                      onChange={handleDataEncerramentoInscChange}
                      disabled={statusTurma === "2"}
                      renderInput={(params) => (
                        <TextField
                          size="large"
                          sx={{
                            bgcolor: errordata_encerramento_inscricoes
                              ? "transparent"
                              : "rgba(212, 109, 13, 0.1)",
                          }}
                          focused
                          fullWidth
                          {...params}
                          error={Boolean(errordata_encerramento_inscricoes)}
                          helperText={errordata_encerramento_inscricoes}
                        />
                      )}
                      minDate={today}
                      maxDate={subDays(parseISO(data_inicio), 2)}
                      inputFormat="dd/MM/yyyy"
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={12} md={4}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <h3 className="label">Data do resultado da seleção</h3>
                    <Tooltip
                      title="A data do resultado da seleção deve estar entre o fim das inscrições e o início das aulas"
                      style={{ marginBottom: "2%" }}
                    >
                      <InfoIcon fontSize="small" />
                    </Tooltip>
                  </div>
                  <LocalizationProvider
                    locale={ptLocale}
                    dateAdapter={AdapterDateFns}
                  >
                    <DatePicker
                      value={data_resultado}
                      onChange={handleDataResultadoChange}
                      disabled={statusTurma === "2"}
                      renderInput={(params) => (
                        <TextField
                          size="large"
                          sx={{
                            bgcolor: errorData_resultado
                              ? "transparent"
                              : "rgba(212, 109, 13, 0.1)",
                          }}
                          focused
                          fullWidth
                          {...params}
                          error={Boolean(errorData_resultado)}
                          helperText={errorData_resultado}
                        />
                      )}
                      minDate={addDays(parseISO(data_encerramento_inscricoes), 1)}
                      maxDate={subDays(parseISO(data_inicio), 1)}
                      inputFormat="dd/MM/yyyy"
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={12} md={12}>
                  <h3 className="label">Pergunta</h3>
                  <TextField
                    fullWidth
                    multiline
                    disabled={statusTurma === "2"}
                    inputProps={{ maxLength: 50 }}
                    sx={{
                      bgcolor: errorPergunta
                        ? "transparent"
                        : "rgba(212, 109, 13, 0.1)",
                    }}
                    placeholder="Adicione uma pergunta para os inscritos que possa auxiliar na avaliação da seleção."
                    focused
                    error={Boolean(errorPergunta)}
                    helperText={errorPergunta}
                    value={pergunta}
                    onChange={handlePerguntaChange}
                  />

                  <p style={{ textAlign: "end" }}>
                    {remainingChars}/{maxLength} caracteres restantes
                  </p>
                </Grid>
              </Grid>
            </div>
            <div style={{ display: "flex", justifyContent: "end" }}>
              {/* <Button
                startIcon={ <DeleteIcon/>}
                variant="outlined"
                color="error"
                onClick={() => setOpenConfirmDelete(true)}
              >
                Excluir Turma
              </Button> */}
              <Button
                startIcon={
                  loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null
                }
                variant="contained"
                color="secondary"
                onClick={handleCheckError}
              >
                Editar Turma
              </Button>
            </div>
          </div>
          <ConfirmationModalDelete
            open={openConfirmDelete}
            onClose={() => setOpenConfirmDelete(false)}
            turmaId={turmaId}
          />
        </ThemeProvider>

        <Snackbar
          open={alertUnauthorized.length > 0}
          autoHideDuration={6000}
          onClose={() => setAlertUnauthorized("")}
        >
          <Alert
            variant="filled"
            onClose={() => setAlertUnauthorized("")}
            severity="error"
            sx={{ width: "100%" }}
          >
            {alertUnauthorized}
          </Alert>
        </Snackbar>
        <Snackbar
          open={alertSuccessful.length > 0}
          autoHideDuration={6000}
          onClose={() => setAlertSuccessful("")}
        >
          <Alert
            variant="filled"
            onClose={() => setAlertSuccessful("")}
            severity="success"
            sx={{ width: "100%" }}
          >
            {alertSuccessful}
          </Alert>
        </Snackbar>
      </Content>
    </Modal>
  );
}

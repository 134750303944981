import React from 'react';
import ReactApexChart from 'react-apexcharts';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { useMediaQuery, Typography} from '@mui/material';
import * as palette from "../../utils/styledVariables";

const LineChartCard = ({ dataPoints }) => {
  const isSmallScreen = useMediaQuery('(max-width:600px)');

  const options = {
    chart: {
      type: 'line',
      height: isSmallScreen ? 150 : 200,
      width: isSmallScreen ? 180 : 300,
      background: 'white',
      borderRadius: 10,
    },
    xaxis: {
      type: 'category',
      categories: dataPoints.map((point) => point.x),
    },
    markers: {
      size: 6,
    },
    stroke: {
      width: 2,
      curve: 'smooth',
      colors: ['black'],
    },
  };

  const series = [
    {
      name: 'Pontos',
      data: dataPoints.map((point) => ({
        x: point.x,
        y: point.y,
        fillColor: point.color,
        strokeColor: point.color,
        strokeWidth: 2,
        size: 6,
      })),
    },
  ];

  return (
    <Card sx={{ backgroundColor: palette.laranjaOXLTransparente, color: 'black' }}>
      <CardContent sx={{ borderRadius: '10px', padding: isSmallScreen ? '10px' : '10px 10px 20px', height: isSmallScreen ? "50px": "33.2vh"}}>
      <Typography sx={{ fontSize: isSmallScreen ? '1.0vh' : '2.0vh', color: palette.preto, fontFamily: "Poppins", fontWeight: '500' }}>Médias por Módulo</Typography>
        <div style={{ borderRadius: '10px', overflow: 'hidden' }}>
          <ReactApexChart options={options} series={series} type="line" height={isSmallScreen ? 80 : 150} />
        </div>
        <div style={{ display: 'flex', flexDirection: isSmallScreen ? 'column' : 'row', alignItems: 'center', flexWrap: 'wrap' }}>
            {dataPoints.map((point, index) => (
                <div key={point.x} style={{ display: 'flex', alignItems: 'center', marginRight: (index < dataPoints.length - 1 && !isSmallScreen) ? '20px' : '0', marginBottom: '10px' }}>
                <div style={{ width: '12px', height: '12px', backgroundColor: point.color, marginRight: '5px', borderRadius: '50%' }}></div>
                <div>{point.x}</div>
                </div>
            ))}
            </div>

      </CardContent>
    </Card>
  );
};

export default LineChartCard;

import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid';
import Typography from "@mui/material/Typography";
import 'animate.css'


export const Container = styled(Box)`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: start;
  width: 100vw;
  height: auto;
  background: radial-gradient(202.53% 215.33% at 8.34% 113.99%, rgba(34, 76, 223, 0.7) 3.42%, rgba(30, 67, 196, 0.7) 15.05%, rgba(0, 0, 0, 0.7) 75.2%);
  @media(max-width: 800px){
    /* flex-direction: column; */
  }
 
`
export const Textos = styled(Box)`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-around;
  padding: 2%;
  width: 50vw;
  flex-grow: 1;

  .titulo{
    color: white;
    font-family: 'Poppins', sans-serif;
    font-size: 5em;
    font-weight: 400;
    animation: Icons 4s ease 0s 1 normal forwards;
    @media(max-width: 800px){
      font-size: 2em;
    }

    @media(max-width: 401px){
      padding-left: 20%;
    }
  }
  .subtitulo{
    margin-top: 4%;
    color: rgba(255, 255, 255, 0.81);
    font-family: 'Poppins', sans-serif;
    text-align: justify;
    font-size: 1.3em;
    font-weight: 400;
    animation: Icons 4s ease 0s 1 normal forwards;

    @media(max-width: 800px){
      font-size: 0.5em;
    }
  }

  @keyframes Icons {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}
  
`
export const GridBox = styled(Grid)`
    @media(max-width: 430px){
      width: 200%;
      margin-left: 100% ;
    }
  
`
export const GridItem = styled(Grid)`
  
  margin-top: 4%;
  display: flex;

  align-items: center;
  flex-direction: column;
  justify-content: start;
  .desc{
    width: 60%;
    text-align: center;
    font-size:0.8em;
    color: #F8F8F8;
    animation: Icons 4s ease 0s 1 normal forwards;
    @media(max-width: 800px){
      width: 100%;
      font-size:0.5em;
    }
  }
  .img{
    width: 30%;
    animation: Icons 4s ease 0s 1 normal forwards;
    @media(max-width: 800px){
      width: 40%;
    }
  }

  
  
`

export const Figura = styled(Box)`
  width: 50vw;
  display: flex;
  justify-content: center;
  animation: Icons 4s ease 0s 1 normal forwards;

  /* @media(max-width: 800px){
    padding-top: 0;
  } */
`



import * as palette from "../../utils/styledVariables";
import CancelIcon from "@mui/icons-material/Cancel";
import Options from "../../utils/options";
import api from "../../services/api";
import ptLocale from "date-fns/locale/pt-BR";
import { useDispatch, useSelector } from "react-redux";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { Container, Subtitles } from "./styles";
import React, { useState, useEffect } from "react";
import SaveIcon from "@mui/icons-material/Save";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import {
  Chip,
  Typography,
  Card,
  styled,
  Box,
  Grid,
  Button,
  TextField,
  FormControlLabel,
  Checkbox,
  ThemeProvider,
  createTheme,
} from "@mui/material";
import ModalOTWork from "./ModalOTWork";
import { Link } from "react-router-dom";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useSnackbar } from "notistack";

const MainTextField = styled((props) => <TextField {...props} />)(() => ({
  input: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "30px",
    lineHeight: "35px",
    display: "flex",
    alignItems: "center",
    color: "#F8F8F8",
    error: {
      color: palette.profileWhiteShade,
    },
  },
  ".MuiFormHelperText-root": {
    backgroundColor: palette.profileWhiteShade,
    borderRadius: "5px",
    padding: 1,
    textAlign: "center",
    width: "150px",
  },
}));

const theme = createTheme({
  palette: {
    secondary: {
      main: palette.laranjaIntermediario2,
    },
  },
});

const PublishButton = styled((props) => <Button {...props} />)(() => ({
  // background:
  //   "radial-gradient(292.41% 335.33% at 41.6% 50%, #741F8A 2.22%, #1609B3 100%)",
  background: palette.laranjaIntermediario2,
  "&:hover": {
    background: palette.laranjaSecundario,
  },
  color: palette.profileWhiteShade,
  float: "right",
  marginRight: "10px",
}));

function formatStoredList(key) {
  return sessionStorage.getItem(key)
    ? JSON.parse(sessionStorage.getItem(key))
    : [];
}

function formatStoredString(key) {
  const value = sessionStorage.getItem(key);
  if (value != null) {
    return JSON.parse(value);
  }

  return "";
}

function CardCadastroVaga(props) {
  const { enqueueSnackbar } = useSnackbar();

  let profile = useSelector((state) => {
    if (
      state &&
      state.user &&
      state.user.profile &&
      state.user.profile.dataValues
    ) {
      return state.user.profile.dataValues;
    } else if (state && state.user && state.user.profile) {
      return state.user.profile;
    }
  });

  const { close, submit, onOxetechWork } = props;
  const [jobTitle, setJobTitle] = useState(sessionStorage.getItem("jobTitle"));
  const [ciclosAbertos, setCiclosAbertos] = useState([]);
  const [publicarNoOxetechWorkText, setPublicarNoOxetechWorkText] = useState(
    "Publicar a vaga no OxeTech Work?"
  );
  const [publicarNoOxetechWorkColor, setPublicarNoOxetechWorkColor] =
    useState("black");

  useEffect(() => {
    api.get("oxetechwork/ciclo/abertos").then((response) => {
      if (response.data.length === 0) {
        setPublicarNoOxetechWorkText(
          "Não há ciclos ativos para publicar a vaga no OxeTech Work no momento!"
        );
        setPublicarNoOxetechWorkColor("red");
        setOxetechWork(false);
      }
      setCiclosAbertos(response.data);
    });
  }, []);

  const [loading, setLoading] = React.useState(false);

  const [description, setDescription] = useState(
    sessionStorage.getItem("description") || ""
  );
  const [selectedRequirements, setSelectedRequirements] = useState(
    formatStoredList("requirements")
  );
  const [qualifications, setQualifications] = useState(
    formatStoredString("qualifications")
  );
  const [aditionalInfo, setAditionalInfo] = useState(
    sessionStorage.getItem("aditionalInfo")
  );
  const [jobsAmount, setJobsAmount] = useState(
    sessionStorage.getItem("jobsAmount")
  );
  const [endDate, setEndDate] = useState(sessionStorage.getItem("endDate"));

  const [remuneration, setRemuneration] = useState(
    sessionStorage.getItem("remuneration")
  );
  const [workLoad, setWorkLoad] = useState(sessionStorage.getItem("workload"));
  const [jobType, setJobType] = useState(formatStoredString("jobType"));
  const [jobModality, setJobModality] = useState(
    formatStoredString("jobModality")
  );
  const [oxetechWork, setOxetechWork] = useState(onOxetechWork);

  const [openModal, setOpenModal] = useState(false);

  const [errorJobTitle, setErrorJobTitle] = useState(null);
  const [errorDescription, setErrorDescription] = useState(null);
  const [errorRequirements, setErrorRequirements] = useState(null);
  const [errorQualifications, setErrorQualifications] = useState(null);
  const [errorJobModality, setErrorJobModality] = useState(null);
  const [errorJobsAmount, setErrorJobsAmount] = useState(null);
  const [errorEndDate, setErrorEndDate] = useState(null);
  const [errorJobType, setErrorJobType] = useState(null);
  const [check, setCheck] = useState(false);
  const [isFormFilled, setIsFormFilled] = useState(false);

  const today = new Date();
  const textLength = 4000;
  const titleLength = 64;
  const filter = createFilterOptions();

  const skills = Options.optionsSkills.map((skill) => {
    return { label: skill.value, value: skill.value };
  });
  const handleClose = (e) => {
    close();
    e.preventDefault();
  };

  const handleJobTitle = (e) => {
    const {
      target: { value },
    } = e;
    setJobTitle(value);
    setErrorJobTitle({ jobTitle: "" });
    if (value.length > 0) sessionStorage.setItem("jobTitle", value);
    else {
      setErrorJobTitle({ jobTitle: "Preencha esse campo." });
      sessionStorage.removeItem("jobTitle");
    }
  };

  // const handleDescription = (e) => {
  //   console.log(e);
  //   setDescription(e);
  //   sessionStorage.setItem("description", e);
  // };
  const handleRequirements = (e, value) => {
    setErrorRequirements({ requirements: "" });
    if (e.type === "blur") {
      if (!sessionStorage.getItem("requirements"))
        setErrorRequirements({ requirements: "Preencha esse campo." });
    } else if (value.length == 0) {
      sessionStorage.removeItem("requirements");
      setSelectedRequirements([]);
      setErrorRequirements({ requirements: "Preencha esse campo." });
    } else {
      if (!Array.isArray(value)) {
        value = [value];
      }
      if (typeof value[value.length - 1] != "object") {
        value.splice(-1, 1, {
          label: value[value.length - 1],
          value: value[value.length - 1],
        });
      }
      setSelectedRequirements(value);
      sessionStorage.setItem("requirements", JSON.stringify(value));
    }
  };

  const handleQualifications = (e, value) => {
    setQualifications(value);
    setErrorQualifications({ qualifications: "" });
    if (value) sessionStorage.setItem("qualifications", JSON.stringify(value));
    else {
      setErrorQualifications({ qualifications: "Preencha esse campo." });
      sessionStorage.removeItem("qualifications");
    }
  };

  const handleJobsAmount = (e) => {
    const {
      target: { value },
    } = e;
    if (value >= 1 || value === "") {
      setJobsAmount(value);
    }

    setErrorJobsAmount({ jobsAmount: "" });
    if (value.length > 0) sessionStorage.setItem("jobsAmount", value);
    else {
      setErrorJobsAmount({ jobsAmount: "Preencha esse campo." });
      sessionStorage.removeItem("jobsAmount");
    }
  };

  const handleJobModality = (e, value) => {
    setJobModality(value);
    setErrorJobModality({ jobModality: "" });
    if (value) sessionStorage.setItem("jobModality", JSON.stringify(value));
    else {
      setErrorJobModality({ jobModality: "Preencha esse campo." });
      sessionStorage.removeItem("jobModality");
    }
  };

  const handleJobType = (e, value) => {
    setJobType(value);
    setErrorJobType({ jobType: "" });
    if (value) sessionStorage.setItem("jobType", JSON.stringify(value));
    else {
      setErrorJobType({ jobType: "Preencha esse campo." });
      sessionStorage.removeItem("jobType");
    }
  };
  const handleDescriptionChange = (value) => {
    setDescription(value);
    sessionStorage.setItem("description", value);
    if (value) sessionStorage.setItem("description", value);
    else {
      setErrorDescription({ description: "Preencha esse campo." });
      sessionStorage.removeItem("description");
    }
  };

  const handleRemuneration = (e) => {
    const {
      target: { value },
    } = e;
    if (value >= 1 || value === "") {
      setRemuneration(value);
    }
    if (value.length > 0) sessionStorage.setItem("remuneration", value);
  };

  const handleWorkLoad = (e) => {
    const {
      target: { value },
    } = e;
    setWorkLoad(value);
    if (value.length > 0) sessionStorage.setItem("workload", value);
  };

  const handleEndDate = (value) => {
    setEndDate(value);
    setErrorEndDate({ endDate: "" });
    if (value) sessionStorage.setItem("endDate", new Date(value).toJSON());
    else {
      setErrorEndDate({ endDate: "Preencha esse campo." });
      sessionStorage.removeItem("endDate");
    }
  };

  const checkErrors = () => {
    const errorMsg = "Preencha esse campo.";

    setErrorEndDate(
      endDate?.length < 1 || endDate == null
        ? { endDate: errorMsg }
        : { endDate: "" }
    );
    setErrorDescription(
      description === null || description.length === 0
        ? { description: errorMsg }
        : { description: "" }
    );
    setErrorQualifications(
      qualifications?.length < 1 || qualifications == null
        ? { qualifications: errorMsg }
        : { qualifications: "" }
    );
    setErrorJobTitle(
      jobTitle == null || jobTitle == ""
        ? { jobTitle: errorMsg }
        : { jobTitle: "" }
    );
    setErrorJobModality(
      jobModality?.length < 1 || jobModality == null
        ? { jobModality: errorMsg }
        : { jobModality: "" }
    );
    setErrorJobType(
      jobType?.length < 1 || jobType == null
        ? { jobType: errorMsg }
        : { jobModality: "" }
    );
    setErrorRequirements(
      selectedRequirements.length < 1
        ? { requirements: errorMsg }
        : { requirements: "" }
    );

    setErrorJobsAmount(
      jobsAmount?.length < 1 || jobsAmount == null
        ? { jobsAmount: errorMsg }
        : { jobsAmount: "" }
    );

    return !(
      endDate &&
      description &&
      qualifications &&
      jobTitle &&
      jobModality &&
      jobType &&
      jobsAmount &&
      selectedRequirements
    );
  };
  const handleFinish = async (data, files) => {
    const errors = checkErrors();
    setLoading(true);
    if (!errors) {
      const form = new FormData();
      form.append("file", files);
      form.append("data", JSON.stringify(data));
      api
        .post("vagas/empresacreatevaga-otw", form)
        .then((res) => {
          sessionStorage.removeItem("jobTitle");
          sessionStorage.removeItem("description");
          sessionStorage.removeItem("requirements");
          sessionStorage.removeItem("qualifications");
          sessionStorage.removeItem("aditionalInfo");
          sessionStorage.removeItem("jobsAmount");
          sessionStorage.removeItem("endDate");
          sessionStorage.removeItem("remuneration");
          sessionStorage.removeItem("workload");
          sessionStorage.removeItem("jobType");
          sessionStorage.removeItem("jobModality");
          sessionStorage.removeItem("name");
          sessionStorage.removeItem("cpf");
          sessionStorage.removeItem("email");
          sessionStorage.removeItem("cargo");
          sessionStorage.removeItem("fileIdCurriculo");
          sessionStorage.removeItem("fileCurriculo");
          sessionStorage.removeItem("fileIdPropostaInovacao");
          sessionStorage.removeItem("fileProposta");
          sessionStorage.removeItem("mentor_nome");
          sessionStorage.removeItem("mentor_cpf");
          sessionStorage.removeItem("mentor_email");
          sessionStorage.removeItem("mentor_cargo");
          submit(true);
          // window.location.reload();
          close();
        })
        .catch((error) => {
          setLoading(false);
          enqueueSnackbar(error.response.data.error, { variant: "error" });
          setOpenModal(false);
          // console.log(error.response);
        });
    }
  };

  const handlePublish = async () => {
    const data = {
      titulo: jobTitle,
      descricao: description,
      requisitos: JSON.stringify(
        selectedRequirements.map((item) => item.value)
      ),
      qualificacoes: JSON.stringify(qualifications.value),
      quantidade: jobsAmount,
      data_encerramento: endDate,
      remuneracao: remuneration,
      carga_horaria: workLoad?.length > 0 ? workLoad : null,
      tipo: jobType.value,
      modalidade: jobModality.value,
      oxetech_work: profile.oxetech_work ? oxetechWork : false,
      status_oxetech_work: null,
    };
    const errors = checkErrors();
    if (!errors) {
      try {
        const response = await api.post("vagas/empresacreate", data);

        sessionStorage.removeItem("jobTitle");
        sessionStorage.removeItem("description");
        sessionStorage.removeItem("requirements");
        sessionStorage.removeItem("qualifications");
        sessionStorage.removeItem("aditionalInfo");
        sessionStorage.removeItem("jobsAmount");
        sessionStorage.removeItem("endDate");
        sessionStorage.removeItem("remuneration");
        sessionStorage.removeItem("workload");
        sessionStorage.removeItem("jobType");
        sessionStorage.removeItem("jobModality");

        submit(false);
        // window.location.reload();
      } catch (err) {
        console.log(err);
      }
    }
    // await handleFinish(data);
  };

  const handleSubmitOTW = async (mentorData, files) => {
    if (mentorData) {
      const data = {
        ...mentorData,
        titulo: jobTitle,
        descricao: description,
        requisitos: JSON.stringify(
          selectedRequirements.map((item) => item.value)
        ),
        qualificacoes: JSON.stringify(qualifications.value),
        quantidade: jobsAmount,
        data_encerramento: endDate,
        remuneracao: remuneration,
        carga_horaria: workLoad?.length > 0 ? workLoad : null,
        tipo: jobType.value,
        modalidade: jobModality.value,
        oxetech_work: oxetechWork,
      };

      await handleFinish(data, files);
    }
  };

  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline", "strike"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["clean"],
    ],
  };

  const handleBlur = (range, source, quill) => {
    setDescription(quill.getHTML());
  };

  const editorStyle = {
    height: "300px", // altura fixa para o editor
    overflowY: "auto", // habilita a barra de rolagem vertical
  };

  return (
    <Card sx={{ background: palette.laranjaOXLTransparente }}>
      <Container>
        <MainTextField
          hiddenLabel
          id="nome-vaga"
          placeholder="Título da vaga"
          variant="standard"
          inputProps={{ maxLength: titleLength }}
          InputProps={{ disableUnderline: true }}
          fullWidth
          value={jobTitle}
          onChange={handleJobTitle}
          onBlur={handleJobTitle}
          error={Boolean(errorJobTitle?.jobTitle)}
          helperText={errorJobTitle?.jobTitle}
          required
        />
        <Button onClick={handleClose}>
          <CancelIcon
            sx={{ color: palette.profileWhiteShade }}
            fontSize="large"
          />
        </Button>
      </Container>
      <Grid container spacing={1} sx={{ padding: "10px" }}>
        <Grid item xs={12} md={12} lg={12}>
          <Subtitles>Descrição</Subtitles>

          <ReactQuill
            id="descricao-vaga"
            value={description}
            onChange={handleDescriptionChange}
            onBlur={handleBlur}
            theme="snow"
            modules={modules}
            style={editorStyle}
          />
          {/* <p style={{display: errorDescription?.description.length>0 ? 'flex': 'none', color: 'red'}}>Preencha este campo!</p> */}
        </Grid>
        <Grid item xs={12} sm={12} md={3} lg={3}>
          <Subtitles>Requisitos</Subtitles>
          <ThemeProvider theme={theme}>
            <Autocomplete
              id="requisitos"
              multiple
              freeSolo
              onChange={(event, value) => handleRequirements(event, value)}
              // onBlur={(event, value) => handleCategories(event, value)}
              options={skills}
              value={selectedRequirements}
              disableCloseOnSelect
              filterOptions={(options, params) => {
                const filtered = filter(options, params);

                const { inputValue } = params;

                const isExisting = options.some(
                  (option) => inputValue === option.label
                );

                if (inputValue !== "" && !isExisting) {
                  filtered.push({
                    value: inputValue,
                    label: `Adicionar "${inputValue}"`,
                  });
                }

                return filtered;
              }}
              getOptionLabel={(option) => {
                if (typeof option === "string") return option;

                return option.value;
              }}
              renderOption={(props, option) => (
                <li {...props}>{option.label}</li>
              )}
              renderValue={(selected) => (
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                  {selected.map((value) => (
                    <Chip key={value} label={value} />
                  ))}
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth={true}
                  required
                  color="secondary"
                  placeholder="Pesquisar requisito"
                  //onBlur={(event, value) => handleCategories(event, value)}
                  error={Boolean(errorRequirements?.requirements)}
                  helperText={errorRequirements?.requirements}
                  hiddenLabel
                  variant="filled"
                />
              )}
            />
          </ThemeProvider>
        </Grid>
        <Grid item xs={12} sm={12} md={3} lg={3}>
          <Subtitles>Nível de formação</Subtitles>
          <ThemeProvider theme={theme}>
            <Autocomplete
              id="qualificacoes"
              onChange={(event, value) => handleQualifications(event, value)}
              // onBlur={(event, value) => handleCategories(event, value)}
              options={Options.qualifications}
              value={qualifications?.value}
              disableCloseOnSelect
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth={true}
                  required
                  color="secondary"
                  //onBlur={(event, value) => handleCategories(event, value)}
                  error={Boolean(errorQualifications?.qualifications)}
                  helperText={errorQualifications?.qualifications}
                  hiddenLabel
                  variant="filled"
                />
              )}
            />
          </ThemeProvider>
        </Grid>
        <Grid item xs={12} sm={12} md={3} lg={3}>
          <Subtitles>Modalidade</Subtitles>
          <ThemeProvider theme={theme}>
            <Autocomplete
              id="modalidade"
              onChange={(e, value) => handleJobModality(e, value)}
              options={Options.modalities}
              value={jobModality?.value}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth={true}
                  required
                  color="secondary"
                  //onBlur={(event, value) => handleCategories(event, value)}
                  error={Boolean(errorJobModality?.jobModality)}
                  helperText={errorJobModality?.jobModality}
                  hiddenLabel
                  variant="filled"
                />
              )}
            />
          </ThemeProvider>
        </Grid>
        <Grid item xs={12} sm={12} md={3} lg={3}>
          <Subtitles>Tipo</Subtitles>
          <ThemeProvider theme={theme}>
            <Autocomplete
              id="tipo"
              onChange={(e, value) => handleJobType(e, value)}
              options={Options.types}
              value={jobType?.value}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth={true}
                  required
                  color="secondary"
                  //onBlur={(event, value) => handleCategories(event, value)}
                  error={Boolean(errorJobType?.jobType)}
                  helperText={errorJobType?.jobType}
                  hiddenLabel
                  variant="filled"
                />
              )}
            />
          </ThemeProvider>
        </Grid>
        <Grid item xs={12} sm={12} md={3} lg={3}>
          <Subtitles>Quantidade de vagas</Subtitles>
          <TextField
            hiddenLabel
            id="qtd-vagas"
            variant="filled"
            type="number"
            fullWidth
            color="secondary"
            value={jobsAmount}
            onChange={handleJobsAmount}
            onBlur={handleJobsAmount}
            error={Boolean(errorJobsAmount?.jobsAmount)}
            helperText={errorJobsAmount?.jobsAmount}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={3} lg={3}>
          <ThemeProvider theme={theme}>
            <Subtitles>Data de encerramento</Subtitles>
            <LocalizationProvider
              locale={ptLocale}
              dateAdapter={AdapterDateFns}
            >
              <DatePicker
                value={endDate}
                onChange={handleEndDate}
                renderInput={(params) => (
                  <TextField
                    variant="filled"
                    fullWidth
                    color="secondary"
                    {...params}
                    error={Boolean(errorEndDate?.endDate)}
                    helperText={errorEndDate?.endDate}
                  />
                )}
                minDate={today}
                inputFormat="dd/MM/yyyy"
              />
            </LocalizationProvider>
          </ThemeProvider>
        </Grid>
        <Grid item xs={12} sm={12} md={3} lg={3}>
          <ThemeProvider theme={theme}>
            <Subtitles>Remuneração</Subtitles>
            <TextField
              hiddenLabel
              id="remuneracao"
              variant="filled"
              type="number"
              fullWidth
              color="secondary"
              disabled={oxetechWork}
              value={oxetechWork ? "1500" : remuneration}
              onChange={handleRemuneration}
              onBlur={handleRemuneration}
            />
          </ThemeProvider>
        </Grid>
        <Grid item xs={12} sm={12} md={3} lg={3}>
          <Subtitles>Carga horária semanal</Subtitles>
          <ThemeProvider theme={theme}>
            <TextField
              hiddenLabel
              id="carga_horaria"
              variant="filled"
              type="number"
              fullWidth
              color="secondary"
              value={workLoad}
              onChange={handleWorkLoad}
              onBlur={handleWorkLoad}
            />
          </ThemeProvider>
        </Grid>
      </Grid>

      {profile.oxetech_work ? (
        <Box
          sx={{
            display: "flex",
            flexFlow: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <FormControlLabel
            label={
              <Typography
                sx={{ color: publicarNoOxetechWorkColor }}
                component="h3"
              >
                {" "}
                {publicarNoOxetechWorkText}{" "}
              </Typography>
            }
            control={
              <Checkbox
                checked={oxetechWork && ciclosAbertos.length > 0}
                disabled={ciclosAbertos.length === 0}
                onChange={() => {
                  setOxetechWork(!oxetechWork);
                }}
                sx={{ margin: "10px 0 10px 10px" }}
                inputProps={{ "aria-label": "controlled" }}
              />
            }
          />
          <PublishButton
            onClick={() => {
              const errors = checkErrors();
              if (!errors && oxetechWork) {
                setOpenModal(true);
              } else {
                handlePublish();
              }
            }}
            startIcon={<SaveIcon />}
          >
            Publicar vaga
          </PublishButton>
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 2,
          }}
        >
          {profile.status_oxetech_work === "Em análise" ? (
            <Typography sx={{ m: "0 1%", width: "60%" }}>
              Sua empresa ainda está <strong>em análise</strong>, portanto você
              ainda não pode cadastrar uma vaga pelo programa OxeTech Work.
            </Typography>
          ) : (
            <Typography sx={{ m: "0 1%", width: "50%" }}>
              Quer publicar uma vaga pelo Oxetech Work?
              <Link
                to="/oxe-tech-work"
                style={{ color: palette.laranjaIntermediario2, margin: "0 1%" }}
              >
                <u>Inscreva-se no programa</u>
              </Link>
            </Typography>
          )}

          <PublishButton
            onClick={() => {
              const errors = checkErrors();
              if (!errors && oxetechWork) {
                setOpenModal(true);
              } else {
                handlePublish();
              }
            }}
            startIcon={<SaveIcon />}
          >
            Publicar vaga
          </PublishButton>
        </Box>
      )}

      {oxetechWork && (
        <ModalOTWork
          openModal={openModal}
          handleSubmit={handleSubmitOTW}
          loading={loading}
          handleModal={() => {
            setOpenModal(false);
          }}
        />
      )}
    </Card>
  );
}

export default CardCadastroVaga;
// export default function CardCadastroVaga() {
//   return (
//     <SnackbarProvider maxSnack={3}>
//       <CardCadastroVagaEmpresa />
//     </SnackbarProvider>
//   );
// }

import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Container';
import { Typography } from '@mui/material';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import background from '../../assets/backgroundSelect.png';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import { Link } from 'react-router-dom';

import { Content } from './styles';

const WhiteBox = styled(Box)`
  height: 100px;
  position: absolute;
  border-radius: 0.5rem;
  color: black;
  overflow: hidden;
  font-size: 20px;
  text-align: center;
  background: rgba(255, 255, 255, 89%);
  padding: 2%;

  @media (min-width: 768px) {
    width: 35rem;
    height: 28rem;
  }

  @media (max-width: 700px) {
    width: 35rem;
    height: 40rem;
  }
  @media (max-width: 360px) {
    width: 33rem;
    height: 45rem;
  }
`;

const ColorButton = styled(Button)(({ theme }) => ({
  color: '#fff',
  backgroundColor: '#741f8a',
  '&:hover': {
    backgroundColor: 'rgba(116, 31, 138, 0.4)',
    color: 'white',
  },
}));

const UseStyles = makeStyles((theme) => ({
  root: {
    overflow: 'hidden',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background:
      'linear-gradient(180deg,rgba(116, 31, 138, 0.9) 35%,rgba(0, 0, 99, 1) 100%)',
    height: '100vh',
  },
  iconCheck: {
    color: 'rgba(116, 31, 138, 0.9)',
    fontSize: '50px',
  },
}));

export default function ForgotPassword() {
  const classes = UseStyles();
  return (
    <React.Fragment>
      <CssBaseline />
      <div className={classes.root}>
        <div sx={{ position: 'absolute', overflow: 'hidden' }}>
          <img
            src={background}
            alt="Background"
            style={{
              height: '100%',
            }}
          />
        </div>
        <WhiteBox>
          <Content>
            <CheckCircleIcon className={classes.iconCheck} fontSize="100px" />
            <Typography
              variant="h4"
              component="div"
              gutterBottom
              sx={{
                mt: 1,
                mb: 2,
                fontSize: '25px',
              }}
            >
              Parabéns!
            </Typography>
            <Typography
              variant="h6"
              component="div"
              gutterBottom
              sx={{
                mb: 2,
                fontSize: '25px',
                fontWeight: 'lighter',
              }}
            >
              Seu cadastro foi finalizado com sucesso.
            </Typography>
            <Typography
              variant="p"
              component="div"
              gutterBottom
              sx={{
                mb: 2,
                fontSize: '15px',
                fontWeight: 'lighter',
              }}
            >
              Você já pode iniciar sua jornada de estudos na plataforma DIO.
            </Typography>
            <Link
              to="/dashboard"
              target="_self"
              rel="noreferrer"
              style={{ marginRight: '10px' }}
            >
              <ColorButton sx={{ mt: 1, p: 1, borderRadius: 1 }}>
                Voltar
              </ColorButton>
            </Link>
            <a
              href="https://bit.ly/cadastro-dio"
              target="_blank"
              rel="noreferrer"
            >
              <ColorButton sx={{ mt: 1, p: 1, borderRadius: 1 }}>
                Plataforma DIO
              </ColorButton>
            </a>
          </Content>
        </WhiteBox>
      </div>
    </React.Fragment>
  );
}

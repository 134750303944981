import { Box } from '@mui/material'
import React from 'react'
import MapChart from '../../components/MapChart/index'
import MapHeader from '../../components/MapHeader'
import MainHeader from '../../components/MainHeader'
import { store } from "../../store";

function InovationMap(props) {
  const { signed} = store.getState().auth;
  return (
    <>
      {!signed && <MainHeader/>}
      <Box>
        <MapChart />
      </Box>
    </>
  )
}
export default InovationMap

import React from 'react';
import { Modal, Typography, Button } from '@material-ui/core';
import DeleteIcon from '@mui/icons-material/Delete';
import api from "../../../../../services/api";
import { makeStyles } from '@material-ui/styles';
const useStyles = makeStyles((theme) => ({
    cancelButton: {
      background: 'rgba(212, 109, 13, 0.7)',
      marginRight: '10px',
      "&:hover": {background:'rgba(212, 109, 13, 0.9)'}
    },
    deleteButton: {
      color: '#ff0000',
      borderColor: '#ff0000',
    },
  }));

const ConfirmationModalDelete = ({ open, onClose, turmaId }) => {
  const handleDelete = () => {
    api
      .delete(`/turma/${turmaId}`)
      .then((response) => {
        console.log(response.data.message); // Exemplo: mostra a mensagem de sucesso no console
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((err) => {
        console.log(err);
        // Coloque aqui o código necessário para exibir a mensagem de erro na interface.
      });
  };
  const classes = useStyles();
  return (
    <Modal
      open={open}
      onClose={onClose}
      style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
    >
      <div style={{ padding: '20px', backgroundColor: 'white' }}>
        <Typography variant="h6" gutterBottom>
        Tem certeza de que deseja excluir esta turma?
        </Typography>
        {/* <Typography variant="body1" gutterBottom>
          Tem certeza de que deseja excluir esta turma?
        </Typography> */}
        <div style={{ marginTop: '20px', textAlign: 'right' }}>
        <Button
            variant="contained"
            color="primary"
            className={classes.cancelButton}
            onClick={onClose}
          >
            Cancelar
          </Button>

          {/* Botão "Excluir" com classe CSS */}
          <Button
            variant="outlined"
            onClick={handleDelete}
            className={classes.deleteButton}
            startIcon={<DeleteIcon />}
          >
            Excluir
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmationModalDelete;

import * as React from "react";
import Box from "@mui/material/Box";

import OxeTechLabTurmas from "./Turmas";
import DashboardTurmaSelecao from "../DashboardTurmaSelecao";
import DashboardTurmaIniciada from "../DashboardTurmaIniciada";
import Mural from "../../components/MuralDaTurma";
import { useLocation, useParams } from "react-router-dom";

function Main({ styles }) {
  const location = useLocation();
  const { labId } = useParams();
  const prefix = `/laboratorio/${labId}`;

  let content = "";
    if (location.pathname === "/oxetechlab-turmas") {
    content = <OxeTechLabTurmas />;
  } else if (location.pathname.startsWith(prefix + "/turma-selecao-painel/")) {
    content = <DashboardTurmaSelecao/>;
  } else if (location.pathname.startsWith(prefix + "/turma-iniciada-painel/")) {
    content = <DashboardTurmaIniciada/>;
  } else if (location.pathname === "/oxetechlab-mural") {
    content = <Mural />;
  } else {
    content = <OxeTechLabTurmas />;
  }

  return (
    <Box
      component="main"
      sx={{ ...styles, backgroundColor: "#FFFFFF", flexGrow: 1, p: 1 }}
    >
      {content}
    </Box>
  );
}

export default Main;

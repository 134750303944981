import styled from 'styled-components'
import * as palette from "../../utils/styledVariables"
export const Container = styled.div``

export const Header = styled.div`
  width: 100vw;
  height: 11vh;
  background: ${palette.gradienteLaranja};

  div {
    max-width: 80vw;
    margin: 0 auto;
    display: flex;
    justify-content: center;

    img {
      margin-top: 13px;
      width: 12vw;
    }
  }

  @media (max-width: 700px) {
    div {
      img {
        margin-top: 18px;
        width: 30vw;
      }
    }
  }
`

export const Content = styled.div`
  /* height: 100vh; */
  background: ${palette.laranjaSecundarioTransp};
  text-align: center;
  color: rgba(0, 0, 0, 0.8);

  h1 {
    padding-top: 10px;
    font-weight: 550;
  }

  div {
    margin: 0 auto;
    max-width: 80vw;
    text-align: justify;
    padding-bottom: 10px;

    p {
      font-weight: 400;
    }
  }
`

export const Footer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 16vh;
  background: rgba(0, 0, 0, 0.9);
  padding-bottom: 30px;
  img {
    width: 9vw;
  }

  p {
    margin-top: 5px;
    margin-bottom: -5px;
    font-size: 1.2rem;
    color: #fff;
  }

  @media (max-width: 700px) {
    img {
      width: 20vw;
    }

    p {
      margin-top: 5px;
      margin-bottom: -5px;
      font-size: 1.2rem;
      color: #fff;
    }
  }
`

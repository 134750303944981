const helperTexts = {
  dataGridToolbarText: {
    // Root
    noRowsLabel: "Sem linhas",
    noResultsOverlayLabel: "Nenhum resultado encontrado.",

    // Density selector toolbar button text
    toolbarDensity: "Densidade",
    toolbarDensityLabel: "Densidade",
    toolbarDensityCompact: "Compacto",
    toolbarDensityStandard: "Padrão",
    toolbarDensityComfortable: "Confortável",

    // Columns selector toolbar button text
    toolbarColumns: "Colunas",
    toolbarColumnsLabel: "Selecionar colunas",

    // Filters toolbar button text
    toolbarFilters: "Filtros",
    toolbarFiltersLabel: "Mostrar filtros",
    toolbarFiltersTooltipHide: "Ocultar filtros",
    toolbarFiltersTooltipShow: "Mostrar filtros",
    toolbarFiltersTooltipActive: (count) =>
      count !== 1 ? `${count} filtros ativos` : `${count} filtro ativo`,

    // Quick filter toolbar field
    toolbarQuickFilterPlaceholder: "Buscar...",
    toolbarQuickFilterLabel: "Buscar",
    toolbarQuickFilterDeleteIconLabel: "Limpar",

    // Export selector toolbar button text
    toolbarExport: "Exportar",
    toolbarExportLabel: "Exportar",
    toolbarExportCSV: "Download como CSV",
    toolbarExportPrint: "Imprimir",
    toolbarExportExcel: "Download como Excel",

    // Columns panel text
    columnsPanelTextFieldLabel: "Encontrar coluna",
    columnsPanelTextFieldPlaceholder: "Título da coluna",
    columnsPanelDragIconLabel: "Reordenar coluna",
    columnsPanelShowAllButton: "Mostrar todas",
    columnsPanelHideAllButton: "Ocultar todas",

    // Filter panel text
    filterPanelAddFilter: "Adicionar filtro",
    filterPanelRemoveAll: "Remover todos",
    filterPanelDeleteIconLabel: "Excluir",
    filterPanelLogicOperator: "Operador lógico",
    filterPanelOperator: "Operador",
    filterPanelOperatorAnd: "E",
    filterPanelOperatorOr: "Ou",
    filterPanelColumns: "Colunas",
    filterPanelInputLabel: "Valor",
    filterPanelInputPlaceholder: "Valor do filtro",

    // Filter operators text
    filterOperatorContains: "contém",
    filterOperatorEquals: "é igual a",
    filterOperatorStartsWith: "começa com",
    filterOperatorEndsWith: "termina com",
    filterOperatorIs: "é",
    filterOperatorNot: "não é",
    filterOperatorAfter: "é depois de",
    filterOperatorOnOrAfter: "é em ou depois de",
    filterOperatorBefore: "é antes de",
    filterOperatorOnOrBefore: "é em ou antes de",
    filterOperatorIsEmpty: "é vazio",
    filterOperatorIsNotEmpty: "não é vazio",
    filterOperatorIsAnyOf: "é qualquer um dos",
    "filterOperator=": "=",
    "filterOperator!=": "!=",
    "filterOperator>": ">",
    "filterOperator>=": ">=",
    "filterOperator<": "<",
    "filterOperator<=": "<=",

    // Header filter operators text
    headerFilterOperatorContains: "Contém",
    headerFilterOperatorEquals: "Igual a",
    headerFilterOperatorStartsWith: "Começa com",
    headerFilterOperatorEndsWith: "Termina com",
    headerFilterOperatorIs: "É",
    headerFilterOperatorNot: "Não é",
    headerFilterOperatorAfter: "É depois de",
    headerFilterOperatorOnOrAfter: "É em ou depois de",
    headerFilterOperatorBefore: "É antes de",
    headerFilterOperatorOnOrBefore: "É em ou antes de",
    headerFilterOperatorIsEmpty: "É vazio",
    headerFilterOperatorIsNotEmpty: "Não é vazio",
    headerFilterOperatorIsAnyOf: "É qualquer um dos",
    "headerFilterOperator=": "Igual a",
    "headerFilterOperator!=": "Diferente de",
    "headerFilterOperator>": "Maior que",
    "headerFilterOperator>=": "Maior ou igual a",
    "headerFilterOperator<": "Menor que",
    "headerFilterOperator<=": "Menor ou igual a",

    // Filter values text
    filterValueAny: "qualquer",
    filterValueTrue: "verdadeiro",
    filterValueFalse: "falso",

    // Column menu text
    columnMenuLabel: "Menu",
    columnMenuShowColumns: "Mostrar colunas",
    columnMenuManageColumns: "Gerenciar colunas",
    columnMenuFilter: "Filtrar",
    columnMenuHideColumn: "Ocultar coluna",
    columnMenuUnsort: "Desfazer ordenação",
    columnMenuSortAsc: "Ordenar em ASC",
    columnMenuSortDesc: "Ordenar em DESC",

    // Column header text
    columnHeaderFiltersTooltipActive: (count) =>
      count !== 1 ? `${count} filtros ativos` : `${count} filtro ativo`,
    columnHeaderFiltersLabel: "Mostrar filtros",
    columnHeaderSortIconLabel: "Ordenar",

    // Rows selected footer text
    footerRowSelected: (count) =>
      count !== 1
        ? `${count.toLocaleString()} linhas selecionadas`
        : `${count.toLocaleString()} linha selecionada`,

    // Total row amount footer text
    footerTotalRows: "Total de Linhas:",

    // Total visible row amount footer text
    footerTotalVisibleRows: (visibleCount, totalCount) =>
      `${visibleCount.toLocaleString()} de ${totalCount.toLocaleString()}`,

    // Checkbox selection text
    checkboxSelectionHeaderName: "Seleção por checkbox",
    checkboxSelectionSelectAllRows: "Selecionar todas as linhas",
    checkboxSelectionUnselectAllRows: "Desmarcar todas as linhas",
    checkboxSelectionSelectRow: "Selecionar linha",
    checkboxSelectionUnselectRow: "Desmarcar linha",

    // Boolean cell text
    booleanCellTrueLabel: "sim",
    booleanCellFalseLabel: "não",

    // Actions cell more text
    actionsCellMore: "mais",

    // Column pinning text
    pinToLeft: "Fixar à esquerda",
    pinToRight: "Fixar à direita",
    unpin: "Desafixar",

    // Tree Data
    treeDataGroupingHeaderName: "Agrupar",
    treeDataExpand: "ver filhos",
    treeDataCollapse: "ocultar filhos",

    // Grouping columns
    groupingColumnHeaderName: "Agrupar",
    groupColumn: (name) => `Agrupar por ${name}`,
    unGroupColumn: (name) => `Parar de agrupar por ${name}`,

    // Master/detail
    detailPanelToggle: "Alternar painel de detalhes",
    expandDetailPanel: "Expandir",
    collapseDetailPanel: "Recolher",

    // Used core components translation keys
    MuiTablePagination: {
      labelRowsPerPage: "Linhas por página:",
      labelDisplayedRows: ({ from, to, count }) => {
        return "" + from + "-" + to + " de " + count;
      },
    },

    // Row reordering text
    rowReorderingHeaderName: "Reordenação de Linhas",

    // Aggregation
    aggregationMenuItemHeader: "Agregação",
    aggregationFunctionLabelSum: "soma",
    aggregationFunctionLabelAvg: "média",
    aggregationFunctionLabelMin: "mínimo",
    aggregationFunctionLabelMax: "máximo",
    aggregationFunctionLabelSize: "tamanho",
  },
};

export default helperTexts;

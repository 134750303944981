import styled from 'styled-components';

export const Container = styled.div`
  padding: 5px 15vw;

  @media (max-width: 700px) {
    padding: 0 10px;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Header = styled.div`
  
  margin-bottom: 15px;

  button { 
    position: absolute; 
    right: 18vw;
  }
  
  @media (max-width: 700px) {
    button { 
      right: 3vw;
    }
  }
  
`;

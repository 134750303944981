import globoOXL from "../../assets/iconesOxeLab/globo.svg";

export const GloboOxlIcon = () => {
  return (
    <img
      src={globoOXL}
      alt="laboratorio perfil"
      width={20}
      style={{ marginLeft: "-4px", }}
    />
  );
};

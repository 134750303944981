import * as palette from "../../utils/styledVariables";
import React, { useEffect, useState } from "react";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import { SnackbarProvider } from "notistack";
import { Box, Button, Tabs, ThemeProvider, createTheme } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import Tab from "@mui/material/Tab";
import ModalCadastroLab from "./ModalCadastroLab";
import ModalEditCadastroLab from "./ModalEditCadastroLab";
import ModalAlunosMatriculados from "./ModalAlunosMatriculados";
import TableCard from "../DashUserAdm/TableCard";
import api from "../../services/api";
import Options from "../../utils/options";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { format } from 'date-fns';

const styles = {
  cadastro: {
    height: "30px",
    margin: "2px",
    float: "right",
    bgcolor: palette.laranjaIntermediario2,
    "&:hover": {
      bgcolor: palette.laranjaSecundario
  },},
  editalItem: {
    display: "flex",
    alignItems: "center",
    minHeight: "50px",
    "&:hover button": {
      display: "inline-block",
    },
  },
  titleContainer: {
    display: "block",
    whiteSpace: "normal",
  },
  editButton: {
    color: palette.laranjaIntermediario2,
    marginLeft: "8px",
    display: "block",
  },
};

function DashboardOXL() {
  const [openModalCadastroLab, setOpenModalCadastroLab] = useState(false);
  const [openModalEditCadastroLab, setOpenModalEditCadastroLab] = useState(false);
  const [editLabId, setEditLabId] = useState(null);
  const [labs, setLabs] = useState([]);
  const [turmas, setTurmas] = useState([]);
  const [chosenTable, setChosenTable] = useState(0);
  const [alertUnauthorized, setAlertUnauthorized] = useState("");
  const [turmaId, setTurmaId] = useState(null);
  const [openModalAlunosMatriculados, setOpenModalAlunosMatriculados] = useState(false);
  const [loading, setLoading] = useState(true)
  const [atualiza, setAtualiza] = useState(false);

  const handleOpenModalAlunosMatriculados = () => {
    setOpenModalAlunosMatriculados(true);
  }
  const handleCloseModalAlunosMatriculados = () => {
    setOpenModalAlunosMatriculados(false);
  }

  const theme = createTheme({
    palette: {
      secondary: {
        main: palette.laranjaIntermediario2, 
      },
     
    }
  })


  useEffect(() => {
    setLoading(true);
    api
      .get("/laboratorio/listar-labs")
      .then((response) => {
        setLabs(response.data);
      })
      .catch((error) => {
        setAlertUnauthorized(error.response.data);
        setLoading(false);
      });
      api
      .get("/turma")
      .then((response) => {
        const turmasData = response.data;
        const promises = turmasData.map(turma => {
          // Para cada turma, faça uma solicitação para obter os alunos aprovados
          return api.get(`/matriculas/aprovados/${turma.id}`)
            .then((response) => {
              // Adicione os alunos aprovados à turma correspondente
              turma.aprovados = response.data;
              setLoading(false);
              return turma;
            })
            .catch((error) => {
              console.error(`Erro ao obter os alunos aprovados da turma ${turma.id}: ${error}`);
              setLoading(false);
              return turma; // Retorna a turma mesmo se houver erro
            });
        });
    
        Promise.all(promises)
          .then(turmasComAprovados => {
            setTurmas(turmasComAprovados); 
            setLoading(false);
          })
          .catch(error => {
            console.error("Erro ao obter alunos aprovados:", error);
            setAlertUnauthorized("Erro ao obter alunos aprovados");
            setLoading(false);
          });
      })
      .catch((error) => {
        console.error("Erro ao obter turmas:", error);
        setAlertUnauthorized(error.response.data);
        setLoading(false);
      });
      setAtualiza(false)
  }, [atualiza]);

  const handleClick = (id) => {
    setEditLabId(id)
    setOpenModalEditCadastroLab(true);
  };

  const handleClickModalAlunosMatriculados = (id) => {
    setTurmaId(id);
    setOpenModalAlunosMatriculados(true);
  }
  const labHeader = [
    { field: "unidade", headerName: "Unidade", width: 150 },
    { field: "municipio", headerName: "Município", width: 150 },
    { field: "cep", headerName: "CEP", width: 150 },
    { field: "coordenador_nome", headerName: "Coordenador", width: 300 },
    {
      field: "editar_lab",
      headerName: "Editar",
      width: 150,
      renderCell: (params) => {
        return (
          <>
            <Button
              sx={{ color: palette.laranjaIntermediario2 }}
              onClick={() => handleClick(labs[params.id].id)}
            >
              <ModeEditIcon />
            </Button>
          </>
        );
      },
    },
  ];
  const labRows = labs?.map((lab, index) => {
    return {
      id: index,
      unidade: lab.nome,
      municipio: lab.municipio,
      cep: lab.cep,
      coordenador_nome: lab.Coordenador.name,
    };
  });

  const turmasRows = turmas?.map((turma) => {
    const partesData = turma.data_inicio.split('-');
  
    const dataFormatada = `${partesData[2]}/${partesData[1]}/${partesData[0]}`;
    return {
      id: turma.id,
      titulo: turma.titulo,
      unidade: turma.Laboratorio.nome,
      municipio: turma.Laboratorio.municipio,
      data_inicio: dataFormatada,
      status: Options.turmasStatuses[turma.status],
      vagas_disponiveis: turma.qtd_vagas_disponiveis,
      vagas_preenchidas: turma.qtd_vagas_preenchidas,
      alunos_aprovados: turma.aprovados.length,
    };
  });
  const turmasHeader = [
    { field: "titulo", headerName: "Título", width: 400 },
    { field: "unidade", headerName: "Unidade", width: 150 },
    { field: "municipio", headerName: "Municipio", width: 150 },
    { field: "data_inicio", headerName: "Data de início", width: 150 },
    { field: "status", headerName: "Status", width: 100 },
    { field: "alunos", headerName: "Alunos matriculados", width: 150,
    renderCell: (params) => {
      return (
        <>
          <Button
            sx={{ color: palette.laranjaIntermediario2 }}
            onClick={() => handleClickModalAlunosMatriculados(params.id)}
          >
           VER
          </Button>
        </>
      );
    },},
    { field: "vagas_disponiveis", headerName: "Vagas disponíveis", width: 150 },
    { field: "vagas_preenchidas", headerName: "Vagas preenchidas", width: 150 },
    { field: "alunos_aprovados", headerName: "Alunos aprovados", width: 150 },
  ];

  const buttonsBar = (
    <div style={{ float: "right" }}>
      <Button
        onClick={() => {
          setOpenModalCadastroLab(true);
        }}
        variant="contained"
        startIcon={<AddIcon />}
        sx={styles.cadastro}
      >
        Cadastrar Laboratório
      </Button>
    </div>
  );

  const tables = [
    {
      name: "Laboratórios",
      columns: labHeader,
      rows: labRows,
      buttonsBar: buttonsBar,
    },
    {
      name: "Turmas",
      columns: turmasHeader,
      rows: turmasRows,
    },
  ];

  return (
    <Box>
       <ThemeProvider theme={theme}>
      <Box>
        <Tabs
          centered
          value={chosenTable}
          onChange={(event, newValue) => setChosenTable(newValue)}
          sx={{ marginBottom: 1 }}
          indicatorColor="secondary"
          textColor="secondary"
        >
          {tables.map((table, index) => (
            <Tab value={index} label={table.name} key={index} />
          ))}
        </Tabs>
      </Box>
      </ThemeProvider>

     <TableCard props={tables[chosenTable]} loading={loading} />

      <SnackbarProvider maxSnack={3}>
        <ModalCadastroLab
          open={openModalCadastroLab}
          isEdit={editLabId}
          close={() => {
            setOpenModalCadastroLab(false);
            setAtualiza(true)
          }}
        />
      </SnackbarProvider>
      <SnackbarProvider maxSnack={3}>
        <ModalEditCadastroLab
          open={openModalEditCadastroLab}
          isEdit={editLabId}
          close={() => {
            setOpenModalEditCadastroLab(false);
            setAtualiza(true)
          }}
        />
      </SnackbarProvider>
      <ModalAlunosMatriculados
      open={openModalAlunosMatriculados}
      close={handleCloseModalAlunosMatriculados}
      turma_id={turmaId}
      />
      <Snackbar
        open={alertUnauthorized.length > 0}
        autoHideDuration={6000}
        onClose={() => setAlertUnauthorized("")}
      >
        <Alert
          variant="filled"
          onClose={() => setAlertUnauthorized("")}
          severity="error"
          sx={{ width: "100%" }}
        >
          {alertUnauthorized}
        </Alert>
      </Snackbar>
    </Box>
  );
}

export default DashboardOXL;

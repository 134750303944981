import React from 'react'

import Box from '@mui/material/Box';

import logo from '../../assets/LOGO_OXETECH_WORK.svg'

import Card from '../../components/VagasOxetechWork'

import Header from '../../components/HeaderOxeTechWork'

import { Container, Logo } from './styles'

function OxeTechWork() {

  return (
      <Container>
          <Header />
          <Box sx={{ p: 2 }}>
              <Logo>
                  <img src={logo} className="img" alt="Oxe-tech_Oxe-Din" />
                  <p className="subtitulo">
                      Candidate-se e faça parte do time de profissionais capacitados!
                  </p>
              </Logo>
              <Card className="card" />
          </Box>

      </Container>
  )
}

export default OxeTechWork
import React, { useEffect, useState } from "react";
import * as palette from "../../../utils/styledVariables";
import {
  Modal,
  Grid,
  Paper,
  Typography,
  Box,
  TextField,
  Button,
  Autocomplete,
  Tooltip,
} from "@mui/material";
import api from "../../../services/api";
import IconButton from "@mui/material/IconButton";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CloseIcon from "@mui/icons-material/Close";
import ModalCadastroAtividade from "../ModalCadastroAtividade";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

import "./style.css";
import { useSnackbar } from "notistack";
import { ThemeProvider, createTheme } from "@mui/material/styles";


export const ModalCadastroTrilha = ({ cursos, open, close }) => {
  const [titulo, setTitulo] = useState(
    sessionStorage.getItem("tituloTrilha") || ""
  );
  const [descricao, setDescricao] = useState(
    sessionStorage.getItem("descricaoTrilha") || ""
  );
  const [cargaHoraria, setCargaHoraria] = useState(
    sessionStorage.getItem("cargaHorariaTrilha") || 0
  );
  const [repeatedFields, setRepeatedFields] = useState([{}]);
  const { enqueueSnackbar } = useSnackbar();

  const styles = {
    modal: {
      width: "50%",
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      bgcolor: "#EDE8EE",
      padding: "1rem",
      maxHeight: "90%",
      overflow: "auto",
      textAlign: "center",
      borderRadius: "1vh",
      justifyContent: "center",
    },
    paper: {
      width: "100%",
      margin: "5px",
      height: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    container: {
      backgroundColor: palette.laranjaOXLTransparente,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      width: "99%",
      height: "90%",
      padding: "15px",
      borderRadius: "1vh",
    },
    field: { textAlign: "left", padding: "8px" },
    button: {
      display: "flex",
      width: "25vh",
      justifyContent: "center",
      alignItems: "center",
      bgcolor: palette.laranjaIntermediario2,
      marginTop: "20px",
      padding: "6px 10px",
    },
  };
  const theme1 = createTheme({
    palette: {
      primary: {
        main: "rgba(212, 109, 13, 1)",
        darker: "rgba(212, 109, 13, 0.5)",
      },
    },
  });

  const [selectedItems, setSelectedItems] = useState(
    JSON.parse(sessionStorage.getItem("cursos")) || [{ titulo: "" }]
  );

  useEffect(() => {
    sessionStorage.setItem("cursos", JSON.stringify(selectedItems));
  }, [selectedItems]);

  const [selectedAtividades, setSelectedAtividades] = useState(
    Array(cursos.length).fill(null)
  );
  const [openModalCadastroAtividade, setOpenModalCadastroAtividade] =
    useState(false);
  const [activityCreated, setActivityCreated] = useState(false);
  const handleCursoChange = async (index, newCurso) => {
    if (selectedItems.some(item => item.id === newCurso.id)) {  
      enqueueSnackbar("Esse curso já foi adicionado.", {
        variant: "error",
      });
      return;
    }
  
    setSelectedItems((prevSelectedItems) => {
      const newSelectedItems = [...prevSelectedItems];
      newSelectedItems[index] = newCurso;  
      return newSelectedItems;
    });
  };
  

  const handleAddAtividade = () => {
    setSelectedItems([...selectedItems, { }]);
    setRepeatedFields([...repeatedFields, {}]);
  };

  const handleClearRepeatedFields = () => {
    if (repeatedFields.length > 1) {
      setRepeatedFields((prevRepeatedFields) =>
        prevRepeatedFields.slice(0, prevRepeatedFields.length - 1)
      );
    }
  };

  const onFinish = async () => {
    let hasValidationError = false;

    if (!titulo) {
      enqueueSnackbar("Por favor, preencha o título", { variant: "error" });
      hasValidationError = true;
    }

    if (!descricao) {
      enqueueSnackbar("Por favor, preencha a descrição", { variant: "error" });
      hasValidationError = true;
    }

    if (cargaHoraria <= 0) {
      enqueueSnackbar("Por favor, insira um valor válido na carga horária.", {
        variant: "error",
      });
      hasValidationError = true;
    }

    for (let i = 0; i < selectedItems.length; i++) {
      if (selectedItems[i].titulo.length <= 0) {
        enqueueSnackbar(`Por favor, preencha o módulo ${i + 1}`, {
          variant: "error",
        });
        hasValidationError = true;
      }
    }

    if (!hasValidationError) {
      const trilhaData = {
        titulo: titulo,
        descricao: descricao,
        modulos: selectedItems.map((curso) => curso.id),
        carga_horaria: cargaHoraria,
      };

      try {
        const response = await api.post(
          "/trilhas-de-conhecimento/",
          trilhaData
        );
        if (response.status === 200) {
          enqueueSnackbar("A trilha foi criada com sucesso.", {
            variant: "success",
          });
          setTimeout(() => {
            close();
          }, 1000);
          sessionStorage.removeItem("tituloTrilha");
          sessionStorage.removeItem("descricaoTrilha");
          sessionStorage.removeItem("cargaHorariaTrilha");
          sessionStorage.removeItem("cursos");
        } else {
          enqueueSnackbar("Ocorreu um erro ao tentar criar a trilha.", {
            variant: "error",
          });
        }
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.error
        ) {
        } else {
          enqueueSnackbar("Ocorreu um erro ao tentar criar a trilha.", {
            variant: "error",
          });
        }
      } finally {
      }
    }
  };

  const handleDeleteModulo = (index) => {
    const updatedModulos = [...selectedItems];

    updatedModulos.splice(index, 1);

    setSelectedItems(updatedModulos);
  };

  const handleAddModulo = () => {
    setSelectedItems([...selectedItems, { titulo: "" }]);
  };
  

  return (
    <ThemeProvider theme={theme1}>
      <Modal open={open} sx={{ overflow: "auto" }}>
        <Box>
          <IconButton
            size="large"
            sx={{ display: "flex", float: "right" }}
            component="label"
            onClick={() => {
              handleClearRepeatedFields();
              close();
            }}
          >
            <CloseIcon
              fontSize="large"
              sx={{ color: palette.profileWhiteShade }}
            />
          </IconButton>
          <Grid sx={styles.modal} container direction="row">
            <Grid container item xs={12} sm={12} md={12} lg={12}>
              <Paper
                sx={[
                  {
                    backgroundColor: palette.laranjaOXLTransparente,
                    height: "50px",
                  },
                  styles.paper,
                ]}
              >
                <Typography
                  sx={{
                    fontSize: "24px",
                    color: palette.preto,
                    fontFamily: "Poppins",
                    fontWeight: 500,
                  }}
                >
                  Cadastrar Trilha
                </Typography>
              </Paper>
            </Grid>
            <Grid sx={styles.container}>
              <Grid container item xs={12} sm={12} md={12} lg={12}>
                <Grid container spacing={2} sx={styles.field}>
                  <Grid item xs={12} sm={8} md={8} lg={8}>
                    <TextField
                      label="Título"
                      variant="outlined"
                      fullWidth
                      value={titulo}
                      color="primary"
                      onChange={(e) => {
                        setTitulo(e.target.value);
                        sessionStorage.setItem("tituloTrilha", titulo);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} md={4} lg={4}>
                    <TextField
                      label="Carga Horária"
                      variant="outlined"
                      type="number"
                      fullWidth
                      value={cargaHoraria}
                      color="primary"
                      onChange={(e) => {
                        const { value } = e.target;
                        if (value > 0) {
                          setCargaHoraria(value);
                          sessionStorage.setItem(
                            "cargaHorariaTrilha",
                            cargaHoraria
                          );
                        }
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} sx={styles.field}>
                  <TextField
                    label="Descrição"
                    variant="outlined"
                    fullWidth
                    color="primary"
                    multiline
                    rows={4}
                    value={descricao}
                    onChange={(e) => {
                      const inputText = e.target.value.slice(0, 250);
                      setDescricao(inputText);
                      sessionStorage.setItem("descricaoTrilha", descricao);
                    }}
                    helperText={`${descricao.length}/250 caracteres`}
                  />
                </Grid>
                {/*
                <Grid item xs={12} sm={12} md={12} lg={12} sx={styles.field}>
                </Grid>

                */}

                {selectedItems.map((_, index) => (
                  <React.Fragment key={index}>
                    <Grid container spacing={2} alignItems="center">
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        sx={{ ...styles.field, marginLeft: "8px" }}
                      >
                        <h3>Módulo {index + 1}</h3>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            width: "80%",
                          }}
                        >
                          <Autocomplete
                            disableClearable
                            value={selectedItems[index] || ""}
                            onChange={(e, newValue) =>
                              handleCursoChange(index, newValue)
                            }
                            sx={{ width: "80%" }}
                            options={cursos.filter(curso => !selectedItems.some((item, idx) => item.titulo === curso.titulo && idx !== index))}
                            getOptionLabel={(curso) => curso.titulo}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Curso"
                                variant="outlined"
                                color="primary"
                              />
                            )}
                          />
                          <Tooltip
                            title="Excluir"
                            style={{
                              display: index !== 0 ? "flex" : "none",
                              cursor: "pointer",
                              marginLeft: "5px",
                            }}
                            onClick={() => handleDeleteModulo(index)}
                          >
                            <DeleteForeverIcon color="primary" />
                          </Tooltip>
                          <Tooltip
                          title="Adicionar novo módulo"
                          sx={{
                            display: index === selectedItems.length - 1 ? "flex" : "none",
                            cursor: "pointer",
                            marginLeft: "5px",

                          }}
                          onClick={handleAddModulo}
                        >
                            <AddCircleIcon style={{ color: "rgba(212, 109, 13, 1)" }} />
                        </Tooltip>

                        </div>
                      </Grid>
                    </Grid>
                    
                  </React.Fragment>
                ))}
                
              </Grid>
              <Button
                onClick={onFinish}
                variant="contained"
                sx={{
                  height: "40px",
                  bgcolor: palette.laranjaIntermediario2,
                  "&:hover": {
                    bgcolor: palette.laranjaSecundario,
                  },
                  marginTop: "50px",
                  marginBottom: "10px",
                  borderRadius: "10px",
                }}
              >
                Cadastrar Trilha
              </Button>
            </Grid>
          </Grid>

          <div>
            <ModalCadastroAtividade
              onOpen={openModalCadastroAtividade}
              onClose={() => {
                setOpenModalCadastroAtividade(false);
              }}
              onActivityCreated={() => setActivityCreated(true)}
            />
          </div>
        </Box>
      </Modal>
    </ThemeProvider>
  );
};

export default ModalCadastroTrilha;

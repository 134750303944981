import React, { useEffect, useMemo, useState, useRef } from "react";
import formatDate from "../../../../utils/formartDate";
import {
  Alert,
  Button,
  CardContent,
  CardMedia,
  Snackbar,
  Tooltip,
  Typography,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import {
  ContainerGrid,
  GridCard,
  LinhaHorizontal,
  ModalCard,
  ModalConfirm,
  NoVagas,
  OxeCard,
  Search,
  Status,
  Submit,
} from "./styles";
import { useParams,Link } from "react-router-dom";
// import MeuCertificado from "../Certificado";
import { useSelector } from "react-redux";
import api from "../../../../services/api"
import history from "../../../../services/history"
import logo from "../../../assets/logo.svg";
import FiltrarTurmas from "./FiltrarTurmas";
import VisibilityIcon from "@mui/icons-material/Visibility";
import homem from "../../../../assets/iconesOxeLab/homem.svg";
import local from "../../../../assets/iconesOxeLab/local.svg";
import noFolder from "../../../../assets/iconesOxeLab/noFolder.svg";
import noResults from "../../../../assets/iconesOxeLab/noResults.svg";
import search from "../../../../assets/iconesOxeLab/search.svg";
import capa from "../../../../assets/iconesOxeLab/capa.svg";
import date from "../../../assets/date.svg";
import hour from "../../../assets/hour.svg"
import { isAfter } from 'date-fns'
function TurmaOxetechEdusInscritas() {
  const signed = useSelector((state) => state.auth.signed);
  const profile = useSelector((state) => state.user.profile)
  const [turmas, setTurmas] = useState([]);
  const [open, setOpen] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [error, setError] = useState(false);
  const [alertExists, setAlertExists] = useState(false);
  const [alertSuccessful, setAlertSuccessful] = useState(false);
  const [alertUnauthorized, setAlertUnauthorized] = useState(false);
  const [busca, setBusca] = useState("");
  const [id, setId] = useState(-1);
  const [loading, setLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const [school, setSchool] = useState([]);
  const [matricula, setMatricula] = useState([]);

  const handleOptionSelect = (option) => {
    setSelectedOption(option);
  };
  const today = new Date();

  useEffect(() => {
    // Primeira chamada da API para obter a escola
    api
      .get(`/oxetechedu/escola/${profile.id}`)
      .then((response) => {
        setSchool(response.data[0].Escola);
          api
            .get(`/oxetechedu/matricula/turmas/${response.data[0].Escola.id}`)
            .then((responseMatricula) => {
              setTurmas(responseMatricula.data);
            })
            .catch((err) => {
              console.log(err);
            });
      })
      .catch((err) => {
        console.log(err);
      });
  }, [selectedOption]);
  


  
  const handleDetails = (i) => {
    setOpen(true);
    setId(i);
  };

  const handleClose = () => {
    setAlertSuccessful(false);
    setAlertExists(false);
    setAlertUnauthorized(false);
    setError(false);
    setOpen(false);
    setConfirm(false);
  };

  
  const handleCancelar = (e, i) => {
    setOpen(false);
    setConfirm(true);
    setId(i);
    e.stopPropagation();
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const response = await api.get(`/oxetechedu/matricula/${profile.id}/${school.id}`);
      const matricula = response.data;
      setMatricula(matricula);
      await api.delete(`/oxetechedu/inscricoes/por-turma/${turmas[id].TurmaOxetechEdu.id}`, { data: { matriculaId: matricula.id } });
  
      setAlertSuccessful('Inscrição cancelada!');
      setTimeout(() => {
        setLoading(false);
        setConfirm(false);
        window.location.reload();
      }, 700);
    } catch (err) {
      console.error(err);
    }
  };
  
  
  const Cancelar = () => {
    return (
      <ModalConfirm
        open={confirm}
        closable={false}
        centered
        onCancel={handleClose}
        footer={null}
      >
        <img src={logo} className="img" alt="OxetechLab" width="40%" />
        <h3>Tem certeza que deseja cancelar sua inscrição?</h3>
        <div className="butoes">
          <Button
            startIcon={
              loading ? <CircularProgress color="inherit" size={20} /> : null
            }
            onClick={handleSubmit}
            className="butao"
          >
            Sim
          </Button>
          <Button
            onClick={handleClose}
            variant="outlined"
            className="butaoCancelar"
          >
            Cancelar
          </Button>
        </div>
      </ModalConfirm>
    );
  };

  const TurmaOxetechEdusSearch = useMemo(() => {
    const lowerSearch = busca.toLowerCase();
    const busca1 = (turma) =>
      turma.TurmaOxetechEdu.titulo.toLowerCase().includes(lowerSearch);

    if (Array.isArray(turmas)) {
        const titulo = turmas.filter(busca1);
        return titulo;
      } else {
        return [];
      }
  }, [busca, turmas]);

  const botoesStatusInscrito = [
    {
      background:
        "linear-gradient(162.93deg, #581780 -10.26%, rgba(213, 109, 13, 0.83) 276.84%)",
      title: "Não selecionado(a)",
      tooltip:
        "Infelizmente você não foi selecionado. Mas você pode tentar novamente em uma próxima TurmaOxetechEdu.",
    },
    {
      background:
        "linear-gradient(162.93deg, #6B85C2 -10.26%, rgba(213, 109, 13, 0.83) 276.84%)",
      title: "Em análise",
      tooltip:
        "Sua inscrição está em análise. Logo você receberá um e-mail do resultado da seleção, aguarde!",
    },
    {
      background:
        "linear-gradient(162.93deg, #6B85C2 -10.26%, rgba(213, 109, 13, 0.83) 276.84%)",
      title: "Em análise",
      tooltip:
        "Sua inscrição está em análise. Logo você receberá um e-mail do resultado da seleção, aguarde!",
    },
    {
      background:
        "linear-gradient(162.93deg, rgba(34, 223, 178, 0.79) -10.26%, rgba(213, 109, 13, 0.83) 276.84%)",
      title: "Selecionado(a)",
      tooltip:
        "Você foi selecionado para participar dessa TurmaOxetechEdu! Atente-se às informações da TurmaOxetechEdu e aproveite.",
    },
  ];

  const botoesStatusTurmaOxetechEdu = [
    {
      background:
        "linear-gradient(162.93deg, #c62828 -10.26%, rgba(213, 109, 13, 0.83) 276.84%)",
      title: "Encerrada",
    },
    {
      background:
        "linear-gradient(162.93deg, #6B85C2 -10.26%, rgba(213, 109, 13, 0.83) 276.84%)",
      title: "Em Seleção",
    },
    {
      background:
        "linear-gradient(162.93deg, rgba(34, 223, 178, 0.79) -10.26%, rgba(213, 109, 13, 0.83) 276.84%)",
      title: "Iniciada",
    },
  ];
  const botoesStatusMatriculado = [
    {
      background:
        "linear-gradient(162.93deg, #581780 -10.26%, rgba(213, 109, 13, 0.83) 276.84%)",
      title: "Reprovado(a)",
      tooltip: "Infelizmente você foi reprovado nesta TurmaOxetechEdu.",
    },
    {
      background:
        "linear-gradient(162.93deg, #6B85C2 -10.26%, rgba(213, 109, 13, 0.83) 276.84%)",
      title: "Matriculado(a)",
      tooltip:
        "Você foi selecionado para participar dessa TurmaOxetechEdu! Atente-se às informações da TurmaOxetechEdu e aproveite.",
    },
    {
      background:
        "linear-gradient(162.93deg, rgba(34, 223, 178, 0.79) -10.26%, rgba(213, 109, 13, 0.83) 276.84%)",
      title: "Aprovado(a)",
      tooltip: "Você foi aprovado nesta TurmaOxetechEdu! Baixe já o seu certificado",
    },
  ];
 
  function podeCancelarInscricao(date1, date2) {
    const day1 = date1.getDate();
    const month1 = date1.getMonth();
    const year1 = date1.getFullYear();

    const day2 = date2.getDate();
    const month2 = date2.getMonth();
    const year2 = date2.getFullYear();

    if (year1 === year2 && month1 === month2 && day1 === day2) {
      return 0; 
    } else if (
      year1 < year2 ||
      (year1 === year2 && month1 < month2) ||
      (year1 === year2 && month1 === month2 && day1 < day2)
    ) {
      return -1; 
    } else {
      return 1; 
    }
  }
  const CardExpandido = () => {
    if (id !== -1) {
      return (
        <>
          <ModalCard
            open={open}
            onCancel={handleClose}
            width={800}
            footer={null}
            closable={false}
          >
            <CardMedia
              component="img"
              alt="Capa Oxetech Lab"
              height="100"
              image={capa}
            />
            {/* {StatusTurmaOxetechEduButton(TurmaOxetechEdusSearch[id].TurmaOxetechEdu.em_selecao)} */}
            <CardContent sx={{ p: " 5% 0 " }} className="header">
           
              <div style={{ width: "60%" }}>
              <div>
              <img src={logo} alt="logoOxetechEdu" className="logo" style={{ width: '100px', marginLeft: '4px', height: 'auto' }} />
            </div>

                <Typography
                  className="title"
                  gutterBottom
                  variant="h5"
                  component="div"
                >
                  {TurmaOxetechEdusSearch[id]?.TurmaOxetechEdu.titulo}
                </Typography>
                <LinhaHorizontal />
                <Typography
                  className="subtitle"
                  sx={{ fontSize: "1.5em" }}
                  gutterBottom
                  variant="h5"
                  component="div"
                >
                </Typography>
                <div style={{ marginTop: "5%" }}></div>
              
                <Typography
                  className="subtitle"
                  sx={{ fontSize: "1.2em" }}
                  gutterBottom
                  variant="h5"
                  component="div"
                >
                  <strong>Dia da Aula:</strong>{" "}
                  {TurmaOxetechEdusSearch[id].TurmaOxetechEdu.dia_da_aula.slice(8, 10) +
                    "/" +
                    TurmaOxetechEdusSearch[id].TurmaOxetechEdu.dia_da_aula.slice(5, 7) +
                    "/" +
                    TurmaOxetechEdusSearch[id].TurmaOxetechEdu.dia_da_aula.slice(0, 4)}
                </Typography>

                <Typography
                  className="subtitle"
                  sx={{ fontSize: "1.2em" }}
                  gutterBottom
                  variant="h5"
                  component="div"
                >
                  <strong>Carga Horária:</strong>{" "}
                  {TurmaOxetechEdusSearch[id].TurmaOxetechEdu.carga_horaria}h
                </Typography>

                <Typography
                  className="subtitle"
                  sx={{ fontSize: "1.2em" }}
                  gutterBottom
                  variant="h5"
                  component="div"
                >
                  <strong>Horário da Turma:</strong>{" "}
                  {TurmaOxetechEdusSearch[id].TurmaOxetechEdu.hora_inicio +
                    "h" + " às " + TurmaOxetechEdusSearch[id].TurmaOxetechEdu.hora_fim +
                    "h" }
                </Typography>
              </div>
              <img className="img" src={homem} alt="Icone do OxetechLab" />
            </CardContent>
            <CardContent sx={{ p: " 2% 4% " }} className="section">
              <Typography variant="h5" component="div" className="sectionName">
                Descrição da turma
              </Typography>
              <Typography variant="body1" component="div" className="text">
                {TurmaOxetechEdusSearch[id]?.TurmaOxetechEdu.descricao}
              </Typography>
              
              <Typography
                variant="h5"
                component="div"
                className="sectionName"
                sx={{ width: "15%", textAlign: "center" }}
              >
                Local
              </Typography>
              <Typography variant="body1" component="div" className="text">
                {school.rua},{" "}
                {school.bairro},{" "}
                {school.numero},{" "}
                {school.complemento !== ""
                  ? school.complemento + ", "
                  : ""}
                {school.cep} - Alagoas
              </Typography>
            </CardContent>

            <Submit>
              <div className="lastinfo">
                <h5 className="encerramento">
                  {" "}
                  {"Inscrições até " +
                    TurmaOxetechEdusSearch[id].TurmaOxetechEdu.encerramento_inscricoes.slice(8, 10) +
                    "/" +
                    TurmaOxetechEdusSearch[id].TurmaOxetechEdu.encerramento_inscricoes.slice(5, 7) +
                    "/" +
                    TurmaOxetechEdusSearch[id].TurmaOxetechEdu.encerramento_inscricoes.slice(0, 4)}{" "}
                </h5>
              </div>
            </Submit>
          </ModalCard>
        </>
      );
    } else {
      return <></>;
    }
  };

  return (
    <div style={{ width: "90vw", maxWidth: "2000px" }}>
      {/* <Search>
        <img src={search} width="3%" alt="Busca" />
        <input
          className="input"
          placeholder="Pesquise o nome da turma..."
          type="text"
          value={busca}
          onChange={(e) => setBusca(e.target.value)}
        />
      </Search> */}
      {/* <FiltrarTurmas onOptionSelect={handleOptionSelect} /> */}
      {TurmaOxetechEdusSearch.length !== 0 ? (
        <GridCard container spacing={{ xs: 1, md: 3, lg: 3 }}>
          {TurmaOxetechEdusSearch.map((item, i) => 
          {
            return (
              <ContainerGrid item xl={4} lg={4} md={6} sm={12} xs={12} key={i}>
                <OxeCard>
                  <CardMedia
                    component="img"
                    alt="Capa Oxetech Lab"
                    height="100"
                    image={capa}
                  />
                  <div style={{ display: "flex", justifyContent: "flex-end"}}>
                    {/* {item.em_selecao
                      ? StatusIncritoButton(item.em_selecao)
                      : StatusMatriculadoButton(
                          item.em_selecao)
                        } */}
                    <Tooltip title="Ver detalhes da TurmaOxetechEdu">
                      <VisibilityIcon
                        sx={{ mr: "5%", cursor: "pointer" }}
                        onClick={() => handleDetails(i)}
                        color="disabled"
                      />
                    </Tooltip>
                  </div>

                  <CardContent sx={{ p: "0 3%" }}>
                    <Typography
                      variant="h5"
                      component="div"
                      className="title"
                      sx={{
                        fontSize:
                          item.TurmaOxetechEdu.titulo.length > 20 ? "1.3em" : "1.6em",
                      }}
                    >
                      {item.TurmaOxetechEdu.titulo}
                    </Typography>
                    <LinhaHorizontal />
                    <div className="info">
                      <Typography
                        className="subtitle"
                        gutterBottom
                        variant="h5"
                        component="div"
                      >
                       
                      </Typography>
                      <div style={{ display: "flex", alignItems: "flex-start" }}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <img src={hour} alt="Hora" />
                      <Typography
                        className="subtitle"
                        sx={{ pl: "5px", color: "#000", fontWeight: 700 }}
                        variant="h5"
                        component="div"
                      >
                        {item.TurmaOxetechEdu.hora_inicio + "h" + " às " + item.TurmaOxetechEdu.hora_fim + "h"}
                      </Typography>
                    </div>
                    <div style={{ width: "10px" }}></div>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <img src={date} alt="Data" />
                      <Typography
                        className="subtitle"
                        sx={{ pl: "5px", color: "#000", fontWeight: 700 }}
                        variant="h5"
                        component="div"
                      >
                        {item.TurmaOxetechEdu.dia_da_aula.slice(8, 10) + "/" + item.TurmaOxetechEdu.dia_da_aula.slice(5, 7) + "/" + item.TurmaOxetechEdu.dia_da_aula.slice(0, 4)}
                      </Typography>
                    </div>
                  </div>
                  <div style={{ width: "10px" }}></div>
                      <div style={{ display: "flex", justifyContent: "flex-end"}}>
                        <img src={local} alt="Local" />
                        <Typography
                          className="subtitle"
                          sx={{ pl: "5px", color: "#000", fontWeight: 700 }}
                          variant="h5"
                          component="div"
                        >
                          {school.nome}
                        </Typography>
                      </div>
                    </div>
                    <Typography
                      className="noWrap"
                      variant="body2"
                      sx={{ minHeight: "60px", maxHeight: "60px" }}
                    >
                      {item.TurmaOxetechEdu.descricao}
                    </Typography>
                  </CardContent>
                  <Submit>
                    <Button
                      onClick={(e) => handleCancelar(e, i)}
                      sx={{
                        display:
                          today >
                          new Date(item.TurmaOxetechEdu.encerramento_inscricoes)
                            ? "none"
                            : "flex",
                      }}
                      className="butao"
                    >
                      Cancelar inscrição
                    </Button>
                  </Submit>
                </OxeCard>
              </ContainerGrid>
            );
          })}
          
          <Cancelar />
          <CardExpandido />

          <Snackbar
            open={alertSuccessful.length > 0}
            autoHideDuration={6000}
            onClose={() => setAlertSuccessful("")}
          >
            <Alert
              variant="filled"
              onClose={() => setAlertSuccessful("")}
              severity="success"
              sx={{ width: "100%" }}
            >
              {alertSuccessful}
            </Alert>
          </Snackbar>
        </GridCard>
      ) : (
        <NoVagas>
          <img src={noResults} width="8%" alt="Nenhuma TurmaOxetechEdu encontrada" />
          <h1 style={{ marginTop: "2%" }}>
            Nenhuma turma encontrada
          </h1>
        </NoVagas>
      )}
    </div>
  );
 
}

export default TurmaOxetechEdusInscritas;

import React from 'react'
import { Link } from 'react-router-dom'
import {
  Container,
  SectionOne,
  LogoOxeDin,
  LogoOxeTechLab,
  LogoOxeTechWork,
  LogoOxeTechHat,
  TriangleFrame,
  Text
} from './styles'
import MainHeader from '../../components/MainHeader'
import triangleFrame from '../../assets/homeTriangle/triangleFrameG.png'
import oxeTechHat from '../../assets/homeTriangle/oxeTechHat.png'
import oxeDinLogo from '../../assets/homeTriangle/oxeDinLogo.png'
import oxeTechLabLogo from '../../assets/homeTriangle/oxeTechLabLogo.png'
import oxeTechWorkLogoON from '../../assets/homeTriangle/oxeTechWorkLogoON.png'
import Typography from '@mui/material/Typography'

function Main(Props) {
  return (
    <>
      <MainHeader />
      <Container>
        <SectionOne>
          <TriangleFrame src={triangleFrame} layoutId="triangleFrame" />
          <Link
            to="/dash-oxe-tech"
            style={{
              marginLeft: '100vw',
              marginTop: '19vh',
              position: 'absolute'
            }}
          >
            <LogoOxeTechHat src={oxeTechHat} layoutId="oxeTech" />
          </Link>

          <Link
            to="/dash-oxe-din"
            style={{
              marginLeft: '160vw',
              marginTop: '130vh',
              position: 'absolute'
            }}
          >
            <LogoOxeDin src={oxeDinLogo} layoutId="oxeDin" />
          </Link>
          <Link
            to="/dash-oxe-tech-lab"
            style={{
              marginLeft: '90vw',
              marginTop: '-50vh',
              position: 'absolute'
            }}
          >
            <LogoOxeTechLab src={oxeTechLabLogo} layoutId="oxeTechLab" />
          </Link>
          <Text
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.2, duration: 0.2 }}
          >
            <Link to="/" className="logo">
              <LogoOxeTechWork src={oxeTechWorkLogoON} layoutId="oxeTechWork" />
            </Link>
            <Typography
              className="typ"
              variant="body1"
              gutterBottom
              sx={{ textAlign: 'justify', fontWeight: '300' }}
            >
              O OxeTech Work nasce com o propósito de fomentar a economia
              alagoana, através de um programa que integre - de maneira direta -
              a capacitação de alunos com vagas de trabalho no mercado
              tecnológico e inovador, transferindo renda, gerando empregos e
              qualificando a população. O diferencial do OxeTech Work:
            </Typography>
            <Typography
              className="typ"
              variant="body1"
              gutterBottom
              sx={{ textAlign: 'justify', fontWeight: '300' }}
            >
              <ul>
                <li>Portfólio com cursos mais específicos;</li>
                <li>
                  Qualificação intensiva com Universidades, ICTs e empresas
                  locais;
                </li>
                <li>
                  Bolsa de estudos de 6 meses + contrato de trabalho de 12
                  meses.
                </li>
              </ul>
              <br />{' '}
            </Typography>
            {/* <Link to="/dash-oxe-tech-lab">
              <Tooltip className="tool" title="OxeTechLab" placement="bottom">
                <a>Próximo</a>
              </Tooltip>
            </Link> */}
          </Text>
        </SectionOne>
      </Container>
    </>
  )
}

export default Main

import * as React from 'react'
import { Box, Typography, Grid, Container } from '@mui/material'
import { makeStyles } from '@mui/styles'
import MainHeader from '../../components/MainHeader'
import logoGovernoAlagoas from '../../assets/Marca-Governo-de-Alagoas-Atualizada.png'
import logoLaCCAN from '../../assets/laccan.png'
import logoFapeal from '../../assets/fapeal_white.png'
import logoUfal from '../../assets/Ufal_white.png'
const useStyles = makeStyles(theme => ({
  root: {
    backgroundSize: 'cover',
    overflow: 'auto',
    background:
      'linear-gradient(180deg,rgba(116, 31, 138, 0.9) 35%,rgba(0, 0, 99, 1) 100%)',
    height: '100vh'
  }
}))
export default function Sobre() {
  const classes = useStyles()
  return (
    <>
      <MainHeader />
      <div className={classes.root}>
        <Container maxWidth="lg" sx={{ mt: 15 }}>
          <Typography
            component="h1"
            textAlign="center"
            variant="h4"
            fontWeight="300"
            align="center"
            color="white"
            gutterBottom
          >
            A Plataforma OxeTech foi desenvolvida através de uma parceria entre
            o governo do estado de Alagoas, por intermédio da Secretaria
            Estadual da Ciência, da Tecnologia e da Inovação, e a Universidade
            Federal de Alagoas, interposto pelo Laboratório de Computação
            Científica e Análise Numérica (LaCCAN).
          </Typography>
          <Box display="flex" justifyContent="center">
            <Grid container spacing={2} sx={{ mt: 2, ml: 9 }}>
              <Grid item lg={3} md={4} sm={6} xs={12}>
                <a
                  href="http://www.cienciaetecnologia.al.gov.br/ "
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    width={200}
                    src={logoGovernoAlagoas}
                    alt="Logo da Secretaria do Governo de Alagoas"
                  />
                </a>
              </Grid>
              <Grid item lg={3} md={4} sm={6} xs={12}>
                <a
                  href="https://sites.google.com/laccan.ufal.br/laccan"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img width={200} src={logoLaCCAN} alt="Logo do Laccan" />
                </a>
              </Grid>
              <Grid item lg={3} md={4} sm={6} xs={12}>
                <a
                  href="https://www.fapeal.br/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img width={200} src={logoFapeal} alt="Logo da Fapeal" />
                </a>
              </Grid>
              <Grid item lg={3} md={4} sm={6} xs={12}>
                <a href="https://ufal.br/" target="_blank" rel="noreferrer">
                  <img
                    width={125}
                    p={0}
                    lg={3}
                    md={4}
                    sm={6}
                    xs={12}
                    src={logoUfal}
                    alt="Logo da Ufal"
                  />
                </a>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </div>
    </>
  )
}

import React, {useState, useEffect} from "react";
import { useSelector } from "react-redux";
import SidebarAdmin from "../../../components/SidebarAdmin/index"

import { Box, CssBaseline } from "@mui/material";

import DashboardOXD from "../../components/DashboardOxd";

const headerHeight = "8vh";

function DashOXD() {
  const profile = useSelector(state => state.user.profile)
  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const onMobile = width <= 600;
  const [sidebarCollapsed, setSidebarCollapsed] = useState(false);

  return (
    <Box sx={{ display: "flex" ,flexGrow: 1}}>
      <CssBaseline />
      <SidebarAdmin
        onMobile={onMobile}
        collapsed={sidebarCollapsed}
        setCollapsed={setSidebarCollapsed}
      />
      <Box>
      <Box sx={{ display: "flex",backgroundColor: "#FFFFF", flexGrow: 1 }}>
      <Box
            component="main"
            sx={{
              backgroundColor: "#FFFFFF",
              flexGrow: 1,
              p: 1,
              width: { xs: `calc(100% - 20vw)`, md: '80vw' },
              marginLeft: onMobile || sidebarCollapsed ? "150px" : "280px",
            }}
          >
            <DashboardOXD />
      </Box>
      </Box>
      </Box>
    </Box>
  );
}

export default DashOXD;
import { React } from "react";
import {
  Page,
  Text,
  View,
  Document,
  Font,
  Line,
  Svg,
  Image,
  StyleSheet
} from "@react-pdf/renderer";
import * as palette from "../../utils/styledVariables";
import Levels from "../../utils/levels";
import poppinsBold from "../../assets/fonts/Poppins-Bold.ttf";
import karlaBold from "../../assets/fonts/karla/Karla-Bold.ttf";
import karlaItalic from "../../assets/fonts/karla/Karla-Italic.ttf";
import karlaRegular from "../../assets/fonts/karla/Karla-Regular.ttf";
import logoOxetech from "../../assets/oxetechRoxo.png";
import logoSecti from "../../assets/BRASAO_SECTI_PNG-02.png";
import logoOxedin from "../../assets/logo_oxeDIN 3.png";
import logoGithub from "../../assets/icons8-github-90.png";
import logoLinkedIn from "../../assets/icons8-linkedin-100.png";
// Register font
Font.register({ family: "PoppinsBold", src: poppinsBold });
Font.register({ family: "KarlaBold", src: karlaBold });
Font.register({ family: "KarlaRegular", src: karlaRegular });
Font.register({ family: "KarlaItalic", src: karlaItalic });

const styles = StyleSheet.create({
  page: {
    padding: "15mm 15mm 5mm 15mm",
    backgroundColor: palette.profileWhiteShade,
  },
  text: {
    textAlign: "justify",
    fontSize: "9",
    marginBottom: "5mm",
    color: palette.preto,
  },
  capitalizedText: {
    textAlign: "justify",
    fontSize: "9",
    color: palette.preto,
    textTransform: "capitalize",
  },
  subtitle: {
    marginBottom: "5mm",
    textAlign: "left",
    fontFamily: "KarlaBold",
    fontSize: "11",
    textTransform: "uppercase",
    color: palette.roxoEscuro,
  },
  name: {
    display: "block",
    fontFamily: "PoppinsBold",
    marginTop: 1,
    marginBottom: 1,
    marginLeft: 0,
    marginRight: 0,
    color: palette.roxoEscuro,
    textTransform: "capitalize",
  },
  row: {
    flex: 1,
    flexDirection: "row",
    flexGrow: 1,
    justifyContent: "center",
  },
  left: {
    width: "65%",
    paddingRight: "8mm",
  },

  right: {
    width: "35%",
    paddingLeft: "8mm",
  },

  img: {
    width: "auto",
    objectFit: "contain",
    margin: 0,
    padding: 0,
    maxWidth: "52.5pt",
  },
  
  header: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: 'center'
  },
  
  headerLogosBox: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },

  headerLogosDimensions: {
    width: 11,
    height: 11,
  },

  headerLink: {
    textAlign: "justify",
    fontSize: 9,
    color: palette.preto,
    paddingLeft: 2
  }

});

const months = [
  "janeiro",
  "fevereiro",
  "março",
  "abril",
  "maio",
  "junho",
  "julho",
  "agosto",
  "setembro",
  "outubro",
  "novembro",
  "dezembro",
];
// Template para experiencia e formação
const itemTemplate01 = ({ title, detail, startDate, endDate }) => {
  const start = new Date(startDate);
  const end = new Date(endDate);

  return (
    <Text
      style={[
        styles.text,
        { marginBottom: "5mm", textTransform: "capitalize" },
      ]}
    >
      <Text
        style={{
          fontWeight: "bold",
          fontSize: 11,
          fontFamily: "KarlaBold",
          lineHeight: "1.5",
        }}
      >
        {title}
      </Text>
      {", "}
      <Text
        style={{ fontSize: 11, fontFamily: "KarlaRegular", lineHeight: "1.5" }}
      >
        {`${detail}\n`}
      </Text>

      <Text
        style={{ fontSize: 8, lineHeight: "1.8", textTransform: "uppercase" }}
      >
        {endDate != null
          ? `${months[start.getMonth()]} DE ${start.getFullYear()} – ${
              months[end.getMonth()]
            } DE ${end.getFullYear()}\n`
          : `${
              months[start.getMonth()]
            } DE ${start.getFullYear()} – Em andamento`}
      </Text>
    </Text>
  );
};

// Template para habilidades e interesses
const itemTemplate03 = (name, level) => {
  return (
    <Text style={[styles.capitalizedText, { marginBottom: "5mm" }]}>
      <Text
        style={{ fontSize: 11, fontFamily: "KarlaBold", lineHeight: "1.5" }}
      >
        {name}
      </Text>
      {"\n"}
      <Text style={styles.capitalizedText}>{level}</Text>
    </Text>
  );
};

const calcNameFontSize = (name) => {
  return name.length <= 13 ? 36 : Math.round((13 / name.length) * 36);
};

const calcEmailFontSize = (email) => email.length <= 20 ? 11 : Math.round((20 / email.length) * 12);

function Currriculum({ candidate, jobName }) {
  const experiences = candidate.experiences; //?.filter(item => item.add_to_cv === true);
  
  const nameFontSize = candidate.nome_social ? 
    calcNameFontSize(candidate.nome_social) : 
    calcNameFontSize(candidate.name);
  
    const professionalExperience = experiences?.map((item) => {
    if (item.experience_type == "professional") {
      const data = {
        title: item.institution,
        detail: item.title,
        startDate: item.start,
        endDate: item.end,
      };
      return itemTemplate01(data);
    }
  });
  const education = experiences?.map((item) => {
    if (item.experience_type == "education") {
      const data = {
        title: item.institution,
        local: "Não consta",
        detail: item.formation,
        startDate: item.start,
        endDate: item.end,
        description: "Lorem ipsum",
      };
      return itemTemplate01(data);
    }
    if (item.experience_type == "certification") {
      const data = {
        title: item.institution,
        detail: item.title,
        startDate: item.start,
        endDate: item.end,
      };
      return itemTemplate01(data);
    }
  });
  const languages = candidate.languages?.map((lang) => {
    if (lang.add_to_cv)
      return itemTemplate03(lang.name, Levels.languageLevels[lang.rating]);
  });

  const skills = candidate.skills?.map((skill) => {
    if (skill.add_to_cv)
      return itemTemplate03(skill.name, Levels.skillLevels[skill.rating]);
  });

  const endereco = (
    <View style={{marginBottom: '5mm'}}>
      <Text style={styles.capitalizedText}>
        {`${candidate.rua.toLowerCase()}, ${candidate.numero.toLowerCase()}\n${candidate.municipio.toLowerCase()}, ${
          candidate.bairro.toLowerCase()
        }, ${candidate.cep.toLowerCase()}`}
      </Text>
        {
          candidate.github && (
            <View style={styles.headerLogosBox}>
              <Image src={logoGithub} style={styles.headerLogosDimensions} />
              <Text style={styles.headerLink}>
                {`${candidate.github}`}
              </Text>
            </View>
          )
        }
        {
          candidate.linkedin && (
            <View style={styles.headerLogosBox} >
              <Image src={logoLinkedIn} style={styles.headerLogosDimensions} />
              <Text style={styles.headerLink}>
                {`${candidate.linkedin}`}
              </Text>
            </View>
          )
        }
        <Text
          style={[styles.capitalizedText, {
            fontWeight: "bold",
            fontSize: 11,
            fontFamily: "KarlaBold"
          }]}>
          {`${candidate.telefone.toLowerCase()}\n`}
        </Text>
        <Text
          style={[styles.capitalizedText, {
            fontWeight: "bold",
            fontSize: calcEmailFontSize(candidate.email),
            fontFamily: "KarlaBold"
          }]}>
          {`${candidate.email.toLowerCase()}`}
        </Text>
    </View>
  );

  return (
    <Document>
      <Page style={styles.page} wrap size="A4">
        <View style={styles.header}>
          <View style={styles.left}>
            <Text
              style={[
                styles.name,
                { textTransform: "capitalize", fontSize: nameFontSize },
              ]}
            >
              {candidate.nome_social ? candidate.nome_social.toLowerCase() : candidate.name.toLowerCase()}
            </Text>
            {jobName && (
              <Text style={styles.capitalizedText}>
                {jobName.toLowerCase()}
              </Text>
            )}
          </View>

          <View style={styles.right}>{endereco}</View>
        </View>

        <Svg height="1.5pt" width="auto" style={{ marginBottom: "15mm" }}>
          <Line
            x1="0"
            y1="0"
            x2="510"
            y2="0"
            strokeWidth={2}
            stroke={palette.roxoEscuro}
          />
        </Svg>
        <View style={styles.row}> 
          <View style={styles.left}>
            <Text style={styles.subtitle}>SOBRE MIM</Text>
            <Text style={styles.text}>{candidate.sobre_mim}</Text>

            <Text style={styles.subtitle}>EXPERIÊNCIA</Text>
            {professionalExperience}
            <Text style={styles.subtitle}>FORMAÇÃO</Text>
            {education}
          </View>
          <View style={styles.right}>
            <Text style={styles.subtitle}>HABILIDADES</Text>
            {skills}
            <Text style={styles.subtitle}>IDIOMAS</Text>
            {languages}
          </View>
        </View>
        <View
          style={[
            styles.row,
            {
              maxHeight: "22.5pt",
              display: "flex",
              alignItems: "center",
              flexFlow: "row",
            },
          ]}
          fixed
        >
          <Image
            src={logoOxetech}
            style={[styles.img, { maxHeight: "15pt" }]}
          />
          <Image
            src={logoSecti}
            style={[styles.img, { maxHeight: "22.5pt" }]}
          />
          <Image
            src={logoOxedin}
            style={[styles.img, { maxHeight: "15pt" }]}
          />
        </View>
      </Page>
    </Document>
  );
}

export default Currriculum;

import { Checkbox, FormControlLabel, Grid, TextField } from "@mui/material";
import React from "react";
import api from "../../services/api";
import { laranjaIntermediario } from "../../utils/styledVariables";
// import {
//     FormHelperText,
//     InputAdornment,
//     FormControl,
//     Grid,
//     Checkbox,
//     Link,
//     FormControlLabel,
//   } from "@mui/material";

export default function AuthenticationForm() {
  const [email, setEmail] = React.useState(sessionStorage.getItem("email") || '');
  const [confirmEmail, setConfirmEmail] = React.useState("");
  const [errorEmail, setErrorEmail] = React.useState(null);
  const [errorConfirmEmail, setErrorConfirmEmail] = React.useState(null);

  const [fullName, setFullName] = React.useState(
    sessionStorage.getItem("fullName") || ''
  );
  const [errorFullName, setErrorFullName] = React.useState(null);

  const [checked, setChecked] = React.useState([true, false]);
  
  // Inicializando propriedade intelectual como false
  if (sessionStorage.getItem("property_intelect") === null) sessionStorage.setItem("property_intelect", false);
  
  const handleEmail = (event) => {
    const {
      target: { value },
    } = event;
    setEmail(value);
    setErrorEmail({ email: "" });
    if (value.trim().length > 0) {
      let reg = new RegExp(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      ).test(value);
      if (!reg) {
        setErrorEmail({ email: "Insira um email válido." });
        sessionStorage.setItem("emailEmpValido", "incorreto");
      } else
        sessionStorage.setItem("emailEmpValido", "correto");
        api
          .get("empresa/verify-email", {
            params: {
              email: value,
            },
          })
          .then((resp) => {
            sessionStorage.setItem("email", value);
          })
          .catch((error) => {
            setErrorEmail({ email: "Email já cadastrado." });
            sessionStorage.removeItem("email");

          });
    } else {
      setErrorEmail({ email: "Preencha esse campo." });
      sessionStorage.removeItem("email");
    }
  };

  const handleConfirmEmail = (event) => {
    const {
      target: { value },
    } = event;
    setConfirmEmail(value)
    setErrorConfirmEmail({ email: "" });
    if (value === email || value === "") {
      sessionStorage.setItem("emailConfirmValido", "correto"); 
    }
    else{
      setErrorConfirmEmail({ email: "Emails diferentes" });
      sessionStorage.setItem("emailConfirmValido", "incorreto");
    }
  }


  const handleFullName = (event) => {
    const {
      target: { value },
    } = event;
    setFullName(value);
    setErrorFullName({ fullName: "" });
    if (value.trim().length < 1) {
      setErrorFullName({ fullName: "Preencha esse campo." });
    } else {
      sessionStorage.setItem("fullName", value);
    }
  };

  const handleChecked = (event) => {
    setChecked([event.target.checked, event.target.checked]);
    sessionStorage.setItem("property_intelect", event.target.checked);
  };

  return (
    <Grid container spacing={3} sx={{ my: 3 }}>
      
      <Grid item xs={12} sm={12}>
        <TextField
          required
          id="email"
          name="email"
          label="Email"
          fullWidth
          autoComplete="social"
          color="warning"
          value={email}
          onBlur={handleEmail}
          onChange={handleEmail}
          error={Boolean(errorEmail?.email)}
          helperText={errorEmail?.email}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>
      <Grid item xs={12} sm={12}>
        <TextField
          required
          id="email"
          name="email"
          label="Repetir Email"
          fullWidth
          autoComplete="social"
          color="warning"
          value={confirmEmail}
          onPaste={e => e.preventDefault()}
          onBlur={handleConfirmEmail}
          onChange={handleConfirmEmail}
          error={Boolean(errorConfirmEmail?.email)}
          helperText={errorConfirmEmail?.email}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>
      <Grid item xs={12} sm={12}>
        <TextField
          required
          id="name"
          name="name"
          label="Nome completo do usuário"
          fullWidth
          autoComplete="name"
          color="warning"
          value={fullName}
          onBlur={handleFullName}
          onChange={handleFullName}
          error={Boolean(errorFullName?.fullName)}
          helperText={errorFullName?.fullName}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>

      <Grid item xs={12} sm={12}>
        <FormControlLabel
          // value=
          control={
            <Checkbox
              required
              sx={{
                "&.Mui-checked": {
                  color: laranjaIntermediario,
                },
              }}
              checked={checked[1]}
              onChange={handleChecked}
            />
          }
          label="A empresa possui propriedade intelectual?"
          labelPlacement="end"
          sx={{ mx: 0.5 }}
        />
      </Grid>
     {/* <Grid item xs={12} sm={12}>
        <FormControlLabel
          // value=
          control={
            <Checkbox
              required
              color="warning"
              checked={checked[1]}
              onChange={handleChecked}
            />
          }
          label="Há interesse em transferir propriedade intelectual?"
          labelPlacement="end"
          sx={{ mx: 0.5 }}
        />
      </Grid> */}
    </Grid>
  );
}

import ModeEditIcon from "@mui/icons-material/ModeEdit";
import {
  Box,
  Button,
  CssBaseline,
  IconButton,
  Snackbar,
  SnackbarContent,
  Tabs,
  createTheme,
  ThemeProvider,
} from "@mui/material";
import { pdf } from "@react-pdf/renderer";
import { SnackbarProvider } from "notistack";
import React, { useEffect, useState } from "react";
import * as palette from "../../utils/styledVariables";
import Currriculum from "../curriculum";

import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import VisibilityIcon from "@mui/icons-material/Visibility";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Tab from "@mui/material/Tab";
import { notification } from "antd";
import api from "../../services/api";
import formatDate from "../../utils/formartDate";
import Options from "../../utils/options";
import ModalCadastroCiclo from "./ModalCadastroCiclo";
import ModalCadastroEdital from "./ModalCadastroEdital";
import ModalConfirmacao from "../ModalConfirmacao";
import ModalContratados from "./ModalContratados";
import ModalDetalhesVaga from "./ModalDetalhesVaga";
import ModalEditarEdital from "./ModalEditarEdital";
import ModalRejeicao from "./ModalRejeição";
import ModalVerCiclos from "./ModalVerCiclos";
import TableCard from "./TableCard";
import SidebarAdmin from "../SidebarAdmin";
const styles = {
  cadastro: {
    height: "30px",
    margin: "2px",
    float: "right",
    bgcolor: palette.laranjaSecundario,
    "&:hover": {
      bgcolor: palette.laranjaIntermediario2,
    },
  },
  editalItem: {
    display: "flex",
    alignItems: "center",
    minHeight: "50px",
    "&:hover button": {
      display: "inline-block",
    },
  },
  titleContainer: {
    display: "block",
    whiteSpace: "normal",
  },
  editButton: {
    color: palette.laranjaIntermediario2,
    marginLeft: "8px",
    display: "block",
  },
};

function DashUserAdm() {
  const [mobile, setMobile] = useState(false);
  const [openModalEdital, setOpenModalEdital] = useState(false);
  const [openModalCiclo, setOpenModalCiclo] = useState(false);
  const [openModalConfirmEdital, setOpenModalConfirmEdital] = useState(false);
  const [openModalConfirmEmpresa, setOpenModalConfirmEmpresa] = useState(false);
  const [openModalConfirmAluno, setOpenModalConfirmAluno] = useState(false);
  const [openModalConfirmVaga, setOpenModalConfirmVaga] = useState(false);
  const [openModalConfirmContratacao, setOpenModalConfirmContratacao] =
    useState(false);
  const [openModalContratados, setOpenModalContratados] = useState(false);
  const [openModalDetalhes, setOpenModalDetalhes] = useState(false);
  const [openModalEditarEdital, setOpenModalEditarEdital] = useState(false);
  const [editalData, setEditalData] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [selectedCiclos, setSelectedCiclos] = useState([]);
  const [atualiza, setAtualiza] = useState(false);

  const [editais, setEditais] = useState([]);
  const [editaisAbertos, setEditaisAbertos] = useState([]);
  const [alunos, setAlunos] = useState([]);
  const [empresas, setEmpresas] = useState([]);
  const [vagas, setVagas] = useState([]);
  const [vagasFechadas, setVagasFechadas] = useState(null);
  const [idVagasFechadas, setIdVagasFechadas] = useState(null);
  const [detalhe, setDetalhe] = useState(null);
  const [contratacoes, setContratacoes] = useState([]);

  const [highlightedEdital, setHighlightedEdital] = useState(null);
  const [hideTimeout, setHideTimeout] = useState(500);

  const theme = createTheme({
    palette: {
      primary: {
        main: palette.laranjaIntermediario2,
      },
    },
  });

  const handleMouseEnter = (editalId) => {
    setHighlightedEdital(editalId);
    if (hideTimeout) {
      clearTimeout(hideTimeout);
      setHideTimeout(null);
    }
  };

  const handleMouseLeave = () => {
    const timeout = setTimeout(() => {
      setHighlightedEdital(null);
      setHideTimeout(null);
    }, 500);
    setHideTimeout(timeout);
  };

  const [chosenTable, setChosenTable] = useState(0);
  const [loading, setLoading] = useState(true);

  const [currentEdital, setCurrentEdital] = useState(null);
  const [currentEmpresa, setCurrentEmpresa] = useState(null);
  const [currentAluno, setCurrentAluno] = useState(null);
  const [currentVaga, setCurrentVaga] = useState(null);
  const [currentContratacao, setCurrentContratacao] = useState(null);

  const [sidebarCollapsed, setSidebarCollapsed] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  const onMobile = width <= 600;

  const [openModalRejeicao, setOpenModalRejeicao] = useState(false);
  const [openModalRejeicaoEmpresa, setOpenModalRejeicaoEmpresa] =
    useState(false);
  const [openSucess, setOpenSucess] = useState(false);
  const [messageSuccess, setMessageSuccess] = useState("");

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={() => {
          setOpenSucess(false);
        }}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  const handleClick = (id) => {
    getAllCiclosByEdital(id);
    setOpenModal(true);
  };

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  useEffect(() => {
    setLoading(true);
    api.get("/oxetechwork/inscricao-empresa/all").then((res) => {
      formatEmpresaData(res.data);
      setLoading(false);
    });
    api
      .get("/oxetechwork/inscricao-aluno/all")
      .then((res) => formatAlunosData(res.data));
    setLoading(false);

    api.get("oxetechwork/edital").then((res) => {
      formatEditalData(res.data.editals);
      setLoading(false);
    });
    api.get("vagas/oxetechwork/all").then((res) => formatVagasData(res.data));
    setLoading(false);
    //api.get("vagas/vagas-fechadas-contratados").then((res) => formatVagasFechadasData(res.data));
    api
      .get("contratacao/contratacoes")
      .then((res) => formatContratacoesData(res.data));
    setLoading(false);

    setAtualiza(false);
  }, [atualiza]);

  function getDocEmpresa(uuid) {
    api
      .get(`/oxetechwork/inscricao-empresa/docs/` + uuid, {
        responseType: "arraybuffer",
      })
      .then((response) => {
        if (response.data) {
          var blob = new Blob([response.data], {
            type: "application/pdf",
          });
          var url = window.URL.createObjectURL(blob);
          window.open(url);
        }
      })
      .catch((err) => {});
  }

  const handleEditClick = (id) => {
    getEditalData(id);
    setOpenModalEditarEdital(true);
  };

  const openNotification = () => {
    notification.open({
      message: <h3>Já existe um edital aberto!</h3>,
      description: "Feche o edital para abrir um novo. ",
      placement: "bottomRight",
    });
  };
  const openNotificationCiclos = () => {
    notification.open({
      message: <h3>Não existe um edital aberto!</h3>,
      description:
        "Para cadastrar um ciclo é preciso que um edital esteja aberto",
      placement: "bottomRight",
    });
  };

  const handleRejeicaoVaga = (motivo) => {
    try {
      const data = {
        motivo_de_rejeicao: motivo,
        status_oxetech_work: "Rejeitada",
      };

      api
        .put(`vagas/oxetechwork/update?id=${currentVaga}`, data)
        .then((res) => {
          setCurrentVaga(null);
          setOpenModalRejeicao(false);
          getVagas();
          setOpenSucess(true);
        });
    } catch (err) {
      console.log(err);
    }
  };

  const handleRejeicaoEmpresa = (motivo) => {
    try {
      const data = {
        motivo_de_rejeicao: motivo,
        status: "Rejeitado",
      };
      api
        .put(`/oxetechwork/inscricao-empresa?id=${currentEmpresa}`, data)
        .then((res) => {
          setCurrentEmpresa(null);
          setOpenModalRejeicaoEmpresa(false);
          getEmpresas();
          setOpenSucess(true);
        });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    const ed = editais.filter(
      (edital) => edital.status == true || edital.status === "Aberto"
    );
    setEditaisAbertos(ed);
  }, [editais]);

  const tabButtons = (
    <div style={{ float: "right" }}>
      {/* {handleExisteEditalAberto} */}
      <Button
        variant="contained"
        onClick={() => {
          editaisAbertos.length > 0
            ? openNotification()
            : setOpenModalEdital(true);
        }}
        sx={styles.cadastro}
        startIcon={<AddIcon />}
      >
        Cadastrar edital
      </Button>
      <Button
        variant="contained"
        onClick={() => {
          editaisAbertos.length === 0
            ? openNotificationCiclos()
            : setOpenModalCiclo(true);
        }}
        sx={styles.cadastro}
        startIcon={<AddIcon />}
      >
        Cadastrar ciclo
      </Button>
    </div>
  );

  const editalHeader = [
    //{ field: "id" },

    {
      field: "status",
      headerName: "Status",
      width: 150,
      renderCell: (params) => {
        return (
          <FormControl variant="filled" sx={{ width: "100%" }}>
            <Select
              value={params.value ? 1 : 0}
              onChange={(e) => {
                changeEditalStatus(e, params.row.id);
              }}
              // MenuProps={MenuProps}
            >
              {Options.editalStatuses.map((item, index) => (
                <MenuItem key={item} value={index}>
                  {item}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        );
      },
    },

    {
      field: "title",
      headerName: "Título do Edital",
      width: 120,
      renderCell: (params) => {
        return (
          <div style={{ display: "flex", flexDirection: "column" }}>
              <span
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  flexGrow: 1,
                }}
              >
                {params.formattedValue}
              </span>
          </div>
        );
      },
    },
    { field: "editar_edital", headerName: "Editar edital", width: 150, 
    renderCell: (params) => {
      return (
        <>
        <Button
          onClick={() => handleEditClick(params.id)}
          sx={{ color: palette.laranjaIntermediario2 }}>
          <ModeEditIcon />
        </Button>
        </>
      );
    }},
    { field: "dt_inicio_edital", headerName: "Data de Abertura", width: 150 },
    { field: "ultimo_ciclo", headerName: "Último ciclo", width: 150 },
    {
      field: "all_ciclo",
      headerName: "Ver ciclos",
      width: 150,
      renderCell: (params) => {
        return (
          <>
            <Button
              sx={{ color: palette.laranjaIntermediario2 }}
              onClick={() => handleClick(params.id)}
            >
              <VisibilityIcon />
            </Button>
          </>
        );
      },
    },
    {
      field: "dt_encerramento_edital",
      headerName: "Data de Encerramento",
      width: 150,
    },
    { field: "updatedAt", headerName: "Última Atualização", width: 150 },
    { field: "qt_vagas", headerName: "Qtd. Vagas", width: 150 },
    { field: "qt_vagas_em_oferta", headerName: "Qtd. em oferta", width: 150 },
    {
      field: "qt_vagas_preenchidas",
      headerName: "Qtd. preenchidas",
      width: 150,
    },
    {
      field: "postos_de_trabalho_disponiveis",
      headerName: "Postos de trabalho disponíveis",
      width: 220,
    },
    {
      field: "id",
      headerName: "Documentação",
      width: 200,
      renderCell: (params) => {
        return (
          <Button
            sx={{ color: palette.laranjaIntermediario2 }}
            onClick={() => {
              api
                .get(`/oxetechwork/edital/docs/${params.value}`, {
                  responseType: "arraybuffer",
                })
                .then((res) => {
                  var blob = new Blob([res.data], {
                    type: "application/pdf",
                  });
                  var url = window.URL.createObjectURL(blob);
                  window.open(url);
                })
                .catch((res) => console.log(res));
            }}
          >
            Baixar
          </Button>
        );
      },
    },
  ];

  const institutionsHeader = [
    { field: "id" },
    {
      field: "status",
      headerName: "Status",
      width: 150,
      renderCell: (params) => {
        return (
          <FormControl variant="filled" sx={{ width: "100%" }}>
            <Select
              value={params.value}
              onChange={(e) => {
                changeEmpresaStatus(e, params.row.id);
              }}
            >
              {Options.subscriptionStatuses.map((item) => (
                <MenuItem key={item} value={item}>
                  {item}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        );
      },
    },
    { field: "razao_social", headerName: "Nome", width: 200 },
    { field: "edital", headerName: "Edital", width: 200 },
    { field: "cnpj", headerName: "CNPJ", width: 200 },
    { field: "qt_vagas", headerName: "Qtd. Vagas", width: 150 },
    { field: "qt_vagas_em_oferta", headerName: "Qtd. em oferta", width: 150 },
    {
      field: "qt_vagas_preenchidas",
      headerName: "Qtd. preenchidas",
      width: 150,
    },
    { field: "telefone", headerName: "Telefone", width: 150 },
    { field: "email", headerName: "E-mail", width: 200 },
    {
      field: "docs",
      headerName: "Documentação",
      width: 200,
      renderCell: (params) => {
        return (
          <Button
            sx={{ color: palette.laranjaIntermediario2 }}
            onClick={() => {
              getDocEmpresa(params.formattedValue);
            }}
          >
            Baixar
          </Button>
        );
      },
      renderCell: (params) => {
        return (
          <Button
            sx={{ color: palette.laranjaIntermediario2 }}
            onClick={() => {
              api
                .get(`/oxetechwork/inscricao-empresa/docs/${params.value}`, {
                  responseType: "arraybuffer",
                })
                .then((res) => {
                  var blob = new Blob([res.data], {
                    type: "application/pdf",
                  });
                  var url = window.URL.createObjectURL(blob);
                  window.open(url);
                });
            }}
          >
            Baixar
          </Button>
        );
      },
    },
    {
      field: "motivo_de_rejeicao",
      headerName: "Motivo de rejeição de inscrição",
      width: 200,
    },
    { field: "createdAt", headerName: "Data de inscrição", width: 200 },
    {
      field: "data_encerramento",
      headerName: "Data de encerramento",
      width: 200,
    },
  ];

  const studentsHeader = [
    { field: "id" },
    { field: "numero_inscricao", headerName: "Nº de inscrição", width: 110 },
    { field: "nome", headerName: "Nome", width: 200 },
    { field: "edital", headerName: "Edital", width: 200 },
    { field: "rg", headerName: "RG", width: 80 },
    { field: "cpf", headerName: "CPF", width: 110 },
    { field: "createdAt", headerName: "Data de Inscrição", width: 150 },
    { field: "encerramento", headerName: "Data de Encerramento", width: 150 },
    { field: "telefone", headerName: "Telefone", width: 150 },
    { field: "email", headerName: "E-mail", width: 200 },
    { field: "nivel_escolaridade", headerName: "Escolaridade", width: 200 },
    { field: "area", headerName: "Área de atuação", width: 200 },
    { field: "experiencia_ti", headerName: "Experiência TI", width: 200 },
    { field: "experiencia_dev", headerName: "Experiência Dev", width: 200 },
    {
      field: "curriculum",
      headerName: "Currículo",
      width: 200,
      renderCell: (params) => {
        return (
          <Button
            sx={{ color: palette.laranjaIntermediario2 }}
            onClick={() => {
              pdf(<Currriculum candidate={params.value} />)
                .toBlob()
                .then((blob) => {
                  const url = URL.createObjectURL(blob);
                  const a = document.createElement("a");
                  a.setAttribute("href", url);
                  a.setAttribute(
                    "download",
                    `${
                      params.value.nome_social
                        ? params.value.nome_social
                        : params.value.name
                    }.pdf`
                  );
                  a.click();
                });
            }}
          >
            Baixar
          </Button>
        );
      },
    },
    {
      field: "docs",
      headerName: "Documentação",
      width: 200,
      renderCell: (params) => {
        return (
          <Button
            sx={{ color: palette.laranjaIntermediario2 }}
            onClick={() => {
              api
                .get(`/oxetechwork/inscricao-aluno/docs/${params.value}`, {
                  responseType: "arraybuffer",
                })
                .then((res) => {
                  var blob = new Blob([res.data], {
                    type: "application/pdf",
                  });
                  var url = window.URL.createObjectURL(blob);
                  window.open(url);
                });
            }}
          >
            Baixar
          </Button>
        );
      },
    },
  ];

  const jobsHeader = [
    { field: "id" },
    {
      field: "status",
      headerName: "Status",
      width: 250,
      renderCell: (params) => {
        return (
          <FormControl variant="filled" sx={{ width: "100%" }}>
            <Select
              value={params.value}
              onChange={(e) => {
                changeVagaStatus(e, params.row.id);
              }}
            >
              {Options.jobsStatuses.map((item) => (
                <MenuItem
                  disabled={item === "Encerrada pela empresa"}
                  key={item}
                  value={item}
                >
                  {item}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        );
      },
    },
    {
      field: "detalhes",
      headerName: "Detalhes",
      width: 75,
      renderCell: (detalhes) => {
        return (
          <div>
            <Button
              sx={{ color: palette.laranjaIntermediario2 }}
              onClick={() => {
                setDetalhe(detalhes);
                setOpenModalDetalhes(true);
              }}
            >
              Ver
            </Button>
          </div>
        );
      },
    },
    { field: "ciclo", headerName: "Ciclo", width: 50 },
    { field: "titulo", headerName: "Título", width: 200 },
    { field: "quantidade", headerName: "Nº de Vagas", width: 95 },
    { field: "n_candidaturas", headerName: "Nº de Candidaturas", width: 150 },
    {
      field: "qtd_preenchida",
      headerName: "Nº de Vagas Preenchidas",
      width: 170,
    },
    {
      field: "qtd_em_oferta",
      headerName: "Nº de Vagas Disponíveis",
      width: 170,
    },
    { field: "empresa", headerName: "Instituição", width: 200 },
    { field: "cnpj", headerName: "CNPJ", width: 200 },
    { field: "data_abertura", headerName: "Data de Abertura", width: 150 },
    {
      field: "data_encerramento",
      headerName: "Data de Encerramento",
      width: 150,
    },
    {
      field: "motivo_de_rejeicao",
      headerName: "Motivo de rejeição de inscrição",
      width: 200,
    },
    { field: "contratacao", headerName: "Data de Contratação", width: 150 },
    { field: "edital", headerName: "Edital" },
    { field: "id_contratacao", headerName: "ID Contratado", width: 105 },
    {
      field: "file_id",
      headerName: "Currículo e Plano de trabalho do Mentor",
      width: 300,
      renderCell: (data) => {
        return (
          <Button
            sx={{ color: palette.laranjaIntermediario2 }}
            onClick={() => {
              getDocEmpresa(data.value);
            }}
          >
            Baixar
          </Button>
        );
      },
    },
  ];
  const jobsClosedHeader = [
    { field: "id" },
    {
      field: "titulo",
      headerName: "Vaga",
      width: 350,
    },
    {
      field: "empresa",
      headerName: "Empresa",
      width: 350,
    },
    {
      field: "contratos",
      headerName: "Contratos",
      width: 300,
      renderCell: (id) => {
        return (
          <div>
            <Button
              sx={{ color: palette.laranjaIntermediario2 }}
              onClick={() => {
                setIdVagasFechadas(id.row);
                setOpenModalContratados(true);
              }}
            >
              Ver
            </Button>
          </div>
        );
      },
    },
  ];

  const contratacoesHeader = [
    { field: "id" },
    {
      field: "status",
      headerName: "Status",
      width: 200,
      renderCell: (params) => {
        return (
          <FormControl variant="filled" sx={{ width: "100%" }}>
            <Select
              value={params.value}
              onChange={(e) => {
                changeContratacaoStatus(e, params.row.id);
              }}
              // MenuProps={MenuProps}
            >
              {Options.contratacoesStatuses.map((item, index) => (
                <MenuItem key={item} value={index}>
                  {item}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        );
      },
    },
    {
      field: "data_contratacao",
      headerName: "Data de contratação",
      width: 150,
    },
    { field: "ciclo", headerName: "Ciclo", width: 150 },
    { field: "titulo_edital", headerName: "Edital", width: 180 },
    {
      field: "nome",
      headerName: "Nome do contratado",
      width: 300,
    },
    {
      field: "empresa",
      headerName: "Nome da empresa",
      width: 200,
    },
    {
      field: "titulo",
      headerName: "Título da vaga",
      width: 350,
    },
    {
      field: "email",
      headerName: "E-mail da empresa",
      width: 300,
    },
  ];
  const [totalVagas, setTotalVagas] = useState();

  // const totalDeVagas = (<div style={{ float: "right" }}><span style={{ fontSize: "18px", color: "gray", fontWeight: "normal" }}>Total de vagas: {totalVagas} </span></div>);

  // const vagasGridRef = useRef();
  const tables = [
    {
      name: "Editais",
      columns: editalHeader,
      rows: editais,
      buttonsBar: tabButtons,
      statusOptions: Options.editalStatuses,
      changeStatus: "",
    },
    {
      name: "Instituições",
      columns: institutionsHeader,
      rows: empresas,
    },
    {
      name: "Vagas",
      columns: jobsHeader,
      rows: vagas,
      // totalDeVagas: totalDeVagas,
      // ref: vagasGridRef,
    },
    {
      name: "Candidatos",
      columns: studentsHeader,
      rows: alunos,
    },
    //{
    //   name: "Vagas Encerradas",
    //   columns: jobsClosedHeader,
    //   rows: vagasFechadas,
    // },
    {
      name: "Contratações",
      columns: contratacoesHeader,
      rows: contratacoes,
    },
  ];

  const vagasTable = tables.find((table) => table.name === "Vagas");
  useEffect(() => {
    if (vagasTable) {
      const column = vagasTable.columns?.find(
        (col) => col.field === "quantidade" && col.headerName === "Nº de Vagas"
      );

      if (column) {
        const total = vagasTable.rows?.reduce(
          (accumulator, row) => accumulator + (row[column.field] || 0),
          0
        );
        setTotalVagas(total);
      }
    }
  }, [vagasTable]);
  // useEffect(() => {
  //   if (vagasTable) {
  //     const column = vagasTable.columns.find((col) => col.field === "quantidade" && col.headerName === "Nº de Vagas");

  //     if (column) {
  //       let total = 0;

  //       vagasTable.rows.forEach((row) => {
  //         const value = row[column.field];
  //         total += value;
  //       });

  //       setTotalVagas(total);
  //     } else {
  //       console.log("Coluna não encontrada na tabela 'Vagas'.");
  //     }
  //   } else {
  //     console.log("Tabela 'Vagas' não encontrada.");
  //   }
  // }, [vagasTable]);

  const formatEditalData = (editalData) => {
    const formated = [];
    editalData.forEach((edital) => {
      const data = {
        ...edital,
        updatedAt: formatDate(edital.updatedAt),
        dt_encerramento_edital: formatDate(edital.dt_encerramento_edital),
        dt_inicio_edital: formatDate(edital.dt_inicio_edital),
        // qt_vagas_a_preencher: edital.qt_vagas_em_oferta - edital.qt_vagas_preenchidas
      };

      formated.push(data);
    });

    setEditais(formated);
  };

  const formatEmpresaData = (empresasData) => {
    const formated = [];
    empresasData.forEach((emp) => {
      const data = {
        id: emp.id,
        status: emp.status,
        razao_social: emp.empresa_info.razao_social,
        cnpj: emp.empresa_info.cnpj,
        qt_vagas: emp.qt_vagas,
        qt_vagas_em_oferta: emp.qt_vagas_em_oferta,
        qt_vagas_preenchidas: emp.qt_vagas_preenchidas,
        edital: emp.edital_info.title,
        telefone: emp.empresa_info.telefone,
        email: emp.empresa_info.email,
        docs: emp.anexo_comprovante_inscricao_cnpj,
        motivo_de_rejeicao: emp.motivo_de_rejeicao,
        createdAt: formatDate(emp.createdAt),
        data_encerramento: formatDate(emp.data_encerramento),
      };

      formated.push(data);
    });

    setEmpresas(formated);
  };

  const formatAlunosData = (alunosData) => {
    const formated = [];
    alunosData.forEach((aln) => {
      const data = {
        id: aln.id,
        status: aln.status,
        nome: aln.aluno_info.nome_social
          ? aln.aluno_info.nome_social
          : aln.aluno_info.name,
        cpf: aln.aluno_info.cpf,
        rg: aln.rg,
        motivo_de_rejeicao: aln.motivo_de_rejeicao,
        numero_inscricao: aln.num_inscricao,
        edital: aln.edital_info.title,
        createdAt: formatDate(aln.createdAt),
        encerramento: aln.data_encerramento
          ? formatDate(aln.data_encerramento)
          : "Não consta",
        telefone: aln.aluno_info.telefone,
        email: aln.aluno_info.email,
        nivel_escolaridade: aln.nivel_escolaridade,
        area: aln.area,
        experiencia_ti: aln.experiencia_ti,
        experiencia_dev: aln.experiencia_ti,
        curriculum: aln.aluno_info,
        docs: aln.file_id,
      };

      formated.push(data);
    });

    setAlunos(formated);
  };

  const formatVagasData = async (vagasData) => {
    const formatted = await Promise.all(
      vagasData.map(async (job) => {
        try {
          const candidatosPorVaga = await api.get(
            `/candidatura/candidatos-vaga-number?id=${job.id}`
          );

          if (candidatosPorVaga.data) {
            job.candidatos = candidatosPorVaga.data;
          }
          const imgResponse = await api.get(
            `/vagas/avatar-empresa?id=${job.id}`,
            {
              responseType: "arraybuffer",
            }
          );

          if (imgResponse.data) {
            job.avatar = URL.createObjectURL(
              new Blob([imgResponse.data], { type: "image" })
            );
          }

          
        } catch (error) {
          console.log(error);
        }

        const data = {
          id: job.id,
          status: job.status_oxetech_work,
          titulo: job.titulo,
          empresa: job.empresa.razao_social,
          cnpj: job.empresa.cnpj,
          edital: job.edital_info?.title,
          data_abertura: formatDate(job.created_at),
          data_encerramento: formatDate(job.data_encerramento),
          data_contratacao: job.data_contratacao
            ? formatDate(job.data_contratacao)
            : null,
          detalhes: {
            avatarEmpresa: job.avatar,
            modalidade: job.modalidade,
            qualificacoes: job.qualificacoes,
            quantidade: job.quantidade,
            remuneracao: job.remuneracao,
            requisitos: job.requisitos,
            tipo: job.tipo,
            titulo: job.titulo,
            descricao: job.descricao,
            carga_horaria: job.carga_horaria,
          },
          quantidade: job.quantidade,
          qtd_preenchida: job.qtd_preenchida,
          qtd_em_oferta: job.qtd_em_oferta,
          edital_id: job.edital_id,
          id_contratacao: job.id_contratacao,
          motivo_de_rejeicao: job.motivo_de_rejeicao,
          ciclo: job.ciclo && job.ciclo?.num_ciclo ? job.ciclo.num_ciclo : null,
          file_id: job.file_id,
          n_candidaturas: job.candidatos
        };

        return { ...data };
      })
    );

    setVagas(formatted);
  };

  const formatVagasFechadasData = (vagasData) => {
    const formated = [];
    vagasData.forEach((job) => {
      const data = {
        id: job.id,
        titulo: job.titulo,
        qtd_em_oferta: job.qtd_em_oferta,
        qtd_preenchida: job.qtd_preenchida,
        empresa: job.empresa.razao_social,
      };

      // console.log(data.file_id)
      formated.push(data);
    });
    setVagasFechadas(formated);
  };
  const formatContratacoesData = (vagasData) => {
    const formated = [];
    vagasData.forEach((contratacao) => {
      const data = {
        id: contratacao.id,
        titulo: contratacao.vaga.titulo,
        empresa: contratacao.vaga.empresa.razao_social,
        email: contratacao.vaga.empresa.email,
        nome: contratacao.aluno.name
          ? contratacao.aluno.name
          : contratacao.aluno.name,
        status: contratacao.status,
        data_contratacao: formatDate(contratacao.data_contratacao),
        ciclo: contratacao.vaga.ciclo?.num_ciclo,
        titulo_edital: contratacao.vaga.ciclo?.edital.title,
      };

      formated.push(data);
    });
    setContratacoes(formated);
  };

  const changeEditalStatus = (e, id) => {
    const {
      target: { value },
    } = e;

    const updatedEditals = editais.map((item) =>
      item.id == id ? { ...item, status: value } : item
    );

    setEditais(updatedEditals);
    setCurrentEdital(id);
    setOpenModalConfirmEdital(true);
  };
  const changeContratacaoStatus = async (e, id) => {
    const {
      target: { value },
    } = e;
    //const newStatus = e.target.value;

    // Atualize localmente
    const updatedContratacoes = contratacoes.map((item) =>
      item.id === id ? { ...item, status: value } : item
    );
    // Atualize no servidor
    // try {
    //   await api.put(`contratacao/contratacoes/update?id=${id}`, { status: newStatus });
    // } catch (err) {
    //   console.log(err);
    // }

    // const {
    //   target: { value },
    // } = e;
    // const updatedContratacoes = contratacoes.map((item) =>
    //   item.id == id ? { ...item, status: value } : item
    // );

    setContratacoes(updatedContratacoes);
    setCurrentContratacao(id);
    setOpenModalConfirmContratacao(true);
    //submitChangesContratacoes();
  };

  const getEditalData = (id) => {
    api.get(`/oxetechwork/edital/${id}`).then((res) => {
      setEditalData(res.data);
    });
  };

  const changeEmpresaStatus = (e, id) => {
    const {
      target: { value },
    } = e;

    setCurrentEmpresa(id);

    if (value === "Rejeitado") {
      const updatedEmpresas = empresas.map((item) =>
        item.id == id ? { ...item, status: value } : item
      );

      setEmpresas(updatedEmpresas);
      setOpenModalRejeicaoEmpresa(true);
      return;
    }

    const updatedEmpresas = empresas.map((item) =>
      item.id == id ? { ...item, status: value } : item
    );

    setEmpresas(updatedEmpresas);
    setOpenModalConfirmEmpresa(true);
  };

  // const changeAlunoStatus = (e, id) => {
  //   const {
  //     target: { value },
  //   } = e;

  //   const updatedAlunos = alunos.map((item) =>
  //     item.id == id ? { ...item, status: value } : item
  //   );

  //   setAlunos(updatedAlunos);
  //   setCurrentAluno(id);
  //   setOpenModalConfirmAluno(true);
  // };

  const changeVagaStatus = (e, id) => {
    const {
      target: { value },
    } = e;
    setCurrentVaga(id);

    if (value === "Rejeitada") {
      const updatedVagas = vagas.map((item) =>
        item.id == id
          ? {
              ...item,
              status: "Rejeitada",
            }
          : item
      );
      setVagas(updatedVagas);
      setOpenModalRejeicao(true);
      return;
    }

    const updatedVagas = vagas.map((item) =>
      item.id == id ? { ...item, status: value } : item
    );
    setVagas(updatedVagas);
    setOpenModalConfirmVaga(true);
  };

  const getEditals = () => {
    api.get("oxetechwork/edital").then((res) => {
      setEditais(res.data.editals);
      setCurrentEdital(null);
      setOpenModalConfirmEdital(false);
    });
  };

  const getAllCiclosByEdital = async (id) => {
    api
      .get(`/oxetechwork/ciclo/by_edital/${id}`)
      .then((response) => {
        setSelectedCiclos(response.data);
        return response.data;
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const getEmpresas = () => {
    api.get("/oxetechwork/inscricao-empresa/all").then((res) => {
      formatEmpresaData(res.data);
      setCurrentEmpresa(null);
      setOpenModalConfirmEmpresa(false);
    });
  };

  const getAlunos = () => {
    api.get("/oxetechwork/inscricao-aluno/all").then((res) => {
      formatAlunosData(res.data);
      setCurrentAluno(null);
      setOpenModalConfirmAluno(false);
    });
  };

  const getVagas = () => {
    api.get("vagas/oxetechwork/all").then(async (res) => {
      formatVagasData(res.data);
      setCurrentVaga(null);
      setOpenModalConfirmVaga(false);
    });
  };

  const getContratacoes = () => {
    api.get("contratacao/contratacoes").then(async (res) => {
      formatContratacoesData(res.data);
      setCurrentContratacao(null);
      setOpenModalConfirmContratacao(false);
    });
  };
  const submitChangesEdital = () => {
    try {
      const data = {
        status: editais.find((item) => item.id === currentEdital).status,
      };
      api.put(`oxetechwork/edital?id=${currentEdital}`, data).then((res) => {
        setCurrentEdital(null);
        setOpenModalConfirmEdital(false);
      });
    } catch (err) {
      console.log(err);
    }
  };

  const submitChangesEmpresa = () => {
    try {
      const data = {
        status: empresas.find((item) => item.id === currentEmpresa).status,
      };
      api
        .put(`/oxetechwork/inscricao-empresa?id=${currentEmpresa}`, data)
        .then((res) => {
          setCurrentEmpresa(null);
          setOpenModalConfirmEmpresa(false);
        });
    } catch (err) {
      console.log(err);
    }
  };

  const submitChangesAluno = () => {
    try {
      const data = {
        status: alunos.find((item) => item.id === currentAluno).status,
      };
      api
        .put(`/oxetechwork/inscricao-aluno?id=${currentAluno}`, data)
        .then((res) => {
          setCurrentAluno(null);
          setOpenModalConfirmAluno(false);
        });
    } catch (err) {
      console.log(err);
    }
  };

  const submitChangesVaga = () => {
    try {
      const data = {
        status_oxetech_work: vagas.find((item) => item.id === currentVaga)
          .status,
      };

      api
        .put(`vagas/oxetechwork/update?id=${currentVaga}`, data)
        .then((res) => {
          setCurrentVaga(null);
          setOpenModalConfirmVaga(false);
        });
    } catch (err) {
      console.log(err);
    }
  };
  const submitChangesContratacoes = async () => {
    try {
      const updatedStatus = contratacoes.find(
        (item) => item.id === currentContratacao
      ).status;
      await api
        .put(`contratacao?id=${currentContratacao}`, { status: updatedStatus })
        .then((res) => {
          setCurrentContratacao(null);
          setOpenModalConfirmContratacao(false);
        });

      getContratacoes();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Box sx={{ display: "flex", zIndex: 2 }}>
      <CssBaseline />

      <Box
        sx={{
          display: "flex",
          backgroundColor: palette.laranjaSecundario,
          flexGrow: 1,
        }}
      >
        <SidebarAdmin
          onMobile={onMobile}
          collapsed={sidebarCollapsed}
          setCollapsed={setSidebarCollapsed}
        />
        <Box sx={{ display: "flex", backgroundColor: "#FFFFF", flexGrow: 1 }}>
          <Box
            component="main"
            sx={{
              backgroundColor: "#FFFFFF",
              flexGrow: 2,
              p: 1,
              width: { xs: `calc(100% - 20vh)` },
              marginLeft: onMobile || sidebarCollapsed ? "auto" : "240px",
            }}
          >
            <ModalDetalhesVaga
              open={openModalDetalhes}
              close={() => {
                setOpenModalDetalhes(false);
              }}
              detalhes={detalhe}
            />
            <ModalConfirmacao
              text="Confirma a alteração no status?"
              open={openModalConfirmEdital}
              close={() => {
                setOpenModalConfirmEdital(false);
              }}
              handleChange={submitChangesEdital}
              handleRevert={getEditals}
            />
            <ModalConfirmacao
              text="Confirma a alteração no status?"
              open={openModalConfirmEmpresa}
              close={() => {
                setOpenModalConfirmEmpresa(false);
              }}
              handleChange={submitChangesEmpresa}
              handleRevert={getEmpresas}
            />
            <ModalConfirmacao
              text="Confirma a alteração no status?"
              open={openModalConfirmAluno}
              close={() => {
                setOpenModalConfirmAluno(false);
              }}
              handleChange={submitChangesAluno}
              handleRevert={getAlunos}
            />
            <ModalConfirmacao
              text="Confirma a alteração no status?"
              open={openModalConfirmVaga}
              close={() => {
                setOpenModalConfirmVaga(false);
              }}
              handleChange={submitChangesVaga}
              handleRevert={getVagas}
            />
            <ModalConfirmacao
              open={openModalConfirmContratacao}
              close={() => {
                setOpenModalConfirmContratacao(false);
              }}
              handleChange={submitChangesContratacoes}
              handleRevert={getContratacoes}
            />
            <SnackbarProvider maxSnack={3}>
              <ModalCadastroCiclo
                open={openModalCiclo}
                close={() => {
                  setOpenModalCiclo(false);
                  setAtualiza(true);
                }}
                editais={editaisAbertos}
              />
            </SnackbarProvider>
            <SnackbarProvider maxSnack={3}>
              <ModalEditarEdital
                open={openModalEditarEdital}
                close={() => {
                  setOpenModalEditarEdital(false);
                  setAtualiza(true);
                }}
                edital={editalData}
              />
            </SnackbarProvider>
            <ModalVerCiclos
              open={openModal}
              close={() => {
                setOpenModal(false);
              }}
              selectedCiclos={selectedCiclos}
            />
            <SnackbarProvider>
              <ModalCadastroEdital
                open={openModalEdital}
                close={() => {
                  setOpenModalEdital(false);
                  setAtualiza(true);
                }}
                editais={editais}
              />
            </SnackbarProvider>
            <ModalContratados
              open={openModalContratados}
              close={() => {
                setOpenModalContratados(false);
              }}
              contratos={idVagasFechadas}
            />
            <ModalRejeicao
              open={openModalRejeicao}
              title={"Confirmação de rejeição de vaga"}
              subtitle={"Insira o motivo de rejeição da vaga."}
              handleFinish={(motivo) => handleRejeicaoVaga(motivo)}
              handleRevert={() => {
                getVagas();
                setOpenModalRejeicao(false);
              }}
            />
            <ModalRejeicao
              open={openModalRejeicaoEmpresa}
              title={"Confirmação de rejeição de inscrição"}
              subtitle={"Insira o motivo de rejeição da inscrição."}
              handleFinish={(motivo) => handleRejeicaoEmpresa(motivo)}
              handleRevert={() => {
                getEmpresas();
                setOpenModalRejeicaoEmpresa(false);
              }}
            />

            <Snackbar open={openSucess} autoHideDuration={6000} action={action}>
              <SnackbarContent
                style={{
                  backgroundColor: "green",
                }}
                message={"Sucesso!"}
                action={action}
              />
            </Snackbar>
            <ThemeProvider theme={theme}>
              <Box sx={{ width: "100%" }}>
                <Tabs
                  centered
                  value={chosenTable}
                  onChange={(event, newValue) => setChosenTable(newValue)}
                  sx={{ marginBottom: 1 }}
                  indicatorColor="primary"
                  textColor="primary"
                >
                  {tables.map((table, index) => (
                    <Tab value={index} label={table.name} key={index} />
                  ))}
                </Tabs>
              </Box>
            </ThemeProvider>
            <TableCard props={tables[chosenTable]} loading={loading} />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default DashUserAdm;

import React, { useState } from "react";

import {
  Avatar,
  Paper,
  Typography,
  useMediaQuery,
  IconButton,
} from '@mui/material';

import {
  useStyles,
  VerticalBar,
} from '../styles';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import IconeAtividadePendente from '../../../assets/iconesAtividadesDaTurma/pending-activities-icon.svg';
import IconeDeadline from '../../../assets/iconesAtividadesDaTurma/deadline-icon.svg';

import { ModalDetalhesAtividadeProfessor } from "../ModalDetalhesAtividadeProfessor";


export const CardAtividadeProfessor = ({ atividade }) => {
    const classes = useStyles();
    const isSmallScreen = useMediaQuery('(max-width:600px)');
  
    const [openModal, setOpenModal] = useState(false);
    const handleOpenModal = () => setOpenModal(true);
    const handleCloseModal = () => setOpenModal(false);
  
    const [isDropDownVisible, setIsDropDownVisible] = useState(false);
    const toggleDropDown = (event) => {
      event.stopPropagation();
      setIsDropDownVisible(!isDropDownVisible);
    };
  
    return (
      <>
        <Paper elevation={6} className={classes.root} onClick={handleOpenModal} >
          <div className={classes.iconAndTitleContainer}>
            <Avatar
              className={classes.icon}
              src={IconeAtividadePendente}
              alt={'Ícone de atividade'}
              classes={{ img: classes.iconImg }}
            />
            <Typography
              variant='h5'
              align='left'
              className={classes.title}
              style={{ fontSize: isSmallScreen ? '2.0vh' : '3.0vh' }}
            >
              {atividade.titulo}
            </Typography>
          </div>
          <div className={classes.statusAndDateContainer}>
            <div className={classes.dropDownButtonContainer}>
              <IconButton 
                onClick={toggleDropDown} 
                className={isDropDownVisible ? classes.dropDownRotateUp : classes.dropDownRotateDown}>
                <KeyboardArrowDownIcon />
              </IconButton>
            </div>
            <div className={classes.dateContainer}>
              <img alt='Icone de relógio' src={IconeDeadline} />
              <Typography variant='body2' sx={{ paddingRight: '0.5rem', paddingLeft: '0.5rem' }} >05/05/2023 às 16h</Typography>
            </div>
          </div>
        </Paper>
        {isDropDownVisible &&
          <div className={classes.activiesNumberContainer}>
            <div>
              <Typography variant='h4'sx={{color: '#D46D0D'}}>{atividade.entregues}</Typography>
              <Typography variant='h4'sx={{color: '#D46D0D'}}>Entregues</Typography>            
            </div>
            <VerticalBar />
            <div>
              <Typography variant='h4'sx={{color: '#D46D0D'}}>{atividade.pendentes}</Typography>
              <Typography variant='h4'sx={{color: '#D46D0D'}}>Pendentes</Typography>            
            </div>
          </div>
        }
  
        <ModalDetalhesAtividadeProfessor
          open={openModal}
          close={handleCloseModal}
          atividade={atividade}
        />
  
      </>
    );
  };
  
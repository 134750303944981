function calcIncrease(candidates) {
  let lastAppliances = [];
  let lastWeek = new Date();
  lastWeek.setDate(lastWeek.getDate() - 7);

  for (let i = 0; i < candidates.length; i++) {
    let applianceDate = new Date(candidates[i].createdAt);

    if (applianceDate.getTime() >= lastWeek.getTime())
      lastAppliances.push(candidates[i]);
  }

  let increase =
    lastAppliances.length != candidates.length
      ? Math.round(
          (lastAppliances.length /
            (candidates.length - lastAppliances.length)) *
            100
        )
      : 100 * candidates.length;

  return [lastAppliances, increase];
}

export default calcIncrease;

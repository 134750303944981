
import React, { useEffect, useState } from "react";
import { Modal, Paper, TextField, Grid, Button, Box, Typography } from "@mui/material";
import * as palette from "../../utils/styledVariables"
import { useSelector } from 'react-redux';
import api from "../../services/api";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import ptLocale from "date-fns/locale/pt-BR";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useSnackbar, SnackbarProvider } from 'notistack';

const styles = {
    modal: {

        width: "50%",
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: "#f2f2f2",
        padding: "10px",
        textAlign: "center",
        borderRadius: "1vh",
        justifyContent: 'center'

    },

    paper: {
        width: "100%",
        margin: "5px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",

    },

    field: { padding: "5px" }



}


function ModalCadastroCiclo(props) {
    const { enqueueSnackbar } = useSnackbar();
    const today = new Date();
    const [numeroCiclo, setNumeroCiclo] = useState(null);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    const [edital, setEdital] = useState(null);

    const handleClose = () => {
        props.close()
    }

    const handleEdital = (e) => {
        const {
            target: { value },
        } = e;
        setEdital(value)
    }
    const handleNumeroCiclo = (e) => {
        const {
            target: { value },
        } = e;

        setNumeroCiclo(value);

    }

    const handleStartDate = (value) => {

        setStartDate(value);

    }

    const handleEndDate = (value) => {
        setEndDate(value);

    }

    const handleFinish = async () => {
        try {
            const data = {
                num_ciclo: numeroCiclo,
                start: startDate,
                end: endDate,
                edital_id: edital
            }

            try {
                await api.post(`/oxetechwork/ciclo`, data);
                enqueueSnackbar('O ciclo foi criado com sucesso!', { variant: 'success' });
                handleClose()
                // window.location.reload();
              } catch (err) {
                console.log(err);
                if (err.response && err.response.status === 409) {
                  enqueueSnackbar('Esse ciclo já existe!', { variant: 'error' });
                } else {
                  enqueueSnackbar('Ocorreu um erro ao criar o ciclo.', { variant: 'error' });
                }
              }
        } catch (err) {
            console.log(err);
            enqueueSnackbar('Esse ciclo já existe!', { variant: 'error' });
        }
    }

    return (
        <Modal open={props.open}>

            <Box>
                <IconButton size='large' sx={{ display: "flex", float: "right" }} component="label" onClick={handleClose} >
                    <CloseIcon fontSize='large' sx={{ color: palette.profileWhiteShade }} />
                </IconButton>


                <Grid sx={styles.modal} container direction="row" >
                    <Grid container item xs={12} sm={12} md={12} lg={12}>
                        <Paper sx={[{ backgroundColor: palette.laranjaOXLTransparente, height: "50px" }, styles.paper]}>
                            <Typography sx={{ fontSize: "24px", color: palette.preto, fontFamily: "Poppins", fontWeight: 500 }}>Cadastro de Ciclo</Typography>
                        </Paper>
                    </Grid>

                    <Paper sx={styles.paper} >

                        <Grid container item xs={12} sm={12} md={12} lg={12}>
                            <Grid item xs={12} sm={12} md={6} lg={6} sx={styles.field}>
                                {"Número do ciclo"}
                                <TextField
                                    hiddenLabel
                                    id="nome-edital"
                                    variant="filled"
                                    fullWidth
                                    value={numeroCiclo}
                                    onChange={handleNumeroCiclo}
                                    onBlur={handleNumeroCiclo}
                                />
                            </Grid>

                            <Grid item xs={12} sm={12} md={6} lg={6} sx={styles.field}>
                                {"Edital"}
                                <FormControl variant="filled" sx={{ width: "100%" }}>
                                    <Select
                                        value={edital}
                                        onChange={handleEdital}
                                    >
                                        {props.editais.map((edital) => (
                                            <MenuItem
                                                key={edital.title}
                                                value={edital.id}

                                            >
                                                {edital.title}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>

                            </Grid>

                            <Grid item xs={12} sm={12} md={6} lg={6} sx={styles.field}>
                                {"Data de abertura"}
                                <LocalizationProvider locale={ptLocale} dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                        value={startDate}
                                        onChange={handleStartDate}
                                        renderInput={(params) => (
                                            <TextField
                                                variant="filled"
                                                fullWidth
                                                {...params}
                                                sx={styles.field}
                                            />
                                        )}
                                        minDate={today}
                                        inputFormat="dd/MM/yyyy"
                                    />
                                </LocalizationProvider>

                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} sx={styles.field}>
                                {"Data de encerramento"}
                                <LocalizationProvider locale={ptLocale} dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                        value={endDate}
                                        onChange={handleEndDate}
                                        renderInput={(params) => (
                                            <TextField
                                                variant="filled"
                                                fullWidth
                                                {...params}
                                                sx={styles.field}
                                            />
                                        )}
                                        minDate={startDate}
                                        inputFormat="dd/MM/yyyy"
                                    />
                                </LocalizationProvider>
                            </Grid></Grid>

                    </Paper >



                    <Button onClick={handleFinish} variant="contained" sx={{ height: "40px", bgcolor: palette.laranjaSecundario,
    "&:hover": {
      bgcolor: palette.laranjaIntermediario2
  }, marginTop: "5px" }} >Cadastrar ciclo</Button>
                </Grid>
            </Box >
        </Modal >)
}

export default ModalCadastroCiclo
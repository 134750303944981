import React from "react"
import * as palette from "../../../utils/styledVariables";
import { makeStyles, styled } from "@material-ui/core";
import { Button, TextField } from "@mui/material";

export const useStyles = makeStyles(() => ({
  modalContainer: {
    backgroundColor: "#EDE8EE",
    padding: "1rem",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-begin",
    borderRadius: "16px",
    height: "85%",
    width: "50%",
  },
  headerContainer: {
    backgroundColor: palette.laranjaOXLTransparente,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    borderRadius: "6px",
  },
  formContainer: {
    padding: "1rem",
    backgroundColor: palette.laranjaOXLTransparente,
    borderRadius: "6px",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-begin",
    marginTop: "1vh",
    marginBottom: "4vh",
  },
  inputsContainer: {
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-evenly",
  },
  requestError: {
    color: 'red',
    marginTop: '0.5rem',
  }
}));

export const SubmitButton = styled((props) => (
  <Button {...props} variant="contained" type="submit" />
))({
  borderRadius: "10px",
  margin: "2px",
  backgroundColor: palette.laranjaIntermediario2,
  "&:hover": {
    backgroundColor: palette.laranjaSecundario
},
});

const TextInputForm = styled((props) => (
  <TextField
    {...props}
    color="primary"
    fullWidth
    variant="outlined"
    size="small"
    // inputProps={{
    //   sx: { height: "2vh" },
    // }}
    // InputLabelProps={{
    //   sx: { display: "flex", justifyContent: ""}
    // }}
  />
))({});

export const TextInput = React.forwardRef((props, ref) => (
  <TextInputForm {...props} inputRef={ref} />
));
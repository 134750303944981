import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  Alert,
  Box,
  Button,
  Checkbox,
  Grid,
  Menu,
  MenuItem,
  Paper,
  Snackbar,
  Tooltip,
  Typography,
  styled,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { addDays, parseISO } from "date-fns";
import React, { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { useLab } from "../../hooks/useLab";
import api from "../../services/api";
import calcIncrease from "../../utils/calcIncrease";
import formatDate from "../../utils/formartDate";
import Options from "../../utils/options";
import Grafico from "./Grafico";
import MotivoMatriculaCancel from "./Modais/MotivoMatriculaCancel";
import ModalResposta from "./Modais/RespostaInscrito";
import TableCard from "./TableCard";
import { styles } from "./styles";
import { SnackbarProvider, useSnackbar } from "notistack";

const yellow = "rgba(255, 200, 4, 0.7)";

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  justifyItems: "center",
  color: theme.palette.text.secondary,
  borderRadius: "15px",
}));

function DashboardTurma() {
  const lab = useLab();
  const { id } = useParams();
  const history = useHistory();
  const [turma, setTurma] = useState({});
  const [inscritos, setInscritos] = useState([]);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [matriculaDeleteId, setMatriculaDeleteId] = useState(null);
  const [openMatriculaCancel, setOpenMatriculaCancel] = useState(false);
  const [atualizarTurma, setAtualizarTurma] = useState(false);
  const [matriculados, setMatriculados] = useState([]);
  const [checkedRowsSelection, setCheckedRowsSelection] = useState([]);
  const today = new Date();
  const posPrimeiroDiaDeAula = today > addDays(parseISO(turma.data_inicio), 7);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    const fetchTurmaEInscricoes = async () => {
      try {
        const resTurma = await api.get(`/turma/${id}`);
        setTurma(resTurma.data);

        const resInscricoes = await api.get(
          `/inscricoes-oxetechlab/turma/${id}`
        );
        setInscritos(resInscricoes.data);

        setAtualizarTurma(false);
      } catch (error) {
        setError(error.response.data.error);
      }
    };

    fetchTurmaEInscricoes();
  }, [success, atualizarTurma]);

  useEffect(() => {
    const fetchMatriculas = async () => {
      try {
        const response = await api.get(`/matriculas/turma/${id}`);
        setMatriculados(response.data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchMatriculas();
  }, [success, atualizarTurma]);

  const preSelecao = inscritos.filter((inscricao) => inscricao.status === "2");
  const emEspera = inscritos.filter((inscricao) => inscricao.status === "1");
  const temNaoSelecionados = inscritos.filter(
    (inscricao) => inscricao.status === "0"
  );
  const [lastAppliances, increase] = calcIncrease(inscritos);
  const habilidades = inscritos
    .map((inscricao) => {
      return inscricao.Aluno.skills?.map((skill) => skill.name);
    })
    .reduce((acc, subArray) => {
      return acc.concat(subArray);
    }, []);

  const [modalOpen, setModalOpen] = useState(false);
  const [verResposta, setVerResposta] = useState({
    perguntas: [],
    respostas: [],
  });

  const handleChangeStatus = async (e, id) => {
    const isChecked = e.target.checked;
    setCheckedRowsSelection((prevCheckedRows) =>
      isChecked
        ? [...prevCheckedRows, id]
        : prevCheckedRows.filter((rowId) => rowId !== id)
    );
    const value = isChecked ? 2 : 1;
    await api
      .patch(`inscricoes-oxetechlab/${id}/status/${value}`)
      .then((res) => {
        const updatedInscritos = inscritos.map((item) =>
          item.id == id ? { ...item, status: value } : item
        );
        setInscritos(updatedInscritos);
        setAtualizarTurma(true);
      })
      .catch((error) => {
        setError(error.response.data.error);
      });
  };
  const handleOpenModal = ({ perguntas, respostas }) => {
    setVerResposta({ perguntas, respostas });
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleCreateMatricula = (id) => {
    api
      .post(`matriculas/inscricoes/${id}`)
      .then((res) => {
        const updatedInscritos = inscritos.map((item) =>
          item.id == id ? { ...item, status: 3 } : item
        );
        setInscritos(updatedInscritos);
        setSuccess(res.data.message);
      })
      .catch((error) => {
        setError(error.response.data.error);
      });
  };
  const handleDeleteMatricula = (id) => {
    api
      .delete(`matriculas/delete?inscricao_id=${id}?`)
      .then((res) => {
        const updatedInscritos = inscritos.map((item) =>
          item.id === id ? { ...item, status: 3 } : item
        );
        setInscritos(updatedInscritos);
        setSuccess(res.data.message);
      })
      .catch((error) => {
        setError(error.response.data.error);
      });
  };
  // const handleEncerrarTurma = () => {
  //   history.
  // };
  const tabInscritos = {
    name: "Todas as inscrições",
    rows: inscritos.map((insc) => {
      return {
        id: insc.id,
        status: insc.status,
        perguntas: Object.keys(insc.perguntas_e_respostas),
        respostas: Object.values(insc.perguntas_e_respostas),
        inscricao: insc.id,
        nome: insc.Aluno.name,
        email: insc.Aluno.email,
        telefone: insc.Aluno.telefone,
        data_inscricao: formatDate(insc.createdAt),
      };
    }),
    columns: [
      {
        field: "status",
        headerName: "Matricular",
        width: 200,
        renderCell: (params) => {
          const opcoes = Options.inscricoesOXLStatuses.slice(0, -1);
          const isChecked = checkedRowsSelection.includes(params.id);
          return params.value !== "3" ? (
            <Checkbox
              checked={params.value === "2" ? true : isChecked}
              onChange={(e) =>
                turma.qtd_vagas_disponiveis === 0 && e.target.checked
                  ? setError("Todas as vagas já foram preenchidas.")
                  : handleChangeStatus(e, params.id)
              }
              sx={{
                "&.Mui-checked": {
                  color: "#D46D0D",
                },
              }}
            />
          ) : (
            // <Select
            //   labelId="demo-simple-select-label"
            //   id="demo-simple-select"
            //   value={params.value}
            //   label="Age"
            //   fullWidth
            //   style={
            //     params.value == 1
            //       ? { backgroundColor: yellow }
            //       : params.value == 0
            //       ? { backgroundColor: red }
            //       : { backgroundColor: green }
            //   }
            //   onChange={(e) => handleChangeStatus(e, params.id)}
            // >
            //   {opcoes.map((status, key) => (
            //     <MenuItem value={key}>{status}</MenuItem>
            //   ))}
            // </Select>
            Options.inscricoesOXLStatuses[params.value]
          );
        },
      },
      {
        field: "resposta",
        headerName: "Resposta",
        width: 80,
        renderCell: (params) => (
          <IconButton
            onClick={() =>
              handleOpenModal({
                perguntas: params.row.perguntas,
                respostas: params.row.respostas,
              })
            }
          >
            <VisibilityIcon />
          </IconButton>
        ),
      },
      {
        field: "inscricao",
        headerName: "N° da Inscrição",
        width: 200,
      },
      {
        field: "nome",
        headerName: "Nome",
        width: 350,
      },
      {
        field: "email",
        headerName: "E-mail",
        width: 250,
      },
      {
        field: "telefone",
        headerName: "Telefone",
        width: 250,
      },
      {
        field: "data_inscricao",
        headerName: "Data da Inscrição",
        width: 250,
      },
    ],
  };
  const tabSelecionados = {
    name: "Lista de pré-seleção",
    rows: preSelecao?.map((insc) => {
      return {
        id: insc.id,
        status: insc.status,
        inscricao: insc.id,
        nome: insc.Aluno.name,
        email: insc.Aluno.email,
        telefone: insc.Aluno.telefone,
        data_inscricao: formatDate(insc.createdAt),
      };
    }),
    columns: [
      {
        field: "nome",
        headerName: "Nome",
        width: 350,
      },
      {
        field: "inscricao",
        headerName: "N° da Inscrição",
        width: 150,
      },
      {
        field: "email",
        headerName: "E-mail",
        width: 250,
      },
      {
        field: "telefone",
        headerName: "Telefone",
        width: 250,
      },
      {
        field: "data_inscricao",
        headerName: "Data de Inscrição",
        width: 250,
      },
      {
        field: "data_matricula",
        headerName: "Data de Matrícula",
        width: 250,
      },
    ],
  };

  const tabMatriculados = {
    name: "Matriculados",
    rows: matriculados?.map((insc) => {
      return {
        id: insc.id,
        status: insc.status,
        inscricao: insc.id_inscricao,
        nome: insc.aluno,
        email: insc.email,
        telefone: insc.telefone,
        data_matricula: formatDate(insc.createdAt),
      };
    }),
    columns: [
      {
        field: "status",
        headerName: "Status da matrícula",
        width: 200,
        renderCell:
          lab.isCoordenador || lab.super_agente
            ? (params) => (
                <Tooltip
                  title={
                    posPrimeiroDiaDeAula
                      ? "Você não pode desmatricular alunos após o primeiro dia útil de aula."
                      : ""
                  }
                  placement="top"
                >
                  <Button
                    variant="contained"
                    // disabled={posPrimeiroDiaDeAula}
                    startIcon={<CheckIcon />}
                    onClick={() => {
                      if (!posPrimeiroDiaDeAula) {
                        setMatriculaDeleteId(params.row.inscricao);
                        setOpenMatriculaCancel(true);
                        // handleDeleteMatricula(params.row.inscricao);
                      }
                    }}
                    sx={{
                      backgroundColor: !posPrimeiroDiaDeAula ? "green" : "gray",
                      color: "white",
                      "&:hover": {
                        backgroundColor: !posPrimeiroDiaDeAula ? "red" : "gray", // Mudando para vermelho quando hover
                        "& span": {
                          display: "none", // Escondendo o texto atual
                        },
                        "&:before": {
                          content: '"Cancelar Matrícula"', // Adicionando o novo texto
                          display: "block",
                        },
                      },
                    }}
                  >
                    <span>{"Matriculado"}</span>
                  </Button>
                </Tooltip>
              )
            : () => "Matriculado",
      },
      {
        field: "nome",
        headerName: "Nome",
        width: 350,
      },
      {
        field: "inscricao",
        headerName: "N° da Inscrição",
        width: 150,
      },
      {
        field: "email",
        headerName: "E-mail",
        width: 250,
      },
      {
        field: "telefone",
        headerName: "Telefone",
        width: 250,
      },
      {
        field: "data_matricula",
        headerName: "Data da Matrícula",
        width: 250,
      },
    ],
  };

  const tabEmEspera = {
    name: "Em espera",
    rows: emEspera.map((insc) => {
      return {
        id: insc.id,
        status: insc.status,
        perguntas: Object.keys(insc.perguntas_e_respostas),
        respostas: Object.values(insc.perguntas_e_respostas),
        inscricao: insc.id,
        nome: insc.Aluno.name,
        email: insc.Aluno.email,
        telefone: insc.Aluno.telefone,
        data_inscricao: formatDate(insc.createdAt),
      };
    }),
    columns: [
      {
        field: "status",
        headerName: "Status",
        width: 200,
        renderCell:
          lab.isCoordenador || lab.super_agente
            ? (params) => (
                <Tooltip
                  title={
                    posPrimeiroDiaDeAula
                      ? "Você não pode matricular novos inscritos após o primeiro dia útil de aula."
                      : ""
                  }
                  placement="top"
                >
                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: !posPrimeiroDiaDeAula ? yellow : "gray",
                      color: "white",
                      "&:hover": {
                        backgroundColor: !posPrimeiroDiaDeAula
                          ? yellow
                          : "gray",
                      },
                    }}
                    onClick={() => {
                      if (!posPrimeiroDiaDeAula) {
                        turma.qtd_vagas_disponiveis === 0
                          ? setError("Todas as vagas já foram preenchidas.")
                          : handleCreateMatricula(params.row.inscricao);
                      }
                    }}
                  >
                    {"Matricular"}
                  </Button>
                </Tooltip>
              )
            : () => "Em espera",
      },
      {
        field: "resposta",
        headerName: "Resposta",
        width: 80,
        renderCell: (params) => (
          <IconButton
            onClick={() =>
              handleOpenModal({
                perguntas: params.row.perguntas,
                respostas: params.row.respostas,
              })
            }
          >
            <VisibilityIcon />
          </IconButton>
        ),
      },
      {
        field: "inscricao",
        headerName: "N° da Inscrição",
        width: 200,
      },
      {
        field: "nome",
        headerName: "Nome",
        width: 350,
      },
      {
        field: "email",
        headerName: "E-mail",
        width: 250,
      },
      {
        field: "telefone",
        headerName: "Telefone",
        width: 250,
      },
      {
        field: "data_inscricao",
        headerName: "Data da Inscrição",
        width: 250,
      },
    ],
  };
  
  const naoSelecionados = {
    name: "Não Selecionados",
    rows: temNaoSelecionados.map((insc) => {
      return {
        id: insc.id,
        status: insc.status,
        inscricao: insc.id,
        nome: insc.Aluno.name,
        email: insc.Aluno.email,
        telefone: insc.Aluno.telefone,
        data_inscricao: formatDate(insc.createdAt),
      };
    }),
    columns: [
      {
        field: "inscricao",
        headerName: "N° da Inscrição",
        width: 200,
      },
      {
        field: "nome",
        headerName: "Nome",
        width: 350,
      },
      {
        field: "email",
        headerName: "E-mail",
        width: 250,
      },
      {
        field: "telefone",
        headerName: "Telefone",
        width: 250,
      },
      {
        field: "data_inscricao",
        headerName: "Data da Inscrição",
        width: 250,
      },
    ],
  };

  const tables = [tabInscritos, tabSelecionados];
  const tablesTurmaAtePrimeiroDia = [tabMatriculados, tabEmEspera];
  const tablesTurmaIniciada = [tabMatriculados,naoSelecionados];
  const encerramentoInscricoesData =
    turma.data_encerramento_inscricoes?.slice(8, 10) +
    "-" +
    turma?.data_encerramento_inscricoes?.slice(5, 7) +
    "-" +
    turma?.data_encerramento_inscricoes?.slice(0, 4);
  const resultadoSelecaoData =
    turma.data_resultado?.slice(8, 10) +
    "-" +
    turma.data_resultado?.slice(5, 7) +
    "-" +
    turma.data_resultado?.slice(0, 4);
  const inicioAulasData =
    turma.data_inicio?.slice(8, 10) +
    "-" +
    turma.data_inicio?.slice(5, 7) +
    "-" +
    turma.data_inicio?.slice(0, 4);


  return (
    <Grid container spacing={1}>
      <Grid item xs={12} md={12}>
        <Item sx={styles.titleCaixa}>
          <div>
            <h3 style={styles.title}>{turma?.titulo}</h3>
            <div style={{ display: "flex", width: "120%" }}>
              <h6 style={styles.subtitle}>
                Encerramento das inscrições:{" "}
                <strong>{encerramentoInscricoesData}</strong>/
              </h6>
              <h6 style={styles.subtitle}>
                Resultado da seleção: <strong>{resultadoSelecaoData}</strong>/
              </h6>
              <h6 style={styles.subtitle}>
                Início das aulas: <strong>{inicioAulasData}</strong>
              </h6>
            </div>
          </div>
          {turma.status === "2" ? (
            <div>
              <Tooltip title="Mais">
                <IconButton
                  aria-label="more"
                  id="long-button"
                  aria-controls={open ? "long-menu" : undefined}
                  aria-expanded={open ? "true" : undefined}
                  aria-haspopup="true"
                  onClick={handleClick}
                >
                  <MoreVertIcon style={{ color: "white" }} />
                </IconButton>
              </Tooltip>
              <Menu
                id="long-menu"
                MenuListProps={{
                  "aria-labelledby": "long-button",
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                PaperProps={{
                  style: {
                    width: "auto",
                  },
                }}
              >
                {/* {console.log('lab',lab)} */}
                <Link
                  to={`/laboratorio/${lab.lab_id}/turma-iniciada-painel/${turma.id}`}
                  style={{ color: "black" }}
                >
                  <MenuItem
                  // onClick={handleEncerrarTurma}
                  >
                    Painel Principal
                  </MenuItem>
                </Link>
                <Link
                  to={`/perfil-laboratorio/${lab.lab_id}`}
                  style={{ color: "black" }}
                >
                  <MenuItem>Fechar</MenuItem>
                </Link>
              </Menu>
            </div>
          ) : (
            <div onClick={() => history.goBack()} style={{ cursor: "pointer" }}>
              <Tooltip title="Sair">
                <CloseIcon />
              </Tooltip>
            </div>
          )}
        </Item>
      </Grid>
      <Grid container direction="row" spacing={1} item xs={12} sm={6} md={3}>
        <Grid item xs={12}>
          <Item sx={styles.card1}>
            <div>
              {inscritos.length}
              <Typography style={styles.subtext}>inscrições</Typography>
            </div>
          </Item>
        </Grid>
        <Grid item xs={12}>
          <Item sx={styles.card1}>
            <h6>Vagas</h6>
            <div
              style={{
                display: "flex",
                justifyContent: "space-around",
                width: "100%",
              }}
            >
              <div>
                <h6>{turma.qtd_vagas_total}</h6>
                <Typography style={{ ...styles.subtext, fontSize: "15px" }}>
                  Total
                </Typography>
              </div>
              <div>
                <h6
                  style={{
                    color:
                      turma.qtd_vagas_total === turma.qtd_vagas_preenchidas
                        ? "red"
                        : "black",
                  }}
                >
                  {turma.qtd_vagas_disponiveis}
                </h6>
                <Typography style={{ ...styles.subtext, fontSize: "15px" }}>
                  Disponíveis
                </Typography>
              </div>
              <div>
                <h6
                  style={{
                    color:
                      turma.qtd_vagas_total === turma.qtd_vagas_preenchidas
                        ? "orange"
                        : "black",
                  }}
                >
                  {turma.qtd_vagas_preenchidas}
                </h6>
                <Typography style={{ ...styles.subtext, fontSize: "15px" }}>
                  Preenchidas
                </Typography>
              </div>
            </div>
          </Item>
        </Grid>
      </Grid>

      <Grid item xs={12} sm={6} md={3}>
        <Item sx={styles.card2}>
          <Box sx={styles.textContent}>
            <Box
              sx={{
                display: "flex",
                "justify-content": "center",
                "align-items": "center",
              }}
            >
              <KeyboardDoubleArrowUpIcon
                fontSize="inherit"
                sx={{
                  "justify-content": "center",
                  "align-items": "center",
                }}
              />
              {increase}%{/* 0% */}
            </Box>
            <Typography sx={styles.subtext}>
              +{lastAppliances.length} inscritos
              <br />
              na última semana
            </Typography>
          </Box>
        </Item>
      </Grid>

      <Grid item xs={12} sm={12} md={6}>
        <Item sx={styles.card4}>
          <Grafico habilidades={habilidades} />
        </Item>
      </Grid>

      <Grid item xs={12}>
        {turma.status === "1" ? (
          <TableCard
            tables={tables}
            turma={turma}
            permissao={lab.isCoordenador || lab.super_agente}
            turmaIniciada={false}
          />
        ) : temNaoSelecionados?.length > 0 ? (
          <TableCard
            tables={tablesTurmaIniciada}
            turma={turma}
            permissao={false}
            turmaIniciada={true}
          />
        ) : (
          <TableCard
            tables={tablesTurmaAtePrimeiroDia}
            turma={turma}
            permissao={lab.isCoordenador || lab.super_agente}
            turmaIniciada={true}
          />
        )}
      </Grid>
      <ModalResposta
        open={modalOpen}
        handleClose={handleCloseModal}
        perguntas={verResposta.perguntas}
        respostas={verResposta.respostas}
      />
      <SnackbarProvider>
        <MotivoMatriculaCancel
          open={openMatriculaCancel}
          handleClose={() => setOpenMatriculaCancel(false)}
          inscricaoId={matriculaDeleteId}
        />
      </SnackbarProvider>

      <Snackbar
        open={success.length > 0}
        autoHideDuration={7000}
        onClose={() => setSuccess("")}
      >
        <Alert
          variant="filled"
          onClose={() => setSuccess("")}
          severity="success"
          sx={{ width: "100%" }}
        >
          {success}
        </Alert>
      </Snackbar>
      <Snackbar
        open={error.length > 0}
        autoHideDuration={7000}
        onClose={() => setError("")}
      >
        <Alert
          variant="filled"
          onClose={() => setError("")}
          severity="error"
          sx={{ width: "100%" }}
        >
          {error}
        </Alert>
      </Snackbar>
    </Grid>
  );
}

export default DashboardTurma;

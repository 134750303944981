import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import * as React from "react";


import { Caixa, Item, Texto } from "./styles";

import SobreNosFig1 from "../../../src/assets/lampada.png";
import fapealLogo from "../../../src/assets/SobreNos/fapeal.svg";
// import laccanLogo from '../../../src/assets/SobreNos/laccan.svg'
import laccanLogo from "../../../src/assets/SobreNos/laccan_white.svg";
// import sectiLogo from '../../../src/assets/SobreNos/secti2.svg'
import sectiLogo from "../../../src/assets/secti_logo_2023.png";
import ufalLogo from "../../../src/assets/SobreNos/ufal.svg";

import { gradienteLaranjaInvertido } from "../../utils/styledVariables";
import "./styles.css";

export default function BasicGrid() {
  const logos = [
    {
      src: laccanLogo,
      alt: "Logo da Laccan",
      href: "https://sites.google.com/laccan.ufal.br/laccan",
      width: "80%",
    },
    {
      src: fapealLogo,
      alt: "Logo da fapeal",
      href: "https://www.fapeal.br/",
      width: "80%",
    },
    {
      src: ufalLogo,
      alt: "Logo da ufal",
      href: "https://ufal.br/",
      width: "60%",
    },
    // {src: dio, alt: "Logo da DIO",href: "https://www.dio.me/",width:"70%"},
  ];
  return (
    <Caixa
      style={{
        background: gradienteLaranjaInvertido,
        // 'radial-gradient(125.48% 125.19% at 73.24% 7.03%, rgba(142, 36, 170, 0.7) 0%, rgba(0, 0, 99, 0.7) 100%)'
        // marginTop: '3px'
      }}
      sx={{ flexGrow: 1 }}
    >
      <Grid container className="caixa">
        <Grid item xs={12} sm={12} md={12} lg={6}>
          <Grid
            container
            spacing={2}
            direction="column"
            alignItems="center"
            justifyContent="center"
            padding="2%"
          >
            <Item>
              <h2 className="titulo">Sobre nós</h2>
            </Item>
            <Texto>
              <p>
                Alagoas e seus Municípios têm demonstrado expressivo potencial
                não só em seu fator de crescimento econômico, como também em
                relação ao seu mercado de tecnologia e inovação. Assim, nasce
                como um programa que tem por meta, abraçar o cenário atual, e
                incentivar o movimento de capacitação técnica e profissional
                para o povo alagoano.
              </p>
              <p>
                A Plataforma OxeTech foi desenvolvida através de uma parceria
                entre o governo do estado de Alagoas, por intermédio da
                Secretaria Estadual da Ciência, da Tecnologia e da Inovação, e a
                Universidade Federal de Alagoas, interposto pelo Laboratório de
                Computação Científica e Análise Numérica (LaCCAN).
              </p>
            </Texto>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={6}>
          <Grid
            container
            direction="column"
            alignItems="center"
            justifyContent="center"
            style={{ height: "auto" }}
          >
            <div style={{ display: "flex", justifyContent: "center" }}>
              <img src={SobreNosFig1} className="img" alt="SobreNosFig1" />
            </div>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        direction="column"
        alignItems="center"
        style={{
          marginTop: "20px",
          marginBottom: "20px",
          // borderTop: '1px solid #6e24bf',
          // borderBottom: '1px solid #6e24bf'
        }}
      >
        <a
          href="http://www.secti.al.gov.br/"
          target="_blank"
          className="logos"
          rel="noreferrer"
        >
          <Box
            component="img"
            src={sectiLogo}
            sx={{ width: { xs: "80vw", md: "60vw", lg: "40vw" } }}
            // height="250"

            alt="Logo da Secti-AL"
          />
        </a>
      </Grid>
      <Grid container className="logos" style={{}}>
        {logos.map((logo, i) => {
          return (
            <Grid item xl={2} lg={2} md={6} sm={6} xs={6} key={i}>
              <a
                href={logo.href}
                target="_blank"
                className="logos"
                rel="noreferrer"
              >
                <img src={logo.src} alt={logo.alt} className="imgs" />
              </a>
            </Grid>
          );
        })}
      </Grid>
    </Caixa>
  );
}

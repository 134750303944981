import React, { useState, useRef } from "react";

import {
  Avatar,
  Modal,
  Paper,
  Typography,
  useMediaQuery,
  IconButton,
  Button,
  TextField
} from '@mui/material';

import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

import {
  useStyles,
  InputTitle,
  UploadContainer,
  SubmissionButton,
} from './styles';


export const ModalCriarAtividade = ({ atividades, open, close, addAtividade }) => {
  const classes = useStyles();
  const isSmallScreen = useMediaQuery('(max-width:600px)');

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");

  const handleChangeName = (event) => setName(event.target.value);
  const handleChangeDescription = (event) => setDescription(event.target.value);

  const handleSubmit = () => {
    addAtividade({titulo: name, descricao: description, concluida: false});
    close();
  }

  const fileInputRef = useRef(null);
  const [dragOver, setDragOver] = useState(false);
  const [fileName, setFileName] = useState(null);

  const handleDragOver = (event) => {
    event.preventDefault();
    setDragOver(true);
  };

  const handleDragLeave = (event) => {
    event.preventDefault();
    setDragOver(false);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    setDragOver(false);
    const file = event.dataTransfer.files[0];
    setFileName(file.name);
    // Process the file here (e.g., upload to server)
    console.log('File dropped:', file);
  };

  const handleFileInputChange = (event) => {
    const file = event.target.files[0];
    setFileName(file.name);
    // Process the file here (e.g., upload to server)
    console.log('File selected:', file);
  };

  return (
    <Modal
      open={open}
      onClose={close}
    >
      <Paper elevation={6} className={classes.modalContainer}>
        <div className={classes.goBackContainer}>
          <IconButton className={classes.goBackButton} color="#D56D0D2E" onClick={close}>
            <ArrowBackIosNewIcon sx={{color: "#D46D0D"}}/>
          </IconButton>
        </div>
        <div className={classes.modalHeader}>
          <div className={classes.iconAndTitleContainer}>
            <Avatar
              className={classes.icon}
              alt={'Ícone de edição de atividade'}
            >
                <NoteAddIcon className={classes.iconImg} />
            </Avatar>
            <Typography
              variant='h5'
              align='left'
              className={classes.title}
              style={{ fontSize: isSmallScreen ? '3.0vh' : '5.0vh' }}
              >
                Criar Atividade
            </Typography>
          </div>
        </div>
        <form action="" className={classes.formContainer}>
            <div  className={classes.inputContainer}>
              <InputTitle >Titulo</InputTitle>
              <TextField 
                fullWidth 
                value={name}
                onChange={handleChangeName}
              />
            </div>
            <div className={classes.inputContainer}>
              <InputTitle >Instruções</InputTitle>
              <TextField 
                multiline 
                rows={8} 
                fullWidth 
                value={description}
                onChange={handleChangeDescription}
              />
            </div>
            <div className={classes.inputContainer}>
              <InputTitle >Anexos</InputTitle>
              <UploadContainer
                onDragOver={handleDragOver}
                onDragLeave={handleDragLeave}
                onDrop={handleDrop}
                onClick={() => {
                  fileInputRef.current.click();
                }}
                style={{ backgroundColor: dragOver ? '#f5f5f5' : 'transparent', marginBottom: '1rem' }}
              >
                <input
                  type="file"
                  accept="application/pdf"
                  style={{ display: 'none' }}
                  onChange={handleFileInputChange}
                  ref={fileInputRef}
                />
                <CloudUploadIcon sx={{width: '30%', height: '50%', color: '#D46D0DB5'}} />
                <Typography variant='h4'>Arraste sua atividade aqui!</Typography>
              </UploadContainer>
                {fileName &&
                  <>
                    <Typography variant='h6'>{fileName}</Typography>
                    <Button onClick={() => setFileName(null)}>Cancelar upload</Button>
                  </>
                }
            </div>
          <div className={classes.inputContainer}>
            <InputTitle >Data de entrega</InputTitle>
            <TextField fullWidth />
          </div>
          <div className={classes.submissionContainer}>
            <SubmissionButton 
              // disabled={fileName === null} 
              // type="submit"
              onClick={handleSubmit}
            >
              Criar
            </SubmissionButton>
          </div>
        </form>
      </Paper>
    </Modal>
  );
};
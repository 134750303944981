import styled from 'styled-components';

export const WrapperSider = styled.div`
  height: 100vh;

  display: flex;
  align-items: stretch;
`;

export const WrapperHeader = styled.div`
  height: auto;
  /* background: linear-gradient(-90deg, #09142b, #203358); */
  overflow: hidden;
`;

export const ContentSider = styled.div`
  display: flex;
  flex-direction: column;
  place-content: center;
  align-items: center;

  width: 20vw;
  max-width: 390px;
`;

export const ContentHeader = styled.div`
  width: 100vw;
  /* max-width: 390px; */
`;

export const Background = styled.div`
  flex: 1;
`;

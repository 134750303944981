import { message } from "antd";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Redirect, Route, useLocation } from "react-router-dom";
import DefaultLayout from "../pages/_layouts/default";
import Error from "../pages/Error";
import { useAuth } from "../hooks/auth/AuthContext";

import api from "../services/api";
import { store } from "../store";

export default function RouteWrapper({
  component: Component,
  isLab,
  isEdu,
  ...rest
}) {
  const { signed, token } = store.getState().auth;
  const profile_labs = useSelector((state) => state.user.labs_profile);
  const profile = useSelector((state) => state.user.profile);
  const location = useLocation();
  const { logout} = useAuth();

  const listNotAllowedEmpresa = [
    '/trilha',
    '/oxetrilha',
    '/account'
  ]

  useEffect(() => {
    if (signed) {
      const payload = JSON.parse(atob(token.split(".")[1]));
      const currentTime = Math.round(new Date().getTime() / 1000);
      const expirationTime = payload.exp;
      const leeway = 600;
      
      if (currentTime - leeway - expirationTime > 1) {
        message.warn("Tempo de sessão expirou!");
        logout();
      }
    }
  }, [signed, token]);

  if(!signed){
    return <Redirect to="/" />;
  }
  //permissao perfil de lab, edu e empresa
  if (
    (isLab && profile_labs === null) ||
    (isEdu && !profile.escola_oxetech_edu)
  ) {
    return <Redirect to="/dashboard" />;
  }
  
  //rotas que empresa não pode entrar
    if (profile.cnpj && listNotAllowedEmpresa.includes(location.pathname)) {
      return <Redirect to="/dashboard" />;
    }

  return (
    <Route
      {...rest}
      render={(props) => (
        <DefaultLayout>
          <Component {...props} />
        </DefaultLayout>
      )}
    />
  );
}

RouteWrapper.propTypes = {
  isLab: PropTypes.bool,
  isEdu: PropTypes.bool,
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.func])
    .isRequired,
};

RouteWrapper.defaultProps = {
  isLab: false,
  isEdu: false,
};

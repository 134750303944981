import React from 'react'
import 'animate.css'
import { Box } from '@mui/system'
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import Header from '../../components/HeaderOxeTechWork'
import Rodape from '../../components/Rodape'

import Aluno from './Aluno'
import Empresa from './Empresa'


import { createTheme, ThemeProvider } from '@mui/material/styles'
const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 800,
      md: 960,
      lg: 1280,
      xl: 1920
    }
  }
})

function Main() {
  const [perfilSelected, setPerfilSelected] = React.useState("Empresa");

  function handlePerfilSelected(e){
    setPerfilSelected(e.target.value)
  }

  return (
    <ThemeProvider theme={theme}>
      <Box >
        <Header />
        <Box sx={{display: 'flex', justifyContent: 'flex-end', mt: 2, mr: 6}}>
          <Select
            value={perfilSelected}
            label="Alunos Inscritos"
            onChange={handlePerfilSelected}
          >
            <MenuItem value={'Aluno'}>Alunos</MenuItem>
            <MenuItem value={'Empresa'}>Empresas</MenuItem>
          </Select>
        </Box>
        {
          perfilSelected === "Empresa" ? <Empresa /> : <Aluno />
        }
        <Rodape />
      </Box>
    </ThemeProvider>
  )
}

export default Main

import { makeStyles } from "@material-ui/styles";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import BadgeIcon from "@mui/icons-material/Badge";
import InfoIcon from "@mui/icons-material/Info";
import LogoutIcon from "@mui/icons-material/Logout";
import PersonIcon from "@mui/icons-material/Person";
import SaveIcon from "@mui/icons-material/Save";
import WorkIcon from "@mui/icons-material/Work";
import Avatar from "@mui/material/Avatar";
import Badge from "@mui/material/Badge";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import LinkMui from "@mui/material/Link";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import { Tooltip } from "antd";
import { SnackbarProvider, useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { updateProfileEmpresaRequest } from "../../store/modules/user/actions";
import * as palette from "../../utils/styledVariables";
import Facebook from "./Facebook";
import Instagram from "./Instagram";
import Linkedin from "./Linkedin";
import Municipio from "./Municipio";

import Name from "./Name";
// import talento from "../../assets/bancoTalentos.svg"
import MilitaryTechIcon from "@mui/icons-material/MilitaryTech";
import EmAnaliseOTW from "../../assets/EmAnaliseOTW.svg";
import oficialOTW from "../../assets/oficialOTW.svg";
import { useAuth } from "../../hooks/auth/AuthContext";
import api from "../../services/api";
import {
  laranjaIntermediario2,
  laranjaSecundario,
} from "../../utils/styledVariables";

const drawerWidth = 240;

const useStyles = makeStyles({
  paper: {
    position: "static !important",
    backgroundColor: laranjaSecundario,
    // backgroundColor: "rgb(142 36 170) !important",
  },
  "@global": {
    "*::-webkit-scrollbar": {
      width: "0.4em",
    },
    "*::-webkit-scrollbar-track": {
      "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "*::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0,0,0,.3)",
    },
  },
  thumb: {
    color: "#e9e9e9",
  },
  rail: {
    boxShadow: "0px 4px 6px rgb(0 0 0)",
  },
  ListPadding: {
    padding: "0 !important",
  },
  SliderRoot: {
    padding: "7px 0 !important",
  },
  underlineNone: {
    "&:hover": {
      color: "red",
    },
  },
});

function Sidebar({
  onMobile,
  collapsed,
  setCollapsed,
  setOpenModal,
  setCloseModal,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const [mobileOpen, setMobileOpen] = useState(false);
  const [dataChanged, setDataChanged] = useState(false);
  let profile = useSelector((state) =>
    state.user.profile.dataValues
      ? state.user.profile.dataValues
      : state.user.profile
  );

  const [avatarUrl, setAvatarUrl] = useState(null);
  const [newFileAvatar, setNewFileAvatar] = useState(null);

  const location = useLocation();

  const dispatch = useDispatch();
  const { logout } = useAuth();
  
  function handleSignOut() {
    logout();
  }

  const classes = useStyles();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleDrawerCollapsed = () => {
    setCollapsed(!collapsed);
  };

  const routes = [
    { name: "Vagas", link: "/dashboard", icon: WorkIcon },
    { name: "Dados da empresa", link: "/empresa-account", icon: PersonIcon },
    { name: "OxeTech Work", link: "/oxe-tech-work", icon: BadgeIcon },
    {
      name: "Banco de Talentos",
      link: "/bancotalentos",
      icon: MilitaryTechIcon,
    },
  ];

  useEffect(() => {
    profile.dataChanged = false;

    setInterval(() => {
      if (profile.dataChanged) {
        setDataChanged(profile.dataChanged);
      }
    }, 500);
  }, []);

  useEffect(async () => {
    try {
      const response = await api.get("/empresa/avatar", {
        responseType: "arraybuffer",
      });
      const imgUrl = URL.createObjectURL(
        new Blob([response.data], { type: "image" })
      );
      setAvatarUrl(imgUrl);
    } catch (err) {
      console.log(err);
    }
  }, []);

  function saveChanges() {
    if (profile.razao_social.length < 5) {
      enqueueSnackbar("O nome precisa ter 5 caracteres no mínimo!", {
        variant: "error",
      });
      return;
    }
    if (newFileAvatar) {
      const formData = new FormData();
      formData.append("imageFile", newFileAvatar);
      api
        .patch("/empresa/postavatar", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          if (res.data.message) {
            profile.avatar_url = res.data.message;
            delete profile.cnpj;
            dispatch(updateProfileEmpresaRequest(profile));
          }
        });
    } else {
      delete profile.cnpj;
      dispatch(updateProfileEmpresaRequest(profile));
    }
  }

  function getName() {
    let name;
    if (profile.razao_social) {
      name =
        profile.razao_social === ""
          ? ""
          : profile.razao_social[0].toUpperCase();
    }
    return name;
  }

  const handleChangeAvatar = (e) => {
    profile.dataChanged = true;
    const file = e.target.files[0];
    if (file) {
      setNewFileAvatar(file);
      setAvatarUrl(URL.createObjectURL(file));
    }
  };

  const SmallAvatar = styled(Avatar)(({ theme }) => ({
    width: 20,
    height: 20,
    // border: `0.5px solid rgba(22, 9, 178, 0.79)`,
  }));

  const filteredRoutes = routes.filter(
    (route, index) => !(index === routes.length - 1 && !profile.oxetech_work)
  );
  const drawer = (
    <div style={{ backgroundColor: laranjaSecundario }}>
      {/* <Tooltip title={"Essa empresa faz parte do OxetechWork"}>
        <img src={logoOTW} alt="Essa empresa faz parte do OxetechWork" width="40%" />
      </Tooltip> */}
      <Box sx={{ position: "relative" }}>
        <Box
          sx={{
            position: "absolute",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Tooltip title={"Salvar alterações"}>
            <IconButton
              sx={{
                display: dataChanged ? "flex" : "none",
                color: "white",
              }}
              onClick={() => saveChanges()}
            >
              <SaveIcon fontSize={"large"} />
            </IconButton>
          </Tooltip>
          <IconButton
            sx={{
              display: dataChanged ? "none" : "flex",
              color: "white",
            }}
            onClick={setOpenModal}
          >
            <InfoIcon fontSize={"medium"} />
          </IconButton>
        </Box>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Badge
          overlap="circular"
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          badgeContent={
            profile.status_oxetech_work === "Validado" ? (
              <Tooltip
                color="purple"
                title={
                  <span style={{ textAlign: "center" }}>
                    Essa empresa faz parte do OxeTech Work.
                  </span>
                }
              >
                <SmallAvatar alt="Oficial OxetechWork" src={oficialOTW} />
              </Tooltip>
            ) : profile.status_oxetech_work === "Em análise" ? (
              <Tooltip
                color="yellow"
                title={
                  <span style={{ textAlign: "center", color: "black" }}>
                    Sua empresa está em análise para entrar no programa OxeTech
                    Work.{" "}
                  </span>
                }
              >
                <SmallAvatar alt="Oficial OxetechWork" src={EmAnaliseOTW} />
              </Tooltip>
            ) : (
              <></>
            )
          }
        >
          <div>
            <input
              accept="image/*"
              style={{ display: "none", backgroundColor: "orange" }}
              id="raised-button-file"
              type="file"
              name="imageFile"
              onChange={handleChangeAvatar}
            />
            <label htmlFor="raised-button-file">
              <IconButton
                variant="text"
                component="span"
                sx={{
                  mt: 1,
                  ml: 3,
                  mr: 3,
                  width: "100px",
                  height: "100px",
                }}
              >
                <Tooltip arrow placement="right" title="Clique para alterar">
                  <Avatar
                    src={avatarUrl}
                    sx={{
                      mt: 1,
                      ml: 3,
                      mr: 3,
                      width: "100px",
                      height: "100px",
                      fontSize: "48px",
                    }}
                  >
                    {getName()}
                  </Avatar>
                </Tooltip>
              </IconButton>
            </label>
          </div>
        </Badge>
      </Box>
      <Name />

      <Box
        sx={{
          ml: 2,
        }}
      >
        {/* <OcupacaoSelect /> */}
        <Municipio />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          justifyContent: "center",
        }}
      >
        <Instagram />
        <Linkedin />
        <Facebook />
      </Box>
      {/* <Divider
        variant="middle"
        sx={{
          mt: 1.5,
          mb: 0,
          borderColor: "rgb(153 77 173)",
        }}
      /> */}
      <List classes={{ padding: classes.ListPadding }}>
        {filteredRoutes.map((route, index) => (
          <ListItem
            selected={location.pathname === route.link}
            dense
            component={Link}
            to={route.link}
            key={index}
            disablePadding
          >
            <ListItemButton>
              <ListItemIcon sx={{ minWidth: "30px", color: "#FFFFFF" }}>
                <route.icon />
              </ListItemIcon>
              <ListItemText sx={{ color: "#FFFFFF" }} primary={route.name} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      {/* <Divider
        variant="middle"
        sx={{
          p: 0.2,
          borderColor: "rgb(153 77 173)",
        }}
      /> */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          mx: 2,
          bottom: "16%",
          position: "absolute",
          borderRadius: "5px",
          height: "40px",
          width: "80px",
          boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.25)",
          bgcolor: palette.laranjaOXLTransparente,
          // outline: `1px solid ${palette.profileMainBrightColor}`
        }}
      >
        <LinkMui
          to="#"
          sx={{
            display: "flex",
            alignItems: "center",
          }}
          variant="h6"
          classes={{ underlineNone: classes.underlineNone }}
          underline="none"
          color="#FFFFFF"
          component={Link}
          onClick={handleSignOut}
        >
          <LogoutIcon
            sx={{
              mr: 1,
              fontSize: "1.5rem",
            }}
          />
          <Typography>Sair</Typography>
        </LinkMui>
      </Box>
    </div>
  );

  return (
    <Box
      sx={{ backgroundColor: laranjaSecundario, position: "fixed", zIndex: 999 }}
    >
      <Box
        sx={{
          display: { xs: "flex", sm: "none" },
          position: "relative",
        }}
      >
        <Box sx={{ position: "absolute" }}>
          <IconButton
            size="small"
            sx={{
              mt: 1,
              left: "15px",
              color: "white",
              backgroundColor: mobileOpen ? "inherit" : laranjaIntermediario2,
            }}
            onClick={handleDrawerToggle}
          >
            <ArrowForwardIcon />
          </IconButton>
        </Box>
      </Box>
      <Box
        sx={{
          backgroundColor: laranjaSecundario,
          position: "relative",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {!onMobile && (
          <Box
            sx={{
              left: !collapsed ? "calc(100% - 35px)" : "",
              position: "absolute",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <IconButton
              id="esse"
              size="small"
              sx={{
                mt: 1,
                ml: collapsed ? 0.5 : -0.5,
                color: "white",
                backgroundColor: !collapsed ? "inherit" : laranjaIntermediario2,
                "&:hover": {
                  backgroundColor: laranjaSecundario,
                  boxShadow: "5"
              }
              }}
              onClick={handleDrawerCollapsed}
            >
              {!collapsed ? <ArrowBackIcon /> : <ArrowForwardIcon />}
            </IconButton>
          </Box>
        )}
        <Box sx={{ display: "flex", height: "100vh" }}>
          <Collapse orientation="horizontal" in={!collapsed}>
            <Box
              component="nav"
              sx={{
                backgroundColor: "#8e24aa",
                width: { sm: drawerWidth },
                flexShrink: { sm: 0 },
              }}
              aria-label="left navigation drawer"
            >
              {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
              <SwipeableDrawer
                classes={{ paper: classes.paper }}
                variant="temporary"
                open={mobileOpen}
                onClose={handleDrawerToggle}
                onOpen={handleDrawerToggle}
                ModalProps={{
                  keepMounted: true, // Better open performance on mobile.
                }}
                sx={{
                  display: { xs: "block", sm: "none" },
                  "& .MuiDrawer-paper": {
                    boxSizing: "border-box",
                    width: drawerWidth,
                  },
                }}
              >
                {drawer}
              </SwipeableDrawer>

              <SwipeableDrawer
                classes={{ paper: classes.paper }}
                variant="permanent"
                onClose={handleDrawerToggle}
                onOpen={handleDrawerToggle}
                sx={{
                  display: { xs: "none", sm: "block" },
                  "& .MuiDrawer-paper": {
                    boxSizing: "border-box",
                    width: drawerWidth,
                  },
                }}
                open
              >
                {drawer}
              </SwipeableDrawer>
            </Box>
          </Collapse>
        </Box>
      </Box>
    </Box>
  );
}

export default function IntegrationNotistack({
  onMobile,
  collapsed,
  setCollapsed,
  setOpenModal,
  setCloseModal,
}) {
  return (
    <SnackbarProvider maxSnack={3}>
      <Sidebar
        onMobile={onMobile}
        collapsed={collapsed}
        setCollapsed={setCollapsed}
        setOpenModal={setOpenModal}
        setCloseModal={setCloseModal}
      />
    </SnackbarProvider>
  );
}

const institutionalCategories = [
  {
    value: 'Aceleradora',
    label: 'Aceleradora'
  },
  {
    value: 'Agentes de fomento',
    label: 'Agentes de fomento'
  },
  {
    value: 'Corporate Venture',
    label: 'Corporate Venture'
  },
  {
    value: 'Coworking',
    label: 'Coworking'
  },
  {
    value: 'Empresa júnior',
    label: 'Empresa júnior'
  },
  {
    value: 'Empresa de Base Tecnológica',
    label: 'Empresa de Base Tecnológica'
  },
  {
    value: 'Empresas de Investimento',
    label: 'Empresas de Investimento'
  },
  {
    value: 'Economia criativa',
    label: 'Economia criativa'
  },
  {
    value: 'Governo',
    label: 'Governo'
  },
  {
    value: 'ICTs(Institutos de Ciência e Tecnologia)',
    label: 'ICTs(Institutos de Ciência e Tecnologia)'
  },
  {
    value: 'Incubadoras',
    label: 'Incubadoras'
  },
  {
    value: 'Marker Space',
    label: 'Marker Space'
  },
  {
    value: 'Polos de Tecnologia',
    label: 'Polos de Tecnologia'
  },
  {
    value: 'Propriedade Industrial',
    label: 'Propriedade Industrial'
  },
  {
    value: 'OxeTech LAB',
    label: 'OxeTech LAB'
  },
  {
    value: 'Startups/empresas de tecnologia',
    label: 'Startups/empresas de tecnologia'
  },

  {
    value: 'Outros',
    label: 'Outros'
  }
]

export default institutionalCategories

import React, { useEffect, useState } from "react";
import api from "../../services/api";
import Sidebar from "../../components/SidebarEmpresa";
// import HeaderEmpresa from "../../components/HeaderEmpresa";

import { Box, CssBaseline } from "@mui/material";
import DashboardVaga from "../../components/DashboardVaga";

function PageVagaEmpresa({ match }) {
  // let headerHeight = "8vh";
  const { id } = match.params;
  const [job, setJob] = useState({});
  const [candidates, setCandidates] = useState({});
  const [candidatesNotHired, setCandidatesNotHired] = useState({});

  useEffect(() => {
    api
      .get(`vagas/vaga-empresa-index?id=${id}`)
      .then((response) => {
        setJob(response.data.vaga);
      })
      .catch((error) => {
        console.log(error);
      });
    api.get(`candidatura/candidatosmyvaga?id=${id}`).then((response) => {
      setCandidates(response.data);
    });
    api
      .get(`/candidatura/naocontratados/${id}`)
      .then((response) => {
        setCandidatesNotHired(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const [width, setWidth] = useState(window.innerWidth);
  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const onMobile = width <= 600;
  const [sidebarCollapsed, setSidebarCollapsed] = useState(false);

  return (
    <Box sx={{ display: "flex", zIndex: 2 }}>
      <CssBaseline />
      {/* <HeaderEmpresa headerHeight={headerHeight} /> */}

      <Sidebar
        onMobile={onMobile}
        collapsed={sidebarCollapsed}
        setCollapsed={setSidebarCollapsed}
      />
      <Box
        component="main"
        sx={{
          flexGrow: 2,
          p: 1,
          width: {
            xs: `calc(100% - 20vh)`,
            marginLeft: onMobile || sidebarCollapsed ? "0" : "240px",
          },
        }}
      >
        {/* <Button sx={{ float: "right", margin: "10px" }} variant="contained" onClick={handleCadastroVaga}>+ Nova vaga</Button> */}
        <DashboardVaga candidatesNotHired={candidatesNotHired} candidates={candidates} job={job} />
      </Box>
    </Box>
  );
}

export default PageVagaEmpresa;

import styled from 'styled-components';
import {Grid, Modal} from '@mui/material';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px;

  width: auto;
  height: 100vh;
  align-items: center;
`;


export const ModalCard = styled(Modal)`
    border-radius: 20px;
    position: absolute;
    width: 50%;
    padding: 0%;
    margin: auto;
    .ant-modal-wrap{
        width: 60vw;

    }
    .ant-modal-content{
        
        width: 100%;
    }
    .ant-modal-body{
        padding: 0 4%;
    }
    .horizontal{
        margin-top: 1.4%;
        margin-bottom: 1.4%;
        width: 100%;
        height: 0.12em;
        background: linear-gradient(90.07deg, rgba(22, 9, 179, 0.65) 7.23%, rgba(142, 36, 170, 0.65) 96.61%);



    }
`

export const Content = styled.div`
  display: grid;
  /* justify-content: space-between; */
  /* flex-direction: column; */
  /* align-items: center; */
  grid-template-columns: 0fr 0fr;
  margin: 0 auto;
  width: 70vw;
  padding: 15px;
  /* background: #333; */
  justify-content: center;

  div {
    /* border: 2px solid #000; */
    /* width: 50vw; */
    margin: 0 auto;
    /* background: #ccc; */

    button {
      width: 15vw;
      height: 15vh;
      margin: 10px;
      background: #8e24aa;
      border: 0;

      color: #fff;

      font-size: 1.6rem;

      &:hover {
        color: #8e24aa;
        background: #fff;
        border: 1px solid #8e24aa;
      }
    }
  }
`;


export const InfoAdd = styled(Grid)`
    h3{
        font-family: 'Karla', sans-serif;
        font-weight: bold;
        margin-top: 1%;
    }
    p, li{
        font-family: 'Poppins', sans-serif;
        font-weight: light;

    }
    ul{
        margin-left: 4%;
    }
  

`

export const Title = styled('div')`

    display: flex;
    justify-content: space-around;
    align-items: center;

    h1 {
        font-family: 'Poppins', sans-serif;
        font-weight: 30px;
        text-transform: capitalize;
        /* font-size: 1.9em; */
        font-size: 300%;
        word-break: break-word;
    }

    h5 {
        font-family: 'Poppins', sans-serif;
        color: #525252;
        text-align: center;
        text-transform: capitalize;
    }

    .foto{
        width: 8em;
        height: 8em;

        @media (max-width: 700px){
            width: 5em;
            height: 5em;
        }
    }

    .avatar-place{
        width: 12em; 
        height: 12em;
        @media (max-width: 700px) {
            width: 8em; 
            height: 7em;
            margin-right: 5% ;
        }
    }
    .avatar {
        background: rgba(22, 9, 178, 0.71);
        width: 100%;
        height: 100%; 

    }
    
    
`
export const Labels = styled('div')`
display: flex;
flex-direction: row;
justify-content: space-between;
align-items: center;
margin-bottom: 2%;
/* .porcentagem{
    font-family: 'Poppins', sans-serif;
    color: #000;
    margin-left: 1%;
    font-weight: bold ;
} */

`

export const Info = styled('div')`
    margin-top: 4%;
    margin-left: 2%;
    margin-bottom: 5%;
    
    h5{
        font-family: 'Poppins', sans-serif;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.8);
    }
    
    `
 
export const VagaName = styled('div')`
width: 70%;
display: flex;
justify-content: center;
flex-direction: column;
h1{
    font-size: 1.53em;
    text-transform: capitalize;
    @media (max-width: 700px) {
        font-size: 1.4em;
        
    }
}

h5{
    text-transform: capitalize;

}

`
import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid';
import Typography from "@mui/material/Typography";

export const Container = styled(Box)`
  display: flex;
  align-items: center;
  flex-direction: column;
  height: auto;
  padding: 5%;
  background: linear-gradient(180deg, #281F8A 48.1%, #340E74 89.08%, #000063 122.33%);

  

  /* @media(max-width: 800px){
    padding-top: 0;
  } */
`




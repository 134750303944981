import React, {useState,useEffect} from 'react'
import Sidebar from '../../../components/Bolsista/SideBar'
import Dashboard from './DashBoard';
import {
  Box,
} from "@mui/material";
function Escola() {
  const [collapsed, setCollapsed] = useState(false)
  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);
  const onMobile = width <= 600;

  return(
  <div>
    <Sidebar onMobile={onMobile} collapsed={collapsed} setCollapsed={setCollapsed}/>
    <Box sx={{ display: "flex", backgroundColor: "#FFFFF", flexGrow: 1 }}>
      <Box          
      component="main"
      sx={{
        backgroundColor: "#FFFFFF",
        flexGrow: 2,
        p: 0,
        width: { xs: `calc(100% - 20vh)` },
        marginLeft: onMobile || collapsed ? "auto" : "300px",
      }}>
        <Dashboard/>
      </Box>
    </Box>
  </div>
  ) 
}

export default Escola
